import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  InputLabel,
  Typography,
  Input,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Popover,
  CardMedia,
  ListSubheader,
  Drawer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  List,
  ListItem,
  ListItemText,
  TablePagination,
  Modal,
  Tabs,
  Tab,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "../../../components/src/TimesheetManagementHelper.web";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { TabPanel, assigneeA11yProps } from "./TimesheetManagement.web";
import DeleteConfirmationModal from "../../../components/src/DeleteConfirmationModal.web";
import WebLoader from "../../../components/src/WebLoader.web";
import moment from 'moment';
import { showLettersFromName } from "../../../components/src/ReusableFunctions";
// Customizable Area End
import DownloadTimesheetController, {
  Props,
  configJSON,
  // Customizable Area Start
  // Customizable Area End
} from "./DownloadTimesheetController.web";
export class DownloadTimesheet extends DownloadTimesheetController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  showTableBars = () => {
    const { classes } = this.props;
    return (
      <Box className="table-top">
        <Box className="top-left">
          <Box className="search-wrapper">
            <Input
              placeholder={this.t(`${configJSON.searchTxtType}`)}
              type="search"
              // onChange={newSearchTerm =>
              //   this.handleTeamsSearch(newSearchTerm)
              // }
              //value={this.state.teamSearchValue}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Box>
        </Box>
        <Box className="top-right">
          <FormControl className="select-control teams-sort">
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                classes: { paper: classes.dropdownStyle },
              }}
              displayEmpty
              defaultValue=""
              //onChange={this.changeSortByTeams}
              data-test-id="sortByTeams"
            >
              <MenuItem value="">{this.t(`${configJSON.sortByTxt}`)}</MenuItem>
              <MenuItem value={1}>A to Z</MenuItem>
              <MenuItem value={2}>Z to A</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    );
  };
  showPaginationToolBar = (rowsLength: number, type: string) => {
    const { classes } = this.props;
    const {setPage} = this.state;
    let text = "";
    if(type === "Members"){
      text = rowsLength > 1 ? this.t(configJSON.membersTxt) : this.t(configJSON.membersSingleTxt);
    }else{
      text = rowsLength > 1 ? this.t(configJSON.teamsTxt) : this.t(configJSON.teamsSingleTxt);
    }
   
    return (
      <Box className={classes.paginationToolbar}>
        <Box className="total-member">
          <Box className="member-count">
            {rowsLength} {text}
          </Box>
        </Box>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={rowsLength}
          rowsPerPage={2}
          page={this.state.setPage}
          onPageChange={(event:any, setPage:number) => this.handleChangePage(event,setPage)}
          className={classes.paginationTable}
          data-test-id={`pagination-action-${type}`}
        />
      </Box>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {viewTimesheetDetails, viewTimesheetMembers, viewTimesheetTeams} = this.state;
    console.log(viewTimesheetTeams)
    let start_date, end_date;
    // let viewTimesheetMembers = [];
    //let viewTimesheetTeams = [];
    if (viewTimesheetDetails.attributes !== undefined) {
      start_date = viewTimesheetDetails.attributes.start_date ? moment(viewTimesheetDetails.start_date).format("MMMM DD, YYYY") : "";
      end_date = viewTimesheetDetails.attributes.end_date ? moment(viewTimesheetDetails.end_date).format("MMMM DD, YYYY") : "";
      //viewTimesheetMembers = viewTimesheetDetails.attributes.members?.data || [];
     // viewTimesheetTeams = viewTimesheetDetails.attributes.teams?.data || [];
    }
   
   
    return (
      <>
      {this.state.isLoading && <WebLoader/>}
        <Box
          className={`wrapper ${classes.wrapper} ${classes.downloadWrapper}`}
        >
          <Box className={`header-wrapper ${classes.downloadHeader}`}>
            <Box
              className="auth-back"
              onClick={this.navigateToTimesheet}
              data-test-id="back-arrow-btn"
            >
              <i>
                <svg
                  className="MuiSvgIcon-root"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                </svg>
              </i>
              Back
            </Box>

            <Box className="auth-right">
              <Button
                className={`secondary-btn ${classes.secondaryButton}`}
                title="Create Team"
                onClick={this.deleteTimesheetAction}
                data-test-id="delete-action-id"
              >
                Delete
              </Button>

              <Button
                className={`primary-btn ${classes.primaryButton}`}
                title="Create Team"
                onClick={this.downloadTimesheet}
              >
                Download Timesheet
              </Button>
            </Box>
          </Box>
          <Box className={classes.innerWrapper}>
            <Box className={classes.timesheetModuleWrapper}>
              <Box className="heading-wrapper">
                <Box className="heading-left">
                  <Typography className="heading" variant="h1">
                  {start_date} - {end_date}
                  </Typography>
                  <Box component="p" className="sub-txt">
                    View the number of hours logged by each member.
                  </Box>
                </Box>
              </Box>
              {this.state.showOnlyTeams ? (
                <></>
              ) : (
                <Box className={classes.tabWrapper}>
                  <Tabs
                    data-test-id="update-tabs"
                    onChange={this.setAssigneeTabsValue}
                    value={this.state.assigneeTabsValue}
                    aria-label="simple tabs example"
                  >
                    <Tab
                      label={this.t(`${configJSON.membersTxt}`)}
                      {...assigneeA11yProps(0)}
                    />
                    <Tab
                      label={this.t(`${configJSON.teamsTxt}`)}
                      {...assigneeA11yProps(1)}
                    />
                  </Tabs>
                  <TabPanel value={this.state.assigneeTabsValue} index={0}>
                    {this.showTableBars()}
                    <Box>
                      {this.showPaginationToolBar(
                        viewTimesheetMembers.length,
                        configJSON.membersTxt
                      )}
                      
                      <TableContainer>
                        <Table
                          className={`viewTeamList`}
                          aria-labelledby="tableTitle"
                          aria-label="enhanced table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell className="name-cell">Name</TableCell>
                              <TableCell className="stage-cell">
                                Email
                              </TableCell>
                              <TableCell className="deal-amount-cell">
                                Teams
                              </TableCell>
                              <TableCell className="task-cell">Tasks</TableCell>
                              <TableCell className="task-cell">Hours</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {viewTimesheetMembers !== undefined && viewTimesheetMembers.length > 0 &&
                            <>
                            {viewTimesheetMembers
                              .slice(
                                this.state.setPage * this.state.setRowsPerPage,
                                this.state.setPage * this.state.setRowsPerPage +
                                  this.state.setRowsPerPage
                              )
                              .map((member: any) => {
                                return (
                                  <TableRow key={member.id}>
                                    <TableCell className="name-cell">
                                      <Box className="name-col">
                                        <Box className="profile-name">{showLettersFromName(`${member.name}`)}</Box>
                                        {member.name}
                                      </Box>
                                    </TableCell>
                                    <TableCell
                                      className="email-cell"
                                      scope="row"
                                    >
                                      {member.email}
                                    </TableCell>
                                    <TableCell
                                      className="team-cell"
                                      scope="row"
                                    >
                                      <Box className="team-col">
                                      <Box className="departments">
                                      <Box className="dep-item">
                                      <Typography className="team-name">
                                          {member.teams.length > 0 && 
                                            member.teams.map((team:any, index:any) => {
                                              return team;
                                            })
                                          }
                                        </Typography>
                                      </Box>
                                      </Box>
                                      
                                      </Box>
                                    </TableCell>
                                    <TableCell
                                      className="task-cell"
                                      scope="row"
                                    >
                                      {member.tasks_count} Tasks
                                    </TableCell>
                                    <TableCell
                                      className="hours-cell"
                                      scope="row"
                                      onClick={this.openHoursModal}
                                      data-test-id={`open-hours-modal-${member.id}`}
                                    >
                                      {member.total_hours} hours
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </>
                            
                            }
                            
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </TabPanel>
                  <TabPanel value={this.state.assigneeTabsValue} index={1}>
                    {this.showTableBars()}
                    <Box>
                      {this.showPaginationToolBar(viewTimesheetTeams.length, configJSON.teamsTxt)}
                      <TableContainer>
                        <Table
                          className={`viewTeamList`}
                          aria-labelledby="tableTitle"
                          aria-label="enhanced table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell className="name-cell">Name</TableCell>
                              <TableCell className="stage-cell">
                                Members
                              </TableCell>
                              <TableCell className="task-cell">Tasks</TableCell>
                              <TableCell className="task-cell">Hours</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {viewTimesheetTeams !== undefined && viewTimesheetTeams.length > 0 && <>
                              {viewTimesheetTeams
                              .slice(
                                this.state.setPage * this.state.setRowsPerPage,
                                this.state.setPage * this.state.setRowsPerPage +
                                  this.state.setRowsPerPage
                              )
                              .map((team: any) => {
                                return (
                                  <TableRow key={team.team_name}>
                                    <TableCell className="name-cell">
                                      <Box
                                        className="name-col"
                                        onClick={this.showTeamDetails}
                                        data-test-id={`click-team-event-${team.team_name}`}
                                      >
                                        <Box className="profile-name">{showLettersFromName(team.team_name)}</Box>
                                        {team.team_name}
                                      </Box>
                                    </TableCell>

                                    <TableCell
                                      className="team-cell"
                                      scope="row"
                                    >
                                      {team.total_members} users
                                    </TableCell>
                                    <TableCell
                                      className="task-cell"
                                      scope="row"
                                    >
                                      {team.tasks_count} Tasks
                                    </TableCell>
                                    <TableCell
                                      className="hours-cell"
                                      scope="row"
                                    >
                                      16:24 hours
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </>}
                            
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </TabPanel>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {this.state.openHoursDetail && (
          <Modal
            open={this.state.openHoursDetail}
            onClose={this.closeHoursModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.modalDialog}
          >
            <Box className={classes.modalDialogBox}>
              <Box className="modal-dialoginner">
                <Box className="modal-heading">
                  <Typography className="modal-title" component="h2">
                    Esthar Howard
                  </Typography>
                  <Link
                    to="#"
                    className="close-icon"
                    onClick={this.closeHoursModal}
                    data-test-id="closeHoursModal"
                  >
                    <CloseIcon />
                  </Link>
                </Box>
                <Box className="modal-description">
                  <Box component="p" className="sub-txt">
                    Below is the time tracked to each task.
                  </Box>
                </Box>
                <Box className="hours-listing">
                  <Box className="project-details-wrapper">
                    <Box className="project-number-and-details">
                      <Box className="project-number">1</Box>
                      <Box className="project-details">
                        <Box className="title">Red Wine Photoshoot</Box>
                        <Box className="project-description">
                          Wine Collection 2022 / Decemb...
                        </Box>
                      </Box>
                    </Box>
                    <Box className="project-hours">6:24hrs</Box>
                  </Box>

                  <Box className="project-details-wrapper">
                    <Box className="project-number-and-details">
                      <Box className="project-number">2</Box>
                      <Box className="project-details">
                        <Box className="title">Red Wine Photoshoot</Box>
                        <Box className="project-description">
                          Wine Collection 2022 / Decemb...
                        </Box>
                      </Box>
                    </Box>
                    <Box className="project-hours">6:24hrs</Box>
                  </Box>

                  <Box className="project-details-wrapper">
                    <Box className="project-number-and-details">
                      <Box className="project-number">3</Box>
                      <Box className="project-details">
                        <Box className="title">Red Wine Photoshoot</Box>
                        <Box className="project-description">
                          Wine Collection 2022 / Decemb...
                        </Box>
                      </Box>
                    </Box>
                    <Box className="project-hours">6:24hrs</Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        )}
        {this.state.isDeleteActive && <DeleteConfirmationModal
          modalOpen={this.state.isDeleteActive}
          modalClose={this.closeTimesheetAction}
          deleteHeading="Delete Timesheet"
          deleteText={this.t(`${configJSON.deleteTimesheetTxt}`)}
          confirmHandler={this.timesheetDeleteAPICAll}
          data-test-id="deleteTimesheetModal"
          t={this.t}
          closeIconGray={true}
        />}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(DownloadTimesheet);
// Customizable Area End
