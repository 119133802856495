import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  tooltip: {
    backgroundColor: "#000000",
    color: "#FFFFFF",
    padding: "24px",
    borderRadius: "8px",
    maxWidth: "225px",
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
  },
  arrow: {
    color: "#000000",
  },
};

interface Props {
  title: string;
  classes: any;
  icon: any;
}

class TruncatedTextWithTooltip extends Component<Props> {
  render() {
    const { classes, icon, title } = this.props; 

    return (
      <Tooltip
        title={title}
        arrow
        placement="bottom-start"
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.arrow,
        }}
      >
        <img src={icon} className="img-tooltip" alt="circle" />
      </Tooltip>
    );
  }
}

export default withStyles(styles)(TruncatedTextWithTooltip);
