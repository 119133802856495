// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Input,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { sortVariant } from "./assets";
import { etohShopStyle } from "../../../components/src/EtohShopStyleHelper.web";
import CustomEtohShopSidebarController, {
  configJSON,
  FilterResult,
  SubCategory,
} from "./CustomEtohShopSidebarController.web";
import { withStyles } from "@material-ui/core/styles";
import BackArrow from "@material-ui/icons/ArrowBack";

export class CustomEthoShopSidebar extends CustomEtohShopSidebarController {
  renderBox = () => {
    const { classes } = this.props;
    return (
      <Box>
        <Box className="header">
          <Box>
            <Typography className="header-heading">
              {this.state.serviceCount > 1
                ? `${this.state.serviceCount} ${this.t(
                    `${configJSON.categoriesTxt}`
                  )}`
                : `${this.state.serviceCount} ${this.t(
                    `${configJSON.categorieTxt}`
                  )}`}
            </Typography>
          </Box>
          <Box>
            <FormControl className="select-control sortby-control">
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  classes: { paper: classes.dropdownStyle },
                }}
                value={this.state.sortValue}
                onChange={this.handleSortByChange}
                displayEmpty
                defaultValue={this.t("Sort by")}
                inputProps={{ IconComponent: () => null }}
                renderValue={(value) => {
                  return (
                    <Box>
                      <img
                        className="select-icon"
                        alt="sortVariant"
                        src={sortVariant}
                      />
                      {value}
                    </Box>
                  );
                }}
                data-test-id="sort-by-input"
              >
                <MenuItem value="A-Z">{this.t("A to Z")}</MenuItem>
                <MenuItem value="Z-A">{this.t("A to Z")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      isSorting,
      isSearching,
      categoryData,
      subCategoryData,
      filterResults,
      searcedCategoryData,
    } = this.state;

    return (
      <Box className={classes.marketingSidebar}>
        <Box className="sidebar-title">
          <BackArrow
            className="back-btn-wrap"
            style={{ color: "#2B2B2B" }}
            onClick={this.props.toggleMenu}
          />
          <Typography className="heading" variant="h1">
            {this.t(`${configJSON.cardsTxt}`)}
          </Typography>
        </Box>
        <Box className="sidebar-group">
          {this.renderBox()}
          <Box className="search-wrapper">
            <Input
              placeholder={this.t("Search Categories")}
              type="search"
              value={this.state.searchQuery}
              onChange={this.handleSearchInputChange}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "rgb(142, 140, 140)" }} />
                </InputAdornment>
              }
              disableUnderline
              data-test-id="search-by-input"
            />
          </Box>
          <Box className="line" />
        </Box>
        {!isSearching && !isSorting && (
          <>
            {categoryData.map((accordion: any) => (
              <Accordion
                key={accordion.id}
                className="sidbar-accordion"
                expanded={this.state.expandedAccordion === accordion.id}
                data-test-id="expandedAccordion"
                onChange={(event: any, isExpanded: any) =>
                  this.setState({
                    expandedAccordion: isExpanded ? accordion.id : null,
                  })
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className="accordionTitle"
                  onClick={() => this.getSubEtohShopCategory(accordion.id)}
                  data-test-id={`getSubEtohShopCategory-${accordion.id}`}
                >
                  <Box className="sidebar-heading">
                    {this.t(accordion.attributes.name)}
                  </Box>
                </AccordionSummary>
                <AccordionDetails className="accordionContent">
                  <List className="sidebar-ul">
                    {subCategoryData.map((item: any) => (
                      <ListItem
                        key={item.id}
                        className={`sidebar-li ${this.state.selectedSubCategoryId === item.id
                            ? "label-selected"
                            : ""
                        }`}
                        onClick={() =>
                          this.selectedValueEvent(item.id, item.attributes.name)
                        }
                        data-test-id={`selectedValueEvent-${item.id}`}
                      >
                        <Link to="#" className="label-wrapper">
                          <Box component="span" className="tab-label">
                            {this.t(item.attributes.name)}
                          </Box>
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}

        {isSearching && (
          <>
            {searcedCategoryData?.etoh_shop_categories?.map(
              (accordion: any) => (
              <Accordion
                key={accordion.id}
                className="sidbar-accordion"
                expanded={this.state.expandedAccordion === accordion.id}
                data-test-id={`expandedAccordion-search-${accordion.id}`}
                  onChange={(event: any, isExpanded: any) =>
                  this.setState({
                    expandedAccordion: isExpanded ? accordion.id : null,
                  })
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className="accordionTitle"
                  onClick={() => {
                    this.getSubEtohShopCategory(accordion.id);
                    this.setState({ expandedAccordion: accordion.id });
                  }}
                  data-test-id={`getSubEtohShopCategory-search-${accordion.id}`}
                >
                  <Box className="sidebar-heading">
                    {this.t(accordion.name)}
                  </Box>
                </AccordionSummary>
                <AccordionDetails className="accordionContent">
                  <List className="sidebar-ul">
                      {accordion.etoh_shop_sub_categories.map((item: any) => (
                      <ListItem
                        key={item.id}
                          className={`sidebar-li ${this.state.selectedSubCategoryId === item.id
                            ? "label-selected"
                            : ""
                        }`}
                        onClick={() =>
                          this.selectedValueEvent(item.id, item.name)
                        }
                        data-test-id={`selectedValueEvent-${item.id}`}
                      >
                        <Link to="#" className="label-wrapper">
                          <Box component="span" className="tab-label">
                            {this.t(item.name)}
                          </Box>
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
              )
            )}
          </>
        )}
        {isSorting && this.state.searchQuery === "" && (
          <>
            {filterResults.map((acc: FilterResult) => (
              <Accordion
                key={acc.id}
                className="sidbar-accordion"
                expanded={this.state.expandedAccordion === acc.id}
                data-test-id="expandedAccordion-sort"
                onChange={(event: React.ChangeEvent<{}>, isExpanded: boolean) =>
                  this.setState({
                    expandedAccordion: isExpanded ? acc.id : null,
                  })
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className="accordionTitle"
                  onClick={() => this.getSubEtohShopCategory(acc.id)}
                  data-test-id={`getSubEtohShopCategory-sort-${acc.id}`}
                >
                  <Box className="sidebar-heading">{this.t(acc.name)}</Box>
                </AccordionSummary>
                <AccordionDetails className="accordionContent">
                  <List className="sidebar-ul">
                    {acc.etoh_shop_sub_categories.map((i: SubCategory) => (
                      <ListItem
                        key={i.id}
                        className={`sidebar-li ${this.state.selectedSubCategoryId === i.id
                            ? "label-selected"
                            : ""
                        }`}
                        onClick={() => this.selectedValueEvent(i.id, i.name)}
                        data-test-id={`selectedValueEvent-sort-${i.id}`}
                      >
                        <Link to="#" className="label-wrapper">
                          <Box component="span" className="tab-label">
                            {this.t(i.name)}
                          </Box>
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}
      </Box>
    );
  }
}

export default withStyles(etohShopStyle, { withTheme: true })(
  CustomEthoShopSidebar
);
// Customizable Area End
