import React from "react";
// Customizable Area Start
import {
  withStyles,
} from "@material-ui/core/styles";
import { customStyles } from "../../../../components/src/TaskListStyles.web";
import TruncatedTextWithTooltip  from "../../../../components/src/CustomTooltip.web";
import {
  Popover,
  Chip,
  Box,
  Tabs,
  Tab,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  InputLabel,
  Checkbox,
  InputAdornment,
  FormControl,
  Select,
  ListSubheader,
  ListItemSecondaryAction,
  IconButton,
  MenuItem,
  Modal,
  Button,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  RadioGroup,
  Radio
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import CustomDatePicker from "../../../../components/src/CustomDatePicker.web";
import ConfirmActionModal from "../../../email-account-registration/src/teams-web/ConfirmActionModal.web";
import {
  fileTree,
  accountPlus,
  dotsHorizontal,
  checkBoxFilled,
  checkBoxEmpty,
  editGray,
  copyGray,
  attachmentImg1,
  minimizeGray,
  closeGray,
  productImg,
  projectCreate,
  contentSave,
  fileTreeBlue,
  formatTreeBlue,
  dotVerticle,
  uploadImg,
  attacmentsIcon,
  calendarViewIcon,
  grayCheck,
  greenCheck,
  warningIcon,
} from "../assets";
import moment from "moment";
import { TabPanel, viwA11yProps ,assigneeA11yProps } from "./TaskList.web";
import FreeSoloCreateOption from "../../../../components/src/FreeSoloCreateOption.web";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ActivityLog } from "../../../ActivityLog/src/ActivityLog.web";
import { FileDrop } from "react-file-drop";
import { Project } from "../../../../components/src/ReusableEnums";
import { formatTimeTaskHours, hoursToSeconds, renderStatusIcon, renderTaskCount, renderTimerIcon ,formatDueDate, getPlaceholderImage} from "../../../../components/src/ReusableFunctions";
// Customizable Area End

import ProjectViewController,{
  Props,
  // Customizable Area Start
  configJSON,
  // Customizable Area End
} from "./ProjectViewController.web";



// Customizable Area Start
// Customizable Area End


export class ProjectView extends ProjectViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    showTaskByTab=()=>{
      const tasksToShow = this.getProjectTasksByTab();
      return <>
      {tasksToShow.today.length >= 0 &&
        this.taskTab("Today",tasksToShow.today)}
      {tasksToShow.tomorrow.length >= 0 &&
        this.taskTab("Tomorrow",tasksToShow.tomorrow)}
      {tasksToShow.thisWeek.length >= 0 &&
        this.taskTab("This Week",tasksToShow.thisWeek)}
      {tasksToShow.thisWeek.length >= 0 &&
        this.taskTab("This Month",tasksToShow.thisMonth)}
      {tasksToShow.thisWeek.length >= 0 &&
        this.taskTab("Later",tasksToShow.later)}
      </>
     }

    taskTab = (value:string,tasks:any) => {      
      return(
        <Box className="tasklist-task-wrap">
            <Box className="task-heading">
              <Box className="heading">{value}</Box>
              <Box className="total-task"> {renderTaskCount(tasks, this.t, configJSON)}</Box>
            </Box>
            <List className="tasklist-task-ul">
            {tasks.map((task:any) => {             
              return (
                <ListItem
                  className="tasklist-task-li"
                  key={task.id}
                  role={undefined}
                  dense
                >
                  <Box className="tasklist-inner">
                    <ListItemIcon className="tasklist-icon">                   
                    {renderStatusIcon(task.attributes.status)}
                    </ListItemIcon>
                    <Box className="tasklist-label">
                      <Box className="task-title">
                        <Box className="title">{task.attributes.title}</Box>
                        <Box className="title-right">
                          <Box className="controller-link">
                           {renderTimerIcon(
                              task.id,
                              task.attributes.status,
                              this.state.taskTimers[task.id] || false,
                              this.startTaskTimerAPI
                            )}
                          </Box>
                        </Box>
                      </Box>
                      <Box className="task-desc">
                        <Box className="desc-left">
                          <Box className="date" component="span">
                          {formatDueDate(task.attributes.due_date)}
                          </Box>
                        </Box>
                        <Box className="desc-right">
                          <Box className="timing" component="span">
                          {formatTimeTaskHours(task.attributes.duration)}/{formatTimeTaskHours(hoursToSeconds(task.attributes.hours))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
              );
            })}
            </List>
        </Box>
        )
    }

    projectListItem=(classes:any, option:any,selected:boolean=true)=>{
    return(
      <ListItem
      key={option.id}
      value={option.id}
      onClick={() => this.changeProjectLead(option.id, option.deactivated)}
      data-test-id={`projectLead-${option.id}`}
    >
      <Box className="assignee-detail">
        <Box className="assignee-left">
          <Box className="assignee-profile">
            {" "}
            {option.initials}
          </Box>
          <Box className="assignee-info">
            <Box className="assignee-name">
              {option.title}
            </Box>
            <Box className="assignee-email">
              {option.email}
            </Box>
            <Box className="chip-wrapper">
              {option.team
                .slice(0, 2)
                .map((teamName: string) => {
                  return (
                    <Chip
                      key={teamName}
                      className={classes.chipButton}
                      // label="Sales Department"
                      label={teamName}
                    />
                  );
                })}

              {option.team.length > 2 && (
                <Chip
                  className={`more-btn ${classes.chipButton}`}
                  label={`+${
                    option.team.length - 2
                  } ${this.t(configJSON.moreTxt)}`}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box className="assignee-right">
          <Box className={selected?"assinee-selected":"assinee-unselected"}>
            <img
              className="normal-img"
              src={checkBoxEmpty}
              alt="checkBoxEmpty"
            />
            <img
              className="selected-img"
              src={checkBoxFilled}
              alt="checkBoxFilled"
            />
          </Box>
        </Box>
      </Box>
    </ListItem>
    )

    }

    timesheetListItem = (classes:any, option:any,selected:boolean=true)=>{
      return(
        <ListItem
          key={option.id}
          value={option.id}
          onClick={() =>
            this.changeTimeSheetAssignee(option.id)
          }
        >
          <Box className="assignee-detail">
            <Box className="assignee-left">
              <Box className="assignee-profile">
                {" "}
                {option.initials}
              </Box>
              <Box className="assignee-info">
                <Box className="assignee-name">
                  {option.title}
                </Box>
                <Box className="assignee-email">
                  {option.email}
                </Box>
                <Box className="chip-wrapper">
                  <Chip
                    key="Sales Department"
                    className={classes.chipButton}
                    label={this.t(`${configJSON.salesDepartmentTxt}`)}
                  />
                  <Chip
                    key="Sales Department"
                    className={classes.chipButton}
                    label={this.t(`${configJSON.accountsTeamTxt}`)}
                  />
                  <Chip
                    className={`more-btn ${classes.chipButton}`}
                    label={`+2 ${this.t(configJSON.moreTxt)}`}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="assignee-right">
              <Box className="hours-details">
                <Box>$15</Box>
                <Box className="gray-txt">{this.t(`${configJSON.perHoursTxt}`)}</Box>
              </Box>
              <Box className={selected?"assinee-selected":"assinee-unselected"}>
                <img
                  className="normal-img"
                  src={checkBoxEmpty}
                  alt="checkBoxEmpty"
                />
                <img
                  className="selected-img"
                  src={checkBoxFilled}
                  alt="checkBoxFilled"
                />
              </Box>
            </Box>
          </Box>
        </ListItem>
      )
    }
    
    timesheetChooseAssigneesModal = (classes:any) => {
      return (
        <Modal
              hideBackdrop
              open={this.state.isActiveTimesheetChooseAssignees}
              onClose={this.timesheetChooseAssigneesModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className={classes.modalDialog}>
          <Box
                className={`${classes.modalDialogBox} filter-dialogbox timesheet-assignee-dialogbox`}
              >
                <Box className="modal-dialoginner">
                  <Box className="modal-heading">
                    <Box className="filter-heading">
                      <Box
                        className="auth-back"
                        onClick={this.timesheetChooseAssigneesModalClose}
                      >
                        <i>
                          <svg
                            className="MuiSvgIcon-root"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                          </svg>
                        </i>
                      </Box>
                      <Typography className="modal-title" component="h2">                        
                        {this.t(`${configJSON.timesheetTxt}`)}
                      </Typography>
                    </Box>
                    <Box                      
                      className="close-icon"
                      onClick={this.timesheetChooseAssigneesModalClose}
                      data-test-id="activityLogClose"
                    >
                      <CloseIcon />
                    </Box>
                  </Box>
                  <Box className="modal-description">
                    <Box className="heading-block">
                      <Box className="primary-txt">{this.t(`${configJSON.chooseAssigneesTxt}`)}</Box>
                      <Box component="p" className="sub-txt">
                        {this.t(`${configJSON.setDefaultTxt}`)}
                      </Box>
                    </Box>
                    <Box className="modal-form" component="form">
                      <List className="assignee-multiSelect-list selected-list">
                        <ListSubheader>
                          <CustomInputWeb
                            fullWidth={true}
                            placeholder={this.t(`${configJSON.txtSearchAssignees}`)}
                            name="assigneeSearchText"
                            autoFocus={true}
                            onChange={this.handleOnChange}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                          />
                        </ListSubheader>
                        <ListSubheader className="multiselect-subheader">
                        {this.t(`${configJSON.txtAssignees}`)}
                          <Box
                            
                            // onClick={this.removeProjectLead}
                            className="tasklist-link"
                          >
                             {this.t(`${configJSON.txtRemoveAllAssignees}`)}
                          </Box>
                        </ListSubheader>
                        {/* <ListSubheader className="multiselect-subtxt">
                          Members
                        </ListSubheader> */}
                        {this.displayAssigneeOptions()
                          .filter((x: any) => this.state.assignees.includes(x.id))
                          .map((option: any) => (
                            this.timesheetListItem(classes,option)
                          ))}
                        <ListSubheader className="multiselect-subheader">
                          {this.t(`${configJSON.txtUnassigned}`)}
                        </ListSubheader>
                      </List>
                      <Box className="tasklist-tabwrapper">
                        <Box className="assignee-tabs">
                          <Tabs
                            aria-label="simple tabs example"
                            value={0}
                            data-test-id="updateAssigneeTabs"
                          >
                            <Tab label={this.t(`${configJSON.membersTxt}`)} {...assigneeA11yProps(0)} />
                          </Tabs>
                        </Box>
                        <TabPanel value={0} index={0}>
                          <List className="assignee-multiSelect-list">
                            {this.assigneeListHeader(classes,this.state.assignees)}
                            {this.sortAssignees(this.displayAssigneeOptions())
                              .filter(
                                (x: any) => !this.state.assignees.includes(x.id)
                              )
                              .map((option: any) => (
                                this.timesheetListItem(classes,option,false)
                              ))}
                          </List>
                        </TabPanel>
                      </Box>
                    </Box>
                  </Box>
                </Box>
          </Box>
        </Modal>
      )
    }

    timeSheetModal = (classes:any) => {
      return (
        <Modal
          hideBackdrop
          open={this.state.isGetTimesheet}
          onClose={this.getTimesheetModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalDialog}>
          <Box className={`${classes.modalDialogBox} create-timesheet-dialog`}>
            <Box className="modal-dialoginner">
              <Box className="modal-heading">
                <Typography className="modal-title" component="h2">
                {this.t(`${configJSON.timesheetTxt}`)}
                </Typography>
                <Box                 
                  className="close-icon"
                  onClick={this.getTimesheetModalClose}
                  data-test-id="timesheetClose"
                >
                  <CloseIcon />
                </Box>
              </Box>
              <Box className="modal-description">
                <Box component="p" className="sub-txt">
                  {this.t(`${configJSON.selectDetailsTxt}`)}
                </Box>
                <Box className="modal-form" component="form">
                  <Box className="form-info-wrapper">
                    <Box className="form-row">
                      <Box className="form-col select-modal-field">
                        <CustomInputWeb
                          label={this.t(`${configJSON.chooseMemeberOrTeam}`)}
                          disabled={true}
                          startAdornment={
                            this.state.assignees.length > 0 ? (
                              <InputAdornment position="start">
                                <Box className="assignee-list">
                                  {this.displayAssigneeOptions()
                                    .filter((x: any) =>
                                      this.state.assignees.includes(x.id)
                                    )
                                    .slice(0, 2)
                                    .map((option: any) => {
                                      return (
                                        <Box
                                          className="assignee-bubble"
                                          key={option.id}
                                        >
                                          {option.initials}
                                        </Box>
                                      );
                                    })}
                                  {this.state.assignees.length > 2 && (
                                    <Box className="assignee-bubble">
                                      +{this.state.assignees.length - 2}
                                    </Box>
                                  )}
                                  {/* <Box className="assignee-bubble">BS</Box>
                              <Box className="assignee-bubble">LA</Box>
                              <Box className="assignee-bubble">+2</Box> */}
                                </Box>
                              </InputAdornment>
                            ) : null
                          }
                          endAdornment={
                            <InputAdornment
                              position="end"
                              onClick={this.timesheetChooseAssigneesHandler}
                              data-test-id="timesheetAssigneeModalOpen"
                            >
                              <ChevronRightIcon />
                            </InputAdornment>
                          }
                          isRequired={true}
                        />
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <Box className="form-control">
                          <CustomDatePicker
                            placeholder={this.t(`${configJSON.dateRangeTxt}`)}
                            range={true}
                            value={this.state.logDates}
                            currentDate=""
                            // datePickerRef={this.datePickerRef}
                            onChange={this.dateRangeFilter}
                            onOpenPickNewDate={false}
                            t={this.t}
                            data-test-id="logDateFilter"
                            required={true}
                            error={""}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <FormControl
                          className="select-outer"
                          variant="outlined"
                        >
                          <InputLabel id="project">{this.t(`${configJSON.projectTxt}`)}</InputLabel>
                          <Select
                            labelId="project"
                            id="project"
                            value={this.state.timeSheetProject}
                            onChange={this.handleProjectChange}
                            name="project"
                            label={this.t(`${configJSON.projectTxt}`)}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                              classes: {
                                paper: `${classes.dropdownStyle} modal-dropdown`,
                              },
                            }}
                            data-test-id="modalChangeStatus"
                            IconComponent={ExpandMoreIcon}
                          >
                            {this.props.projectList.map((proj) => {
                              return (
                                <MenuItem value={proj.id} key={proj.id}>
                                  {proj.attributes.title}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <Box className="form-control">
                          <Autocomplete
                            multiple
                            className="team-multiselect"
                            options={this.state.projectTaskList}
                            disableCloseOnSelect
                            ChipProps={{ deleteIcon: <CloseIcon /> }}
                            popupIcon={<ExpandMoreIcon />}
                            getOptionLabel={(option) => option.attributes.name}
                            classes={{
                              popper: classes.teamAutocompleteDropbox,
                            }}
                            renderOption={(option, { selected }) => (
                              <Box className="tasklist-li">
                                <Box className="tasklist-left">
                                  {option.attributes.name}
                                </Box>
                                <Box className="tasklist-icon">
                                  <Checkbox
                                    className="checklist-check"
                                    icon={
                                      <img
                                        src={checkBoxEmpty}
                                        alt="checkBoxEmpty"
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                        src={checkBoxFilled}
                                        alt="checkBoxFilled"
                                      />
                                    }
                                    tabIndex={-1}
                                    disableRipple
                                    checked={selected}
                                  />
                                </Box>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={this.t(`${configJSON.chooseTaskListTxt}`)}
                                placeholder=""
                                autoComplete="off"
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <Box className="form-control">
                          <Autocomplete
                            multiple
                            renderOption={(option, { selected }) => (
                              <Box className="tasklist-li">
                                <Box className="tasklist-left">
                                  {option.title}
                                </Box>
                                <Box className="tasklist-icon">
                                  <Checkbox
                                    className="checklist-check"
                                    icon={
                                      <img
                                        src={checkBoxEmpty}
                                        alt="checkBoxEmpty"
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                        alt="checkBoxFilled"
                                        src={checkBoxFilled}
                                      />
                                    }
                                    checked={selected}
                                    disableRipple
                                    tabIndex={-1}
                                  />
                                </Box>
                              </Box>
                            )}
                            className="team-multiselect"
                            disableCloseOnSelect
                            ChipProps={{ deleteIcon: <CloseIcon /> }}
                            options={ [
                              { title: "Red Wine Photoshoot 1" },
                              { title: "Red Wine Photoshoot 2" },
                              { title: "Red Wine Photoshoot 3" },
                              { title: "Red Wine Photoshoot 4" },
                            ]}
                            
                            getOptionLabel={(option) => option.title}
                            classes={{
                              popper: classes.teamAutocompleteDropbox,
                            }}
                            popupIcon={<ExpandMoreIcon />}
                           
                            renderInput={(params) => (
                              <TextField
                                autoComplete="off"
                                {...params}
                                placeholder=""
                                label="Choose Task"
                                variant="outlined"
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="button-wrapper full-btn">
                    <Button
                      className={`primary-btn ${classes.primaryButton}`}
                      title="+ Create Timesheet"
                      onClick={this.TimesheetGenerating}
                      data-test-id="createTimesheet"
                    >
                      + {this.t(`${configJSON.createTimesheetTxt}`)}
                    </Button>
                  </Box>
                  {/* timesheet generating modal start */}
                    <ConfirmActionModal
                      modalClass="nestedModal"
                      isOpen={this.state.isTimesheetGenerating}
                      handleClose={this.TimesheetGeneratingModalClose}
                      modalMessage={this.t(`${configJSON.requestConfirmationTxt}`)}                      
                      confirmBtnTxt={this.t(`${configJSON.okTxt}`)}
                      cancelBtnTxt={this.t(`${configJSON.caneleRequestTxt}`)}
                      modalHeading={this.t(`${configJSON.timesheetGeneratingTxt}`)}
                      data-testid="confirmDeleteTask"
                    />
                  {/* timesheet generating modal end */}
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      )
    }

    sortByDesign = (classes:any,onChange:any)=>{

      return (
        <FormControl className="select-control sortby-control">
          <Select
            data-testid="sortTeams"
            displayEmpty
            defaultValue={this.t(`${configJSON.txtSortBy}`)}
            renderValue={(value: any) => {
              return <Box>{value}</Box>;
            }}
            IconComponent={ExpandMoreIcon}
            onChange={onChange}

            MenuProps={{
              anchorOrigin: {
                horizontal: "left",
                vertical: "bottom",
              },
              transformOrigin: {
                horizontal: "left",
                vertical: "top",
              },
              getContentAnchorEl: null,
              classes: { paper: classes.dropdownStyle },
            }}
          >
            <MenuItem value="A-Z">{this.t(`${configJSON.aTozTxt}`)}</MenuItem>
            <MenuItem value="Z-A">{this.t(`${configJSON.zToaTxt}`)}</MenuItem>
          </Select>
        </FormControl>
      )
    }

    assigneeListHeader=(classes:any,selAssignee:any[])=>{
      const assignLength = this.displayAssigneeOptions().filter(
        (x: any) =>
          !selAssignee.includes(x.id)
      ).length
      return(
        <ListSubheader className="multiselect-membres">
            <Box className="total-member">
              {assignLength}&nbsp;
              {assignLength >1 ? this.t(`${configJSON.membersTxt}`) :this.t(`${configJSON.memberTxt}`)}
            </Box>
           {this.sortByDesign(classes,this.sortByAssigneeHandler)}
      </ListSubheader>
      )
    }
    projectLeadModal = (classes:any) => {
      return (
        <Modal
              open={this.state.isActiveChangeProjectLead}
              onClose={this.projectLeadChangeModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className={classes.modalDialog}
            >
              <Box className={`${classes.modalDialogBox} project-dialogbox`}>
                <Box className="modal-dialoginner">
                  <Box className="modal-heading">
                    <Typography className="modal-title" component="h2">                      
                     {this.t(`${configJSON.changeProjectLeadTxt}`)}
                    </Typography>
                    <Box                     
                      className="close-icon"
                      onClick={this.projectLeadChangeModalClose}
                      data-test-id="projectLeadModalClose"
                    >
                      <CloseIcon />
                    </Box>
                  </Box>
                  <Box className="modal-description">
                    <Box component="p" className="sub-txt">
                      {this.t(`${configJSON.selectSubTxt}`)}
                    </Box>
                    <Box className="modal-form" component="form">
                      <List className="assignee-multiSelect-list selected-list">
                        <ListSubheader>
                          <CustomInputWeb
                            placeholder={this.t(`${configJSON.searchProjectLeadTxt}`)}
                            fullWidth={true}
                            autoFocus={true}
                            name="assigneeSearchText"
                            onChange={this.handleOnChange}
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                            data-test-id="projectLeadSearch"
                          />
                        </ListSubheader>
                        <ListSubheader className="multiselect-subheader">
                          {this.t(`${configJSON.projectLeadTxt}`)}
                          <Box                           
                            className="tasklist-link"
                            onClick={this.removeProjectLead}
                            data-test-id="clearProjectLead"
                          >
                            {this.t(`${configJSON.projectRemoveTxt}`)}
                          </Box>
                        </ListSubheader>
                        {/* <ListSubheader className="multiselect-subtxt">
                          Members
                        </ListSubheader> */}
                        {this.displayAssigneeOptions()
                          .filter((x: any) => this.state.assignees.includes(x.id))
                          .map((option: any) => (
                            this.projectListItem(classes,option)
                          ))}
                        <ListSubheader className="multiselect-subheader">
                          {this.t(`${configJSON.txtUnassigned}`)}
                        </ListSubheader>
                      </List>
                      <Box className="tasklist-tabwrapper">
                        {/* <Box className="assignee-tabs">
                          <Tabs
                            aria-label="simple tabs example"
                            value={0}
                            data-test-id="updateAssigneeTabs"
                          >
                            <Tab label="Members" {...assigneeA11yProps(0)} />
                          </Tabs>
                        </Box> */}
                        {/* <TabPanel value={0} index={0}> */}
                          <List className="assignee-multiSelect-list">
                            {this.assigneeListHeader(classes,this.state.assignees)}
                            {this.sortAssignees(this.displayAssigneeOptions())
                              .filter(
                                (x: any) => !this.state.assignees.includes(x.id)
                              )
                              .map((option: any) => (
                                this.projectListItem(classes,option,false)
                              ))}
                          </List>
                        {/* </TabPanel> */}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
        </Modal>
      )
    }
    deleteProjectModal = () => {
      return (
        <ConfirmActionModal
          isOpen={this.state.isDeletingProject}
          handleClose={this.projectDeleteModalClose}
          modalConfirmAction={this.projectDelete}
          modalMessage={this.t(configJSON.confirmDeleteProject, { title: this.state.currentProject?.attributes.title })}
          confirmBtnTxt={this.t(`${configJSON.txtDelete}`)}
          modalHeading={this.t(`${configJSON.deleteProjectTxt}`)} 
          cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
          data-testid="confirmDeleteProject"
        />
      )
    }

    projectCreatedModal=(classes:any)=>{
      return(
        <Modal
          open={this.state.isActiveProjectCreated}
          onClose={this.projectCreatedModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalDialog}        
        >
        <Box className={`${classes.modalDialogBox} project-created-dialog`}>
          <Box className="modal-dialoginner">
            <Box className="modal-heading">
              <Box                
                className="close-icon"
                onClick={this.projectCreatedModalClose}
                data-test-id="projectCreatedModalClose"
              >
                <CloseIcon />
              </Box>
            </Box>
            <Box className="modal-description">
              <Box className="project-create-wrapper">
                <Box className="project-img">
                  <img src={projectCreate} alt="projectCreate" />
                </Box>
                <Typography className="modal-title" component="h2">                 
                  {this.t(`${configJSON.projectCreatedMsgTxt}`)}
                </Typography>
                <Box component="p" className="sub-txt">
                  {this.t(`${configJSON.projectCreateSuccessSubTxt}`)}
                </Box>
              </Box>
            </Box>
            <Box className="modal-footer">
              <Button
                className={`secondary-btn ${classes.secondaryButton}`}
                title="Create Another Project"
                onClick={this.createAnotherProject}
              >                
                {this.t(`${configJSON.createAnotherProject}`)}
              </Button>
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                title="Create Tasks"
                onClick={this.taskOpenPopover}
              >
                {this.t(`${configJSON.createTaskTxt}`)}
              </Button>
            </Box>
          </Box>
        </Box>
        </Modal>
      )
    }

    createProjectBlock=(classes:any)=>{
      const popOverOpen = Boolean(this.state.popoverAnchorEl);
      const popoverId = popOverOpen ? "simple-popover" : undefined;
      return(
          <Box className={classes.createTaskWrapper}>
            <Box className="task-actions">
              {this.state.draftProjects.map((draftProject: any) => {
                return (
                    <Button
                      className={`secondary-btn ${classes.secondaryButton}`}
                      key={`draftProject-${draftProject.id}`}
                    >
                      <Box
                        className="draft-name"
                        component="span"
                        onClick={(e: any) => this.openDraftProject(e, draftProject.id)}
                        data-test-id={`draftProject-${draftProject.id}`}
                      >
                        <img
                          className="draft-img"
                          src={fileTree}
                          alt="fileTree"
                        />
                        {draftProject.title == "" ? this.t(configJSON.draftTxt) : draftProject.title}
                      </Box>
                      <Box                      
                        className="close"
                        onClick={(e: any) => this.deleteDraftProject(draftProject.id)}
                        data-test-id={`draftProjectDelete-${draftProject.id}`}
                      >
                        <img src={closeGray} alt="closeGray" />
                      </Box>
                    </Button>
                );
              })}
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                style={{visibility:this.props.showCreateProject==undefined || this.props.showCreateProject?"visible":"hidden"}}
                aria-controls="createProjectEl"
                aria-haspopup="true"
                onClick={this.createProjectOpen}
                data-test-id="createProject"
              >
                + {this.t(`${configJSON.createProjectTxt}`)}
              </Button>
            </Box>
  
            <Menu
              id="createProjectEl"
              anchorEl={this.state.createProjectEl}
              keepMounted
              open={Boolean(this.state.createProjectEl)}
              onClose={this.createProjectClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              className={`changepriority-dropdown ${classes.dropDropdown}`}
            >
              <MenuItem
                aria-describedby={popoverId}
                onClick={this.popoverHandleClick}
                ref={this.popoverRef}
                data-test-id="createNewProject"
              >
                {this.t(`${configJSON.createNewTxt}`)}
              </MenuItem>
              <MenuItem 
              onClick={this.handleOpenUseExisting}
              data-test-id="handleOpenUseExisting"
              >
               {this.t(`${configJSON.useExistingTxt}`)}
              </MenuItem>
            </Menu>
          </Box>
      )
    }

    

    allAssigneeModal=(classes:any)=>{
      const { currentProject } = this.state;
      const projectAssignees = this.getAssigneeNames(currentProject?.attributes?.assignees ?? []);
      return(
      <Modal
        hideBackdrop
        open={this.state.isActiveAllAssignee}
        onClose={this.allAssigneeModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
      >
        <Box
          className={`filter-dialogbox all-assignee-dialogbox ${classes.modalDialogBox}`}
        >
          <Box className="modal-dialoginner">
            <Box className="modal-heading">
              <Box className="filter-heading">
                <Box
                  className="auth-back"
                  onClick={this.allAssigneeModalClose}
                >
                  <i>
                    <svg
                      className="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                    </svg>
                  </i>
                </Box>
                <Typography className="modal-title" component="h2">
                   {currentProject?.attributes.title}
                </Typography>
              </Box>
              <Box              
                className="close-icon"
                onClick={this.allAssigneeModalClose}
                data-test-id="activityLogClose"
              >
                <CloseIcon />
              </Box>
            </Box>
            <Box className="modal-description">
              <Box className="heading-block">
                <Box className="primary-txt">{this.t(`${configJSON.allAssignessTxt}`)}</Box>
                <Box component="p" className="sub-txt">
                  {this.t(configJSON.allAssignessSubTxt, { length: projectAssignees.length })}
                </Box>
              </Box>
              <Box className="modal-form" component="form">
                <Box className="form-info-wrapper">
                  <List className="tasklist-member">
                    <ListSubheader className="tasklist-total">
                      <Box className="total-member">{projectAssignees.length} {this.t(`${configJSON.txtAssignees}`)}</Box>
                      {this.sortByDesign(classes,this.sortByAssigneeHandler)}
                    </ListSubheader>
                    {this.sortAssignees(projectAssignees).map((assignee:any) => {
                      return (
                        <ListItem
                          className="tasklist-li"
                          key={assignee.id}
                          role={undefined}
                          data-test-id={`logAssignee-${assignee.id}`}
                        >
                          <Box
                            className="tasklist-left"
                          >
                            <Box className="assignee-profile">
                              {assignee.initials}
                            </Box>
                            <Box className="assignee-info">
                              <Box className="assignee-name">
                                {assignee.title}
                              </Box>
                              <Box className="assignee-email">
                                {assignee.email}
                              </Box>
                              <Box className="chip-wrapper">
                              {assignee.team
                                  .slice(0, 2)
                                  .map((teamName: string) => {
                                    return (
                                      <Chip
                                        key={teamName}
                                        className={classes.chipButton}
                                        // label="Sales Department"
                                        label={teamName}
                                      />
                                    );
                                  })}

                                {assignee.team.length > 2 && (
                                  <Chip
                                    className={`more-btn ${classes.chipButton}`}
                                    label={`+${
                                      assignee.team.length - 2
                                    } more`}
                                  />
                                )}
                              </Box>
                            </Box>
                          </Box>
                          <ListItemIcon className="tasklist-icon">
                            <Box className="tasklist-hours">
                              10{" "}
                              <Box component="span" className="hours">
                                {this.t(`${configJSON.txtHours}`)}
                              </Box>
                            </Box>
                            {/* <Checkbox
                            className="checklist-check"
                            icon={
                              <img
                                src={checkBoxEmpty}
                                alt="checkBoxEmpty"
                              />
                            }
                            checkedIcon={
                              <img
                                src={checkBoxFilled}
                                alt="checkBoxFilled"
                              />
                            }
                            checked={
                              this.state.logAssignees.indexOf(
                                assignee.id
                              ) !== -1
                            }
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          /> */}
                          </ListItemIcon>
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      )
    }

    projectPopover = (classes:any) => {
      const { popoverAnchorEl,isEditing } = this.state;
    
      const popOverOpen = Boolean(popoverAnchorEl);
      const popoverId = popOverOpen ? "simple-popover" : undefined; 

      return (
      <Popover
        id={popoverId}
        open={popOverOpen}
        anchorEl={popoverAnchorEl}
        onClose={this.popoverHandleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        classes={{ paper: `${classes.createTaskBox}` }}
        data-test-id="create-project-popover"
        onKeyDown={(event) => {this.handleProjectEscEvent(event)}}
      >
        <Box
          onClick={this.popoverHandleClose}
          className="auth-back show-sm"
        >
          <i>
            <svg
              className="MuiSvgIcon-root"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
            </svg>
          </i>
        </Box>
        <Box className="task-heading">
          <Box className="heading-left">
            <Typography className="modal-title" component="h2">
              {isEditing ? this.t(configJSON.editProjectTxt) : this.t(configJSON.createProjectTxt)}
            </Typography>
            <Box component="p" className="sub-txt">
              {isEditing
                ? this.t(configJSON.editProjectSubTxt)
                : this.t(configJSON.letsCreateProjectTxt)}
            </Box>
          </Box>
          <Box className="heading-right">
              <Box
                className="heading-icon hide-sm"
                onClick={this.saveAsDraftProject}
                data-test-id="saveAsDraftProject"
              >
                <img src={minimizeGray} alt="minimizeGray" />
              </Box>
            <Box
              className="heading-icon hide-sm"
              data-test-id="popoverProjectClose"
              onClick={this.popoverHandleClose}
            >
              <img src={closeGray} alt="closeGray" />
            </Box>
          </Box>
        </Box>
        <Box className="task-description">
          <Box className="task-form" component="form">
            <Box className="form-info-wrapper">
              <Box className="form-row">
                <Box className="form-col">
                  <CustomInputWeb
                    type="text"
                    label={this.t(`${configJSON.txtTitle}`)}
                    value={this.state.title}
                    isRequired={true}
                    name="title"
                    onChange={this.handleOnChange}
                    errors={this.state.error.title}
                    data-test-id="crateProjectTitle"
                  />
                </Box>
              </Box>
              <Box className="form-row">
                <Box className="form-col">
                  <FreeSoloCreateOption
                    options={this.state.categoryList.map((val) => {
                      return {
                        id: val.id,
                        title: val.attributes.category,
                      };
                    })}
                    addEditCallback={this.addCategory}
                    addLinkTitle={this.t(`${configJSON.addNewCategoryTxt}`)}
                    noOptionTitle={this.t(`${configJSON.noCategoryFoundTxt}`)}
                    label={this.t(`${configJSON.categoryTxt}`)}
                    id="job-id"
                    onChange={this.categoryChangeHandler}
                    value={this.state.category}
                    errors={this.state.error.category}
                    deleteCallback={this.deleteCategory}
                    errorMsgNoRecord={this.t(`${configJSON.noCategoryCreatedTxt}`)}
                    data-test-id="projectCategory"
                    notRequired={true}
                  />
                    <ConfirmActionModal
                      isOpen={this.state.isDeletingCategory}
                      handleClose={this.deleteCategoryModalClose}
                      modalConfirmAction={this.deleteCategoryApi}
                      deleteTeamId={this.state.deleteCategoryId}
                      modalMessage={this.t(`${configJSON.confirmationTxt}`)}
                      confirmBtnTxt={this.t(`${configJSON.txtDelete}`)}
                      modalHeading={this.t(`${configJSON.deleteCategoryTxt}`)}
                      cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
                      data-testid="confirmDeleteCategory"
                    />
                </Box>
              </Box>
              <Box className="form-row">
                <Box className="form-col col6">
                  <Box className="form-control">
                    <CustomDatePicker
                      placeholder={this.t(`${configJSON.startDateTxt}`)}
                      currentDate={this.state.startDate}
                      onChange={this.changeStartDate}
                      onOpenPickNewDate={false}
                      data-test-id="createProjectstartDate"
                      required={true}
                      error={this.state.error.startDate}
                      t={this.t}
                      inPortal={true}
                      maxDate={this.state.endDate == ""?undefined:this.state.endDate}
                    />
                  </Box>
                </Box>
                <Box className="form-col col6">
                  <Box className="form-control">
                    <CustomDatePicker
                      placeholder={this.t(`${configJSON.endDateTxt}`)}
                      currentDate={this.state.endDate}
                      onChange={this.changeEndDate}
                      onOpenPickNewDate={false}
                      data-test-id="createProjectEnddate"
                      showPastWarning={true}
                      inPortal={true}
                      t={this.t}
                      minDate={this.state.startDate == ""?undefined:this.state.startDate}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="form-row">
                <Box className="form-col">
                  <CustomInputWeb
                    type="text"
                    label={this.t(`${configJSON.txtDescription}`)}
                    isMultiline
                    rows={4}
                    value={this.state.description}
                    name="description"
                    onChange={this.handleOnChange}
                    data-test-id="crateProjectDesc"
                  />
                </Box>
              </Box>
            </Box>
            <Box className="attachments-container">
            <FileDrop
                    onDrop={()=>this.handleOnDropEvent}
                    onDragOver={this.dragEnterEvent}
                    onDragLeave={this.dragLeaveEvent}
                    data-test-id="handleOnDrop"
                  >
                    <Box
                      className={`${classes.fileUploadWrapper} ${this.state
                        .fileDropEvent && classes.fileDropDrag} `}
                      onClick={this.onBrowseEvent}
                      data-test-id="onBrowse"
                    >
                      <form ref={this.formRef}>
                        <input
                          name="upload-photo"
                          accept="*"
                          type="file"
                          onChange={this.handleOnDropEvent}
                          multiple
                          hidden
                          ref={this.fileRef}
                          data-test-id="add-project-attachment"
                        />
                      </form>
                      <Box className="file-upload-block 1">
                        <Box className="file-subtxt" component="p" >
                          {this.t(`${configJSON.clickHereTxt}`)}
                          <Box className="browse-link" component="span">
                            {this.t(`${configJSON.browseTxt}`)}
                          </Box>
                          {this.t(`${configJSON.yourPictureTxt}`)}
                        </Box>
                        <Box className="upload-icon 1">
                          <img src={uploadImg} alt="uploadImg1" />
                        </Box>
                        <Button className={`secondary-btn2 ${classes.secondaryButton}`}>{this.t(`${configJSON.addAttachmentsTxt}`)}
                        </Button>
                      </Box>
                    </Box>
            </FileDrop>
            </Box>
                {this.state.filesUploaded.length > 0 && (
                 <List style={{display:"flex"}}>
                    {this.state.filesUploaded.map((file: any, index: any) => {
                      return(
                      <ListItem key={file.name}>
                    <Box className="form-info-wrapper">         
                    <Box className="upload-list-row">
                        <Box className="upload-col col3">
                  <Box className="upload-inner">
                    <Box className="close-icon" data-test-id="removeFileUPload" onClick={()=>this.removeFileUPloadEvent(index)}>
                      <CloseIcon />
                    </Box>
                    <Box className="upload-img">
                      {getPlaceholderImage(file.type)}
                    </Box>                
                  </Box>
                  <Box className="upload-details">
                    <Box className="img-name">{file.name || file.filename}</Box>
                  </Box>
                       </Box>
                       </Box>
                     </Box>
                      </ListItem>
                    )})}
                  </List>
                )}             
          </Box>
        </Box>
        <Box className="createtask-bottom">
          <Box className="createtask-left">
            <Box className="outerbutton-wrapper">
              <Box                
                className="outer-link"
                onClick={this.projectLeadHandler}
                data-test-id="projectLeadAssignee"
              >
                {this.state.assignees.length > 0 ? (
                  <>
                    <Box className="cancel-link">
                      <CancelIcon
                        onClick={(e: any) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.setState({ assignees: [] });
                        }}
                      />
                    </Box>
                    {/* <Box className="assignee-list"> */}
                    {this.displayAssigneeOptions()
                      .filter((x: any) =>
                        this.state.assignees.includes(x.id)
                      )
                      .slice(0, 4)
                      .map((option: any) => {
                        return (
                          <Box className="assignee-bubble" key={option.id}>
                            {option.initials}
                          </Box>
                        );
                      })}
                    {this.state.assignees.length > 4 && (
                      <Box className="assignee-bubble">
                        +{this.state.assignees.length - 4}
                      </Box>
                    )}
                    {/* </Box> */}
                  </>
                ) : (
                  <img src={accountPlus} alt="accountPlus" />
                )}
              </Box>
            </Box>
          </Box>
          <Box className="createtask-right">
            <Button
              className={`primary-btn ${classes.primaryButton}`}
              onClick={this.createProject}
              data-test-id="saveProjectBtn"
            >
              {isEditing
                ? this.t(`${configJSON.updateProjectTxt}`)
                : this.t(`${configJSON.createProjectTxt}`)}
            </Button>
          </Box>
        </Box>
      </Popover>
      )
    }

    projectTemplateModal=(classes:Record<string,string>)=>{
      let filterdData ;
      if (this.state.isSearching) {
        filterdData = this.state.filteredProjects;
      } else if( this.state.isSorting) {
        filterdData = this.state.sortedProjects;
      } else {
        filterdData = this.state.projectList;
      }
      return(
        <Modal
        open={this.state.openUseExisting}
        onClose={this.handleCloseUseExisting}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
        >
          <Box
            className={`${classes.modalDialogBox} project-template-dialog`}
          >
            <Box className="modal-dialoginner">
              <Box className="modal-heading">
                <Typography className="modal-title" component="h2">
                  {this.t(`${configJSON.chooseProjectTxt}`)}
                </Typography>
                <Box 
                  className="close-icon"
                  data-test-id="handleCloseUseExisting"
                  onClick={this.handleCloseUseExisting}
                >
                  <CloseIcon />
                </Box>
              </Box>
            <Box className="modal-description">
                <Box component="p" className="sub-txt">
                  {this.t(`${configJSON.selectedProjectTxt}`)}
                </Box>
                <Box className="modal-form" component="form">
                  <Box className="form-info-wrapper">
                    <RadioGroup
                      aria-label="templatelist"
                      name="templatelist1"
                    >
                      <List className="template-list">
                        <ListSubheader>
                          <CustomInputWeb
                            placeholder={this.t(`${configJSON.searchTypeTxt}`)}
                            autoFocus={true}
                            name="search"
                            value={this.state.searchQuery}
                            onChange={this.handleSearchChange}
                            data-test-id="handleSearchChange"
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }                         
                          />
                        </ListSubheader>
                        <ListSubheader className="multiselect-membres">
                          <Box className="total-member">{filterdData.length}&nbsp;{filterdData.length>1?this.t(`${configJSON.templatesTxt}`):this.t(`${configJSON.templateTxt}`)}</Box>
                          {this.sortByDesign(classes,this.handleSortChange)}
                        </ListSubheader>
                        {filterdData.map((project:Project) => {
                          return (
                            <ListItem
                              className="template-li"
                              key={project.id}
                              role={undefined}
                              onClick={this.handleTemplateGroupButton(project.id)}
                              data-test-id={`handleTemplateGroupButton-${project.id}`}
                            >
                              <Box
                                className="template-left"
                              >
                                <Box className="name">{project.attributes.title}</Box>
                                <Box className="sub-txt">
                                  {this.t(`${configJSON.createdOnTxt}`)} Aug 30, 2022 11:42PM
                                </Box>
                              </Box>
                              <ListItemIcon className="template-icon">                             
                                <Radio
                                  checked={
                                    this.state.chooseTemplateChecked === project.id
                                  }
                                  tabIndex={-1}
                                />
                              </ListItemIcon>
                            </ListItem>
                          );
                        })}
                      </List>
                    </RadioGroup>
                  </Box>
                </Box>
              </Box> 
              <Box className="modal-footer">
                <Button onClick={ () => {
                  this.duplicateProject();
                  this.handleCloseUseExisting()
                  }
                  } className={`primary-btn ${classes.primaryButton}`} data-test-id="duplicateProject">
                  {this.t(`${configJSON.createProjectTxt}`)}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )
    }

    // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes,isOpen,projectClose } = this.props;

    const { currentProject,popoverAnchorEl } = this.state;
    const assigneesOfProject = this.getAssigneeNames(currentProject?.attributes?.assignees ?? []);
    const projectLead = this.getProjectLead(currentProject);

    //project create edit
    const popOverOpen1 = Boolean(popoverAnchorEl);
    const popoverId1 = popOverOpen1 ? "simple-popover" : undefined; 
    const status = currentProject?.attributes.status;    
    const statusValue = configJSON.projectStatusMap[status] ?? 1; 
    const statusClassName = configJSON.projectClassMap[statusValue];
    const calculateTotalBudget = (actual:number, projected:number) => actual + projected;
    const totalBudget = calculateTotalBudget(
      currentProject?.attributes.actual_budget,
      currentProject?.attributes.projected_budget
    );
    const totalHours = calculateTotalBudget(
      currentProject?.attributes.actual_hours,
      currentProject?.attributes.projected_hours
    );
    const hasProjectStatus = (project:{ attributes:{ status:string } }) => project?.attributes?.status;
    return (
     <>
      <Modal
        className={classes.modalDialog}
        aria-labelledby="modal-modal-title"    
        onClose={()=>{ projectClose(); this.projectDataEmpty();}}
        open={isOpen}
        aria-describedby="modal-modal-description"
         data-test-id="viewProjectCloseFirst"
        
      >
        <Box className={`view-modal-dialog ${classes.modalDialogBox}`}>
          <Box className="modal-heading">
            <Box className="heading-links">
            <Box className="heading-link show-sm">
                <Box
                  data-testid="viewTaskClose"
                  onClick={()=>{ projectClose(); this.projectDataEmpty();}}    
                  data-test-id="viewProjectCloseSec"              
                  className="auth-back icon"
                >
                  <i>
                    <svg
                      className="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                    </svg>
                  </i>
                </Box>
              </Box>  
              <Box className="heading-link hide-sm">
                <Box
                  data-test-id="viewEditProject"
                  aria-describedby={popoverId1}
                  onClick={this.editProjectOpen}                  
                  className="icon"
                >
                  <img src={editGray} alt="editGray" />
                </Box>
              </Box>
              <Box className="heading-link hide-sm">
                <Box  className="icon" onClick={this.getTimesheetModalOpen}  data-test-id="getTimesheet">
                  <img src={contentSave} alt="contentSave" />
                </Box>
              </Box>
              <Box className="heading-link hide-sm">
                <Box                 
                  className="icon"
                  data-test-id="duplicateProject"
                  onClick={this.duplicateProject}
                >
                  <img src={copyGray} alt="copyGray" />
                </Box>
              </Box>
              <Box className="heading-link">
                <Box                 
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={this.handleMenuOpen}
                  className="dropmenu-btn"
                  data-test-id="viewMenuOpen"
                >
                 <img src={dotsHorizontal} alt="dotsHorizontal" className="hide-sm" />
                 <img src={dotVerticle} alt="dotVerticle" className="show-sm" />
                </Box>
                <Menu
                  keepMounted
                  getContentAnchorEl={null}
                  open={Boolean(this.state.anchorEl)}
                  className={classes.dropDropdown}
                  onClose={this.handleMenuClose}
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    horizontal: "left",
                    vertical: "bottom",
                  }}
                  transformOrigin={{
                    horizontal: "left",
                    vertical: "top",
                  }}
                  id="simple-menu"
                >
                  <MenuItem className="show-sm" onClick={this.editProjectOpen} data-test-id="viewEditProject">
                   {this.t(`${configJSON.editTxt}`)}
                  </MenuItem>
                  <MenuItem className="show-sm" 
                    data-test-id="duplicateProject"
                    onClick={this.duplicateProject}
                    >
                    {this.t(`${configJSON.duplicateProjectTxt}`)}
                  </MenuItem>
                  <MenuItem className="show-sm">                    
                    {this.t(`${configJSON.saveAsTemplate}`)}
                  </MenuItem>
                  {/* <MenuItem onClick={this.getTimesheetModalOpen} data-test-id="getTimesheet">
                    {this.t(`${configJSON.getTimesheetTxt}`)}
                  </MenuItem> */}
                  <MenuItem onClick={this.tasklistOpenPopover}  data-test-id="createTasklist">                    
                    {this.t(`${configJSON.craeteTaskListTxt}`)}
                  </MenuItem>
                  <MenuItem
                    onClick={this.taskOpenPopover}
                    data-test-id="createTaskFromProject"
                  >
                    {this.t(`${configJSON.txtCreateTask}`)}
                  </MenuItem>
                  <MenuItem
                    onClick={this.deleteProjectHandler}
                    data-test-id="viewDeleteProject"
                  >                    
                    {this.t(`${configJSON.deleteProjectTxt}`)}
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
            <Box
              className="close-icon hide-sm"
              onClick={()=>{ projectClose(); this.projectDataEmpty();}}
              data-test-id="viewProjectClose"
            >
              <CloseIcon />
            </Box>
          </Box>
          <Box className="modal-description">
            <Box className="task-subtitle">
              {currentProject?.attributes.category?.category}
            </Box>
            <Typography className="modal-title" component="h2">
              {currentProject?.attributes.title}
            </Typography>
            <Box className="task-progress-block">
              <Box className="progress-info">
                <Box className="prec-value">88% {this.t(`${configJSON.txtStatusCompleted}`)}</Box>
                <Box className="total-value">38:25:00 / 50:00:00</Box>
              </Box>
              <Box className="progress-wrap">
                <Box className="progress-inner" style={{ width: "88%" }}>
                  <Box
                    className="current-progress"
                    style={{ width: "15%" }}
                  ></Box>
                </Box>
              </Box>
            </Box>
            <List className="taskview-list">
              <ListItem>
                <Box className="list-label">{this.t(`${configJSON.txtStatus}`)}</Box>
                <Box className="list-description">
                  <FormControl className="select-control status-control">
                    {hasProjectStatus(currentProject) && <Select
                      IconComponent={ExpandMoreIcon}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        classes: {
                          paper: `${classes.dropdownStyle} modal-dropdown`,
                        },
                      }}
                      onChange={this.projectChangeStatus}
                      displayEmpty
                      value={this.state.projectStatus}
                      //defaultValue={2}
                      data-test-id="projectStatusChange"
                      className={`${statusClassName} MuiSelect-select`}     
                    >
                      <MenuItem value={0}>{this.t(`${configJSON.txtStatusOpen}`)}</MenuItem>
                      <MenuItem value={1}>{this.t(`${configJSON.onTrackTxt}`)}</MenuItem>
                      <MenuItem value={2}>{this.t(`${configJSON.offTrackTxt}`)}</MenuItem>
                      <MenuItem value={3}>{this.t(`${configJSON.txtStatusCompleted}`)}</MenuItem>
                    </Select>}
                  </FormControl>
                </Box>
              </ListItem>
              <ListItem>
                <Box className="list-label">{this.t(`${configJSON.txtAssignees}`)}</Box>
                <Box className="list-description">
                  <Box className="assignee-list">
                    
                    {/* <Box className="assignee-bubble more-bubble" onClick={this.allAssigneeModalOpen}>+5</Box> */}
                    {assigneesOfProject.slice(0, 4).map((assignee:any)=>{
                      return(
                        <Box className="assignee-bubble" key={assignee.id}>{assignee.initials}</Box>
                      )
                    })}
                      {assigneesOfProject.length > 4 && (
                      <Box className="assignee-bubble more-bubble" 
                       onClick={this.allAssigneeModalOpen}
                       data-test-id="showMoreAssignee"
                       >
                        +{assigneesOfProject.length - 4}
                      </Box>
                    )}

                  </Box>
                </Box>
              </ListItem>
              <ListItem className="date-item-root">
                <Box className="list-label">{this.t(`${configJSON.txtDates}`)}</Box>
                <Box className="list-description">
                  <Box className="date-info">
                    <Box className="label">{this.t(`${configJSON.startDateTxt}`)}: </Box>
                    <Box className="value">
                      {currentProject?.attributes.start_date
                        ? moment(currentProject?.attributes.start_date).format(
                            "ddd, MMM DD, YYYY"
                          )
                        : ""}
                      <img
                        className="icon"
                        src={calendarViewIcon}
                        alt="calendarViewIcon"
                      />
                    </Box>
                  </Box>
                  <Box className="date-info">
                    <Box className="label">{this.t(`${configJSON.endDateTxt}`)}: </Box>
                    <Box className="value">
                      {/* Mar 30, 2022 */}
                      {currentProject?.attributes.end_date
                        ? moment(currentProject?.attributes.end_date).format(
                            "ddd, MMM DD, YYYY"
                          )
                        : ""}
                      <img
                        className="icon"
                        src={calendarViewIcon}
                        alt="calendarViewIcon"
                      />
                    </Box>
                  </Box>
                </Box>
              </ListItem>
              <ListItem>
                <Box className="list-label">{this.t(`${configJSON.projectLeadTxt}`)}</Box>
                <Box className="list-description">{projectLead}</Box>
              </ListItem>
            </List>
            <Box className="tasklist-tabwrapper">
              <Box>
                <Tabs
                  aria-label="simple tabs example"
                  value={this.state.viewTabsValue}
                  onChange={this.setViewTabsValue}
                  data-test-id="viewTaskBottomTabs"
                >
                  <Tab label={this.t(`${configJSON.txtDescription}`)} {...viwA11yProps(0)} />
                  <Tab label={this.t(`${configJSON.budgetTxt}`)}  {...viwA11yProps(1)} />
                  <Tab label={this.t(configJSON.attacmentsTxt , { count: currentProject?.attributes?.attachments_url.length })} {...viwA11yProps(2)} />
                  <Tab label={this.t(`${configJSON.activityLogsTxt}`)} {...viwA11yProps(3)} />
                </Tabs>
              </Box>
              <TabPanel value={this.state.viewTabsValue} index={0}>
                <Box className="description-box">
                  {currentProject?.attributes.description}
                </Box>
              </TabPanel>
              <TabPanel value={this.state.viewTabsValue} index={1}>
                <Box className="budget-wrapper">
                  <Box className="budget-main">
                    <Box className="slider-wrap">
                      <Box className="slider-heading">
                        <Box className="slider-label">{this.t(`${configJSON.budgetTxt}`)}
                        <TruncatedTextWithTooltip classes={this.props.classes} icon={warningIcon} title={this.t(`${configJSON.tooltip1}`)} />
                        </Box>
                      
                        <Box className="legends-wrapper">
                          <Box className="slider-legends">
                            <Box className="legends-label">{this.t(`${configJSON.actualTxt}`)}</Box>
                            <Box className="legends-dots actual"></Box>
                          </Box>
                          <Box className="slider-legends">
                            <Box className="legends-label">{this.t(`${configJSON.projectedTxt}`)}</Box>
                            <Box className="legends-dots"></Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="slider-inner">
                        <Box className="progress-wrap">
                          <Box
                            className="progress-inner"
                            style={{
                              width: `${(currentProject?.attributes.actual_budget / totalBudget) * 100}%`,
                            }}
                          ></Box>
                          <Box
                            className="progress-label"
                            style={{
                              left: `${(currentProject?.attributes.projected_budget / totalBudget) * 100}%`,
                            }}
                          >
                            {currentProject?.attributes?.projected_budget}
                          </Box>
                        </Box>
                        <Box className="max-value">
                          {totalBudget}
                        </Box>
                      </Box>                       
                    </Box>
                    <Box className="slider-wrap overflow-count">
                      <Box className="slider-heading">
                        <Box className="slider-label">{this.t(`${configJSON.txtHours}`)}                      
                        <TruncatedTextWithTooltip classes={this.props.classes} icon={warningIcon} title={this.t(`${configJSON.tooltip2}`)} />
                        </Box>
                        <Box className="legends-wrapper">
                          <Box className="slider-legends">
                            <Box className="legends-label">{this.t(`${configJSON.projectedTxt}`)}</Box>
                            <Box className="legends-dots"></Box>
                          </Box>
                          <Box className="slider-legends">
                            <Box className="legends-label">{this.t(`${configJSON.actualTxt}`)}</Box>
                            <Box className="legends-dots actual"></Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="slider-inner">
                        <Box className="progress-wrap">
                          <Box
                            className="progress-inner"
                            style={{
                              width: `${(currentProject?.attributes.actual_hours / totalHours) * 100}%`,
                            }}
                          ></Box>
                          <Box
                            className="progress-label"
                            style={{
                              left: `${(currentProject?.attributes.projected_hours / totalHours) * 100}%`,
                            }}
                          >
                            {currentProject?.attributes?.actual_hours}
                          </Box>
                        </Box>
                        <Box className="max-value">
                          {totalHours}
                        </Box>
                      </Box>
                    </Box>
                    <Accordion className="accordion-block">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="budget-content"
                        id="budget-header"
                        className="accordion-heading"
                      >
                        <Box className="heading">{this.t(`${configJSON.fullDetailsTxt}`)}</Box>
                        <Box className="sub-txt">
                          {this.t(`${configJSON.fullDetailsSubTxt}`)}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails className="accordion-details">
                        <TableContainer>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  colSpan={3}
                                  className="table-heading"
                                  component="th"
                                  scope="row"
                                >                                  
                                  {this.t(`${configJSON.laborCostTxt}`)}
                                </TableCell>
                              </TableRow>
                              <TableRow>                              
                                <TableCell>{currentProject?.attributes?.labor_data.labor_count} {this.t(`${configJSON.employeesTxt}`)}</TableCell>
                                <TableCell>{currentProject?.attributes?.labor_data.actual_labor_hours} {this.t(`${configJSON.hoursTxt}`)}</TableCell>
                                <TableCell>&#163;{currentProject?.attributes?.labor_data.actual_labor_cost}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  colSpan={3}
                                  className="table-heading"
                                  component="th"
                                  scope="row"
                                >                                  
                                  {this.t(`${configJSON.inventoryCostTxt}`)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  {this.t(`${configJSON.machineryItemTxt}`)} 
                                  <Box className="open-icon">
                                    <OpenInNewIcon />
                                  </Box>
                                </TableCell>
                                <TableCell>{currentProject?.attributes?.machinery_data.actual_machinery_hours} {this.t(`${configJSON.hoursTxt}`)}</TableCell>
                                <TableCell>&#163;{currentProject?.attributes?.machinery_data.actual_machinery_cost}</TableCell>
                              </TableRow>
                              <TableRow className="last-row">
                                <TableCell
                                  className="table-heading last"
                                  component="th"
                                  scope="row"
                                >
                                  {this.t(`${configJSON.expendituresText}`)}
                                </TableCell>
                                <TableCell colSpan={2}>&#163;{currentProject?.attributes?.expenditure}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box className="budget-footer">
                    {this.t(`${configJSON.totalCostActual}`)}:<Box component="span" className="price">&#163;1000</Box>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={this.state.viewTabsValue} index={2}>
              {currentProject?.attributes?.attachments_url.length===0?
               <List
                  className="attachments-list-default-view"              
                >
                 <img src={attacmentsIcon} className="attacments-icon" alt="attachmentImg1"/>
                 <Typography className="no-attachment-text 1">
                 {this.t(`${configJSON.notAttachmentTest}`)}
                 </Typography>
                 <Typography className="no-attachment-sub-text 1">
                 {this.t(`${configJSON.subTextForDEfaultAttaments}`)}
                 </Typography>
                 <FileDrop>
                    <Box
                      data-test-id="onBrowse"
                      onClick={this.onBrowseEvent}
                    >
                      <form ref={this.formRef}>
                        <input
                          name="upload-photo"
                          accept="*"
                          onChange={this.handleOnDropEvent}
                          type="file"
                          hidden
                          multiple
                          data-test-id="add-project-attachment"
                          ref={this.fileRef}
                        />
                      </form>
                      <Box className="file-upload-block 1">                       
                        <Button
                          onClick={this.handleUpload}
                          className={`secondary-btn ${classes.secondaryButton}`}                         
                        >
                          + {this.t(`${configJSON.selecteFileTxt}`)}
                        </Button>                        
                      </Box>
                    </Box>
                </FileDrop>                
                </List>: <List
                  className="attachments-list"  
                  subheader={
                    <ListSubheader
                    id="nested-list-subheader"
                    className="subheader-title"
                    >
                      <Box className="title 1">{this.t(`${configJSON.attachmentsTxt}`)}</Box>
                  <FileDrop>
                    <Box
                      onClick={this.onBrowseEvent}                   
                      data-test-id="onBrowse"
                    >
                      <form ref={this.formRef}>
                        <input
                          name="upload-photo"
                          accept="*"
                          onChange={this.handleOnDropEvent}                          
                          type="file"
                          multiple
                          hidden
                          data-test-id="add-project-attachment"
                          ref={this.fileRef}
                        />
                      </form>
                      <Box className="file-upload-block 1">                       
                      <Box className="tasklist-link 1" onClick={this.handleUpload}>
                        + {this.t(`${configJSON.uploadTxt}`)}
                      </Box>                     
                      </Box>
                    </Box>
                   </FileDrop>
                    </ListSubheader> 
                  }             
                >
                  {currentProject?.attributes?.attachments_url.map((item:{
                      id: number;
                      filename: string;
                      content_type: string;
                      byte_size: number;
                      created_at: string;
                      url: string;
                    })=>{
                    return <ListItem  key={item.id}  className="list-item">
                    <Box className="list-img">
                      <img alt="imgAttachment" src={item.url ? item.url : attachmentImg1 } />
                    </Box>
                    <Box className="list-name">
                    {item.filename}
                    </Box>
                    <ListItemSecondaryAction className="list-secondary">
                      <IconButton aria-label="delete" data-test-id="attachmentRemove" edge="end" onClick={()=>this.attachmentRemove(item.id.toString())}>
                        <CloseIcon/>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  })}
                </List>}
              
              </TabPanel>
              <TabPanel value={this.state.viewTabsValue} index={3}>
                <ActivityLog 
                classes={classes}
                activityType="project"
                activityId={this.state.currentProject?.id}
                activityTitle={this.state.currentProject?.attributes?.title}
                />
              </TabPanel>
            </Box>
            <Box className="tasklist-tabwrapper">
              <Box>
                <Tabs
                  aria-label="simple tabs example"
                  value={this.state.projectTaskTabsValue}
                  onChange={this.setProjectTaskTabsValue}
                  data-test-id="projectTaskBottomTabs"
                >
                  <Tab label={this.t(`${configJSON.tasklistsTxt}`)} {...viwA11yProps(0)} />
                  <Tab label={this.t(`${configJSON.txtTasks}`)} {...viwA11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={this.state.projectTaskTabsValue} index={0}>
              {this.state.projectTaskList.length > 0 ? (
                  this.state.projectTaskList.map((tasklist: any) => {
                    return (
                      <Box className="tasklist-task-wrap" key={tasklist.id}>
                        <Box className="task-heading">
                          <Box className="heading">
                            {tasklist.attributes.name}
                          </Box>
                          <Box className="total-task">{tasklist.attributes.tasks.data.length} {tasklist.attributes.tasks.data.length>1 ? this.t(`${configJSON.txtTasks}`) : this.t(`${configJSON.txtTask}`)}</Box>
                        </Box>

                        <List className="tasklist-task-ul">
                          {tasklist.attributes.tasks.data.map((task:any) => {
                             const formatHours = hoursToSeconds(task.attributes.hours);
                             const isTimerRunning = this.state.taskTimers[task.id] || false;     
                            return (
                              <ListItem
                                onClick={this.handleTaskListTasks(task.id)}
                                dense
                                role={undefined}
                                key={task.id}
                                className="tasklist-task-li"
                              >
                                <Box className="tasklist-inner">
                                  <ListItemIcon className="tasklist-icon">
                                    {task.attributes.current_status === "complete"? <img src={greenCheck} alt="check" /> :  <img src={grayCheck} alt="check" />}
                                  </ListItemIcon>
                                  <Box className="tasklist-label">
                                    <Box className="task-title">
                                      <Box className={task.attributes.status === "completed"?"title-dash":"title"}>{task.attributes.title}</Box>
                                      <Box className="title-right">
                                        <Box className="controller-link">  
                                        {renderTimerIcon(
                                          task.id,
                                          task.attributes.status,
                                          isTimerRunning,
                                          this.startTaskTimerAPI
                                        )}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="task-desc">
                                      <Box className="desc-left">
                                        <Box className="date" component="span">
                                        {task.attributes.due_date
                                          ? moment(
                                              task.attributes.due_date
                                            ).format("MMM DD , YYYY")
                                          : ""}
                                        </Box>
                                      </Box>
                                      <Box className="desc-right">
                                        <Box
                                          className="timing"
                                          component="span"
                                        >
                                          {formatTimeTaskHours(task.attributes.duration)}/{formatTimeTaskHours(formatHours)}
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Box>
                    );
                  })
                ) : (
                  <Box className="no-task-block">
                    <img
                      src={formatTreeBlue}
                      alt="formatTreeBlue"
                      className="task-img"
                    />
                    <Box className="heading">{this.t(`${configJSON.noTasklistsTxt}`)}</Box>
                    <Box className="sub-txt">
                    {this.t(`${configJSON.notTsaklistSubTxt}`)}
                    </Box>
                    <Box className="button-wrapper full-btn">
                      <Button
                        className={`secondary-btn ${classes.secondaryButton}`}
                        title="+ Create Tasklists"
                        onClick={this.tasklistOpenPopover}
                      >
                        + {this.t(`${configJSON.createTstklistTxt}`)}
                      </Button>
                    </Box>
                  </Box>
                )}
              </TabPanel>
              <TabPanel value={this.state.projectTaskTabsValue} index={1}>
                <Button data-test-id="getProjectTasksByTab" onClick={()=>{this.startTaskTimerAPI("1","started"); this.getProjectTasksByTab()}} style={{display:"none"}}></Button>
                {currentProject?.attributes.tasks.data.length > 0
                ?this.showTaskByTab()
                :
                <Box className="no-task-block">
                  <img
                    src={fileTreeBlue}
                    alt="fileTreeBlue"
                    className="task-img"
                  />
                  <Box className="heading">{this.t(`${configJSON.noTasksTxt}`)}</Box>
                  <Box className="sub-txt">{this.t(`${configJSON.noTasksSubTxt}`)}</Box>
                  <Box className="button-wrapper full-btn">
                    <Button
                      className={`secondary-btn ${classes.secondaryButton}`}
                      title="+ Create Tasks"
                      onClick={this.taskOpenPopover}
                    >
                      + {this.t(`${configJSON.createTaskTxt}`)}
                    </Button>
                  </Box>
                </Box>
                }
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </Modal> 
      
        {this.projectLeadModal(classes)}
        {this.deleteProjectModal()}
        {this.projectCreatedModal(classes)}
        {this.createProjectBlock(classes)}
        {this.timeSheetModal(classes)}
        {this.timesheetChooseAssigneesModal(classes)}
        {this.allAssigneeModal(classes)}
        {this.projectPopover(classes)}
        {this.projectTemplateModal(classes)}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(ProjectView);
// Customizable Area End
