import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { toast } from "react-toastify";
import {minimizeDeal, renameOutline, copyIcon, deleteIcon} from './assets';
import i18n from "../../../web/src/utilities/i18n";
import { DateObject } from "react-multi-date-picker";
import {Box, CardMedia} from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  isMobile?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabsValue: any;
  popoveAnchorEl: any;
  popoverEditDealEl: any;
  isFileValidType: boolean;
  isCancel: boolean;
  isPause: false;
  isLoading: boolean;
  filesUploaded: File[];
  membersList: any;
  fileDrop: boolean;
  isSaveDraft: boolean;
  isMenuDrawerOpened: boolean;
  anchorEl: any;
  contactEl:any;
  tableMenuEl: any;
  isActiveChooseContact: boolean;
  createDealPopoverShown: boolean;
  editDealPopoverShown: boolean;
  floatCreateDeal: boolean;
  floatCreateContact: boolean;
  dealOwnerModal: boolean;
  isActiveChooseStage: boolean;
  newStatus: number | undefined | null;
  dealsListing: any;
  openViewDetailModal: boolean;
  isActiveChooseDeal: boolean;
  isActiveContact: boolean;
  isEditContact: boolean;
  openProductsModal: boolean;
  dealOwners: any;
  contactList: any;
  selectedOwner: any;
  selectedContacts: any;
  dealName: string;
  dealCloseDate: any;
  expectedDateRange: DateObject[],
  dealFilterDate:any;
  dealDescription: string;
  dealStage: string;
  dealFiles: any;
  dealOwner: string;
  dealContact: string;
  dealProduct: any;
  viewSingleDealId: string;
  menuDealId: string;
  menuDealName: string;
  searchQueryVal: string;
  isSearching: boolean;
  currentDealStage: {
    currentdealId: string;
    currentdealStage: number | undefined;
  };
  addDealNote: string;
  updateDeal: {
    dealStage: string;
    dealNote: string;
    dealId: string;
  };
  createDealError: {
    dealNameError: string;
    dealOwnerError: string;
    dealContactError: string;
  };
  editDealDetails: {
    dealId: string;
    dealName: string;
    dealDescription: string;
    dealCloseDate: any;
    dealStage: any;
    dealNote: string;
    dealProducts: any;
    dealContacts: any;
    dealAttachments:any;
    edittotalAmount:any;
    productIds:any;
    dealOwner:any;
    dealOwnerSelected:any;
  };
  editAttachments: any;
  editAttachmentsNew:any;
  openDeleteModal: boolean;
  searchResults: any;
  filterStageVal: string;
  filterCloseDateType:string;
  dealContacts: any;
  sortBySelectedVal: string;
  filterDealOwnerVal: string;
  filterContactVal: string;
  chosenDealProductIds: any;
  productAmount: number|null;
  chosenProductsData:[];
  viewSelectedProducts: boolean;
  anchorElMore: any;
  anchorElCloseDate:any;
  anchorDealAmount: any;
  hasAttachment: boolean;
  hasTasksDeals: boolean;
  hasAssociatedProduct: boolean;
  showDealNoteInput: boolean;
  contactsSearchTxt: string;
  dealAmountRange: number[];
  minValueAmount: number;
  maxValueAmount: number;
  showCreateContact: boolean;
  isAmountFilter: boolean;
  isCloseDateFilter: boolean;
  dealsPerPage: number;
  currentPage:number,
  dealsTabCount: any;
  editDealId: string;
  selectedDealId: string;
  propData:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class LeadManagementControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  welcomeTitle: string;
  welcomeHyperTitle: string;
  welcomeButtonText: string;

  fileRef: React.RefObject<HTMLInputElement>;
  formRef: React.RefObject<HTMLFormElement>;
  attachListLabelRef:React.RefObject<HTMLFormElement>;
  getDealOwnersRequestId: string = "";
  getAllDealsRequestId: string = "";
  createDealRequestAPICallId: string = "";
  viewSingleDealIdAPICallId: string = "";
  editDealAPICallId: string = "";
  removeDealAttachmentId:string = "";
  duplicateDealAPICallId: string = "";
  getContactListRequestId: string = "";
  deleteDealAPICallId: string = "";
  updateDealStatusAPICallId: string = "";
  updateDealAPICallId: string = "";
  searchDealAPICallId: string = "";
  getDealsTabCountsAPICallId: string = "";
  undoDealActionsAPICallId: string = "";
  userSessionData: any;
  userToken: any;
  userRole: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.welcomeTitle = configJSON.welcomeTitle;
    this.welcomeHyperTitle = configJSON.welcomeHyperTitle;
    this.welcomeButtonText = configJSON.welcomeButtonText;

    this.fileRef = React.createRef();
    this.formRef = React.createRef();
    this.attachListLabelRef = React.createRef();
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseData),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      tabsValue: "",
      popoveAnchorEl: null,
      popoverEditDealEl: null,
      isFileValidType: true,
      isCancel: false,
      isPause: false,
      isLoading: false,
      filesUploaded: [],
      membersList: [],
      fileDrop: false,
      isSaveDraft: false,
      isMenuDrawerOpened: false,
      anchorEl: null,
      contactEl: null,
      tableMenuEl: null,
      isActiveChooseContact: false,
      openProductsModal: false,
      createDealPopoverShown: true,
      editDealPopoverShown: false,
      floatCreateDeal: false,
      floatCreateContact: false,
      dealOwnerModal: false,
      isActiveChooseStage: false,
      isActiveContact: false,
      isEditContact: false,
      newStatus: null,
      dealsListing: [],
      openViewDetailModal: false,
      isActiveChooseDeal: false,
      dealOwners: [],
      contactList: [],
      selectedOwner: [],
      selectedContacts: [],
      dealName: "",
      dealCloseDate: "",
      expectedDateRange: [],
      dealFilterDate: "",
      dealDescription: "",
      dealStage: "0",
      dealFiles: "",
      dealOwner: "",
      dealContact: "",
      dealProduct: "",
      viewSingleDealId: "",
      menuDealId: "",
      menuDealName: "",
      searchQueryVal: "",
      isSearching:false,
      currentDealStage: {
        currentdealId: "",
        currentdealStage: 0,
      },
      addDealNote: "",
      updateDeal: {
        dealStage: "",
        dealNote: "",
        dealId: "",
      },
      createDealError: {
        dealNameError: "",
        dealOwnerError: "",
        dealContactError: "",
      },
      openDeleteModal: false,
      editDealDetails: {
        dealId: "",
        dealName: "",
        dealDescription: "",
        dealCloseDate: "",
        dealStage: "",
        dealNote: "",
        dealProducts: [],
        dealContacts: [],
        dealAttachments: [],
        edittotalAmount: "",
        productIds:[],
        dealOwner:[],
        dealOwnerSelected: [],
      },
      editAttachments: [],
      editAttachmentsNew:[],
      searchResults: [],
      filterStageVal: "",
      filterCloseDateType: "on_or_before",
      dealContacts: [],
      sortBySelectedVal: "",
      filterDealOwnerVal: "",
      filterContactVal: "",
      chosenDealProductIds: [],
      productAmount: 0,
      chosenProductsData:[],
      viewSelectedProducts: false,
      anchorElMore: null,
      anchorElCloseDate:null,
      anchorDealAmount: null,
      hasAttachment: false,
      hasTasksDeals: false,
      hasAssociatedProduct: false,
      showDealNoteInput: false,
      contactsSearchTxt: '',
      dealAmountRange: [0, 9999],
      minValueAmount: 0,
      maxValueAmount: 9999,
      showCreateContact: false,
      isAmountFilter: false,
      isCloseDateFilter: false,
      dealsPerPage: 20,
      currentPage:0,
      dealsTabCount: [],
      editDealId: "",
      selectedDealId: "",
      propData:{}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.userRole = this.userToken.data.attributes.job_type;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
     // Customizable Area Start
     if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse || !responseJson || !apiRequestCallId) {
        this.createToastNotificationError("Backend down");
        this.setState({ isLoading: false });
        return;
      }
      switch (apiRequestCallId) {
        case this.getDealOwnersRequestId:
         
          let ownersListings = responseJson.members.data;
          this.setState({ dealOwners: ownersListings }, () =>
            this.getCurrentOwner(ownersListings)
          );
          break;

        case this.getAllDealsRequestId:
          this.setState({ isLoading: false });
          this.setState({ dealsListing: responseJson.data }, () => { this.getDealsCountTabs() });
          
          break;

        case this.createDealRequestAPICallId:
          this.setState({ isLoading: false });
          toast.success('Deal Created', {
            position: toast.POSITION.TOP_CENTER,
            icon: () => <img src={minimizeDeal} alt="emptydata" />,
          });
          this.popoverHandleClose();
          this.getAllDeals();
          this.clearAllInputs();
          break;

        case this.duplicateDealAPICallId:
          this.setState({ isLoading: false });
          this.getAllDeals();
          this.closeViewDetails();
          this.tableMenuClose();
          this.createToastNotificationUndo("Deal Duplicated", "duplicate", copyIcon, responseJson.data.id)
          break;

        case this.updateDealStatusAPICallId:
          this.setState({ isActiveChooseStage: false });
          this.setState({ isLoading: false });
          this.createToastNotificationUndo("Deal Updated", "update", renameOutline, this.state.currentDealStage.currentdealId)
          this.getAllDeals();
          break;

        case this.deleteDealAPICallId:
          this.setState({ isLoading: false });
          this.getAllDeals();
          this.closeDeleteModal();
          this.closeViewDetails();
          this.tableMenuClose();
          this.createToastNotificationUndo("Deal Deleted", "delete", deleteIcon, this.state.selectedDealId)
          break;

        case this.getContactListRequestId:
          this.setState({
            isLoading : false
          })
          this.setState({ contactList: responseJson.data });
          break;
        case this.editDealAPICallId:
          this.editDealAPIHandler(responseJson)
          
          break;
        case this.updateDealAPICallId:
          this.getAllDeals();
          this.createToastNotificationUndo("Deal Edited", "update", renameOutline, this.state.selectedDealId)
          this.setState({editDealDetails : 
            {
                dealId: "",
            dealName: "",
            dealDescription: "",
            dealCloseDate: "",
            dealStage: 0,
            dealNote: "",
            dealProducts:[],
            dealContacts:[],
            dealAttachments:[],
            edittotalAmount: "",
            productIds:[],
            dealOwner: "",
            dealOwnerSelected: [],
          }, 
        editAttachments: [], editAttachmentsNew: []})
          this.setState({ isLoading: false });
          this.setState({editDealPopoverShown : false})
          break;
        case this.searchDealAPICallId: {
         
          this.setState({isLoading: false})
          this.setState({searchResults : responseJson.data, anchorDealAmount: null}) 
        }
        break;
        case this.getDealsTabCountsAPICallId:
          this.setState({
            isLoading: false,
            dealsTabCount : responseJson.marketings
          });
          break;
        case this.undoDealActionsAPICallId:
          this.getAllDeals();
          this.getDealsCountTabs();
          this.setState({isLoading: false})
          break;
        case this.removeDealAttachmentId:
          this.handleRemoveDealAttachments(responseJson);
        default:
          // Handle any other cases if needed
          break;
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const propData = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
      this.setState({propData:propData.itemsToSendDeals});     
    }
    // Customizable Area End
    }
  
   // Customizable Area Start
   async componentDidMount() {
    this.getAllDeals();
    this.getAllDealOwners();
    this.getAllContacts();
    this.getDealsCountTabs();
    const lang = localStorage.getItem("lang") ?? "en";
    await i18n.changeLanguage(lang); 
    if (new URLSearchParams(window.location.search).get('isModal') === "true") {
      const fakeElement = document.createElement('div');
      fakeElement.style.position = 'absolute';
      fakeElement.style.top = '50px';
      fakeElement.style.left = '100px';
      document.body.appendChild(fakeElement);
      this.setState({ createDealPopoverShown: true ,popoveAnchorEl:fakeElement});
    }     
   
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  async componentDidUpdate(prevProps: Props, prevState: S) {
    
    if (prevState.dealName !== this.state.dealName) {
      this.setState({
        createDealError: {
          ...this.state.createDealError,
          dealNameError: "",
        },
      });
      
    }
    
    
  }
  createToastNotificationError = (toastMesssage: string) => {
    toast.error(toastMesssage, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  createToastNotificationSuccess = (toastMessage: string) => {
    toast.success(toastMessage, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  createToastNotificationUndo = (toastMessage: string, actionType:string, toastIcon:string, undoId: string) => {
    const toastOptions = {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
      icon: () => <img src={toastIcon} alt={toastIcon} />,
    };
    toast.success(
      <Box className="toast-notification">
        <Box className="notification-txt">{toastMessage}</Box>
        <a href="#" data-test-id="undoApi" className="toast-link" onClick={() => {this.undoDealsOperation(actionType, undoId)}}>
          {this.t("undo")}
        </a>
      </Box>,
        toastOptions,
    );
      
    };
  dealLabeltoVal = (label: string) => {
    if (label === "Initiated") {
      return 0;
    } else if (label === "sent proposal") {
      return 1;
    } else if (label === "follow up") {
      return 2;
    } else if (label === "won") {
      return 3;
    } else if (label === "lost") {
      return 4;
    }
  };
  getCurrentOwner = (ownersListings: any) => {
    let selected = ownersListings.find(
      (owner: any) => owner.id === this.userToken.data.id
    );
    this.setState({ selectedOwner: selected });
  };
  getAllDealOwners = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDealOwnersRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dealOwnersEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllDeals = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllDealsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewDealsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );

   

    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllContacts = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = configJSON.viewContactsPath;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactListRequestId = requestMessage.messageId;

    //* Adding endpoint
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    //* Adding Request Method
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );

    //* Making Network Request
    this.setState({
      isLoading: true
    })
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createDealHandler = () => {
    let errorFlag = false;
    const {
      dealName,
      dealDescription,
      dealStage,
      dealCloseDate,
      selectedOwner,
      selectedContacts,
      filesUploaded,
      productAmount,
      expectedDateRange
    } = this.state;
    let dealNameError = this.state.createDealError.dealNameError;
    let dealContactError = this.state.createDealError.dealContactError;
    if (dealName.trim() === "") {
      dealNameError = "Please enter deal name";
      errorFlag = true;
    }
    
   
    if(selectedContacts.length === 0){
      dealContactError = "Please select deal contact";
      errorFlag = true;
        }
    if (errorFlag) {
      this.setState({
        createDealError: {
          dealNameError: dealNameError,
          dealOwnerError: "Please select deal owner",
          dealContactError: dealContactError,
        },
      });
      return true;
    }
    let startDate = "";
    let endDate = "";
   
    const formData = new FormData();
    formData.append("data[name]", dealName);
    if (expectedDateRange.length === 2) {
      startDate = expectedDateRange[0].format("YYYY-MM-DD");
      endDate = expectedDateRange[1].format("YYYY-MM-DD");
      formData.append("data[start_date]",startDate);
      formData.append("data[close_date]", endDate);
    }
   
    formData.append("stage", dealStage);
    formData.append("data[description]", dealDescription);
    formData.append("data[deal_owner_id]",selectedOwner.id)
    
    if(this.state.chosenDealProductIds.length > 0){
      const convertProductIds = [this.state.chosenDealProductIds.map(Number)];
      // [1245, 45454]
      formData.append("products", `[${convertProductIds}]`);
      if(productAmount !==null){
        formData.append("data[total_amount]", productAmount.toString())
      }
      
    }
    if(filesUploaded.length > 0){
      filesUploaded.forEach((file) => {
        formData.append("files[]", file, file.name);
      });
     
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createDealRequestAPICallId = requestMessage.messageId;
    const header = {
      token: this.userToken.meta.token,
    };

    this.createDealRequestAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createDealsEndpoint +
        `contact_ids=[${selectedContacts}]`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePOST
    );

    //
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  updateDealHandler = async () => {
    let errorFlag = false;
    const {editDealDetails,editAttachmentsNew} = this.state;
    let selectedContacts = [];
    let fileTaskArray = [];
    let dealContactError = "";
    let startDate = "";
    let endDate = "";
   
    const header = {
      token: this.userToken.meta.token,
    };

    if(editDealDetails.dealContacts.length === 0){
      dealContactError = "Please select deal contact";
      errorFlag = true;
        }

        if (errorFlag) {
          this.setState({
            createDealError: {
              ...this.state.createDealError,
              dealContactError: dealContactError,
            },
          });
          return true;
        }

    const formData = new FormData();
    formData.append("data[name]", editDealDetails.dealName);
    if (editDealDetails.dealCloseDate.length === 2) {
      startDate = editDealDetails.dealCloseDate[0].format("YYYY-MM-DD");
      endDate = editDealDetails.dealCloseDate[1].format("YYYY-MM-DD");
      formData.append("data[start_date]",startDate);
      formData.append("data[close_date]", endDate);
    }
    
    formData.append("stage", editDealDetails.dealStage.toString());
    if(editDealDetails.dealNote !==""){
      formData.append("data[note]", editDealDetails.dealNote);
    }
   
    formData.append("data[description]", editDealDetails.dealDescription);

    if (editDealDetails.dealContacts.length > 0) {
      selectedContacts = editDealDetails.dealContacts.map(
        (item: any) => item.id
      );
    }
    formData.append("contact_ids", `[${selectedContacts.toString()}]`);
    if(this.state.editDealDetails.productIds.length > 0){
      const convertProductIds = [this.state.editDealDetails.productIds.map(Number)];
      formData.append("products", `[${convertProductIds}]`);
      if(this.state.editDealDetails.edittotalAmount !==null){
        formData.append("data[total_amount]", this.state.editDealDetails.edittotalAmount)
      }
      
    }
    
    if(this.state.editAttachmentsNew.length > 0){
      editAttachmentsNew.forEach((file:any) => {
        formData.append("files[]", file, file.name);
      });
     
    }
    formData.append("deal_owner_id", this.state.editDealDetails.dealOwner.id);
    this.setState({
     selectedDealId : this.state.editDealDetails.dealId
    })
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateDealAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editDealEndPoint + this.state.editDealDetails.dealId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePUT
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  duplicateDealHandler = (dealId: string) => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    this.setState({
      selectedDealId : dealId
    })

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.duplicateDealAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.duplicateDeal + dealId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePOST
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteDealHandler = () => {
    let deleteDealId = "";
    const { openViewDetailModal, menuDealId, viewSingleDealId } = this.state;
    if (openViewDetailModal === true) {
      deleteDealId = viewSingleDealId;
    } else {
      deleteDealId = menuDealId;
    }

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    this.setState({
      selectedDealId: deleteDealId,
    })

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteDealAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteDealsEndPoint + deleteDealId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeDELETE
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  updateDealStage = () => {
    const { newStatus, currentDealStage, addDealNote } = this.state;

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateDealStatusAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateDealStatus +
        `id=${currentDealStage.currentdealId}&stage=${newStatus}&note=${addDealNote}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePATCH
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  popoverHandleClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    if (event) {
      this.setState({
        popoveAnchorEl: event.currentTarget,
        createDealPopoverShown: true,
      });
    }
  };

  openDeleteModal = () => {
    this.setState({ openDeleteModal: true });
  };

  closeDeleteModal = () => {
    this.setState({ openDeleteModal: false });
  };

  popoverHandleClose = () => {
    this.setState({ popoveAnchorEl: null }, () => this.clearAllInputs());
  };
  editPopoverHandleClose = () => {
    this.setState({ popoverEditDealEl: null, editDealPopoverShown: false });
  }
  handleDealsSearchquery = (seachVal: string) => {

    this.setState({searchQueryVal: seachVal, isSearching: true },() => {this.searchDealsAPI()})
  }
  handleSortByDeals = (event : {target : {value : any}}) => {
    
    if (event.target.value != undefined) {
      this.setState({sortBySelectedVal : event.target.value, isSearching: true}, () => {this.searchDealsAPI()})
    }
   
    
  }
  filterStageHandler = (event : {target : {value : any}}) => {
    if (event.target.value != undefined) {
      this.setState({ filterStageVal: event.target.value, isSearching: true }, () => this.searchDealsAPI());
    }
  }
  filterDealOwnerHandler = (event : {target : {value : any}}) => {
    if (event.target.value != undefined) {
      this.setState({ filterDealOwnerVal: event.target.value, isSearching: true }, () => this.searchDealsAPI());
    }

  }
  filterContactsHandler = (event: {target: {value: any}}) => {
    if (event.target.value != undefined) {
      this.setState({ filterContactVal: event.target.value, isSearching: true }, () => this.searchDealsAPI());
    }
  }
  onBrowse = () => {
    if (this.fileRef.current !== null) {
      this.fileRef.current.click();
    }
  };

  handleOnDrop = (e:React.ChangeEvent<HTMLInputElement>) => { 
    let fileObject:FileList;
  if(e.target.files != null){
      fileObject= e.target.files;
      const arrayLength = this.state.filesUploaded.length;
      //if(arrayLength<6){
        this.setState({filesUploaded: [...this.state.filesUploaded,...Object.values(fileObject)]}, () => this.scrollToFiles());
      //}
  }
  };

  handleOnDropEdit = (e:React.ChangeEvent<HTMLInputElement>) => { 
    let fileObject:FileList;
  if(e.target.files != null){
      fileObject= e.target.files;
      this.setState({editAttachmentsNew: [...this.state.editAttachmentsNew,...Object.values(fileObject)]}, () => this.scrollToFiles());
     
  }
  };
  

  removeFileUPload = (index: any) => {
    const updatedArray = [...this.state.filesUploaded.slice(0, index), ...this.state.filesUploaded.slice(index + 1)];
    this.setState({ filesUploaded: updatedArray });
  }

  removeAddedFileNew = (index:number) => {
    const updatedArrayNew = [...this.state.editAttachmentsNew.slice(0, index), ...this.state.editAttachmentsNew.slice(index + 1)];
    this.setState({ editAttachmentsNew: updatedArrayNew });
  }

  removeAddedFile = (attachmentId:number) => {
    const updatedArray = this.state.editAttachments.filter((img:any)=>img.id!=attachmentId)
    this.setState({ editAttachments: updatedArray });
   
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeDealAttachmentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAttachment + `/${this.state.editDealId}/delete_attachment?attachment_id=${attachmentId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }
  

  
  toggleMenuDrawer = () => {
    this.setState({
      isMenuDrawerOpened: true,
    });
  };
  closeMenuDrawer = () => {
    this.setState({
      isMenuDrawerOpened: false,
    });
  };

  tableMenuClose = () => {
    this.setState({ tableMenuEl: null, anchorEl: null });
  };
  setTableMenuEl = (event: any, dealId: string, dealName: string) => {
    this.setState({ menuDealId: dealId, menuDealName: dealName });
    this.setState({ tableMenuEl: event.currentTarget });
  };
  openFloatCreateDeal = () => {
    this.setState({
      floatCreateDeal: true,
      createDealPopoverShown: false,
      editDealPopoverShown: false,
    });
  };
  openFloatCreateContact = () => {
    this.setState({
      floatCreateContact: true,
      showCreateContact: false,
    });
  };
  closeFloatCreateContact = () => {
    this.setState({
      floatCreateContact: false,
      showCreateContact: true,
    });
  }
  openCreateDealPopoverShown = () => {
    this.setState({
      floatCreateDeal: false,
      createDealPopoverShown: true,
      editDealPopoverShown: true,
    });
  };
  handleEscDealsForm = (event: any) => {
    event.stopPropagation();
    if (event.key === "Escape") {
      this.openFloatCreateDeal();
    }
  }

  getSortParam = (sortBySelectedVal:string) => {
    switch (sortBySelectedVal) {
      case "A-Z":
        return "A-Z";
      case "Z-A":
        return "Z-A";
      case "Highest to lowest value":
        return "Highest to Lowest Value";
      case "Lowest to highest value":
        return "Lowest to Highest Value";
      case "Soonest Close Date":
        return "Soonest Close Date";
      case "Date Created":
        return "DATE_CREATED";
      default:
        return "";
    }
  };
  
  searchDealsAPI = () => {
    const {searchQueryVal, filterStageVal, filterDealOwnerVal, filterContactVal, sortBySelectedVal,hasAttachment, hasAssociatedProduct,hasTasksDeals, filterCloseDateType,dealFilterDate, dealAmountRange, isAmountFilter, isCloseDateFilter} = this.state;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

  
    
    let url = "";
    if(filterStageVal !== ""){
      url += `stage=${filterStageVal}&`;
    }
    if(filterDealOwnerVal !== ""){
      url += `deal_owner_id=${filterDealOwnerVal}&`;
    }
    if(filterContactVal !== ""){
      url += `contact_id=${filterContactVal}&`;
    }
    if(filterCloseDateType !== "" && isCloseDateFilter){
      url += `close_date_type=${filterCloseDateType}&`;
    }
    if(dealAmountRange.length > 0 && isAmountFilter){
      url += `from_amount=${dealAmountRange[0]}&to_amount=${dealAmountRange[1]}&`;
    }
    if(dealFilterDate!=="" && isCloseDateFilter){
      url += `close_date=${dealFilterDate}&`;
    }
    
    if(sortBySelectedVal !==""){
      url += `sort_by=${this.getSortParam(sortBySelectedVal)}&`;
    }
    if(searchQueryVal.trim() !==""){
    url += `search=${searchQueryVal}&`;
    }
    if(this.state.hasAttachment){
      url += `attachments=${hasAttachment}&`;
    }
    if(this.state.hasAssociatedProduct){
      url += `products=${hasAssociatedProduct}&`;
    }
    if(this.state.hasTasksDeals){
      url += `tasks=${hasTasksDeals}&`;
    }
    

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchDealAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchDealEndPoint + url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );

    
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  popoverEditDeal = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    dealId: string
  ) => {
    const {openViewDetailModal} = this.state;
    if(openViewDetailModal){
      this.setState({
        openViewDetailModal : false,
      })
    }
    if (event) {
      this.setState({
        popoverEditDealEl: event.currentTarget,
        editDealPopoverShown: true,
        editDealId: dealId
      });
    }
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editDealAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewSingleDeal + `id=${dealId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );

    
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 
  closeFloatCreateDeal = () => {
    this.setState({ floatCreateDeal: false }, () => this.clearAllInputs());
  };

  chooseStageHandler = (dealId: string, dealStage: string, dealNote: string) => {
    let dealValue;
    if (dealStage === "Initiated") {
      dealValue = 0;
    } else if (dealStage === "sent proposal") {
      dealValue = 1;
    } else if (dealStage === "follow up") {
      dealValue = 2;
    } else if (dealStage === "won") {
      dealValue = 3;
    } else if (dealStage === "lost") {
      dealValue = 4;
    }
    this.setState({ isActiveChooseStage: true });
    this.setState({
      currentDealStage: {
        currentdealId: dealId,
        currentdealStage: dealValue,
      },
      newStatus : dealValue,
      addDealNote: dealNote,
    });
  };
  dealNoteHandler = (dealNote: string) => {
    this.setState({ addDealNote: dealNote });
  };
  chooseStageModalClose = (e: any) => {
    this.setState({ isActiveChooseStage: false });
  };
  handleStageChange = (event: { target: { value: any } }) => {
    if (event.target.value != undefined) {
      this.setState({ newStatus: event.target.value });
    }
  };
  viewDealDetails = (dealId: string) => {
    this.setState({ openViewDetailModal: true, viewSingleDealId: dealId });
  };
  closeViewDetails = () => {
    this.setState({ openViewDetailModal: false });
  };
  openChooseDealModal = () => {
    this.setState({ isActiveChooseDeal: true });
    this.setState({
      createDealError: {
        ...this.state.createDealError,
        dealOwnerError: "",
      },
    });
  };
  openContactModal = () => {
    this.getAllContacts()
    this.setState({ isActiveContact: true });
    this.setState({
      createDealError: {
        ...this.state.createDealError,
        dealContactError: "",
      },
    });
  };
  openProductsModal = () => {
    this.setState({ openProductsModal: true });
  }
  closeProductsModal = () => {
    this.setState({ openProductsModal: false });
  }
  closeChooseDealOwner = () => {
    this.setState({ isActiveChooseDeal: false });
  };
  closeContactModal = () => {
    this.setState({ isActiveContact: false });
  };
  handleDealOwnersClick = (ownerId: string) => {
    const { dealOwners } = this.state;
    let updateSeleected = dealOwners.find((owner: any) => owner.id === ownerId);
    this.setState({ selectedOwner: updateSeleected });
    
  };
  handleEditDealOwner = (ownerId: string, ownerDetails:any) => {
    const { dealOwners } = this.state;
    let updateSeleected = dealOwners.find((owner: any) => owner.id === ownerId);
    let newOwner = {
      id: ownerId,
      name: ownerDetails.first_name + ownerDetails.last_name,
  };
    this.setState({
      editDealDetails :{
        ...this.state.editDealDetails,
        dealOwner: newOwner,
        dealOwnerSelected: updateSeleected
      }
    },)
  }
  handleContactsList = (contactId: string) => {
    let changeContacts = this.state.selectedContacts;
    if (changeContacts.includes(contactId)) {
      changeContacts = changeContacts.filter((x: any) => x !== contactId);
    } else {
      changeContacts.push(contactId);
    }
    this.setState({
      selectedContacts: changeContacts,
    });
  };
  handleEditContactsList = (contactId: string, contactName:string) => {
    let updatedContactList = this.state.editDealDetails.dealContacts;
    let newContact = {
      id: contactId,
      name: contactName
  };

  updatedContactList.push(newContact);
    this.setState({
      editDealDetails :{
        ...this.state.editDealDetails,
        dealContacts: updatedContactList,
      }
    })

  }

  removeSelectedContact = (contactId: string) => {
    let changeContacts = this.state.selectedContacts;
    if (changeContacts.includes(contactId)) {
      changeContacts = changeContacts.filter((x: any) => x !== contactId);
    } else {
      changeContacts.pop(contactId);
    }
    this.setState({
      selectedContacts: changeContacts,
    });
  }

  removeSelectedEditContact = (contactId: string, contactTitle: string) => {
    let updatedContactList = this.state.editDealDetails.dealContacts;
      updatedContactList.pop(contactId);
    this.setState({
      editDealDetails :{
        ...this.state.editDealDetails,
        dealContacts: updatedContactList,
      }
    })
    
  }
  removeAllContacts = () => {
    if(this.state.editDealPopoverShown){
      this.setState({ editDealDetails: {
        ...this.state.editDealDetails,
        dealContacts: [],
      } });
    }else{
      this.setState({ selectedContacts: [] });
    }
    
  };

  normalizeText = (str: string) => str.toLowerCase().replace(/\s+/g, ' ').trim();

  containsText = (text: string, searchText: string) =>
    this.normalizeText(text).indexOf(this.normalizeText(searchText)) > -1;

  handleContactsSearch = (searchvalue: string) => {
    const searchTxt = searchvalue;
      this.setState({
        contactsSearchTxt: searchTxt,
      }); 
  };

  displayContactOptions = () => {
    const contactNames = this.state.contactList.map(
      (contact: {
        id: number;
        attributes: {
          first_name: string;
          last_name: string;
          email: string;
          team: any[];
        };
      }) => ({
        id: contact.id,
        title: `${contact.attributes.first_name} ${contact.attributes.last_name}`,
        email: contact.attributes.email,
        initials: `${contact.attributes.first_name
          .charAt(0)
          .toUpperCase()}${contact.attributes.last_name
          .charAt(0)
          .toUpperCase()}`,
      })
    );
    return contactNames.filter((obj: { title: string }) =>
      this.containsText(obj.title, this.state.contactsSearchTxt)
    );
  };
  
  dealNameHandler = (dealValue: string) => {
    this.setState({ dealName: dealValue });
  };
  handleCloseDateChange = (date: any) => {
    this.setState({
      expectedDateRange: date,
    });
  };

  handleSliderChange = (newValue:number[]) => {
    this.setState({
      dealAmountRange : newValue,
    })
  }

  handleMinInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {dealAmountRange} = this.state;
    const inputValue = event.target.value.replace(/^0+/, '');
    const newMinValue = Math.min(Number(inputValue), dealAmountRange[1]);
    this.setState({
      dealAmountRange : [newMinValue, dealAmountRange[1]]
    })
   
  };
  

  handleMaxInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {dealAmountRange} = this.state;
    const inputValue = event.target.value.replace(/^0+/, '');
    const newMaxValue = Math.max(Number(inputValue), dealAmountRange[0]);
    this.setState({
      dealAmountRange : [dealAmountRange[0], newMaxValue]
    })
  };
  handleAmountsFilter = () => {
    this.setState({ isSearching: true,isAmountFilter: true, }, () => this.searchDealsAPI());
  }

  handleDealFilterChange = (date: any) => {
    this.setState({
      dealFilterDate: date?.isValid ? date.format("YYYY-MM-DD") : "",
      isSearching: true,
      isCloseDateFilter: true,
    }, () => this.searchDealsAPI());
  };
  handleCloseDateTypeFilter = (event:any) => {
    if (event.target.value != undefined) {
      this.setState({ filterCloseDateType: event.target.value, isSearching: true, isCloseDateFilter: true }, () => this.searchDealsAPI());
    }
  }
  dealStageHAndler = (event: { target: { value: any } }) => {
    if (event.target.value != undefined) {
      this.setState({ dealStage: event.target.value });
    }
  };
  dealDescriptionHandler = (description: string) => {
    this.setState({ dealDescription: description });
  };
  editDealNameHandler = (dealValue: string) => {
    this.setState({
      editDealDetails: {
        ...this.state.editDealDetails,
        dealName: dealValue,
      },
    });
  };
  editCloseDateHandler = (date: any) => {
   
    this.setState({
      editDealDetails: {
        ...this.state.editDealDetails,
        dealCloseDate: date,
      },
    });
  };
  editDealStageHandler = (event: { target: { value: any } }) => {
    if (event.target.value != undefined) {
      this.setState({
        editDealDetails: {
          ...this.state.editDealDetails,
          dealStage: event.target.value,
        },
      }, () => this.toggleDealNotes());
    }
  };
  toggleDealNotes = () => {
    this.setState({
      showDealNoteInput: true
    })
  }
  editdealDescription = (description: string) => {
    this.setState({
      editDealDetails: {
        ...this.state.editDealDetails,
        dealDescription: description,
      },
    });
  };
  editDealNote = (dealNote: string) => {
    this.setState({
      editDealDetails: {
        ...this.state.editDealDetails,
        dealNote: dealNote,
      },
    });
  }
  selectedDealProducts=(productsSelected:string[],totalAmount:number|null,selectedProductsData:[])=>{
    
    if(this.state.editDealPopoverShown){
      this.setState({
        editDealDetails : {
          ...this.state.editDealDetails,
          dealProducts:selectedProductsData,
          edittotalAmount:totalAmount,
          productIds:productsSelected,
        }
      },()=> {this.closeProductsModal()})
    }else{
      this.setState({chosenDealProductIds:productsSelected,chosenProductsData: selectedProductsData,productAmount: totalAmount,  }, ()=> {this.closeProductsModal()})
    }
    
  }
  openViewSelectedProducts= () => {
    this.setState({viewSelectedProducts : true});
  }
  closeViewSelectedProducts = () => {
    this.setState({viewSelectedProducts : false});
  }
  handleOpenMoreMenu = (event: any) => {
    if (event) {
      this.setState({ anchorElMore: event.target });
    }
  };
  handleCloseMoreMenu = () => {
    this.setState({ anchorElMore: null });
  };
  handleOpenCloseDateFilters = (event:any) => {
    this.setState({anchorElCloseDate : event.target});
  }
  handleCloseDateFilters = () => {
    this.setState({anchorElCloseDate : null});
  }
  handleOpenDealAmount = (event:any) => {
    this.setState({anchorDealAmount : event.target});
  }
  handleCloseDealAmount = () => {
    this.setState({anchorDealAmount : null});
  }
  resetDealAmountsFilter = () => {
    this.setState({
      dealAmountRange: [0, 9999],
      minValueAmount: 0,
      maxValueAmount: 9999,
      
    }, () => this.searchDealsAPI())
  }
  handleChecked = (event:any) => {
    const { name, checked } = event.target;
    this.setState({isSearching: true})
    this.setState((prevState) => ({
      ...prevState,
      [name]: checked,
      
    }), () => this.searchDealsAPI());
  };
  openEditContactModal = () => {
    this.setState({ isEditContact: true });
    this.setState({
      createDealError: {
        ...this.state.createDealError,
        dealContactError: "",
      },
    });
  }
  closeEditContactModal = () => {
    this.setState({ isEditContact: false });
  };
  editDealAPIHandler = (responseJson:any) => {
    let editAttributes = responseJson.data.attributes;
    let dealStageVal;
    dealStageVal = this.dealLabeltoVal(
      responseJson.data.attributes.stage
    );
    let productsData = editAttributes.products.data;
    const productIds = productsData.map((product:any) => {
      return product.id

    })
    let selected = this.state.dealOwners.find(
      (owner: any) => owner.id == editAttributes.deal_owner.id
    );
    
     this.setState({
        editDealDetails: {
          dealId: editAttributes.id,
          dealName: editAttributes.name,
          dealDescription: editAttributes.description,
          dealCloseDate: editAttributes.close_date === null ? [] : editAttributes.close_date,
          dealStage: dealStageVal,
          dealNote: editAttributes.note === null ? "" : editAttributes.note,
          dealProducts:editAttributes.products.data,
          edittotalAmount:editAttributes.total_amount,
          dealContacts:editAttributes.contacts,
          dealAttachments:editAttributes.attachments,
          productIds:productIds,
          dealOwner:editAttributes.deal_owner,
          dealOwnerSelected: selected,
        },
        editAttachments: editAttributes.attachments === null ? [] : editAttributes.attachments
        });
        this.setState({ isLoading: false });
  }
  clearAllInputs = () => {
    this.setState({
      dealName: "",
      dealCloseDate: "",
      dealDescription: "",
      dealStage: "0",
      dealFiles: "",
      dealOwner: "",
      dealContact: "",
      dealProduct: "",
      selectedContacts: [],
      filesUploaded: [],
      chosenDealProductIds: [],
      chosenProductsData:[],
      productAmount:0,

    })
  }
  scrollToFiles = () => {
    if (this.attachListLabelRef.current) {
      this.attachListLabelRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  createContact = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    if (event) {
      this.setState({
        contactEl: event.currentTarget,
        
        showCreateContact:  true,
      createDealPopoverShown: true,
      });
    }
   
  }
 
  handleCloseCreateContact= () => {
    this.setState({
      showCreateContact:  false,
      contactEl: null,
      floatCreateContact: false
    }, () => this.getAllContacts())
  }
  handleEscCreateContact = (event:any) => {
    event.stopPropagation();
    if (event.key === "Escape") {
    this.openFloatCreateContact()
    }
  }
  handleDealsPageChange = (event: unknown, newPage: number) => {
   
    this.setState({
      currentPage: newPage
    })
  }
  handleChangeRowsPerPage = ( event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      dealsPerPage: (parseInt(event.target.value, 20)),
      currentPage: 0

    })
  }
  getDealsCountTabs = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDealsTabCountsAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDealCounts
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  undoDealsOperation = (actionType:string, undoId:string) => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
   

      const body = {
        "id": undoId, 
        "action_type": actionType
      };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.undoDealActionsAPICallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.undoDealAPI
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePOST
    );

    this.setState({isLoading:true})

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleRemoveDealAttachments = (responseJson:any) => {
    this.setState({isLoading: false})
    if(responseJson.message === "Attachment deleted"){
      this.createToastNotificationSuccess(responseJson.message)
     this.getAllDeals();
    }

  }
   // Customizable Area End
}
