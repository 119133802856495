import React from 'react';
import { Box, Typography, Modal, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface CommonModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    description: string;
    bodyContent: React.ReactNode; // For dynamic body content like QuizList
    classes: any; // Pass styles as a prop
}

const CommonModal: React.FC<CommonModalProps> = ({
    isOpen,
    onClose,
    title,
    description,
    bodyContent,
    classes,
}) => {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className={classes.ModalDialog}
        >
            <Box className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper} ${classes.modalDialogBox}`}>
                <Box className="modal-heading mb5">
                    <Typography className="modal-title" component="h2">
                        {title}
                    </Typography>
                    <CloseIcon
                        className="close-icon"
                        onClick={onClose}
                        data-testId="closeIcon"
                    />
                </Box>

                <Box className="modal-description">
                    <Typography style={{ color: "#94A3B8", fontSize: "14px", marginBottom: "20px"}}>
                        {description}
                    </Typography>
                </Box>

                <Box className={classes.dialogContentFb}>{bodyContent}</Box>

            </Box>
        </Modal>
    );
};

export default CommonModal;
