import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

export const configJSON = require('./config')
const globalJSON = require('../../../framework/src/config')

export type NavigationRouteParamsType = {
    serviceCaller: string,
    connectCallback: () => void
}

interface S { 
    isGoogleConnected: boolean,
    isSendinBlueConnected: boolean,
    showErrorModal: boolean,
    errorMessage: string,
    isAddIntegration: boolean
    isMetaConnected: boolean,
}

interface SS { }

export interface Props {
    navigation?: {navigate?:any};
    id?: string;
    classes?: any;
    backToStartIntegration: any;
    route: any;
}

export default class StartIntegrationController extends BlockComponent<Props, S, SS> {

    userSessionData: any;
    userToken: any;

    getGoogleAccessTokenRequestId: string = "";
    getMetaAccessTokenRequestId: string = "";
    getBravoAccessTokenRequestId: string = "";

    constructor(props: Props) {

        super(props)

        this.receive = this.receive.bind(this)
        
        this.subScribedMessages = [


            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage),

            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
           
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)

        this.userSessionData = localStorage.getItem("userData");

        this.userToken = JSON.parse(this.userSessionData);

        this.state = {
            errorMessage: '',
            isSendinBlueConnected: false,

            showErrorModal: false,
            isAddIntegration: false,
            isMetaConnected: false,
            isGoogleConnected: false,
           
        }
    }

    async receive(from: string, message: Message) {
        
        if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
            const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage))

            if (apiRequestCallId == this.getMetaAccessTokenRequestId) {
                if(responseJson.access_token){
                    this.setState({
                        isMetaConnected:true,
                        isAddIntegration: true
                    })
                }
            }
            if (apiRequestCallId == this.getGoogleAccessTokenRequestId) {
                
                if(responseJson.access_token){
                    this.setState({
                        isGoogleConnected:true,
                        isAddIntegration: true
                    })
                }
            }
            if (apiRequestCallId == this.getBravoAccessTokenRequestId) {
                if(responseJson.access_token){
                    this.setState({
                        isSendinBlueConnected:true,
                        isAddIntegration: true
                    })
                }
            }
        }
    };

    async componentDidMount() {
        super.componentDidMount();
        const url = new URL(window.location.href);
        const code = url.searchParams.get('code');

        const scope = url.searchParams.get('scope');
        const state = url.searchParams.get('state');
        const bravo = url.searchParams.get('bravo');

        if (scope) {
            this.getGoogleAccessToken(code)
        } else if (state) {
            this.getMetaAccessToken(code)
        } 
        else if(bravo){
            this.getBravoAccessToken(code)
        }

    }

    skipToProfile = (navigation:string) => {
        if( navigation == "Back")
        this.props.navigation?.navigate("CustomisableUserProfiles");
        else this.props.navigation?.navigate("AccountCreation");
    }

    addIntegration = () => {
        this.setState({ isAddIntegration: true })
    }

    backToStartIntegration = () => {
        this.setState({ isAddIntegration: false })
    }

    getGoogleAccessToken = (code: any) => {

        const header = {
            "Content-Type": "application/json",
            token: this.userToken.meta.token,
        };

        let endPoint = `${globalJSON.baseURL}${configJSON.gglaccessTokenEndPoint}?code=${code}`;
        
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.getGoogleAccessTokenRequestId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint.toString()
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    getMetaAccessToken = (code: any) => {

        const header = {
            "Content-Type": "application/json",
            token: this.userToken.meta.token,
        };

        let endPoint = `${globalJSON.baseURL}${configJSON.metaAccessTokenEndPoint}?code=${code}`;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.getMetaAccessTokenRequestId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint.toString()
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    getBravoAccessToken = (code: any) => {

        const header = {
            "Content-Type": "application/json",
            token: this.userToken.meta.token,
        };

        let endPoint = `${globalJSON.baseURL}${configJSON.brevoAccessTokenEndPoint}?code=${code}`;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.getBravoAccessTokenRequestId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint.toString()
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

}

