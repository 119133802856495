import React from "react";
// Customizable Area Start
import { Box, Typography, Button, InputAdornment } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import EmailAccountRegistrationControllerWeb, {
  Props, configJSON,
} from "./EmailAccountRegistrationController.web";
import AuthLeft from "../../../components/src/AuthLeft.web";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import CreateProfileWeb from "./CreateAccount.Web";
import CustomModalWeb from "../../../components/src/CustomModal.web";
import Footer from "../../../components/src/Footer.web";
import AppHeader from "../../../components/src/AppHeader.web";
import CustomGoogleLogInButton from "../../social-media-account/src/CustomGoogleLogInButton.web";
import WebLoader from "../../../components/src/WebLoader.web";
import {fetchAllTranslations} from "../../../web/src/utilities/i18n";
// Customizable Area End

// Customizable Area Start
export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    overrides: {
      MuiButton: {
        primaryButton: {},
        socialButton: {},
      },
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "6px",
      // filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "16px",
      lineHeight: "21px",
      color: "#f2f8fc",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      width: "100%",
      padding: "12px 10px",
      minHeight: "56px",
      marginBottom: "30px",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    socialButton: {
      fontSize: "14px",
      lineHeight: "19px",
      border: "1px solid rgba(0,0,0,0.12)",
      borderRadius: "7px",
      width: "100%",
      color: "#2b2b2b",
      textTransform: "unset",
      background: "rgba(98,2,238,0.0001)",
      padding: "12px 10px",
      marginBottom: "0",
      fontWeight: 500,
      minHeight: "56px",
      fontFamily: "Expletus Sans",
      transition: "all 0.5s ease-in-out",
      "&:hover": {
        background: "transparent",
      },
      "& .MuiButton-startIcon": {
        width: "18px",
        height: "18px",
        marginRight: "6px",
      },
    },
    formLink: {
      fontSize: "14px",
      lineHeight: "19px",
      letterSpacing: 0,
      color: "#4ba3b7",
      fontWeight: 500,
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      fontFamily: "Expletus Sans",
    },
    orTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      fontSize: "16px",
      lineHeight: "21px",
      marginBottom: "30px",
      color: "#94a3b8",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      "&:before": {
        content: '""',
        position: "absolute",
        width: "100%",
        top: "50%",
        borderBottom: "1px solid #e8ecf2",
      },
      "& span": {
        position: "relative",
        background: "#ffffff",
        padding: "0 10px",
      },
    },
    authWrapper: {
      textAlign: "left",
      "& img": {
        maxWidth: "100%",
      },
      "& .auth-inner-block": {
        display: "flex",
        flexWrap: "wrap",
        "& .auth-right": {
          flex: "1",
          padding: "64px 63px 142px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          [theme.breakpoints.down("md")]: {
            padding: "64px 63px 83px",
          },
          [theme.breakpoints.down("sm")]: {
            padding: "60px 60px 139px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "24px 24px 99px",
          },
          "& .right-inner-content": {
            maxWidth: "512px",
            width: "100%",
            margin: "auto",
            // [theme.breakpoints.down("md")]: {
            //   maxWidth: "400px",
            // },
          },
          "& .right-inner": {
            display: "flex",
            flexDirection: "column",
            height: "100%",
          },
          "& .back-btn-wrap": {
            [theme.breakpoints.down("sm")]: {
              position: "absolute",
              top: "120px",
            },
            [theme.breakpoints.down("xs")]: {
              position: "absolute",
              top: "110px",
            },
            "& i": {
              display: "block",
            },
          },
        },
      },
      "& .heading": {
        fontSize: "24px",
        lineHeight: "30px",
        margin: "0 0 12px",
        color: "rgba(0,0,0,0.87)",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        margin: "0 0 55px",
        color: "#505050",
        fontFamily: "Roboto",
        letterSpacing: "0.25px",
        fontWeight: 400,
        [theme.breakpoints.down("xs")]: {
          margin: "0 0 31px",
        },
      },
      "& .page-link": {
        textAlign: "right",
        marginBottom: "16px",
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#505050",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        "& .form-link": {
          marginLeft: "10px",
        },
      },
    },
    wrapper: {
      minHeight: "100vh",
      paddingBottom: "142px",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "105px",
      },
    },
    authMainWrapper: {
      "& .wrapper": {
        paddingBottom: "0",
      },
      "& .header-wrapper": {
        display: "none",
        [theme.breakpoints.down("sm")]: {
          display: "flex",
        },
      },
      "& .footer-wrapper": {
        marginLeft: "576px",
        [theme.breakpoints.down("md")]: {
          marginLeft: "384px",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "0",
        },
      },
    },
    innerWrapper: {
      margin: "auto",
      width: "100%",
    },
  });
// Customizable Area End

class EmailAccountRegistration extends EmailAccountRegistrationControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const authToken = sessionStorage.getItem("createAcocuntData");
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
       {this.state.isLoading && <WebLoader />}
      {authToken !== null ? (
        <CreateProfileWeb />
      ) : (
        <Box className={`${classes.authMainWrapper}`}>
          <Box className={`wrapper ${classes.wrapper}`}>
            <AppHeader />
            <Box className={classes.innerWrapper}>
              <Box className={classes.authWrapper}>
                <Box className="auth-inner-block">
                  <AuthLeft />
                  <Box className="auth-right">
                    <Box className="right-inner">
                      <Box
                        className="back-btn-wrap"
                        onClick={this.handleBack}
                      >
                        <i>
                          <svg
                            className="MuiSvgIcon-root"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"></path>
                          </svg>
                        </i>
                      </Box>
                      <Box className="right-inner-content">
                        <Typography className="heading" variant="h1">
                          {this.t(`${this.labelHeader}`)}
                        </Typography>
                        <Box component="p" className="sub-txt">
                          {this.t(`${configJSON.errorMsgTxt}`)}
                        </Box>
                        <Box component="form">
                          <CustomInputWeb
                            testdataid="emailInputTestId"
                            errors={this.state.error.emailError.message}
                            label={this.t(`${this.labelEmail}`)}
                            value={this.state.email}
                            inputID="txtInputEmail"
                            disabled={this.state.isEmailDisabled}
                            onChange={(event) =>
                              this.setState({ email: event.target.value })
                            }
                            isDealForm={true}
                          />
                            <CustomInputWeb
                            testdataid="passwordInputTestId"
                              errors={this.state.error.passwordError.message}
                              label={this.t(`${this.labelPassword}`)}
                              t={this.t}
                              inputID="txtInputPassword"
                              endAdornment={
                                <InputAdornment position="end">
                                  {this.state.isPasswordVisible ? (
                                    <VisibilityOffIcon
                                      className="visibility-icon"
                                      onClick={this.togglePasswordVisibility}
                                    />
                                  ) : (
                                    <VisibilityIcon
                                      className="visibility-icon"
                                      onClick={this.togglePasswordVisibility}
                                      data-testid="emailTogglePwd"
                                    />
                                  )}
                                </InputAdornment>
                              }
                              type={
                                this.state.isPasswordVisible
                                  ? "text"
                                  : "password"
                              }
                              value={this.state.password}
                              onChange={(event) =>
                                this.setState({ password: event.target.value })
                              }
                              showRequirements={true}
                              onPassChange={this.isValidPassword}
                              isDealForm={true}
                            />

                          <Button
                            className={classes.primaryButton}
                            onClick={this.validateAccount}
                            data-testid="emailRegisterBtn"
                          >
                            {this.btnTextSignUp}
                          </Button>
                          <div data-testid={"customModalID"}>
      
                              <CustomModalWeb
                              testdataid='customModalID'
                              getPrivacyPolicy={this.getTermsAndPrivacy}
                              setPrivacyNull={this.setPrivacyNull}
                              termsPrivacyData = {this.state.termsPrivacyData}
                              modalOpen={this.state.showModal}
                              modalClose={this.handleClose}
                              notYouHandler={this.handleNotyouclick}
                              createUserAccount={this.createAccount}
                              checkBoxChecked={this.handleCheckBox}
                              isChecked={this.state.isChecked}
                              email={this.state.email}
                              checkBoxErrors={
                                this.state.checkboxError.message
                              }
                              displayName={""}
                              userGoogleImageURL={""}
                              t={this.t}
                            />
                          </div>
                          <Box className={classes.orTxt}>
                            <Box component="span">{this.t(`${configJSON.lableOR}`)}</Box>
                          </Box>
                          <CustomGoogleLogInButton />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Footer />
        </Box>
      )}
    </>
      // Customizable Area End
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    try {
      await fetchAllTranslations();
      this.setState({ isLoading: false });
    } catch (error) {
      console.error('Error loading translations:', error);
    } finally {
      this.setState({ isLoading: false });
    }
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(
  (EmailAccountRegistration)
  );
// Customizable Area End
