import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme
} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import CustomInput from "../../../components/src/CustomInput.web";
import {fileIcon, tickIcon} from "../../catalogue/src/assets";
import FreeSoloCreateOption from "../../../components/src/FreeSoloCreateOption.web";
import ConfirmActionModal from "../../email-account-registration/src/teams-web/ConfirmActionModal.web";
import {ImageDataType} from "./InventoryTypes";

import ToolsInfoController, {
  Props,
  configJSON
} from "./ToolsInfoController.web";
import { customStyles } from "../../../components/src/AddItemStyles.web";

// Customizable Area End

export class ToolsInfo extends ToolsInfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes,backBtn, seletedItem,} = this.props;
    return (
        <Box className={classes.basicInfoSection}>
        <Box className="heading-box">
          <Box>
            <Typography className={classes.heading} variant="h1">
              {this.t(`${configJSON.addingTitle}`)}
            </Typography>
            <Typography className={classes.subtitleText}>
              {this.t(`${configJSON.addingSubtext}`)}
            </Typography>
          </Box>
          <Box className="item-box" data-testId="itemText">
            {this.t(`${seletedItem}`)}
          </Box>
        </Box>
        <Grid className={classes.formSection} container alignItems="center" justifyContent="center">
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <CustomInput
              value={this.state.productName}
              onChange={(event) => this.handleName(event)}
              placeholder={this.t(`${configJSON.productNamePlaceHolder}`)}
              data-testId={'product-name'}
              errors={
                this.state.errorMsg.name
              }
            />
          </Grid>

          <Grid item md={12} lg={12} sm={12} xs={12} className={classes.dropdownSpacing}>
          <FreeSoloCreateOption  
                onChange={this.colorChangeHandler}
                addLinkTitle={this.t(`${configJSON.addColorTxt}`)}
                noOptionTitle={this.t(`${configJSON.noColorFoundTxt}`)}
                label={this.t(`${configJSON.colorPlaceHolder}`)}
                id="Color"
                value={this.state.colour}
                deleteCallback={this.deleteColor}
                addEditCallback={this.addColor}
                errorMsgNoRecord={this.t(`${configJSON.noColorCreatedTxt}`)}
                data-test-id="toolsColor"
                notRequired={true}
                placeHolder={this.t(`${configJSON.searchColorTxt}`)}
                options={this.state.colourList.map((clr) => {
                  return {
                    id: clr.id,
                    title: clr.name,
                  };
                })}
              />
               <ConfirmActionModal
                  isOpen={this.state.isDeletingColor}
                  handleClose={this.deleteColorModalClose}
                  modalConfirmAction={this.deleteColorApi}
                  modalMessage={this.t(`${configJSON.confirmationMsgForColorTxt}`)}
                  confirmBtnTxt={this.t(`${configJSON.txtDelete}`)}
                  modalHeading={this.t(`${configJSON.deleteColorTxt}`)}
                  data-test-id="confirmDeleteSelector"
                />
          </Grid>


          <Grid item md={12} lg={12} sm={12} xs={12} className={classes.dropdownSpacing}>
             <FreeSoloCreateOption
                data-testId={"tools-Brand"}
                addEditCallback={this.addBrand}
                options={this.state.brandList.map((value) => {
                  return {
                    title: value.attributes.name ?? "Test",
                    id: value.id,
                  };
                })}
                label={this.t(`${configJSON.brandTxt}`)}
                id={configJSON.brandTxt}
                addLinkTitle="Add New Brand"
                onChange={this.handlebrandName}
                value={Object(this.state.toolBrand)}
                deleteCallback={this.deleteBrand}
                notRequired={true}
                noOptionTitle={this.t(`${configJSON.noBrandTxt}`)}
                errorMsgNoRecord={this.t(`${configJSON.noBrandCreatedTxt}`)}
                placeHolder={this.t(`${configJSON.brandPlaceholder}`)}
              />
          </Grid>
            <ConfirmActionModal
                  isOpen={this.state.isBrandDelete}
                  handleClose={this.deleteBrandModalClose}
                  modalConfirmAction={this.deleteBrandApi}
                  deleteTeamId={String(this.state.deleteBrandId)}
                  modalMessage={this.t(`${configJSON.confirmationMsgForBrandTxt}`)}
                  confirmBtnTxt={this.t(`${configJSON.txtDelete}`)}
                  modalHeading={this.t(`${configJSON.deleteBrandTxt}`)}
                  data-testId={"confirmDeleteBrand"}
                />
        </Grid>
        <Typography className={classes.uploadText}>
            {this.t(`${configJSON.attachmentText}`)}
        </Typography>
        <input hidden ref={this.inputRef} type="file" multiple
        onChange={this.uploadFile} data-testId={"img-upload"}
        />
        <Box className={classes.browseBox} onClick={this.onClickBrowse} data-test-id={"browse-text"}>
        <img src={fileIcon} className="pinImg" />
        <Typography className="upload-text">
           {this.t(`${configJSON.uploadText1}`)} {" "}
            <span className="span-text">{configJSON.browseText}</span>
            {" "}{this.t(`${configJSON.uploadText2}`)}{" "}{this.t(`${configJSON.uploadImgIext}`)}
        </Typography>
        </Box>
        {this.state.addedImages.map((img: any, index: number) => (
        <Box key={index} className={classes.uploadFileBox} data-test-id={`file-preview-${index}`}>
          <img src={tickIcon} data-test-id={`tick-icon-${index}`} />
          <Typography className="image-text" data-test-id={`file-name-${index}`}>
            {img.filename}
          </Typography>
          <CloseIcon onClick={()=>this.removeAddedImages(img.id)} className="file-close-icon" data-test-id={`remove-icon-${index}`} />
        </Box>
      ))}
        {this.state.imagePreview.map((img: ImageDataType,index:number) => {
          return (
            <Box key={img.url} className={classes.uploadFileBox} >
              <img src={tickIcon} />
              <Typography className="image-text" data-testId={`imageBox-${img.name}`}>{img.name}</Typography>
              <CloseIcon
                className="file-close-icon"
                onClick={()=>this.removeAttachment(index)}
                data-test-id={`file-remove-${index}`}
              />
            </Box>
          )
        })
        }
        <Box className={classes.formButtons}>
          <Button className="backBtn" onClick={backBtn}
            data-testId={"back-btn"}
          >
            {this.t(`${configJSON.backButton}`)}
          </Button>
          <Button className="next-step-btn" 
            data-testId={"nextbtn"} onClick={this.nextButton}
          >
            {this.t(`${configJSON.nextButton}`)}
          </Button>
        </Box>
      </Box>
      )
    }
    // Customizable Area End
}

// Customizable Area Start


export default withStyles(customStyles, { withTheme: true })(ToolsInfo);
// Customizable Area End