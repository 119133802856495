import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import {ImageDataType, TrophyData} from "./InventoryTypes";
import StorageProvider from "../../../framework/src/StorageProvider";
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  openTrophyModal: boolean;
  closeTrophy: ()=>void;
  addingTrpohyData: (name: string, year: number, result: string, comment:string,trophyImage?:ImageDataType[],id?:number)=>void;
  editTrophyData?:TrophyData
  // Customizable Area End
}

interface S {
// Customizable Area Start
trophyName: string | unknown;
trophyYear: string;
trophyResult: string | unknown;
trophComment: string;
trophyimageName:string;
trophyimagePreview: any;
trophyNamesData:{ 
  id: number,
  name: string,
}[];
trophyResultsData:{ 
  id: number,
  name: string,
}[];
error:{
  trophyName: string ;
  trophyYear: string;
  trophyResult:string;
};

// Customizable Area End
}
  
interface SS {
id: any;
// Customizable Area Start
// Customizable Area End
}
  
// Customizable Area Start

// Customizable Area End
export default class TrophyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  inputRef: React.RefObject<HTMLInputElement> = React.createRef();
  getTrophyNameApiCallId: string = "";
  getTrophyResultApiCallId: string = "";
  userSessionData: any;
  userToken: {meta:{token:string}};
  yearValidation: RegExp;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      // Customizable Area Start
      trophyName: '',
      trophyYear: '',
      trophyResult: '',
      trophComment: '',
      trophyimageName:"",
      trophyimagePreview: [],
      trophyNamesData: [],
      trophyResultsData: [],
      error:{
        trophyName: "" ,
        trophyYear: "",
        trophyResult:"",
      }
      // Customizable Area End
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData); 
    this.yearValidation = new RegExp(/^\d{0,4}$/);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestCallId === this.getTrophyNameApiCallId){
      this.setState({trophyNamesData: responseJson});
    }
    if(apiRequestCallId === this.getTrophyResultApiCallId){
      this.setState({trophyResultsData: responseJson.options});
    }

    // Customizable Area End
  }

  handleTrophyName=(event:React.ChangeEvent<{value: unknown;}>)=>{
    this.setState({trophyName: event.target.value})
  }
  handleTrophyYear=(event:React.ChangeEvent<HTMLInputElement>)=>{
    if(this.yearValidation.test(event.target.value)){
      this.setState({trophyYear: event.target.value,
        error: {
          ...this.state.error,
          trophyYear: "",
        },
      })
    }
    else{
      this.setState({ 
        error: {
        ...this.state.error,
        trophyYear: "",
      },})
    }
    
  }
  handleTrophyResult=(event:React.ChangeEvent<{value: unknown;}>)=>{
    this.setState({trophyResult: event.target.value})
  }
  handleTrophyComment=(event:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({trophComment: event.target.value})
  }
  uploadFile = (e:React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.files != null){
      const selectedFilesData:any = Array.from(e.target.files).map((file) => ({
        name: file.name,
        image: URL.createObjectURL(file),
        type: file.type,
        image_name: file.name,
      }));
      this.setState({trophyimagePreview: selectedFilesData});
    }
  }
  onClickBrowse = () => {this.inputRef.current && this.inputRef.current.click()}

  delTrophyImage=(img:string)=>{
    let newImgArr = this.state.trophyimagePreview.filter((data:any)=> {return data.name != img});
    this.setState({trophyimagePreview: newImgArr});
  }
  
  getTrophyNames = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTrophyNameApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTrophyNameAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTrophyResult = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTrophyResultApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsDropdownAPIEndPoint + "trophy_result"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addTrophy =()=>{
    let errorFlag = false;
    let trophyNameError=this.state.error.trophyName
    let trophyYearError=this.state.error.trophyYear
    let trophyResultError=this.state.error.trophyResult
    if(this.state.trophyName==""){
        trophyNameError="Please enter trophy name"
        errorFlag = true;
    }
    if(this.state.trophyYear==""){
        trophyYearError="Please enter trophy year"
        errorFlag = true;
     
    } 
    else if (!/^\d{4}$/.test(this.state.trophyYear)) {
      trophyYearError = "Please enter  4-digit year";
      errorFlag = true;
  }
    if(this.state.trophyResult==""){
     
      trophyResultError="Please enter trophy result"
        errorFlag = true;
    
    }
    if (errorFlag) {
      this.setState({
        error: {
          ...this.state.error,
          trophyName:trophyNameError,
          trophyYear:trophyYearError,
          trophyResult: trophyResultError,
        },
      });
      return;
    }

    this.props.addingTrpohyData(String(this.state.trophyName),Number(this.state.trophyYear),String(this.state.trophyResult),this.state.trophComment,this.state.trophyimagePreview,this.props.editTrophyData?.id)
    this.setState({
      trophyName: '',
      trophyYear: '',
      trophyResult:'',
      trophComment:'',
      trophyimagePreview:[],
    })
  }

  async componentDidMount(): Promise<void> {
    this.getTrophyNames()
    this.getTrophyResult()
    const lang = localStorage.getItem("lang") || "en"; 
    await (i18n as any).changeLanguage(lang);
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  async componentDidUpdate(prevProps: Props, prevState: S) {
    if(this.props.openTrophyModal != prevProps.openTrophyModal && this.props.openTrophyModal){
      
      if(this.props.editTrophyData!=undefined){
        this.setState({
          trophyName: this.props.editTrophyData.trophyName,
          trophyYear: String(this.props.editTrophyData.trophyYear),
          trophyResult:this.props.editTrophyData.trophyResult,
          trophComment:this.props.editTrophyData.trophyComment,
          trophyimagePreview: this.props.editTrophyData.trophyImages,
          trophyimageName:this.props.editTrophyData.image_name
        })
      }
      else{
        this.clearTrophy();
      }
    }  
    
    
    if (prevState.trophyName !== this.state.trophyName) {
      this.setState({
        error: {
          ...this.state.error,
          trophyName: "",
        },
      });
    }
    if (prevState.trophyYear !== this.state.trophyYear) {
      this.setState({
        error: {
          ...this.state.error,
          trophyYear: "",
        },
      });
    }
    if (prevState.trophyResult !== this.state.trophyResult) {
      this.setState({
        error: {
          ...this.state.error,
          trophyResult: "",
        },
      });
    }
  }

  clearTrophy = () => {
    this.setState({
      trophyName: '',
      trophyYear: '',
      trophyResult:'',
      trophComment:'',
      trophyimagePreview:[],
      error:{
        trophyName:  "",
        trophyYear: "",
        trophyResult:"",
      },
    })
  }

  // Customizable Area End
}
