import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import i18n from "../../../web/src/utilities/i18n";
import { calender } from "./assets";
import { toast } from "react-toastify";
import React from "react";
import { warningIcon } from "../../ContactsList/src/assets";
import { CardMedia } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  viewBulkTabsValue: number;
  selectedDays: any;
  selectWeekdays: boolean;
  selectWeekends: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  userSessionData: any;
  userToken: any;
  weekdays: any;
  weekends: any;
  workingDaysRequestID: string = "";
  getAllWorkingDaysApiRequestID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isLoading: false,
      viewBulkTabsValue: 0,
      selectedDays: [],
      selectWeekdays: false,
      selectWeekends: false,
    };
    this.weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    this.weekends = ["Saturday", "Sunday"];
    this.userSessionData =
      sessionStorage.getItem("userData") || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getAllWorkingDaysApiRequestID) {
        if(responseJson.data){
          const attributes = {
            weekdays: responseJson.data.attributes.weekdays,
            weekends: responseJson.data.attributes.weekends,
          };
    
          const selectWeekdays = this.weekdays.every((weekday: string) =>
            attributes.weekdays.includes(weekday)
          );

          this.setState({
            selectedDays: attributes.weekdays,
            selectWeekdays,
          });
    
        }
      } else if (apiRequestCallId === this.workingDaysRequestID) {
        if (responseJson.data) {
          this.createToastNotificationSuccess(configJSON.successMsg, calender);         
          this.getAllWorkingDayEvent();
        } else {
          this.createToastNotification(responseJson.errors[0]);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const lang = localStorage.getItem("lang") || "en";
    await (i18n as any).changeLanguage(lang);
    this.getAllWorkingDayEvent();   
  }

  translateText = (key: string, variables?: Record<string, any>) => {
    return (i18n as any).t(key, { ns: "translation", ...variables });
  };
  createToastNotification = (toastMesssage: string) => {
    toast.error(this.translateText(`${toastMesssage}`), {
      position: toast.POSITION.TOP_CENTER,
      icon: () => (
        <CardMedia component="img" src={warningIcon} alt="emptydata" />
      ),
    });
  };
  createToastNotificationSuccess = (
    toastMesssagee: string,
    toastIconI: string
  ) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">
          {this.translateText(`${toastMesssagee}`)}
        </div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        icon: () => <img src={toastIconI} alt="emptydata" />,
      }
    );
  };
  setScheduleTab = (obj: any, val: any) => {
    this.setState({
      viewBulkTabsValue: val,
    });
  };
  handleSelectWeekdays = () => {
    const { selectedDays, selectWeekdays } = this.state;

    if (selectWeekdays) {
      this.setState({
        selectWeekdays: false,
        selectedDays: selectedDays.filter(
          (day: any) => !this.weekdays.includes(day)
        ),
      });
    } else {
      const weekdaysToAdd = this.weekdays.filter(
        (day: any) => !selectedDays.includes(day)
      );
      this.setState({
        selectWeekdays: true,
        selectedDays: [...selectedDays, ...weekdaysToAdd],
      });
    }
  };

  handleSelectWeekends = () => {
    const { selectedDays, selectWeekends } = this.state;

    if (selectWeekends) {
      this.setState({
        selectWeekends: false,
        selectedDays: selectedDays.filter(
          (day: any) => !this.weekends.includes(day)
        ),
      });
    } else {
      const weekendsToAdd = this.weekends.filter(
        (day: any) => !selectedDays.includes(day)
      );
      this.setState({
        selectWeekends: true,
        selectedDays: [...selectedDays, ...weekendsToAdd],
      });
    }
  };

  handleDayCheckboxChange = (day: any) => {
    this.setState((prevState) => {
      const { selectedDays } = prevState;
      const isSelected = selectedDays.includes(day);
      const newSelectedDays = isSelected
        ? selectedDays.filter((d: any) => d !== day)
        : [...selectedDays, day];
      const selectWeekdays = this.weekdays.every((weekday: any) =>
        newSelectedDays.includes(weekday)
      );
      const selectWeekends = this.weekends.every((weekend: any) =>
        newSelectedDays.includes(weekend)
      );

      return {
        selectedDays: newSelectedDays,
        selectWeekdays,
        selectWeekends,
      };
    });
  };

  handleCancel = () => {
    this.setState({
      selectedDays: [],
      selectWeekdays: false,
      selectWeekends: false,
    });
  };

  navigateBacktoProfile = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "CustomisableUserProfiles"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  getWorkingDaysPayload = () => {
    const allDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const { selectedDays } = this.state;
    const weekends = allDays.filter((day: string) => !selectedDays.includes(day));
    
    const data = {
      weekdays:selectedDays,
      weekends,
    };
  
    return { data };
  };
  createWorkingDays = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };
    const payload = this.getWorkingDaysPayload();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.workingDaysRequestID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.workingDaysApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllWorkingDayEvent = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllWorkingDaysApiRequestID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.workingDaysApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );  

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
