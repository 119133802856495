// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  Modal,
  CardMedia,
  List,
  ListItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import CustomInputWeb from "./CustomInput.web";
import { connectBrevo } from "./assets";
import { configJSON } from "../../blocks/cfsendinblueapi/src/CfsendinblueapiController.web";
import ArrowBack from "@material-ui/icons/ArrowBack";

interface SyncBrevoScreenProps {
  classes: any;
  t: any;
  showSyncModal: boolean;
  closeSyncModal: () => void;
  syncImage: any;
}
const SyncBrevoScreen = (props: SyncBrevoScreenProps) => {
  const { classes, t,showSyncModal, closeSyncModal, syncImage } = props;

  const brevoLink = "https://login.brevo.com";
  
  return (
    <>
      <Modal
        open={showSyncModal}
        onClose={closeSyncModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.syncBrevoModal}>
          <Box className="brevo-input-header">
          </Box>
          <Box className="main-wrapper brevo-main-wrapper">
               <Box className="sync-brevo-screen">
                <CardMedia
                  component="img"
                  className="img-contact-book"
                  image={syncImage}
                />
                <Box className="box-empty-right">
                  <Typography className="txt-no-contact-title">
                  Sync data to Brevo
                  </Typography>
                  <Typography className="txt-no-contact-sub-title">
                  Let’s back up your marketing data (e.g. contacts) to Brevo.
                  </Typography>
                  <Button
                    //onClick={this.createCampaignOpen}
                    data-test-id="empty-campaign-btn-no-mails"
                  >
                  Sync my data to Brevo
                  </Button>
                </Box>

            </Box>
          </Box>
        </Box>
        
      </Modal> 
    </>
  );
};
export default SyncBrevoScreen;
// Customizable Area End
