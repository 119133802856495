import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  CardMedia,
  Popover,
  MenuItem,
  Menu,
  Drawer,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import { contactListEmpty, expand, close, menu } from "./assets";
import { customStyles } from "./ContactsListStyles.web";
import { withStyles } from "@material-ui/core/styles";
import ContactBooks from "./components/ContactBooks.web";
import AddGroupToContact from "./components/AddGroupToContact.web";

import CreateContactWeb from "./CreateContact.web";
import WebLoader from "../../../components/src/WebLoader.web";
import MergeContacts from "../../../components/src/MergeContacts.web";
import MergeContactsInfoModal from "../../../components/src/MergeContactInformationModel.web";
import ViewContactNew from "./ViewContactNew.web";
import GroupContacts from "./components/GroupContacts.web";
import { configJSON } from "./ContactsListController";
import BackArrow from "@material-ui/icons/ArrowBack";
import CustomMarketingSidebar from "../../../components/src/CustomMarketingSidebar.web";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
// Customizable Area End

import ContactsListController, { Props } from "./ContactsListController.web";

export class ContactsList extends ContactsListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes ,isMobile } = this.props;
    const {contacts ,sortedAndFilteredData, searchedContacts, isSearching ,isSortAndFiltering } =
      this.state;
      let draftParsed: any = "";
      const draftContact = localStorage.getItem("draftContact");
      if (draftContact) {
        draftParsed = JSON.parse(draftContact);
      }
    return (
      <>
        {this.state.isLoading && <WebLoader />}
        <div style={{position: "fixed", width: "100%", zIndex: 2}}>
        <GlobalHeaderWeb pageLink={this.state.pageLink} />
          </div>
       <Box className={classes.mainWrapper}>
        <Box className="mobile-heading">
                <Box className="back-btn-wrap">
                <BackArrow style={{color:"#2B2B2B"}} onClick={this.handleBackBtn} />
                </Box>
                <Typography className="heading" variant="h1">
                    {this.t(`${configJSON.contacts}`)}
                </Typography>
                <Box
                    className="marketing-menu"
                    data-test-id="marketingmenubar"
                    onClick={this.toggleMenuDrawer}
                >
                    <img src={menu} alt="menu" />
                </Box>
            </Box>
        <Box className="page-wrapper" sx={{
            paddingTop: contacts.length === 0 ? { xs: "10%", sm: "8%", md: "6%" } : "",
           }}>
          <Box className={classes.contactdrawer}>
              <Drawer
                classes={{
                  root: classes.campaignRootWrapper,
                  paper: classes.contactPaper,
                }}
                variant={isMobile ? "temporary" : "permanent"}
                open={isMobile ? this.state.isMenuDrawerOpened : true}
              >
                <CustomMarketingSidebar
                  toggleMenu={this.closeMenuDrawer} 
                  allTabsCount={this.state.contactsTabCount}                
                  t={this.t}
                />
              </Drawer>
            </Box>
          <Box className="main-content">
            {this.state.contacts.length > 0 ? (
              <ContactBooks
                classes={classes}
                contacts={contacts}
                handleShowContactDetails={this.handleShowContactDetail}
                closeCreateContact={this.handleCloseCreateContact}
                searchValue={this.state.searchKeyword}
                handleSearch={this.handleSearch}
                anchorEl={this.state.anchorEl}
                anchorElMore={this.state.anchorElMore}
                handleOpenMenuMore={this.handleOpenMoreMenu}
                handleCloseMenuMore={this.handleCloseMoreMenu}
                filterGroups={this.state.filterGroups}
                sortValue={this.state.sortValue}
                onChangeSort={this.onChangeSort}
                filterCompany={this.state.filterCompany}
                filterBusinessCategories={this.state.filterBusinessCategories}
                filterShippingCountry={this.state.filterShippingCountry}
                hasAnOpenDeal={this.state.hasAnOpenDeal}
                hasInteractions={this.state.hasInteractions}
                hasFavoriteProducts={this.state.hasFavoritesProduct}
                hasNote={this.state.hasNotes}
                handleChecked={this.handleChecked}
                handleCheckedHideColumns={this.handleCheckedHideColumns}
                checkedColumns={this.state.checkedColumns}
                name={this.state.name}
                email={this.state.email}
                group={this.state.group}
                favProduct={this.state.favProduct}
                interaction={this.state.interaction}
                openDeal={this.state.openDeal}
                handleColumnOpenMenu={this.handleColumnOpenMenu}
                handleColumnCloseMenu={this.handleColumnCloseMenu}
                anchorElColmun={this.state.anchorElColmun}
                handleInputChange={this.handleInputChange}
                groupsData={this.state.groups}
                businessCategoryList={this.state.businessCategoryList}
                searchedContacts={searchedContacts}
                isSearching={isSearching}
                isSortAndFiltering={isSortAndFiltering}
                sortedAndFilteredData={sortedAndFilteredData}
                countryDataList={this.state.countryDataList}
                t={this.t}
                data-test-id="contactbooks"
              />
            ) :
              <Box className="empty-box">
                <CardMedia
                  component="img"
                  className="img-contact-book"
                  image={contactListEmpty}
                />
                <Box className="box-empty-right">
                  <Typography className="txt-no-contact-title">
                    {this.t(`${configJSON.contactNotCreated}`)}
                  </Typography>
                  <Typography className="txt-no-contact-sub-title">
                    {this.t(`${configJSON.contactDefaultTxt}`)}
                  </Typography>
                  <Button onClick={this.handleOpenMenu} >+ {this.t(`${configJSON.newContact}`)}</Button>
                </Box>
              </Box>
            }
            {this.state.isMergeContact && (
              <MergeContacts
                classes={classes}
                contacts={contacts}
                isMergeContact={Boolean(this.state.isMergeContact)}
                handleShowContactDetails={this.handleShowContactDetail}
                closeCreateContact={this.handleCloseCreateContact}
                searchValue={this.state.searchKeyword}
                handleSearch={this.handleSearch}
                anchorEl={this.state.anchorEl}
                anchorElMore={this.state.anchorElMore}
                handleOpenMenuMore={this.handleOpenMoreMenu}
                handleCloseMenuMore={this.handleCloseMoreMenu}
                filterGroups={this.state.filterGroups}
                sortValue={this.state.sortValue}
                onChangeSort={this.onChangeSort}
                filterCompany={this.state.filterCompany}
                filterBusinessCategories={this.state.filterBusinessCategories}
                filterShippingCountry={this.state.filterShippingCountry}
                hasAnOpenDeal={this.state.hasAnOpenDeal}
                hasInteractions={this.state.hasInteractions}
                hasFavoriteProducts={this.state.hasFavoritesProduct}
                hasNote={this.state.hasNotes}
                handleChecked={this.handleChecked}
                handleInputChange={this.handleInputChange}
                groupsData={this.state.groups}
                businessCategoryList={this.state.businessCategoryList}
                handleMergeContactClose={this.handleMergeContactClose}
                handleMergeInfoSelectionOpen={this.handleMergeInfoSelectionOpen}
                contactDefaultDetails={this.state.contactDetails}
                isSearching={isSearching}
                searchedContacts={searchedContacts}
                sortedAndFilteredData={sortedAndFilteredData}
                isSortAndFiltering={isSortAndFiltering}
                countryDataList={this.state.countryDataList}
                t={this.t}
                data-test-id="mergeContact"
              />
            )}

            {this.state.floatCreateContact && (
              <Box className="btn-minimize-create-contact">
                <CardMedia
                  component="img"
                  src={expand}
                  className="expand-icon"
                  onClick={(event: any) => {
                    this.handleOpenCreateContact(event)
                  }}
                  data-test-id="openCreateContactPopoverShown"
                />
                <Typography className="create-txt"
                 data-test-id="handleOpenCreateContact"
                 onClick={(event: any) => {
                    this.handleOpenCreateContact(event)
                  }}> {draftParsed && draftParsed[0].firstName && draftParsed[0].lastName
                    ? `${draftParsed[0].firstName} ${draftParsed[0].lastName}`
                    : this.t(`${configJSON.createContact}`)}</Typography>
                <Button data-test-id="closeFloatCreateContact" onClick={this.closeFloatCreateContact}>
                  <CardMedia
                    component="img"
                    src={close}
                    className="close-icon"
                  />
                </Button>
              </Box>
            )}

            <Box className={contacts.length > 0 ? "create-contact-wrapper" : "create-contact-wrapper-hide"}>
              <Button
                className="btn-create-contact"
                data-test-id="btn-create-contact"
                onClick={this.handleOpenMenu}
              >
                + {this.t(`${configJSON.createContact}`)}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={this.state.openContactMenu}
                keepMounted
                open={Boolean(this.state.openContactMenu)}
                onClose={this.handleCloseMenu}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                className={contacts.length > 0 ? classes.dropDropdown : classes.dropDropdown2}
              >
                <MenuItem onClick={(event: any) => {
                  this.handleOpenCreateContact(event)
                  this.handleCloseMenu()
                }}>
                  {this.t(`${configJSON.addManually}`)}
                </MenuItem>
                <MenuItem onClick={this.handleBulkUploadNavigation}>
                  {this.t(`${configJSON.bulkUpload}`)}
                </MenuItem>
              </Menu>              
                <CreateContactWeb
                  handleCloseDialog={this.handleCloseCreateContact}
                  handleMinimizeCreateContact={this.openFloatCreateContact}
                  id={""}
                  navigation="/contacts"
                  contactList={this.getContactsList}
                  anchorElState={this.state.anchorEl}
                  handleEscCreateContact={this.handleEscCreateContact}
                  createContactUndo={this.createContactUndo}
                  data-test-id="create-contact-comp"
                />
            </Box>
          </Box>
          {this.state.addGroupToContact && (
            <AddGroupToContact
              modalOpen={this.state.addGroupToContact}
              modalClose={this.handleCloseAddGroupToContact}
              searchGroup={this.state.searchGroup}
              handleSearchGroup={this.handleSearchGroup}
              classes={classes}
              groups={this.state.groups}
              addToGroup={this.addToGroup}
              contsctDetails={this.state.contactDetails}
              t={this.t}
              openGroupState={this.state.openGroup}
              openGroup={this.handleOpenGroup}
              closeGroup={this.handleCloseGroup}
              data-test-id="addGroupToContact"
            />
          )}
          
          {this.state.showContactDetailModal && (
            <ViewContactNew
            classes={this.props.classes}
            isShowViewContactModal={this.state.showContactDetailModal}
            handleCloseViewContactModal={this.handleCloseContactDetail}
            contactDetails={this.state.viewContactData}
            handleOpenAddGroupToContact={this.handleOpenAddGroupToContact}
            handleMergeContactOpen={this.handleMergeContactOpen}
            handleSelectContactsOpen={this.handleSelectContactsOpen}
            data-test-id="viewContact"
            getLatestDetails={this.handleShowContactDetail}
            />
          )}

          {this.state.selectContacts &&
            <GroupContacts
              classes={classes}
              modalOpen={this.state.selectContacts}
              modalClose={this.handleSelectContactsClose}
              searchContactKeyword={this.state.searchKeyword}
              handleSearchContact={this.handleSearch}
              contacts={this.state.contacts}
              isSearching={this.state.isSearching}
              isSorting={this.state.isSortAndFiltering}
              handleSortContact={this.onChangeSort}
              searchedContacts={this.state.searchedContacts}
              sortedContacts={this.state.sortedAndFilteredData}
              subTitle="Choose contacts you want to add to this deal."
              data-test-id="groupContacts"
              t={this.t}
              editGroupEvent={function (): void {
                throw new Error("Function not implemented.");
              }} />
          }
          {this.state.isSelectMergeInfo &&
            <MergeContactsInfoModal
              classes={this.props.classes}
              isSelectMergeInfo={this.state.isSelectMergeInfo}
              handleMergeInfoSelectionClose={this.handleMergeInfoSelectionClose}
              contactMergeDetails={this.state.contactMergeDetails}
              mergeContact={this.mergeContact}
              data-test-id="mergeContactModal"
            />
          }
        </Box>
      </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(ContactsList));
// Customizable Area End
