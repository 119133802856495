// Customizable Area Start
import React from "react";
import { Box, Typography, Button, CardMedia } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBack from "@material-ui/icons/ArrowBack";
import {
  imgGradeA,
  imgGradeB,
  imgGradeC,
  imgGradeD,
  imgGradeE,
  creativeExperiment,
} from "./assets";
export const configJSON = require("./config");
interface GradeScoreModalProps {
  classes: any;
  modalOpen: boolean;
  modalClose: () => void;
  submittedData: any;
  openRetakeQuiz: (quizId: string, questionTitle: string) => void;
  openSimulateScreen: (questionId: string) => void;
  openFullDetails: (questionId: string) => void;
  t:(key:string) => React.ReactNode;
}

const GradeScoreModal = (props: GradeScoreModalProps) => {
  const {
    classes,
    modalOpen,
    modalClose,
    submittedData,
    openRetakeQuiz,
    openSimulateScreen,
    openFullDetails,
    t
  } = props;
  let gradeImage = "";
  let gradeTitle = "";
  let gradeHeadline = "";
  let quizTitle = submittedData.attributes.title;
  let quizId = submittedData.attributes.id;
  console.log(submittedData);
  if (submittedData.attributes.score === "A") {
    gradeImage = imgGradeA;
    gradeTitle = "Grade A";
    gradeHeadline = configJSON.gradeAText;
  } else if (submittedData.attributes.score === "B") {
    gradeImage = imgGradeB;
    gradeTitle = "Grade B";
    gradeHeadline = `${t(configJSON.gradeBText)}`
  } else if (submittedData.attributes.score === "C") {
    gradeImage = imgGradeC;
    gradeTitle = "Grade C";
    gradeHeadline = configJSON.gradeCText
  } else if (submittedData.attributes.score === "D") {
    gradeImage = imgGradeD;
    gradeTitle = "Grade D";
    gradeHeadline = configJSON.gradeDText
  } else if (submittedData.attributes.score === "E") {
    gradeImage = imgGradeE;
    gradeTitle = "Grade E";
    gradeHeadline = configJSON.gradeEText
  }

  return (
    <Modal
      open={modalOpen}
      onClose={modalClose}
      className={classes.modalBoxCenter}
    >
      <Box className={classes.modalQuizEScoreBox}>
        <Box className="header-mobile">
          <Link to="#" className="back-icon" onClick={modalClose}>
            <ArrowBack style={{ fontSize: "24px" }} />
          </Link>
        </Box>

        <Box className="quizscore-inner">
          <Box className="score-modal-heading">
            <Link to="#" className="close-icon" onClick={modalClose}>
              <CloseIcon style={{ fontSize: "24px" }} />
            </Link>
          </Box>
          <Box className="escore-content">
            <Box className="grade-score">
              <Typography className="primary-txt">
                {gradeHeadline}
              </Typography>
              <Typography className="sub-txt">
                {t("Your")} {submittedData.attributes.title} {t("E-Score is")}
              </Typography>
              <CardMedia
                component="img"
                className="grade-img"
                image={gradeImage}
                title={gradeTitle}
              />
              <Typography className="recommendation-title">
                {t("Our Recommendation")}
              </Typography>
              <Typography className="recommendation-content">
                To improve your {submittedData.attributes.title} E-Score,
                consider our
                <a
                  href="https://tools.etoh.fr/"
                  target="_blank"
                  className="hightlight-txt"
                >
                  economic consultation services
                </a>
                from the EtOH Shop. For any questions, don’t hesitate to reach
                out to EtOH over{" "}
                <a
                  href="https://tools.etoh.fr/"
                  target="_blank"
                  className="hightlight-txt"
                >
                  chat
                </a>
                .
              </Typography>

              <Box className="modal-bottom-view">
                <Button
                  className={`secondary-btn ${classes.secondaryButton} retake-quiz-btn`}
                  onClick={() => openRetakeQuiz(quizId, quizTitle)}
                >
                  {t("Retake E-Quiz")}
                </Button>
                <Button
                  className={`primary-btn ${classes.primaryButton} secondary-bottom-btn`}
                  data-testid="nextBtn"
                  onClick={() => openFullDetails(quizId)}
                >
                  {t("Full Details")}
                </Button>
              </Box>
            </Box>
            <Box sx={{ border: 1 }} className="try-simulation-mode">
              <CardMedia
                component="img"
                image={creativeExperiment}
                title="Grade A"
                className="simulation-img"
              />
              <Typography className="primary-txt">
                {t("Try out Simulation Mode")}
              </Typography>
              <Typography className="sub-txt">
                {t("Use this mode to focus your improvements and see how changes to e-questions affect your overall e-score.")}
              </Typography>
              <Button
                variant="outlined"
                className={`secondary-btn ${classes.secondaryButton}`}
                onClick={() => openSimulateScreen(quizId)}
              >
                {t("Try Simulation Mode")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default GradeScoreModal;
// Customizable Area End
