// Customizable Area Start
import { WithStyles } from "@material-ui/core/styles";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { customStyles } from "./EmailAccountLogin.web";
import { requestDeviceToken } from "../../../components/src/Notification";
import { toast } from "react-toastify";
import React from "react";
import i18n, {fetchAllTranslations} from "../../../web/src/utilities/i18n";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id?: string;
  classes: any;
}

interface S {
  email: string;
  password: string;
  isEmailChecked: boolean;
  showLoginText: boolean;
  hideContinueButon: boolean;
  isPasswordVisible: boolean;
  createPassword: boolean;
  error: {
    emailError: {
      message: string;
      visible: boolean;
    };
    passwordError: {
      message: string;
      visible: boolean;
    };
  };
  emailNotExist: boolean | null;
  deviceToken: string | null;
  isFromBlur: boolean;
  apiCallInProgress: boolean;
  isLoading: boolean;
}
interface SS {
  id: any;
}

export default class EmailAccountLoginControllerBlock extends BlockComponent<
  Props,
  S,
  SS
> {
  loginApiID: string = "";
  welcomeApiID: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      email: "",
      password: "",
      isEmailChecked: false,
      isPasswordVisible: false,
      createPassword: false,
      showLoginText: false,
      hideContinueButon: false,
      error: {
        emailError: {
          message: "",
          visible: false,
        },
        passwordError: {
          message: "",
          visible: false,
        },
      },
      emailNotExist: null,
      deviceToken: null,
      isFromBlur: true,
      apiCallInProgress: true,
      isLoading: true,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    requestDeviceToken((token) => {
      this.setState({ deviceToken: token });
    });
    try {
      // Fetch translations and set the language
      await fetchAllTranslations();
      this.setState({ isLoading: false });
    } catch (error) {
      console.error('Error loading translations:', error);
    } finally {
      // Stop the loader once translations are loaded
      this.setState({ isLoading: false });
    }
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  
  
  loginApiSuccess = (responseJson: any) => {
    // user is logged in
    if (responseJson.errors) {
      const msg = responseJson.errors[0].failed_login;

      if (msg.toLowerCase().includes("password")) {
        if (this.state.isEmailChecked) {
          this.setState({
            error: {
              ...this.state.error,
              passwordError: {
                message: "Password is incorrect",
                visible: true,
              },
            },
          });
        }
      } else {
        this.setState({
          error: {
            ...this.state.error,
            emailError: {
              message: responseJson.errors[0].failed_login,
              visible: true,
            },
          },
        });
      }
    } else {
      sessionStorage.setItem("userData", JSON.stringify(responseJson));
      localStorage.setItem("userData", JSON.stringify(responseJson));
      if (
        responseJson.data.attributes.original_owner &&
        responseJson.data.attributes.profile_created === false
      ) {
        this.props.navigation.navigate("CreateProfile");
      } else if (
        responseJson.data.attributes.original_owner &&
        responseJson.data.attributes.owner_onboard === false
      ) {
        this.props.navigation.navigate("OwnerOnboardingBlock");
      } else {
        this.props.navigation.navigate("EquizDashboardWeb");
      }
    }
  };
  welcomeApiResponse = (responseJson: any) => {
    if (responseJson.message[0].status) {
      if (!responseJson.data.password_set) {
        this.setState({
          createPassword: true,
          hideContinueButon: false,
          error: {
            ...this.state.error,
            emailError: {
              message: "Please click on the link you received in your email to set a password.",
              visible: true,
            },
          },
        });
      } else {
        this.setState({
          emailNotExist: false,
          createPassword: false,
          showLoginText: true,
          hideContinueButon: false,
        });
      }
    } else {
      sessionStorage.setItem("userEmail", this.state.email);
      if(responseJson.message[0].has_social){
        this.setState({
          error: {
            ...this.state.error,
            emailError: {
              message: "You may have signed up with Google. Please try Google Login.",
              visible: true,
            },
          },
          emailNotExist: false,
          createPassword: false,
          showLoginText: true,
          hideContinueButon: true,
        });
      }else{
        this.setState({
          error: {
            ...this.state.error,
            emailError: {
              message: this.t(configJSON.newEmailTxt),
              visible: true,
            },
          },
          emailNotExist: true,
          createPassword: false,
          hideContinueButon: false,
        });
      }
      
    }
  };

  createToastNotification = (toastMesssage: string) => {
    toast.error(
      <div className="toast-notification">
        <div className="notification-txt">{toastMesssage}</div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER
      }
    );
  };
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const isWelcomeApi = apiRequestCallId===this.welcomeApiID;
      if(isWelcomeApi){
        this.setState({apiCallInProgress:false})
      }
      if ((responseJson?.error || errorReponse) && (( isWelcomeApi && !this.state.isFromBlur) || !isWelcomeApi)){
        this.createToastNotification("Something went wrong");
      }
      else if (apiRequestCallId === this.loginApiID) {
        this.loginApiSuccess(responseJson);
      } else if (isWelcomeApi) {
        this.welcomeApiResponse(responseJson);
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }
  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.email !== this.state.email) {
      this.setState({
        error: {
          ...this.state.error,
          emailError: {
            message: "",
            visible: false,
          },
        },
        emailNotExist: null,
        createPassword: false,
      });
    }
    if (prevState.password !== this.state.password) {
      this.setState({
        error: {
          ...this.state.error,
          passwordError: {
            message: "",
            visible: false,
          },
        },
      });
    }
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value });
  };
  handlePassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: event.target.value });
  };
  togglePasswordVisibility = () => {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
  };
  handleForgot = () => {
    this.props.navigation.navigate("ForgotPassword");
  };
  handleBack = () => {
    this.setState({ isEmailChecked: false, password: "" });
  };

  validateEmail = () => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.exec(this.state.email);
  };

  handleBlur = () => {
    if (this.state.isEmailChecked) {
      return;
    }
    if (this.state.email.trim() === "") {
      return;
    } else if (!this.validateEmail()) {
      this.setState({
        error: {
          ...this.state.error,
          emailError: { message: configJSON.webInvalidEmail, visible: true },
        },
      });
      return;
    }
    this.setState({isFromBlur:true,apiCallInProgress:true},this.handleWelcomApi);
    
  };

  handleWelcomApi = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.welcomeApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailValidateEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        email: this.state.email,
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleLogin = async (e: any) => {
    if (e) {
      e.preventDefault();
    }

    if (this.state.email.trim() === "") {
      this.setState({
        error: {
          ...this.state.error,
          emailError: {
            message: this.t(configJSON.webEmailRequiredMsg),
            visible: true,
          },
        },
      });
      return;
    }
    if (!this.state.createPassword && this.state.emailNotExist == null){
      if(!this.state.apiCallInProgress){
        this.setState({isFromBlur:false},this.handleWelcomApi);
      }      
      return;
    }
    if (this.state.createPassword) {
      return;
    }
    if (this.state.emailNotExist) {
      this.props.navigation.navigate("EmailAccountRegister");
      return;
    }
    if (!this.state.isEmailChecked) {
      if (!this.state.emailNotExist && this.state.emailNotExist !== null) {
        this.setState({
          isEmailChecked: true,
        });
      }
      return;
    }
    if (this.state.password.trim() === "" && this.state.isEmailChecked) {
      this.setState({
        error: {
          ...this.state.error,
          passwordError: {
            message: this.t(configJSON.webPwdRequiredMsg),
            visible: true,
          },
        },
      });
      return;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    
    let device_token = localStorage.getItem("device_token");
    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          type: "email_account",
          attributes: {
            email: this.state.email,
            password: this.state.password,
            device_token: device_token,
          },
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
// Customizable Area End
