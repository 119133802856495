export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const bulkUploadDocIcon = require("../assets/bulkUploadDocIcon.png");
export const ConnectAccountToastError = require("../assets/ConnectAccountToastError.png");
export const ConnectAccountBackArrow = require("../assets/connectAccountBackArrow.png");
export const bulkUploadTick = require("../assets/bulkUploadTick.png");
export const HomeImg = require("../assets/homeImg.png");
export const searchIcon = require("../assets/searchIcon.png");
export const filterIcon = require("../assets/filterIcon.png");
export const GroupImg = require("../assets/GroupImg.png");
export const uploadImg = require("../assets/upload-icon.png");
export const imgLogo = require("../assets/logo.png");
export const webemptyImage = require("../assets/empty-data.png");
export const menuIcon = require("../assets/menuIcon.png");
export const warningIcon = require("../assets/warning-red.png");
export const sortIcon = require("../assets/sort-icon.png");
export const filterSmIcon = require("../assets/filter-icon.png");
export const productsImg = require("../assets/alchol-img.png");
export const processingContact = require("../assets/processingContact.svg");
