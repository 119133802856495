import React from "react";
import { Box, Typography, Grid, FormControl, Select, MenuItem, Card } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Chart } from "react-charts";
import SalesDashboardController, { Props } from "./SalesDashboardController.web";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import { customStyles } from "./EquizStyles.web";
import { down_arrow } from "./assets";

export class SalesDashboard extends SalesDashboardController {
  render() {
    const { classes } = this.props;    
    const pageURL = window.location.pathname;
    const headerData = this.getHeaderData();
    const footerData = this.getFooterData();
    const primaryAxis = this.getPrimaryAxis();
    const secondaryAxes = this.getSecondaryAxes();
    const data = this.data;
    const tooltip = this.tooltip;

    return (
      <Box>
      {/* {this.state.isLoading && <WebLoader />} */}
      <Box
          className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper}`}
      >
          <GlobalHeaderWeb pageLink={pageURL} />
          <Box className={classes.innerWrapper}>

              <Box className={classes.teamModuleWrapper}>
                  <Grid className="box-center" container spacing={2}>
                      <Grid item sm={9} xs={9}>
                          <Box className="google-heading-wrapper">
                              <Box className="heading-left">
                                  <Typography className="heading" variant="h1">
                                      Statistics
                                  </Typography>
                              </Box>
                              <Box style={{marginRight: "-20px"}} className="heading-right  align-see-all">
                                  <Box className="filter-list">
                                      <FormControl className="select-control sortby-control">
                                          <Select
                                              MenuProps={{
                                                  anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "left",
                                                  },
                                                  transformOrigin: {
                                                      vertical: "top",
                                                      horizontal: "left",
                                                  },
                                                  getContentAnchorEl: null,
                                                  classes: { paper: classes.dropdownClass },
                                              }}
                                              // onChange={this.handleSubmittedQuizesSort}
                                              
                                              displayEmpty
                                              defaultValue={"Last 7 Days"}
                                              style={{fontFamily: "Expletus Sans", minWidth: "130px"}}
                                              inputProps={{ IconComponent: () => null }}
                                              renderValue={(value) => {
                                                  return (
                                                      <Box className={classes.filterBox}>
                                                          <span>{value as string}</span>
                                                          <img
                                                              className="select-icon"
                                                              style={{marginRight: "-20px", marginLeft: "15px"}}
                                                              src={down_arrow}
                                                              alt="sortVariant"
                                                          />
                                                      </Box>
                                                  );
                                              }}
                                              data-test-id="sbmt-quiz-dropdown"
                                              
                                          >
                                              <MenuItem className={classes.dropdown_ele} value="Last 4 weeks">{"Last 4 weeks"}</MenuItem>
                                              <MenuItem className={classes.dropdown_ele} value="This month">{"This month"}</MenuItem>
                                              <MenuItem className={classes.dropdown_ele} value="This quarter">{"This quarter"}</MenuItem>
                                              <MenuItem className={classes.dropdown_ele} value="This semester">{"This semester"}</MenuItem>
                                              <MenuItem className={classes.dropdown_ele} value="This year">{"This year"}</MenuItem>
                                          </Select>
                                      </FormControl>

                                  </Box>
                              </Box>                                    
                              <Box sx={{ width: "100%" }}>
                                  <Box sx={{ minWidth: 275 }} className="sales-card-row">
                                    {this.renderStatisticList(headerData, "header")}
                                  </Box>
                              </Box>
                          </Box>

                          <Box className="google-heading-wrapper">
                              <Box className="heading-left">
                                  <Typography className="heading" variant="h1">
                                      Deals
                                  </Typography>
                              </Box>
                              <Box style={{marginRight: "-20px"}} className="heading-right  align-see-all">
                                  <Box className="filter-list">
                                      <FormControl className="select-control sortby-control">
                                          <Select
                                              MenuProps={{
                                                  anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "left",
                                                  },
                                                  transformOrigin: {
                                                      vertical: "top",
                                                      horizontal: "left",
                                                  },
                                                  getContentAnchorEl: null,
                                                  classes: { paper: classes.dropdownClass },
                                              }}
                                              // onChange={this.handleSubmittedQuizesSort}
                                              
                                              displayEmpty
                                              defaultValue={"Last 7 Days"}
                                              style={{fontFamily: "Expletus Sans", minWidth: "130px"}}
                                              inputProps={{ IconComponent: () => null }}
                                              renderValue={(value) => {
                                                  return (
                                                      <Box className={classes.filterBox}>
                                                          <span>{value as string}</span>
                                                          <img
                                                              className="select-icon"
                                                              style={{marginRight: "-20px", marginLeft: "15px"}}
                                                              src={down_arrow}
                                                              alt="sortVariant"
                                                          />
                                                      </Box>
                                                  );
                                              }}
                                              data-test-id="sbmt-quiz-dropdown"
                                              
                                          >
                                              <MenuItem className={classes.dropdown_ele} value="Last 4 weeks">{"Last 4 weeks"}</MenuItem>
                                              <MenuItem className={classes.dropdown_ele} value="This month">{"This month"}</MenuItem>
                                              <MenuItem className={classes.dropdown_ele} value="This quarter">{"This quarter"}</MenuItem>
                                              <MenuItem className={classes.dropdown_ele} value="This semester">{"This semester"}</MenuItem>
                                              <MenuItem className={classes.dropdown_ele} value="This year">{"This year"}</MenuItem>
                                          </Select>
                                      </FormControl>

                                  </Box>
                                  <Typography className="see-all-txt">See All</Typography>
                              </Box>

                              <Box sx={{ width: "100%", height: "100%" }}>
                                  <Box className="google-card-row">
                                      <Box sx={{ width: "1500px", height: "400px" }}>
                                          <Chart
                                              options={{
                                                  data,
                                                  primaryAxis,
                                                  secondaryAxes,
                                                  tooltip,
                                              }}
                                          />
                                      </Box>
                                  </Box>
                              </Box>

                              <Box sx={{ width: "100%" }}>
                                  <Box sx={{ minWidth: 275 }} className="sales-card-row">
                                    {this.renderStatisticList(footerData, "footer")}
                                  </Box>
                              </Box>
                          </Box>
                      </Grid>
                  </Grid>
              </Box>
          </Box>
      </Box>
  </Box>    
  );
  }
}

export default withStyles(customStyles, { withTheme: true })(SalesDashboard);
