import React from "react";
import {
    Box,
    Typography,
    Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "./IntegrationStyles.web";

import StartIntegrationController, {
    Props,
} from './StartIntegrationController.web'
import AddIntegration from "./AddIntegration.web";


export class StartIntegration extends StartIntegrationController {

    constructor(props: Props) {
        super(props)
    }

    render() {

        const { classes } = this.props;
        const searchParams = new URLSearchParams(window.location.search);

        return (
            <>
                {this.state.isAddIntegration ? <AddIntegration state={this.state} backToStartIntegration={this.backToStartIntegration} /> : (
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        height={"100%"}
                        width={"100%"}
                    >
                        <Box display={"flex"}
                            width={"100%"}
                            justifyContent={"flex-start"}
                            alignItems={"center"} >
                            <img className={classes.image} src={require("../assets/logo.png")} alt="logo" />
                            <Box></Box>
                        </Box>
                        <Box className={classes.teamModuleWrapper}>
                            <Box className="heading-wrapper">
                                <Box className="heading-left">
                                    <img src={require("../assets/rafiki.svg")} alt="logo" />
                                </Box>

                                <Box className="heading-right">
                                    <Box>
                                        <Typography className={classes.heading} variant="h1">
                                            Lets add your integrations
                                        </Typography>
                                        <Box component="p" className={classes.subTxt}>
                                            You can add various integrations to enhance your experience with EtOH Suite
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "100%", marginY: 5, display: "flex", justifyContent: "space-between" }}>
                                        <Button
                                            className={`secondary-btn ${classes.secondaryButton}`}
                                            onClick={()=>this.skipToProfile(searchParams.has('code') ? 'Back' : 'Skip')}
                                            data-testid="skip-btn"
                                        >
                                            {searchParams.has('code') ? 'Back' : 'Skip'}
                                        </Button>
                                        <Button
                                            className={`primary-btn ${classes.primaryButton}`}
                                            onClick={this.addIntegration}
                                            data-testid="start-btn"
                                        >
                                            Start
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                        </Box>
                    </Box>
                )}</>
        );
    }
}

export default withStyles(customStyles, { withTheme: true })(StartIntegration);


