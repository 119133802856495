import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "../../../components/src/SchedulingStyles.web";
import SchedulingController, {
  Props,
  configJSON,
} from "./SchedulingController.web";
import { Typography, Tabs, Tab, Checkbox, Button } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import WebLoader from "../../../components/src/WebLoader.web";
import { checked } from "./assets";
import { TimeOffCalender } from "./TimeOffCalender.web";
function viewScheduleTabProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
// Customizable Area End

export class Scheduling extends SchedulingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { selectedDays, selectWeekdays, selectWeekends } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.isLoading && <WebLoader />}
        <div className={`${classes.headerSection} `}>
          <Typography
            className="backStyle"
            onClick={this.navigateBacktoProfile}
            data-test-id="back-btn"
          >
            <KeyboardBackspaceIcon className="backIcon" />
            {this.translateText(`${configJSON.backButton}`)}
          </Typography>
        </div>
        <div className={classes.schedulingTabwrapper}>
          <Tabs
            aria-label="simple tabs example"
            value={this.state.viewBulkTabsValue}
            onChange={this.setScheduleTab}
            data-test-id="viewScheduleTab"
          >
            <Tab
              label={this.translateText(`${configJSON.workingDaysTxt}`)}
              {...viewScheduleTabProps(0)}
            />
            <Tab
              label={this.translateText(`${configJSON.timeOffTxt}`)}
              {...viewScheduleTabProps(1)}
            />
          </Tabs>
          <TabPanel value={this.state.viewBulkTabsValue} index={0}>
            <div className="main-container">
              <div className="container">
                <div>
                  <Typography className="title">
                    {this.translateText(`${configJSON.workingDaysTxt}`)}
                  </Typography>
                  <Typography className="sub-title">
                    {this.translateText(`${configJSON.chooseWorkingDaysTxt}`)}
                  </Typography>
                </div>
                <div>
                  <div className="dropdown-menu-item-column">              
                    <Checkbox
                      checked={selectWeekdays}
                      onChange={this.handleSelectWeekdays}
                      data-test-id="selectWeekdays"
                      name="nameChecked"
                      checkedIcon={<img src={checked} alt="checkbox" />}
                    />
                    <Typography className="dropdown-menu-item-text">
                      {this.translateText(`${configJSON.weekDaysTxt}`)}
                    </Typography>
                    <Checkbox
                      checked={selectWeekends}
                      onChange={this.handleSelectWeekends}
                      data-test-id="selectWeekends"
                      name="nameChecked"
                      checkedIcon={<img src={checked} alt="checkbox" />}
                    />
                    <Typography className="dropdown-menu-item-text">
                      {this.translateText(`${configJSON.weekEndTxt}`)}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="days">
                {configJSON.daysOfWeek.map((day: any) => (
                  <div key={day.checkedName} className="day">
                    <Typography className="dropdown-menu-item-text">
                      {day.displayName}
                    </Typography>
                    <Checkbox
                      data-test-id="handleDayCheckboxChange"
                      name={day.checkedName}
                      checked={selectedDays.includes(day.checkedName)}
                      onChange={() =>
                        this.handleDayCheckboxChange(day.checkedName)
                      }
                      checkedIcon={<img src={checked} alt="checkbox" />}
                    />
                  </div>
                ))}
              </div>
              <div className="button-wrapper">
                <Button
                  className={`secondary-btn ${classes.secondaryButton}`}
                  onClick={this.handleCancel}
                  data-test-id="handleCancel"
                >
                  {this.translateText(`${configJSON.cancel}`)}
                </Button>
                <Button
                  className={this.state.selectedDays.length===0 ? "save-disabled" : `primary-btn ${classes.primaryButton}`}
                  onClick={this.createWorkingDays}
                  data-test-id="createWorkingDays"
                  disabled={this.state.selectedDays.length===0}
                >
                  {this.translateText(`${configJSON.save}`)}
                </Button>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={this.state.viewBulkTabsValue} index={1}>
            <TimeOffCalender navigation={""} id={"2"} classes={classes} />
          </TabPanel>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(Scheduling);
// Customizable Area End
