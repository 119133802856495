Object.defineProperty(exports, "__esModule", {
    value: true
  });

//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";

exports.profileValidationSettingsAPiEndPoint = "profile/validations";
exports.passwordRecoveryStartOtpAPiEndPoint = "forgot_password/otp"
exports.passwordRecoveryConfirmOtpAPiEndPoint = "otp_confirmation"
exports.passwordRecoveryChangePasswordAPiEndPoint = "forgot_password/password"
exports.forgotPasswordAPiContentType = "application/json";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";
exports.phoneNumberIsNotValid = "Phone number is not valid";
exports.phoneNumberIsRequired = "Phone number is required";
exports.otpCodeIsRequired = "Phone number is required";
exports.pleaseEnterAPassword = "Please enter a password";
exports.passwordMustBeAtLeast2Characters = "Password must be at least 2 characters";
exports.pleaseConfirmYourPassword = "Please confirm your password";
exports.passwordsMustMatch = "Passwords must match";
exports.invalidEmailAddress = "Invalid email address";
exports.invalidPassword = "Invalid password";
exports.goToOtpAfterPhoneValidationErrorTitle = "Error";
exports.goToOtpAfterPhoneValidationErrorBody = "Please select country code";

exports.labelTextIsAccountRecovery = "Account Recovery";
exports.secondLabelText = "Please choose what type of account you signed up with."
exports.thirdLabelText = "To Reset your password, please enter the email associated with your account.";
exports.forthLabelText = "We sent a confirmation code to the following email:";
exports.fifthLabelText = "To Reset your password, please enter the phone number associated with your account."
exports.sixthLabelText = "We sent a confirmation code to the following phone:"

exports.firstInputAutoCompleteType = "email";
exports.firstInputPlaceholder = "Email";
exports.firstInputKeyboardStyle = "email-address";
exports.firstInputErrorColor = "red";

exports.buttonTextIsNext = "Next";
exports.buttonColorForNextButton = "#6200EE";

exports.secondInputAutoCompleteType = "tel";
exports.secondInputKeyboardType= "phone-pad"
exports.secondInputPlaceholder = "Mobile"
exports.secondInputErrorColor = "red";

exports.thirdInputPlaceholder = "Enter OTP";
exports.thirdInputErrorColor = "red";

exports.buttonTitleIsSMSPhoneAccount = "SMS (Phone) Account";
exports.buttonTitleIsEmailAccount = "Email Account";

exports.labelTextIsPleaseEnterYourNewPassword = "Please enter your new password.";
exports.labelTextIsYourPasswordHasBeenSuccessfullyChanged = "Your password has been successfully changed";

exports.handled = "handled";

exports.placeholderIsReTypePassword = "Re-Type Password";

exports.buttonTitleIsOk = "Ok"
exports.buttonColorForOkButton = "#6200EE"; 

exports.placeholderIsPassword = "password";
exports.countryCodeSelectorPlaceholder = "Select Country";
// Customizable Area Start

exports.labelTextAccountRecovery = "Create Password";
exports.placeholderReTypePassword = "New Password";
exports.FirstTimeCreatePassSubTitle = "Note: If you have been invited via Gmail and would like to use single sign on, you can navigate to the previous page and click Login with Google.";

exports.phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
exports.forgotpasswordAPiMethod = "POST";
exports.forgotPasswordApiEndpoint = "bx_block_forgot_password/otps";
exports.resetpasswordAPiMethod = "POST";
exports.resetPasswordAPiContentType = "application/json";
exports.resetPasswordApiEndpoint = "bx_block_forgot_password/otp_confirmations";
exports.webForgotPwdTxt="Forgot Password"
exports.webForgotSubTxt1="Enter the email you used to sign up to receive password reset instructions"
exports.webBtnSend="Send"
exports.webLabelEmail="Email"
exports.webBtnResendEmailTxt="Didn't receive the email? Resend"
exports.webResendSec="Resend in 00";
exports.webResendTxt="Resend in"
exports.webHavingTrouble="Having trouble?"
exports.webHavingTroubleTxt="Visit our Knowledge Base to find articles or submit a ticket."
exports.webBtnCancel="Cancel"
exports.webBtnKnowledgeBase="Knowledge Base"
exports.webLinkContactSupport="Contact Support";
exports.resetPasswordTxt=" Password Reset";
exports.resetSubTxt="Your password has been successfully reset. You can now login with your new credentials."
exports.loginTxt="Login";
exports.createNewPass="Create New Password";
exports.createPassSubTxt="Create a new password for your account";
exports.confirmPassTxt="Confirm Password";
exports.confirmNewPasswordTxt="Confirm new password";
exports.copyRightTxt="Copyright";
exports.downloadAppTxt="Download App";

exports.txtForgotPassword = "Forgot Password"
exports.txtForgotPasswordSubLabel = "Enter the email you used to sign up to receive password reset instructions"
exports.pleaseEnterYourNewPassword = "Create a new password for your account"
exports.txtNewPassword = "New Password"
exports.txtHavingTroubleTitle = "Having Trouble?"
exports.txtHavingTroubleText = "Having trouble? Visit our Knowledge Base to find articles or submit a ticket."
exports.btnTxtKnowledgeBase = "Knowledge Base"
exports.btnTxtCancel = "Cancel"
exports.linkKnowledgeBase = "https://etoh.freshdesk.com/support/home/"
exports.backButtonText = "Back"
exports.rememberTxt = "Remember Password? Login In"
// Customizable Area End
