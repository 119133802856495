import React from "react";
// Customizable Area Start
import { Box, Typography } from "@material-ui/core";
import { startTracker, pauseTracker, timerLarge } from "./assets";
import moment from "moment";
import { formatTime } from "../../../components/src/ReusableFunctions";
// Customizable Area End

import TimetrackingbillingControllerWeb, {
  Props,
  configJSON,
  // Customizable Area Start
  // Customizable Area End
} from "./TimetrackingbillingController.web";

export class TimeTrackingBillingWeb extends TimetrackingbillingControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, taskDetails } = this.props;

    const { time, timerRunning } = this.state;
    let taskPriority, taskDate, taskTitle, taskProgress;
    const formatHours = this.hoursToSeconds(taskDetails?.attributes?.hours);
    const progress = (time / formatHours) * 100;
    // 45 + 47
    // 47 - 45 = 2

    // const remainingProgress = 100 - progress;
    // const totalProgress = progress + remainingProgress;
    let progressClass;
    if (taskDetails && taskDetails.attributes) {
      taskPriority = taskDetails.attributes.priority;
      taskTitle = taskDetails.attributes.title;
      taskDate = taskDetails.attributes.due_date;
      taskProgress = taskDetails.attributes.completion_percent;
    }
    if (taskProgress === 0) {
      progressClass = "not-started";
    } else if (taskProgress < 100) {
      progressClass = "in-progress";
    } else {
      progressClass = "completed";
    }
    const currentProgress = progress - taskProgress;
    
    return (
      <>
        {this.props.type == "list" ? (
          <Box className={classes.timetrackerWrapper}>
            <Box className="title-wrapper">
              <Box
                className="title"
                onClick={() => {
                  this.props.viewTaskHandler(taskDetails.id);
                }}
                data-test-id={`task-${taskDetails.id}`}
              >
                {taskPriority !== "normal" && (
                  <Box className="priority-status" component="span">
                    {taskPriority == "critical" ? "!!" : "!!!"}
                  </Box>
                )}
                {taskTitle}
              </Box>
              <Box className="timer-display">
                <Box
                  onClick={this.startTaskTimerAPI}
                  data-test-id="timer-action"
                >
                  {timerRunning ? (
                    <img src={pauseTracker} alt="pause tracker" />
                  ) : (
                    <img src={startTracker} alt="start tracker" />
                  )}
                </Box>
              </Box>
            </Box>
            <Box className="task-desc">
              <Box className="desc-left">
                <Box className="date" component="span">
                  {moment(taskDate).format("MMM DD , YYYY")}
                </Box>
                <Box className="task-about">{taskDetails.attributes.project_title}</Box>
              </Box>
              <Box className="tracker-value">
                {formatTime(time)}/{formatTime(formatHours)}
              </Box>
            </Box>

            <Box className="progress-bar-wrapper">
              <Box className="progress-bar">
                <Box
                  className={`progress ${progressClass}`}
                  style={{ width: `${progress}%` }}
                ></Box>
              </Box>
              {!timerRunning && (
                <Box className={`progress-value`}>{taskProgress}%</Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box className={`${classes.timetrackerWrapper}`}>
            <Box className="left-section-wrapper">
              <Typography className="modal-title" component="h2">
                {taskPriority !== "normal" && (
                  <Box className="priority-status" component="span">
                    {taskPriority == "critical" ? "!!" : "!!!"}
                  </Box>
                )}

                {taskTitle}
              </Typography>
              <Box className="timer-display">
                <Box
                  onClick={this.startTaskTimerAPI}
                  data-test-id="timer-action"
                >
                  {timerRunning ? (
                    <img src={pauseTracker} alt="pause tracker" />
                  ) : (
                    <img src={timerLarge} alt="start tracker" />
                  )}
                </Box>
              </Box>
            </Box>
            <Box className="right-section-wrapper">
             
            </Box>
            
            <Box className="task-progress-block">
              <Box className="progress-info">
                <Box className="prec-value">{taskProgress}% {this.t("completed")}</Box>
                <Box className="total-value">
                  {formatTime(time)}/{formatTime(formatHours)}
                </Box>
              </Box>
              <Box className="progress-wrap">
                <Box
                  className="progress-inner"
                  style={{ width: `${taskProgress}%` }}
                >
                </Box>
                {timerRunning && 
                  <Box
                    className="current-progress"
                    style={{ width: `${currentProgress}%` }}
                  >
                    <Box className="current-tracker">
                      <Typography>{formatTime(time)}</Typography>
                    </Box>
                  </Box>
                   }
              </Box>
            </Box>
          </Box>
        )}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default TimeTrackingBillingWeb;
// Customizable Area End
