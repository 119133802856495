// Customizable Area Start
import React, {useEffect} from "react";
import i18n from "../../web/src/utilities/i18n";
import {
  useStyles,
  createTeams,
} from "../../blocks/email-account-registration/src/teams-web/CustomTable.web";
import { deleteOutline } from "../../blocks/ContactsList/src/assets";
import {
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TablePagination,
  Checkbox,
  Box,
} from "@material-ui/core";

export default function ContactBulkGroupTable(props: any) {
  const {
    renderTeams,   
    hideActionColumn,
    userRole,
    tableType = "teams",
    getCount,
    showDifferentLabel,
    multipleDelete,
    getSelectedRecords,
  } = props;

  const rows = renderTeams.map((team: any) => {
    return createTeams(
      team.id,
      team.attributes.title,
      team.attributes.users,
      5.4
    );
  });


  const classes = useStyles(props);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    if (
      getSelectedRecords !== null &&
      typeof getSelectedRecords === "function"
    ) {
      getSelectedRecords(selected);
    }
    
  }, [selected]);


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const t = (key: any, variables?: Record<string, any>) => {
    return (i18n as any).t(key, { ns: "translation", ...variables });
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const selectedIndex = selected.find((x: any) => x == name);

    let newSelected: any[] = [];

    if (selectedIndex == null) {
      newSelected = [...selected, name];
    } else {
      newSelected = [...selected.filter((x: any) => x != name)];
    }
    setSelected(newSelected);
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => (n.id ? n.id : n.name));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  let tableClass;
  if (userRole === "manager") {
    tableClass = "team-list-manager";
  } else if (userRole === "employee") {
    tableClass = "team-list-employee";
  }

  const tabName: string = tableType === "groups" ? "Group" : "Team";
  let selectionMessage: string;

  if (selected.length > 0) {
    selectionMessage =
      selected.length === 1
        ? `1 ${tabName} ${t("Selected")}`
        : `${selected.length} ${tabName}s ${t("Selected")}`;
  } else {
    const countTab: string = rows.length === 1 ? tabName : `${tabName}s`;
    selectionMessage = `${rows.length} ${countTab}`;
  }

  const isSelected = (name: any) => selected.indexOf(name) !== -1;
 
  return (
    <div className={classes.root}>
      <Box>
        <Box className={classes.paginationToolbar}>
          <Box className="total-member">
            <Box className="member-count">
              <Checkbox
               indeterminate={
                selected.length > 0 && selected.length < rows.length
              }
              checked={rows.length > 0 && selected.length === rows.length}
                onChange={handleSelectAllClick}
              />
              {selectionMessage}
              {selected.length > 0 && (
                <Box
                  className="delete-icon"
                  onClick={() => {multipleDelete("groups")}}
                  data-testid="delete-icon"
                >
                  <img src={deleteOutline} alt="deleteOutline" />
                </Box>
              )}
            </Box>
          </Box>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.paginationTable}
            {...(showDifferentLabel && {
              labelDisplayedRows: ({ count }) => `${count} of ${count}`,
            })}
          />
        </Box>

        <TableContainer>
          <Table
            className={`${
              hideActionColumn
                ? "bulk-upload-confirm"
                : `viewTeamList ${tableClass}`
            } ${classes.table}`}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell padding="normal">{t("Group Name")}</TableCell>
                <TableCell padding="normal">{t("Contacts")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => {
                  const isItemSelected = isSelected(
                    row.id ? row.id : row.name
                  );
                  return (
                    <TableRow hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event: any) =>
                            handleCheckboxChange(event, row.id ? row.id : row.name)
                          }
                        />
                      </TableCell>

                      <TableCell component="td" id="myid" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell component="td" id="myid" scope="row">
                        {row.users}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
// Customizable Area End
