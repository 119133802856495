import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import i18n from "../../../web/src/utilities/i18n";
import { toast } from "react-toastify";
import React from "react";
import { AddToCart , UserToken } from "../../../components/src/ReusableEnums";

interface ServiceAttributes {
  id: number;
  SKU: string;
  title: string;
  etoh_shop_category_id: number;
  etoh_shop_sub_category_id: number;
  visibililty_in_catalog: string | null;
  short_description: string;
  description: string;
  service_type: string;
  converted_price: string | null;
  converted_sale_price: string | null;
  converted_price_weekly: string | null;
  converted_sale_price_weekly: string | null;
  converted_price_monthly: string | null;
  converted_sale_price_monthly: string | null;
  converted_price_yearly: string | null;
  converted_sale_price_yearly: string | null;
  currency: string | null;
  is_featured: boolean;
  buyers_additional_details: boolean;
  created_at: string;
  updated_at: string;
  thumbnails: null | File[];
}

interface BxBlockCatalogueService {
  data: {
    id: string;
    type: string;
    attributes: ServiceAttributes;
  };
}

interface CartItemAttributes {
  quantity: number;
  duration: string | null;
  is_cart: boolean;
  account_id: number;
  bx_block_catalogue_service_id: number;
  bx_block_catalogue_service: BxBlockCatalogueService;
  selected_price: string;
  selected_sale_price: string;
  total_price: string;
}

interface File {
  id: number;
  filename: string;
  url: string;
}

interface CartItem {
  id: string;
  type: string;
  attributes: CartItemAttributes;
}

interface CartSummary {
  item: string;
  total_subscription_service: string;
  total_price: string;
  add_to_carts: { data :  CartItem[]};
}

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  isCartOpen: boolean;
  handleCloseCartEvent: () => void;
  setCartState?: (deleteID: string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  cartData: CartItem[];
  isLoading: boolean;
  selectedItems: any;
  isCart: boolean;
  oneTimePrice: string;
  subscriptionPrice: string;
  totalPrice: string;
  oneTimeItemCount: number;
  subscriptionItemCount: number;
  stripeApiKey: any;
  stripeProductsData: any;
  customQuantity: any;
  sessionID: string;
  stripeSuccessProductsData:any;
  orderPlacedID:string;
  durationType:string[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShoppingCartOrdersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCartDetailsRequestId: string = "";
  userSessionData: any;
  userToken: UserToken;
  deleteCartItemRequestId: string = "";
  updateQuantityRequestId: string = "";
  makePaymentRequestId: string = "";
  createOrderRequestId: string = "";
  paymentStatusApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      cartData: [],
      isLoading: false,
      selectedItems: [],
      isCart: false,
      oneTimePrice: "",
      subscriptionPrice: "",
      totalPrice: "",
      oneTimeItemCount: 0,
      subscriptionItemCount: 0,
      stripeApiKey: null,
      stripeProductsData: [],
      customQuantity: "1",
      sessionID: "",
      stripeSuccessProductsData:[],
      orderPlacedID:"",
      durationType:[]
      // Customizable Area End
    };

    // Customizable Area Start
    this.userSessionData = sessionStorage.getItem("userData") || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.error) {
        this.setState({ isLoading: false });
        return;
      } else {
        switch (apiRequestCallId) {
          case this.getAllCartDetailsRequestId:
            let subscriptionCount = 0;
            let oneTimeServiceCount = 0;
            let TotalquantityCount = 0;
            responseJson.add_to_carts.data.forEach((item: AddToCart) => {
              const serviceType =
                item.attributes.bx_block_catalogue_service.data.attributes
                  .service_type;
              TotalquantityCount = this.updateTotalQuantityCount(item, TotalquantityCount);
              if (serviceType === "Subscription") {
                subscriptionCount++;
              } else if (serviceType === "One Time") {
                oneTimeServiceCount++;
              }
            });
            const newStateArray = this.getSortedDurations(responseJson)
            const stripeProductsData = this.createStripeProductsData(responseJson)
            

            const stripeSuccessProductsData = {
              items: responseJson.add_to_carts.data.map((item: AddToCart) => ({                
                service_id: item.attributes.bx_block_catalogue_service.data.attributes.id,               
                price: item.attributes.selected_sale_price,
                quantity: item.attributes.quantity,
                duration: item.attributes.bx_block_catalogue_service.data.attributes
                .service_type ==="Subscription" ? item.attributes.duration : null,
              })),
            };

            this.setState({
              isLoading: false,
              cartData: responseJson.add_to_carts.data,
              oneTimePrice: responseJson.item,
              subscriptionPrice: responseJson.total_subscription_service,
              totalPrice: responseJson.total_price,
              oneTimeItemCount: oneTimeServiceCount,
              subscriptionItemCount: TotalquantityCount,
              stripeProductsData,
              stripeSuccessProductsData,
              durationType: newStateArray,
            });
            break;
          case this.deleteCartItemRequestId:
            this.setState({ isLoading: false });
            this.createToastNotificationSuccess(responseJson.message);
            this.setState({ selectedItems: [] });
            this.getAllCartData();
            break;
          case this.updateQuantityRequestId:
            this.setState({ isLoading: false });
            this.getAllCartData();
            break;
          case this.makePaymentRequestId:
            this.setState({
              isLoading: false,
              sessionID: responseJson.session_data.id,
            });
            if (responseJson.session_data.url) {
              window.location.href = responseJson.session_data.url; 
              sessionStorage.setItem('stripeProductsData', JSON.stringify({
                session_id: responseJson.session_data.id,
                total_price: this.state.totalPrice,
                items: this.state.stripeSuccessProductsData.items,
              })); 
            }
            sessionStorage.setItem("session_id",responseJson.session_data.id);                   
            break;            
            case this.paymentStatusApiCallId:
              this.setState({ isLoading: false });
              if(responseJson.payment_status==="paid"){
                this.createOrderAfterPayment();
              }
            break;
            case this.createOrderRequestId:
              this.setState({ isLoading: false });
              this.setState({orderPlacedID:responseJson.data.id})
              this.createToastNotificationSuccess("Order created successfully");
              sessionStorage.removeItem('session_id');
            break;
          default:
            this.parseApiCatchErrorResponse(this.translationShopEvent(errorResponse));
            break;
        }
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getAllCartData();
    if (window.location.pathname==="/payment-success") {
      this.checkPaymentSuccess();
    }
    const langT = localStorage.getItem("lang") ?? "en";
    await (i18n).changeLanguage(langT);
  }
  translationShopEvent(shopkey: string) {
    return i18n.t(shopkey, { ns: "translation" });
  }
  createToastNotificationSuccess = (toastMessage: string) => {
    toast.success(this.translationShopEvent(`${toastMessage}`), {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  getAllCartData = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCartDetailsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToCartApiId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteCartItems = (deleteID: string) => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      cart_id: deleteID,
      isCart: this.state.isCart,
    };
    if (this.props.setCartState) {
      this.props.setCartState(deleteID);
    }    
    this.deleteCartItemRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteCart}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateQuantityData = (productId: string, quantity: string | number) => {
    if (typeof quantity === "string" && quantity.trim() === "") {
      quantity = 1;
    } else {
      quantity = parseFloat(quantity as any);
    }

    if (quantity <= 1) {
      quantity = 1;
      this.setState((prevState) => ({
        customQuantity: {
          ...prevState.customQuantity,
          [productId]: 1,
        },
      }));
    }
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateQuantityRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addToCartApiId}/${productId}?quantity=${quantity}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleViewShop = () => {
    window.location.href = "/etoh-shop";
  };

  makePayment = async () => {
    const body = this.state.stripeProductsData;
    const headers = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.makePaymentRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.paymentApiPath
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleCustomQuantityChange = (itemId: string, event: { target: { value: string } }) => {
    const value = event.target.value.trim();
    if (value === "" || (parseFloat(value) > 0 && !value.includes("-"))) {
      this.setState((prevState) => ({
        customQuantity: {
          ...prevState.customQuantity,
          [itemId]: value,
        },
      }));
    }
  };
  checkPaymentSuccess = async () => {
    const sessionID = sessionStorage.getItem("session_id");  
    const headers = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.paymentStatusApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.paymentStatus}?session_id=${sessionID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

     this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createOrderAfterPayment = async () => {
    const sessionID = sessionStorage.getItem('session_id');
    const body =  {
      session_id: sessionID,
      total_price: this.state.totalPrice,
      items: this.state.stripeSuccessProductsData.items,
    }
    const headers = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createOrderRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createOrderApiPath
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleFillDetailsOpen = () => {
  window.location.href = `/order-history?openPopup=true&id=${this.state.orderPlacedID}`;
  }

  createStripeProductsData = (responseJson: CartSummary) => {  
    const productsData = responseJson && responseJson.add_to_carts && responseJson.add_to_carts.data;
  
    return {
      products: productsData.map((item) => {
        const {duration, selected_sale_price, bx_block_catalogue_service} = item.attributes;
        const {converted_sale_price_weekly, converted_sale_price_monthly, converted_sale_price_yearly } = bx_block_catalogue_service.data.attributes;
        let price:string;

        switch (duration) {
          case "yearly":
            price = converted_sale_price_yearly != null ? converted_sale_price_yearly.replace('€', '').trim().replace(/[^0-9.-]+/g, '') : "0";
            break; 
          case "monthly":
            price = converted_sale_price_monthly != null ? converted_sale_price_monthly.replace('€', '').trim().replace(/[^0-9.-]+/g, '') : "0";
            break;
          case "weekly":
            price = converted_sale_price_weekly != null ? converted_sale_price_weekly.replace('€', '').trim().replace(/[^0-9.-]+/g, '') : "0";
            break;
          default:
            price = selected_sale_price;
            break;
        }
        const parsedPrice = parseFloat(price) || 0;
        return {
          name: (item.attributes && item.attributes.bx_block_catalogue_service &&
                  item.attributes.bx_block_catalogue_service.data &&
                  item.attributes.bx_block_catalogue_service.data.attributes &&
                  item.attributes.bx_block_catalogue_service.data.attributes.title) || '',
          imgdata: (item.attributes && item.attributes.bx_block_catalogue_service &&
                     item.attributes.bx_block_catalogue_service.data &&
                     item.attributes.bx_block_catalogue_service.data.attributes &&
                     item.attributes.bx_block_catalogue_service.data.attributes.thumbnails &&
                     item.attributes.bx_block_catalogue_service.data.attributes.thumbnails[0] &&
                     item.attributes.bx_block_catalogue_service.data.attributes.thumbnails[0].url) || '',
          price: parsedPrice,
          quantity: item.attributes && item.attributes.quantity ? item.attributes.quantity : 0,
        };
      }),
    };
  };

  ConvertedPrice(item:CartItemAttributes) {
    const {duration, selected_price, bx_block_catalogue_service  } = item;
    const {converted_price_weekly, converted_price_monthly, converted_price_yearly } = bx_block_catalogue_service.data.attributes;

    switch (duration) {
      case "yearly":
        return converted_price_yearly;
      case "monthly":
        return converted_price_monthly;
      case "weekly":
        return converted_price_weekly;
      default:
        return selected_price; 
    }
  }
  ConvertedSalePrice(item:CartItemAttributes) {
    const {duration, selected_sale_price, bx_block_catalogue_service  } = item;
    const {converted_sale_price_weekly, converted_sale_price_monthly, converted_sale_price_yearly } = bx_block_catalogue_service.data.attributes;

    switch (duration) {
      case "yearly":
        return converted_sale_price_yearly;
      case "monthly":
        return converted_sale_price_monthly;
      case "weekly":
        return converted_sale_price_weekly;
      default:
        return selected_sale_price; 
    }
  }
  NewKeydown(event:React.KeyboardEvent) {    
    if (["e", "E", "-"].includes(event.key)) {
      event.preventDefault();
    }
  }
  generateSubtotalText(cartData: CartItem[], totalPrice: string) {
    const totalQuantity = cartData.reduce((sum, item) => {
      return sum + item.attributes.quantity;
    }, 0);
    const itemCount = totalQuantity;
    const itemText =
      itemCount > 1
        ? `${itemCount} ${this.translationShopEvent(configJSON.itemsTxtCapital)}`
        : `${itemCount} ${this.translationShopEvent(configJSON.itemTxt)}`;
  
    return `${this.translationShopEvent(configJSON.subtotalTxt)} (${itemText}): €${totalPrice}`;
  }
  getSortedDurations(responseJson: CartSummary) {
    return responseJson.add_to_carts.data
    .filter((item: CartItem) => item.attributes.duration !== null)
    .map((item: CartItem) => item.attributes.duration as string)
    .filter((value: string, index: number, self: string[]) => self.indexOf(value) === index)
    .sort((a: string, b: string) => {
      const sortOrder = ["weekly", "monthly", "yearly"];
      return sortOrder.indexOf(a) - sortOrder.indexOf(b);
    })
    .map((duration: string) => {
      if (duration === "monthly") return this.translationShopEvent("month");
      if (duration === "weekly") return this.translationShopEvent("week");
      if (duration === "yearly") return this.translationShopEvent("year");
      return duration;
    });
  }
  updateTotalQuantityCount(item: AddToCart, TotalquantityCount: number): number {
    const durationType = item.attributes.duration;
    if (durationType !== null) {
      TotalquantityCount += item.attributes.quantity;
    }
    return TotalquantityCount;
  }  
  viewNavigate = (itemId: any) => {
    window.location.href = `/OrderDetails/${itemId}`;
  }
  // Customizable Area End
}
