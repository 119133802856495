//Customizable Area Start
import React, { useState } from "react";
import {
    Box,
    Typography,
    Button,
    Card,
    Grid,
    Modal
} from "@material-ui/core";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "../EquizStyles.web";


export const configJSON = require("./../config");

import {
    eclipse,
    info_logo,
    green_eclipse,
    right_arrow
} from "../assets";

import GlobalHeaderWeb from "../../../customisableuserprofiles/src/GlobalHeader.web";
import UnfinishedTasksModal from "./UnfinishedTasksModal";
import SuggestionsToImproveModal from "./SuggestionsToImproveModal";


// import { customStyles } from "../EquizStyles.web";
const ProjectLeadDashboard = (props: any) => {

    const { classes } = props;
    const pageURL = window.location.pathname;

    const ongoingTasks = [
        {
         "title": "Red Wine Photoshoot ",
         "timer": "33:00/35:00 hrs",
         "day": "Wednesday (Mar 15)",
        },
        {
        "title": "Red Wine Photoshoot ",
        "timer": "33:00/35:00 hrs",
        "day": "Wednesday (Mar 15)",
        },
        {
        "title": "Red Wine Photoshoot ",
        "timer": "33:00/35:00 hrs",
        "day": "Wednesday (Mar 15)",
        },
    ];

    const tabValues = ["Unfinished", "Priority", "Past Due", "Completed"];

    const tasksOverTracked = {
        "total": 3500,
        "tracked": 3800,
    };

    const hoursTracked = {
        "total": 80,
        "tracked": 40,
    };

    const [activeTab, setActiveTab] = useState("Unfinished");
    const [isUnfinishedModal, setIsUnfinished] = useState(false);
    const [isHowToImprove, setIsHowToImprove] = useState(false);

    const handleTabClick = (tab: any) => {
      setActiveTab(tab);
    };

    const closeUnfinishedModal = () => setIsUnfinished(false);
    const closeImproveModal = () => setIsHowToImprove(false);

    return (
        <Box>
            {/* {this.state.isLoading && <WebLoader />} */}
            <Box
                className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper}`}
            >
                <GlobalHeaderWeb pageLink={pageURL} />
                <Box style={{marginTop: "80px"}} className={classes.innerWrapper}>
                    <Box className={classes.teamModuleWrapper}>
                        <Grid container spacing={2}>
                            <Grid item sm={8} xs={8}>
                                <Box className="google-heading-wrapper">
                                    <Box className="heading-left">
                                        <Typography className="pl_dashboard_head">
                                            <span>Mar 15th, 2025</span>
                                            <img style= {{width: "8px", height: "8px"}} src={eclipse} alt="eclipse"/>
                                            <span>On Track</span>
                                            <img src={green_eclipse} alt="eclipse"/>
                                        </Typography>
                                        <Typography className="heading head_align" variant="h1">
                                            <span>New Wine Launch</span>
                                           <img src={right_arrow} alt="arrow" />
                                        </Typography>
                                    </Box>
                                    <Box className="card-container-one" sx={{ width: "100%" }}>
                                        <Box className="google-card-row">
                                            <Card className={'followers-card'} onClick={() => setIsUnfinished(true)}>
                                                <CardContent className="content-card">
                                                    <Box className="right-content">
                                                        <Box className="sub-cards">
                                                            <span>
                                                              Unfinished
                                                            </span>
                                                             <span className= "info-container">
                                                             <img src={info_logo} alt="info" />
                                                             {/* <span className="info-box">Unfinished tasks are all tasks that are not completed 
                                                             (Open + In Progress).
                                                             </span> */}
                                                            </span>
                                                        </Box>
                                                        
                                                        <Box className="card-val" display="flex" justifyContent={'flex-start'} alignItems={"center"}>
                                                            <span>
                                                                60 &nbsp;
                                                            </span>
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                            <Card className={'followers-card'}>
                                                <CardContent className="content-card">
                                                    <Box className="right-content">
                                                        <Box className="sub-cards">
                                                            <span>
                                                              Priority
                                                            </span>
                                                            <img src={info_logo} alt="info" />
                                                        </Box>
                                                        
                                                        <Box className="card-val" display="flex" justifyContent={'flex-start'} alignItems={"center"}>
                                                            <span>
                                                                9 &nbsp;
                                                            </span>
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                            <Card className={'followers-card'}>
                                                <CardContent className="content-card">
                                                    <Box className="right-content">
                                                        <Box className="sub-cards">
                                                            <span>
                                                              Past Due
                                                            </span>
                                                            <img src={info_logo} alt="info" />
                                                        </Box>
                                                        
                                                        <Box className="card-val" display="flex" justifyContent={'flex-start'} alignItems={"center"}>
                                                            <span>
                                                                10 &nbsp;
                                                            </span>
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                            <Card className={'followers-card'}>
                                                <CardContent className="content-card">
                                                    <Box className="right-content">
                                                        <Box className="sub-cards">
                                                            <span>
                                                              Completed
                                                            </span>
                                                            <img src={info_logo} alt="info" />
                                                        </Box>
                                                        
                                                        <Box className="card-val" display="flex" justifyContent={'flex-start'} alignItems={"center"}>
                                                            <span>
                                                                5 &nbsp;
                                                            </span>
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className="google-heading-wrapper">
                                    <Box className="heading-container">
                                      <Box>
                                        <Typography className="heading" variant="h1">
                                           This week’s focus
                                        </Typography>
                                        <Typography>
                                         <Box className="tabs-container pl_tabs_container">
                                            {tabValues.map((tab) => {
                                            return(
                                                <Box key={tab} onClick={() => handleTabClick(tab)} className={activeTab === tab ? "activeTab" : "tabs"}>{tab}</Box>
                                            )
                                            })}
                                         </Box>
                                        </Typography>
                                      </Box>
                                        <Typography style={{ fontWeight: 500, fontSize: "14px", color: "#4BA3B7", fontFamily: "Expletus Sans" }} variant="h3">
                                            See All
                                        </Typography>
                                    </Box>

                                    <Box sx={{ width: "100%" }}>
                                        <Box className="ongoing-tasks-box">
                                            {ongoingTasks.map((obj) => {
                                              return(
                                               <Card className={'followers-card'} >
                                                <CardContent>
                                                    <Box className="ongoing-tasks-card">
                                                      <Box className="row-one">
                                                        <span>{obj.title}</span>
                                                        <span>{obj.timer}</span>
                                                      </Box>
                                                      <Box className="pl-row-three">
                                                        <span style={{fontSize: "12px"}}>{obj.day}</span>
                                                      </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                                )
                                            })}
                                        
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item sm={4} xs={4}>
                              <Box className="google-side-wrapper" >
                                <Typography style={{ fontWeight: 400, fontSize: "22px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                   Budget & Hours
                                </Typography>

                                <Box className={"budget-cards-container"}>
                                  <Box className={'performance-metrics-card'} >
                                    <Typography style={{ marginBottom: 10, fontWeight: 400, fontSize: "16px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="subtitle1">
                                        Tasks Over Tracked
                                    </Typography>
                                    <Typography>
                                        <span className={(tasksOverTracked.tracked > tasksOverTracked.total) ? "tasks-over-tracked" : ""} style={{fontSize: "26px"}}>{tasksOverTracked.tracked}</span>
                                        <span style={{fontSize: "12px", marginLeft: "2px"}}>/ {tasksOverTracked.total}</span>
                                    </Typography>
                                    <Typography style={{ fontWeight: 400, fontSize: "12px", color: "#94A3B8", fontFamily: "Roboto" }} variant="h1">
                                        40% of budget remaining
                                    </Typography>
                                  </Box>
                                  <Box className={'performance-metrics-card'} >
                                    <Typography style={{ marginBottom: 10, fontWeight: 400, fontSize: "16px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="subtitle1">
                                        Hours Tracked
                                    </Typography>
                                    <Typography>
                                        <span className={(hoursTracked.tracked > hoursTracked.total) ? "hours-over-tracked" : ""} style={{fontSize: "26px"}}>{hoursTracked.tracked}</span>
                                        <span style={{fontSize: "12px", marginLeft: "2px"}}>/ {hoursTracked.total}</span>
                                    </Typography>
                                    <Typography style={{ fontWeight: 400, fontSize: "12px", color: "#94A3B8", fontFamily: "Roboto" }} variant="h1">
                                        40% of time remaining
                                    </Typography>
                                  </Box>
                                </Box>
                                {(hoursTracked.tracked > hoursTracked.total) || (tasksOverTracked.tracked > tasksOverTracked.total)
                                  &&
                                  <Button onClick={() => setIsHowToImprove(true)} className="improve-btn">How can I improve?</Button>
                                }
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
            {isUnfinishedModal &&
                <UnfinishedTasksModal
                    classes={classes}
                    modalOpen={isUnfinishedModal}
                    closeModal={closeUnfinishedModal}
                    tasks={ongoingTasks}
                />
            }
            {
             isHowToImprove && 
               <SuggestionsToImproveModal
                 classes={classes}
                 modalOpen={isHowToImprove}
                 closeModal={closeImproveModal}
               />
            }
        </Box>
    );
};

// export default MarketingDashboard;

export default withStyles(customStyles, { withTheme: true })(ProjectLeadDashboard);
