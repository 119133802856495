// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  Modal,
  CardMedia,
  List,
  ListItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import CustomInputWeb from "./CustomInput.web";
import { connectBrevo } from "./assets";
import { configJSON } from "../../blocks/cfsendinblueapi/src/CfsendinblueapiController.web";
import ArrowBack from "@material-ui/icons/ArrowBack";

interface ConnectBrevoProps {
  classes: any;
  t: any;
  connectBtnHandler: () => void;
  isInputModal: boolean;
  closeModalHandler: () => void;
  brevoKeyVal: string;
  keyInputError: string;
  keyInputHandler: (event: any) => void;
  validateAccount: () => void;
}
const ConnectBrevoAPI = (props: ConnectBrevoProps) => {
  const { classes, t, connectBtnHandler, isInputModal, closeModalHandler, brevoKeyVal, keyInputError,keyInputHandler, validateAccount,} = props;

  const brevoLink = "https://login.brevo.com";
  
  return (
    <>
      <Box className="empty-box">
        <CardMedia
          component="img"
          className="img-contact-book"
          image={connectBrevo}
        />
        <Box className="box-empty-right">
          <Typography className="txt-no-contact-title">
            {t(`${configJSON.connectBrevoTxt}`)}
          </Typography>
          <Typography className="txt-no-contact-sub-title">
            {t(`${configJSON.connectSubTxt}`)}
          </Typography>
          <Button
            onClick={connectBtnHandler}
            data-test-id="connect-brevo-account"
            className="brevo-connect-btn"
          >
            {t(`${configJSON.connectbtnTxt}`)}
          </Button>
        </Box>
      </Box>
      {isInputModal && 
      <Modal
        open={isInputModal}
        onClose={closeModalHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        //className={classes.modalBoxCenter}
      >
        <Box className={classes.brevoInputWrapper}>
          <Box className="brevo-input-header">
            <ArrowBack onClick={closeModalHandler} />
          </Box>
          <Box className="main-wrapper brevo-main-wrapper">
            <Box className="main-content-wrapper">
              <Typography className="brevo-title">
                {" "}
                {t(`${configJSON.brevoAccountHeading}`)}{" "}
              </Typography>
              <Typography className="brevo-desc">
                {t(`${configJSON.brevoAccountSubHeading}`)}
              </Typography>
              <CustomInputWeb
                type="text"
                label="Brevo API key"
                value={brevoKeyVal}
                isDealForm={true}
                isRequired={true}
                errors={keyInputError}
                name="Brevo API Key"
                onChange={(event: any) =>
                  keyInputHandler(event.target.value)
                }
                data-test-id="brevoAPI-input"
              />
              <Box className="list-wrapper">
                <Typography className="brevo-desc">
                  {t(`${configJSON.setUpGuide}`)}
                </Typography>
                <List>
                  <ListItem>
                    {t(`${configJSON.step1Guide}`)}{" "}
                    <Link to={{ pathname: brevoLink }} target="_blank">{brevoLink}</Link>
                  </ListItem>
                  <ListItem>{t(`${configJSON.step2Guide}`)}</ListItem>
                  <ListItem>{t(`${configJSON.step3Guide}`)}</ListItem>
                </List>
              </Box>
              <Button
                onClick={validateAccount}
                data-test-id="validate-brevo-btn"
              >
                {t(`${configJSON.connectbrevo}`)}
              </Button>
            </Box>
            
          </Box>
        </Box>
        
      </Modal> 
    }
    </>
  );
};
export default ConnectBrevoAPI;
// Customizable Area End
