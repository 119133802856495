// Customizable Area Start
import React, { useEffect, useCallback, useState, useRef } from "react";
import {
  Modal,
  Box,
  IconButton,
  Button,
} from "@material-ui/core";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { activeDash, inActiveDash } from "../../blocks/ordermanagement/src/assets";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {rightArrow, leftArrow, fileIcon, playIcon} from './assets';
import FileSaver from "file-saver";
interface CustomInputProps {
    isOpen: boolean;
    onClose: () => void;
    viewDetails: any;
    classes: Record<string, string>;
    currentIndex: number;
    currentId: number;
    handleAfterChange: (currentSlide: number) => void;
    isEmailAttachment?: boolean;
}

const ImageModal = (props: CustomInputProps) => {
  const {
    isOpen,
    onClose,
    classes,
    viewDetails,
    currentIndex,
    currentId,
    handleAfterChange,
    isEmailAttachment,
  } = props;

  const checkFileType = (filename: string) => {
    const fileExtension = filename.split('.').pop()?.toLowerCase();
    return fileExtension || '';
  };
  const videoRef :any = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayClick = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };
 
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentIndex,
    adaptiveHeight: true,
    autoplay: false,
    autoplaySpeed: 10000,
    customPaging: (index: number) => (
      <img
        src={index === currentIndex ? activeDash : inActiveDash}
        alt="dot"
      />
    ),
    nextArrow: (
      <IconButton className="next-arrow">
        {/* <ArrowForwardIosIcon /> */}
        <img src={rightArrow}/>
      </IconButton>
    ),
    prevArrow: (
      <IconButton className="prev-arrow">
        {/* <ArrowBackIosIcon /> */}
        <img src={leftArrow}/>
      </IconButton>
    ),
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        handleAfterChange(currentIndex > 0 ? currentIndex - 1 : 0);
      } else if (event.key === 'ArrowRight') {
        handleAfterChange(currentIndex < viewDetails.attachments.length - 1 ? currentIndex + 1 : currentIndex);
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [isOpen, handleAfterChange, currentIndex, viewDetails]);


const handleDownload = (url: string, fileName: string) => {
  const fileType = url.split(".").pop()?.toLowerCase();
  
  if (["jpg", "jpeg", "png", "svg", "mp4"].includes(fileType || "")) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        FileSaver.saveAs(blob, fileName);
      })
      .catch((err) => {
        console.error("Error downloading file:", err);
      });
  } else {
    // Direct download for other file types (e.g., PDFs, CSVs)
    FileSaver.saveAs(url, fileName);
  }
}

  return (
    <Modal
      className={classes.modalImageDialog}
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      // style={{ backgroundColor: "rgba(0, 0, 0, 0.5) !important"}}
    >
      <Box className="image-container">
        <Box className="close-icon" onClick={onClose} data-test-id="handleFiltersClose">
          <CloseIcon className="close-icon" />
        </Box>
        <Box className={`image-wrapper-2 ${classes.carouselWrapper}`}>
          {viewDetails?.attachments?.length > 0 && (
            <Slider key={currentId} {...settings} className="carousel-slider">
              {viewDetails.attachments.map((item: any) => (
                <Box className="slider-item" key={item.id}>
                  <>
                    
                      {(() => {
                        const fileType = checkFileType(item.url);
                        if (fileType === "mp4") {
                          return (
                            <>
                            <div className="item-inner has-video">
                              <div className={`image-video-wrapper ${isPlaying && `is-video-playing`}`}>
                                <video controls={false}  ref={videoRef}
                                  onPause={() => setIsPlaying(false)}
                                  onEnded={() => setIsPlaying(false)}>
                                  <source src={item.url} type="video/mp4" />
                                </video>
                                {!isPlaying && (
                                <a 
                                  href="#"
                                  className="play-icon"
                                  onClick={handlePlayClick}
                                  style={{
                                    display:"inline-block",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    cursor: "pointer",
                                    zIndex: 5,
                                  }}
                                >
                                  <img src={playIcon} alt="Play Icon" />
                                </a>
                              )}
                              </div>
                            </div>
                            <Button
                            className={`primary-btn ${classes.primaryButton} download-button`}
                            href={item.url}
                            onClick={() => handleDownload(item.url,item.name)}
                            target="_blank"
                            download
                            style={{ marginTop: '10px' }}
                          >
                            Download
                          </Button>
                            </>
                          );
                        } else if (["jpg", "jpeg", "png", "svg"].includes(fileType)) {
                          return (
                            <>
                            <div className="item-inner has-image">
                              <div className="image-video-wrapper">
                                <img src={item.url} alt="sliderImg" />
                              </div>
                            </div>
                            <Button
                            className={`primary-btn ${classes.primaryButton} download-button`}
                            href={item.url}
                            onClick={() => handleDownload(item.url,item.name)}
                            target="_blank"
                            download
                            style={{ marginTop: '10px' }}
                          >
                            Download
                          </Button>
                          </>
                          );
                        } else {
                          return (
                            <>
                            <div className="item-inner has-document">
                              <div className="file-icon">
                                <em>
                                  <img src={fileIcon} alt="File icon" />
                                </em>
                                <p>{item.name}</p>
                              </div>
                            </div>
                            <Button
                            className={`primary-btn ${classes.primaryButton} download-button`}
                            href={item.url}
                            onClick={() => handleDownload(item.url,item.name)}
                            target="_blank"
                            download
                            style={{ marginTop: '10px' }}
                          >
                            Download
                          </Button>
                            </>
                          );
                        }
                      })()}
                    
                  </>
                </Box>
              ))}
            </Slider>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ImageModal;
// Customizable Area End
