export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const minusGray = require("../assets/minus-circle.svg");
export const menu = require("../assets/menu.png");
export const plusCircle = require("../assets/plus-circle.svg");
export const arrowGreenUp = require("../assets/arrow-down-circle.svg");
export const checkBoxEmpty = require("../assets/check-empty.png");
export const checkBoxFilled = require("../assets/check-filled.png");
export const bellBadge = require("../assets/bell-badge.svg");
export const sortby = require("../assets/tune-vertical.svg");
export const fileTree = require("../assets/file-tree.png");
export const menuIcon = require("../assets/menu.svg");
export const checkGreen = require("../assets/check-green.png");
export const timerSand = require("../assets/timer-sand.svg");
export const chatBubble = require("../assets/chat-bubble.svg");
export const minimizeGray = require("../assets/minimize-gray.png");
export const closeGray = require("../assets/close-gray.png");
export const cartIcon = require("../assets/shopping-cart.svg");
export const memberRevoked = require("../assets/member-revoked.png");
export const checkedIcon = require("../assets/account-multiple-plus-outline.png");
export const memberEdited = require("../assets/member-edited.png");
export const thumb = require("../assets/thumb.png");
export const projectCreate = require("../assets/project-create.png");
export const borderIcon = require("../assets/icon-border.svg");
export const accountPlus = require("../assets/account-plus-outline.svg");
export const uploadImg = require("../assets/upload-icon.png");
export const backArrowIcon = require("../assets/back-arrow-icon.svg");

export const starIcon = require("../assets/star.png");
export const unclickedStarIcon = require("../assets/unclicked-star.svg");
export const assigneeMultipleIcon = require("../assets/account-multiple-plus-outline.svg");
export const assigneeSingleIcon = require("../assets/account-plus-outline.svg");
export const calenderNotiFicationIcon = require("../assets/calendar-range.svg");
export const paymentIcon = require("../assets/payment_24px.svg");
export const booking = require("../assets/book.svg");

