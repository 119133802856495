//Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
import { setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props{
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  openModal: boolean;
  closeModal: ()=>void;
  // Customizable Area End
}

interface Category {
  Product: boolean,
  Asset:boolean,
  Consumable:boolean,
  Tool:boolean
}
interface SubCategory {
    Wine: boolean,
    Beer:boolean,
    Spirit:boolean,
    OtherProduct:boolean,
    Hardware:boolean,
    Machinery:boolean,
    Land:boolean,
    Vessel:boolean,
    Chemical:boolean,
    Fertilizer:boolean,
    PhytoProduct:boolean
}
export interface S {
  // Customizable Area Start
  inventoryItem:any[];
  switchCategory:Category
  switchSubCategory:SubCategory
  // Customizable Area End
}
export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CategoriesSubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionData: any;
  userToken: any;
  ownerPermissionApiId:string = "";
  showInventoryApiId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      inventoryItem:[],
      switchCategory:{
        Product: false,
        Asset:false,
        Consumable:false,
        Tool:false
      },
      switchSubCategory:{
        Wine: false,
        Beer:false,
        Spirit:false,
        OtherProduct:false,
        Hardware:false,
        Machinery:false,
        Land:false,
        Vessel:false,
        Chemical:false,
        Fertilizer:false,
        PhytoProduct:false
      }
      
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
  }

  async componentDidMount() {
  // Customizable Area Start
    this.showInventory();
    const lang = localStorage.getItem("lang") || "en"; 
    await (i18n as any).changeLanguage(lang);
  // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          if(apiRequestCallId == this.showInventoryApiId){
            this.setState({
              switchCategory : this.setCategorySwitches(responseJson),
              switchSubCategory: this.setSubCategorySwitches(responseJson),
            })
          } else if(apiRequestCallId == this.ownerPermissionApiId){
             if(responseJson.status === "ok"){
              await setStorageData('categoryData', JSON.stringify(this.state.switchCategory))
             }
          }
        }
      }
    }
    
    // Customizable Area End
  }
  
  // Customizable Area Start
  
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }

  setCategorySwitches = (permitted:any):Category => {
    return {
      Product: permitted?.Product?.is_mark ?? false,
      Asset:permitted?.Asset?.is_mark ?? false,
      Consumable:permitted?.Consumable?.is_mark ?? false,
      Tool:permitted?.Tool?.is_mark ?? false
    }
  }

  setSubCategorySwitches = (permitted:any) :SubCategory=>{
    return {
      Wine: permitted?.Product?.Wine?.is_mark ?? false,
      Beer:permitted?.Product?.Beer?.is_mark ?? false,
      Spirit:permitted?.Product?.Spirit?.is_mark ?? false,
      OtherProduct:permitted?.Product["Other Product"]?.is_mark ?? false,
      Hardware:permitted?.Tool?.Hardware?.is_mark ?? false,
      Machinery:permitted?.Tool?.Machinery?.is_mark ?? false,
      Land:permitted?.Asset?.Land?.is_mark ?? false,
      Vessel:permitted?.Asset?.Vessel?.is_mark ?? false,
      Chemical:permitted?.Consumable?.Chemical?.is_mark ?? false,
      Fertilizer:permitted?.Consumable?.Fertilizer?.is_mark ?? false,
      PhytoProduct:permitted?.Consumable["Phyto Product"]?.is_mark ?? false
    }
  }
  
  handleCategory =(event: React.ChangeEvent<HTMLInputElement>)=>{
    const name = event.target.name as keyof Category
    const checked=event.target.checked
    let subCategory:SubCategory = this.state.switchSubCategory;
    switch(name){
      case "Asset":
        subCategory = {...subCategory,Vessel:checked,Land:checked}
        break;
      case "Consumable":
        subCategory = {...subCategory,Chemical:checked,Fertilizer:checked,PhytoProduct:checked}
        break;
      case "Product":
        subCategory = {...subCategory,Beer:checked,Wine:checked,OtherProduct:checked,Spirit:checked}
        break;
      case "Tool":
        subCategory = {...subCategory,Hardware:checked,Machinery:checked}
        break;
    }
    this.setState({ switchCategory:{
      ...this.state.switchCategory,
      [name]:checked
    },switchSubCategory:subCategory },this.setPermittedItems);
    // this.setState({categorySwitch: event.target.checked})
  }

  handleSubCategory =(event: React.ChangeEvent<HTMLInputElement>)=>{
    const name = event.target.name as keyof SubCategory
    this.setState({ switchSubCategory:{
      ...this.state.switchSubCategory,
      [name]:event.target.checked
    } },()=>this.checkAllSubcategoryChecked(name));
  }

  checkAllSubcategoryChecked = (name:keyof SubCategory) =>{
    let isChecked = false;
    switch(name){
      case "Beer":
      case "Wine":
      case "Spirit":
      case "OtherProduct":
        isChecked = this.state.switchSubCategory.Beer || 
        this.state.switchSubCategory.Wine ||
        this.state.switchSubCategory.Spirit ||
        this.state.switchSubCategory.OtherProduct;
        this.setState({switchCategory:{...this.state.switchCategory,Product:isChecked}},this.setPermittedItems) 
        break;

      case "Land":
      case "Vessel":
        isChecked = this.state.switchSubCategory.Land || 
        this.state.switchSubCategory.Vessel;
        this.setState({switchCategory:{...this.state.switchCategory,Asset:isChecked}},this.setPermittedItems) 
        break;

      case "Machinery":
      case "Hardware":
        isChecked = this.state.switchSubCategory.Machinery || 
        this.state.switchSubCategory.Hardware;
        this.setState({switchCategory:{...this.state.switchCategory,Tool:isChecked}},this.setPermittedItems) 
        break;

      case "Chemical":
      case "Fertilizer":
      case "PhytoProduct":
        isChecked =  this.state.switchSubCategory.Fertilizer || 
        this.state.switchSubCategory.PhytoProduct || this.state.switchSubCategory.Chemical;
        this.setState({switchCategory:{...this.state.switchCategory,Consumable:isChecked}},this.setPermittedItems) 
        break;
    }
  }

  setPermittedItems=()=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    const body ={
      "permitted_inventory_hash":{
        "Tool": {
            "is_mark": this.state.switchCategory.Tool,
            "Hardware": {
                "is_mark": this.state.switchSubCategory.Hardware
            },
            "Machinery": {
                "is_mark": this.state.switchSubCategory.Machinery
            }
        },
        "Asset": {
            "is_mark": this.state.switchCategory.Asset,
            "Land": {
                "is_mark": this.state.switchSubCategory.Land
            },
            "Vessel": {
                "is_mark": this.state.switchSubCategory.Vessel
            },
        },
        "Product": {
            "is_mark": this.state.switchCategory.Product,
            "Beer": {
                "is_mark": this.state.switchSubCategory.Beer
            },
            "Wine": {
                "is_mark": this.state.switchSubCategory.Wine
            },
            "Spirit": {
                "is_mark": this.state.switchSubCategory.Spirit
            },
            "Other Product": {
                "is_mark": this.state.switchSubCategory.OtherProduct
            },
        },
        "Consumable": {
            "is_mark": this.state.switchCategory.Consumable,
            "Chemical": {
                "is_mark": this.state.switchSubCategory.Chemical
            },
            "Fertilizer": {
                "is_mark": this.state.switchSubCategory.Fertilizer
            },
            "Phyto Product": {
                "is_mark": this.state.switchSubCategory.PhytoProduct
            },
        }
      }
    }
  
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.userToken.meta.token,
    };
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ownerPermission
    );
    this.ownerPermissionApiId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  showInventory=()=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showInventory
    );
    this.showInventoryApiId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

}
//Customizable Area End
