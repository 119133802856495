// Customizable Area Start
import React, { useState, useEffect, useRef } from "react";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CustomInputWeb from "./CustomInput.web";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { TextField, Box, Link } from "@material-ui/core";
import CustomMenu from "./CustomMenu.web";
import i18n from "../../web/src/utilities/i18n";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiAutocomplete-inputRoot": {
        alignItems: "start",
      },
    },
    inputField: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(25,25,25,0.32)",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& .MuiFormLabel-root": {
        fontSize: "14px",
        lineHeight: "18px",
        color: "#94a3b8",
        letterSpacing: "0.0075em",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        display: "flex",
        width: "calc(100% - 48px)",
        "& .MuiInputLabel-asterisk": {
          marginLeft: "auto",
          fontSize: "18px",
          lineHeight: "23px",
          color: "#e86577",
        },
        "&.Mui-error": {
          color: "rgba(43, 43, 43, 0.87)",
        },
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "6px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "&.MuiOutlinedInput-adornedEnd": {
          paddingRight: "23px",
        },
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#b5b5b5",
        borderWidth: "1px",
      },
      "& .MuiOutlinedInput-input": {
        fontSize: "14px",
        lineHeight: "18px",
        color: "#2b2b2b",
        fontFamily: "Roboto",
        fontWeight: 400,
        letterSpacing: "0.0075em",
        padding: "20px 16px",
        "&.MuiOutlinedInput-inputAdornedStart": {
          paddingLeft: "0",
        },
      },
      "& .MuiInputLabel-outlined": {
        color: "#94a3b8",
      },
      "& .MuiInputLabel-outlined.Mui-focused, & .MuiFormLabel-filled": {
        fontSize: "12px",
        lineHeight: "16px",
        color: "#94A3B8",
        letterSpacing: "0.0075em",
        fontFamily: "Roboto",
        fontWeight: "400",
        width: "auto",
        "& ~.MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
        },
        "&.Mui-error": {
          color: "rgba(43,43,43,0.87)",
        },
      },
      "& .MuiInputLabel-outlined.Mui-focused ": {
        "& ~.MuiOutlinedInput-root.Mui-error": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#C7263E",
          },
        },
      },
      "& .MuiOutlinedInput-root.Mui-error": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#C7263E",
        },
      },
      "& .MuiFormLabel-filled ~.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#C7263E",
      },
      "& .visibility-icon": {
        color: "#94a3b8",
        width: "20px",
        height: "20px",
      },
      "& .Mui-error .visibility-icon": {
        color: "#C7263E !important",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "12px",
        letterSpacing: "0.0075em",
        color: "#00BA88",
        fontFamily: "Roboto",
        fontWeight: "400",
        "&.Mui-error": {
          color: "#C7263E",
        },
        "&.Mui-default": {
          color: "#2B2B2B",
        },
      },
    },
    addOptionWrapper: {
      borderRadius: "8px",
      filter: "drop-shadow(0px 1px 2.5px rgba(0,0,0,0.2))",
      backgroundColor: "#fafafa",
      padding: "19px 22px 22px",
      margin: "5px 0",
      "& .MuiOutlinedInput-input": {
        padding: "10px 0",
        fontSize: "14px",
        letterSpacing: "0px",
        lineHeight: "20px",
        color: "#000000",
        fontWeight: 500,
        fontFamily: "Expletus Sans",
      },
      "& .MuiFormLabel-root": {
        transform: "none",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-error": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "& .form-control": {
        marginBottom: "0",
      },
      "& .button-wrapper": {
        borderTop: "1px solid #e8ecf2",
        marginTop: "9px",
        paddingTop: "16px",
        display: "flex",
        justifyContent: "flex-end",
        "& .add-link": {
          marginRight: "28px",
          opacity: "0.671",
          fontSize: "14px",
          lineHeight: "18px",
          letterSpacing: "0",
          color: "#4ba3b7",
          fontWeight: 500,
          fontFamily: "Expletus Sans",
          "&.secondary-link": {
            color: "#2b2b2b",
          },
          "&:last-child": {
            marginRight: "0",
          },
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
      "& .add-item-title input::placeholder":{
        color:"#2B2B2B"
      }
    },
    optionList: {
    width: "100%",
    height: "48px",
    lineHeight: "48px",
  },
  optionListLink:{
    width: '100%',
    height:'100%',
    display:'block'
  }
  })
);

const filter = createFilterOptions<OptionType>();

const ITEM_HEIGHT = 48;
interface CustomOptionProps {
  options: any[];
  addEditCallback?: (data: any, setError?: any, setDialog?: any) => void;
  deleteCallback?: (data: any) => void;
  addLinkTitle: string;
  label: string;
  id: string;
  value: string;
  onChange: (event: any, newValue: any) => void;
  errors?: string | React.ReactNode;
  success?: string;
  errorMsgNoRecord?: string;
  noOptionTitle?: string;
  disabled?:boolean;
  notRequired?:boolean;
  hideOtherOptions?:boolean;
  placeHolder?:string;
  wrapperClass?:string;
  isinventoryPricing?:boolean;
  isIcon?:boolean;
}
export default function FreeSoloCreateOption(props: CustomOptionProps) {
  const classes = useStyles();
  const {
    options,
    addEditCallback,
    deleteCallback,
    addLinkTitle,
    label,
    id,
    value,
    onChange,
    errors,
    success,
    errorMsgNoRecord,
    noOptionTitle,
    disabled,
    notRequired,
    hideOtherOptions,
    placeHolder,
    wrapperClass,
    isinventoryPricing,
    isIcon
  } = props;
  const t = (key: any, variables?: Record<string, any>) => {
    return (i18n as any).t(key, { ns: "translation", ...variables });
  };
  const [open, setOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [optionId, setOptionId] = useState(null);
  const [title, setTitle] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);


  useEffect(() => {
    if (showDialog) {
      inputRef?.current?.focus();
    } else {
      setTitle("");
      setIsEdit(false);
    }
  }, [showDialog]);
  const handleCreate = React.useCallback(() => {
    if (title.trim() && addEditCallback) {
      addEditCallback(
        { title: title.trim(), id: optionId },
        setErrorTitle,
        setShowDialog
      );
    } else {
      setErrorTitle(errorMsgNoRecord ?? t("Please enter title"));
    }
  }, [title, optionId]);
  const handleCancel = React.useCallback(() => {
    setShowDialog(false);
  }, []);
  const handleDelete = React.useCallback((link: any) => {
    if (deleteCallback) {
      deleteCallback(link);
    }
  }, []);

  const handleEdit = (link: any) => {
    setOpen(false);
    setIsEdit(true);
    setOptionId(link.id);
    setShowDialog(true);
    setTitle(link.title);
    setErrorTitle("");
  };
  const setOpenHandler = React.useCallback(() => {
    setOpen(true);
  }, []);
  const setCloseHandler = React.useCallback(() => {
    if (!isMenuOpen) {
      setOpen(false);
    }
  }, [isMenuOpen]);
  const changeHandler = React.useCallback((event: any, newValue: any) => {
    if (!newValue) {
      onChange(event, null);
      setOpen(false);
    }
    else if (newValue.isAdd) {
      setShowDialog(true);
      setTitle(newValue.inputValue);
      setOpen(false);
    }
    else if (!newValue.noOption) {
      onChange(event, newValue);
      setOpen(false);
    }

  }, []);
  const filerOptions = React.useCallback((options: any, params: any) => {
    const filtered = filter(options, params);
    if (params.inputValue !== "") {
      filtered.push({
        inputValue: params.inputValue,
        title: `${noOptionTitle} '${params.inputValue}'`,
        noOption: true,
      });
      filtered.push({
        inputValue: params.inputValue,
        title: `Add "${params.inputValue}"`,
        isAdd: true,
      });
    }

    return filtered;
  }, []);

  const getOptionLabel = React.useCallback((option: any) => {
    if (typeof option === "string") {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option.title;
  }, []);

  const addNewLinkClick = React.useCallback(() => {
    setOpen(false);
    setShowDialog(true);
    setOptionId(null);
    setTitle("");
    setErrorTitle("");
  }, []);



  const renderOption = React.useCallback((option: any) => {
    return (
      <>
        {option.noOption ? (
          <div className={"MuiAutocomplete-noOptions"}>{option.title}</div>
        ) : (
          <>
            <Box className={`option-list ${classes.optionList}`}>
              {option.title}
              {option.linkDirect ? (
                <Link onClick={addNewLinkClick} className={classes.optionListLink} data-testid="addNewLink">{option.linkDirect}</Link>
              ) : null}
            </Box>
            {!option.linkDirect && option.id && !hideOtherOptions ? (
              <div className="menu-wrapper">
                <CustomMenu
                  links={[
                    {
                      title: "Edit",
                      event: handleEdit,
                      value: option,
                    },
                    {
                      title: "Delete",
                      event: handleDelete,
                      value: option,
                    },
                  ]}
                  handleOpenMenu={handleOpenMenu}
                  handleCloseMenu={handleCloseMenu}
                  data-testid="customMenu"
                />
              </div>
            ) : null}
          </>
        )}
      </>
    );
  }, []);
  const renderInput = React.useCallback(
    (params: any) => (
      <TextField
        {...params}
        label={label}
        variant="outlined"
        error={errors != "" && errors != undefined}
        helperText={success ?? errors ?? ""}
        className={classes.inputField}
        required={!notRequired}
      />
    ),
    [errors]
  );
  const inputHandleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(e.target.value);
      setErrorTitle("");
    },
    []
  );

  const handleOpenMenu = React.useCallback(() => {
    setIsMenuOpen(true);
  }, []);
  const handleCloseMenu = React.useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const sortedOptions = options.slice().sort((a, b) => {
    return a.title.localeCompare(b.title);
  });

  return (
    <Box className={`autocomplete-wrapper ${wrapperClass}`}>
      <Autocomplete
        popupIcon={isIcon ? <KeyboardArrowDownIcon /> : null}
        classes={{ popper: isinventoryPricing ? 'freeSoloBox' : '' }}
        open={open}
        value={title ? title : value}
        onOpen={setOpenHandler}
        //disableClearable
        onClose={setCloseHandler}
        onChange={changeHandler}
        filterOptions={filerOptions}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id={id}
        options={[...sortedOptions, { title: "", linkDirect: addLinkTitle }]}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        style={{ width: "100%" }}
        renderInput={renderInput}
        disabled={disabled}
        getOptionSelected={(option, value) =>
          option.id == value.id
        }
      />
      {showDialog && (
        <div className={classes.addOptionWrapper}>
          <CustomInputWeb
            type="text"
            value={title}
            onChange={inputHandleChange}
            refTest={inputRef}
            errors={errorTitle}
            data-testid="addEditTitle"
            placeholder={placeHolder ?? ""}
            customClass="add-item-title"
          />
          <Box className="button-wrapper">
            <Link className="add-link secondary-link" onClick={handleCancel}>
              {t("Cancel")}
            </Link>
            <Link className="add-link" onClick={handleCreate} data-testid="saveTitle">
              {isEdit ? t("Update") : t("Create")}
            </Link>
          </Box>
        </div>
      )}
    </Box>
  );
}

interface OptionType {
  inputValue?: string;
  linkDirect?: string;
  title: string;
  id?: string;
  isAdd?: boolean;
  noOption?: boolean;
}
// Customizable Area End