// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    Modal,
    Button,
    List,
    ListItem,
    ListItemIcon,
    Card,
    CardMedia,
    CardContent
} from "@material-ui/core";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import SortQuizWeb from "./../SortQuiz.web";
import {
    tuneVertical,uname, dotsHor
} from "./../assets";

import { makeStyles } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Close";

import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import i18n from "./../../../../web/src/utilities/i18n";


const OngoingTasksModal = (props: any) => {
    const { modalOpen, closeModal, classes, tasks } = props;
    console.log("classes: ", classes)

    const t = (key: any, variables?: any) => {
        return (i18n as any).t(key, { ns: "translation", ...variables })
    }

    const OngoingTasks = () => {
  
        const updated_tasks = tasks.map((obj: any) => {
            return(
                <Card style={{borderBottom: "1px solid #E8ECF2"}} className={'followers-card'} >
                 <CardContent>
                     <Box className="ongoing-tasks-card">
                       <Box className="row-one">
                         <span>{obj.name}</span>
                         <span>{obj.timer}</span>
                       </Box>
                       <Box className="row-two">
                         <span className="row-elem">
                             <span className="sub-head">Unfinished</span>
                             <span>{obj.unfinished}</span>
                         </span>
                         <span className="row-elem">
                             <span className="sub-head">High Priority</span>
                             <span>{obj.high_priority}</span>
                         </span>                                                       
                         <span className="row-elem">
                             <span className="sub-head">Past Due</span>
                             <span>{obj.past_due}</span>
                         </span>
                         <span className="row-elem">
                             <span className="sub-head">Completed</span>
                             <span>{obj.completed}</span>
                         </span>
                         <span className="row-elem">
                             <span className="sub-head">Total</span>
                             <span>{obj.total}</span>
                         </span>                                                        
                       </Box>
                       <Box className="row-three">
                         <span className="sub-head">Ongoing</span>
                         <span style={{fontSize: "12px"}}>{obj.ongoing}</span>
                       </Box>
                     </Box>
                 </CardContent>
             </Card>
          );
        });
        return updated_tasks;
      };

    return (
        <Modal
            open={modalOpen}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.ModalDialog}
            data-testId="categoryModal"
        >
            <Box className={classes.modalDialogBox}>
                <Box className="modal-heading mb5">
                    <Typography className="modal-title" component="h2">
                       Ongoing Tasks
                    </Typography>
                    <CloseIcon
                        className="close-icon"
                        onClick={closeModal}
                        data-testId="closeIcon"
                    />
                </Box>
                
                <Box className="modal-description">
                    <Box className={classes.dialogContentFb}>
                      <Typography style={{fontSize: "14px", marginTop: "-10px"}}>
                         Below are the tasks.  
                      </Typography>
                    </Box>
                    <Box sx={{ minWidth: 275 }} className={classes.fbCardRow}>
                    <Box className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper}`}>
                      {OngoingTasks()}
                    </Box>
                  </Box>
                </Box>
            </Box>
        </Modal>
    );
};
export default OngoingTasksModal;
// Customizable Area End
