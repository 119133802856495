export const ResolveLastMessage = (item: any) => {
    const lastMessage = item?.attributes?.last_message;
  
    if (lastMessage?.data?.attributes?.message && typeof lastMessage?.data?.attributes?.message === 'string') {
      return lastMessage.data.attributes.message;
    } else if (lastMessage?.data === null) {
      return null;
    } else {
      return lastMessage;
    }
  };