// Customizable Area Start
import React from "react";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./OwnerOnboardingBlock.web";
import { toast } from "react-toastify";
import i18n from "../../../web/src/utilities/i18n";
import { accountPlusBlack } from "../../TaskList/src/assets";
import { vectorPng } from "../../cfescore/src/assets";

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id?: string;
  classes: any;
  // newUser: boolean;
}

interface S {
  teamName: any;
  hours: any;
  hourlyPayActive: boolean;
  data: any[];
  jobTitle: any;
  jobTitles: any[];
  isDeleting: boolean;
  isDeletingTeam: boolean;
  deleteTeamId: string;
  deleteJobTitleId: string;
  errorMessage: any;
  teams: any[];
  team: any;
  error: {
    jobTitleError: {
      message: string;
      visible: boolean;
    };
    hoursError: {
      message: string;
      visible: boolean;
    };
    teamError: {
      message: string;
      visible: boolean;
    };
  };
}
interface SS {
  id: any;
}

export default class OwnerOnboardingController extends BlockComponent<
  Props,
  S,
  SS
> {
  validationApiCallId: string = "";
  createTitleApiCallId: string = "";
  createTeamApiCallId: string = "";
  editTeamApiCallId: string = "";
  fetchTitleListApiCallId: string = "";
  deleteJobTitleAPICallId: string = "";
  deleteTeamAPICallId: string = "";
  fetchTeamApiCallId: string = "";
  editTitleApiCallId: string = "";
  ownerOnboardApiCallId: string = "";
  setErrorTitle: any;
  showDialogTitle: any;
  setErrorTeam: any;
  showDialogTeam: any;
  userToken: any;
  userSessionData: any;

  labelHeader: string;
  ownerLabelHeaderHelperText: string;
  ownerTeamNameText: string;
  ownerCreateTeamNameText: string;
  ownerTeamNameHyperText: string;
  ownerTeamNameErrorText: string;
  ownerJobTitleText: string;
  ownerJobTitleHelperText: string;
  ownerHourText: string;
  ownerHourHyperText: string;
  hourlyPayActive: boolean = false;
  ownerButtonText: string;
  currentTeam: { id: number; title: string };
  currentJobTitle: { id: number };
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      teamName: "",
      hours: 0,
      data: [],
      teams: [],
      jobTitle: null,
      jobTitles: [],
      errorMessage: "",
      isDeleting: false,
      isDeletingTeam: false,
      deleteTeamId: "",
      deleteJobTitleId: "",
      hourlyPayActive: false,
      // team: "",
      team: null,
      error: {
        jobTitleError: {
          message: "",
          visible: false,
        },
        hoursError: {
          message: "",
          visible: false,
        },
        teamError: {
          message: "",
          visible: false,
        },
      },
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    if (
      this.userToken.data.attributes.original_owner &&
      this.userToken.data.attributes.owner_onboard===false
    ) {
      this.props.navigation.navigate("OwnerOnboardingBlock");
    } else {
      this.props.navigation.navigate("AccountCreation");
    }

    this.labelHeader = configJSON.ownerLabelHeader;
    this.ownerLabelHeaderHelperText = configJSON.ownerLabelHeaderHelperText;
    this.ownerTeamNameText = configJSON.ownerTeamNameText;
    this.ownerCreateTeamNameText = configJSON.ownerCreateTeamNameText;
    this.ownerTeamNameHyperText = configJSON.ownerTeamNameHyperText;
    this.ownerTeamNameErrorText = configJSON.ownerTeamNameErrorText;
    this.ownerJobTitleText = configJSON.ownerJobTitleText;
    this.ownerJobTitleHelperText = configJSON.ownerJobTitleHelperText;
    this.ownerHourText = configJSON.ownerHourText;
    this.ownerHourHyperText = configJSON.ownerHourHyperText;
    this.ownerButtonText = configJSON.ownerButtonText;
    this.currentTeam = { id: 0, title: "" };
    this.currentJobTitle = { id: 0 };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          switch (apiRequestCallId) {
            case this.fetchTeamApiCallId:
              this.setState({
                teams: responseJson.data,
              });
              break;
            case this.fetchTitleListApiCallId:
              this.setState({
                jobTitles: responseJson.data,
              });
              break;
            case this.createTitleApiCallId:
              this.createTitleSuccess(responseJson);
              break;
            case this.editTitleApiCallId:
              this.editTitleSuccess(responseJson);

              break;
            case this.createTeamApiCallId:
              this.createTeamApiResponse(responseJson);
              break;
            case this.editTeamApiCallId:
              this.editTeamSuccess(responseJson);
              break;
            case this.deleteTeamAPICallId:
              this.deleteTeamSuccess(responseJson);
              break;
            case this.deleteJobTitleAPICallId:
              this.deleteJobTitleResponse();
              break;
            case this.ownerOnboardApiCallId:
              this.ownerOnboardResponse(responseJson);
              break;
          }
        } else {
          this.checkTitleError(responseJson, apiRequestCallId);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
  }

  async componentDidMount() {
    this.fetchTeams();
    this.fetchTitleList();
    const langC = localStorage.getItem("lang") ?? "en"; 
    this.createToastNotificationSuccess("Profile Created",accountPlusBlack)
    await (i18n as any).changeLanguage(langC); 

  }

  hourlyPayAmount = () => {
    this.setState({hourlyPayActive: !this.state.hourlyPayActive})
  }

  createToastNotificationSuccess = (toastMesssage: string, toastIcon: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt profile-notify">
          <p>{this.t(`${toastMesssage}`)}</p>  
          <img src={vectorPng} alt="emptydata"/>
          </div>{" "}
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        closeOnClick: true,
        pauseOnHover: true,
        icon: () => <img src={toastIcon} alt="emptydata"/>,
      }
    );
  };

  t(key:any, variable?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variable } )
  }

  checkPrevStateTeams = (prevState: S) => {
    if (prevState.teams !== this.state.teams) {
      if (this.currentTeam.id !== 0) {
        const curTeam = this.state.teams.find(
          (x) => x.id == this.currentTeam.id
        );

        this.setState({
          team: {
            id: curTeam.id,
            title: curTeam.attributes.title,
          },
        });
      } else if (this.currentTeam.title !== "") {
        const curTeam = this.state.teams.find(
          (x) => x.attributes.title == this.currentTeam.title
        );

        this.setState({
          team: {
            id: curTeam.id,
            title: curTeam.attributes.title,
          },
        });
      }
      this.currentTeam = { id: 0, title: "" };
    }
  };

  checkPrevStateTitles = (prevState: S) => {
    if (prevState.jobTitles !== this.state.jobTitles) {
      if (this.currentJobTitle.id !== 0) {
        const curTitle = this.state.jobTitles.find(
          (x) => x.id == this.currentJobTitle.id
        );
        this.setState({
          jobTitle: {
            id: curTitle.id,
            title: curTitle.attributes.title,
          },
        });
      }
      this.currentJobTitle = { id: 0 };
    }
  };

  checkTeamAndTitle = (prevState: S) => {
    this.checkPrevStateTeams(prevState);
    this.checkPrevStateTitles(prevState);
  };

  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.checkTeamAndTitle(prevState);
    if (prevState.jobTitle !== this.state.jobTitle) {
      this.setState({
        error: {
          ...this.state.error,
          jobTitleError: {
            message: "",
            visible: false,
          },
        },
      });
    }
    if (prevState.hours !== this.state.hours) {
      this.setState({
        error: {
          ...this.state.error,
          hoursError: {
            message: "",
            visible: false,
          },
        },
      });
    }
    if (prevState.team !== this.state.team) {
      this.setState({
        error: {
          ...this.state.error,
          teamError: {
            message: "",
            visible: false,
          },
        },
      });
    }
  }

  ownerOnboardResponse = (responseJson: any) => {
    if (responseJson.data.id > 0) {
      sessionStorage.removeItem("userData");
      sessionStorage.setItem("userData", JSON.stringify({...responseJson,"meta":this.userToken.meta}));
      localStorage.removeItem("userProfileDetails");
      this.props.navigation.navigate("OnboardingWelcome");
    } else {
      this.createToastNotification(
        "Something went wrong when updating your profile. Please try again."
      );
    }
  };

  deleteJobTitleFromList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body = {
      id: this.state.deleteJobTitleId,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteJobTitleAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webDeleteJobTitleEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteJobTitleResponse = () => {
    if (
      this.state.jobTitle &&
      this.state.deleteJobTitleId == this.state.jobTitle.id
    ) {
      this.setState({ jobTitle: null });
    }
    this.setState({ deleteJobTitleId: "" });
    this.fetchTitleList();
    this.handleClose();
    this.createToastNotification("Title deleted Successfully");
  };

  createTitleSuccess = (responseJson: any) => {
    this.currentJobTitle.id = responseJson.data.attributes.id;
    this.showDialogTitle(false);
    this.fetchTitleList();
  };

  checkTitleError = (responseJson: any, apiRequestCallId: string) => {
    if (
      apiRequestCallId === this.createTitleApiCallId ||
      apiRequestCallId === this.editTitleApiCallId
    ) {
      const msg: string = responseJson.errors[0].message;
      if (msg.includes("already exists")) {
        this.setErrorTitle("Job title already exists");
      } else {
        this.setErrorTitle(msg);
      }
    }
  };

  editTitleSuccess = (responseJson: any) => {
    this.currentJobTitle.id = responseJson.id;
    this.showDialogTitle(false);
    this.fetchTitleList();
  };

  titleChangeHandler = (e: any, newValue: any) => {
    this.setState({
      jobTitle: newValue,
    });
  };

  createToastNotification = (toastMesssage: string) => {
    toast.success(toastMesssage, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  validateForm = () => {
    let errorFlag = false;
    let teamErr = this.state.error.teamError;
    let jobTitleErr = this.state.error.jobTitleError;
    let hourlyPayErr = this.state.error.hoursError;

    if (this.state.team === "" || this.state.team == null) {
      teamErr = { message: this.t(configJSON.ownerTeamNameError), visible: true };
      errorFlag = true;
    }
    if (this.state.jobTitle === "" || this.state.jobTitle == null) {
      jobTitleErr = { message: this.t(configJSON.ownerJobTitleError), visible: true };
      errorFlag = true;
    }
    if (this.state.hours === "" || this.state.hours <= 0) {
      hourlyPayErr = { message: configJSON.ownerHourError, visible: true };
      errorFlag = true;
    }

    if (errorFlag) {
      this.setState({
        error: {
          teamError: teamErr,
          jobTitleError: jobTitleErr,
          hoursError: hourlyPayErr,
        },
      });
      return false;
    }
    const userDetails = JSON.parse(localStorage.getItem('userProfileDetails') ?? '{}')
    // const firstName = userDetails.first_name !== "" || userDetails.first_name !== null : 

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.userToken.meta.token,
    };

    const httpBody = {
      data: {
        first_name: userDetails.first_name ?? this.userToken.data.attributes.first_name,
        last_name: userDetails.last_name ?? this.userToken.data.attributes.last_name,
        hourly_pay: this.state.hours,
        job_type: userDetails.job_type ?? this.userToken.data.attributes.job_type, 
        choose_team: this.state.team.id,
        job_role: this.state.jobTitle.title
      },
      company: {
        name: userDetails.company_name ?? this.userToken.data.attributes.company_name,
        city: userDetails.city ?? this.userToken.data.attributes.city,
        state: userDetails.state ?? this.userToken.data.attributes.state,
        country: userDetails.country ?? this.userToken.data.attributes.country,
        postal_code: userDetails.postal_code ?? this.userToken.data.attributes.postal_code,
        address: userDetails.company_place_address ?? this.userToken.data.attributes.company_place_address
      },
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ownerOnboardApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ownerOnboardAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodPatch
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  fetchTitleList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchTitleListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webTitleListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addJobTitle = (data: any, setError?: any, setShowDialog?: any) => {
    this.setErrorTitle = setError;
    this.showDialogTitle = setShowDialog;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (data.id != null) {
      this.editTitleApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.webUpdateJobTitleEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            id: data.id,
            title: data.title,
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.requestMethodPatch
      );
    } else {
      this.createTitleApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.webCreateJobTitleEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            title: data.title,
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getMethodPost
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteJobTitle = (data: any) => {
    this.setState({ isDeleting: true, deleteJobTitleId: data.id });
  };

  addEditTeam = (data: any, setError?: any, setShowDialog?: any) => {
    this.setErrorTeam = setError;
    this.showDialogTeam = setShowDialog;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (data.id != null) {
      this.editTeamApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.webUpdateTeamsEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            id: data.id,
            title: data.title,
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.requestMethodPatch
      );
    } else {
      this.createTeamApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.webcreateTeamsEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            title: [data.title],
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getMethodPost
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteTeam = (data: any) => {
    this.setState({ isDeletingTeam: true, deleteTeamId: data.id });
  };

  teamChangeHandler = (e: any, newValue: any) => {
    this.setState({ team: newValue });
  };

  handleClose = () => {
    this.setState({
      isDeleting: false,
      isDeletingTeam: false,
    });
  };

  deleteTeamFromList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body = {
      id: this.state.deleteTeamId,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTeamAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webDeleteTeamsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodDelete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchTeams = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchTeamApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webTeamsGetEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createTeamApiResponse = (responseJson: any) => {
    if (responseJson.created_teams.length > 0) {
      this.currentTeam.title = responseJson.created_teams[0];
      this.showDialogTeam(false);
      this.fetchTeams();
    } else {
      const msg: string = responseJson.failed_teams;
      this.setErrorTeam(msg);
    }
  };
  editTeamSuccess = (responseJson: any) => {
    this.currentTeam.title = responseJson.title;
    this.currentTeam.id = responseJson.id;
    this.showDialogTeam(false);
    this.fetchTeams();
  };
  deleteTeamSuccess = (responseJson: any) => {
    if (this.state.team && this.state.deleteTeamId == this.state.team.id) {
      this.setState({ team: null });
    }
    this.setState({ deleteTeamId: "" });
    this.fetchTeams();
    this.handleClose();
    this.createToastNotification("Team deleted Successfully");
  };
}
// Customizable Area End
