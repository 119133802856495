// Customizable Area Start
import React, { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import { Box, Menu, Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Link } from "react-router-dom";
import i18n from "../../../../web/src/utilities/i18n";
import { formatDate } from "./CustomTable.web";

const TableItems = (props: any) => {
  const {
    classes,
    id,
    memId,
    teamName,
    users,
    team,
    isItemSelected,
    labelId,
    tableType,
    membersType,
    hideActionColumn,
    userRole,
    checkBoxHandler,
    teamDetails,
    teamActions,
    memDetails,
    memActions,
    lastIndex,
    rowIndex,
    unCheckHandler,
  } = props;
 

  let teamTitle: string[] = [];
  let teamID: string[] = [];
  if (tableType == "members" && team !== null) {
    teamTitle = team.map((item: any) => {
      return item.title;
    });
    teamID = team.map((item: any) => {
      return item.id;
    });
  }

  const t = (key:any, variables?: Record<string, any>) => {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }

  const userSessionData: any = sessionStorage.getItem("userData") || localStorage.getItem("userData");
  const userDetails = JSON.parse(userSessionData) ;
  const showMeLabel =
    userDetails.data.id === memId ? (
      <Box component="span" className="show-me-link">
        {" "}
        {t(`Me`)}
      </Box>
    ) : (
      ""
    );
  const showMyTeam =
    userDetails.data.attributes.choose_team === id ? (
      <Box component="span" className="show-me-link">
        {" "}
        {t(`My team`)}
      </Box>
    ) : (
      ""
    );

  const [tableMenuEl, setTableMenuEl] = React.useState<null | HTMLElement>(
    null
  );
  const [expanded, setExpanded] = useState(false);

  const showMoreTeamsHandler = () => {
    setExpanded(!expanded);
  };

  const renderCheckboxCell = (
    isItemSelected: boolean,
    labelId: string,
    hideActionColumn: any,
    userRole: string,
  ) => {
    if (userRole !== "employee") {
    if (!hideActionColumn) {
      return (
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
            data-testid="select-checkbox"
            onClick={checkBoxHandler}
          />
        </TableCell>
      );
    } else {
      return null;
    }
  }
  };


  const renderNameCellTeams = (
    teamName: string,
    users: number,
    hideActionColumn: any,
    labelId: string,
    id: string,
    showMyTeam: any
  ) => {
    return (
      <TableCell
        className="name-cell"
        component="th"
        id={labelId}
        scope="row"
        padding="none"
      >
        {users == 0 || hideActionColumn ? (
          <Box className="name-col">
            <Link
              to={`/view-team-members/${teamDetails.id}`}
              className="table-link"
            >
              {teamDetails.teamName}
            </Link>
            </Box>
        ) : (
          <Box className="name-col">
            <Link
              to={`/view-team-members/${teamDetails.id}`}
              className="table-link"
            >
              {teamDetails.teamName} {showMyTeam}
            </Link>
          </Box>
        )}
      </TableCell>
    );
  };

  const renderTeamActions = (userRole: string, hideActionColumn: any) => {
    if (userRole !== "employee") {
      if (!hideActionColumn) {
        return (
          <TableCell className="action-link">
            <Link
              to="#"
              onClick={() =>{
                teamActions.renameAction(teamDetails.teamName, teamDetails.id);
                unCheckHandler();
              }
              }
             
              title="Rename"
              className="table-link"
              data-testid="rename-link"
            >
              {t(`Rename`)}
            </Link>
            <Link
              to="#"
              onClick={() => {teamActions.deleteAction(teamDetails.id); unCheckHandler();}}
              title="Delete"
              className="table-link"
              data-testid="delete-link"
            >
              {t(`Delete`)}
            </Link>
          </TableCell>
        );
      }
    }
  };

  const renderMem = () => {
    return (
      <>
       {!membersType && (
            <>
              <TableCell className="team-cell">
                {teamTitle.length === 0 && "Unassigned Members"}
                {teamTitle.length <= 2 ? (
                  teamTitle.join(", ")
                ) : (
                  <>
                    {teamTitle.slice(0, 2).join(", ")}
                    {!expanded && (
                      <Box
                        component="span"
                        onClick={showMoreTeamsHandler}
                        className="member-team-link"
                      >
                        {"+" + (teamTitle.length - 2) + " more"}
                      </Box>
                    )}
                  </>
                )}
                {expanded &&
                  ", " + teamTitle.slice(2, teamTitle.length).join(", ")}
              </TableCell>
            </>
          )}
      </>
    )
  }

  const renderMemberAction = () => {
    return (
      <>
      {membersType ? (
                <>
                  {userRole === "owner" && (
                    <>
                      {!memDetails.isOriginalOwner && (
                        <Link
                          to="#"
                          onClick={() => memActions.editAction(memDetails)}
                          title="Edit"
                          className="table-link"
                          data-testid="editMember"
                        >
                          {t(`Edit`)}
                        </Link>
                      )}
                      {memDetails.isOriginalOwner && showMeLabel && (
                        <Link
                          to="#"
                          onClick={() => memActions.editAction(memDetails)}
                          title="Edit"
                          className="table-link"
                          data-testid="editMember"
                        >
                          {t(`Edit`)}
                        </Link>
                      )}
                      <Link
                        to="#"
                        onClick={() =>
                          memActions.moveMemberAction(memId, teamTitle)
                        }
                        title="Deactivate"
                        className="table-link"
                        data-testid="moveto-link"
                      >
                        {t(`Move to`)}
                      </Link>
                      <Box className="table-menu">
                        <Button
                          className="table-link"
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(e) => setTableMenuEl(e.currentTarget)}
                          endIcon={<ArrowDropDownIcon />}
                        >
                          {t(`More`)}
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={tableMenuEl}
                          keepMounted
                          open={Boolean(tableMenuEl)}
                          onClose={() => setTableMenuEl(null)}
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            vertical: lastIndex === rowIndex ? "top" : "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: lastIndex === rowIndex ? "bottom" : "top",
                            horizontal: "right",
                          }}
                          className={classes.dropDropdown}
                        >
                          <MenuItem
                            onClick={() =>{
                              memActions.removeMemberAction(
                                memDetails.firstName,
                                memDetails.lastName,
                                memDetails.memId
                              );
                              setTableMenuEl(null);
                            }}
                            data-testid="removeTeam-link"
                          >
                            {t(`Remove from this Team`)}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              memActions.addMemberAction(memId);
                              setTableMenuEl(null);
                            }}
                            data-testid="addtoTeam-link"
                          >
                            {t(`Add to another Team`)}
                          </MenuItem>
                        </Menu>
                      </Box>
                    </>
                  )}
                  {userRole === "manager" && (
                    <>
                      <Link
                        to="#"
                        onClick={() =>
                          memActions.moveMemberAction(memId, teamTitle)
                        }
                        title="Deactivate"
                        className="table-link"
                        data-testid="moveto-link"
                      >
                        {t(`Move to`)}
                      </Link>
                      <Link
                        to="#"
                        onClick={() =>
                          memActions.removeMemberAction(
                            memDetails.firstName,
                            memDetails.lastName,
                            memDetails.memId
                          )
                        }
                        title="Deactivate"
                        className="table-link"
                        data-testid="moveto-link"
                      >
                        {t(`Remove`)}
                      </Link>
                    </>
                  )}
                </>
              ) : (
                <>
                  {memActions.showDeleteInMembers ? (
                    <>
                      <Link
                        to="#"
                        onClick={() => memActions.deleteAction(memId)}
                        title="delete"
                        className="table-link"
                        data-testid="deleteMemberLink"
                      >
                        {t(`Delete`)}
                      </Link>
                    </>
                  ) : (
                    <>
                      {userRole === "owner" && memActions.showEditOption && (
                        <>
                          {!memDetails.isOriginalOwner && (
                            <Link
                              to="#"
                              onClick={() => memActions.editAction(memDetails)}
                              title="Edit"
                              className="table-link"
                              data-testid="editMembers-34"
                            >
                              {t(`Edit`)}
                            </Link>
                          )}
                          {memDetails.isOriginalOwner && showMeLabel && (
                            <Link
                              to="#"
                              onClick={() => memActions.editAction(memDetails)}
                              title="Edit"
                              className="table-link"
                              data-testid="editMembers-34"
                            >
                              {t(`Edit`)}
                            </Link>
                          )}
                        </>
                      )}
                      {userRole === "owner" && (
                        <>
                         <Box className="table-menu">
                          <Button
                            className="table-link"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            data-test-id="checkMore"
                            onClick={(e) => setTableMenuEl(e.currentTarget)}
                            endIcon={<ArrowDropDownIcon />}
                          >
                            {t(`More`)}
                          </Button>
                          <Menu
                            id="simple-menu"
                            data-test-id="menuoption"
                            anchorEl={tableMenuEl}
                            keepMounted
                            open={Boolean(tableMenuEl)}
                            onClick={() => setTableMenuEl(null)}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            className={classes.dropDropdown}
                          >
                            <MenuItem
                              onClick={() => memActions.addMemberAction(memId)}
                              data-testid="addtoTeam-link"
                            >
                              {t(`Add to Team`)}
                            </MenuItem>
                            {memActions.showDeactivateOption && (
                              <>
                                {memDetails.isDeactivated ?
                                <MenuItem
                                  onClick={() =>
                                    memActions.reActivateAction(
                                      memDetails,
                                      teamID
                                    )
                                  } 
                                className="table-link reactivate"
                                data-testid="reActivate-link"
                                >
                                  {t(`Reactivate`)}
                                </MenuItem>
                                :  <>{!memDetails.isOriginalOwner && !showMeLabel && 
                                <MenuItem
                                  onClick={() =>
                                    memActions.deActivateAction(memDetails)
                                  }
                                  className="table-link"
                                  data-testid="deActivate-link"
                                >
                                  {t(`Deactivate`)}
                                </MenuItem>}
                                </>
                                }
                              </>)}
                            {(!memDetails.isInviteAccepted) && <MenuItem
                               onClick={() =>
                                memActions.resendInviteOption(memDetails.email)
                              }
                              className="table-link"
                              data-testid="resend-link"
                            >
                              {t(`Resend Invite`)}
                            </MenuItem>}
                          </Menu>
                        </Box>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
      </>
    )
  }

  return (
    <>
      {tableType == "teams" ? (
        <>
          {renderCheckboxCell(isItemSelected, labelId, hideActionColumn, userRole)}
          {renderNameCellTeams(
            teamName,
            users,
            hideActionColumn,
            labelId,
            id,
            showMyTeam
          )}
          <TableCell className="email-cell">{teamDetails.users}</TableCell>
          {renderTeamActions(userRole, hideActionColumn)}
        </>
      ) : (
        <>
          {renderCheckboxCell(isItemSelected, labelId, hideActionColumn, userRole)}
          <TableCell
            className="name-cell"
            component="th"
            id={labelId}
            scope="row"
            padding="none"
          >
            <Box className="name-col">
              <Box className="profile-name">
                {memDetails?.firstName?.charAt(0)} {memDetails?.lastName?.charAt(0)}
              </Box>
              {memDetails?.firstName} {memDetails?.lastName} {showMeLabel}
            </Box>
          </TableCell>
          <TableCell className="email-cell">
            <Link to="#" className="table-link">
              {memDetails.email}
            </Link>
          </TableCell>

          <>{renderMem()}</>
          <TableCell className="joindate-cell">
            {formatDate(memDetails.joinDate)}
          </TableCell>

          {!hideActionColumn && (
            <TableCell className="action-link">
            {renderMemberAction()}
            </TableCell>
          )}
        </>
      )}
    </>
  );
};

export default TableItems;
// Customizable Area End
