// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Popover,
  TextField,
  FormControlLabel,
  AppBar,
  Box,
  Tabs,
  Tab,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Input,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  ListItemSecondaryAction,
  IconButton,
  MenuItem,
  Modal,
  Button,
  Menu,
  Radio,
  RadioGroup,
  Chip,
  Switch
} from "@material-ui/core/";
import SearchIcon from "@material-ui/icons/Search";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import TaskListController ,{ configJSON } from "./TaskListController.web";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import CustomDatePicker from "../../../../components/src/CustomDatePicker.web";
import { customStyles } from "../../../../components/src/TaskListStyles.web";
import TaskListFilter from "./TaskListFilter.web";
import { FileDrop } from "react-file-drop";
import {
  fileTree,
  accountPlus,
  checkedSvg,
  dotsHorizontal,
  checkBoxFilled,
  checkBoxEmpty,
  checkGreen,
  accountGray,
  editGray,
  copyGray,
  attachmentImg1,
  accountPlusWhite,
  calendarWhite,
  deleteWhite,
  priorityWhite,
  checkboxMarkedWhite,
  timerSand,
  minimizeGray,
  closeGray,
  contentSave,
  dotVerticle,
  dotVerticleWhite,
  MoveTaskIcon,
  sortby,
  timerUntracked,
  uploadImg,
  attacmentsIcon,
  checkboxMarkedCircleGray2,
  radioCheck,
  calendar2,
  calendarViewIcon,
  inventoryIcon,
} from "../assets";
import moment from "moment";
import ConfirmActionModal from "../../../email-account-registration/src/teams-web/ConfirmActionModal.web";
import FreeSoloCreateOption from "../../../../components/src/FreeSoloCreateOption.web";
import ProjectView from "./ProjectView.web";
import WebLoader from "../../../../components/src/WebLoader.web";
import ActivityLog from "../../../ActivityLog/src/ActivityLog.web";
import { TimeTrackingBillingWeb } from "../../../timetrackingbilling/src/Timetrackingbilling.web";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { AddInventoryAndAssests } from "./AddInventoryAndAssests.web";
import ProductInventoryViewModal from "../../../../components/src/ProductInventoryViewModal.web";
import AllInventoryAssetsViewModal from "../../../../components/src/AllInevtoryAssestsModel.web";
import { generateSummary } from "../../../../components/src/ReusableFunctions";


export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

export function viwA11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function assigneeA11yProps(index: any) {
  return {
    id: `assignee-tab-${index}`,
    "aria-controls": `assignee-tabpanel-${index}`,
  };
}
class TaskList extends TaskListController {

  getTabData = (classes: any) => {
    const popOverOpen = Boolean(this.state.popoveAnchorEl);

    return (
      <Box className="tasklist-row">
        <Box className="tasklist-col">
          <Accordion className="accordion-block" expanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />} 
              aria-controls="open-content"
              id="open-header"
              className="accordion-heading"
            >
              <Box className="tasklist-status open">
                {this.t(`${configJSON.txtStatusOpen}`)} ({this.getOpenTask().length})
              </Box>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              {this.getOpenTask().map((task) => {
                return (
                  <Card
                    key={task.id}
                    className={`task-card ${
                      this.state.selectedTasks.includes(task.id)
                        ? "task-selected"
                        : ""
                    } `}
                    
                  >
                    <CardContent
                      className="card-content"
                      data-test-id={`hoverAction-${task.id}`}
                    >
                      <Box className="task-title">                        
                        <TimeTrackingBillingWeb classes={classes} taskDetails={task} viewTaskHandler={this.handle_modalOpen} data-test-id={`task-${task.id}`} type="list"/>
                      </Box>
                      
                      <Box
                        className={`tasklist-bottom ${
                          this.state.priorityId == task.id ? "task-active" : ""
                        } ${
                          this.state.changeDueDateTaskId == task.id && this.state.datePickerOpen ? "task-active" : ""
                        }`}
                        
                      >
                        <Box className="left-col">
                          <Box className="tasklist-info">
                            <Box className="bottom-icon">
                              <img
                                src={accountPlus}
                                alt="accountPlus"
                                onClick={(e: any) =>
                                  this.assigneeHandler(e, task.id)
                                }
                              />
                            </Box>
                          </Box>
                          <Box className="tasklist-info">
                            <Box
                              className="bottom-icon"
                              onClick={(e: any) =>
                                this.changeStatusHandler(e, task.id)
                              }
                              data-test-id={`hoverChangeStatus-${task.id}`}
                            >
                              <img
                                src={checkboxMarkedCircleGray2}
                                alt="checkboxMarkedCircle"
                              />
                            </Box>
                          </Box>
                          <Box className="tasklist-info">
                            <CustomDatePicker
                              currentDate=""
                              t={this.t}
                              onChange={this.singleDueDateChange}
                              onOpenPickNewDate={false}
                              onOpen={() => this.openDueDate(task.id)}
                              render={(value: any, openCalendar: any) => (
                                <Box  onClick={openCalendar} className="bottom-icon">
                                  <img src={calendar2} alt="calendar" />
                                 </Box>
                                )
                              }
                              data-test-id={`hoveDueDateChange-${task.id}`}
                              inPortal={true}
                              onClose={this.datePickerCloseHandler}
                            />
                          </Box>
                        </Box>
                        <Box className="right-col">
                          <Box className="tasklist-info">
                            <Box
                              className="bottom-icon"
                              onClick={() => this.moveTask(task.id)}
                              data-test-id={`moveTask-${task.id}`}
                            >
                              <img src={checkedSvg} alt="checkedSvg" />
                            </Box>
                          </Box>
                          <Box className="tasklist-info">
                            <Box className="bottom-icon">
                              <Checkbox
                                className="checklist-check"
                                icon={
                                  <img
                                    src={checkBoxEmpty}
                                    alt="checkBoxEmpty"
                                  />
                                }
                                checkedIcon={
                                  <img
                                    src={radioCheck}
                                    alt="checkBoxFilled"
                                  />
                                }
                                checked={this.state.selectedTasks.includes(
                                  task.id
                                )}
                                onChange={() => {
                                  this.handleTaskSelectedChange(task.id);
                                }}
                                data-test-id={`selectedFilled-${task.id}`}                              
                              />
                            </Box>
                          </Box>
                          <Box className="tasklist-info">
                             <Box
                               
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              onClick={(e) => this.handleMenuOpen(e, task.id)}
                              data-test-id={`handleMenuOpen-open-${task.id}`}
                              className="dropmenu-btn bottom-icon"
                            >
                              <img src={dotsHorizontal} alt="dotsHorizontal" />
                             </Box>
                          </Box>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                );
              })}
              <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                className={classes.dropDropdown}
              >
                <MenuItem
                  aria-controls="changePriorityTaskEl"
                  aria-haspopup="true"
                  onClick={this.changePriorityTaskOpen}
                >                  
                  {this.t(`${configJSON.txtChangePriority}`)}
                </MenuItem>
                <MenuItem
                  onClick={() => this.deleteTask(this.state.priorityId)}
                  data-test-id="deleteTask-open"
                >
                  {this.t(`${configJSON.txtDeleteTask}`)}
                </MenuItem>
              </Menu>
              <Menu
                id="changePriorityTaskEl"
                anchorEl={this.state.changePriorityTaskEl}
                keepMounted
                open={Boolean(this.state.changePriorityTaskEl)}
                onClose={() => this.changePriorityTaskClose()}
                data-test-id="changePriorityTaskClose-open"
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                className={`changepriority-dropdown ${classes.dropDropdown}`}
              >
                <MenuItem   data-test-id="changePriorityTaskClose-open-0" onClick={() => this.changePriorityTaskClose(2)}>
                  <Box className="priority-name">{this.t(`${configJSON.txtEmergency}`)}</Box>
                  <Box className="priority-icon">!!!</Box>
                </MenuItem>
                <MenuItem   data-test-id="changePriorityTaskClose-open-1" onClick={() => this.changePriorityTaskClose(1)}>
                  <Box className="priority-name">{this.t(`${configJSON.txtCritical}`)}</Box>
                  <Box className="priority-icon">!!</Box>
                </MenuItem>
                <MenuItem   data-test-id="changePriorityTaskClose-open-2" onClick={() => this.changePriorityTaskClose(0)}>
                  <Box className="priority-name">{this.t(`${configJSON.txtNone}`)}</Box>
                </MenuItem>
              </Menu>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="tasklist-col">
          <Accordion className="accordion-block" expanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />} 
              aria-controls="open-content"
              id="open-header"
              className="accordion-heading"
            >
              <Box className="tasklist-status in-progress">
                {this.t(`${configJSON.txtStatusInProgress}`)} ({this.getInprogressTask().length})
              </Box>
            </AccordionSummary>
            <AccordionDetails className="accordion-details 1">
              {this.getInprogressTask().map((task) => {
                return (
                  <Card
                  className={`task-card ${
                    this.state.selectedTasks.includes(task.id)
                      ? "task-selected"
                      : ""
                  } `}
                  key={task.id}                    
                  >
                    <CardContent
                      data-test-id={`hoverAction-${task.id}`}
                      className="card-content"
                    >
                       <Box  className="task-title">                        
                        <TimeTrackingBillingWeb classes={classes} taskDetails={task} viewTaskHandler={this.handle_modalOpen} type="list"/>
                      </Box>
                      
                      <Box
                        className={`tasklist-bottom 1 ${
                          this.state.priorityId == task.id ? "task-active" : ""
                        } ${
                          this.state.changeDueDateTaskId == task.id && this.state.datePickerOpen ? "task-active" : ""
                        }`}
                        
                      >
                        <Box className="left-col 1">
                          <Box className="tasklist-info 1">
                            <Box className="bottom-icon 1">
                              <img
                                alt="accountPlus"
                                src={accountPlus}
                                onClick={(e: any) =>
                                  this.assigneeHandler(e, task.id)
                                }
                                data-test-id={`assigneeHandler-${task.id}`}
                              />
                            </Box>
                          </Box>
                          <Box className="tasklist-info 1">
                            <Box
                              onClick={(e: any) =>
                                this.changeStatusHandler(e, task.id)
                              }
                              className="bottom-icon"
                              data-test-id={`hoverChangeStatus-${task.id}`}
                            >
                              <img
                                alt="checkboxMarkedCircle"
                                src={checkboxMarkedCircleGray2}
                                style={{color:"red"}}
                              />
                            </Box>
                          </Box>
                          <Box className="tasklist-info 1">
                            <CustomDatePicker
                              currentDate=""
                              onOpenPickNewDate={false}
                              onChange={this.singleDueDateChange}
                              onOpen={() => this.openDueDate(task.id)}
                              render={(value: any, openCalendar: any) => (
                                <Box onClick={openCalendar}  className="bottom-icon">
                                  <img src={calendar2} alt="calendar" />
                                 </Box>
                                )
                              }
                              t={this.t}
                              inPortal={true}
                              data-test-id={`hoveDueDateChange-${task.id}`}
                              onClose={this.datePickerCloseHandler}
                            />
                          </Box>
                        </Box>
                        <Box className="right-col 1">
                          <Box className="tasklist-info 1">
                            <Box
                              onClick={() => this.moveTask(task.id)}
                              className="bottom-icon"
                              data-test-id={`moveTask-${task.id}`}
                            >
                              <img src={checkedSvg} alt="checkBoldd" />
                            </Box>
                          </Box>
                          <Box className="tasklist-info 1">
                            <Box className="bottom-icon 1">
                              <Checkbox
                                className="checklist-check 1"
                                icon={
                                  <img
                                  alt="checkBoxEmptyy"
                                  src={checkBoxEmpty}
                                  />
                                }
                                checkedIcon={
                                  <img
                                  alt="checkBoxFilled"
                                  src={radioCheck}
                                  />
                                }
                                checked={this.state.selectedTasks.includes(
                                  task.id
                                )}
                                data-test-id={`selectedFilled-${task.id}`}
                                onChange={() => {
                                  this.handleTaskSelectedChange(task.id);
                                }}
                              />
                            </Box>
                          </Box>
                          <Box className="tasklist-info 1">                          
                             <Box
                               
                              aria-haspopup="true"
                              aria-controls="simple-menu"
                              className="dropmenu-btn bottom-icon"
                              data-test-id={`handleMenuOpen-${task.id}`}
                              onClick={(e) => this.handleMenuOpen(e, task.id)}
                            >
                              <img src={dotsHorizontal} alt="dotsHorizontall" />
                             </Box>
                          </Box>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                );
              })}
              <Menu
                id="simple-menu"
                keepMounted
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                getContentAnchorEl={null}
                onClose={this.handleMenuClose}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                className={classes.dropDropdown}
              >
                <MenuItem
                  aria-haspopup="true"
                  aria-controls="changePriorityTaskEl"
                  onClick={this.changePriorityTaskOpen}
                >                  
                  {this.t(`${configJSON.txtChangePriority}`)}
                </MenuItem>
                <MenuItem
                  data-test-id={`deleteTask`}
                  onClick={() => this.deleteTask(this.state.priorityId)}
                >
                  {this.t(`${configJSON.txtDeleteTask}`)}
                </MenuItem>
              </Menu>
              <Menu
                anchorEl={this.state.changePriorityTaskEl}
                id="changePriorityTaskEl"
                open={Boolean(this.state.changePriorityTaskEl)}
                keepMounted
                data-test-id="changePriorityTaskClose"
                onClose={() => this.changePriorityTaskClose()}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                getContentAnchorEl={null}
                transformOrigin={{
                  horizontal: "left",
                  vertical: "top",
                }}
                className={`changepriority-dropdown ${classes.dropDropdown}`}
              >
                <MenuItem  data-test-id="changePriorityTaskClose2" onClick={() => this.changePriorityTaskClose(2)}>
                  <Box className="priority-name">{this.t(`${configJSON.txtEmergency}`)}</Box>
                  <Box className="priority-icon">!!!</Box>
                </MenuItem>
                <MenuItem  data-test-id="changePriorityTaskClose1" onClick={() => this.changePriorityTaskClose(1)}>
                  <Box className="priority-name">{this.t(`${configJSON.txtCritical}`)}</Box>
                  <Box className="priority-icon">!!</Box>
                </MenuItem>
                <MenuItem  data-test-id="changePriorityTaskClose0" onClick={() => this.changePriorityTaskClose(0)}>
                  <Box className="priority-name">{this.t(`${configJSON.txtNone}`)}</Box>
                </MenuItem>
              </Menu>
            </AccordionDetails>
          </Accordion>
        </Box>  
        <Box className="tasklist-col">
          <Accordion className="accordion-block" expanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="completed-content"
              id="completed-header"
              className="accordion-heading"
            >
              <Box className="tasklist-status completed1">
              {this.t(`${configJSON.txtStatusCompleted}`)} ({this.getCompletedTask().length})
              </Box>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              {this.getCompletedTask().map((task) => {
                return (
                  <Card
                    key={task.id}
                    className={`task-card ${
                      this.state.selectedTasks.includes(task.id)
                        ? "task-selected"
                        : ""
                    } `}
                  >
                    <CardContent className="card-content">
                      <Box
                        className="task-title"
                        onClick={() => this.handle_modalOpen(task.id)}
                        data-testid={`task-${task.id}`}
                      >
                        <Box className="title">
                          {" "}
                          {task.attributes.priority !== "normal" && (
                            <Box className="priority-status" component="span">
                              {task.attributes.priority == "critical"
                                ? "!!"
                                : "!!!"}
                            </Box>
                          )}
                          {task.attributes.title}
                        </Box>
                        <Box className="check-complete">
                          <img src={checkGreen} alt="checkGreen" />
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="tasklist-col">
          <Accordion className="accordion-block" expanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="cancelled-content"
              id="cancelled-header"
              className="accordion-heading"
            >
              <Box className="tasklist-status cancelled">
              {this.t(`${configJSON.txtStatusCancelled}`)} ({this.getCanceledTask().length})
              </Box>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              {this.getCanceledTask().map((task) => {
                return (
                  <Card
                    key={task.id}
                    className={`task-card ${
                      this.state.selectedTasks.includes(task.id)
                        ? "task-selected"
                        : ""
                    } `}
                  >
                    <CardContent className="card-content">
                      <Box
                        className="task-title"
                      >
                        <Box className="title">
                          {task.attributes.priority !== "normal" && (
                            <Box className="priority-status" component="span">
                              {task.attributes.priority == "critical"
                                ? "!!"
                                : "!!!"}
                            </Box>
                          )}
                          {task.attributes.title}
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    );
  };
  renderDateInfo = (label:string, date:string ,cssClass:string) => (
    <Box className={cssClass}>
      <Box className="label">{this.t(`${label}`)}: </Box>
      <Box className="value">
        {date ? moment(date).format("MMM DD , YYYY") : ""}
        <img className="icon" src={calendarViewIcon} alt="calendarViewIcon" />
      </Box>
    </Box>
  );

  renderDatesAccordion = () => (
    <ListItem className="date-item-root">
      <Accordion className="accordion-block">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="open-content"
          id="open-header"
          className="accordion-heading"
        >
          <Box className="list-label">{this.t(`${configJSON.txtDates}`)}</Box>
          <Box className="list-description1">
            {this.renderDateInfo(configJSON.dueDate, this.state.editTask?.attributes.due_date,"date-info")}
          </Box>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <Box className="list-description1">
            {this.renderDateInfo(configJSON.txtDeadline, this.state.editTask?.attributes.deadline_date ,"date-info-1")}
          </Box>
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
  allInventoryAssestsViewModal=(classes:any)=>{
    return(
    <AllInventoryAssetsViewModal
    editTask={this.state.editTask}
    classes = {classes}
    isInventoryAssests ={this.state.isInventoryAssests}
    allInventoryAssestsModalClose = {this.allIneventoryAssestsModalClose}
    openProver={this.openProver}
    openConsumableAnchorElMore={this.state.openConsumableAnchorElMore}
    closeProver={this.closeProver}
    staticID={this.state.staticID}
    openEditConsumable={this.state.openEditConsumable}
    openEditConsumableModal={this.openEditConsumableModal}
    backToInventory={this.backToInventory}
    closeEditConsumableModal={this.closeEditConsumableModal}
    itemID={this.state.itemID}
    t={this.t}
    />
    )
  }
  showActivityLog = () => {
    const {classes} = this.props;
    return (
      <>
      {this.state.editTask !==null && 
        <ActivityLog 
        classes={classes}
        activityType="task"
        activityId={this.state.editTask?.id}
        activityTitle={this.state.editTask?.attributes.title}
        taskPriority={this.state.editTask.attributes.priority}
        data-test-id="task-logs"
        />
       }
   </>
        
    )
  }
  viewModal = (classes: any) => {
    const popOverOpen = Boolean(this.state.popoveAnchorEl);
    const popoverId = popOverOpen ? "simple-popover" : undefined;
    const status = this.state.editTask?.attributes.status;
    const time= this.state.editTask?.attributes.hours;
    let displayHours = "";
    if(time){
      const timeData :string[]= time.split(":");
      if(Number(timeData[1])>0){
        displayHours = `${timeData[0]} h ${timeData[1]} m`
      }
      else{
        displayHours = timeData[0] + "hrs"
      }
    }
  const productCount = this.state.editTask?.attributes?.inventory_or_assets?.data.length;
  const statusValue = configJSON.statusMap[status] ?? 1; 
  const statusClassName = configJSON.classMap[statusValue]; 
  const transformedData = {
    "inventory_costs": Object.entries(this.state.totalCostDetails.inventory_costs).map(([name, cost]) => ({
      name,
      cost: String(cost)
    }))
  };  
  const totalCost = Object.values(this.state.totalCostDetails.inventory_costs).reduce((sum, cost) => sum + cost, 0);
    return (
      <Modal
        open={this.state.modalOpen}
        onClose={this.handle_modalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
      >
        <Box className={`view-modal-dialog ${classes.modalDialogBox}`}>
          <Box className="modal-heading">
            <Box className="heading-links">
            <Box className="heading-link show-sm">
                 <Box
                  data-testid="viewTaskClose"
                  onClick={this.handle_modalClose}
                   
                  className="auth-back icon"
                >
                  <i>
                    <svg
                      className="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                    </svg>
                  </i>
                 </Box>
              </Box>    
              <Box className="heading-link hide-sm">
                 <Box
                   
                  className="icon"
                  aria-describedby={popoverId}
                  onClick={this.editTaskOpen}
                  data-test-id="viewEditTask"
                >
                  <img src={editGray} alt="editGray" />
                 </Box>
              </Box>
              <Box className="heading-link hide-sm">
                 <Box
                   
                  className="icon"
                  onClick={this.assigneeHandler}
                  data-test-id="viewUpdateAssignee"
                >
                  <img src={accountGray} alt="accountGray" />
                 </Box>
              </Box>
              <Box className="heading-link hide-sm">
                 <Box
                   
                  className="icon"
                  onClick={() =>
                    this.createDuplicateTask(this.state.editTask?.id)
                  }
                  data-testid="duplicateTask"
                >
                  <img src={copyGray} alt="copyGray" />
                 </Box>
              </Box>
              <Box className="heading-link">
                 <Box
                   
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(e) => this.handleMenuOpen(e)}
                  className="dropmenu-btn"
                  data-testid="viewMenuOpen"
                >
                  <img src={dotsHorizontal} alt="dotsHorizontal" className="hide-sm" />
                  <img src={dotVerticle} alt="dotVerticle" className="show-sm" />
                 </Box>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleMenuClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  className={classes.dropDropdown}
                >
                   <MenuItem className="show-sm" onClick={this.editTaskOpen} data-test-id="viewEditTask">
                    {this.t(`${configJSON.editTxt}`)}
                  </MenuItem>
                  <MenuItem className="show-sm" onClick={this.assigneeHandler} data-test-id="viewUpdateAssignee">
                    {this.t(`${configJSON.viewAssigneeTxt}`)}
                  </MenuItem>
                  <MenuItem 
                    className="show-sm" 
                    onClick={() =>
                      this.createDuplicateTask(this.state.editTask?.id)
                    }
                    data-testid="duplicateTasksm"
                  >
                    {this.t(`${configJSON.duplicateTaskTxt}`)}
                  </MenuItem>
                  <MenuItem
                    onClick={() => this.deleteTask()}
                    data-test-id="viewDeleteTask"
                  >
                    {this.t(`${configJSON.txtDeleteTask}`)}
                  </MenuItem>
                  <MenuItem
                    onClick={(e:any)=>this.moveTasklistProjectHandler(e,this.state.selectedTasks)}
                    data-test-id="moveTasklistProjectHandler"
                  >
                    {this.t(`${configJSON.moveTasklistProjectTitle}`)}
                  </MenuItem>                 
                </Menu>
                <Menu
                  id="changePriorityViewEl"
                  anchorEl={this.state.changePriorityViewEl}
                  keepMounted
                  open={Boolean(this.state.changePriorityViewEl)}
                  onClose={() => this.changePriorityViewClose()}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  className={`changepriority-dropdown ${classes.dropDropdown}`}
                  data-test-id="changePriorityMenu"
                >
                  <MenuItem
                    onClick={() => this.changePriorityViewClose(2)}
                    data-test-id="changePriorityEmergency"
                  >
                    <Box className="priority-name">{this.t(`${configJSON.txtPriorityEmergency}`)}</Box>
                    <Box className="priority-icon">!!!</Box>
                  </MenuItem>
                  <MenuItem
                    onClick={() => this.changePriorityViewClose(1)}
                    data-test-id="changePriorityCritical"
                  >
                    <Box className="priority-name">{this.t(`${configJSON.txtCritical}`)}</Box>
                    <Box className="priority-icon">!!</Box>
                  </MenuItem>
                  <MenuItem
                    onClick={() => this.changePriorityViewClose(0)}
                    data-test-id="changePriorityNone"
                  >
                    <Box className="priority-name">{this.t(`${configJSON.txtNone}`)}</Box>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
             <Box
               
              className="close-icon hide-sm"
              onClick={this.handle_modalClose}
              data-testid="viewTaskClose"
            >
              <CloseIcon />
             </Box>
          </Box>
          <Box className="main-content-txt">
          <Typography className="content-sub-txt">{this.state.editTask?.attributes.title}</Typography>
          <Typography className="content-sub-txt content-dots">{this.state.editTask?.attributes.project_title}</Typography>
          <Typography className="content-sub-txt content-dots">{this.state.editTask?.attributes.task_list_name}</Typography>
          </Box>
          <Box className="modal-description">
            {this.state.editTask !==null && <TimeTrackingBillingWeb classes={classes} taskDetails={this.state.editTask} viewTaskHandler={this.handle_modalOpen} type="single"/> }
            
            <List className="taskview-list">
              <ListItem>
                <Box className="list-label">{this.t(`${configJSON.txtStatus}`)}</Box>
                <Box className="list-description">
                  <FormControl className="select-control status-control">
                    <Select
                      IconComponent={ExpandMoreIcon}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        classes: { 
                          paper: `${classes.dropdownStyle} modal-dropdown`
                        },
                      }}
                      onChange={this.viewChangeStatus}
                      displayEmpty
                      value={statusValue}
                      className={`${statusClassName} MuiSelect-select`}                    
                      data-test-id="status-update"
                    >                      
                      <MenuItem value={0}>{this.t(`${configJSON.txtStatusOpen}`)}</MenuItem>
                      <MenuItem value={3}>{this.t(`${configJSON.txtStatusInProgress}`)}</MenuItem>
                      <MenuItem value={1}>{this.t(`${configJSON.txtStatusCancelled}`)}</MenuItem>
                      <MenuItem value={2}>{this.t(`${configJSON.txtStatusCompleted}`)}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </ListItem>
              <ListItem>
                <Box className="list-label">{this.t(`${configJSON.inventoryAseetsViewTitle}`)}</Box>
                <Box className="list-description">
                  <Box className="brand-names">
                  {this.state.editTask?.attributes?.inventory_or_assets?.data.slice(0, 2).map((item:any)=>{
                  return <>
                  <Box className="brand-list">
                   <Box className="list-subtitle">{this.t(`${configJSON.brandTxt}`)}</Box>
                  <Box className="list-value">
                    {item.attributes.name}
                  </Box>
                  </Box>   
                      </>
                    })}                
                  <Box>
                  {productCount > 2 && <Chip
                   className={`more-btn ${classes.chipButton}`}
                   label={`+${productCount - 2} more`}
                   onClick={this.allIneventoryAssestsModalOpen}
                   data-test-id="showMoreBtn"
                  />} 
                  </Box>                
                  </Box>
                </Box>
                {this.allInventoryAssestsViewModal(classes)}
              </ListItem>
              <ListItem className="date-item-root">
               {this.renderDatesAccordion()}
              </ListItem>
              <ListItem>
                <Box className="list-label">{this.t(`${configJSON.expendituresText}`)}</Box>
                <Box className="list-description">£{this.state.editTask?.attributes.expenditures}</Box>
              </ListItem>
              <ListItem>              
              <Accordion className="accordion-block">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="open-content"
                id="open-header"
                className="accordion-heading"
              >
                <Box className="list-label">
                  {this.t(`${configJSON.totalCostTxt}`)}
                </Box>
                <Box className="list-description" style={{display:"flex",justifyContent:"end",paddingRight: "35px"}}>
                  <Box className="date-info">
                      <Box className="label">{this.t(`${configJSON.actualTxt}`)}: </Box>
                      <Box className="value">                  
                        £{Math.floor(this.state.totalCostDetails.actual_hours / 3600) !== 
                          parseInt(moment(this.state.totalCostDetails.hours, "HH:mm:ss").format("H")) ?
                          ((+this.state.totalCostDetails.total_cost) -
                          ((+this.state.totalCostDetails.labour_cost) * 
                          ((parseInt(moment(this.state.totalCostDetails.hours, "HH:mm:ss").format("H")) -
                            Math.floor(this.state.totalCostDetails.actual_hours / 3600))
                          / (parseInt(moment(this.state.totalCostDetails.hours, "HH:mm:ss").format("H")))))).toFixed(2) :
                          (this.state.totalCostDetails.total_cost)
                        }</Box>
                    </Box>
                    </Box>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
              <Box className="labour-cost">
              <Box className="list-label-1">{this.t(`${configJSON.laborCostTxt}`)}</Box>
              <Box className="list-value">
                    £{Math.floor(this.state.totalCostDetails.actual_hours / 3600) !== 
                    parseInt(moment(this.state.totalCostDetails.hours, "HH:mm:ss").format("H")) ?
                    ((+this.state.totalCostDetails.labour_cost) * ((Math.floor(this.state.totalCostDetails.actual_hours / 3600)) 
                    / (parseInt(moment(this.state.totalCostDetails.hours, "HH:mm:ss").format("H"))))) :
                    (this.state.totalCostDetails.labour_cost)
                    }
                    </Box>
                </Box>
              </AccordionDetails>
              <Box className="employee-txt">
            <Box className="employee-value">{this.state.totalCostDetails.employee_count}&nbsp;{this.state.totalCostDetails.employee_count > 1 ? this.t(`${configJSON.employeesTxt}`) : this.t(`${configJSON.employeeTxt}`) }</Box>
            <Box className="actual-position">
              <Box className="list-actual">
                  <Box className="list-subtitle">{this.t(`${configJSON.actualTxt}`)}:
                  </Box>
                  <Box className="list-value">
                  {this.state.totalCostDetails.actual_hours} hours
                  </Box>
                </Box>             
              <Box className="list-actual">
                  <Box className="list-subtitle">{this.t(`${configJSON.projectedTxt}`)}:
                  </Box>
                  <Box className="list-value">
                 {moment(this.state.totalCostDetails.hours, "HH:mm:ss").format("H")} hours
                  </Box>
                </Box>
              </Box> 
              </Box> 
              <Box className="accordion-details">

              <Box className="labour-cost">
              <Box className="list-label-1">{this.t(`${configJSON.inventoryCostTxt}`)}</Box>
              <Box className="list-value">
                £{totalCost}
              </Box>
              </Box>
              <table className="inventory-table">
                {transformedData.inventory_costs.map((data:{name:string,cost:string})=>{
                  return <tr className="inventeory-items">
                  <th className="employee-value-head">{data.name} <img className="image" src={inventoryIcon} alt="icon"/></th>
                  <td className="employee-value">{moment(this.state.totalCostDetails.hours, "HH:mm:ss").format("H")} hours</td>
                  <td className="employee-value">£{data.cost}</td>
              </tr>
              })} 
              </table>
              </Box>   
              <ListItem>
                <Box className="list-label">{this.t(`${configJSON.expendituresText}`)}</Box>
                <Box className="list-description">£{this.state.totalCostDetails.expenditures}</Box>
              </ListItem>
              </Accordion>
              </ListItem>
              <ListItem>
                <Box className="list-label">{this.t(`${configJSON.txtAssignees}`)}</Box>
                <Box className="list-description">
                  <Box className="assignee-list">
                    {this.state.editTask?.attributes.assignees
                      .slice(0, 4)
                      .map((assignee: any, index: number) => {
                        return (
                          <>
                            <Box className="assignee-bubble">{`${assignee.first_name
                              ?.charAt(0)
                              .toUpperCase()}${assignee.last_name
                              ?.charAt(0)
                              .toUpperCase()}`}</Box>
                          </>
                        );
                      })}
                    {this.state.editTask?.attributes.assignees.length > 4 && (
                      <Box className="assignee-bubble">
                        +{this.state.editTask?.attributes.assignees.length - 4}
                      </Box>
                    )}
                  </Box>
                </Box>
              </ListItem>
              <ListItem>
                <Box className="list-label">{this.t(`${configJSON.createdByTxt}`)}</Box>
                <Box className="list-description">{this.state.editTask?.attributes.created_by_name}</Box>
              </ListItem>
              {this.userRole === 'employee' && <>
              <ListItem>
                <Box className="list-label">{this.t(`${configJSON.unTrackedHrs}`)}</Box>
                <Box className="list-description">
                  
                  <Box className="list-value">
                   <Box
                    data-test-id="unTrackedHoursAdd"
                    className="untracked-hours"
                    onClick={this.toggleHoursModal}                     
                  >
                   
                    <Box className="value-container">
                      {this.state.unTrackedTaskours !== "" ?  <Box className="time">{this.state.unTrackedTaskours}</Box> : "Add Hours"}
                      <img className="icon" alt="timerUntracked" src={timerUntracked}/>
                      </Box>
                   
                     </Box>
                  </Box>
                  {this.unTrackedHoursModal()}
                  
                </Box>
              </ListItem>
              </>}
              
            </List>
            <Box className="tasklist-tabwrapper">
              <Box>
                <Tabs
                  aria-label="simple tabs example"
                  value={this.state.viewTabsValue}
                  onChange={this.setViewTabsValue}
                  data-test-id="viewTaskBottomTabs"
                >
                  <Tab label={this.t(`${configJSON.txtDescription}`)} {...viwA11yProps(0)} />
                  <Tab label={this.t(configJSON.attacmentsTxt , { count: this.state?.editTask?.attributes?.attachments_url.length })} {...viwA11yProps(1)} />
                  <Tab label={this.t(`${configJSON.activityLogsTxt}`)} {...viwA11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={this.state.viewTabsValue} index={0}>
                <Box className="description-box">
                  {this.state.editTask?.attributes.description}
                </Box>
              </TabPanel>
              <TabPanel value={this.state.viewTabsValue} index={1}>
              {this.state?.editTask?.attributes?.attachments_url.length === 0 ? <List
                  className="attachments-list-default-view"                  
                >
                 <img src={attacmentsIcon} className="attacments-icon" alt="attachmentImg1"></img>
                 <Typography className="no-attachment-text">
                 {this.t(`${configJSON.notAttachmentTest}`)}
                 </Typography>
                 <Typography className="no-attachment-sub-text">
                 {this.t(`${configJSON.subTextForDEfaultAttaments}`)}
                 </Typography>
                 <FileDrop>
                    <Box
                      data-test-id="onBrowse"
                      onClick={this.onBrowse}
                    >
                      <form ref={this.formRef}>
                        <input
                          name="upload-photo"
                          accept="*"
                          type="file"
                          onChange={this.handleOnDrop}
                          hidden
                          data-test-id="add-project-attachment"
                          ref={this.fileRef}
                          multiple
                        />
                      </form>
                      <Box className="file-upload-block 2">                       
                      <Button
                          onClick={this.handleUploadEvent}
                          className={`secondary-btn ${classes.secondaryButton}`}
                        >
                          + {this.t(`${configJSON.selecteFileTxt}`)}
                        </Button>                   
                      </Box>
                    </Box>
                </FileDrop>                
                </List> :  <List
                  className="attachments-list"
                  subheader={
                    <ListSubheader
                      className="subheader-title"
                      id="nested-list-subheader"
                    >
                      <Box className="title">{this.t(`${configJSON.attachmentsTxt}`)}</Box>
                  <FileDrop>
                    <Box
                      data-test-id="onBrowse"
                      onClick={this.onBrowse}
                    >
                      <form ref={this.formRef}>
                        <input
                          accept="*"
                          name="upload-photo"
                          onChange={this.handleOnDrop}
                          hidden
                          type="file"
                          data-test-id="handleOnAttachmentDrop"
                          ref={this.fileRef}
                          multiple
                        />
                      </form>
                      <Box className="file-upload-block 1">                       
                       <Box className="tasklist-link"   data-test-id="handleUploadEvent" onClick={this.handleUploadEvent}>
                        + {this.t(`${configJSON.uploadTxt}`)}
                       </Box>                     
                      </Box>
                    </Box>
                </FileDrop>
                    </ListSubheader>
                  }
                >
                  {this.state?.editTask?.attributes?.attachments_url?.map((image:any)=>{
                  return<ListItem className="list-item">
                    <Box className="list-img">
                      <img src={image.url ? image.url: attachmentImg1} alt="attachmentImg1" />
                    </Box>
                    <Box className="list-name">
                      {image.filename}
                    </Box>
                    <ListItemSecondaryAction aria-label="delete1" className="list-secondary">
                      <IconButton edge="end" aria-label="delete" data-test-id={`attachmentRemoveEvent-${image.id}`} onClick={()=>this.attachmentRemoveEvent(image.id.toString())}>
                        <CloseIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  })}
                </List>}
              
              </TabPanel>
              <TabPanel value={this.state.viewTabsValue} index={2}>
                {this.showActivityLog()}
                        
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };

  unTrackedHoursModal = () => {
    const {classes} = this.props;
    if(this.state.showHoursModal){
        return (
          <Box className={classes.timestandDropbox} id="timeStandId">
          <Box className="timestand-inner">
            <Box className="timestand-heading">{this.t(`${configJSON.txtTaskHoursUnTracked}`)}</Box>
            <Box component="form">
              <Box className="time">
                <TextField
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 999,
                  }}
                  placeholder="HH"
                  id="standard-basic"
                  name="unTrackedHours"
                  label=""
                  value={this.state.unTrackedHours}
                  onChange={this.hoursChange}
                  type="number"
                  data-test-id="unTrackedHours"
                />
                <Box component="span" className="colon-txt">
                  :
                </Box>
                <TextField
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="MM"
                  id="standard-basic2"
                  label=""
                  name="unTrackedMinutes"
                  type="number"
                  value={this.state.unTrackedMinutes}
                  onChange={this.minutesChange}
                  data-test-id="unTrackedMinutes"
                />
              </Box>
            </Box>
          </Box>
          <Box className="timestand-actions">
             <Box
               
              className="secondary-link"
              onClick={this.toggleHoursModal}
              data-test-id="CreateTaskHoursCancel"
            >
              {this.t(`${configJSON.txtCancel}`)}
             </Box>
             <Box
               
              className="primary-link"
              onClick={this.setUnTrackedHours}
              data-test-id="unTrackedHoursSave"
            >
              {this.t(`${configJSON.txtDone}`)}
             </Box>
          </Box>
        </Box>
        )
    }
     
    
  }

  changeAssigneeModal = (classes:any) => {
    const teamsOptions = this.displayTeamsOptions().filter(
      (team) => !this.state.selectedTaskAssignees.includes(team.id));
      
    const assigneeOptions = this.displayAssigneeOptions().filter(
      (assignee) => !this.state.selectedTaskAssignees.includes(assignee.id)
    );
    return (
    <Modal
      open={this.state.isActiveChangesAssignee}
      onClose={this.assigneeChangeModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalDialog}
    >
      <Box className={classes.modalDialogBox}>
              <Box className="modal-dialoginner">
                <Box className="modal-heading">
                  <Typography className="modal-title" component="h2">
                    {this.state.selectedTaskAssignees.length} {this.t(`${configJSON.txtMemberSelected}`)}
                  </Typography>
                   <Box
                     
                    className="close-icon"
                    onClick={this.assigneeChangeModalClose}
                    data-test-id="assigneeModalClose"
                  >
                    <CloseIcon />
                   </Box>
                </Box>
                <Box className="modal-description">
                  <Box component="p" className="sub-txt">
                    {this.t(`${configJSON.txtAssigneeDescription}`)}
                  </Box>
                  <Box className="modal-form" component="form">
                    <List className="assignee-multiSelect-list selected-list">
                      <ListSubheader>
                        <CustomInputWeb
                          placeholder={this.t(`${configJSON.txtSearchAssignees}`)}
                          fullWidth={true}
                          autoFocus={true}
                          name="assigneeSearchText"
                          onChange={this.handleOnChange}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                      <ListSubheader className="multiselect-subheader">
                      {this.t(`${configJSON.txtAssignees}`)}
                         <Box
                           
                          className="tasklist-link"
                          onClick={this.clearSelectedAssignees}
                          data-test-id="clearAssignees"
                        >
                         {this.t(`${configJSON.txtRemoveAllAssignees}`)}
                         </Box>
                      </ListSubheader>                     
                      {this.displayAssigneeOptions()
                        .filter((x: any) =>
                          this.state.selectedTaskAssignees.includes(x.id)
                        )
                        .map((member: any) => (
                          <ListItem
                              onClick={() => this.changeAssignee(member.id)}
                              value={member.id}
                              key={member.id}
                          >
                            <Box className="assignee-detail">
                              <Box className="assignee-left">
                                <Box className="assignee-profile">
                                  {member.initials}
                                </Box>
                                <Box className="assignee-info">
                                  <Box className="assignee-name">
                                    {member.title}
                                  </Box>
                                  <Box className="assignee-email">
                                    {member.email}
                                  </Box>
                                  {member.job_role && <Chip
                                    className={classes.chipButton}
                                    label={member.job_role}
                                  />} 
                                </Box>
                              </Box>
                              <Box className="assignee-right">
                                <Box className="assinee-selected">
                                  <img
                                    alt="checkBoxEmpty"
                                    src={checkBoxEmpty}
                                    className="normal-img"
                                  />
                                  <img
                                    alt="checkBoxFilled"
                                    src={checkBoxFilled}
                                    className="selected-img"
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </ListItem>
                        ))}
                        {this.displayTeamsOptions()
                        .filter((x: any) =>
                          this.state.selectedTaskAssignees.includes(x.id)
                        )
                        .map((team: any) => (
                          <ListItem onClick={() => this.changeAssignee(team.id)} value={team.id} key={team.id}>
                            <Box className="assignee-detail">
                              <Box className="assignee-left 1">
                                <Box className="assignee-profile 1">
                                  {team.initials}
                                </Box>
                                <Box className="assignee-info">
                                      <Box className="assignee-name">
                                        {team.title}
                                      </Box>
                                      <Box className="assignee-email">
                                        {team.users} {team.users >1 ? this.t(`${configJSON.membersTxt}`) : this.t(`${configJSON.memberTxt}`)}
                                      </Box>
                                    </Box>
                              </Box>
                              <Box className="assignee-right">
                                <Box className="assinee-selected">
                                  <img
                                    alt="checkBoxEmpty"
                                    src={checkBoxEmpty}
                                    className="normal-img"
                                  />
                                  <img
                                    alt="checkBoxFilled"
                                    src={checkBoxFilled}
                                    className="selected-img"
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </ListItem>
                        ))}
                      <ListSubheader className="multiselect-subheader">
                      {this.t(`${configJSON.txtUnassigned}`)}
                      </ListSubheader>
                    </List>
                    <Box className="tasklist-tabwrapper">
                      <Box className="assignee-tabs">
                        <Tabs
                          data-test-id="updateAssigneeTabs"
                          onChange={this.setAssigneeTabsValue}
                          value={this.state.assigneeTabsValue}
                          aria-label="simple tabs example"
                        >
                          <Tab label={this.t(`${configJSON.teamsTxt}`)} {...assigneeA11yProps(0)} />
                          <Tab label={this.t(`${configJSON.membersTxt}`)} {...assigneeA11yProps(1)} />
                        </Tabs>
                      </Box>

                      <TabPanel value={this.state.assigneeTabsValue} index={0}>
                        <List className="assignee-multiSelect-list">
                          <ListSubheader className="multiselect-membres">
                            <Box className="total-member">
                              {teamsOptions.length
                              }&nbsp;
                             {teamsOptions.length > 1 ? this.t(`${configJSON.teamsTxt}`): this.t(`${configJSON.teamTxt}`)}
                            </Box>
                            <FormControl className="select-control sortby-control">
                              <Select
                              IconComponent={ExpandMoreIcon}
                                MenuProps={{
                                  anchorOrigin: {
                                    horizontal: "left",
                                    vertical: "bottom",
                                  },
                                  transformOrigin: {
                                    horizontal: "left",
                                    vertical: "top",
                                  },
                                  classes: { paper: classes.dropdownStyle },
                                  getContentAnchorEl: null,
                                }}
                                displayEmpty
                                defaultValue={this.t(`${configJSON.txtSortBy}`)}
                                onChange={this.changeSortByAssignee}
                                data-testid="sortTeams"
                                renderValue={(value: any) => {
                                  return <Box>{value}</Box>;
                                }}
                                
                              >
                                <MenuItem value="A-Z">{this.t(`${configJSON.aTozTxt}`)}</MenuItem>
                                <MenuItem value="Z-A">{this.t(`${configJSON.zToaTxt}`)}</MenuItem>
                              </Select>
                            </FormControl>
                          </ListSubheader>
                          {this.sortRecords(this.displayTeamsOptions())
                            .filter(
                              (x: any) =>
                                !this.state.selectedTeamList.includes(x.id)
                            )
                            .map((option: any) => (
                              <ListItem
                                key={option.id}
                                value={option.id}
                                onClick={() => this.changeAssigneeWithMemberId(option.memberId, option.id)}
                                data-test-id="changeAssigneeWithMemberId"
                              >
                                <Box className="assignee-detail">
                                  <Box className="assignee-left">
                                    <Box className="assignee-profile">
                                      {option.initials}
                                    </Box>
                                    <Box className="assignee-info">
                                      <Box className="assignee-name">
                                        {option.title}
                                      </Box>
                                      <Box className="assignee-email">
                                        {option.users} {option.users >1 ? this.t(`${configJSON.membersTxt}`) : this.t(`${configJSON.memberTxt}`)}
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box className="assignee-right">
                                    <Box className="assinee-unselected">
                                      <img
                                        className="normal-img"
                                        src={checkBoxEmpty}
                                        alt="checkBoxEmpty"
                                      />
                                      <img
                                        className="selected-img"
                                        src={checkBoxFilled}
                                        alt="checkBoxFilled"
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </ListItem>
                            ))}
                        </List>
                      </TabPanel>
                      <TabPanel value={this.state.assigneeTabsValue} index={1}>
                        {assigneeOptions.length !==0 ? <List className="assignee-multiSelect-list">
                          <ListSubheader className="multiselect-membres">
                            <Box className="total-member">
                              {assigneeOptions.length}&nbsp;
                              {assigneeOptions.length > 1 ? this.t(`${configJSON.membersTxt}`): this.t(`${configJSON.memberTxt}`)}
                            </Box>
                            <FormControl className="select-control sortby-control">
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    horizontal: "left",
                                    vertical: "top",
                                  },
                                  getContentAnchorEl: null,
                                  classes: { paper: classes.dropdownStyle },
                                }}
                                displayEmpty
                                onChange={this.changeSortByAssignee}
                                data-testid="sortTeams"
                                defaultValue={this.t(`${configJSON.txtSortBy}`)}
                                IconComponent={ExpandMoreIcon}
                                renderValue={(value: any) => {
                                  return <Box>{value}</Box>;
                                }}                               
                              >
                                <MenuItem value="A-Z">{this.t(`${configJSON.aTozTxt}`)}</MenuItem>
                                <MenuItem value="Z-A">{this.t(`${configJSON.zToaTxt}`)}</MenuItem>
                              </Select>
                            </FormControl>
                          </ListSubheader>
                          {this.sortRecords(this.displayAssigneeOptions())
                            .filter(
                              (ass: any) =>
                                !this.state.selectedTaskAssignees.includes(ass.id)
                            )
                            .map((user: any) => (
                              <ListItem
                                  data-test-id={`changeAssignee-${user.id}`}
                                  value={user.id}
                                  onClick={() => this.changeAssignee(user.id)}
                                  key={user.id}
                              >
                                <Box className="assignee-detail">
                                  <Box className="assignee-left">
                                    <Box className="assignee-profile">
                                      {user.initials}
                                    </Box>
                                    <Box className="assignee-info">
                                      <Box className="assignee-name">
                                        {user.title}
                                      </Box>
                                      <Box className="assignee-email">
                                        {user.email}                                      
                                      </Box>  
                                      {user.job_role && <Chip
                                         className={classes.chipButton}
                                         label={user.job_role}
                                      />}                                   
                                    </Box>
                                  </Box>
                                  <Box className="assignee-right">
                                    <Box className="assinee-unselected">
                                      <img
                                        alt="checkBoxEmpty"
                                        src={checkBoxEmpty}
                                        className="normal-img"
                                      />
                                      <img
                                        className="selected-img"
                                        alt="checkBoxFilled"
                                        src={checkBoxFilled}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </ListItem>
                            ))}
                        </List> : <Typography className="not-found">{this.t(configJSON.memberNotFountError)}</Typography>
                        }
                        
                      </TabPanel>
                    </Box>
                  </Box>
                </Box>
              </Box>
      </Box>
    </Modal>
    )
  }

  changeStatusModal=(classes:any)=>{
    return(
      <Modal
        open={this.state.isActiveChangeStatus}
        onClose={this.changeStatusModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
      >
          <Box className={classes.modalDialogBox}>
            <Box className="modal-dialoginner">
              <Box className="modal-heading">
                <Typography className="modal-title" component="h2">
                {this.t(`${configJSON.txtChangeStatus}`)}
                </Typography>
                 <Box
                   
                  className="close-icon"
                  onClick={this.changeStatusModalClose}
                >
                  <CloseIcon />
                 </Box>
              </Box>
              <Box className="modal-description">
                <Box component="p" className="sub-txt">
                {this.t(`${configJSON.aductActivityTxt}`)}
                </Box>
                <Box className="modal-form" component="form">
                  <Box className="form-info-wrapper">
                    <Box className="form-row">
                      <Box className="form-col">
                        <FormControl
                          className="select-outer"
                          variant="outlined"
                        >
                          <InputLabel id="current-status">
                            {this.t(`${configJSON.currentStatusTxt}`)}
                          </InputLabel>
                          <Select
                            labelId="current-status"
                            id="current-status"
                            value={this.state.newStatus ?? 0}
                            onChange={this.handleStatusChange}
                            name="newStatus"
                            label={this.t(`${configJSON.currentStatusTxt}`)}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                              classes: {
                                paper: `${classes.dropdownStyle} modal-dropdown`,
                              },
                            }}
                            data-test-id="modalChangeStatus"
                          >
                            <MenuItem value={0} className="status-item">
                              <Box className="status-name">{this.t(`${configJSON.txtStatusOpen}`)}</Box>
                              <Box className="status open"></Box>
                            </MenuItem>
                            <MenuItem value={3} className="status-item">
                              <Box className="status-name">{this.t(`${configJSON.txtStatusInProgress}`)}</Box>
                              <Box className="status cancelled"></Box>
                            </MenuItem>                          
                            <MenuItem value={2} className="status-item">
                              <Box className="status-name">{this.t(`${configJSON.txtStatusCompleted}`)}</Box>
                              <Box className="status completed"></Box>
                            </MenuItem>
                            <MenuItem value={1} className="status-item">
                              <Box className="status-name">{this.t(`${configJSON.txtStatusCancelled}`)}</Box>
                              <Box className="status cancelled"></Box>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <CustomInputWeb
                          type="text"
                          label={this.t(`${configJSON.addANoteTxt}`)}
                          value={this.state.addNote}
                          name="addNote"
                          onChange={this.handleOnChange}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className="button-wrapper full-btn">
                    <Button
                      className={`primary-btn ${classes.primaryButton}`}
                      title="Update"
                      onClick={this.changeStatus}
                      data-test-id="updateStatusModal"
                    >
                      {this.t(`${configJSON.txtUpdate}`)}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
      </Modal>
    )
  }

  createTaskBlock=(classes:any)=>{
    const popOverOpen = Boolean(this.state.popoveAnchorEl);
    const popoverId = popOverOpen ? "simple-popover" : undefined;
    const timeStandOpen = Boolean(this.state.timeStandAnchorEl);
    const timeStandId = timeStandOpen ? "simple-popper" : undefined;
    return(
      <Box className={classes.createTaskWrapper}>
          <Box className="task-actions">
            {this.state.draftTasks.map((draftTask: any) => {
              return (
                  <Button
                    className={`secondary-btn ${classes.secondaryButton}`}                   
                  >
                    <Box
                      className="draft-name"
                      component="span"
                      onClick={(e: any) => this.openDraftTask(e, draftTask.id)}
                      data-test-id={`draftTask-${draftTask.id}`}
                    >
                      <img
                        className="draft-img"
                        src={fileTree}
                        alt="fileTree"
                      />
                      {draftTask.title == "" ? "Draft" : draftTask.title}
                    </Box>
                     <Box
                       
                      className="close"
                      onClick={(e: any) => this.deleteDraftTask(draftTask.id)}
                    >
                      <img src={closeGray} alt="closeGray" />
                     </Box>
                  </Button>
              );
            })}
            <Button
              className={`primary-btn ${classes.primaryButton}`}
              aria-describedby={popoverId}
              onClick={this.popoverHandleClick}
              ref={this.popoverRef}
              data-testid="createTaskBtn"
            >
              + {this.t(`${configJSON.txtCreateTask}`)}
            </Button>
          </Box>
          <Button style={{display:"none"}} data-test-id="selectedProducts" onClick={()=>this.getAllProductsFromInventory(["12"])}></Button>
          <Popover
            onClose={this.popoverHandleClose}
            open={popOverOpen}
            anchorEl={this.state.popoveAnchorEl}
            id={popoverId}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}           
            classes={{ paper: `${classes.createTaskBox}` }}
            data-test-id="create-task-popover"
            onKeyDown={(event) => {this.handleTaskEscEvent(event)}}
          >
            <Box
              onClick={this.popoverHandleClose}
              className="auth-back show-sm"
            >
              <i>
                <svg
                  className="MuiSvgIcon-root"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                </svg>
              </i>
            </Box>    
            <Box className="task-heading">
              <Box className="heading-left">
                <Typography className="modal-title" component="h2">
                  {this.state.isEditing ? this.t(configJSON.txtEditTask) : this.t(configJSON.txtCreateTask)}
                </Typography>
                <Box component="p" className="sub-txt">
                  {this.state.isEditing
                    ? this.t(configJSON.letsEditTaskSubTxt)
                    : this.t(configJSON.txtLetCreate)}
                </Box>
              </Box>
              <Box className="heading-right">
                 <Box
                  data-test-id="saveAsDraftTask"
                  onClick={this.saveAsDraftTask}
                  className="heading-icon hide-sm"
                   
                >
                  <img alt="minimizeGray" src={minimizeGray}  />
                 </Box>
                 <Box
                  onClick={this.popoverHandleClose}
                  className="heading-icon hide-sm"
                   
                  data-test-id="closeTaskPopover"
                >
                  <img alt="closeGray" src={closeGray}  />
                 </Box>
              </Box>
            </Box>
            <Box className="task-description">
              <Box className="task-form" component="form">
                <Box className="form-info-wrapper">
                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomInputWeb
                        data-test-id="crateTaskTitle"
                        label={this.t(`${configJSON.txtTitle}`)}
                        value={this.state.title}
                        errors={this.state.error.title}
                        type="text"
                        name="title"
                        onChange={this.handleOnChange}
                        isRequired={true}
                      />
                    </Box>
                  </Box>
                  <Box className="form-row">
                  <Box className="form-col col6">
                    <FormControl className="select-outer" variant="outlined">
                      <InputLabel id="project">{this.t(`${configJSON.projectTxt}`)}</InputLabel>
                      <Select
                        labelId="project"
                        id="project"
                        value={this.state.projectId > 0 ?this.state.projectId:undefined}
                        onChange={this.projectChangeHandler}
                        name="project"
                        label={this.t(`${configJSON.projectTxt}`)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: `${classes.dropdownStyle} modal-dropdown`,
                          },
                        }}
                        data-test-id="projectSelect"
                        IconComponent={ExpandMoreIcon}
                        disabled={this.props.projectId != undefined}
                      >
                        {this.state.projectList.map((proj) => {
                          return (
                            <MenuItem value={Number(proj.id)} key={proj.id}>
                              {proj.attributes.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="form-col col6">
                    <FreeSoloCreateOption
                      options={this.state.allTaskList.map((val) => {
                        return {
                          id: val.id,
                          title: val.attributes.name,
                        };
                      })}
                      addEditCallback={this.taskListAdd}
                      addLinkTitle={this.t(`${configJSON.addNewTaskListTxt}`)}
                      noOptionTitle={this.t(`${configJSON.noTaskListFoundTxt}`)}
                      label={this.t(`${configJSON.labelTitleText}`)}
                      id="job-id"
                      onChange={this.taskListHandler}
                      value={this.state.taskListObject.name}
                      errors={this.state.error.tasklistName}
                      errorMsgNoRecord={this.t(`${configJSON.notCreatedTasklistTxt}`)}
                      hideOtherOptions={true}
                      disabled={this.props.taskListId != undefined}
                      data-test-id="taskTasklist"
                      notRequired={true}
                    />
                  </Box>
                </Box>
                  <Box className="form-row">
                    <Box className="form-col col6">
                      <Box className="form-control">
                        <CustomDatePicker
                          error={this.state.error.due_date}
                          currentDate={this.state.due_date}
                          onOpenPickNewDate={false}
                          t={this.t} 
                          data-test-id="crateTaskDueDate"
                          required={false}
                          onChange={this.changeDueDate}
                          placeholder={this.t(`${configJSON.txtDueDate}`)}
                          showPastWarning={true}
                          inPortal={true}          
                        />
                      </Box>
                    </Box>
                    <Box className="form-col col6">
                      <Box className="form-control">
                        <CustomDatePicker
                          placeholder={this.t(`${configJSON.txtDeadline}`)}
                          currentDate={this.state.deadline_date}
                          onChange={this.changeDeadlineDate}
                          onOpenPickNewDate={false}
                          data-test-id="crateTaskDeadline"
                          showPastWarning={true}
                          inPortal={true}
                          t={this.t}
                          required={false}
                          isDeal={false}
                          range={false}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomInputWeb
                        type="text"
                        label={this.t(`${configJSON.txtDescription}`)}
                        isMultiline
                        rows={4}
                        value={this.state.description}
                        name="description"
                        onChange={this.handleOnChange}
                        data-test-id="crateTaskDesc"
                      />                      
                    </Box>
                  </Box>                              
                  <Box className="form-row">
                  <Box className="wrap-option">                      
                     {!this.state.showAddProductsAssets && this.state.selectedProductsId.length === 0 && (
                       <Button
                         className="btn-add-option"
                         data-test-id="handleShowAssets"
                         onClick={this.handleShowInventoryAssets}
                       >
                         + {this.t(`${configJSON.addProductAssestsTxt}`)}
                       </Button>
                     )}
                  </Box>
                  <Box className="wrap-option">                      
                     {!this.state.showAddExpenditures && (
                       <Button
                         className="btn-add-option"
                         data-test-id="handleShowExpenditures"
                         onClick={this.handleShowExpenditures}
                       >
                         + {this.t(`${configJSON.addExpenditures}`)}
                       </Button>
                     )}
                  </Box>
                  <Box className="wrap-option">                      
                     {!this.state.showAddDeal && this.state.selectedDeals===""  && (
                       <Button
                         className="btn-add-option"
                         data-test-id="handleShowDeal"
                         onClick={this.handleShowAddDeals}
                       >
                         + {this.t(`${configJSON.addDealTxt}`)}
                       </Button>
                     )}
                  </Box>
                  </Box>
                  {this.state.showAddExpenditures && 
                  <Box className="form-row">
                    <Box className="form-col">
                    <Typography className="expenditure-title">
                    {this.t(`${configJSON.addExpenditures}`)}
                    </Typography>
                      <CustomInputWeb
                        type="number"                       
                        placeholder={this.t(`${configJSON.expendituresText}`)}  
                        autoFocus={true}                     
                        value={this.state.expenditures}
                        name="expenditures"
                        onChange={this.handleOnChange}
                        data-test-id="expenditures"
                        onKeyDown={(e: any) => {
                          if (["e", "E", "-"].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            <AttachMoneyIcon />
                          </InputAdornment>
                        }
                      />                      
                    </Box>
                  </Box>}  
                  {this.state.showAddDeal && this.addDealsModal(classes)}
                  {this.state.isDealsFilter && this.renderFiltersModal(classes)}
                  {this.state.showInventoryAssets &&   
                  <AddInventoryAndAssests
                    openProductsModal={this.state.showInventoryAssets}
                    closeProductsModal={this.handleShowInventoryAssetsClose}
                    classes={this.props.classes}
                    selectedDealProducts={this.selectedProducts}
                    buttonTxt={this.t("Continue")}
                    data-test-id="is-save-product"
                  />}                 
                   {this.state.viewSelectedProducts && (
                    <ProductInventoryViewModal
                      modalOpen={this.state.viewSelectedProducts}
                      modalClose={this.handleViewInventoryAssetsClose}
                      selectedProductsData={this.state.selectedProductsData}
                      openEdit={this.handleShowInventoryAssets}
                      classes={classes}
                      t={this.t}
                    />
                  )}
                  {this.state.selectedProductsId.length > 0 &&  <Box className="info-group favorite-product">           
                  <Box className="group-form favorite-box" component="form">
                    <Box className="category-left-box">
                      <Typography className="left-title">
                      {this.state.selectedProductsId.length} {this.t(`${configJSON.inventoryItemAddedTxt}`)}
                      </Typography>               
                      <Typography className="total-amount-txt">
                      {generateSummary(this.state.productCountAndName, this.t)}
                      </Typography>
                    </Box>
                    <Box className="category-right-box">                      
                      <Button
                        className="arrow-btn"
                        data-test-id="view-products"
                        onClick={this.handleViewInventoryAssets}
                      >
                         <ArrowForwardIosIcon className="btn-icon" />
                      </Button>
                    </Box>
              </Box>
          </Box>}
                  {this.state.selectedDeals &&   <Box className="info-group favorite-product">           
                  <Box className="group-form favorite-box" component="form">
                    <Box className="category-left-box">
                      <Typography className="left-title">
                      {this.state.singleDealData.attributes?.name}
                      </Typography>               
                      <Typography className="total-amount-txt">
                      To [{this.state.singleDealData.attributes?.contacts.length > 0 && (
                                      <>
                                        {this.state.singleDealData.attributes?.contacts
                                          .map(
                                            (contact: {
                                              id: string;
                                              name: string;
                                            }) => contact.name
                                          )
                                          .join(", ")}
                                      </>
                                    )}]; Close by {this.renderCloseDate(this.state.singleDealData.attributes?.close_date)}
                      </Typography>
                    </Box>
                    <Box className="category-right-box">
                      <Typography className="amount-txt">
                      ${this.state.singleDealData.attributes?.total_amount}                       
                      </Typography>
                      <Button
                        className="arrow-btn"
                        data-test-id="close-deal"
                        onClick={this.handleCloseShowAddDeals}
                      >
                        <CloseIcon className="btn-icon" />
                      </Button>
                    </Box>
              </Box>
          </Box>}
                </Box>              
                <Box className="form-info-wrapper">
                  <Typography className="form-heading" variant="h2">
                  {this.t(`${configJSON.attachmentsTxt}`)}
                  </Typography>
                  <FileDrop
                    onDragOver={this.dragEnter}
                    onDrop={() => this.handleOnDrop}
                    onDragLeave={this.dragLeave}
                    data-test-id="handleOnDrop"
                  >
                    <Box
                      onClick={this.onBrowse}
                      className={`${classes.fileUploadWrapper} ${this.state.fileDrop && classes.fileDropDrag} `}
                      data-test-id="onBrowse"
                    >
                      <form ref={this.formRef}>
                        <input
                          name="upload-photo"
                          type="file"
                          accept="*"
                          onChange={this.handleOnDrop}
                          multiple
                          ref={this.fileRef}
                          hidden
                          data-test-id="add-task-attachment"
                        />
                      </form>
                      <Box className="file-upload-block">
                        <Box component="p" className="file-subtxt">
                          {this.t(`${configJSON.clickHereTxt}`)}
                          <Box component="span" className="browse-link">
                            {this.t(`${configJSON.browseTxt}`)}
                          </Box>
                          {this.t(`${configJSON.yourPictureTxt}`)}
                        </Box>
                        <Box className="upload-icon">
                          <img src={uploadImg} alt="uploadImg" />
                        </Box>
                        <Button
                          className={`secondary-btn2 ${classes.secondaryButton}`}
                        >
                          {this.t(`${configJSON.addAttachmentsTxt}`)}
                        </Button>
                      </Box>
                    </Box>
                  </FileDrop>
                  {this.state.filesUploaded.length > 0 && (
                 <List className="attachlist-ul">
                    {this.state.filesUploaded.map((file: any, index: any) => {
                      return(
                        <ListItem
                        disableRipple
                        className="attachlist-li"                        
                        role={undefined}
                        dense
                        button
                        key={file.name}
                      >
                        <ListItemIcon className="attachlist-icon">
                          <img src={checkGreen} alt="checkGreen" />
                        </ListItemIcon>
                        <ListItemText
                          className="attachlist-label"                         
                          primary={file.name || file.filename}
                        />
                        <ListItemSecondaryAction>
                           <Box   className="tasklist-link">
                            <img src={closeGray} alt="closeGray" data-test-id="removeFileUPload" onClick={()=>this.removeFileUPload(index)}/>
                           </Box>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )})}
                  </List>
                )}                  
                </Box>
                <Box>
                  <p style={{ textAlign:"right", padding: "20px", border:"2px dashed #e5e5e5", color:"#AFBACA" }}>{this.t(`${configJSON.txtProjectedLabourCost}: `)}<span style={{color:"#2B2B2B"}}>£{this.LabourCostCalculation()}</span></p>
                </Box>
              </Box>
            </Box>
            <Box className="createtask-bottom">
              <Box className="createtask-left">
                <Box className="outerbutton-wrapper">
                   <Box
                    data-test-id="crateTaskPriority"
                    className="outer-link dropmenu-btn"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                     
                    onClick={this.dropMenuHandler}
                  >
                    <Box
                      className={`priority ${
                        this.state.priority == 1 || this.state.priority == 2
                          ? "active-priority"
                          : ""
                      }`}
                    >
                      {this.state.priority == 1 ? "!!" : "!!!"}
                    </Box>
                   </Box>
                  <Menu
                    open={Boolean(this.state.createTaskanchorEl)}
                    className={classes.dropDropdown}
                    transformOrigin={{
                      horizontal: "left",
                      vertical: "bottom",
                    }}
                    anchorEl={this.state.createTaskanchorEl}
                    keepMounted
                    onClose={() => this.dropMenuClose(this.state.priority)}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    id="simple-menu"      
                  >
                    <MenuItem
                      data-test-id="CreateTaskPriorityEmergency"
                      onClick={() => this.dropMenuClose(2)}
                    >
                      <Box className="priority-name">{this.t(`${configJSON.txtEmergency}`)}</Box>
                      <Box className="priority-icon">!!!</Box>
                    </MenuItem>
                    <MenuItem
                      data-test-id="CreateTaskPriorityCritical"
                      onClick={() => this.dropMenuClose(1)}
                    >
                      <Box className="priority-name">{this.t(`${configJSON.txtCritical}`)}</Box>
                      <Box className="priority-icon">!!</Box>
                    </MenuItem>
                    <MenuItem
                      data-test-id="CreateTaskPriorityNone"
                      onClick={() => this.dropMenuClose(0)}
                    >
                      <Box className="priority-name">{this.t(`${configJSON.txtNone}`)}</Box>
                    </MenuItem>
                  </Menu>
                </Box>
                <Box className="outerbutton-wrapper">
                   <Box
                    data-test-id="CreateTaskHours"
                    className="outer-link"
                    aria-describedby={timeStandId}
                    onClick={this.toggleHoursModal}
                     
                  >
                    <img alt="timerSand" src={timerSand}/>
                    <Box className="time">{this.state.taskHours}</Box>
                   </Box>
                  {this.state.showHoursModal && (
                    <Box className={classes.timestandDropbox} id={timeStandId}>
                      <Box className="timestand-inner">
                        <Box className="timestand-heading">{this.t(`${configJSON.txtTaskHours}`)}</Box>
                        <Box component="form">
                          <Box className="time">
                            <TextField
                              InputProps={{
                                disableUnderline: true,
                              }}
                              inputProps={{
                                step: 1,
                                min: 0,
                                max: 999,
                              }}
                              placeholder="00"
                              id="standard-basic"
                              name="hours"
                              label=""
                              value={this.state.hours}
                              onChange={this.hoursChange}
                              type="number"
                              data-test-id="CreateTaskHoursInput"
                            />
                            <Box component="span" className="colon-txt">
                              :
                            </Box>
                            <TextField
                              InputProps={{
                                disableUnderline: true,
                              }}
                              placeholder="00"
                              id="standard-basic2"
                              label=""
                              name="minutes"
                              type="number"
                              value={this.state.minutes}
                              onChange={this.minutesChange}
                              data-test-id="CreateTaskMinutesInput"
                            />                           
                          </Box>
                        </Box>
                      </Box>
                      <Box className="timestand-actions">
                         <Box                           
                          className="secondary-link"
                          onClick={this.toggleHoursModal}
                          data-test-id="CreateTaskHoursCancel"
                        >
                          {this.t(`${configJSON.txtCancel}`)}
                         </Box>
                         <Box                           
                          className="primary-link"
                          onClick={this.setHours}
                          data-test-id="CreateTaskHoursSave"
                        >
                          {this.t(`${configJSON.txtSave}`)}
                         </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box className="outerbutton-wrapper">
                   <Box
                    data-test-id="createTaskassignee"
                    onClick={this.assigneeHandler}
                    className="outer-link"
                     
                  >
                    <img alt="accountPlus"  src={accountPlus} />
                    <Box className="assignee-list">
                      {this.displayAssigneeOptions()
                        .filter((y: any) => this.state.assignees.includes(y.id))
                        .slice(0, 3)
                        .map((user: any) => {
                          return (
                            <Box className="assignee-bubble" key={user.id}>
                              {user.initials}
                            </Box>
                          );
                        })}
                      {this.state.assignees.length > 3 && (
                        <Box className="assignee-bubble">
                          +{this.state.assignees.length - 3}
                        </Box>
                      )}
                    </Box>
                   </Box>
                </Box>
              </Box>
              <Box className="createtask-right">
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  onClick={this.createTask}
                  data-test-id="saveTaskBtn"
                >
                  {this.state.isEditing ? this.t(configJSON.updateTaskTxt) : `+ ${this.t(configJSON.txtCreateTask)}`}
                </Button>
              </Box>
            </Box>
          </Popover>
      </Box>
    )
  }

  renameTasklistModal=(classes:any)=>{
    return(
      <Modal
      open={this.state.isRenameTasklist}
      onClose={this.renameTaskListModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalDialog}
    >
      <Box className={`${classes.modalDialogBox} create-tasklist-dialog`}>
        <Box className="modal-dialoginner">
          <Box className="modal-heading">
            <Typography className="modal-title" component="h2">
              {this.t(`${configJSON.renameTasklistTxt}`)}
          </Typography>
             <Box
               
              className="close-icon"
              onClick={this.renameTaskListModalClose}
              data-test-id="closeTaskListEdit"
            >
              <CloseIcon />
             </Box>
          </Box>
          <Box className="modal-description">
            <Box component="p" className="sub-txt">
            {this.t(`${configJSON.renameTasklistTxt}`)}
          </Box>
            <Box className="modal-form" component="form">
              <Box className="form-info-wrapper">
                <Box className="form-row">
                  <Box className="form-col">
                    <CustomInputWeb
                      type="text"
                      label={this.t(`${configJSON.tasklistNameTxt}`)}
                      value={this.state.tasklistName}
                      name="tasklistName"
                      onChange={this.handleOnChange}
                      errors={this.state.error.tasklistName}
                      data-test-id="tasklistName"
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="button-wrapper full-btn">
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  title="Update"
                  onClick={this.editTasklist}
                  data-test-id="updateTasklist"
                >
                {this.t(`${configJSON.txtUpdate}`)}
              </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
    )
  }

  moveTasklistModal=(classes:any)=>{
    return(
      <Modal
        open={this.state.isActiveMoveTasklist}
        onClose={this.moveTasklistModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
        >
          <Box className={`${classes.modalDialogBox} move-tasklist-dialog`}>
            <Box className="modal-dialoginner">
              <Box className="modal-heading">
                <Typography className="modal-title" component="h2">
                  {this.t(`${configJSON.moveTasklistTxt}`)}
                </Typography>
                 <Box
                   
                  className="close-icon"
                  onClick={this.moveTasklistModalClose}
                  data-test-id="assigneeModalClose"
                >
                  <CloseIcon />
                 </Box>
              </Box>
              <Box className="modal-description">
                <Box component="p" className="sub-txt">
                {this.t(`${configJSON.moveTasklistSubTxt}`)}
                </Box>
                <Box className="modal-form" component="form">
                  <Box className="form-info-wrapper">
                    <RadioGroup
                      aria-label="templatelist"
                      name="templatelist1"
                    >
                      <List className="move-tasklist-list">
                        <ListSubheader className="multiselect-membres">
                          <Box className="total-member">
                            {this.t(`${configJSON.currentProjectTxt}`)}
                          </Box>
                        </ListSubheader>
                        <ListItem
                          className="template-li" key={this.props.projectId} role={undefined}
                        >
                          <Box
                            className="template-left"
                          >
                            <Box className="name">
                              {this.state.currentProject?.attributes.title}
                            </Box>
                            <Box className="sub-txt">
                              {this.state.currentProject?.attributes.category?.category}
                            </Box>
                          </Box>
                        </ListItem>
                      </List>
                    </RadioGroup>
                  </Box>
                  <Box className="form-info-wrapper">
                    <RadioGroup
                      aria-label="templatelist"
                      name="templatelist1"
                    >
                      <List className="move-tasklist-list">
                        <ListSubheader className="multiselect-membres">
                          <Box className="total-member">
                            {this.t(`${configJSON.otherProjectsTxt}`)}
                          </Box>
                        </ListSubheader>
                        {this.state.projectList.filter((x)=>x.id !== this.state.currentProject?.id).map((value:any) => {
                          return (
                            <ListItem
                              className="template-li" key={value.id} role={undefined}
                              onClick={this.moveTasklistGroupList(value.id)}
                              data-test-id={`moveTasklist-${value.id}`}
                            >
                              <Box
                                className="template-left"
                              >
                                <Box className="name">
                                {value.attributes.title}
                              </Box>
                                <Box className="sub-txt">
                                {value.attributes.category?.category}
                              </Box>
                              </Box>
                              <ListItemIcon className="template-icon">
                                <Radio
                                  checked={this.state.moveTasklistChecked === value.id}
                                  tabIndex={-1}
                                
                                />
                              </ListItemIcon>
                            </ListItem>
                          );
                        })}
                      </List>
                    </RadioGroup>
                  </Box>
                </Box>
              </Box>
              <Box className="modal-footer">                 
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  title="Save"
                  onClick={this.moveTasklist}
                  data-test-id="saveBtn"
                >
                 {this.t(`${configJSON.txtSave}`)}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
    )
  }

  moveTasklistProjectModal=(classes:any)=>{
    return(
      <Modal
        open={this.state.isMoveTasklistproject}
        onClose={this.moveTasklistProjectModalClose}
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
        aria-labelledby="modal-modal-title"
        >
          <Box className={`${classes.modalDialogBox} move-tasklist-dialog`}>
            <Box className="modal-dialoginner 1">
              <Box className="modal-heading">
                <Typography component="h2" className="modal-title">
                  {this.t(`${configJSON.moveTasklistProjectTitle}`)}
                </Typography>
                 <Box
                  className="close-icon"
                   
                  onClick={this.moveTasklistProjectModalClose}
                  data-test-id="assigneeModalClose"
                >
                  <CloseIcon />
                 </Box>
              </Box>
              <Box className="modal-description">
                <Box className="sub-txt" component="p">
                {this.t(`${configJSON.moveTasklistProjectSubTitle}`)}
                </Box>              
                <Box className="tasklist-tabwrapper">
              <Box>
                <Tabs
                  aria-label="simple tabs example"
                  onChange={this.setViewTabsValue}
                  value={this.state.viewTabsValue}
                  data-test-id="viewTaskBottomTabs1"
                >
                  <Tab label={this.t(`${configJSON.projectsTxt}`)} {...viwA11yProps(0)} />
                  <Tab label={this.t(`${configJSON.tasklistsTxt}`)} {...viwA11yProps(1)} />                  
                </Tabs>
              </Box>
              <TabPanel value={this.state.viewTabsValue} index={0}>
              <Box className="modal-form" component="form">
                  <Box className="form-info-wrapper">
                    <RadioGroup
                      name="templatelist1"
                      aria-label="templatelist"
                    >
                      <List className="move-tasklist-list 1">
                        <ListSubheader className="multiselect-membres 1">
                          <Box className="total-member 1">
                            {this.t(`${configJSON.currentProjectTxt}`)}
                          </Box>
                        </ListSubheader>
                        <ListItem
                          className="template-li" role={undefined} key={this.props.projectId}
                        >
                          <Box className="template-left">
                            <Box className="name 1">
                              {this.state.currentProject?.attributes.title}
                            </Box>
                            <Box className="sub-txt 1">
                              {this.state.currentProject?.attributes.category?.category}
                            </Box>
                          </Box>
                        </ListItem>
                      </List>
                    </RadioGroup>
                  </Box>
                  <Box className="form-info-wrapper 2">
                    <RadioGroup
                      name="templatelist1"
                      aria-label="templatelist"
                    >
                      <List className="move-tasklist-list 2">
                        <ListSubheader className="multiselect-membres 2">
                          <Box className="total-member 2">
                            {this.t(`${configJSON.otherProjectsTxt}`)}
                          </Box>
                        </ListSubheader>
                        {this.state.projectList.filter((x)=>x.id !== this.state.currentProject?.id).map((value:any) => {
                          return (
                            <ListItem
                              className="template-li" 
                              onClick={this.moveTasklistGroupList(value.id)}
                              role={undefined}
                              key={value.id}
                              data-test-id={`moveTasklist-${value.id}`}
                            >
                              <Box className="template-left">
                                <Box className="name 2">
                                {value.attributes.title}
                              </Box>
                                <Box className="sub-txt 2">
                                {value.attributes.category?.category}
                              </Box>
                              </Box>
                              <ListItemIcon className="template-icon 2">
                                <Radio
                                  tabIndex={-1}                                
                                  checked={this.state.moveTasklistChecked === value.id}
                                />
                              </ListItemIcon>
                            </ListItem>
                          );
                        })}
                      </List>
                    </RadioGroup>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={this.state.viewTabsValue} index={1}>
              <Box className="modal-form" component="form">
                  <Box className="form-info-wrapper">
                    <RadioGroup
                      aria-label="templatelist "
                      name="templatelist1 "
                    >
                      <List className="move-tasklist-list">
                        <ListSubheader className="multiselect-membres">
                          <Box className="total-member">
                            {this.t(`${configJSON.currentTasklistTxt}`)} 
                          </Box>
                        </ListSubheader>
                        <ListItem
                          className="template-li" role={undefined} key={this.props.projectId} 
                        >
                          <Box  className="template-left">
                            <Box className="name 3">
                              {this.state.editTask?.attributes.task_list_name}
                            </Box>
                            <Box className="sub-txt 3">
                              {this.state.editTask?.attributes.project_title}
                            </Box>
                          </Box>
                        </ListItem>
                      </List>
                    </RadioGroup>
                  </Box>
                  <Box className="form-info-wrapper ">
                    <RadioGroup
                      aria-label="templatelist "
                      name="templatelist1 "
                    >
                      <List className="move-tasklist-list ">
                        <ListSubheader className="multiselect-membres ">
                          <Box className="total-member">
                            {this.t(`${configJSON.otherTasklistTxt}`)}
                          </Box>
                        </ListSubheader>
                        {this.state.taskListData.filter((x)=>x.id !== this.state.editTask?.id).map((value:any) => {
                          return (
                            <ListItem
                              className="template-li" key={value.id} role={undefined}
                              data-test-id={`movedTasklistEvent-${value.id}`}
                              onClick={this.movedTasklistEvent(value.attributes.account_block_task_list_id)}
                            >
                              <Box
                                className="template-left"                           
                              >
                                <Box className="name">
                                {value.attributes.task_list_name}
                              </Box>
                                <Box className="sub-txt">
                                {value.attributes.title}
                              </Box>
                              </Box>
                              <ListItemIcon className="template-icon">
                                <Radio
                                  tabIndex={-1}
                                  checked={this.state.movedTasklist === value.attributes.account_block_task_list_id}
                                
                                />
                              </ListItemIcon>
                            </ListItem>
                          );
                        })}
                      </List>
                    </RadioGroup>
                  </Box>
                </Box>
              </TabPanel>              
            </Box>
              </Box>
              <Box className="modal-footer">                 
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  title="Save"
                  onClick={this.moveTasklistProject}
                  data-test-id="moveTasklistProject"
                >
                 {this.t(`${configJSON.txtSave}`)}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
    )
  }
  renderCloseDate = (date: any) => {
    if (date === null) {
      return "-";
    }
    return moment(date).format("MMM DD, YYYY");
  };

 stageStatusClassMaping = (dealStageStatus: string) => {
    if (dealStageStatus === "Initiated") {
      return <Box className="status sky-blue"></Box>;
    } else if (dealStageStatus === "sent proposal") {
      return <Box className="status orange"></Box>;
    } else if (dealStageStatus === "follow up") {
      return <Box className="status yellow"></Box>;
    } else if (dealStageStatus === "won") {
      return <Box className="status green"></Box>;
    } else if (dealStageStatus === "lost") {
      return <Box className="status black"></Box>;
    }
  };

  addDealsModal=(classes:any)=>{  
    let dealsListData;
    const { allDealsData, searchResults } =
      this.state;
    if (this.state.isSearching) {
      dealsListData = searchResults;
    } else {
      dealsListData = allDealsData;
    } 
    return(
      <Modal
        open={this.state.showAddDeal}
        onClose={this.handleCloseShowAddDeals}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
        >
          <Box className={`${classes.modalDialogBox} move-tasklist-dialog`}>
            <Box className="modal-dialoginner">
              <Box className="modal-heading">
                <Typography component="h2" className="modal-title" >
                  {this.t(`${configJSON.chooseDealsTxt}`)}
                </Typography>
                 <Box
                   
                  className="close-icon"
                  onClick={this.handleCloseShowAddDeals}
                  data-test-id="handleCloseShowAddDeals"
                >
                  <CloseIcon />
                 </Box>
              </Box>
              <Box className="modal-description">
                <Box component="p" className="sub-txt">
                {this.t(`${configJSON.chosseDealsSubTxt}`)}
                </Box>
                <Box className="modal-form" component="form">
                  <Box className="form-info-wrapper">
                    <RadioGroup
                      aria-label="templatelist"
                      name="templatelist1"
                    >
                      <List className="move-tasklist-list">
                      <Box className="search-wrapper">
                      <Box className="search-box">
                        <CustomInputWeb
                          type="search"
                          label=""
                          value={this.state.searchValue}
                          onChange={this.handleSearch}
                          placeholder={this.t(`${configJSON.searchPlaceholder}`)}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon style={{ color: "#225980" }} />
                            </InputAdornment>
                          }
                          data-test-id="searchfield"
                        />
                        <Box className="sort-by" data-test-id="handleFiltersOPen" onClick={this.handleDealsFilter}>
                          <img src={sortby} alt="bg-image" className="filter-image" />
                        </Box>
                      </Box>
                    </Box>
                        <ListSubheader className="multiselect-membres">
                          <Box className="total-member">
                          {dealsListData.length > 1
                          ? `${
                            dealsListData.length
                          } ${this.t(`${configJSON.dealsTxt}`)}`
                          : `${
                            dealsListData.length
                          } ${this.t(`${configJSON.dealTxt}`)}`}
                          </Box>
                          <FormControl className="select-control sortby-control">
                                <Select
                                IconComponent={ExpandMoreIcon}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      horizontal: "left",
                                      vertical: "top",
                                    },
                                    getContentAnchorEl: null,
                                    classes: { paper: classes.dropdownStyle },
                                  }}
                                  displayEmpty                                 
                                  onChange={this.handleSortByDeals}
                                  defaultValue={this.t(`${configJSON.txtSortBy}`)}
                                  data-test-id="sortDeals"
                                  renderValue={(value: any) => {
                                    return <Box>{value}</Box>;
                                  }}                                  
                                >
                                  <MenuItem value="A-Z">{this.t(`${configJSON.aTozTxt}`)}</MenuItem>
                                  <MenuItem value="Z-A">{this.t(`${configJSON.zToaTxt}`)}</MenuItem>
                                </Select>
                          </FormControl>                      
                        </ListSubheader> 
                          <Box className="line"></Box>
                      </List>
                    </RadioGroup>
                  </Box>
                  <Box className="form-info-wrapper">
                    <RadioGroup
                      aria-label="templatelist"
                      name="templatelist1"
                    >
                      <List className="move-tasklist-list">                       
                        {dealsListData.map((value:any) => {
                          return (
                            <ListItem
                              className="template-li"
                              key={value.id}
                              onClick={this.dealsValue(value.id)}
                              data-test-id={`dealsValue-${value.id}`}
                            >
                              <Box
                                className="template-left"
                              >
                                <Box className="deal-container">
                                  <Typography className="deal-close-date">
                                    Close by:&nbsp;
                                    {this.renderCloseDate(value.attributes.close_date)}
                                    </Typography>
                                    <Typography className="deal-close-date content-dots"></Typography>                                   
                                            <Box className="status-item">
                                            <Box className="status-name">
                                              {this.t(`${value.attributes.stage}`)}
                                            </Box>
                                            {this.stageStatusClassMaping(
                                              value.attributes.stage
                                            )}
                                          </Box>
                                </Box>
                                <Box className="deal-name">
                                {value.attributes.name}
                              </Box>
                              <Box>                              
                              <table>
                                <tr>
                                  <th className="deal-owner">{this.t(`${configJSON.contactTxt}`)}</th>
                                  <th className="deal-owner">{this.t(`${configJSON.dealOwnerNameTxt}`)}</th>
                                </tr>
                                <tr>
                                  <td className="deal-owner-name">{value.attributes.contacts.length > 0 && (
                                      <>
                                        {value.attributes.contacts
                                          .map(
                                            (contact: {
                                              id: string;
                                              name: string;
                                            }) => contact.name
                                          )
                                          .join(", ")}
                                      </>
                                    )}</td>
                                  <td className="deal-owner-name">{value.attributes.deal_owner.name}</td>                                
                                </tr>                                
                              </table>
                          </Box>                              
                              </Box>
                              <ListItemIcon className="template-icon-deal">
                              <Box className="price-text">
                                ${value.attributes.total_amount}
                              </Box>
                                <Radio
                                  checked={this.state.selectedDeals === value.id}
                                  tabIndex={-1}                                
                                />
                              </ListItemIcon>
                            </ListItem>
                          );
                        })}
                      </List>
                    </RadioGroup>
                  </Box>
                </Box>
              </Box>
              <Box className="modal-footer">                 
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  data-test-id="handleContinouDeal"
                  onClick={() => this.handleContinouDeal(this.state.selectedDeals)}
                >
                 {this.t(`${configJSON.continoueBtnTxt}`)}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
    )
  }
  renderFiltersModal = (classes:any) =>{ 
    const { allDealsData } =
      this.state;  
      const ownerFilters = allDealsData.map((dealOwner: any) => ({
        value: dealOwner.attributes.deal_owner.id,
        label: dealOwner.attributes.deal_owner.name,
      }));
      const uniqueOwnerFiltersSet = new Set(
        ownerFilters.map((item: any) => item.value)
      );
  
     const uniqueOwnerFilters = Array.from(uniqueOwnerFiltersSet).map((value) =>
        ownerFilters.find((item: any) => item.value === value)
      );
    const uniqueContactNamesSet = new Set(); 
    const uniqueContacts: any = [];

    allDealsData.forEach((deal: any) => {
      const contacts = deal.attributes.contacts || [];
      contacts.forEach((contact: { id: string; name: string }) => {
        const contactId = contact.id;
        const contactName = contact.name;

        if (!uniqueContactNamesSet.has(contactName)) {
          uniqueContactNamesSet.add(contactName);
          uniqueContacts.push({ id: contactId, name: contactName });
        }
      });
    });

    return(
    <Modal
      className={classes.modalFilterDialog}
      aria-labelledby="modal-modal-title"
      onClose={this.handleCloseDealsFilter}
      open={this.state.isDealsFilter}
      aria-describedby="modal-modal-description"
    >
      <Box className={`view-modal-dialog ${classes.modalFilterDialogBox}`}>
        <Box className="modal-content">
          <Box className="modal-wrapper">
            <Box>
              <Typography className="modal-headding">{this.t(`${configJSON.txtFilters}`)}</Typography>
            </Box>
            <Box className="reset-cross">
              <Box
                className="reset-txt"
                onClick={this.resetFilters}
                data-test-id="resetFilters"
              >
                {this.t(`${configJSON.txtReset}`)}
              </Box>
              <Box
                className="close-icon"
                onClick={this.handleCloseDealsFilter}
                data-test-id="handleFiltersClose"
              >
                <CloseIcon className="close-icon" />
              </Box>
            </Box>
          </Box>
          <Typography className="desc"></Typography>
              <Typography className="filter-headding">{this.t(`${configJSON.stageTxt}`)}</Typography>
              <FormControl
                className="select-outer"
                variant="outlined"
              >
                <InputLabel>
                  {this.t(`${configJSON.stageTxt}`)}
                </InputLabel>
                <Select
                  value={this.state.filterStageVal}
                  name="filterStageVal"
                  onChange={this.handleFiltersInputChange}
                  label={this.t(`${configJSON.chooseCategory}`)}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  data-test-id="stage-filter"
                >                  
                  {configJSON.stageState.map((unit: any) => {
                    return (
                      <MenuItem value={unit.value} key={unit.value} className="status-item">
                      <Box className="status-name">{this.t(`${unit.name}`)}</Box>
                      <Box className={`status ${unit.className}`}></Box>
                    </MenuItem>
                    )
                  })}

                </Select>
              </FormControl>      
            <Typography className="filter-headding">{this.t(`${configJSON.dealAmountTxt}`)}</Typography>
            <Box className="form-row">
            <Box className="form-col col6">
            <Box className="form-control">
                   <CustomInputWeb
                      type="number"
                      label={this.t(`${configJSON.fromTxt}`)}
                      value={this.state.fromAmount}
                      name="fromAmount"
                      onChange={this.handleFiltersInputChange}                
                      data-test-id="fromAmount"
                    />
              </Box>
              </Box>
              <Box className="form-col col6">
                <Box className="form-control">
                    <CustomInputWeb
                      type="number"
                      label={this.t(`${configJSON.toTxt}`)}
                      value={this.state.toAmount}
                      name="toAmount"
                      onChange={this.handleFiltersInputChange}
                      data-test-id="toAmount"
                    />
               </Box>
              </Box>
              </Box>  
              <Typography className="filter-headding">{this.t(`${configJSON.closeByDateTxt}`)}</Typography>
              <Box className="form-row">
            <Box className="form-col col6">
            <Box className="form-control">          
             <FormControl
                className="select-outer"
                variant="outlined"
              >
                <InputLabel>
                {this.t(`${configJSON.closeByDateTxt}`)}
                </InputLabel>
                <Select
                  value={this.state.closeDate}
                  name="closeDate"
                  onChange={this.handleFiltersInputChange}
                  label= {this.t(`${configJSON.closeByDateTxt}`)}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  data-test-id="closeDate"
                > 
                 <MenuItem value="on_or_before">{this.t(`${configJSON.onOrBefore}`)}</MenuItem>
                 <MenuItem value="on_or_after">{this.t(`${configJSON.onOrAfter}`)}</MenuItem>
                </Select>
              </FormControl> 
              </Box>
              </Box>
              <Box className="form-col col6">
                <Box className="form-control">
                <CustomDatePicker
                  currentDate=""
                  onOpenPickNewDate={false}
                  t={this.t}
                  placeholder={this.t(`${configJSON.selectDateFilter}`)}
                  onChange={this.changeDateFilter}               
                  data-test-id="select-date"
                />                 
               </Box>
              </Box>
              </Box>                    
              <Typography className="filter-headding">{this.t(`${configJSON.contactTxt}`)}</Typography>
              <FormControl
                className="select-outer"
                variant="outlined"
              >
                <InputLabel>
                  {this.t(`${configJSON.selectContactTxt}`)}
                </InputLabel>
                <Select
                  value={this.state.contact}
                  name="contact"
                  onChange={this.handleFiltersInputChange}
                  label={this.t(`${configJSON.selectContactTxt}`)}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  data-test-id="contact"
                >
                  {uniqueContacts.map((contact: any) => {
                    return (
                      <MenuItem key={contact.id} value={contact.id}>
                         {contact.name}
                      </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>           
          
              <Typography className="filter-headding">{this.t(`${configJSON.dealOwnerNameTxt}`)}</Typography>
              <FormControl
                className="select-outer"
                variant="outlined"
              >
                <InputLabel>
                  {this.t(`${configJSON.dealOwnerNameTxt}`)}
                </InputLabel>
                <Select
                  value={this.state.dealOwner}
                  name="dealOwner"
                  onChange={this.handleFiltersInputChange}
                  label={this.t(`${configJSON.dealOwnerNameTxt}`)}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  data-test-id="dealOwner"
                >
                  {uniqueOwnerFilters.map((dealOwner: any) => {
                    return (
                      <MenuItem
                        key={dealOwner.value}
                        value={dealOwner.value}
                        >
                        {dealOwner.label}
                        </MenuItem>
                    );
                   })}
                </Select>
              </FormControl>            
            <Box className="view-more-dropdown">
                                <Box className="dropdown-menu-item">
                                  <Typography className="dropdown-menu-item-text">
                                    {this.t(`${configJSON.hasTasks}`)}
                                  </Typography>
                                  <Switch
                                    className="dropdown-menu-item-switch"
                                    checked={this.state.hasTasksDeals}
                                    onChange={this.handleChecked}
                                    name="hasTasksDeals"
                                    data-test-id="hasTaskDeals"
                                  />
                                </Box>
                                <Box className="dropdown-menu-item">
                                  <Typography className="dropdown-menu-item-text">
                                    {this.t(`${configJSON.hasAttachment}`)}
                                  </Typography>
                                  <Switch
                                    className="dropdown-menu-item-switch"
                                    checked={this.state.hasAttachment}
                                    onChange={this.handleChecked}
                                    name="hasAttachment"
                                  />
                                </Box>
                                <Box className="dropdown-menu-item">
                                  <Typography className="dropdown-menu-item-text">
                                    {this.t(
                                      `${configJSON.hasAssociatedProduct}`
                                    )}
                                  </Typography>
                                  <Switch
                                    className="dropdown-menu-item-switch"
                                    checked={this.state.hasAssociatedProduct}
                                    onChange={this.handleChecked}
                                    name="hasAssociatedProduct"
                                  />
                                </Box>
                 </Box>
            
              <Button className={`primary-btn2 ${classes.primaryButton}`} 
               data-test-id="applyFilters" onClick={this.applyFilters}>
                {this.t(`${configJSON.txtApplyFilters}`)}</Button>
        </Box>
      </Box>
    </Modal>
  )};
  openDetailsModal = () => {
    const {classes} = this.props;
    return (
      <>{this.state.modalOpen && <>{this.viewModal(classes)}</>  }</>
    )
    
  }
  render() {
    const { classes } = this.props;
    const toogleTopTaskSelected =
      this.state.selectedTasks.length > 0 ? "show-topbar" : "";
    const tasklistPopOverOpen = Boolean(this.state.tasklistPopoveAnchorEl)
    const tasklistPopoverId = tasklistPopOverOpen ? "simple-popover" : undefined
    let deleteTaskListMsg = this.t(`${configJSON.deleteTasklistPhotoshopTxt}`);
    deleteTaskListMsg += this.state.taskListDetails?.attributes.name ?? "";
    deleteTaskListMsg += this.t(`${configJSON.deleteTasklistTxt}`);
    const ProjectLeadDetails = this.state.currentProject?.attributes.project_lead;

    return (
      <>
        {this.state.isLoading && <WebLoader />}
        <Box className={classes.tasklistInnerRoot} >
          <Box className="tasklist-top">
            <Box className="top-left">
              <Box className="search-wrapper">
                <Input
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  onChange={(e) =>
                    this.setState({ searchTask: e.target.value })
                  }
                  type="search"                 
                  value={this.state.searchTask}
                  placeholder={this.t(`${configJSON.searchTypeTxt}`)}
                />
              </Box>
            </Box>
            <Box className="top-right TaskList-calender">
              <TaskListFilter
                selectAssigneeOptions={this.state.assigneeList}
                classes={classes}
                onSelectedOptions={this.saveSelectedOption}
                clearAction={this.handleClearSelection}
                selectedAssignee={this.state.selectedAssigneeVal}
                selectedPriority={this.state.selectedPriorityVal}
                selectedSortBy={this.state.selectedSortByVal}
                dueDateRangeFilter={this.dueDateRangeFilter}
                selectedDueDate={this.state.selectedDueDateVal}
                selectedDeadlineDate={this.state.selectedDeadlineDateVal}
                deadlineDateRangeFilter={this.deadlineDateRangeFilter}
                selectProjectOptions={this.state.projectList}
                selectedProject={this.state.selectedProjectVal}
                isOneProject={this.props.projectId && !this.props.taskListId}
                isTaskList={this.props.taskListId != undefined}
                selectedTaskList={this.state.selectedTaskListVal}
                selectTaskListOptions={this.state.allTaskList}
                getFilterdData={this.getTasks}
              />
            </Box>
          </Box>
          {this.props.taskListId && 
          <Box className="tasklist-top-details">
            <Box className="top-left">
              <Box className="task-title">
                <Box className="title">
                  {this.state.taskListDetails?.attributes.name}
                   <Box   className="edit-link" data-test-id="tasklistEdit" onClick={this.renameTaskListModalOpen}>
                    <EditOutlinedIcon />
                   </Box>
                </Box>
              </Box>
              <Box className="task-desc">
                <Box className="desc-left">
                  <Box component="span" className="name">{this.state.currentProject?.attributes.title}</Box>
                  <Box className="task-about">
                   {this.state.currentProject?.attributes.end_date
                        ? moment(this.state.currentProject?.attributes.end_date).format(
                            "ddd, MMM DD, YYYY"
                          )
                        : ""}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="top-right">
              <Box className="task-progress-wrapper">
                <Box className="prec-wrap">
                  <Box className="prec-value">25% {this.t(`${configJSON.completedTxt}`)}</Box>
                </Box>
                <Box className="progress-wrap">
                  <Box className="progress-inner" style={{ width: "25%" }}></Box>
                </Box>
              </Box>
              <Box className="top-right-links">
                <Box className="top-links">
                   <Box
                     
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(e) => this.taskTopDetailsMenuOpen(e)}
                    className="dropmenu-btn"
                    data-test-id="taskListviewMenuOpen"
                  >
                    <img src={dotsHorizontal} alt="dotsHorizontal" />
                   </Box>
                  <Menu
                    id="simple-menu"
                    anchorEl={this.state.taskTopDetailsAnchorEl}
                    keepMounted
                    open={Boolean(this.state.taskTopDetailsAnchorEl)}
                    onClose={this.taskTopDetailsMenuClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    className={classes.dropDropdown}
                  >
                    <MenuItem onClick={this.deleteTasklistHandler} data-test-id="deleteTaskList">{this.t(`${configJSON.txtDelete}`)}</MenuItem>
                    <MenuItem onClick={this.moveTasklistHandler} data-test-id="moveTaskList">{this.t(`${configJSON.moveTasklistTxt}`)}</MenuItem>
                    <MenuItem onClick={this.timeSheetModalShow} data-test-id="getTimesheet">{this.t(`${configJSON.getTimesheetTxt}`)}</MenuItem>
                  </Menu>
                </Box>
              </Box>
            </Box>
          </Box>
          }
          {(this.props.projectId && !this.props.taskListId ) && <Box className="project-detail-top">
          <ConfirmActionModal
            isOpen={this.state.showDeleteProject}
            handleClose={this.projectDeleteModalClose}
            modalConfirmAction={this.deleteProject}
            modalMessage={this.t(configJSON.confirmDeleteProject, { title: this.state.currentProject?.attributes.title })}
            confirmBtnTxt={this.t(`${configJSON.txtDelete}`)}
            modalHeading={this.t(`${configJSON.deleteProjectTxt}`)}
            cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
            data-testid="confirmDeleteProject"
          />
            <Box className="project-row">
              <Box className="project-left">
                <Box className="task-title">
                  <Box
                    className="title"
                  >
                    {this.state.currentProject?.attributes.title}
                     <Box   className="edit-link" onClick={this.editProjectModalShow} data-test-id="editProjectLink">
                      <img src={editGray} alt="editGray" />
                     </Box>
                    <FormControl className="select-control status-control open">
                      <Select
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: `${classes.dropdownStyle} modal-dropdown`,
                          },
                        }}
                        onChange={this.updateProjectStatus}
                        displayEmpty
                        value={this.state.projectStatusChange}
                        data-test-id="changeProjectStatusTop"
                      >
                        <MenuItem value={0} className="status-item">
                          <Box className="status-name">{this.t(`${configJSON.txtStatusOpen}`)}</Box>
                          <Box className="status blue"></Box>
                        </MenuItem>
                        <MenuItem value={1} className="status-item">
                          <Box className="status-name">{this.t(`${configJSON.onTrackTxt}`)}</Box>
                          <Box className="status green"></Box>
                        </MenuItem>
                        <MenuItem value={2} className="status-item">
                          <Box className="status-name">{this.t(`${configJSON.offTrackTxt}`)}</Box>
                          <Box className="status red"></Box>
                        </MenuItem>
                        <MenuItem value={3} className="status-item">
                          <Box className="status-name">{this.t(`${configJSON.txtStatusCompleted}`)}</Box>
                          <Box className="status yellow"></Box>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box className="task-desc">
                  <Box className="desc-left">
                    <Box component="span">
                    {this.state.currentProject?.attributes.category?.category}
                    </Box>
                    <Box className="task-about">
                      {this.state.currentProject?.attributes.end_date
                        ? moment(this.state.currentProject?.attributes.end_date).format(
                            "ddd, MMM DD, YYYY"
                          )
                        : ""}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="project-right">
                <Box className="task-progress-block border-hr">
                  <Box className="progress-info">
                    <Box className="prec-value">25%</Box>
                    <Box className="total-value">88:52:00 / 400:00:00</Box>
                  </Box>
                  <Box className="progress-wrap">
                    <Box
                      className="progress-inner"
                      style={{ width: "25%" }}
                    ></Box>
                  </Box>
                </Box>
                <Box className="heading-link-wrapper border-hr">
                  <Box className="heading-links">                  
                       <Box className="heading-link">
                       <Box
                         
                        className="icon"
                        data-test-id="duplicateProjectMain"
                        onClick={this.cloneProject}
                      >
                        <img src={copyGray} alt="copyGray" />
                       </Box>
                    </Box>
                    <Box className="heading-link">
                       <Box   className="icon" onClick={this.timeSheetModalShow}>
                        <img src={contentSave} alt="contentSave" />
                       </Box>
                    </Box>
                 
                    <Box className="heading-link">
                       <Box
                         
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={this.projectMenuOpen}
                        className="dropmenu-btn"
                        data-test-id="projectMenuOpen"
                      >
                        <img src={dotsHorizontal} alt="dotsHorizontal" />
                       </Box>
                      <Menu
                        id="simple-menu"
                        anchorEl={this.state.projectAnchor}
                        keepMounted
                        open={Boolean(this.state.projectAnchor)}
                        onClose={this.projectMenuClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        className={classes.dropDropdown}
                      >
                        <MenuItem onClick={this.tasklistPopoverOpen} data-test-id="createTasklist">
                        {this.t(`${configJSON.craeteTaskListTxt}`)}
                      </MenuItem>
                        <MenuItem
                          data-test-id="createTaskFromProject"
                          onClick={this.popoverHandleClick}
                        >
                         {this.t(`${configJSON.txtCreateTask}`)}
                      </MenuItem>
                        <MenuItem
                          onClick={this.deleteProjectHandler}
                          data-test-id="viewDeleteProject"
                        >
                          {this.t(`${configJSON.deleteProjectTxt}`)}
                      </MenuItem>
                      </Menu>
                    </Box>
                  </Box>

                </Box>
                <Box className="action-button">
                  <Button
                    className={`primary-btn ${classes.primaryButton}`}
                    title="More Details"
                    onClick={this.openProject}
                    data-test-id="projectMoreDetails"
                  >
                    {this.t(`${configJSON.moreDetailsTxt}`)}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box className="project-row">
              <Box className="project-left">
                <Box className="lead-label">{this.t(`${configJSON.projectLeadTxt}`)}</Box>
                {ProjectLeadDetails && ProjectLeadDetails.length > 0 && (
                  <Box className="lead-name">
                    {ProjectLeadDetails[0].first_name + " " + ProjectLeadDetails[0].last_name}
                  </Box>)}
              </Box>
              <Box className="project-right">
                <List className="project-total-list">
                  <ListItem>
                    <Box className="label">{this.t(`${configJSON.budgetTxt}`)}</Box>
                    <Box className="details">$3500 / <Box component="span">$6000</Box></Box>
                  </ListItem>
                  <ListItem>
                    <Box className="label">{this.t(`${configJSON.txtTasks}`)}</Box>
                    <Box className="details">88 / <Box component="span">400</Box></Box>
                  </ListItem>
                  <ListItem>
                    <Box className="label">{this.t(`${configJSON.highPriorityTxt}`)}</Box>
                    <Box className="details">1</Box>
                  </ListItem>
                  <ListItem>
                    <Box className="label">{this.t(`${configJSON.pastDueTxt}`)}</Box>
                    <Box className="details">2</Box>
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Box>
          }
          <Box className="tasklist-inner">{this.getTabData(classes)}</Box>
        </Box>

        <ProjectView 
            isOpen={this.state.projectViewOpen || this.state.showTimesheet}
            projectClose={this.closeProject} 
            projectId={this.props.projectId?? 0}
            tasklistPopoverOpen={this.createtasklistPopoverOpen}
            taskPopoverOpen={this.createtaskPopoverOpen}
            createToastNotification={this.createToastNotification}
            fetchAllProjects={this.getProjects}
            popoverHandleClose={this.editProjectModalClose}
            projectList={this.state.projectList}
            showCreateProject={false}
            editProjectOpen={this.state.showEditProject}
            deleteCallback={this.deleteCallback}
            updateData={this.props.updateData}
            showTimeSheet={this.state.showTimesheet}
            closeTimeSheet={this.timeSheetModalClose}
          />
        {this.openDetailsModal()}
        
        
        

        {this.changeAssigneeModal(classes)}

        {this.changeStatusModal(classes)}

        {/* task selected top start */}
        <AppBar
          position="static"
          className={`${classes.TopTaskSelected} ${toogleTopTaskSelected}`}
        >
          <Box className="taskselect-left">
           <Box
              onClick={this.clearSelectedTask}
              data-test-id="clearSelectedTask"
               
              className="auth-back show-sm"
            >
              <i>
                <svg
                  className="MuiSvgIcon-root"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                </svg>
              </i>
             </Box>
            <FormControlLabel
              control={
                <Checkbox
                  className="checklist-check hide-sm"
                  icon={<img src={checkBoxEmpty} alt="checkBoxEmpty" />}
                  checkedIcon={
                    <img src={checkBoxFilled} alt="checkBoxFilled" />
                  }
                  tabIndex={-1}
                  disableRipple
                  checked                
                />
              }
              label={
                <Box component="span">
                  {this.state.selectedTasks.length} <Box className="hide-sm" component="span">{this.t(`${configJSON.tasksSelectedTxt}`)}</Box>
                </Box>
              }
              className="tasklist-count"
            />
          </Box>
          <Box className="taskselect-middle">
            <Box className="heading-links">
              <Box className="heading-link">
                <Box  data-test-id="assigneeHandler" className="icon" onClick={(e:any) => this.assigneeHandler(e, this.state.selectedTasks[this.state.selectedTasks.length - 1])}>
                  <img src={accountPlusWhite} alt="accountPlusWhite" />
                </Box>
              </Box>
              <Box className="heading-link">
                 <Box
                   
                  className="icon"
                  onClick={this.changeStatusHandler}
                  data-test-id="changeStatusSelected"
                >
                  <img src={checkboxMarkedWhite} alt="checkboxMarkedWhite" />
                 </Box>
              </Box>
              <Box className="heading-link">
                <CustomDatePicker
                  currentDate=""
                  onChange={this.changeDueDates}
                  t={this.t}
                  onOpenPickNewDate={false}
                  render={(value: any, openCalendar: any) => (
                    <Box  onClick={openCalendar} className="icon">
                        <img src={calendarWhite} alt="calendarWhite" />
                     </Box>
                    )
                  }
                  data-test-id="topCalendar"
                />
              </Box>
              <Box className="heading-link hide-sm">
                 <Box
                   
                  className="icon"
                  aria-controls="changePriorityTopEl"
                  aria-haspopup="true"
                  onClick={this.changePriorityTopOpen}
                  data-test-id="prioritySelected"
                >
                  <img src={priorityWhite} alt="priorityWhite" />
                 </Box>
                <Menu
                  id="changePriorityTopEl"
                  anchorEl={this.state.changePriorityTopEl}
                  keepMounted
                  open={Boolean(this.state.changePriorityTopEl)}
                  onClose={() => this.changePriorityTopClose()}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  className={`changepriority-dropdown ${classes.dropDropdown}`}
                >
                  <MenuItem onClick={() => this.changePriorityTopClose(2)}>
                    <Box className="priority-name">{this.t(`${configJSON.txtEmergency}`)}</Box>
                    <Box className="priority-icon">!!!</Box>
                  </MenuItem>
                  <MenuItem
                    onClick={() => this.changePriorityTopClose(1)}
                    data-test-id="prioritySelectedCritical"
                  >
                    <Box className="priority-name">{this.t(`${configJSON.txtCritical}`)}</Box>
                    <Box className="priority-icon">!!</Box>
                  </MenuItem>
                  <MenuItem onClick={() => this.changePriorityTopClose(0)}>
                    <Box className="priority-name">{this.t(`${configJSON.txtNone}`)}</Box>
                  </MenuItem>
                </Menu>
              </Box>
              <Box className="heading-link hide-sm">
                 <Box
                   
                  className="icon"
                  onClick={() => this.deleteTask()}
                  data-test-id="deleteSelected"
                >
                  <img src={deleteWhite} alt="deleteWhite" />
                 </Box>
              </Box>
              <Box className="heading-link">
                 <Box
                   
                  className="icon"
                  style={{width:"20px",height:"20px"}}
                  onClick={(e:any)=>this.moveTasklistProjectHandler(e,this.state.selectedTasks)}
                  data-test-id="MoveTaskIcon"
                >
                  <img src={MoveTaskIcon} alt="MoveTaskIcon" />
                 </Box>
              </Box>
              <Box className="heading-link show-sm">
                 <Box
                   
                  className="icon"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(e) => this.handleMenuOpen(e)}
                  data-test-id="prioritySelectedsm"
                >
                  <img src={dotVerticleWhite} alt="dotVerticleWhite" />
                 </Box>
              </Box>
            </Box>
          </Box>
          <Box className="taskselect-right hide-sm">
             <Box
               
              className="tasklist-close"
              onClick={this.clearSelectedTask}
              data-test-id="clearSelectedTask"
            >
              <CloseIcon />
              {this.t(`${configJSON.closeTxt}`)}
             </Box>
          </Box>
        </AppBar>
        {/* task selected top end */}

        {/* delete task start */}
          <ConfirmActionModal
            isOpen={this.state.isDeletingTask}
            handleClose={this.handleClose}
            modalConfirmAction={this.deleteTaskFromList}
            modalMessage={this.state.selectedTasks.length < 1 ? this.t(configJSON.confirmDeleteSingleTask) : this.t(configJSON.confirmDeleteTask, {
              taskCount: this.state.editTask != null || this.state.deleteTaskId !== 0
                ? 1
                : this.state.selectedTasks.length
            })}
            confirmBtnTxt={this.t(`${configJSON.txtDelete}`)}
            cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
            modalHeading={this.t(`${configJSON.txtDeleteTask}`)}
            data-testid="confirmDeleteTask"
          />
        {/* delete task end */}

        {/* task create block start */}
        {this.createTaskBlock(classes)}
        {/* task create block end */}

        {/* creating tasklist modal start */}  
        <Popover
          onClose={this.tasklistPopoverHandleClose}
          open={tasklistPopOverOpen}
          anchorEl={this.state.tasklistPopoveAnchorEl}
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          classes={{ paper: `${classes.createTaskBox}` }}
          transformOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          id={tasklistPopoverId}
          >
          <Box
            className="auth-back show-sm"
            onClick={this.tasklistPopoverHandleClose}
            >
            <i>
              <svg
                viewBox="0 0 24 24"
                aria-hidden="true"
                focusable="false"
                className="MuiSvgIcon-root"
              >
                <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
              </svg>
            </i>
          </Box> 
          <Box className="task-heading">
            <Box className="heading-left">
              <Typography component="h2" className="modal-title" >
                {this.t(`${configJSON.craeteTaskListTxt}`)}
              </Typography>
              <Box component="p" className="sub-txt">
                {this.t(`${configJSON.createTasklistSubTxt}`)}
              </Box>
            </Box>
            <Box className="heading-right">
               <Box
                 
                className="heading-icon hide-sm"
                onClick={this.tasklistPopoverHandleClose}
                data-test-id="closeTaskListPopover"
              >
                <img src={closeGray} alt="closeGray" />
               </Box>
            </Box>
          </Box>
          <Box className="task-description">
            <Box className="task-form" component="form">
              <Box className="form-info-wrapper">
                <Box className="form-row">
                  <Box className="form-col">
                    <FormControl className="select-outer" variant="outlined">
                      <Select
                        IconComponent={ExpandMoreIcon}
                        labelId="project"
                        id="project"
                        value={this.state.taskListObject.project}
                        onChange={this.handleProjectChange}
                        name="project"
                        label={this.t(`${configJSON.projectTxt}`)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: `${classes.dropdownStyle} modal-dropdown`,
                          },
                        }}
                        data-test-id="modalChangeStatus"
                      >
                        {this.state.projectList.map((proj) => {
                          return (
                            <MenuItem value={proj.id} key={proj.id}>
                              {proj.attributes.title}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box className="form-row">
                  <Box className="form-col">
                    <CustomInputWeb
                      type="text"
                      label={this.t(`${configJSON.tasklistNameTxt}`)}
                      value={this.state.taskListObject.name}
                      name="addNote"
                      onChange={this.handleTaskListNameChange}
                      errors={this.state.error.tasklistName}
                      isRequired={true}
                      data-test-id="tasklistCreateName"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="createtask-bottom">
            <Box className="button-wrapper full-btn">
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                onClick={this.createTaskList}
                data-test-id="saveTaskListBtn"
                title="Create TaskList"
              >
                {this.t(`${configJSON.craeteTaskListTxt}`)}
              </Button>
            </Box>
          </Box>
        </Popover>
        {/* creating tasklist modal end */}


        {this.renameTasklistModal(classes)}

          <ConfirmActionModal
            isOpen={this.state.isDeletingTasklist}
            handleClose={this.deleteTasklistModalClose}
            modalConfirmAction={this.deleteTasklist}
            modalMessage={deleteTaskListMsg}
            confirmBtnTxt={this.t(`${configJSON.txtDelete}`)}
            modalHeading={this.t(`${configJSON.deleteTaskListTxt}`)}
            cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
            data-testid="confirmDeleteTaskList"
          />

        {this.moveTasklistModal(classes)}
        {this.moveTasklistProjectModal(classes)}
        {displayDataErrorMessage()}
        {displayApiErrorMessage()}
        {displaySelectedMemberMessage()}
        {displaySelectedMemberErrorMessage()}
      </>
    );
  }
}

const displayDataErrorMessage = () => {
  return(
    <Box style={{display: 'none'}}>
      <Typography>No data found.</Typography>
    </Box>
  );
}

const displayApiErrorMessage = () => {
  return(
    <Box style={{display: 'none'}}>
      <Typography>Api response failed.</Typography>
    </Box>
  );
}

const displaySelectedMemberMessage = () => {
  return(
    <Box style={{display: 'none'}}>
      <Typography>No data found.</Typography>
    </Box>
  );
}

const displaySelectedMemberErrorMessage = () => {
  return(
    <Box style={{display: 'none'}}>
      <Typography>Api response failed.</Typography>
    </Box>
  );
}
export default withStyles(customStyles, { withTheme: true })(TaskList);
// Customizable Area End