// Customizable Area Start
import React from "react";
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";

export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    dealsMarketingRoot: {
      display: "flex",
      flexWrap: "wrap",
      minHeight: "calc(100vh - 88px)",
      flex: "1",
      "& .mobile-heading-deals": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          paddingBottom: "0",
          marginBottom: "48px",
          borderBottom: "0",
          padding: "24px 24px 0",
        },
        "& .marketing-menu-deals": {
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .heading": {
          [theme.breakpoints.down("xs")]: {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
            margin: "0 20px",
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            letterSpacing: "0",
          },
        },
        "& .back-btn-wrap-deals": {
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
      },
      "& .marketing-content-deals": {
        "& .marketing-top-deals": {
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center",
          padding: "8px 32px",
          borderBottom: "1px solid #E8ECF2",
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
          "& .top-left": {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            [theme.breakpoints.down(1366)]: {
              flex: "0 0 100%",
              maxWidth: "100%",
              marginBottom:"10px",
            },
            "& .email-count": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 500,
              letterSpacing: "0.1px",
              marginRight: "18px",
              paddingRight: "18px",
              borderRight: "1px solid #E8ECF2",
            },
            "& .search-wrapper": {
              [theme.breakpoints.down("xs")]: {
                width: "100%",
              },

              "& .MuiInputAdornment-positionStart": {
                marginRight: "12px",
              },
              "& .MuiInput-root": {
                maxWidth: "100%",
              },

              "& .MuiInput-underline": {
                "&:before, &:after": {
                  display: "none",
                },
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "0.1px",
                color: "#2B2B2B",
                fontWeight: "500",
                fontFamily: "Expletus Sans",
                "&::-webkit-input-placeholder": {
                  opacity: "0.37",
                  color: "#2B2B2B",
                },
                "&:-ms-input-placeholder": {
                  opacity: "0.37",
                  color: "#2B2B2B",
                },
                "&::placeholder": {
                  opacity: "0.37",
                  color: "#2B2B2B",
                },
              },
              "& .MuiSvgIcon-root": {
                color: "#2b2b2b",
                fontSize: "20px",
                opacity: "0.67",
              },
            },
          },
          "& .top-right": {
            marginLeft: "15px",
            [theme.breakpoints.down(1366)]: {
              flex: "0 0 100%",
              maxWidth: "100%",
              marginLeft:"0",
            },
            "& .filter-list": {
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              [theme.breakpoints.down(1199)]: {
                margin:"0 -6px -12px",
              },
              "& .filter-more": {
                "& .menu-dropdown": {
                  display: "flex",
                  flexDirection: "row",
                  backgroudColor: "transparent",
                  justifyContent: "center",
                  marginRight: "22px",     
                  alignItems: "center",      
                  "& .more-txt": {
                    color: "rgba(43, 43, 43, 0.87)",
                    fontFamily: "Expletus Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                    cursor:"pointer",
                    whiteSpace: "nowrap",
                    opacity: 0.671,
                  },
                  "& .more-columns": {
                    color: "#737070",
                    fontSize: "14px",
                    fontFamily: "Expletus Sans",
                    fontWeight: "500",
                    fontStyle: "normal",
                    marginRight:"3px",
                    whiteSpace: "nowrap",
                    lineHeight: "normal",
                    cursor:"pointer",
                  },
                },
                "& .MuiPopover-root": {
                  backgroundColor: "green",
                  "& .MuiPopover-paper": {
                    "& .MuiBox-root": {
                      padding: "16px 24px 8px",
                      "& .MuiBox-root": {
                        display: "flex",
                        flexDirection: "row",
                      },
                    },
                  },
                },
              },
              "& .MuiTablePagination-toolbar":{
                paddingLeft : "2px",
                "& .MuiTablePagination-actions":{
                  marginLeft: "0",
                  "& button":{
                    paddingLeft: "0",
                  }
                },
              },
              "& .filter-control": {
                marginRight: "24px",
                color: "#2B2B2B",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                display: "flex",
                alignItems: "center",
                opacity: "0.67",
                "& .icon": {
                  marginRight: "6px",
                },
              },
              "& .switch-wrapper": {
                "& .MuiFormControlLabel-root": {
                  marginRight: "0",
                },
              },
              "& .border-hl": {
                marginLeft: "24px",
                paddingLeft: "24px",
                borderLeft: "1px solid #E8ECF2",
              },
              "& .select-control-deals": {
                marginRight: "3px",
                [theme.breakpoints.down(1440)]: {
                  marginRight:"14px",
                },
                [theme.breakpoints.down(1366)]: {
                  marginRight:"25px",
                },
                [theme.breakpoints.down(1199)]: {
                  marginRight:"0",
                  flex:"0 0 25%",
                  maxWidth:"25%",
                  padding:"0 6px 12px"
                },
                "& .MuiInputBase-root":{
                  [theme.breakpoints.down(1199)]: {
                    width:"max-content",
                  }
                },
                "&:last-child": {
                  marginRight: "0",
                },
              },
            },
          },
          "& .select-control-deals": {
            "&.multi-select": {
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                position: "absolute",
                fontSize: "15px",
                right: "18px",
                top: "3px",
                zIndex: 1,
                color: "#94A3B8",
              },
            },
            "& .MuiSelect-select": {
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.1px",
              color: "rgba(43, 43, 43, 0.87)",
              opacity: "0.671",
              fontWeight: "500",
              fontFamily: "Expletus Sans",
              paddingBottom: "0",
              paddingTop: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                width: "50px",
                height: "50px",
                background: "red",
              },
              "& .MuiBox-root": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                [theme.breakpoints.down(1199)]: {
                  justifyContent: "flex-start",
                },
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
            "&.sortby-control": {
              "& .MuiSelect-select": {
                paddingRight: "0",
                paddingLeft:"10px",
                borderLeft:"1px solid #E8ECF2",
              },
              "& .select-icon": {
                top: "3px",
              },
            },
            "& .select-icon": {
              position: "relative",
              marginRight: "6px",
            },
          },
          
        },
        "& .empty-box": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          paddingTop: '200px',

          "& .img-contact-book": {
            width: "300px",
            height: "244px",
            objectFit: "contain",
          },
          "& .box-empty-right": {
            margin: "0 0 0 127px",
            "& .txt-no-contact-title": {
              color: "var(--task-completed-color, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "400",
              linHeight: "normal",
              letterSpacing: "0.25px",
            },
            "& .txt-no-contact-sub-title": {
              color: "var(--task-completed-color, #94A3B8)",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              linHeight: "20px",
              letterSpacing: "0.25px",
              margin: "12px 0",
            },
            "& .MuiButton-root": {
              borderRadius: "6px",
              background: "var(--brand-color-primary-100, #4BA3B7)",
              color: "#ffffff",
              textTransform: "none",
              fontFamily: "Expletus Sans",
              width: "136px",
              height: "48px",
              margin: "48px 0px 0px",
            },
          },
        },
      },
    },
    switchWrapperDeals: {
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& .MuiSwitch-root": {
        [theme.breakpoints.down("xs")]: {
          width: "33px",
          height: "20px",
          padding: "5px",
        },
        "& .MuiIconButton-root": {
          [theme.breakpoints.down("xs")]: {
            padding: "6px",
          },
        },
      },
      "& .MuiFormControlLabel-root": {
        opacity: "0.671",
        fontSize: "14px",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          width: "100%",
          justifyContent: "space-between",
          opacity: "1",
          color: "#2B2B2B",
        },
        lineHeight: "20px",
        letterSpacing: "0px",
        color: "#000000",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        "& .MuiFormControlLabel-label": {
          font: "inherit",
        },
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },

      "& .MuiSwitch-track": {
        backgroundColor: "#d3d9e1",
        opacity: 1,
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "#94A3B8",
        },
      },

      "& .MuiSwitch-switchBase": {
        color: "#fafafa",
        [theme.breakpoints.down("xs")]: {
          color: "#ffffff",
        },
        "&.Mui-checked": {
          color: "#3691a6",
          [theme.breakpoints.down("xs")]: {
            transform: "translateX(12px)",
          },
          "&+ .MuiSwitch-track": {
            backgroundColor: "#3691a6",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.2))",
        [theme.breakpoints.down("xs")]: {
          width: "8px",
          height: "8px",
        },
      },
    },
    dropdownDeals: {
      "&.changepriority-dropdown": {
        "& .MuiMenu-paper": {
          marginTop: "0",
        },
      },
      "&.tablemenu-dropdown": {
        "& .MuiMenu-paper": {
          minWidth: "291px",
        },
        "& .table-menu-item": {
          "& .name": {
            marginRight: "10px",
          },
          "& .icon": {
            marginLeft: "auto",
          }
        }
      },
      "& .MuiMenu-paper": {
        minWidth: "164px",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        marginTop: "12px",

        "& .MuiList-padding": {
          paddingTop: "8px",
          paddingBottom: "8px",
        },
        "& .table-menu-item": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "& .icon": {
            maxWidth: "24px",
            width: "24px",
            marginLeft: "10px",
            textAlign: "right",
          },
        },
        "& .MuiMenuItem-root": {
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "0",
          color: "#2b2b2b",
          padding: "16px 24px",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          display: "flex",
          justifyContent: "flex-start",
          // borderBottom: "1px solid #ECF1F4",
          "& .priority-name": {
            marginRight: "10px",
          },
          "& .priority-icon": {
            marginLeft: "auto",
          },
        },
      },
    },
    dropdownStyleDeals: {
      marginTop: "21px",
      maxWidth: "190px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "26px",
      },
      "&.modal-dropdown": {
        marginTop: "0",
      },
      "& .status-item": {
        display: "flex",
        "& .status-name": {
          marginRight: "10px",
          textTransform: "capitalize",
        },
        "& .status": {
          width: "12px",
          height: "12px",
          background: "#94A3B8",
          borderRadius: "100%",
          //marginLeft: "auto",
          "&.open": {
            background: "#4BA3B7",
          },
          "&.cancelled": {
            background: "#F7E223",
          },
          "&.sky-blue": {
            background: "#12AEF1",
          },
          "&.orange": {
            background: "#F6A318",
          },
          "&.yellow": {
            background: "#F7E223",
          },
          "&.green": {
            background: "#00BA88",
          },
          "&.black": {
            background: "#2B2B2B",
          },
        },
      },
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        "&.assignee-multiSelect": {
          maxWidth: "400px",
        },
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "24px",
            paddingRight: "24px",
            position: "static",
            "&.multiselect-subheader": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              lineHeight: "19px",
              borderBottom: "1px solid #ECF1F4",
              paddingTop: "15px",
              paddingBottom: "15px",
              marginBottom: "5px",
              "& .tasklist-link": {
                marginLeft: "10px",
                color: "#C7263E",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                lineHeight: "19px",
                whiteSpace: "nowrap",
                textDecoration: "none",
              },
            },
            "& .form-control": {
              marginBottom: "20px",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          color: "#2B2B2B",
          padding: "14px 24px",
          "& .priority-name": {
            margin: "0",
            "& .MuiTypography-root": {
              display: "flex",
            },
            "& .priority-status": {
              marginLeft: "auto",
            },
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
            "& .multiselect-squareCheck": {
              "& .normal-img": {
                display: "none",
              },
              "& .selected-img": {
                display: "block",
              },
            },
            "& .assignee-detail": {
              "& .assignee-right": {
                "& .assinee-selected": {
                  "& .normal-img": {
                    display: "none",
                  },
                  "& .selected-img": {
                    display: "block",
                  },
                },
              },
            },
          },
          "& .multiselect-check": {
            display: "none",
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
          },
          "& .multiselect-squareCheck": {
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
            "& .selected-img": {
              display: "none",
            },
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        whiteSpace: "normal",
        "& .select-icon": {
          marginRight: "6px",
        },
        "& .MuiTypography-root": {
          font: "inherit",
          letterSpacing: "0",
        },
      },
      "& .MuiList-padding": {
        paddingTop: "4px",
        paddingBottom: "8px",
      },
      "& .assignee-detail": {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        "& .assignee-left": {
          display: "flex",
          alignItems: "center",
          "& .assignee-info": {
            marginLeft: "10px",
          },
          "& .assignee-profile": {
            width: "39px",
            height: "39px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ECF1F4",
            borderRadius: "100%",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Roboto",
            fontSize: "15.485px",
            fontWeight: 600,
            lineHeight: "21.679px",
            letterSpacing: "-0.395px",
          },
          "& .assignee-name": {
            fontSize: "14px",
            lineHeight: "19px",
            color: "#2b2b2b",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
          },
          "& .assignee-email": {
            fontSize: "12px",
            lineHeight: "16px",
            color: "#94A3B8",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
          },
        },
        "& .assignee-right": {
          marginLeft: "10px",
          "& .assinee-selected": {
            width: "20px",
            height: "20px",
            "& .normal-img": {
              display: "none",
            },
            "& .selected-img": {
              display: "block",
            },
          },
          "& .assinee-unselected": {
            width: "20px",
            height: "20px",
            "& .normal-img": {
              display: "block",
            },
            "& .selected-img": {
              display: "none",
            },
          },
        },
      },
    },
    table: {
      "& .select-modal-field": {
        "& .form-control": {
          marginBottom: "0",
          "& .MuiOutlinedInput-input": {
            padding: "0",
            width: "0",
          },
          "& .MuiInputAdornment-positionEnd": {
            marginLeft: "0",
        },
          "& .MuiOutlinedInput-root": {
            padding: "0",
            borderRadius: "0",
            display: "flex",
            justifyContent: "flex-start",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
              padding: "0",
            }
          }
        },      
        "& .MuiInputAdornment-positionEnd": {
          "& .MuiSvgIcon-root": {
            fontSize: "20px",
            color: "#94A3B8",
          },
        },      
      },
      "& .status-item": {
        display: "flex",
        alignItems: "center",
        "& .status-name": {
          marginRight: "10px",
          textTransform: "capitalize",
        },
        "& .status": {
          width: "12px",
          height: "12px",
          background: "#94A3B8",
          borderRadius: "100%",
          marginLeft: "auto",
          "&.open": {
            background: "#4BA3B7",
          },
          "&.cancelled": {
            background: "#F7E223",
          },
          "&.sky-blue": {
            background: "#12AEF1",
          },
          "&.orange": {
            background: "#F6A318",
          },
          "&.yellow": {
            background: "#F7E223",
          },
          "&.green": {
            background: "#00BA88",
          },
          "&.black": {
            background: "#2B2B2B",
          },
        },
      },
      "& .table-wrap-deals": {
        "& .MuiTableRow-root": {
          "&.Mui-selected": {
            background: "#fafcfe",
            "&:hover": {
              background: "#fafcfe",
            },
            "& .MuiTableCell-root": {
              "& .profile-name": {
                backgroundColor: "#eaeff2",
              },
            },
          },
          "&.disable-member-row": {
            "&:hover": {
              background: "#ffffff",
            },
          },
        },
        "& .MuiTableCell-head": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          lineHeight: "20px",
          color: "rgba(43, 43, 43, 0.369)",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          padding: "19px 16px",
          borderBottom: "1px solid #E8ECF2",
          [theme.breakpoints.down("md")]: {
            padding: "19px 10px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "17px 10px",
          },
          "&.MuiTableCell-paddingNone": {
            padding: "0",
          },
          "&.MuiTableCell-paddingCheckbox": {
            padding: "0 40px 0 18px",
          },
          "&.name-cell": {
            paddingLeft: "29px",
            cursor:"pointer",
          },
        },
        "& .MuiTableCell-body": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          lineHeight: "20px",
          color: "#2b2b2b",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          padding: "11px 16px",
          borderBottom: "1px solid #E8ECF2",
          [theme.breakpoints.down("md")]: {
            padding: "11px 10px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "10px",
          },
          "&.MuiTableCell-paddingNone": {
            padding: "0",
          },
          "&.MuiTableCell-paddingCheckbox": {
            padding: "0 40px 0 18px",
          },
          "&.name-cell": {
            paddingLeft: "29px",
            cursor: "pointer",
          },
          "& .MuiLink-root": {
            color: "inherit",
          },
          "&.action-link": {
            whiteSpace: "noWrap",
            "& .table-link": {
              marginLeft: "0",
              whiteSpace: "noWrap",
            },
          },
          "& .show-me-link": {
            marginLeft: "5px",
            background: "#4ba3b7",
            borderRadius: "6px",
            color: "#FFFFFF",
            textAlign: "center",
            fontFamily: "Roboto",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.0075em",
            padding: "2px 8px",
            textTransform: "capitalize",
          },
          "& .attachment-link": {
            font: "inherit",
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            alignItems: "center",
            whiteSpace: "noWrap",
            "& .attach-icon-deals": {
              marginRight: "12px",
              "& img":{
                width:"7px",
                height:"15px",
              },
            },
          },
          "& .table-menu": {
            display: "inline-block",
            "& .table-link": {
              padding: "0",
              textTransform: "none",
              font: "inherit",
              "& .MuiButton-endIcon": {
                marginLeft: "2px",
              },
            },
          },
          "& .table-link": {
            color: "#2b2b2b",
            textDecoration: "none",
            marginLeft: "10px",
            "&:hover": {
              textDecoration: "underline",
            },
          },
          "& .name-col": {
            display: "flex",
            alignItems: "center",
            "& .table-link": {
              marginLeft: "0",
            },
          },
          "& .desc": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": "1",
            "-webkit-box-orient": "vertical",
          },
          "& .profile-name": {
            width: "48px",
            height: "48px",
            borderRadius: "24px",
            backgroundColor: "#fafcfe",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textTransform: "uppercase",
            fontWeight: "700",
            marginRight: "12px",
          },
          "& .select-control-deals": {
            "& .MuiSelect-select": {
              fontSize: "14px",
              letterSpacing: "0.1px",
              color: "#2b2b2b",
              fontWeight: "500",
              fontFamily: "Expletus Sans",
              paddingBottom: "0",
              paddingTop: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
        },
        "& .action-link": {
          width: "14.6%",
          [theme.breakpoints.down("md")]: {
            width: "21.6%",
          },
          "&> a": {
            marginRight: "25px",
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
      },
      "&.direct-emails-sent": {
        minWidth: "1553px",
        "& .MuiTableCell-head": {
          "&.name-cell": {
            width: "18.5%",
            cursor:"pointer",
          },
          "&.title-cell": {
            width: "13.8%",
          },
          "&.desc-cell": {
            width: "25.5%",
          },
          "&.date-cell": {
            width: "14%",
          },
          "&.attachment-cell": {
            width: "20.5%",
          },
          "&.action-cell": {
            width: "7.7%",
          },
        },
      },
      "& .MuiTableRow-root": {
        "&.Mui-selected": {
          background: "#fafcfe",
          "&:hover": {
            background: "#fafcfe",
          },
          "& .MuiTableCell-root": {
            "& .profile-name": {
              backgroundColor: "#eaeff2",
            },
          },
        },
        "&.disable-member-row": {
          "&:hover": {
            background: "#ffffff",
          },
        },
      },
      "& .MuiTableCell-head": {
        fontSize: "14px",
        letterSpacing: "0.1px",
        lineHeight: "20px",
        color: "rgba(43, 43, 43, 0.369)",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        padding: "19px 16px",
        borderBottom: "1px solid #E8ECF2",
        [theme.breakpoints.down("md")]: {
          padding: "19px 10px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "17px 10px",
        },
        "&.MuiTableCell-paddingNone": {
          padding: "0",
        },
        "&.MuiTableCell-paddingCheckbox": {
          padding: "0 40px 0 18px",
        },
        "&.name-cell": {
          paddingLeft: "29px",
          cursor:"pointer",
        },
      },
      "& .MuiTableCell-body": {
        fontSize: "14px",
        letterSpacing: "0.1px",
        lineHeight: "20px",
        color: "#2b2b2b",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        padding: "11px 16px",
        borderBottom: "1px solid #E8ECF2",
        [theme.breakpoints.down("md")]: {
          padding: "11px 10px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "10px",
        },
        "&.MuiTableCell-paddingNone": {
          padding: "0",
        },
        "&.MuiTableCell-paddingCheckbox": {
          padding: "0 40px 0 18px",
        },
        "&.name-cell": {
          paddingLeft: "29px",
          cursor:"pointer",
        },
        "& .MuiLink-root": {
          color: "inherit",
        },
        "&.action-link": {
          whiteSpace: "noWrap",
          "& .table-link": {
            marginLeft: "0",
            whiteSpace: "noWrap",
          },
        },
        "& .show-me-link": {
          marginLeft: "5px",
          background: "#4ba3b7",
          borderRadius: "6px",
          color: "#FFFFFF",
          textAlign: "center",
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.0075em",
          padding: "2px 8px",
          textTransform: "capitalize",
        },
        "& .attachment-link": {
          font: "inherit",
          textDecoration: "none",
          color: "inherit",
          display: "inline-block",
          alignItems: "center",
          whiteSpace: "noWrap",
          "& .attach-icon-deals": {
            marginRight: "12px",
            position:"relative",
            top:"3px",
            "& img":{
              width:"7px",
              height:"15px",
            },
          },
        },
        "& .table-menu": {
          display: "inline-block",
          "& .table-link": {
            padding: "0",
            textTransform: "none",
            font: "inherit",
            "& .MuiButton-endIcon": {
              marginLeft: "2px",
            },
          },
        },
        "& .table-link": {
          color: "#2b2b2b",
          textDecoration: "none",
          marginLeft: "10px",
          "&:hover": {
            textDecoration: "underline",
          },
        },
        "& .name-col": {
          display: "flex",
          alignItems: "center",
          "& .table-link": {
            marginLeft: "0",
          },
        },
        "& .desc": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          "-webkit-line-clamp": "1",
          "-webkit-box-orient": "vertical",
        },
        "& .profile-name": {
          width: "48px",
          height: "48px",
          borderRadius: "24px",
          backgroundColor: "#fafcfe",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textTransform: "uppercase",
          fontWeight: "700",
          marginRight: "12px",
        },
        "& .select-control-deals": {
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0.1px",
            color: "#2b2b2b",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            paddingBottom: "0",
            paddingTop: "0",
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiInput-underline:before": {
            display: "none",
          },
          "& .MuiInput-underline:after": {
            display: "none",
          },
        },
      },
      "& .action-link": {
        width: "14.6%",
        [theme.breakpoints.down("md")]: {
          width: "21.6%",
        },
        "&> a": {
          marginRight: "25px",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "6px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#f2f8fc",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      maxWidth: "100%",
      minWidth: "170px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    createTaskWrapper: {
      "& .task-actions": {
        position: "fixed",
        right: "32px",
        bottom: "32px",
        background: "#ffffff",
        [theme.breakpoints.down("sm")]: {
          bottom: "60px",
        },
        "& .secondary-btn": {
          boxShadow: "0px 0px 18px 2px rgba(0, 0, 0, 0.10)",
          marginRight: "16px",
          minWidth: "auto",
          color: "rgba(43, 43, 43, 0.87)",
          "&:hover": {
            border: "1px solid rgba(43, 43, 43, 0.87)",
          },
          "& .draft-name": {
            display: "flex",
            alignItems: "center",
          },
          "& .draft-img": {
            marginRight: "6px",
          },
          "& .close": {
            margin: "0 0 0 5px",
            cursor: "pointer",
          },
        },
      },
      "& .primary-btn": {
        boxShadow: "0px 0px 24px 0px rgba(5, 111, 135, 0.47)",
        minWidth: "136px",
      },
    },
    createDealsBox: {
      borderRadius: "12px",
      background: "#FFF",
      boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
      padding: "0",
      overflow: "hidden",
      maxWidth: "591px",
      width: "100%",
      marginLeft: "auto",
      maxHeight: "unset",
      display: "inline-block",
      top: "auto !important",
      bottom: "95px",
      right: "32px",
      position: "fixed",
      [theme.breakpoints.down("sm")]: {
        bottom: "65px",
      },
      [theme.breakpoints.down("xs")]: {
        top: "0 !important",
        bottom: "0",
        left: "0 !important",
        right: "0",
        maxWidth: "100%",
        borderRadius: "0",
        boxShadow: "none",
      },
      "& .deals-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "40px 40px 14px",
        marginBottom: "0",
        [theme.breakpoints.down("xs")]: {
          padding: "14px 24px 17px",
        },
        "& .modal-title": {
          color: "#2B2B2B",
          fontSize: "18px",
          fontWeight: 400,
          lineHeight: "24px",
          fontFamily: "Expletus Sans",
          letterSpacing: "0",
          marginBottom: "5px",
        },
        "& .sub-txt": {
          fontSize: "12px",
          lineHeight: "16px",
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontWeight: 400,
          letterSpacing: "0.09px",
        },
        "& .heading-right": {
          display: "flex",
          marginLeft: "10px",
          "& .heading-icon": {
            marginRight: "24px",
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
      },
      "& .task-description": {
        padding: "10px 40px",
        overflowY: "auto",
        height: "calc(100vh - 400px)",
        [theme.breakpoints.down("sm")]: {
          height: "calc(100vh - 355px)",
        },
        [theme.breakpoints.down("xs")]: {
          height: "calc(100vh - 193px)",
          padding: "10px 24px",
        },
        "& .task-form":{
          "& .MuiFormControl-root":{
            "& .MuiFormLabel-root.MuiInputLabel-shrink":{
              color:"#94a3b8",
              fontSize:"14px",
              "& span":{
                top:"0",
              }
            }
          }
        }
      },
      "& .form-heading": {
        color: "#2B2B2B",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "19px",
        fontFamily: "Expletus Sans",
        letterSpacing: "0",
        marginBottom: "16px",
      },
      "& .form-info-wrapper": {
        marginBottom: "16px",
        "&:last-child": {
          marginBottom: "0",
        },
      },
      "& .form-row": {
        display: "flex",
        flexWrap: "wrap",
        margin: "0 -8px",
        "&:last-child": {
          "& .form-control": {
            marginBottom: "0",
          },
        },
        "& .form-col": {
          padding: "0 8px",
          flex: "0 0 100%",
          maxWidth: "100%",
          "&.col6": {
            flex: "0 0 50%",
            maxWidth: "50%",
          },
          "& .form-control": {
            marginBottom: "16px",
          },
        },
      },
      "& .favorite-box": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: "8px",
        padding: "24px",
        border: "1px solid #E8ECF2",
        "& .category-left-box": {
          "& .left-title": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            marginBottom: "10px",
          },
          "& .left-sub-txt": {
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.09px",
          },
        },
        "& .category-right-box": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // marginTop: "24px",
          "& .total-amount-txt": {
            color: "var(--text-secondary, #94A3B8)",
            texAlign: "right",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          },
          "& .amount-txt": {
            color: "var(--text-secondary, #2B2B2B)",
            texAlign: "right",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            marginLeft: "6px",
          },
          "& .arrow-btn": {
            // minWidth: "24px",
            "& .btn-icon": {
              height: "16px",
              width: "16px",
              color:"#94A3B8"
            },
          },
        },
      },
      "& .attachlist-ul": {
        padding: "0",
        "& .attachlist-li": {
          paddingLeft: "0",
          paddingTop: "15px",
          paddingBottom: "15px",
          borderBottom: "1px solid #E8ECF2",
          background: "transparent",
          "&:last-child": {
            borderBottom: "0",
          },
          "& .attachlist-label": {
            color: "#2B2B2B",
            fontSize: "14px",
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            margin: "0",
            "& .MuiTypography-root": {
              font: "inherit",
              letterSpacing: "inherit",
            },
          },
          "& .attachlist-icon": {
            minWidth: "unset",
            marginRight: "15px",
          },
        },
      },
      "& .multiselect-autocomplete": {
        marginBottom: "16px",
        "&.MuiAutocomplete-hasPopupIcon": {
          "&.MuiAutocomplete-hasClearIcon": {
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
              paddingRight: "85px",
            },
          },
        },
        "& .MuiOutlinedInput-root": {
          paddingLeft: "16px",
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2",
              borderWidth: "1px",
            },
          },
        },
        "& .MuiInputAdornment-root": {
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "29px",
          height: "auto",
        },
        "& .start-wrapper": {
          display: "flex",
        },
        "& .end-icon": {
          position: "absolute",
          top: "calc(50% - 12px)",
          right: "60px",
          marginLeft: "0",
        },
        "& .MuiChip-root": {
          backgroundColor: "#ECF1F4",
          borderRadius: "6px",
          padding: "6px 8px",
          height: "auto",
          color: "#4F4F4F",
          fontFamily: "Roboto",
          fontSize: "13px",
          fontWeight: 400,
          lineHeight: "17px",
          margin: "0 5px 5px 0",
          "& .MuiChip-label": {
            paddingLeft: "0",
          },
          "& .MuiChip-deleteIcon": {
            width: "17px",
            height: "17px",
            margin: "0",
          },
        },
        "& .MuiAutocomplete-endAdornment": {
          "& .MuiIconButton-root": {
            color: "#94A3B8",
          },
        },
      },
      "& .inventory-wrapper": {
        marginBottom: "12px",
        "& .links-wrapper": {
          display: "flex",
          flexWrap: "wrap",
        },
        "& .inventory-link": {
          padding: "5px 12px",
          borderRadius: "15px",
          border: "1px solid #E8ECF2",
          color: "rgba(43, 43, 43, 0.87)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0.09px",
          textDecoration: "none",
          marginRight: "12px",
          marginBottom: "12px",
        },
        "& .items-list-wrapper": {
          "& .items-list": {
            padding: "24px",
            marginBottom: "16px",
            borderRadius: "6px",
            border: "1px solid #E8ECF2",
            "& .items-title": {
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5px",
            },
            "& .title-icon": {
              "& .MuiSvgIcon-root": {
                color: "#94A3B8",
                "&.close-icon": {
                  fontSize: "20px",
                },
              },
            },
            "& .primary-txt": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "19px",
            },
            "& .sub-txt": {
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0.09px",
              "& span": {
                color: "#2B2B2B",
              },
            },
          },
        },
      },
      "& .createtask-bottom": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "14px 40px 40px",
        [theme.breakpoints.down("xs")]: {
          padding: "14px 24px 55px",
        },
        "& .full-btn": {
          width: "100%",
          "& .MuiButton-root": {
            width: "100%",
          },
        },
        "& .primary-btn": {
          minWidth: "165px",
        },
      },
      "& .select-outer": {
        width: "100%",
        marginBottom: "24px",
        "& .MuiInputLabel-formControl": {
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "19px",
          letterSpacing: "0.1px",
          color: "#94A3B8",
          display: "flex",
          width: "calc(100% - 46px)",
          "&.MuiFormLabel-filled": {
            width: "auto",
          },
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0",
          color: "#000000",
          opacity: "1",
          fontWeight: "400",
          fontFamily: "Roboto",
          display: "flex",
          alignItems: "center",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .status-name": {
            color: "#2B2B2B",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "26px",
            letterSpacing: "0.105px",
          },
          "& .status": {
            width: "12px",
            height: "12px",
            background: "#94A3B8",
            borderRadius: "100%",
            marginLeft: "10px",
            "&.open": {
              background: "#4BA3B7",
            },
            "&.cancelled": {
              background: "#F7E223",
            },
            "&.yellow": {
              background: "#F7E223",
            },
            "&.green": {
              background: "#00BA88",
            },
            "&.orange": {
              background: "#F6A318",
            },
            "&.sky-blue": {
              background: "#12AEF1",
            },

            "&.black": {
              background: "#2B2B2B",
            },
          },
        },
        "& .MuiSvgIcon-root": {
          fontSize: "20px",
          color: "#94A3B8",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
            },
          },
        },
        "& .MuiFormLabel-root": {
          "&.Mui-focused": {
            width: "auto",
          },
          "& .MuiInputLabel-asterisk": {
            color: "#e86577",
            fontSize: "18px",
            lineHeight: "23px",
            marginLeft: "auto",
          },
        },
      },
    },
    fileUploadWrapper: {
      textAlign: "center",
      transition: "all 0.5s ease-in-out",
      cursor: "pointer",
      "& .file-heading": {
        fontSize: "12px",
        lineHeight: "16px",
        margin: "0 0 7px",
        color: "rgba(43, 43, 43, 0.87)",
        fontFamily: "Roboto",
        fontWeight: 400,
        letterSpacing: "0.09px",
        marginBottom: "0",
        display: "flex",
        alignItems: "center",
        "& .attach-icon-deals": {
          marginRight: "4px",
        },
      },
      "& .browse-link-file": {
        color: "#4ba3b7",
        cursor: "pointer",
        fontWeight: 700,
        marginLeft: "3px",
        marginRight: "3px",
      },
    },
    secondaryButton: {
      background: "transparent",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      minWidth: "198px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    dealsdrawerWrapper: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    },

    fileDropDrag: {
      filter: "drop-shadow(0px 16px 10px rgba(0,0,0,0.05))",
      backgroundColor: "#fafcfe",
      borderColor: "transparent",
    },
    autocompleteDropbox: {
      padding: "0",
      "& .MuiAutocomplete-paper": {
        borderRadius: "12px",
        background: "#ffffff",
        filter: "none",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
      },
      "& .MuiAutocomplete-listbox": {
        padding: "0",
        "& .MuiAutocomplete-option": {
          padding: "0",
          background: "transparent",
        },
      },
      "& .tasklist-li": {
        display: "flex",
        justifyContent: "space-between",
        background: "transparent",
        position: "relative",
        borderBottom: "1px solid #ECF1F4",
        padding: "18px 24px",
        width: "100%",
        "& .tasklist-left": {
          display: "flex",
          alignItems: "center",
          "& .assignee-info": {
            marginLeft: "12px",
          },
          "& .assignee-profile": {
            width: "38px",
            height: "38px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ECF1F4",
            borderRadius: "100%",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Roboto",
            fontSize: "15.485px",
            fontWeight: 600,
            lineHeight: "21.679px",
            letterSpacing: "-0.395px",
          },
          "& .assignee-name": {
            fontSize: "14px",
            lineHeight: "19px",
            color: "#000000",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
          },
          "& .assignee-email": {
            fontSize: "12px",
            lineHeight: "16px",
            color: "#94A3B8",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
          },
        },
        "& .tasklist-icon": {
          minWidth: "unset",
        },
      },
    },
    btnMinimizeDeals: {
      gap: "16px",
      right: "230px",
      bottom: "34px",
      height: "56px",
      display: "flex",
      padding: "14px 16px",
      position: "fixed",
      background: "#FFF",
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
      alignItems: "center",
      borderRadius: "8px",
      flexDirection: "row",
      "& .expand-icon": {
        width: " 16px",
        height: "16px",
        cursor: "pointer",
      },
      "& .create-txt": {
        color: "#2B2B2B",
        fontSize: "14px",
        fontStyle: "normal",
        fontFamily: " Expletus Sans",
        fontWeight: 500,
        lineHeight: "normal",
      },
      "& .minimize-close-btn": {
        minWidth: "24px",
      },
      "& .close-icon": {
        width: " 16px",
        height: "16px",
      },
    },
    drawerRootWrapper: {
      "& .MuiBackdrop-root": {
        [theme.breakpoints.down("xs")]: {
          background: "rgba(34, 89, 128, 0.70)",
        },
      },
      "& .drawer-inner": {
        [theme.breakpoints.down("xs")]: {
          padding: "56px 24px",
        },
      },
    },
    drawer: {
      minWidth: "367px",
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
      position: "sticky",
      top: "88px",
      maxHeight: "calc(100vh - 88px)",
      [theme.breakpoints.down("md")]: {
        minWidth: "320px",
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: "100%",
      position: "static",
      borderRight: "0",
      padding: "18px 40px 18px 28px",
      maxHeight: "100%",
      overflowY: "auto",
      [theme.breakpoints.down("sm")]: {
        padding: "18px 28px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "80%",
        position: "fixed",
      },
    },
    dealsdrawerContent: {
      width: "calc(100% - 367px)",
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 320px)",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    modalCenterDialogBox: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 580,
      backgroundColor: "#ffffff",
      padding: "48px",
      borderRadius: "24px",
      // [theme.breakpoints.down("md")]: {
      //   padding: "35px 35px 20px",
      //   width: 520,
      // },
      // [theme.breakpoints.down("sm")]: {
      //   padding: "25px 25px 20px",
      //   maxWidth: "90%",
      // },
      [theme.breakpoints.down("xs")]: {
        padding: "24px",
        maxWidth: "327px",
        width: "90%",
        borderRadius: "12px",
        backgroundColor: "#F2F8FC",
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "42px",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "23px",
        },
      },
      "& .modal-title": {
        fontSize: "24px",
        lineHeight: "30px",
        color: "#2B2B2B",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        // [theme.breakpoints.down("md")]: {
        //   fontSize: "20px",
        //   lineHeight: "24px",
        // },
        [theme.breakpoints.down("xs")]: {
          fontSize: "18px",
          lineHeight: "24px",
        },
      },
      "& .modal-desc": {
        fontSize: "14px",
        letterSpacing: "0.0075em",
        lineHeight: "26px",
        color: "#2b2b2b",
        fontWeight: 400,
        fontFamily: "Roboto",
        [theme.breakpoints.down("xs")]: {
          fontSize: "13px",
          lineHeight: "17px",
        },
      },
      "& .select-outer": {
        width: "100%",
        marginBottom: "24px",
        "& .MuiInputLabel-formControl": {
          fontFamily: "Expletus Sans",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "19px",
          letterSpacing: "0.1px",
          color: "#94A3B8",
          display: "flex",
          width: "calc(100% - 46px)",
          "&.MuiFormLabel-filled": {
            width: "auto",
            color: "rgba(43,43,43,0.87)",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: 400,
            lineHeight: "16px",
            letterSpacing: "0.0075em",
          },
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0",
          color: "#000000",
          opacity: "1",
          fontWeight: "400",
          fontFamily: "Roboto",
          display: "flex",
          alignItems: "center",
          paddingRight: "45px",
          border:"1px solid #E8ECF2",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .status-name": {
            color: "#2B2B2B",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "19px",
            letterSpacing: "normal",
          },
          "& .status": {
            width: "12px",
            height: "12px",
            background: "#94A3B8",
            borderRadius: "100%",
            marginLeft: "10px",
            "&.open": {
              background: "#4BA3B7",
            },
            "&.cancelled": {
              background: "#F7E223",
            },
            "&.sky-blue": {
              background: "#12AEF1",
            },
            "&.orange": {
              background: "#F6A318",
            },
            "&.yellow": {
              background: "#F7E223",
            },
            "&.green": {
              background: "#00BA88",
            },
            "&.black": {
              background: "#2B2B2B",
            },
          },
        },        
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
            },
          },
        },
        "& .MuiSvgIcon-root": {
          fontSize: "20px",
          color: "#94A3B8",
          right: "16px",
        },
        "& .MuiFormLabel-root": {
          "&.Mui-focused": {
            width: "auto",
          },
          "& .MuiInputLabel-asterisk": {
            color: "#e86577",
            fontSize: "18px",
            lineHeight: "23px",
            marginLeft: "auto",
          },
        },
      },
      "& .form-info-wrapper": {
        margin: "0 0 28px",
        "&:last-child": {
          margin: "0",
        },        
        "& .form-row": {
          display: "flex",
          flexWrap: "wrap",
          margin: "0 -8px",
          "& .form-col": {
            padding: "0 8px",
            flex: "0 0 100%",
            maxWidth: "100%",
            "&.col6": {
              flex: "0 0 50%",
              maxWidth: "50%",
            },
            "& .form-control": {
              marginBottom: "24px",
            },
          },
        },
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .modal-footer": {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "32px",
        [theme.breakpoints.down("xs")]: {
          marginTop: "24px",
        },
        "& .primary-btn, & .secondary-btn ": {
          width: "auto",
          minWidth: "157px",
          [theme.breakpoints.down("xs")]: {
            minWidth: "70px",
            padding: "4px 6px",
          },
        },
        "& .MuiButton-root": {
          marginRight: "24px",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
      "& .primary-btn": {
        minHeight: "48px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "0",
        [theme.breakpoints.down("xs")]: {
          minHeight: "30px",
        },
      },
      "& .secondary-btn": {
        minHeight: "48px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "0",
        [theme.breakpoints.down("xs")]: {
          minHeight: "30px",
          border: "0",
          color: "#4BA3B7",
        },
      },
    },
    modalDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      padding: "0",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        width: 570,
      },
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
      "&.deals-modal": {
        "& .modal-heading": {
          marginBottom: "8px",
          "& .modal-title": {
            color: "rgba(43,43,43,0.87)",
            fontSize: "24px",
            lineHeight: "30px",
          },
        },
        "& .sub-txt": {
          letterSpacing: "0.105px",
          color: "#94A3B8",
          marginBottom: "24px",
        },
        "& .modal-form-stage": {
          marginTop: "0",
          "& .MuiListSubheader-gutters": {
            position: "static",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#225980",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-adornedStart": {
                paddingLeft: "16px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "17px 16px 16px",
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "7px",
                },
              },
            },
          },
        },
      },
      "&.view-modal-dialog": {
        "& .modal-heading": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #E8ECF2",
          padding: "24px 40px",
          marginBottom: "0",
          "& .heading-links": {
            display: "flex",
            alignItems: "center",
            "& .heading-link": {
              marginRight: "24px",
              "&:last-child": {
                marginRight: "0",
              },
            },
          },
          "& .close-icon": {
            color: "#94A3B8",
            lineHeight: "0",
          },
        },
        "& .modal-title": {
          fontSize: "22px",
          lineHeight: "26px",
          color: "#2B2B2B",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          marginBottom: "16px",
          "& .priority-status": {
            color: "#C7263E",
            marginRight: "5px",
          },
        },
        "& .modal-description-stage": {
          padding: "24px 40px",
        },
        "& .modal-description-deal": {
          padding: "24px 40px",
        },
        "& .dealview-list": {
          marginBottom: "8px",
          padding: "0",
          "& .MuiListItem-root": {
            padding: "16px 0",
            borderBottom: "1px solid #E8ECF2",
            display: "flex",
            alignItems: "center",
            "&:last-child": {
              borderBottom: "0",
            },
            "&.date-item-root": {
              alignItems: "flex-start",
            },
            "& .list-description": {
              letterSpacing: "0.105px",
              color: "#2B2B2B",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "26px",
              paddingLeft: "15px",
              fontFamily: "Roboto",
              width: "67%",
              "& .date-cal-icon":{
                display: "flex",
                alignItems: "center",
                "& .icon":{
                  marginLeft: "8px",
                }
              },
              "& .list-subtitle": {
                letterSpacing: "normal",
                color: "#94A3B8",
                lineHeight: "14px",
                fontSize: "10px",
              },
              "& .assignee-list": {
                alignItems: "center",
                display: "flex",
                "& .assignee-bubble": {
                  marginLeft: "-4px",                
                  color: "rgba(43, 43, 43, 0.87)",
                  letterSpacing: "-0.244px",
                  justifyContent: "center",
                  background: "#ECF1F4",
                  width: "24px",
                  height: "24px",
                  borderRadius: "100%",
                  border: "2px solid #FFF",
                  fontSize: "9.586px",
                  fontFamily: "Roboto",
                  fontWeight: 600,
                  lineHeight: "13.42px",
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                  "&:first-child": {
                    marginLeft: "0",
                  },
                  "&.more-bubble": {
                    cursor: "pointer",
                  },
                },
              },
              "& .date-info": {
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
                "&:last-child": {
                  marginBottom: "0",
                },
                "& .label": {
                  color: "#94A3B8",
                  minWidth: "67px",
                  textAlign: "right",
                },
                "& .value": {
                  marginLeft: "8px",
                  display: "flex",
                  alignItems: "center",
                  "& .icon": {
                    marginLeft: "8px",
                  },
                },
              },
            },
            "& .list-label": {
              letterSpacing: "0.105px",
              fontWeight: 400,
              lineHeight: "26px",
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontSize: "14px",
              width: "33%",
            },           
          },
        },
        "& .tasklist-tabwrapper": {
          marginBottom: "45px",
          "&:last-child": {
            marginBottom: "0",
          },
          "& .assignee-tabs": {
            "& .MuiTabs-root": {
              marginBottom: "0",
            },
          },
          "& .MuiTabs-root": {
            borderBottom: "1px solid #E8ECF2",
            marginBottom: "17px",
            "& .MuiTab-root": {
              color: "#2B2B2B",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 500,
              fontFamily: "Expletus Sans",
              textTransform: "capitalize",
              opacity: "0.67",
              minWidth: "unset",
              padding: "16px 24px",
              letterSpacing: "0",         
              "& .MuiTouchRipple-root": {
                display: "none",
              },
              "&.Mui-selected": {
                opacity: "1",
              },
            },
          },          
          "& .tab-heading": {
            marginBottom: "24px",
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",            
            "& .sub-txt": {
              letterSpacing: "0",
              marginBottom: "0",
              fontWeight: 400,
              lineHeight: "17px",
              fontSize: "13px",
              fontFamily: "Roboto",
              color: "#94A3B8",
            },          
            "& .primary-txt": {
              fontFamily: "Expletus Sans",
              marginBottom: "5px",
              lineHeight: "21px",
              fontWeight: 500,
              fontSize: "16px",
              letterSpacing: "0",
              color: "#2B2B2B",
            },
            "& .heading-right": {
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
              "& .heading-link": {
                marginRight: "24px",
                "&:last-child": {
                  marginRight: "0",
                },
              },
            },
          },          
          "& .attachments-list": {            
            "& .list-secondary": {
              right: "0",
              "& .MuiIconButton-root": {
                margin: "0",
                padding: "0",
                color: "#828282",
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                },
              },
            },            
            "& .list-item": {
              paddingLeft: "0",
              paddingBottom: "16px",
              paddingTop: "16px",
              borderBottom: "1px solid #E8ECF2",
              "& .list-item-inner":{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
              },
              "& .list-img": {
                marginRight: "8px",
                width: "28px",
              },
              "& .list-name": {
                fontFamily: "Expletus Sans",
                lineHeight: "16px",
                fontSize: "12px",
                fontWeight: 500,
                color: "#2B2B2B",
              },
            },
            "& .subheader-title": {
              borderBottom: "1px solid #E8ECF2",
              paddingBottom: "16px",
              justifyContent: "space-between",
              padding: "0",
              position: "static",
              display: "flex",
              "& .title": {
                opacity: "0.67",
                lineHeight: "16px",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontWeight: 500,
                color: "#2B2B2B",
              },
              "& .tasklist-link": {
                fontFamily: "Expletus Sans",
                textDecoration: "none",
                fontSize: "14px",
                opacity: "0.67",
                fontWeight: 500,
                lineHeight: "19px",
                color: "#4BA3B7",
              },
            },
          },
          "& .activitylist-ul": {
            padding: "0",
            "& .activity-li": {
              position: "relative",
              background: "transparent",
              padding: "0",
              marginBottom: "32px",
              justifyContent: "space-between",
              display: "flex",
              "& .activity-icon": {
                marginRight: "16px",
                width: "32px",
                height: "32px",
                minWidth: "unset",
              },
              "& .activity-details": {
                margin: "0",
                "& .activity-info": {
                  letterSpacing: "0.09px",
                  marginBottom: "2px",
                  lineHeight: "16px",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  color: "#2B2B2B",
                  fontSize: "12px",
                  "& .name": {
                    marginRight: "5px",
                    color: "#4BA3B7",
                  },
                },
                "& .activity-date": {
                  letterSpacing: "0.125px",
                  fontWeight: 400,
                  lineHeight: "17px",
                  fontFamily: "Roboto",
                  fontSize: "10px",
                  color: "#94A3B8",
                },
              },
              "&:last-child": {
                marginBottom: "0",
                "&:before": {
                  display: "none",
                },
              },
              "&:before": {
                left: "16px",
                height: "100%",
                borderRight: "1px solid #E8ECF2",
                position: "absolute",
                top: "100%",
                content: "''",
              },
            },
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#4BA3B7",
          },
          "& .description-box": {
            "& .overview-txt":{
               fontFamily: "Roboto",
            fontWeight: 400,
            letterSpacing: "0.105px",
            padding: "16px",
            color: "#2B2B2B",
            fontSize: "14px",
            background: "#F2F8FC",
            lineHeight: "26px",
            borderRadius: "12px",
            },
            "& .content-heading":{
              display: 'flex',
              alignItems: "center",
              borderBottom: "1px solid #E8ECF2",
              marginBottom: "16px",
              paddingBottom: "16px",
              justifyContent: "space-between",
              "& .title":{
                color: "rgba(43, 43, 43, 0.67)",
                fontSize: "12px",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                lineHeight: "16px"
              },
              "& .content-filter": {
                alignItems: "center",
                display: "flex",
                "& .select-control": {
                  "& .MuiSelect-select": {
                    fontSize: "12px",
                  },
                  "& .MuiInput-underline:before": {
                    display: "none",
                  },
                  "& .MuiInput-underline:after": {
                    display: "none",
                  },
                  "&.border-hr": {
                    paddingRight: "10px",
                    marginRight: "10px",
                   
                  },
                  "& .down-arrow": {
                    width: "20px",
                    fontSize: "20px",
                    color: "rgba(43, 43, 43, 0.67)",
                    height: "20px",
                  },
                  
                },
              },
            },
           
            "& .product-list-row": {
              display: "flex",
              flexWrap: "wrap",
              margin: "0 -12px",
              [theme.breakpoints.down("sm")]: {
                margin: "0 -8px",
              },
              "& .product-col": {
                padding: "0 12px",
                marginBottom: "24px",
                [theme.breakpoints.down("sm")]: {
                  padding: "0 8px",
                  marginBottom: "16px",
                },
                "&.col4": {
                  flex: "0 0 33.33%",
                  maxWidth: "33.33%",
                  [theme.breakpoints.down("xs")]: {
                    flex: "0 0 50%",
                    maxWidth: "50%",
                  },
                },
              },
              "& .product-inner": {
                borderRadius: "12px",
                border: "1px solid #E8ECF2",
                padding: "24px 24px 15px",
                position: "relative",
                [theme.breakpoints.down("sm")]: {
                  padding: "24px 16px 16px",
                },
                "& .product-img": {
                  textAlign: "center",
                  width: "72px",
                  height: "72px",
                  maxWidth: "100%",
                  margin: "0 auto 24px",
                },
                "& .product-details": {
                  "& .product-info": {
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    color: "#94A3B8",
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontWeight: 400,
                    lineHeight: "17px",
                    letterSpacing: "0.125px",
                    marginBottom: "4px",
                    "& .rating": {
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "10px",
                      paddingLeft: "14px",
                      position: "relative",
                      [theme.breakpoints.down("sm")]: {
                        marginLeft: "6px",
                        paddingLeft: "5px",
                      },
                      "&:before": {
                        content: "''",
                        width: "4px",
                        height: "4px",
                        borderRadius: "100%",
                        background: "#E0E0E0",
                        position: "absolute",
                        left: 0,
                      },
                      "& .star-icon": {
                        color: "#F6A318",
                        width: "10px",
                        height: "10px",
                        fontSize: "10px",
                        marginLeft: "2px",
                      },
                    },
                  },
                  "& .product-name": {
                    color: "#2B2B2B",
                    fontFamily: "Expletus Sans",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "19px",
                    marginBottom: "4px",
                  },
                  "& .product-desc": {
                    color: "#94A3B8",
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontWeight: 400,
                    lineHeight: "17px",
                    letterSpacing: "0.125px",
                  },
                },
              },
              "& .close-icon": {
                color: "rgba(43, 43, 43, 0.67)",
                position: "absolute",
                right: "16px",
                top: "16px",
                width: "20px",
                height: "20px",
                fontSize: "20px",
                "& .MuiSvgIcon-root": {
                  width: "inherit",
                  height: "inherit",
                  color: "inherit",
                  fontSize: "inherit",
                },
              },
            },
          },
          "& .deals-task-wrap": {
            marginBottom: "16px",
            "&:first-child": {
              "& .deal-task-heading": {
                paddingTop: "0",
              },
            },
            "&:last-child": {
              marginBottom: "0",
            },
            "& .deal-task-heading": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 0",
              "& .deal-heading": {
                color: "#2B2B2B",
                fontFamily: "Expletus Sans",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
              },
              "& .deal-total-task": {
                color: "#94A3B8",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0.09px",
              },
            },
            "& .deal-task-ul": {
              padding: "0",
              width: "100%",
              "& .deal-task-ul": {
                paddingLeft: "32px",
              },
              "& .deal-task-li": {
                alignItems: "flex-start",
                background: "transparent",
                flexDirection: "column",
                padding: "0",
              },
              "& .deal-inner": {
                display: "flex",
                alignItems: "flex-start",
                width: "100%",
                cursor: "pointer",
                padding: "16px 0",
                borderBottom: "1px solid #E8ECF2",
              },
              "& .tasklist-icon": {
                minWidth: "unset",
                marginRight: "16px",
                "& .MuiIconButton-root": {
                  padding: "0",
                },
              },
              "& .tasklist-label": {
                margin: "0",
                flex: "1",
              },
            },
            "& .task-title": {
              display: "flex",
              justifyContent: "space-between",
              "& .title": {
                color: "#2B2B2B",
                fontSize: "14px",
                lineHeight: "19px",
                fontFamily: "Expletus Sans",
                fontWeight: 400,
                "& .priority-status": {
                  color: "#C7263E",
                  marginRight: "3px",
                },
              },
              "& .title-right": {
                marginLeft: "10px",
                "& .controller-link": {
                  width: "22px",
                },
              },
            },
            "& .task-desc": {
              display: "flex",
              justifyContent: "space-between",
              color: "#94A3B8",
              fontSize: "12px",
              lineHeight: "16px",
              fontFamily: "Roboto",
              fontWeight: 400,
              letterSpacing: "0.09px",
              marginTop: "3px",
              "& .desc-left": {
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              },
              "& .task-about": {
                paddingLeft: "14px",
                marginLeft: "8px",
                position: "relative",
                "&:before": {
                  content: '""',
                  width: "6px",
                  height: "6px",
                  borderRadius: "100%",
                  background: "#E8ECF2",
                  display: "inline-block",
                  position: "absolute",
                  left: "0",
                  top: "5px",
                },
              },
              "& .timing": {
                color: "#2B2B2B",
                fontFamily: "Roboto",
                fontSize: "10px",
                fontWeight: 400,
                lineHeight: "17px",
                letterSpacing: "0.125px",
              },
            },
          },
        }, 
        
      },
      
      "& .modal-dialoginner": {
        padding: "80px 60px",
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        [theme.breakpoints.down("xs")]: {
          padding: "24px",
        },
        "& .MuiCheckbox-root": {
          color: "#94A3B8",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
        "& .MuiRadio-root": {
          color: "#94A3B8",
          padding: "0",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
      },
      "& .modal-heading": {
        marginBottom: "20px",
        display: "flex",
        justifyContent: "space-between",
        "& .modal-title ": {
          fontWeight: 400,
          fontSize: "22px",
          lineHeight: "26px",
          fontFamily: "Expletus Sans",
          color: "#2b2b2b",
        },
        "& .heading-left-deals": {
          display: "flex",
          alignItems: "center",
          "& .auth-back": {
            cursor: "pointer",
            marginRight: "8px",
            opacity: "0.67",
            color: "#2B2B2B",
          },
          "& .modal-title ": {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            color: "#2b2b2b",
          },
        },
        "& .close-icon": {
          color: "#94A3B8",
          lineHeight: "0",
        },
      },
      "& .modal-description-stage": {
        "& .datepicker-wrapper": {
          "& .rmdp-container": {
            "& .rmdp-input": {
              paddingLeft: "16px",
              letterSpacing: "0.105px",
              "&::-webkit-input-placeholder": {
                color: "#94A3B8",
              },
              "&:-ms-input-placeholder": {
                color: "#94A3B8",
              },
              "&::placeholder": {
                color: "#94A3B8",
              },
            },
          },
        },
        "& .select-outer": {
          width: "100%",
          marginBottom: "24px",
          "& .MuiInputLabel-formControl": {
            fontFamily: "Roboto",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "19px",
            letterSpacing: "0.1px",
            color: "#94A3B8",
            display: "flex",
            width: "calc(100% - 46px)",
            "&.MuiFormLabel-filled": {
              width: "auto",
            },
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0",
            color: "#000000",
            opacity: "1",
            fontWeight: "400",
            fontFamily: "Roboto",
            display: "flex",
            alignItems: "center",
            "&:focus": {
              backgroundColor: "transparent",
            },
            "& .status-name": {
              color: "#2B2B2B",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "26px",
              letterSpacing: "0.105px",
            },
            "& .status": {
              width: "12px",
              height: "12px",
              background: "#94A3B8",
              borderRadius: "100%",
              marginLeft: "10px",
              "&.open": {
                background: "#4BA3B7",
              },
              "&.cancelled": {
                background: "#F7E223",
              },
              "&.sky-blue": {
                background: "#12AEF1",
              },
              "&.orange": {
                background: "#F6A318",
              },
              "&.yellow": {
                background: "#F7E223",
              },
              "&.green": {
                background: "#00BA88",
              },
              "&.black": {
                background: "#2B2B2B",
              },
            },
          },
          "& .MuiSvgIcon-root": {
            fontSize: "20px",
            color: "#94A3B8",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            },
          },
          "& .MuiFormLabel-root": {
            "&.Mui-focused": {
              width: "auto",
            },
            "& .MuiInputLabel-asterisk": {
              color: "#e86577",
              fontSize: "18px",
              lineHeight: "23px",
              marginLeft: "auto",
            },
          },
        },

        "& .task-progress-block": {
          "& .progress-info": {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginBottom: "16px",
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "14px",
            textTransform: "uppercase",
            "& .total-value": {
              color: "#94A3B8",
            },
          },
          "& .progress-wrap": {
            width: "100%",
            height: "4px",
            background: "#ECF1F4",
            borderRadius: "32px",
            "& .progress-inner": {
              background: "rgba(75, 163, 183, 0.37)",
              height: "inherit",
              borderRadius: "inherit",
              maxWidth: "100%",
              "&.inprogress": {
                background: "#4BA3B7",
              },
              "&.completed": {
                background: "#00BA88",
              },
            },
            "& .current-progress": {
              background: "#4BA3B7",
              height: "inherit",
              borderRadius: "16px",
              maxWidth: "100%",
              borderLeft: "4px solid #fff",
              marginLeft: "auto",
            },
          },
        },
        "& .task-subtitle": {
          color: "rgba(43, 43, 43, 0.67)",
          fontFamily: "Roboto",
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: 400,
          letterSpacing: "0.09px",
          marginBottom: "8px",
        },
        "& .tasklist-tabwrapper": {
          marginBottom: "48px",
          "&:last-child": {
            marginBottom: "0",
          },
          "& .assignee-tabs": {
            "& .MuiTabs-root": {
              marginBottom: "0",
            },
          },
          "& .MuiTabs-root": {
            borderBottom: "1px solid #E8ECF2",
            marginBottom: "17px",
            "& .MuiTab-root": {
              color: "#2B2B2B",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 500,
              fontFamily: "Expletus Sans",
              textTransform: "capitalize",
              opacity: "0.67",
              minWidth: "unset",
              padding: "16px 24px",
              "&.Mui-selected": {
                opacity: "1",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            },
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#4BA3B7",
          },
          "& .description-box": {
            borderRadius: "12px",
            background: "#F2F8FC",
            padding: "16px",
            color: "#2B2B2B",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "26px",
            letterSpacing: "0.105px",
            fontFamily: "Roboto",
          },
          "& .attachments-list": {
            "& .subheader-title": {
              position: "static",
              display: "flex",
              justifyContent: "space-between",
              padding: "0",
              paddingBottom: "16px",
              borderBottom: "1px solid #E8ECF2",
              "& .title": {
                color: "#2B2B2B",
                fontSize: "12px",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                lineHeight: "16px",
                opacity: "0.67",
              },
              "& .tasklist-link": {
                color: "#4BA3B7",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                lineHeight: "19px",
                opacity: "0.67",
                textDecoration: "none",
              },
            },
            "& .list-item": {
              borderBottom: "1px solid #E8ECF2",
              paddingTop: "16px",
              paddingBottom: "16px",
              paddingLeft: "0",
              "& .list-img": {
                width: "28px",
                marginRight: "8px",
              },
              "& .list-name": {
                color: "#2B2B2B",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                fontFamily: "Expletus Sans",
              },
            },
            "& .list-secondary": {
              right: "0",
              "& .MuiIconButton-root": {
                padding: "0",
                margin: "0",
                color: "#828282",
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                },
              },
            },
          },
          "& .tab-heading": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "24px",
            "& .heading-right": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "& .heading-link": {
                marginRight: "24px",
                "&:last-child": {
                  marginRight: "0",
                },
              },
            },
            "& .primary-txt": {
              color: "#2B2B2B",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "21px",
              letterSpacing: "0",
              marginBottom: "5px",
              fontFamily: "Expletus Sans",
            },
            "& .sub-txt": {
              color: "#94A3B8",
              fontSize: "13px",
              fontWeight: 400,
              lineHeight: "17px",
              letterSpacing: "0",
              fontFamily: "Roboto",
              marginBottom: "0",
            },
          },
        },
        "& .taskview-list": {
          marginBottom: "24px",
          "& .MuiListItem-root": {
            padding: "16px 0",
            borderBottom: "1px solid #E8ECF2",
            display: "flex",
            alignItems: "center",
            "&:last-child": {
              borderBottom: "0",
            },
            "& .list-label": {
              width: "40%",
              fontSize: "14px",
              lineHeight: "26px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontWeight: 400,
              letterSpacing: "0.105px",
            },
            "& .list-description": {
              width: "60%",
              paddingLeft: "15px",
              fontSize: "14px",
              lineHeight: "26px",
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontWeight: 400,
              letterSpacing: "0.105px",
              "& .list-subtitle": {
                fontSize: "10px",
                lineHeight: "14px",
                color: "#94A3B8",
                letterSpacing: "normal",
              },
              "& .assignee-list": {
                display: "flex",
                alignItems: "center",
                "& .assignee-bubble": {
                  lineHeight: "13.42px",
                  letterSpacing: "-0.244px",
                  position: "relative",
                  marginLeft: "-4px",
                  background: "#ECF1F4",
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "100%",
                  border: "2px solid #FFF",
                  color: "rgba(43, 43, 43, 0.87)",
                  fontSize: "9.586px",
                  fontFamily: "Roboto",
                  fontWeight: 600,

                  "&.more-bubble": {
                    cursor: "pointer",
                  },
                  "&:first-child": {
                    marginLeft: "0",
                  },
                },
              },
              "& .date-info": {
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",

                "& .label": {
                  color: "#94A3B8",
                },
                "& .value": {
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                  "& .icon": {
                    marginLeft: "8px",
                  },
                },
              },
            },
          },
        },

        "& .activitylist-ul": {
          padding: "0",
          "& .activity-li": {
            display: "flex",
            justifyContent: "space-between",
            padding: "0",
            marginBottom: "32px",
            background: "transparent",
            position: "relative",
            "&:last-child": {
              marginBottom: "0",
              "&:before": {
                display: "none",
              },
            },
            "&:before": {
              content: "''",
              borderRight: "1px solid #E8ECF2",
              position: "absolute",
              top: "100%",
              left: "16px",
              height: "100%",
            },
            "& .activity-details": {
              margin: "0",
              "& .activity-info": {
                color: "#2B2B2B",
                fontSize: "12px",
                lineHeight: "16px",
                fontFamily: "Roboto",
                fontWeight: 400,
                marginBottom: "2px",
                letterSpacing: "0.09px",
                "& .name": {
                  color: "#4BA3B7",
                  marginRight: "5px",
                },
              },
              "& .activity-date": {
                color: "#94A3B8",
                fontSize: "10px",
                lineHeight: "17px",
                fontFamily: "Roboto",
                fontWeight: 400,
                letterSpacing: "0.125px",
              },
            },
            "& .activity-icon": {
              minWidth: "unset",
              width: "32px",
              height: "32px",
              marginRight: "16px",
            },
          },
        },

        "& .tasklist-member": {
          padding: "0",
          "& .tasklist-li": {
            display: "flex",
            justifyContent: "space-between",
            background: "transparent",
            position: "relative",
            borderBottom: "1px solid #ECF1F4",
            padding: "26px 24px 18px",
            "& .tasklist-left": {
              display: "flex",
              alignItems: "center",
              "& .assignee-info": {
                marginLeft: "12px",
              },
              "& .assignee-profile": {
                width: "38px",
                height: "38px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ECF1F4",
                borderRadius: "100%",
                color: "rgba(43, 43, 43, 0.87)",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontWeight: 600,
                lineHeight: "21.679px",
                letterSpacing: "-0.395px",
              },
              "& .assignee-name": {
                fontSize: "14px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
              },
              "& .assignee-email": {
                fontSize: "12px",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
              },
            },
            "& .tasklist-icon": {
              minWidth: "unset",
              marginLeft: "10px",
            },
            "& .tasklist-hours": {
              textAlign: "right",
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 600,
              lineHeight: "16px",
              letterSpacing: "0.09px",
              "& .hours": {
                display: "block",
                color: "#94A3B8",
                fontWeight: 400,
                marginTop: "3px",
              },
            },
          },
          "& .tasklist-total": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            padding: "16px 24px",
            borderBottom: "1px solid #ECF1F4",
            position: "static",
            "& .total-member": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19px",
            },
            "& .select-control-deals": {
              "&.sortby-control": {
                  borderLeft:"1px solid #E8ECF2",
                "& .MuiSelect-select": {
                  paddingRight: "26px",
                },
                "& .MuiSelect-icon": {
                  fontSize: "20px",
                  width: "20px",
                  height: "20px",
                  color: "#2B2B2B",
                  top: "calc(50% - 11px)",
                },
              },
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
              },
            },
          },
        },
        "& .heading-block": {
          marginBottom: "46px",
          "& .primary-txt": {
            color: "rgba(43, 43, 43, 0.87)",
            fontSize: "24px",
            fontFamily: "Expletus Sans",
            fontWeight: 400,
            lineHeight: "32px",
            marginBottom: "6px",
          },
          "& .sub-txt": {
            fontSize: "14px",
            lineHeight: "26px",
            color: "#94A3B8",
            letterSpacing: "0.105px",
            marginBottom: "0",
          },
        },
        "& .template-list": {
          padding: "0",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "0",
            paddingRight: "0",
            position: "static",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#225980",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-adornedStart": {
                paddingLeft: "16px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "17px 16px 16px",
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "7px",
                },
              },
            },
          },
          "& .multiselect-membres": {
            display: "flex",
            alignItems: "center",
            width: "auto",
            maxWidth: "unset",
            justifyContent: "space-between",
            padding: "17px 24px",
            margin: "0",
            borderBottom: "1px solid #ECF1F4",
            opacity: "0.67",
            [theme.breakpoints.down("xs")]: {
              padding: "15px 0",
              margin: "0",
            },
            "& .total-member": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19px",
            },
            "& .select-control-deals": {
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
                paddingRight: "26px",
              },
              "& .MuiSelect-icon": {
                top: "calc(50% - 11px)",
                color: "#2b2b2b",
                fontSize: "20px",
              },
            },
          },
          "& .template-li": {
            display: "flex",
            justifyContent: "space-between",
            background: "transparent",
            position: "relative",
            padding: "21px 0",
            borderBottom: "1px solid #ECF1F4",
            "& .template-left": {
              "& .name": {
                fontSize: "14px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                marginBottom: "4px",
              },
              "& .sub-txt": {
                fontSize: "12px",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                letterSpacing: "0",
                marginBottom: "0",
              },
            },
            "& .template-icon": {
              minWidth: "unset",
              marginLeft: "10px",
              "& .MuiCheckbox-root": {
                padding: "0",
              },
              "& .MuiIconButton-colorSecondary": {
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            },
          },
        },
        "& .multiselect-membres": {
          display: "flex",
          alignItems: "center",
          width: "auto",
          maxWidth: "unset",
          justifyContent: "space-between",
          padding: "15px 60px",
          margin: "0 -40px",
          borderBottom: "1px solid #ECF1F4",
          [theme.breakpoints.down("xs")]: {
            padding: "15px 0",
            margin: "0",
          },
          "& .total-member": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "19px",
          },
          "& .select-control-deals": {
            "& .MuiSelect-select": {
              color: "#2b2b2b",
              opacity: "1",
              fontSize: "12px",
              letterSpacing: "0",
              paddingRight: "0",
            },
            "& .down-arrow": {
              fontSize: "20px",
              marginLeft: "6px",
            },
          },
        },
        
        "& .assignee-multiSelect-list": {
          paddingTop: "0",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "0",
            paddingRight: "0",
            position: "static",
            "&.multiselect-subheader": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              lineHeight: "19px",
              borderBottom: "1px solid #ECF1F4",
              padding: "15px 20px",
              marginBottom: "5px",
              "& .tasklist-link": {
                marginLeft: "10px",
                color: "#C7263E",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                lineHeight: "19px",
                whiteSpace: "nowrap",
                textDecoration: "none",
              },
            },
            "&.multiselect-subtxt": {
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "10px",
              fontWeight: 400,
              lineHeight: "normal",
              textTransform: "uppercase",
              padding: "10px 20px",
              borderBottom: "1px solid #ECF1F4",
            },
            "& .form-control": {
              marginBottom: "20px",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
          "& .MuiListItem-root": {
            padding: "15px 20px",
            cursor: "pointer",
          },
          "& .multiselect-membres": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            padding: "15px 20px",
            borderBottom: "1px solid #ECF1F4",
            margin: "0",
            "& .total-member": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19px",
            },
            "& .select-control-deals": {
              "&.sortby-control": {
                "& .MuiSelect-select": {
                  paddingRight: "26px",
                },
                "& .MuiSelect-icon": {
                  top: "calc(50% - 11px)",
                  color: "#2b2b2b",
                  fontSize: "20px",
                },
              },
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
              },
            },
          },
          "& .assignee-detail": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            "& .assignee-left": {
              display: "flex",
              "& .assignee-info": {
                marginLeft: "10px",
              },
              "& .assignee-profile": {
                width: "39px",
                height: "39px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ECF1F4",
                borderRadius: "100%",
                color: "rgba(43, 43, 43, 0.87)",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontWeight: 600,
                lineHeight: "21.679px",
                letterSpacing: "-0.395px",
              },
              "& .assignee-name": {
                fontSize: "14px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
              },
              "& .assignee-email": {
                fontSize: "12px",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
              },
            },
            "& .assignee-right": {
              marginLeft: "10px",
              "& .assinee-selected": {
                width: "20px",
                height: "20px",
                "& .normal-img": {
                  display: "none",
                },
                "& .selected-img": {
                  display: "block",
                },
              },
              "& .assinee-unselected": {
                width: "20px",
                height: "20px",
                "& .normal-img": {
                  display: "block",
                },
                "& .selected-img": {
                  display: "none",
                },
              },
            },
          },
        },
        "& .project-create-wrapper": {
          textAlign: "center",
          marginBottom: "75px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "35px",
          },
          "& .project-img": {
            marginBottom: "40px",
          },
          "& .modal-title": {
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Expletus Sans",
            fontSize: "24px",
            lineHeight: "28px",
            fontWeight: 400,
            marginBottom: "7px",
            [theme.breakpoints.down("md")]: {
              fontSize: "22px",
              lineHeight: "26px",
            },
            [theme.breakpoints.down("xs")]: {
              fontSize: "18px",
              lineHeight: "24px",
            },
          },
          "& .sub-txt": {
            letterSpacing: "0.25px",
          },
        },
        "& .no-task-block": {
          textAlign: "center",
          padding: "31px 0 95px",
          "& .task-img": {
            marginBottom: "16px",
          },
          "& .heading": {
            color: "#2B2B2B",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            marginBottom: "8px",
          },
          "& .sub-txt": {
            color: "#94A3B8",
            letterSpacing: "0.105px",
            marginBottom: "0",
          },
          "& .button-wrapper": {
            marginTop: "30px",
            "& .MuiButton-root": {
              maxWidth: "327px",
              color: "#4BA3B7",
              borderColor: "#4BA3B7",
              minHeight: "48px",
              "&:hover": {
                color: "#4BA3B7",
                borderColor: "#4BA3B7",
              },
            },
          },
        },

        "& .budget-wrapper": {
          "& .budget-main": {
            "& .MuiSlider-root": {
              height: 23,
              borderRadius: "24px",
              padding: "0",
              "& .MuiSlider-rail": {
                background: "#00BA88",
                opacity: "0.17",
                height: "inherit",
                borderRadius: "inherit",
              },
              "& .MuiSlider-track": {
                border: "none",
                background: "#00BA88",
                height: "inherit",
                borderRadius: "inherit",
              },
              "& .MuiSlider-thumb": {
                border: "0",
                height: "23px",
                marginTop: "0",
                borderRadius: "0",
                backgroundColor: "transparent",
                "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                  boxShadow: "inherit",
                },
                "&:before": {
                  display: "none",
                },
              },
              "& .MuiSlider-valueLabel": {
                // display: "none",
              },
            },
            "& .slider-wrap": {
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              padding: "16px",
              marginBottom: "24px",
              "&.overflow-count": {
                "& .legends-dots": {
                  background: "#F5DADE",
                  "&.actual": {
                    background: "#C7263E",
                  },
                },
                "& .progress-wrap": {
                  background: "#F5DADE",
                  "& .progress-inner": {
                    background: "#C7263E",
                  },
                  "& .progress-label": {
                    color: "#2B2B2B",
                  },
                },
              },
              "& .slider-heading": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "14px",
              },
              "& .slider-label": {
                color: "#94A3B8",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "19px",
                fontFamily: "Expletus Sans",
              },
              "& .legends-wrapper": {
                display: "flex",
                alignItems: "center",
                color: "#2B2B2B",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0.09px",
              },
              "& .slider-legends": {
                marginRight: "8px",
                display: "flex",
                alignItems: "center",
                "&:last-child": {
                  marginRight: "0",
                },
              },
              "& .legends-dots": {
                width: "11px",
                height: "11px",
                background: "#D4F3EB",
                borderRadius: "100%",
                marginLeft: "8px",
                "&.actual": {
                  background: "#00BA88",
                },
              },
              "& .slider-inner": {
                display: "flex",
                alignItems: "center",
                "& .max-value": {
                  marginLeft: "12px",
                  color: "#2B2B2B",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  letterSpacing: "0.09px",
                },
                "& .MuiSlider-valueLabel": {
                  transform: "none",
                  transition: "none",
                  top: "0",
                  bottom: "0",
                  "& > span": {
                    width: "auto",
                    height: "23px",
                    transform: "none",
                    borderRadius: "0",
                    background: "transparent",
                    "& > span": {
                      color: "#2B2B2B",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      letterSpacing: "0.09px",
                      transform: "none",
                    },
                  },
                },
              },
            },
            "& .progress-wrap": {
              width: "100%",
              height: "23px",
              background: "rgba(0, 186, 136, 0.17)",
              borderRadius: "24px",
              position: "relative",
              "& .progress-inner": {
                background: "#00BA88",
                height: "inherit",
                borderRadius: "inherit",
                maxWidth: "100%",
                borderRight: "3px solid #fff",
              },
              "& .progress-label": {
                textAlign: "center",
                color: "#fff",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "23px",
                letterSpacing: "0.09px",
                height: "inherit",
                maxWidth: "100%",
                position: "absolute",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
              },
            },
            "& .accordion-block": {
              boxShadow: "none",
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              minHeight: "unset",
              padding: "16px 15px",
              "&:before": {
                display: "none",
              },
              "& .accordion-heading": {
                minHeight: "unset",
                padding: "0",
                alignItems: "flex-start",
                "& .heading": {
                  width: "100%",
                  color: "#2B2B2B",
                  fontFamily: "Expletus Sans",
                  fontSize: "14px",
                  lineHeight: "19px",
                  fontWeight: 500,
                  marginBottom: "8px",
                },
                "& .sub-txt": {
                  marginBottom: "0",
                  color: "#94A3B8",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  letterSpacing: "0.09px",
                },
                "& .MuiAccordionSummary-content": {
                  margin: "0",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                },
                "& .MuiIconButton-root": {
                  padding: "0",
                  margin: "0",
                  "& .MuiSvgIcon-root": {
                    fontSize: "20px",
                    color: "#94A3B8",
                  },
                },
              },
              "& .accordion-details": {
                padding: "0",
                marginTop: "24px",
                "& table": {
                  "& tr": {
                    "& td": {
                      "&.MuiTableCell-root": {
                        color: "#2B2B2B",
                        fontFamily: "Roboto",
                        fontSize: "13px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        padding: "6px 8px 24px",
                        border: "0",
                        "&:first-child": {
                          paddingLeft: "0",
                          width: "43.5%",
                        },
                        "&:nth-child(2)": {
                          textAlign: "center",
                        },
                        "&:last-child": {
                          paddingRight: "0",
                          textAlign: "right",
                        },
                      },
                      "& .open-icon": {
                        display: "inline-block",
                        marginLeft: "3px",
                        position: "relative",
                        top: "2px",
                        "& .MuiSvgIcon-root": {
                          color: "#94A3B8",
                          fontSize: "14px",
                        },
                      },
                    },
                    "& th": {
                      border: "0",
                    },
                    "& .table-heading": {
                      color: "#94A3B8",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "16px",
                      letterSpacing: "0.09px",
                      padding: "0",
                      "&.last": {
                        padding: "8px 0 24px",
                      },
                    },
                  },
                },
              },
            },
          },
          "& .budget-footer": {
            borderTop: "1px dashed rgba(148, 163, 184, 0.37)",
            paddingTop: "20px",
            marginTop: "24px",
            textAlign: "right",
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "16px",
            lineHeight: "22px",
            fontWeight: 400,
          },
        },
        "& .button-wrapper": {
          marginTop: "48px",
          [theme.breakpoints.down("xs")]: {
            marginTop: "32px",
          },
          "&.full-btn": {
            "& .MuiButton-root": {
              width: "100%",
            },
          },
        },
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        color: "#2b2b2b",
        letterSpacing: "0",
        fontWeight: 400,
        fontFamily: "Roboto",
        marginTop: "0",
        marginBottom: "27px",
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .form-info-wrapper": {
        margin: "0 0 28px",
        "&:last-child": {
          margin: "0",
        },
        "& .form-row": {
          display: "flex",
          flexWrap: "wrap",
          margin: "0 -8px",
          "& .form-col": {
            padding: "0 8px",
            flex: "0 0 100%",
            maxWidth: "100%",

            "&.col6": {
              flex: "0 0 50%",
              maxWidth: "50%",
            },
            "& .form-control": {
              marginBottom: "24px",
            },
          },
        },
      },
      "& .form-heading": {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#2B2B2B",
        fontFamily: "Roboto",
        margin: "0 0 16px",
        letterSpacing: "0",
      },
      "& .profile-team": {
        "& .team-label": {
          fontWeight: 400,
          fontSize: "10px",
          lineHeight: "12px",
          textTransform: "uppercase",
          color: "#94A3B8",
          marginBottom: "8px",
          fontFamily: "Roboto",
        },
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#2B2B2B",
        fontWeight: "500",
        "& .modal-link": {
          marginLeft: "3px",
        },
      },
      "& .modal-form-stage": {
        marginTop: "47px",
      },
      "& .current-team": {
        color: "#94A3B8",
        padding: "17px 16px",
        fontSize: "14px",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        border: "1px solid #e8ecf2",
        borderRadius: "6px",
      },
      "& .select-control-deals": {
        "&.multi-select": {
          "&.full-outlined": {
            width: "100%",
            marginBottom: "24px",
            "& .multiselect-label": {
              display: "block",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "18px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              letterSpacing: "0.105px",
            },
            "& .MuiSelect-select": {
              fontFamily: "Roboto",
              fontWeight: 400,
              color: "#2B2B2B",
              padding: "0 40px 0 16px",
              lineHeight: "20px",
              letterSpacing: "0.105px",
              minHeight: "56px",
              "& .assignee-list": {
                marginLeft: "0",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
              },
            },
            "& .MuiInputLabel-outlined.Mui-focused, & .MuiFormLabel-filled": {
              fontSize: "14px !important",
              color:"#94A3B8 !important",
              lineHeight: "16px",
              width: "auto",
              "& ~.MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#e8ecf2",
                },
              },
              "&.Mui-error": {
                color: "rgba(43,43,43,0.87)",
              },
            },
            "& .multiple-clear": {
              position: "absolute",
              fontSize: "15px",
              zIndex: 1,
              color: "#94A3B8",
              top: "50%",
              right: "27px",
              transform: "translateY(-50%)",
            },
          },
          "& .multiselect-label": {
            display: "none",
          },
          "& .MuiInput-formControl": {
            marginTop: "0",
          },
          "& .MuiSelect-select": {
            paddingRight: "40px",
            display: "flex",
            alignItems: "center",
            "& .select-icon": {
              marginRight: "6px",
            },
            "& .assignee-list": {
              display: "flex",
              alignItems: "center",
              marginLeft: "6px",
              "& .assignee-bubble": {
                color: "rgba(43, 43, 43, 0.87)",
                width: "24px",
                border: "2px solid #FFF",
                height: "24px",
                display: "flex",
                position: "relative",
                fontSize: "9.586px",
                background: "#ECF1F4",
                alignItems: "center",
                fontFamily: "Roboto",
                fontWeight: 600,
                lineHeight: "13.42px",
                marginLeft: "-4px",
                borderRadius: "100%",
                letterSpacing: "-0.244px",
                justifyContent: "center",
                "&:first-child": {
                  marginLeft: 0,
                },
              },
            },
          },
          "& .multiple-clear": {
            position: "absolute",
            fontSize: "15px",
            right: "18px",
            top: "3px",
            zIndex: 1,
            color: "#94A3B8",
          },
        },
        "&.status-control": {
          textTransform : "capitalize",
          "&.sky-blue":{
            color: "#12AEF1",
            "& .MuiSelect-select": {
              background: "rgba(18, 174, 241, 0.07)",
              color: "#12AEF1",
            },
            "& .MuiSelect-icon": {
              color: "#12AEF1",
            },
          },
          "&.orange":{
            color: "#F6A318",
            "& .MuiSelect-select": {
              background: "rgba(246, 163, 24, 0.07)",
              color: "#F6A318",
            },
            "& .MuiSelect-icon": {
              color: "#F6A318",
            },
          },
          "&.yellow":{
            color: "#F7E223",
            "& .MuiSelect-select": {
              background: "rgba(247, 226, 35, 0.07)",
              color: "#F7E223",
            },
            "& .MuiSelect-icon": {
              color: "#F7E223",
            },
          },
          "&.green":{
            color: "#00BA88",
            "& .MuiSelect-select": {
              background: "rgba(0, 186, 136, 0.07)",
              color: "#00BA88",
            },
            "& .MuiSelect-icon": {
              color: "#00BA88",
            },
          },
          
          "&.black":{
            color: "#2B2B2B",
            "& .MuiSelect-select": {
              background: "rgba(43, 43, 43, 0.07)",
              color: "#2B2B2B",
            },
            "& .MuiSelect-icon": {
              color: "#2B2B2B",
            },
          },
          
          "&.cancelled": {
            "& .MuiSelect-select": {
              background: "rgba(247, 226, 35, 0.07)",
              color: "#F7E223",
            },
            "& .MuiSelect-icon": {
              color: "#F7E223",
            },
          },
          "&.on-track": {
            "& .MuiSelect-select": {
             
              color: "#12AEF1",

            },
            "& .MuiSelect-icon": {
              color: "#12AEF1",
            },
          },
          "& .MuiSelect-icon": {
            width: "16px",
            height: "16px",
            right: "6px",
            color: "#12AEF1",
            top: "calc(50% - 8px)",
          },
          "& .MuiSelect-select": {
            color: "#12AEF1",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.09px",
            opacity: "1",
            borderRadius: "12px",
            background: "rgba(18, 174, 241, 0.07)",
            padding: "6px 22px 6px 12px",
          },
        },

        "& .select-icon": {
          position: "relative",
          marginRight: "6px",
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          color: "rgba(43, 43, 43, 0.87)",
          opacity: "0.671",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          paddingBottom: "0",
          paddingTop: "0",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .clear-icon": {
            width: "50px",
            height: "50px",
            background: "red",
          },
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
      },
      "& .modal-footer": {
        marginTop: "auto",
        "& .half-btn": {
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          "& .MuiButton-root": {
            width: "auto",
            marginBottom: "0",
            marginRight: "10px",
            "&:last-child": {
              marginRight: "0",
            },
            "&.primary-btn": {
              padding: "12px 38px",
            },
            "&.secondary-btn": {
              minWidth: "140px",
            },
          },
        },
      },
      "& .primary-btn": {
        minHeight: "56px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "17px",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px",
        },
      },
      "& .secondary-btn": {
        minHeight: "56px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "17px",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px",
        },
      },
      "&.filter-dialogbox": {
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
          width: "100%",
        },
        "& .modal-heading": {
          marginBottom: "44px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "26px",
          },
          "& .filter-heading": {
            display: "flex",
            alignItems: "center",
            "& .auth-back": {
              cursor: "pointer",
              marginRight: "8px",
            },
            "& .modal-title ": {
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "24px",
              fontFamily: "Expletus Sans",
            },
          },
          "& .filter-link": {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "19px",
            color: "#4BA3B7",
            fontFamily: "Expletus Sans",
            textDecoration: "none",
          },
        },
      },
      "& .tasklist-member": {
        padding: "0",
        "& .tasklist-li": {
          display: "flex",
          justifyContent: "space-between",
          background: "transparent",
          position: "relative",
          borderBottom: "1px solid #ECF1F4",
          padding: "26px 24px 18px",
          "& .tasklist-left": {
            display: "flex",
            alignItems: "center",
            "& .assignee-info": {
              marginLeft: "12px",
            },
            "& .assignee-profile": {
              width: "38px",
              height: "38px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#ECF1F4",
              borderRadius: "100%",
              color: "rgba(43, 43, 43, 0.87)",
              fontFamily: "Roboto",
              fontSize: "15.485px",
              fontWeight: 600,
              lineHeight: "21.679px",
              letterSpacing: "-0.395px",
            },
            "& .assignee-name": {
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
            },
            "& .assignee-email": {
              fontSize: "12px",
              lineHeight: "16px",
              color: "#94A3B8",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
            },
          },
          "& .tasklist-icon": {
            minWidth: "unset",
            marginLeft: "10px",
          },
          "& .tasklist-hours": {
            textAlign: "right",
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: 600,
            lineHeight: "16px",
            letterSpacing: "0.09px",
            "& .hours": {
              display: "block",
              color: "#94A3B8",
              fontWeight: 400,
              marginTop: "3px",
            },
          },
        },
        "& .tasklist-total": {
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          padding: "16px 24px",
          borderBottom: "1px solid #ECF1F4",
          position: "static",
          "& .total-member": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "19px",
          },
          "& .select-control-deals": {
            "&.sortby-control": {
                borderLeft:"1px solid #E8ECF2",
              "& .MuiSelect-select": {
                paddingRight: "26px",
              },
              "& .MuiSelect-icon": {
                fontSize: "20px",
                width: "20px",
                height: "20px",
                color: "#2B2B2B",
                top: "calc(50% - 11px)",
              },
            },
            "& .MuiSelect-select": {
              color: "#2b2b2b",
              opacity: "1",
              fontSize: "12px",
              letterSpacing: "0",
            },
          },
        },
      },
     
    },
    modalDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
    },
    chooseModalBox: {
      display: "flex",
      justifyContent: "flex-end",
      backdropFilter: "blur(3px)",
    },
    dealOwnersModal: {
      width: "680px",
      height: "100vh",
      backgroundColor: "#ffffff",
      position: "relative",
      overflow: "auto",
      "&.is-create-contact-open":{
        zIndex : "-9",
      },
      // padding: "0px 60px",
      "& .modal-heading": {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginTop: "80px",
        padding: "0px 60px",
        "& .gr-heading-left": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          "& .nav-text": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            marginLeft: "10px",
          },
        },
        "& .heading-left": {
          "& .modal-title": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontFamily: "Expletus Sans",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "30px",
          },
          "& .sub-txt": {
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "26px",
            letterSpacing: "0.105px",
          },
        },
        "& .heading-right": {
          display: "flex",
          alignItems: "center",
          "& .create-contact":{
            color:"#4ba3b7",
            fontSize:"16px",
            textDecoration:"none",
            marginRight:"10px",
          },
          "& .plus-icon": {
            width: "24px",
            height: "24px",
            color: "#94A3B8",
          },
          "& .close-icon": {
            width: "24px",
            height: "24px",
            color: "#94A3B8",
            marginLeft: "16px",
          },
          "& .heading-icon":{
            "& svg":{
              "& path":{
                fill:"#94A3B8",
              }
            }
          }
        },
      },
      "& .modal-title-box": {
        padding: "0px 60px",
        margin: "45px 0px",
        "& .title-txt": {
          color: "var(--brand-color-brand-color-dark, #2B2B2B)",
          fontFamily: "Expletus Sans",
          fontSize: "22px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "26px",
        },
        "& .sub-title-txt": {
          color: "var(--task-completed-color, #2B2B2B)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.09px",
        },
      },
      "& .search-box": {
        margin: "24px 0",
        padding: "0px 60px",
      },
      "& .assign-owner": {
        "& .selected-owner-header": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0px 60px 0",
          paddingBottom:"20px",
          borderBottom:"1px solid #ECF1F4",
          "& .selected-owner-txt": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          },
          "& .remove-contact-txt":{
            color: "#c7263e",
            fontSize: "14px",
            fontStyle: "normal",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            lineHeight: "normal",
            textTransform: "none",
          },
        },
        "& .selected-owner": {
          "& + .un-assign-owner":{
            "& .top-title":{
              borderTop:"1px solid #ECF1F4",
            },
          },
          "& .select-owner-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "var(--background-background-light, #F2F8FC)",
            padding: "8px 60px",
            cursor: "pointer",
            "& .avatar": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "39px",
              width: "39px",
              borderRadius: "19.5px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "21.679px",
                letterSpacing: "-0.395px",
              },
            },
            "& .owner-info": {
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .departments": {
                display: "flex",
                flexWrap: "wrap",
                "& .dep-item": {
                  display: "flex",
                  padding: "6px",
                  marginRight: "16px",
                  alignItems: "flex-start",
                  gap: "8px",
                  borderRadius: "6px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  },
                },
              },
            },
            "& .checkedCirlce": {
              position: "absolute",
              right: 84,
              color: "#4BA3B7",
            },
          },
        },
        "& .selected-contact":{
          "& .select-contact-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "#FFF",
            padding: "15px 0 15px",
            margin:"0 60px 15px",
            borderBottom:"1px solid #ECF1F4",
            cursor: "pointer",
            "& .avatar": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "39px",
              width: "39px",
              borderRadius: "19.5px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "21.679px",
                letterSpacing: "-0.395px",
              },
            },
            "& .contact-info": {
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .departments": {
                display: "flex",
                flexWrap: "wrap",
                "& .dep-item": {
                  display: "flex",
                  padding: "6px",
                  marginRight: "16px",
                  alignItems: "flex-start",
                  gap: "8px",
                  borderRadius: "6px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  },
                },
              },
            },
            "& .checkedCirlce": {
              position: "absolute",
              right: 84,
              color: "#4BA3B7",
            },
          },

        },
      },
      "& .un-assign-owner": {
        margin:"0",
        padding: "0 60px",
        "& .top-title": {
          color: "var(--brand-color-brand-color-dark, #2B2B2B)",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
          // borderTop:"1px solid #ECF1F4",
          paddingTop: "20px",
        },
        "& .top-list": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "20px",
          paddingBottom:"20px",
          borderBottom:"1px solid #ECF1F4",
          "& .count-owner": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            opacity:"0.5",
          },
          "& .select-control": {
            "&.multi-select": {
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                position: "absolute",
                fontSize: "15px",
                right: "18px",
                top: "3px",
                zIndex: 1,
                color: "#94A3B8",
              },
            },
            "& .MuiSelect-select": {
              fontSize: "12px",
              letterSpacing: "0.1px",
              color: "#2b2b2b",
              opacity: "0.671",
              fontWeight: "500",
              fontFamily: "Expletus Sans",
              paddingBottom: "0",
              paddingTop: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                width: "50px",
                height: "50px",
                background: "red",
              },
              "& .MuiBox-root": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
        },
        "& .un-assign-owner-list": {
          paddingTop: "16px",
          "& .select-contact-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "8px 0px",
            "& .avatar": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "39px",
              width: "39px",
              borderRadius: "19.5px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "21.679px",
                letterSpacing: "-0.395px",
              },
            },
            "& .owner-info": {
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .departments": {
                display: "flex",
                flexWrap: "wrap",
                "& .dep-item": {
                  display: "flex",
                  padding: "6px",
                  marginRight: "16px",
                  alignItems: "flex-start",
                  gap: "8px",
                  borderRadius: "6px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  },
                },
              },
            },
            "& .checkedCirlce": {
              position: "absolute",
              right: 60,
              color: "#4BA3B7",
            },
          },
          "& .owner-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "0 0 15px",
            marginBottom:"10px",
            borderBottom:"1px solid #ECF1F4",
            cursor: "pointer",
            "&:last-child":{
              marginBottom:"0",
            },
            "& .avatar": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "39px",
              width: "39px",
              borderRadius: "19.5px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "21.679px",
                letterSpacing: "-0.395px",
              },
            },
            "& .owner-info": {
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .departments": {
                display: "flex",
                flexWrap: "wrap",
                "& .dep-item": {
                  display: "flex",
                  padding: "6px",
                  marginRight: "16px",
                  alignItems: "flex-start",
                  gap: "8px",
                  borderRadius: "6px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  },
                },
              },
            },
            "& .circleCheck": {
              position: "absolute",
              right: 60,
              color: "#E8ECF2",
            },
          },
        },
        "& .bottom-button": {
          bottom: "24px",
          width: "100%",
          textTransform: "none",
          textDecoration: "none",
          color: "white",
          display: "flex",
          height: "48px",
          padding: "8px 24px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "6px",
          background: "var(--brand-color-primary-100, #4BA3B7)",
        },
      },
    },
    popoverStyle: {
      display: "inline-flex",
      height: "auto",
      width: "310px",
      top:"140px",
      flexDirection: "column",
      alignItems: "center",
      flexShrink: 0,
      padding: "10px",
      borderRadius: "12px",
      background: "var(--white, #FFF)",
      boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
      marginTop:"24px",
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& .MuiFormControlLabel-root": {
        opacity: "0.671",
        lineHeight: "20px",
        fontSize: "14px",
        color: "#000000",
        letterSpacing: "0px",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          justifyContent: "space-between",
          width: "100%",
          color: "#2B2B2B",
          opacity: "1",
        },
        "& .MuiFormControlLabel-label": {
          font: "inherit",
        },
      },
      "& .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#d3d9e1",
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "#94A3B8",
        },
      },
      "& .MuiSwitch-root": {
        [theme.breakpoints.down("xs")]: {
          width: "33px",
          padding: "5px",
          height: "20px",
        },
        "& .MuiIconButton-root": {
          [theme.breakpoints.down("xs")]: {
            padding: "6px",
          },
        },
      },
      "& .MuiSwitch-switchBase": {
        color: "#fafafa",
        [theme.breakpoints.down("xs")]: {
          color: "#ffffff",
        },
        "&.Mui-checked": {
          color: "#3691a6",
          [theme.breakpoints.down("xs")]: {
            transform: "translateX(12px)",
          },
          "&+ .MuiSwitch-track": {
            backgroundColor: "#3691a6",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.2))",
        boxShadow: "none",
        [theme.breakpoints.down("xs")]: {
          width: "8px",
          height: "8px",
        },
      },
      "& .MuiSwitch-colorSecondary.Mui-checked":{
        color:'#FFF' 
      },
      "& .view-more-dropdown":{
        width: "310px",
        "& .dropdown-menu-item":{
          display: 'flex',
          padding: '12px 24px',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'stretch',
          "&.close-menu-item":{
            display:"block",
            "& .MuiFormControl-root":{
              width: "100%",
              marginLeft: "0",
              marginBottom: "15px",
              borderRadius: "5px",
              height: "55px",
              lineHeight: "30px",
              padding: "0 15px",
              "& .MuiInput-underline":{
                "&::before":{
                  display:"none",
                },
                "&::after":{
                  display:"none",
                },
                "& .MuiSelect-selectMenu":{
                  lineHeight:"53px",
                  padding:"0",
                  color:"#94A3B8",
                  fontSize:"14px",
                  "&::focus":{
                    backgroundColor:"transparent !important",
                  },
                },
                "& input":{
                  height:"100%",
                  "& + .MuiSvgIcon-root":{
                    color:"rgba(0, 0, 0, 0.54)",
                  },
                },
              }
            },
            "& .rmdp-container":{
              "&> input + div":{
                width:"100%",
              },
              "& .rmdp-wrapper":{
                width:"100%",
                "& .rmdp-calendar":{
                  width:"100%",
                  "& .rmdp-day-picker + div":{
                      width:"100%",
                  }
                },
                "& .rmdp-day-picker>div":{
                  width:"100%",
                },
                "& .rmdp-day":{
                  "& span":{
                    width:"25px",
                    height:"25px",
                    fontSize:"12px"
                  }
                },
                "& .rmdp-week-day":{
                  width:"25px",
                  fontSize:"12px",
                }
              }
            }
          },
          "& .deal-amount-header":{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom : '16px',
            "& .title":{
              fontFamily: 'Expletus Sans',
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
              color: "#2B2B2B",
            },
            "& .reset":{
              fontFamily: 'Expletus Sans',
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
              color: "#4BA3B7",
              cursor: "grab",
            },
            "& .no-reset":{
              fontFamily: 'Expletus Sans',
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
              color: "#94A3B8",
              cursor: "grab",
            }
          },
          "& .slider-input":{
            display: "flex",
          },
          "& .deal-amount-slider":{
            //background: "red",
            "& .MuiSlider-root":{
              color: "#4BA3B7",
              padding: "0 0 19px",
              marginBottom: "35px",
            },
            "& .MuiSlider-rail":{
              height: "4px",
              opacity: 1,
              borderRadius: "6px",
              backgroundColor: "#ECF1F4",
            },
            "& .MuiSlider-track":{
              height: "4px",
              boxShadow: "none",
              borderRadius: "6px",
              backgroundColor: "#4BA3B7",
            },
            "& .MuiSlider-thumb":{
              width: "20px",
              border: "4px solid #4BA3B7",
              height: "20px",
              boxShadow: "none",
              marginTop: "-7px",
              marginLeft: "-10px",
              backgroundColor: "white",
            },

          },
          "& .primary-btn":{
            width: '100%',
          }
        },
        "& .MuiFormControl-root":{
          width:"280px",
          marginLeft:"20px"
        },
      
        "& .dropdown-menu-item-column":{
          display: 'flex',
          padding: '8px 16px',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'stretch',
        },
        "& .MuiSvgIcon-root":{
          color:"#E8ECF2"
        },
        // "& .dropdown-menu-item-checked":{
        //   color:"#4BA3B7",
        // }, 
        "& .MuiTouchRipple-root": {
          display: "none",
        },      
        "& .dropdown-menu-item-text":{
          color: '#2B2B2B',
          fontFamily: 'Expletus Sans',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
        }
      },
    },
    productListPopoverStyle: {
      display: "inline-flex",
      height: "auto",
      width: "500px",
      marginLeft:"-160px",
      flexDirection: "column",
      flexShrink: 0,
      padding: "20px",
      borderRadius: "24px",
      background: "var(--white, #FFF)",
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
      marginTop:"68px",
      maxHeight: "calc(100% - 90px)",
      "& .view-selected-item-dropdown-list":{
        "& .headding":{
          display:"flex",
          justifyContent:"space-between",
          alignItems:"center",
          "& .headding-txt":{
            color: 'var(--Task-Completed-Color, #2B2B2B)',
            fontFamily: 'Expletus Sans',
            fontSize: '22px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '26px',
          }
        },
        "& .sub-headding-txt":{
          color: 'var(--Task-Completed-Color, #2B2B2B)',
          fontFamily: 'Roboto',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '17px',
          marginTop:"6px",
        },
        "& .product-container":{
          padding:"24px 16px",
          borderBottom: "1px solid #E8ECF2",
          display:"flex",
          justifyContent:"space-between",
          alignItems:"center",
         "& .categoryImg":{
          width:"56px",
          height:"56px",
         },
         "& .delete-content":{
          display:"flex",
          justifyContent:"space-between",
          alignItems:"center",
          "& .delete-img":{
            width:"24px",
            height:"24px",
            position:"absolute",
            right:"32px",
          },
         },
         "& .product-content":{
          "& .product-name": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px" /* 150% */,
            letterSpacing: "0.15px,",
          },
          "& .product-item": {
            color: "#94A3B8",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            letterSpacing: "0.4px",
            margin:"6px 0",
          },
         },
         "& .product-add-remove":{
          display:"flex",
          justifyContent:"space-between",
          alignItems:"center",
          marginTop:"-32px",
          "& .secondary-btn": {
            width:"32px",
            height:"32px",
            minWidth: "48px",
            color: "#4BA3B7",
          },
          "& .product-count":{
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px" /* 150% */,
            letterSpacing: "0.15px,",
            margin:"0 8px",
          }
         },
         "& .wrap-price": {
          display: "flex",
          // flexDirection: "column",
          alignItems: "flex-end",
          "& .price-btn": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            textTransform: "none",
            "& .retail-price-txt": {
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              letterSpacing: "0.4px",
              whiteSpace:"nowrap",
            },
            "& .icon-down": {
              width: "24px",
              height: "24px",
              color: "#94A3B8",
            },
          },
          "& .price-txt": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px" /* 150% */,
            letterSpacing: "0.15px,",
          },
        },
        }
      }
    },
    productsWrapper: {
      display: "flex",
      flexDirection: "column",
      background:"#fff",
      overflow: 'auto',
      width:"100%",
      height: "100%",

      "& .product-top-header": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        height: "84px",
        padding: "12px 24px",
        borderBottom: "1px solid #E8ECF2",

        "& .top-left": {},
        "& .top-right": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",

          "& .calendar-icon": {
            height: "24x",
            width: "24px",
            marginRight: "12px",
            
          },
          "& .selected-txt": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            whiteSpace: "nowrap",
            cursor:"pointer",
          },
          "& .add-to-contact-btn": {
            textTransform: "none",
            borderRadius: "6px",
            background: "var(--brand-color-disable, #92C7D3)",
            width: "147px",
            height: "48px",
            padding: "8px 14px",
            color: "#ffffff",
            marginLeft: "32px",
          },
          "& .add-to-contact-btn-active": {
            textTransform: "none",
            borderRadius: "6px",
            background: "var(--brand-color-disable, #4BA3B7)",
            width: "147px",
            height: "48px",
            padding: "8px 14px",
            color: "#ffffff",
            marginLeft: "32px",
          },
        },
      },
      "& .main-wrapper": {
        padding:"0 90px",
        [theme.breakpoints.down("xs")]: {
          padding:"0 30px",
        },
        "& .top-main-wrapper": {
          display: "flex",
          flexDirection: "column",
          "& .title-section": {
            display: "flex",
            flexDirection: "column",
            padding: "67px 0px 0",
            "& .choose-item-txt": {
              color: "var(--brand-color-brand-color-dark, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "22px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "26px",
            },
            "& .choose-item-sub-txt": {
              color: "#505050",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "24px",
              letterSpacing: "0.25px",
              marginTop: "16px",
            },
          },
          "& .filter-section": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "16px",
            borderBottom: "1px solid #E8ECF2",
            "& .left-box": {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              // paddingLeft: "184px",
              paddingRight: "20px",
              // width: "580px",
              // borderRight: "1px solid #E8ECF2",

              "& .filter-txt": {
                color: "#505050",
                fontFamily: "Expletus Sans",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.25px",
              },
              "& .clear-all-btn": {
                color: "var(--brand-color-primary-100, #4BA3B7)",
                textAlign: "right",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "0.1px",
                textTransform: "none",
                whiteSpace: "nowrap",
              },
            },
            "& .right-box": {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              // width: "calc(100vw - 580px)",
              paddingRight: "12px",

              "& .check-box": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",

                "& .chk-select-all": {
                  color: "#94A3B8",
                  width: "24px",
                  height: "24px",
                  marginRight: "6px",
                },
                "& .selected-txt": {
                  color: "rgba(0, 0, 0, 0.60)",
                  fontFamily: "Expletus Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "24px",
                  letterSpacing: "0.1px",
                  whiteSpace: "nowrap",
                },
              },
              "& .search-sort-box": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                "& .form-control": {
                  marginBottom: 0,
                  "& .MuiTextField-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    height: "48px",
                    marginRight: "68px",
                  },
                },
                "& .top-right": {
                  "& .select-control": {                    
                    "& .MuiInput-underline:before": {
                      display: "none",
                    },
                    "& .MuiInput-underline:after": {
                      display: "none",
                    },
                    "& .MuiInputBase-root": {
                      color:"rgb(109 106 106 / 87%)",
                      fontFamily: "Expletus Sans",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "24px",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  },
                },
              },
              "& .select-control": {
                "&.multi-select": {
                  "& .MuiInput-formControl": {
                    marginTop: "0",
                  },
                  "& .multiselect-label": {
                    display: "none",
                  },
                  "& .MuiSelect-select": {
                    paddingRight: "40px",
                  },
                  "& .multiple-clear": {
                    position: "absolute",
                    right: "18px",
                    fontSize: "15px",
                    zIndex: 1,
                    top: "3px",
                    color: "#94A3B8",
                  },
                },
                "& .MuiSelect-select": {
                  letterSpacing: "0.1px",
                  fontSize: "14px",
                  color: "#2b2b2b",
                  opacity: "0.671",
                  fontFamily: "Expletus Sans",
                  paddingBottom: "0",
                  fontWeight: "500",
                  paddingTop: "0",
                  paddingRight: "2px",
                  "&:focus": {
                    backgroundColor: "transparent",
                  },
                  "& .clear-icon": {
                    height: "50px",
                    background: "red",
                    width: "50px",
                  },
                  "& .MuiBox-root": {
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    "& .select-icon": {
                      marginRight: "6px",
                    },
                  },
                },
                "& .MuiInput-underline:after": {
                  display: "none",
                },
                "& .MuiInput-underline:before": {
                  display: "none",
                },
              },
            },
          },
        },
        "& .main-content-wrapper": {
          display: "flex",
          flexDirection: "row",
          "& .left-wrapper": {
            display: "flex",
            flexDirection: "column",
            // width: "580px",
            // height: "50vh",           

            "& .section-header": {
              display: "block",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottom: "1px solid #E8ECF2",
              marginBottom:"30px",
                "& .select-control": {
                  "& .MuiSelect-select": {
                    paddingRight: "0",
                    "& .down-arrow": {
                      color: "rgba(43, 43, 43, 0.67)",
                    },
                  },
                },
                "& .MuiAccordion-root": {
                  borderRadius: "0",
                  boxShadow: "none",
                  // margin: "0 0 10px",
                  "&:before": {
                    display: "none",
                  },
                  "& .MuiAccordionSummary-root": {
                    marginBottom: "28px",
                    padding: "0",
                    minHeight: "unset",
                    [theme.breakpoints.down("sm")]: {
                      // marginBottom: "16px",
                    },
                    "& .MuiAccordionSummary-content": {
                      margin: 0,
                    },
                    "& .MuiIconButton-root": {
                      marginRight: "0",
                      padding: "0",
                      "& .MuiSvgIcon-root": {
                        width: "20px",
                        height: "20px",
                        fontSize: "20px",
                        color: "#94A3B8",
                      },
                    },
                  },
                  "& .MuiAccordionDetails-root": {
                    flexDirection: "column",
                    padding: "0",
                    display: "flex",
                  },
                  "& .form-control":{
                    margin: "-16px 0px 8px 0",
                  }
                },               
              "& .section-title": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "0.1px",
              },
              "& .hide-content-section-btn": {
                margin: 0,
                minWidth: 24,
              },
            },
            "& .form-control": {
              "& .MuiTextField-root": {
                "& fieldset": { border: "none" },
                borderBottom: "1px solid #E8ECF2",
                height: "48px",
              },
            },
            "& .wrap-items": {
              "& .product-item": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // marginBottom: "8px",

                "& .chk-item": {
                  color: "#94A3B8",
                  marginRight: "8px",
                },
                "& .item-txt": {
                  color: " #2B2B2B",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "24px" /* 171.429% */,
                  letterSpacing: "0.25px",
                },
              },
            },
            "& .wrap-expand": {
              paddingLeft: "18px",
              "& .expand-list-btn": {
                textTransform: "none",
                color: "var(--brand-color-primary-100, #4BA3B7)",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "0.25px",
              },
            },
          },
          "& .right-wrapper": {
            display: "flex",
            flexDirection: "row",
            columns: 3,
            width: "calc(100vw - 580px)",
            height: "50vh",
            flex: 1,
            padding: "10px 0px 0px 0px",
            "& .grid-container":{            
            "& .left-side-info":{
              paddingTop:"35px",
              borderRight: "1px solid #E8ECF2",
            },
            "& .right-side-info":{
              paddingLeft:"35px",
              paddingTop:"40px",
            }
            },

            "& .product-list-item": {
              display: "flex",
              flexDirection: "column",
              flex: 1,
              border: "1px solid #E8ECF2",
              borderRadius: "12px",
              padding: "16px",
              minHeight: "203px",

              "& .item-top": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                "& .check-section":{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "& .instock-quantity":{
                    color: 'var(--Gray, rgba(43, 43, 43, 0.87))',
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0.09px',
                    background: '#F2F8FC',
                    borderRadius: '6px',
                    padding: '2px 8px',
                    whiteSpace: "nowrap",
                  },
                },
                "& .left-top": {
                  display: "flex",
                  flexDirection: "column",
                  
                  "& .product-img": {
                    height: "68px",
                    width: "68px",
                    marginBottom: "25px",
                  },
              
                  "& .wrap-under-img": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    "& .dot-img": {
                      width: "6px",
                      height: "6px",
                      margin: "0 6px",
                    },
                    "& .star-icon": {
                      width: "12px",
                      height: "11.4px",
                      marginLeft: "6px",
                    },
                  },
                },

                "& .right-top-chk": {
                  color: "#94A3B8",
                },
              },
              "& .item-bottom-info": {
                marginTop: "25px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",

                "& .wrap-name": {
                  flexDirection: "column",
                  display: "flex",
                  "& .dot-section":{
                   display:"flex",
                   "& .product-info":{
                     fontFamily: "Roboto",
                     color: "rgba(0, 0, 0, 0.38)",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontStyle: "normal",
                    letterSpacing: "0.4px",
                    lineHeight: "normal",
                   },
                   "& .product-dot-info":{
                    color: "rgba(0, 0, 0, 0.38)",
                    fontSize: "12px",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    letterSpacing: "0.4px",
                    marginLeft:"6px",
                    "&:before": {
                      content: '""',
                      width: "8px",
                      borderRadius: "100%",
                      height: "8px",
                      display: "inline-block",
                      background: "#ECF1F4",
                      marginRight: "8px",
                    },
                   }
                  },
                  "& .product-name-txt": {
                    fontFamily: "Roboto",
                    color: "var(--gray, rgba(43, 43, 43, 0.87))",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    margin:"6px 0"
                  },
                  "& .product-additional-info": {
                    color: "rgba(0, 0, 0, 0.38)",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    letterSpacing: "0.4px",
                  },
                },
                "& .wrap-price": {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  "& .price-btn": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    textTransform: "none",
                    marginTop:"20px",
                    "& .retail-price-txt": {
                      color: "rgba(0, 0, 0, 0.38)",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      letterSpacing: "0.4px",
                      whiteSpace:"nowrap",
                    },
                    "& .icon-down": {
                      width: "24px",
                      height: "24px",
                      color: "#94A3B8",
                    },
                  },
                  "& .price-txt": {
                    color: "var(--gray, rgba(43, 43, 43, 0.87))",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px" /* 150% */,
                    letterSpacing: "0.15px,",
                    marginRight:"8px",
                  },
                },
              },
            },
          },
        },
      },
    },
    productListViewModal: {
      height: "100vh",
      width: "680px",
      backgroundColor: "#ffffff",
      overflow: "auto",
      position: "relative",
      padding:" 45px 60px",
      marginLeft: "auto",
      "& .modal-heading": {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "row",
        marginTop: "20px",
        justifyContent: "space-between",
        marginBottom: "48px",        
        "& .title-txt": {
          color: 'rgba(43, 43, 43, 0.87)',
          fontSize: '24px',
          fontFamily: 'Expletus Sans',
          fontStyle: 'normal',
          lineHeight: 'normal',         
          fontWeight: 400,
        },
        "& .heading-left": {
          "& .modal-title": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontFamily: "Expletus Sans",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "30px",
          },
          "& .sub-txt": {
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "14px",
            lineHeight: "26px",
            fontStyle: "normal",
            letterSpacing: "0.105px",
            fontWeight: "400",
          },
        },
        "& .heading-right": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          "& .heading-icon":{
            marginLeft: "12px",
            "& .pencil-icon": {
              width: "24px",
              height: "24px",
              color: "#94A3B8",
            },
            "& .close-icon": {
              width: "24px",
              height: "24px",
              color: "#94A3B8",
            },
          },
        },
      },
      "& .product-card":{
        display:"flex",
        justifyContent:"space-between",
        borderBottom: "1px solid #E8ECF2",
        marginBottom:"24px",
        "& .product-img":{
          width:"56px",
          height:"56px",
         },
         "& .card-content":{
           "& .headding":{
            color: 'var(--Brand-Color-Brand-Color-Dark, #2B2B2B)',
            fontFamily: 'Expletus Sans',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal'
           },
           "& .table-content":{
            "& .table-content-2":{             
              marginBottom:"16px"
             },
             "& .table-content-2 th":{
              color: 'var(--text-Secondary, #94A3B8)',
              fontFamily: 'Roboto',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              padding:"6px 24px 6px 0",
              textAlign:"start",
              marginBottom:"16px"
             },
             "& .table-content-2 td":{
              color: 'var(--Brand-Color-Brand-Color-Dark, #2B2B2B)',
              textAlign: 'start',
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '16px',
              letterSpacing: '0.09px',
              padding:"0px 24px 0px 0",
              marginBottom:"16px"
             },
           },
           "& .retail-content":{
            display:"flex",
            marginTop:"8px",
            marginBottom:"16px",
            "& .item-quantity":{
              color: 'var(--Gray, rgba(43, 43, 43, 0.87))',
              fontFamily: 'Roboto',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              marginRight:"4px",
            },
            "& .retail-price-txt":{
              color: 'var(--text-Secondary, #94A3B8)',
              fontFamily: 'Roboto',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal'
            },
           },
         },
         "& .price-txt":{
          "& .price":{
            color: 'var(--Brand-Color-Brand-Color-Dark, #2B2B2B)',
            fontFamily: 'Expletus Sans',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal'
          },
         },
      }
     
      
    },
   
    createContactBoxDeals: {
      minWidth: "auto",
    },
    
   
  });
// Customizable Area End
