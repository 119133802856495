Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = 'application/json'
exports.getApiMethod = 'GET'
exports.postApiMethod = 'POST'
exports.deleteApiMethod = 'DELETE'

exports.getOrdersApiEndPoint = 'shopping_cart/orders'
exports.createOrderItemApiEndPoint = 'shopping_cart/order_items'
exports.deleteOrderItemApiEndPoint = 'shopping_cart/orders'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.cartTxt="Cart";
exports.cartSubTxt="Below are the cart items, you can adjust the quantity.";
exports.removeTxt="Remove";
exports.itemSelectedTxt="{{count}} item selected";
exports.itemsSelectedTxt="{{count}} items selected";
exports.subtotalTxt="Subtotal";
exports.itemsTxt="items";
exports.itemsTxtCapital="Items";
exports.itemTxt="Item";
exports.noteTxt="Note";
exports.notesMsgTxt="Subscription services will incur a recurring charge.";
exports.subscriptionServiceTxt ="Subscription Service";
exports.payNowTxt="Pay Now";
exports.orderTotalTxt="Order Total";
exports.monthelyTxt="Monthly";
exports.ExclTaxtTxt="Excl. Tax";
exports.addToCartApiId="/bx_block_shopping_cart/add_to_carts";
exports.deleteCart="/bx_block_shopping_cart/delete_cart_item";
exports.viewShopTxt="View Shop";
exports.cartEmptySubTxt ="Please add services from the Shop";
exports.cartEmptyTxt="Your cart is empty";
exports.putApiMethod = 'PUT';
exports.successPaymentTxt="Payment Successful";
exports.successPaymentSubTxt="Thank you for your order. We will send your order details via email. You can also find your Order History within your Profile.";
exports.backTxt="Back";
exports.returnToShop="Return to EtOH Shop";
exports.viewAdditionalDetails="Fill out additional details";
exports.paymentApiPath="/bx_block_payments/stripe_payments/create_checkout_session";
exports.createOrderApiPath="/bx_block_shopping_cart/create_order";
exports.paymentStatus="/bx_block_payments/stripe_payments/check_payment_status"
// Customizable Area End
