import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { toast } from "react-toastify";
import { warningIcon } from "./assets";
import { CardMedia } from "@material-ui/core";
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl: any;
  viewAllTickets:boolean;
  viewSingleTicket:boolean;
  openNewTicket:boolean;
  description:string;
  ticketTitle:string;
  category:string;
  categoryFilter:string;
  fileDrop: boolean;
  isFileValidType: boolean;
  isPause:boolean;
  isCancel:boolean;
  filesUploaded: any;
  isDeleteFile: boolean;
  fileSelected: FileList | null;
  isLoading:boolean;
  progress: number;
  skipped: Set<number>;
  timeRemain: number;
  imageDataUrl:any;
  videoDataUrl:any;
  onFilters:boolean;
  date:any;
  status:any;
  ticketList:any;
  visibleItems: number,
  searchValue:string,
  sortValue:string,
  viewId:string,
  singleTicketData:any;
  isSuccess:boolean;
  searchOptions:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TicketSystemController extends BlockComponent<
    Props,
    S,
    SS
> {
  // Customizable Area Start
  formRef: React.RefObject<HTMLFormElement>;
  fileRef: React.RefObject<HTMLInputElement>;
  userSessionData: any;
  userToken: any;
  currentParser: any;
  curProgress: number = 0;
  myRef: any;
  getAllTicketsRequestId: string = "";
  getSingleTicketsRequestId:string = "";
  markAsResolvedTicketRequestId:string = "";
  createTicketRequestId:string="";
  getTicketNameOptionsRequestId:string="";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleSubmitTicket = this.handleSubmitTicket.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl: null,
      viewAllTickets:false,
      viewSingleTicket:false,
      openNewTicket:false,
      description:"",
      ticketTitle:"",
      category:"",
      fileDrop: false,
      isFileValidType: false,
      isPause:false,
      isCancel:false,
      filesUploaded: [],
      isDeleteFile: false,
      fileSelected: null,
      isLoading:false,
      progress: 0,
      skipped: new Set(),
      timeRemain: 100,
      imageDataUrl:"",
      videoDataUrl:"",
      onFilters:false,
      date:"",
      status:"",
      categoryFilter:"",
      ticketList:[],
      visibleItems: 3,
      searchValue:"",
      sortValue:"",
      viewId:"",
      singleTicketData:[],
      isSuccess:false,
      searchOptions:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.formRef = React.createRef();
    this.fileRef = React.createRef();
    this.userSessionData = sessionStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.error) {       
        this.createToastNotificationError("Something wents wrong");
        this.setState({ isLoading: false });
        return;
      }else{
        switch (apiRequestCallId) { 
          case this.getAllTicketsRequestId:
            this.setState({ isLoading: false });
            this.setState({ ticketList: responseJson.data});          
            break;
          case this.getSingleTicketsRequestId:
            this.setState({ isLoading: false });
            this.setState({ singleTicketData: responseJson.ticket.data.attributes});          
            break;
          case this.markAsResolvedTicketRequestId:
            if(responseJson.data){
              this.createToastNotificationSuccesss("Marked as resolved successfully")
              this.setState({ isLoading: false , viewSingleTicket: false });  
              this.getAllTickets();      
            }
            break;
          case this.getTicketNameOptionsRequestId:
              this.getNameData(responseJson)
            break;
          case this.createTicketRequestId:
            this.createResponse(responseJson.data);
            this.getTicketNameOptions();  
            break;
          default:
            this.parseApiCatchErrorResponse(errorResponse);
            break;
        }
      }
    }   
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
   this.getAllTickets();
   this.getTicketNameOptions();
   const lang = localStorage.getItem("lang") ?? "en"; 
   await i18n.changeLanguage(lang);
  }

  t(key:any) {
    return i18n.t(key, { ns: "translation" } )
  }

  getNameData=(response:any)=>{
    const uniqueNames = new Set<string>();

    const displayNameList = response.data
      .filter((item: any) => {
        if (!uniqueNames.has(item.attributes.name)) {
          uniqueNames.add(item.attributes.name);
          return true;
        }
        return false;
      })
      .map((item: any) => ({
        id: item.id,
        name: item.attributes.name,
      }));
    this.setState({ searchOptions: displayNameList });
  }

  createResponse = (response:any)=>{
    if(response){
      this.setState({ isLoading: false, openNewTicket:false, isSuccess:true }); 
      this.getAllTickets();  
    }else{
      this.setState({ isLoading: false});
      this.createToastNotificationError("Please enter all fields")
    }
  }
  handleSuccessClose=()=>{
    this.setState({isSuccess:false})
  }
  createToastNotificationError = (toastMesssage: string) => {
    toast.error(toastMesssage, {
      position: toast.POSITION.TOP_CENTER,
      icon: () => (
        <CardMedia component="img" src={warningIcon} alt="emptydata" />
      ),
    });
  };
  createToastNotificationSuccesss = (toastMesssage: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMesssage}</div>
      </div>
    );
  };
  
  getAllTickets=()=>{
    const newValue = this.state.sortValue;
    let processedValue = '';
   
    switch (newValue) {
      case 'Status':
        processedValue = 'status';
        break;
      case 'Recently added':
        processedValue = 'recently_added';
        break;
    }
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    let formattedStartDate = "";
    let formattedEndDate = "";

    if (this.state.date !== null && this.state.date.length > 0) {
      const startDate = new Date(this.state.date[0]);
      formattedStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`;
  
      if (this.state.date.length === 1) {
        formattedEndDate = formattedStartDate;
      } else {
        const endDate = new Date(this.state.date[1]);
        formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`;
      }
    }
    
    let endpoint=`${configJSON.ticketPath}?name=${this.state.searchValue}&sort_by=${processedValue}&category=${this.state.categoryFilter}&status=${this.state.status}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllTicketsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getSingleTicket=(id:any)=>{   
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    let endpoint=`${configJSON.viewSingleTicktePath}?ticket_id=${id}`
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleTicketsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getTicketNameOptions=()=>{   
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTicketNameOptionsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.nameOptionsPath
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  markAsResolved=()=>{    
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    let endpoint=`${configJSON.markAsResolvedPath}?id=${this.state.viewId}&status=2`
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsResolvedTicketRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethodType
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSubmitTicket=()=> {
    const formData = new FormData();
    formData.append("ticket[name]", this.state.ticketTitle);
    formData.append("ticket[description]", this.state.description);
    formData.append("category", this.state.category);
    formData.append("status", this.state.status);
    if(this.state.filesUploaded){
    for (const file of this.state.filesUploaded) {
      formData.append("[:ticket][:attachments]", file);
     }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      token: this.userToken.meta.token,
    };

    this.createTicketRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ticketPath}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleOpenMyTicket = (
    event: React.MouseEvent<HTMLButtonElement> | null
  ) => {
    if (event) {
      this.setState({
        anchorEl: event.currentTarget,
      });
    }
  };
  handleCloseMyTicket = () => {
    this.setState({ anchorEl: null });
  };
  handleBackBtn = () => {
    window.location.href="/dashboard"
  };
  backToHelpCenter=()=>{
    window.location.href="/help-center"
  }
  handleOpenViewAllTickets=()=>{
    this.setState({ viewAllTickets:true }, () => {
      this.getAllTickets(); 
    });
  }
  handleCloseViewAllTickets=()=>{
    this.setState({ viewAllTickets:false,searchValue:"" }, () => {
      this.getAllTickets(); 
    });
  }
  handleOpenViewSingleTicket=(id:any)=>{
    this.setState({viewId:id})
    this.setState({viewSingleTicket:true})
    this.getSingleTicket(id)
  }
  handleCloseViewSingleTicket=()=>{
    this.setState({viewSingleTicket:false})
  }
  handleNewTicket=()=>{
    this.setState({openNewTicket:true})
  }
  handleNewTicketClose=()=>{
    this.setState({openNewTicket:false})
  }
  handleInputChange = (event:any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  onBrowse = () => {
    if (this.fileRef.current !== null) {
      this.fileRef.current.click();
    }
  };
  handleOnDrop = (files: FileList | null, event?: any) => { 
    if(files){
      const filesArray = Array.from(files);
      // this.setState({
      //   filesUploaded: filesArray
      // })
      this.setState((prevState) => ({
        filesUploaded: [...prevState.filesUploaded, ...filesArray]
      }), () => {
        console.log(this.state.filesUploaded, '=====>filesUploaded');
      });
    }
   
  };
  handleFiltersOPen=()=>{
    this.setState({onFilters:true})
  }
  handleFiltersClose=()=>{
    this.setState({onFilters:false})
  }
  chooseDate = (date: any) => {  
    console.log("date test",date)
    if (date) {
      this.setState({date: date});
    }
  };
  handleFiltersInputChange = (event:any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  applyFilters =()=>{
    this.getAllTickets(); 
    this.handleFiltersClose();
  }
  resetFilters =()=>{
    this.setState({
      date:"",
      status:"",
      categoryFilter:"",
    })
  }
  handleViewMore = () => {
    this.setState((prevState) => ({
      visibleItems: prevState.visibleItems + 3,
    }));
  };

  handleSearch = (
    value: any,
    reason?:any,
  ) => {
  let searchValue = '';

  if (typeof value === 'string') {
    searchValue = value;
  } else if (value && value.name) {
    searchValue = value.name;
  } else if (typeof reason === 'string') {
    searchValue = reason;
  }
  this.setState({ searchValue }, () => {
    this.getAllTickets(); 
  });
  }; 
  handleSort= (text: string) => {
    this.setState({ sortValue: text, isLoading:true}, () => {
      this.getAllTickets(); 
    });
  };
  pauseUpload = () => {
    if (!this.state.isPause) {
      this.currentParser.pause();
    }
    this.setState(
      {
        isPause: !this.state.isPause,
      },
      () => {
        if (this.currentParser != null) {
          if (!this.state.isPause) {
            this.currentParser.resume();
          }
        }
      }
    );
  };
  cancelParsingAction = (index:any) => {
    if (this.currentParser != null) {
      this.currentParser.abort();
      this.currentParser = null;
    }
  
    if (this.formRef.current != null) {
      this.formRef.current.reset();
    }
    
    const updatedFiles = [...this.state.filesUploaded];
    updatedFiles.splice(index, 1);
  
    this.setState({
      isCancel: true,
      isLoading: false,
      filesUploaded: updatedFiles,
      fileSelected: null,
    });
  };
  cancelUploadAction = () => {
    if (this.formRef.current != null) {
      this.formRef.current.reset();
    }

    this.setState({
      isCancel: true,
      isLoading: false,
      filesUploaded: null,
      fileSelected: null,      
    });
  };
  formatTicketDate = (dateString: any) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };
 formatTicketWithDateTime = (dateString:any) => {
    const date = new Date(dateString);        
      
    const dateOptions: any = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };

    const timeOptions: any = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };

    const formattedDate = `${date.toLocaleDateString('en-US', dateOptions)} ${date.toLocaleTimeString('en-US', timeOptions)}`;
      return formattedDate;
    };
    goToChat=()=>{
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "LiveChat2"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
  // Customizable Area End
}
