import React, { Component } from "react";

export interface Props {
  classes: any;
}

export interface State {
  selectedTab: string;
}

class TaskOwnerDashboardController extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedTab: "unfinished", 
    };
  }

  handleTabClick = (tabName: string) => {
    this.setState({ selectedTab: tabName });
  };


}

export default TaskOwnerDashboardController;
