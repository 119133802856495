//Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardMedia,
  Popover,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import { customStyles } from "./EquizStyles.web";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CardContent from "@material-ui/core/CardContent";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import { Chart } from "react-charts";

import EquizDasboardControllerWeb, {
  Props, configJSON,
} from "./EquizDashboardController.web";
import {
  emptyQuiz,
  scoreA,
  scoreB,
  scoreC,
  scoreD,
  scoreE,
  sort,
  tuneVertical,
  dotVertical,
  starBlack,
  sortVariant,
  checkBoxFilled,
  checkBoxEmpty,
  unCheckedA,
  unCheckedB,
  unCheckedC,
  unCheckedD,
  unCheckedE,
  filterLogo
} from "./assets";
import CreateQuiz from "./CreateQuizModal.web";
import EQuizResults from "./EquizResultsModal.web";
import GradeScoreModal from "./GradeScoreModal.web";
import SimulationModeScore from "./SimulationModeScore.web";
import ShareQuizScore from "./ShareQuizScoreModal.web";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import SortQuizWeb from "./SortQuiz.web";
import FullScreenMode from "./FullScreenMode.web";
import EScoreDatePicker from "../../../components/src/EScoreDatePicker.web";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import WebLoader from "../../../components/src/WebLoader.web";
const blankArr: any = [];

const getLabel = (value: any, def: string, icon: any, t:any) => {
  const selectedItemCount = value.length;

  if (selectedItemCount === 0) {
    return (
      <>
        {icon} {def}
      </>
    );
  } else if (selectedItemCount === 1) {
    return (
      <>
        {icon} {value}
      </>
    );
  } else if (selectedItemCount > 1) {
    return (
      <>
        {icon} {selectedItemCount} {t("items selected")}
      </>
    );
  }
};

const checkboxData = [
  { id: 'A', unCheckedSrc: unCheckedA, checkedSrc: scoreA },
  { id: 'B', unCheckedSrc: unCheckedB, checkedSrc: scoreB },
  { id: 'C', unCheckedSrc: unCheckedC, checkedSrc: scoreC },
  { id: 'D', unCheckedSrc: unCheckedD, checkedSrc: scoreD },
  { id: 'E', unCheckedSrc: unCheckedE, checkedSrc: scoreE },
];

const formatDate = (dateString: any) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};

export class EquizDashboardWeb extends EquizDasboardControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, isMobile } = this.props;
    const quizTitle = this.state.reTakeQuiz.quizTitle;
    const {
      submittedquizList,
      filteredDataQuiz,
      filteredlibraryQuiz,
      isFilteringQuizLibrary,
      isFilteringQuiz,
      quizList,
      currentPage,
      itemsPerPage,
    } = this.state;
    const pageURL = window.location.pathname;
    const selectedQuizId = this.state.selecteditem;
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const listSubmittedQuizes = () => {
      let lists = [];
      if (isFilteringQuiz) {
        if (filteredDataQuiz.length > 0) {
          const displayedQuizzes = filteredDataQuiz.slice(startIndex, endIndex);
          lists = displayedQuizzes;
        } else {
          return <Box className="no-member">{this.t("No quizzes found")}</Box>;
        }
      } else {
        const displayedQuizzes = submittedquizList.slice(startIndex, endIndex);
        lists = displayedQuizzes;
      }

      const updated_lists = lists.map((quiz: any) => {
        return (
          <Card className="escore-card" key={quiz.id}>
            <CardContent className="content-card">
              <Box className="quiz-score">
                <Box className="score">
                  {quiz.attributes.score === "A" && (
                    <img src={scoreA} alt="emptydata" />
                  )}
                  {quiz.attributes.score === "B" && (
                    <img src={scoreB} alt="emptydata" />
                  )}
                  {quiz.attributes.score === "C" && (
                    <img src={scoreC} alt="emptydata" />
                  )}
                  {quiz.attributes.score === "D" && (
                    <img src={scoreD} alt="emptydata" />
                  )}
                  {quiz.attributes.score === "E" && (
                    <img src={scoreE} alt="emptydata" />
                  )}
                </Box>
                <Typography className="score-text">{this.t("is your E-score")}</Typography>
              </Box>
              <Box className="right-content">
                <Box
                  className="quiz-title"
                  onClick={() => this.handleQuizTitleClick(quiz.id)}
                  data-test-id={`quiz-title-click-${quiz.id}`}
                >
                  {quiz.attributes.title}
                </Box>
                <Box className="quiz-date">
                  {formatDate(quiz.attributes.most_recent_submission_date)}
                </Box>
              </Box>
              <Box className="score-menu">
                <Button
                  variant="text"
                  onClick={(e) => this.handleOpenScoreMenu(e, quiz.id)}
                >
                  <CardMedia
                    component="img"
                    className="img-dot"
                    image={dotVertical}
                  />
                </Button>
                <Popover
                  open={this.state.openedPopoverId === quiz.id}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleCloseScoreMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  className={classes.popover}
                >
                  <Box className="menu-button">
                    <Button
                      className="btn-popover"
                      onClick={() =>
                        this.handleRetakeQuizModal(
                          quiz.id,
                          quiz.attributes.title
                        )
                      }
                    >
                    {this.t("Retake E-Quiz")}
                    </Button>
                    <Button
                      className="btn-popover"
                      onClick={() => this.handleFullDetailsClick(quiz.id)}
                    >
                      {this.t("Full Details")}
                    </Button>
                    <Button
                      className="btn-popover"
                      onClick={() => this.handleOpenSimlateQuiz(quiz.id)}
                    >
                      {this.t("Simulate Quiz")}
                    </Button>
                  </Box>
                </Popover>
              </Box>
              <Box className="button-wrapper">
                <Button
                  className={`primary-btn ${classes.secondaryButton}`}
                  onClick={() =>
                    this.handleRetakeQuizModal(quiz.id, quiz.attributes.title, quiz?.attributes?.caption)
                  }
                  data-test-id={`retake-quiz-click-${quiz.id}`}
                >
                  {this.t("Retake E-Quiz")}
                </Button>
                <Button
                  className={`primary-btn ${classes.secondaryButton}`}
                  onClick={() => this.handleFullDetailsClick(quiz.id)}
                  data-test-id={`full-details-click-${quiz.id}`}
                >
                  {this.t("Full Details")}
                </Button>
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  onClick={() => this.handleOpenSimlateQuiz(quiz.id)}
                  data-test-id={`simulate-quiz-click-${quiz.id}`}
                >
                  {this.t("Simulate Quiz")}
                </Button>
              </Box>
            </CardContent>
          </Card>
        );
      });
      return updated_lists;
    };

    const listQuizLibrary = () => {
      let lists = [];

      if (isFilteringQuizLibrary) {
        if (filteredlibraryQuiz.length > 0) {
          lists = filteredlibraryQuiz;
        } else {
          return <Box className="no-member">{this.t("No quizzes found")}</Box>;
        }
      } else {
        lists = quizList;
      }
      const updated_lists = lists.map((quiz: any) => {
        let selectedClass = ""; // Define selectedClass here
        if (quiz.id === selectedQuizId) {
          selectedClass = "selected";
        }
        return (
          <Box
            className={`list-item ${selectedClass}`}
            key={quiz.id}
            onClick={() => this.handleQuizClick(quiz.id)}
            data-test-id={`quiz-library-item-${quiz.id}`}
          >
            <Box className="left-view">
              <Typography className="list-item-title">
                {quiz.attributes.title}
              </Typography>
              <Typography className="list-item-sub-title">
                {quiz.attributes.caption}
              </Typography>
            </Box>
            <Box className="right-view">
              {quiz.id === selectedQuizId ? (
                <CheckCircleIcon className="icon-check" />
              ) : (
                <RadioButtonUncheckedIcon className="icon-un-check" />
              )}
            </Box>
          </Box>
        );
      });
      return updated_lists;
    };

    const renderCheckbox = (data:any) => (
      <Checkbox
        key={data.id}
        className="checklist-check"
        icon={<img src={data.unCheckedSrc} alt={`unChecked${data.id}`} />}
        checkedIcon={<img src={data.checkedSrc} alt={`score${data.id}`} />}
        tabIndex={-1}
        disableRipple
        onClick={() => this.handleScoreCheckboxes()}
        checked={this.state.isScoreChecked}
        inputProps={{
          "aria-labelledby": `score${data.id}`,
        }}
        data-test-id={`mobile-filter-checkbox-${data.id}`}
      />
    );

    return (
      <Box>
        {this.state.isLoading && <WebLoader />}
        <Box
          className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper}`}
        >
          {/* <AppHeader pageLink={pageURL} /> */}
          <GlobalHeaderWeb pageLink={pageURL} />
          <Box className={classes.innerWrapper}>
            <Box className={classes.teamModuleWrapper}>
              <Box className="heading-wrapper">
                <Box className="heading-left">
                  <Typography className="heading" variant="h1">
                    {this.t("E-Score")}
                  </Typography>
                  <Box component="p" className="sub-txt">
                    {this.t("Measure how well your company is performing.")}
                  </Box>
                </Box>
                {submittedquizList.length != 0 && <Box className="heading-right">
                  <Button
                    className={`secondary-btn ${classes.secondaryButton}`}
                    title="Create your own"
                    onClick={this.handleCreateQuiz}
                    data-testid="create-your-quiz"
                  >
                   {this.t("Create Your Own")}
                  </Button>
                  <Button
                    className={`primary-btn ${classes.primaryButton}`}
                    title="Choose E-quiz"
                    onClick={this.handlequizLibrary}
                    data-testid="choose-e-quiz"
                  >
                   {this.t("Choose E-Quiz")}
                  </Button>
                </Box>}
              </Box>

              <Box className="search-filter-sm">
                <Box className="search-wrapper">
                  <CustomInputWeb
                    type="search"
                    value={this.state.searchQuizValue}
                    data-test-id="quiz-search-input-first"
                    label=""
                    placeholder={this.t("Start typing to search...")}
                    onChange={(event) => this.handleSearchClick(event)}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </Box>
                <Box className="sort-group">
                  <img src={sort} alt="sort-group" />
                </Box>
                <Box className="filter-group" onClick={this.openFilterModalMobile} data-test-id="mobileFilter">
                  <img src={tuneVertical} alt="tuneVertical" />
                </Box>
              </Box>

              {this.state.openFilter && (
                <Modal
                  open={this.state.openFilter}
                  onClose={this.handle_modalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className={classes.modalDialog}
                >
                  <Box className={`${classes.modalDialogBox} filter-dialogbox`}>
                    <Box className="modal-heading">
                      <Box className="filter-heading">
                        <Box
                          className="auth-back"
                          onClick={this.handle_modalClose}
                        >
                          <i>
                            <svg
                              className="MuiSvgIcon-root"
                              focusable="false"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                            >
                              <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                            </svg>
                          </i>
                        </Box>
                        <Typography className="modal-title" component="h2">                          
                          {this.t(`${configJSON.txtFilters}`)}
                        </Typography>
                      </Box>
                      <Link to="#" className="filter-link">                        
                        {this.t(`${configJSON.txtReset}`)}
                      </Link>
                    </Box>
                    <Box className="modal-description">
                      <Box className="modal-form" component="form">
                        <Box className="form-info-wrapper">
                          <Typography className="form-heading" variant="h2">                            
                            {this.t(`${configJSON.txtEScore}`)}
                          </Typography>
                          <Box className="score-select">
                          {checkboxData.map(renderCheckbox)}
                          
                          </Box>
                        </Box>
                        <Box className="form-info-wrapper">
                          <Typography className="form-heading" variant="h2">                            
                            {this.t(`${configJSON.txtDateLastTaken}`)}
                          </Typography>
                          <EScoreDatePicker
                            placeholder={this.t("Choose Date range")}
                            range={true}
                            value={this.state.filter_date}
                            maxDate={new Date()}
                            currentDate=""
                            // datePickerRef={this.datePickerRef}
                            onChange={this.handleDateChange}
                            onOpenPickNewDate={false}
                            data-test-id="filter-date-range"
                            getFilterdData={this.filterQuizSearch}
                          />
                        </Box>
                        
                      </Box>
                      <Box className="modal-footer">
                        <Button
                          className={`primary-btn ${classes.primaryButton}`}
                          title="Apply Filters"
                        >
                          {this.t(`${configJSON.txtApplyFilters}`)}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Modal>
              )}

              <Box className={classes.tabWrapper}>
                <Box sx={{ width: "100%" }}>
                  <Box className="table-top">
                    <Box className="top-left">
                      <Box className="search-wrapper">
                        <Input
                          placeholder={this.t("Start typing to search...")}
                          type="search"
                          value={this.state.searchQuizValue}
                          data-test-id="quiz-search-input"
                          onChange={(event) => this.handleSearchClick(event)}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                        />
                      </Box>
                    </Box>
                    <Box className="top-right">
                      <Box className="filter-list">
                        <Box
                          className={`datepicker-wrapper ${classes.datepickerWrapper}`}
                          style={{width:"140px", paddingRight:"12px"}}
                        >
                          <EScoreDatePicker
                            placeholder={this.t("Date Last Taken")}
                            range={true}
                            value={this.state.filter_date}
                            maxDate={new Date()}
                            // readOnly={true} 
                            currentDate=""
                            // datePickerRef={this.datePickerRef}
                            onChange={this.handleDateChange}
                            onOpenPickNewDate={false}
                            data-test-id="filter-date-range"
                            getFilterdData={this.filterQuizSearch}                            
                          />
                        </Box>
                        {this.state.filter_escore.length > 0 && (
                          <FormControl
                            className="select-control multi-select score divider-border"
                            variant={isMobile ? "outlined" : "standard"}
                          >
                            <InputLabel
                              className="multiselect-label"
                              id="priority-mutiple-checkbox"
                            >
                              <Box className="select-icon" component="span">
                                <img src={starBlack} alt="starBlack" />
                              </Box>
                              {this.t("E-Score")}
                            </InputLabel>
                            <Select
                              labelId="priority-mutiple-checkbox-label"
                              id="priority-mutiple-checkbox"
                              data-test-id="filter-score-selector"
                              multiple
                              value={this.state.selected_escore_value}
                              defaultValue={blankArr}
                              onChange={(event: any) =>
                                this.scoreFilterHandler(event)
                              }
                              renderValue={() =>
                                getLabel(
                                  this.state.selected_escore_value,
                                  "E-score",
                                    <Box
                                      className="select-icon"
                                      component="span"
                                    >
                                      <img src={starBlack} alt="starBlack" />
                                    </Box>,
                                    this.t
                                )
                              }
                              inputProps={{ IconComponent: () => null }}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                classes: {
                                  paper: `${classes.dropdownStyle} multiSelect escore-dropdown`,
                                },
                              }}
                              displayEmpty
                            >
                              {this.state.filter_escore.map((value) => (
                                <MenuItem key={value} value={value}>
                                  <ListItemText className="priority-name">
                                    {value}
                                  </ListItemText>
                                  <Box className="multiselect-squareCheck">
                                    <img
                                      className="normal-img"
                                      src={checkBoxEmpty}
                                      alt="checkBoxEmpty"
                                    />
                                    <img
                                      className="selected-img"
                                      src={checkBoxFilled}
                                      alt="checkBoxFilled"
                                    />
                                  </Box>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}

                        <FormControl className="select-control sortby-control">
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                              classes: { paper: classes.dropdownStyle },
                            }}
                            onChange={this.handleSubmittedQuizesSort}
                            displayEmpty
                            defaultValue={this.t("Sort by")}
                            inputProps={{ IconComponent: () => null }}
                            renderValue={(value) => {
                              return (
                                <Box>
                                  <img
                                    className="select-icon"
                                    src={sortVariant}
                                    alt="sortVariant"
                                  />
                                  {value}
                                </Box>
                              );
                            }}
                            data-test-id="sbmt-quiz-dropdown"
                          >
                            <MenuItem value="A-Z">{this.t("A-Z")}</MenuItem>
                            <MenuItem value="Z-A">{this.t("Z-A")}</MenuItem>
                          </Select>
                        </FormControl>

                        <Box className="select-control">
                          <Box className="paginationTable">
                            <Box className="pagination-toolbar">
                              <Box className="pagination-caption" component="p">
                                {this.state.isFilteringQuiz ? (
                                  <>
                                    {startIndex + 1} -{" "}
                                    {Math.min(
                                      endIndex,
                                      filteredDataQuiz.length
                                    )}{" "}
                                    of {filteredDataQuiz.length}
                                  </>
                                ) : (
                                  <>
                                    {startIndex + 1} -{" "}
                                    {Math.min(
                                      endIndex,
                                      submittedquizList.length
                                    )}{" "}
                                    of {submittedquizList.length}
                                  </>
                                )}
                              </Box>
                              <Box className="pagination-actions">
                                <Button
                                  className={`page-btn prev ${
                                    currentPage === 0 ? "disable" : ""
                                  }`}
                                  onClick={this.handlePrevPage}
                                  disabled={currentPage === 0}
                                >
                                  <ChevronLeftIcon />
                                </Button>
                                {this.state.isFilteringQuiz ? (
                                    <Button
                                      className={`page-btn next ${
                                        endIndex >= filteredDataQuiz.length
                                          ? "disable"
                                          : ""
                                      }`}
                                      onClick={this.handleNextPage}
                                      disabled={
                                        endIndex >= filteredDataQuiz.length
                                      }
                                    >
                                      <ChevronRightIcon />
                                    </Button>
                                ) : (
                                    <Button
                                      className={`page-btn next ${
                                        endIndex >= submittedquizList.length
                                          ? "disable"
                                          : ""
                                      }`}
                                      onClick={this.handleNextPage}
                                      disabled={
                                        endIndex >= submittedquizList.length
                                      }
                                    >
                                      <ChevronRightIcon />
                                    </Button>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {submittedquizList.length === 0 && (
                    <Box className={classes.emptyDataBox}>
                      <Box className="emptydata-inner">
                        <Box className="emptydata-row">
                          <Box className="emptydata-img">
                            <img src={emptyQuiz} alt="emptydata" />
                          </Box>
                          <Box className="emptydata-content">
                            <Typography className="heading" variant="h2">
                            {this.t(configJSON.labelTitleTxt)}
                            </Typography>
                            <Box component="p" className="sub-txt">
                              {this.t("E-Score is a useful tool for assessing your company on industry standards. Take one of our pre-made E-Quizzes or create your own.")}
                            </Box>
                            <Box className="button-wrapper">
                              <Button
                                className={`${classes.secondaryButton} btn-create-your-own`}
                                title="Create your own"
                                onClick={this.handleCreateQuiz}
                              >
                                {this.t("Create your own")}
                              </Button>
                              <Button
                                className={classes.primaryButton}
                                title="Choose E-quiz"
                                onClick={this.handlequizLibrary}
                              >
                               {this.t("Choose E-quiz")}
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  <Box sx={{ minWidth: 275 }} className="escore-card-row">
                    {listSubmittedQuizes()}
                    <Button
                      className="btn-take-equiz"
                      onClick={this.handleCreateQuiz}
                    >
                      + {this.t("Take E-Quiz")}
                    </Button>
                  </Box>
                  {/* modal */}
                  <Modal
                    open={this.state.viewQuizLibrary}
                    onClose={this.handle_modalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className={classes.modalDialog}
                  >
                    <Box className={classes.modalDialogBox}>
                      <Box className="modal-heading">
                        <Typography className="modal-title" component="h2">
                          {this.t("E-Quiz Library")}
                        </Typography>
                        <Link
                          to="#"
                          className="close-icon"
                          onClick={this.handle_modalClose}
                        >
                          <CloseIcon />
                        </Link>
                      </Box>
                      <Box className="modal-description">
                        <Box component="p" className="sub-txt">
                         {this.t("Select an e-quiz to measure your organisation’s performance.")}
                        </Box>
                      </Box>

                      <Box className={classes.dialogContent}>
                        <Box className="search-wrapper" style={{ display:"flex", gap:"16px" }}>
                          <Box style={{ width:"100%" }}>
                          <CustomInputWeb
                            data-test-id="library-quiz-search-input"
                            type="search"
                            value={this.state.chooseQuizSearch}
                            label=""
                            placeholder={this.t("Start typing to search...")}
                            onChange={(event: any) =>
                              this.handleChooseSearchClick(event)
                            }
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                          />
                          </Box>
                          <Box>
                            <img src={filterLogo} width={"48px"} height={"48px"}/>
                          </Box>
                        </Box>
                        <Box className={`${classes.dialogContent} filter-box`}>
                          <Typography
                            className={`${classes.dialogContent} quiz-count`}
                          >
                            {isFilteringQuizLibrary ? this.state.filteredlibraryQuiz.length : this.state.quizList.length} {this.t("E-Quizzes")}
                          </Typography>
                          <SortQuizWeb
                            classes={classes}
                            sortHandler={this.handleLibraryQuizSort}
                            sortType="librarySort"
                            data-test-id="sort-dropdown"
                            t={this.t}
                          />
                        </Box>
                        <Box className="list-data">{listQuizLibrary()}</Box>
                      </Box>
                      {selectedQuizId && (
                        <Box className={`${classes.dialogContent} bottom-box`}>
                          <Button
                            className={`${classes.dialogContent} btn-continue`}
                            onClick={() => this.handleContinueButtonClick()}
                            data-test-id="continue-btn"
                          >
                            <Typography>{this.t("Continue")}</Typography>
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Modal>
                  {/* Create Quiz*/}
                  {this.state.isCreatingQuiz && (
                    <CreateQuiz
                      classes={classes}
                      modalOpen={this.state.isCreatingQuiz}
                      modalClose={this.handle_modalClose}
                      quizTitle={this.state.createQuiz.quizTitle}
                      quizCaption={this.state.createQuiz.quizCaption}
                      saveQuizDetails={this.saveQuizDetails}
                      createQuiz={this.createQuizHandler}
                      errors={this.state.createQuizError}
                      t={this.t}
                      data-test-id="create-quiz-modal"
                    />
                  )}
                  {/* Rettake Quiz Modal*/}
                  <Modal
                    open={this.state.reTakeQuizModal}
                    onClose={this.handleCloseReTakeQuizModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className={classes.modalBoxCenter}
                  >
                    <Box className={classes.modalreTakeQuiz}>
                      <Box className="modal-heading">
                        <Typography className="modal-title" component="h2">
                         {this.t("Retake E-Quiz")}
                        </Typography>
                        <Link
                          to="#"
                          className="close-icon"
                          onClick={this.handleCloseReTakeQuizModal}
                        >
                          <CloseIcon style={{ fontSize: "24px" }} />
                        </Link>
                      </Box>
                      <Box className="modal-description">
                        <Typography className="modal-desc">                         
                          {this.t(configJSON.retakeQuizeconfirmationMessage, { quizTitle })}
                        </Typography>
                        <Box className="modal-footer">
                          <Button
                            className={`secondary-btn ${classes.secondaryButton}`}
                            title={this.t("Cancel")}
                            data-test-id="btn-cancel-retake"
                            onClick={this.handleCloseReTakeQuizModal}
                          >
                            {this.t("Cancel")}
                          </Button>
                          <Button
                            className={`primary-btn ${classes.primaryButton}`}
                            title="Knowledge Base"
                            data-test-id="btn-retake-quiz"
                            onClick={this.retakeQuizBtnHandler}
                          >
                            {this.t("Retake E-Quiz")}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Modal>
                  {/* Delete Quiz modal*/}
                  <Modal
                    open={this.state.deleteQuizModal}
                    onClose={this.handleCloseDeleteQuizModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className={classes.modalBoxCenter}
                    data-test-id="delete-quiz-modal"
                  >
                    <Box className={classes.modalreTakeQuiz}>
                      <Box className="modal-heading">
                        <Typography className="modal-title" component="h2">
                          {this.t("Delete Quiz")}
                        </Typography>
                        <Link
                          to="#"
                          className="close-icon"
                          onClick={this.handleCloseDeleteQuizModal}
                        >
                          <CloseIcon style={{ fontSize: "24px" }} />
                        </Link>
                      </Box>
                      <Box className="modal-description">
                        <Typography className="modal-desc">
                          {this.t("Are you sure you want to delete this quiz?")}
                        </Typography>
                        <Box className="modal-footer">
                          <Button
                            className={`secondary-btn ${classes.secondaryButton}`}
                            title="Cancel"
                            data-test-id="btn-cancel-retake"
                            onClick={this.handleCloseDeleteQuizModal}
                          >
                            {this.t("Cancel")}
                          </Button>
                          <Button
                            className={`primary-btn ${classes.primaryButton}`}
                            title="Delete Quiz"
                            data-test-id="btn-delete-quiz"
                            onClick={this.handleDeleteQuiz}
                          >
                            {this.t("Delete Quiz")}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Modal>
                  {/*Equiz Result Modal*/}
                  {this.state.equizResultModal && (
                    <EQuizResults
                      classes={classes}
                      modalOpen={this.state.equizResultModal}
                      openDeleteModal={this.handleDeleteQuiizModal}
                      openFullScreenMode={this.handleFullScreenMode}
                      modalClose={this.handleCloseEquizResultModal}
                      fullDetails={this.state.getQuizFullDetails}
                      shareCodeModal={this.handleOpenSharingModal}
                      openSimulateScreen={this.handleOpenSimlateQuiz}
                      openRetakeQuiz={this.handleRetakeQuizModal}
                      t={this.t}
                      data-test-id="full-details-modal"
                    />
                  )}
                  {this.state.fullScreenMode && (
                    <FullScreenMode
                      classes={classes}
                      modalOpen={this.state.equizResultModal}
                      openDeleteModal={this.handleDeleteQuiizModal}
                      modalClose={this.handlecloseFullScreen}
                      fullDetails={this.state.getQuizFullDetails}
                      shareCodeModal={this.handleOpenSharingModal}
                      openSimulateScreen={this.handleOpenSimlateQuiz}
                      openRetakeQuiz={this.handleRetakeQuizModal}
                      t={this.t}
                    />
                  )}

                  {this.state.simulationQuizModal && (
                    <SimulationModeScore
                      modalClasses={classes}
                      modalOpen={this.state.simulationQuizModal}
                      modalClose={this.handleCloseSimlateQuiz}
                      simulatequizId={this.state.simulateQuizId}
                      t={this.t}
                    />
                  )}

                  {/* Score Modal */}
                  {this.state.viewGradeScore && (
                    <GradeScoreModal
                      classes={classes}
                      modalOpen={this.state.viewGradeScore}
                      modalClose={this.handleCloseGradeScoreModal}
                      submittedData={this.state.getQuizSubmittedData}
                      openRetakeQuiz={this.handleRetakeQuizModal}
                      openSimulateScreen={this.handleOpenSimlateQuiz}
                      openFullDetails={this.handleFullDetailsClick}
                      t={this.t}
                    />
                  )}

                  {/* Share E-Quiz score modal*/}
                  {this.state.viewSharingModal && (
                    <ShareQuizScore
                      classes={classes}
                      modalOpen={this.state.viewSharingModal}
                      modalClose={this.handleCloseSharingModal}
                      gradeScore={this.state.gradeScore}
                      fullDetails={this.state.getQuizFullDetails}
                      t={this.t}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box >
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(EquizDashboardWeb)
);
//Customizable Area End
