// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Popover,
  Button
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { sortVariant } from "../assets";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import { configJSON } from "../GroupListController.web";

interface GroupsProps {
  classes: any;
  contactGroups: any;
  openCreateContact: any;
  closeCreateContact: any;
  searchValue: string;
  handleSearch: (e: any) => void;
  anchorEl: any;
  closePopover: () => void;
  openPopover:(
    event: React.MouseEvent<HTMLButtonElement> | null, name:string,id:string,contactIds:any
  ) => void;
  showAddDeleteInGroup:() => void;
  closeDeleteInGroup?:() => void;
  showAddRenameInGroup:()=> void;
  handleSortGroup:(e: any)=>void;
  openEditGroup:()=>void;
  onOpenViewGroup:(id:any)=>void
  isGroupSearchingSorting:boolean;
  searcheSortGroupData:any;
  t:any;
}

const Groups = (props: GroupsProps) => {
  const { 
    classes, 
    contactGroups, 
    searchValue,
     handleSearch ,
     anchorEl,
     closePopover,
     openPopover,
     showAddDeleteInGroup,
    showAddRenameInGroup,
    handleSortGroup,
    openEditGroup,
    onOpenViewGroup,
    isGroupSearchingSorting,
    searcheSortGroupData,
    t
    } = props;
    const [groupData,setGroupData] = useState(contactGroups)

    useEffect(() => {     
      if (isGroupSearchingSorting ) {
        setGroupData(searcheSortGroupData);
        } else {
        setGroupData(contactGroups);
      }
    }, [isGroupSearchingSorting,searcheSortGroupData,contactGroups]);
    let message;
    const contactText = groupData.length !== 1 ? t(`${configJSON.groupsCountTxt}`) : t(`${configJSON.groupCountTxt}`);
    message = contactText?.replace('{{count}}', groupData.length);
  return (
    <Box className={classes.contactBooksWrapper}> 
      <Box className="filter-box">
        <Box className="left-box">
          <Typography className="total-contact">{message}</Typography>
          <div className="group-line"></div>
          <CustomInputWeb
            type="search"
            value={searchValue}
            label=""
            placeholder={t(`${configJSON.searchTxtType}`)}
            onChange={(event: any) => handleSearch(event.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon style={{color:"#958f8f"}}/>
              </InputAdornment>
            }
            data-test-id="searchfield"
          />
        </Box>
        <Box className="right-box">
          <FormControl className="select-control sortby-control">
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                classes: { paper: classes.dropdownStyle },
              }}
              onChange={(e:any)=>handleSortGroup(e.target.value)}
              displayEmpty
              defaultValue={t("Sort by")}
              inputProps={{ IconComponent: () => null }}
              renderValue={(value) => {
                return (
                  <Box>
                    <img
                      className="select-icon"
                      src={sortVariant}
                      alt="sortVariant"
                    />
                    {value}
                  </Box>
                );
              }}
              data-test-id="sbmt-quiz-dropdown"
            >
             <MenuItem value="A-Z">{t("A-Z")}</MenuItem>
              <MenuItem value="Z-A">{t("Z-A")}</MenuItem>
              <MenuItem value="Most to least contacts">{t("Most to least contacts")}</MenuItem>
              <MenuItem value="Least to most contacts">{t("Least to most contacts")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box className="content-wrapper">
        <Box className="groups">
        {groupData.length > 0 ?
          groupData.map((group: any) => {
            const contactIds = group.attributes.contacts.map((contact:any) => contact?.id);
            const filteredContacts = group.attributes.contacts.filter((contact:any) => contact !== null);   
            return (
            <div key={group.attributes.id} className="group-container">
             <Box  className="group-item-list" data-test-id="onOpenViewGroup" onClick={()=>onOpenViewGroup(group.attributes.id)} key={group.attributes.id}>
                <Box className="gr-item-left-box">
                  <Typography className="gr-item-title">
                    {group.attributes.name}
                  </Typography>
                  <Typography className="gr-item-sub-title">
                    {filteredContacts.length} {filteredContacts.length>1?"Contacts":"Contact" }
                  </Typography> 
                </Box>        
              </Box>
              <div className="more-button">                
                <Button className="btn-view-action" data-test-id="openPopover" onClick={(e)=>openPopover(e, group.attributes.name,group.attributes.id,contactIds)}>
                 <MoreVertIcon fontSize="small" style={{color:"#94A3B8"}}/>
                </Button>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={closePopover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    className={classes.dropdownStyle}
                  >
                    <Box className={classes.contactMenuItem}>
                      <Box
                        className="menu-item"
                        data-test-id="menu-item-1"
                        onClick={()=>{
                          openEditGroup();
                          closePopover()
                        }}
                      >
                        <Typography>{t(`${configJSON.edit}`)}</Typography>
                       
                      </Box>
                      <Box className="menu-item"                       
                       onClick={()=>{
                          showAddRenameInGroup()
                          closePopover()
                        }}
                        data-test-id="showAddRenameInGroup"
                        >
                        <Typography>{t(`${configJSON.rename}`)}</Typography>                       
                      </Box>
                      <Box className="menu-item"
                       onClick={()=>{
                          showAddDeleteInGroup()
                          closePopover()
                        }}
                        data-test-id="menu-item"
                        >
                        <Typography>{t(`${configJSON.delete}`)}</Typography>                       
                      </Box>                     
                    </Box>
                </Popover>
              </div>
              </div>
            );
          }):(
              <Box className="error-message">
                 <Typography>
                     No groups found.                      
                 </Typography>
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default Groups;

// Customizable Area End
