Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.methodGET = "GET";
exports.methodPOST = "POST";
exports.methodDELETE = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TimesheetManagement";
exports.labelBodyText = "TimesheetManagement Body";
exports.noTimesheetText = "Timesheet";
exports.noTimesheetDesc = "There are no timesheets created yet. Click the button to begin.";
exports.newTimesheetTxt = "New Timesheet";
exports.viewTimesheetText = "View or download previously created timesheets.";
exports.timesheetFormDesc = "Select the details you require.";
exports.dateRangePlaceholder ="Date Range";
exports.txtMemberSelected = "Choose Teams or Members";
exports.txtAssigneeDescription = "Timesheet will be generated for those selected.";
exports.txtSelected = "Selected";
exports.txtRemoveAllAssignees = "Remove";
exports.txtSearch = "Search";
exports.txtUnassigned = "Unselected";
exports.projectListEndPoint = "/account_block/projects";
exports.fetchTaskListByProject = "/account_block/project_tasklist_view?project_id";
exports.fetchTasks = "/account_block/task_filter?"
exports.getallTimesheets = "/bx_block_timesheetmanagement/timesheets";
exports.getTeamsEndPoint = "/account_block/teams";
exports.getMembersEndPoint = "/account_block/member_list";
exports.generateTimesheet = "/bx_block_timesheetmanagement/timesheets";
exports.deleteTimesheet = "/bx_block_timesheetmanagement/timesheets";
exports.getTimesheetSingle = "/bx_block_timesheetmanagement/timesheets";
exports.getTimesheetMembers = "/bx_block_timesheetmanagement/get_members_data";
exports.getTimesheetTeams = "/bx_block_timesheetmanagement/get_teams_data";
exports.downloadTimesheet = "/account_block/report_download";
exports.searchTxt = "Search here";
exports.btnExampleTitle = "CLICK ME";
exports.createTimesheetTxt ="Create Timesheet";
exports.teamsTxt = "Teams";
exports.teamsSingleTxt = "Team";
exports.membersTxt = "Members";
exports.membersSingleTxt = "Member";
exports.searchTxtType = "Start typing to search...";
exports.sortByTxt = "Sort by";
exports.deleteTimesheetTxt = "timesheet";
exports.aTozTxt = "A-Z";
exports.zToaTxt = "Z-A"
exports.newestToOldest = "Newest to oldest";
exports.oldestToNewest = "Oldest to newest";
exports.timeSheetSuccess = "Timesheet generated";
exports.timeSheetDeleted = "Timesheet deleted";
// Customizable Area End