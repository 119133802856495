//Customizable Area Start
import React, { useState } from "react";
import {
    Box,
    Typography,
    Button,
    Card,
    Grid
} from "@material-ui/core";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "./../EquizStyles.web";


export const configJSON = require("./../config");

import {
    eclipse,
    info_logo
} from "./../assets";

import GlobalHeaderWeb from "../../../customisableuserprofiles/src/GlobalHeader.web";
import OngoingTasksModal from "./OngoingTasksModal";
import UnfinishedTasksModal from "./UnfinishedTasksModal";

// import { customStyles } from "../EquizStyles.web";
const ManagerDashboard = (props: any) => {

    const { classes } = props;
    const pageURL = window.location.pathname;

    const ongoingTasks = [
        {
         "name": "Arnold Carter",
         "timer": "33:00/35:00 hrs",
         "unfinished": 0,
         "high_priority": 0,
         "past_due": 0,
         "completed": 0,
         "total": 0,
         "ongoing": "Time Off"
        },
        {
        "name": "Esther Howard",
        "timer": "43:00/36:00 hrs",
        "unfinished": 15,
        "high_priority": 23,
        "past_due": 18,
        "completed": 26,
        "total": 40,
        "ongoing": "Determine Retail Price for Newly Made Wines"
        },
        {
        "name": "Arnold Carter",
        "timer": "33:00/35:00 hrs",
        "unfinished": 0,
        "high_priority": 0,
        "past_due": 0,
        "completed": 0,
        "total": 0,
        "ongoing": "Time Off"
        },
        {
        "name": "Esther Howard",
        "timer": "43:00/36:00 hrs",
        "unfinished": 15,
        "high_priority": 23,
        "past_due": 18,
        "completed": 26,
        "total": 40,
        "ongoing": "Determine Retail Price for Newly Made Wines"
        },
    ]

    const [isOngoingModal, setIsOngoing] = useState(false);

    const closeOngoingModal = () => setIsOngoing(false);

    const [isUnfinishedModal, setIsUnfinished] = useState(false);

    const closeUnfinishedModal = () => setIsUnfinished(false);

    return (
        <Box>
            {/* {this.state.isLoading && <WebLoader />} */}
            <Box
                className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper}`}
            >
                <GlobalHeaderWeb pageLink={pageURL} />
                <Box style={{marginTop: "80px"}} className={classes.innerWrapper}>
                    <Box className={classes.teamModuleWrapper}>
                        <Grid container spacing={2}>
                            <Grid item sm={8} xs={8}>
                                <Box className="google-heading-wrapper">
                                    <Box className="heading-left">
                                        <Typography className="heading" variant="h1">
                                            Task Metrics
                                        </Typography>
                                    </Box>
                                    <Box className="card-container-one" sx={{ width: "100%" }}>
                                        <Box className="google-card-row">
                                            <Card className={'followers-card'} onClick={() => setIsUnfinished(true)}>
                                                <CardContent className="content-card">
                                                    <Box className="right-content">
                                                        <Box className="sub-cards">
                                                            <span>
                                                              Unfinished
                                                            </span>
                                                             <span className= "info-container">
                                                             <img src={info_logo} alt="info" />
                                                             {/* <span className="info-box">Unfinished tasks are all tasks that are not completed 
                                                             (Open + In Progress).
                                                             </span> */}
                                                            </span>
                                                        </Box>
                                                        
                                                        <Box className="card-val" display="flex" justifyContent={'flex-start'} alignItems={"center"}>
                                                            <span>
                                                                60 &nbsp;
                                                            </span>
                                                        </Box>
                                                        <Box className="quiz-date">
                                                            And 40 Completed
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                            <Card className={'followers-card'} onClick={() => setIsOngoing(true)}>
                                                <CardContent className="content-card">
                                                    <Box className="right-content">
                                                        <Box className="sub-cards">
                                                            <span>
                                                            Ongoing
                                                            </span>
                                                            <img src={info_logo} alt="info" />
                                                        </Box>
                                                        
                                                        <Box className="card-val" display="flex" justifyContent={'flex-start'} alignItems={"center"}>
                                                            <span>
                                                                9 &nbsp;
                                                            </span>
                                                        </Box>
                                                        <Box className="quiz-date">
                                                            2 members not tracking time
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                            <Card className={'followers-card'}>
                                                <CardContent className="content-card">
                                                    <Box className="right-content">
                                                        <Box className="sub-cards">
                                                            <span>
                                                            Emergency
                                                            </span>
                                                            <img src={info_logo} alt="info" />
                                                        </Box>
                                                        
                                                        <Box className="card-val" display="flex" justifyContent={'flex-start'} alignItems={"center"}>
                                                            <span>
                                                                10 &nbsp;
                                                            </span>
                                                        </Box>
                                                        <Box className="quiz-date">
                                                            3 Ongoing
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                            <Card className={'followers-card'}>
                                                <CardContent className="content-card">
                                                    <Box className="right-content">
                                                        <Box className="sub-cards">
                                                            <span>
                                                            Past Due
                                                            </span>
                                                            <img src={info_logo} alt="info" />
                                                        </Box>
                                                        
                                                        <Box className="card-val" display="flex" justifyContent={'flex-start'} alignItems={"center"}>
                                                            <span>
                                                                5 &nbsp;
                                                            </span>
                                                        </Box>
                                                        <Box className="quiz-date">
                                                          1 Ongoing
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className="google-heading-wrapper">
                                    <Box className="heading-container">
                                      <Box>
                                        <Typography className="heading" variant="h1">
                                            Ongoing Tasks
                                        </Typography>
                                        <Typography className="sub-head-container">
                                            <span>10 Members</span>
                                            <img width="8px" height="8px" src={eclipse} alt="eclipse" />
                                            <span>Average of 6 tasks per member</span>
                                        </Typography>
                                      </Box>
                                        <Typography style={{ fontWeight: 500, fontSize: "14px", color: "#4BA3B7", fontFamily: "Expletus Sans" }} variant="h3">
                                            See All
                                        </Typography>
                                    </Box>

                                    <Box sx={{ width: "100%" }}>
                                        <Box className="ongoing-tasks-box">
                                            {ongoingTasks.map((obj) => {
                                              return(
                                               <Card className={'followers-card'} >
                                                <CardContent>
                                                    <Box className="ongoing-tasks-card">
                                                      <Box className="row-one">
                                                        <span>{obj.name}</span>
                                                        <span>{obj.timer}</span>
                                                      </Box>
                                                      <Box className="row-two">
                                                        <span className="row-elem">
                                                            <span className="sub-head">Unfinished</span>
                                                            <span>{obj.unfinished}</span>
                                                        </span>
                                                        <span className="row-elem">
                                                            <span className="sub-head">High Priority</span>
                                                            <span>{obj.high_priority}</span>
                                                        </span>                                                       
                                                        <span className="row-elem">
                                                            <span className="sub-head">Past Due</span>
                                                            <span>{obj.past_due}</span>
                                                        </span>
                                                        <span className="row-elem">
                                                            <span className="sub-head">Completed</span>
                                                            <span>{obj.completed}</span>
                                                        </span>
                                                        <span className="row-elem">
                                                            <span className="sub-head">Total</span>
                                                            <span>{obj.total}</span>
                                                        </span>                                                        
                                                      </Box>
                                                      <Box className="row-three">
                                                        <span className="sub-head">Ongoing</span>
                                                        <span style={{fontSize: "12px"}}>{obj.ongoing}</span>
                                                      </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                                )
                                            })}
                                        
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item sm={4} xs={4}>
                              <Box className="google-side-wrapper" >
                                <Typography style={{ fontWeight: 400, fontSize: "22px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                  Performance Metrics
                                </Typography>

                                <Box className={"performance-card-container"}>
                                  <Box className={'performance-metrics-card'} >
                                    <Typography style={{ marginBottom: 10, fontWeight: 400, fontSize: "16px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="subtitle1">
                                        Tasks Over Tracked
                                    </Typography>
                                    <Typography style={{ marginBottom: 6, fontWeight: 500, fontSize: "26px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                        30
                                    </Typography>
                                    <Typography style={{ fontWeight: 400, fontSize: "12px", color: "#94A3B8", fontFamily: "Roboto" }} variant="h1">
                                        £450 over budget
                                    </Typography>
                                  </Box>
                                  <Box className={'performance-metrics-card'} >
                                    <Typography style={{ marginBottom: 10, fontWeight: 400, fontSize: "16px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="subtitle1">
                                        Hours Tracked
                                    </Typography>
                                    <Typography style={{ marginBottom: 6, fontWeight: 500, fontSize: "26px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                        500
                                    </Typography>
                                    <Typography style={{ fontWeight: 400, fontSize: "12px", color: "#94A3B8", fontFamily: "Roboto" }} variant="h1">
                                        99% of Hours Allocated
                                    </Typography>
                                  </Box>
                                </Box>

                                </Box>
                                <Box className="google-side-wrapper" >
                                 <Typography style={{ fontWeight: 400, fontSize: "22px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                    Team Time Off
                                 </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
            {isOngoingModal &&
                <OngoingTasksModal
                    classes={classes}
                    modalOpen={isOngoingModal}
                    closeModal={closeOngoingModal}
                    tasks={ongoingTasks}
                />
            }
            {isUnfinishedModal &&
                <UnfinishedTasksModal
                    classes={classes}
                    modalOpen={isUnfinishedModal}
                    closeModal={closeUnfinishedModal}
                    tasks={ongoingTasks}
                />
            }
        </Box>
    );
};

// export default MarketingDashboard;

export default withStyles(customStyles, { withTheme: true })(ManagerDashboard);
