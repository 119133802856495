import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
import {
  SubscribedUser,
  Chat,
  ChatMessagesResponse,
  UserToken,
  chatRoomData,
  GroupedMessages,
  Type,
  ExternalChat,
  LiveChatEtohData,
} from "../../../components/src/ReusableEnums";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes: Record<string, string>;
  openModal: boolean;
  closeModal: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openNewChat: boolean;
  isSortModalOpenEvent: boolean;
  deleteMessage: string;
  isLoading: boolean;
  chatRoomList: Array<Chat>;
  chatTxtMessage: string;
  chatRoomAllList: Array<Chat>;
  messageId: string;
  allSinglePersonChat: Array<ChatMessagesResponse>;
  openExternalChat: boolean;
  email: string;
  error: {
    email: string;
  };
  subscribedUserData: Array<SubscribedUser>;
  userSearch: string;
  userSortBy: string;
  chatRoomSortBy: string;
  groupedMessages: Array<GroupedMessages>;
  chatRoomSearch: string;
  openEmailvarification: boolean;
  currentUserId: number;
  message: string;
  teamDetail: [];
  projectDetail: [];
  projectDetailName: string;
  projectDetailSearchVal: string;
  teamDetailSearchVal: string;
  teamDetailName: string;
  attachMedia: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class NewChatModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionData: string | null;
  userToken: UserToken;
  getAllChatRoomsRequestId: string = "";
  createExternalChat: string = "";
  getAllUsersRequestId: string = "";
  createExternalInternalChatRequestId: string = "";
  etohSupportLiveChatRequestId: string = "";
  inviteMemberRequestId: string = "";
  getAllChatRoomsRequestID: string = "";
  fetchProjectDetailsRequestId: string = "";
  fetchTeamDetailsRequestId: string = "";
  applyAllFilterDetailsRequestId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      openNewChat: false,
      isSortModalOpenEvent: false,
      deleteMessage: "",
      isLoading: false,
      chatTxtMessage: "",
      chatRoomList: [],
      chatRoomAllList: [],
      allSinglePersonChat: [],
      messageId: "",
      openExternalChat: false,
      email: "",
      error: {
        email: "",
      },
      subscribedUserData: [],
      userSortBy: this.translateChat(`${configJSON.sortByTxt}`),
      userSearch: "",
      chatRoomSortBy: this.translateChat(`${configJSON.sortByTxt}`),
      chatRoomSearch: "",
      groupedMessages: [],
      openEmailvarification: false,
      currentUserId: 0,
      message: "",
      projectDetail: [],
      teamDetail: [],
      projectDetailName: "",
      projectDetailSearchVal: "",
      teamDetailName: "",
      teamDetailSearchVal: "",
      attachMedia: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.userSessionData = sessionStorage.getItem("userData") || "";
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.error) {
        this.setState({ isLoading: false });
        return;
      }
      switch (apiRequestCallId) {
        case this.getAllChatRoomsRequestId:
          this.handleAllChatRoomsResponse(responseJson.data);
          break;
        case this.getAllChatRoomsRequestID:
          this.handleAllChatRoomsResponseData(responseJson.data);
          break;
        case this.fetchProjectDetailsRequestId:
          this.handleProjectDetailResponse(responseJson.data);
          break;
        case this.fetchTeamDetailsRequestId:
          this.handleTeamDetailResponse(responseJson.data);
          break;
        case this.applyAllFilterDetailsRequestId:
          this.handleapplyAllFilterDetailResponse(responseJson);
          this.setState({ isSortModalOpenEvent: false });
          break;

        case this.getAllUsersRequestId:
          this.setState({
            isLoading: false,
            subscribedUserData: responseJson.data,
          });
          break;
        case this.createExternalChat:
          this.handleExternalChatResponse(responseJson);
          break;
        case this.createExternalInternalChatRequestId:
          responseJson.errors &&
            this.createToastNotification(
              this.translateChat(responseJson.errors.chat.base[0])
            );
          this.props.closeModal();
          this.setState({ isSortModalOpenEvent: false });
          this.handleChatRooms();
          break;

        case this.etohSupportLiveChatRequestId:
          this.handleEtohSupportLiveChatResponse(responseJson);
          break;
        case this.inviteMemberRequestId:
          if (responseJson.message) {
            this.createToastNotification(responseJson.message);
            this.closeEmailVarification();
            this.closeExternalChat();
          } else {
            this.handleInviteMember();
          }
          break;
        default:
          this.parseApiCatchErrorResponse(errorResponse);
          break;
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleAllChatRoomsResponse(responseJson: Array<Chat>) {
    this.setState({
      isLoading: false,
      chatRoomList: responseJson,
    });
  }
  handleAllChatRoomsResponseData(responseJson: Array<Chat>) {
    if (responseJson) {
      this.setState({
        chatRoomAllList: responseJson,
      });
    } else {
      this.setState({
        chatRoomAllList: [],
      });
    }
  }

  handleProjectDetailResponse(responseJson: any) {
    let dropDownResponse = responseJson.map((item: any) => ({
      label: `${item.attributes.title}`,
      value: item.id,
    }));
    this.setState({ projectDetail: dropDownResponse });
  }

  handleTeamDetailResponse(responseJson: any) {
    let dropDownResponse = responseJson.map((item: any) => ({
      label: `${item.attributes.title}`,
      value: item.id,
    }));
    this.setState({ teamDetail: dropDownResponse });
  }

  handleapplyAllFilterDetailResponse(responseJson: any) {
    if (responseJson.message) {
      this.setState({
        isLoading: false,
        chatRoomList: [],
      });
    } else {
      this.setState({
        isLoading: false,
        chatRoomList: responseJson.data,
      });
      this.props.closeModal();
    }
  }

  handleEtohSupportLiveChatResponse(responseJson: LiveChatEtohData) {
    this.setState({ isLoading: false });
    if (responseJson.errors) {
      this.createToastNotification(responseJson.errors);
      this.props.closeModal();
    } else {
      this.createToastNotification(configJSON.etohSupportChatAdded);
    }
    this.props.closeModal();
    this.handleChatRooms();
    window.location.reload();
  }

  handleExternalChatResponse(responseJson: ExternalChat) {
    if (responseJson.message === "Member succesfully found") {
      this.setState({
        isLoading: false,
        openExternalChat: false,
      });
      this.createExternalAndInternalChatEvent(
        responseJson.account.data,
        "external"
      );
    } else {
      this.setState({
        isLoading: false,
        error: {
          email: `${this.translateChat(configJSON.errorMessageForUserNotFoud)}`,
        },
      });
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.clearError(prevState);
    this.fetchUsersUpdate(prevState);
  }

  async componentDidMount(): Promise<void> {
    this.handleChatRooms();
    this.handleGetChatRooms();
    this.getAllSubscribedUsers();
    this.fetchProjectDetails();
    this.fetchTeamDetails();
    const lang = localStorage.getItem("lang") || "en";
    await i18n.changeLanguage(lang);
  }
  createToastNotification = (toastMesssage: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">
          {this.translateChat(toastMesssage)}
        </div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  translateChat(keyValue: string) {
    return i18n.t(keyValue, { ns: "translation" });
  }

  openSortModal = () => {
    this.setState({ isSortModalOpenEvent: true });
  };

  closeSortModal = () => {
    this.setState(
      {
        isSortModalOpenEvent: false,
        projectDetailName: "",
        teamDetailName: "",
        attachMedia: false,
      },
      () => this.handleChatRooms()
    );
  };

  openExternalChatEvent = () => {
    this.setState({
      openExternalChat: true,
    });
  };
  closeExternalChat = () => {
    this.setState({
      openExternalChat: false,
    });
  };
  closeEmailVarification = () => {
    this.setState({ openEmailvarification: false });
  };
  handleInviteMember = () => {
    this.setState({ openEmailvarification: true });
  };

  fetchUsersUpdate = (prevState: S) => {
    if (
      prevState.userSearch !== this.state.userSearch ||
      prevState.userSortBy !== this.state.userSortBy
    ) {
      this.getAllSubscribedUsers();
    }
    if (
      prevState.chatRoomSearch !== this.state.chatRoomSearch ||
      prevState.chatRoomSortBy !== this.state.chatRoomSortBy
    ) {
      this.handleChatRooms();
    }
  };

  handleAllInputChange = (event: Type) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  clearError = (prevState: S) => {
    let error = { ...this.state.error };
    const email = this.state.email.trim();
    if (prevState.email !== email) {
      if (email !== "") {
        error.email = "";
      } else if (!configJSON.emailRegex.test(email)) {
        error.email = this.translateChat(configJSON.emailErrorMsg);
      } else {
        error.email = "";
      }
    }
    if (JSON.stringify(error) !== JSON.stringify(this.state.error)) {
      this.setState({ error: error });
    }
  };
  createExternalChatEvent = () => {
    let error = this.state.error;
    let hasError = false;
    const email = this.state.email.trim();
    const userSessionData = sessionStorage.getItem("userData");
    const userInfo = userSessionData ? JSON.parse(userSessionData) : null;
    const currentUserEmail = userInfo?.data?.attributes?.email;
    if (currentUserEmail && email === currentUserEmail) {
      this.setState({
        error: {
          ...this.state.error,
          email: "You're unable to create a chat with yourself",
        },
      });
      return;
    }
    if (email === "") {
      this.setState({
        error: {
          ...this.state.error,
          email: this.translateChat(configJSON.emailEmptyErrorMsg),
        },
      });
      return;
    } else if (!configJSON.emailRegex.test(email)) {
      error.email = this.translateChat(configJSON.emailErrorMsg);
      this.setState({
        error: error,
      });
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createExternalChat = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createExternalChatApi}?email=${this.state.email}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createExternalAndInternalChatEvent = (
    dataList: chatRoomData,
    type: string
  ) => {
    const userName =
      dataList.attributes.first_name + " " + dataList.attributes.last_name;
    const formdata = new FormData();
    formdata.append("chat[chat_type]", type);
    formdata.append("chat[name]", userName);
    formdata.append("account_id", dataList.id);

    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createExternalInternalChatRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createChatForExternalAndInternalUser
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChatRooms = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const newValue = this.state.chatRoomSortBy;

    let processedValue = "";

    switch (newValue) {
      case "Recent":
        processedValue = "recent";
        break;
      case "Unread":
        processedValue = "unread";
        break;
    }

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    this.getAllChatRoomsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getChatPath}?search_query=${this.state.chatRoomSearch}&sort=${processedValue}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleGetChatRooms = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    this.getAllChatRoomsRequestID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getChatPath}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllSubscribedUsers = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const newValue = this.state.userSortBy;
    let processedValue = "";

    switch (newValue) {
      case "A-Z":
        processedValue = "A-Z";
        break;
      case "Z-A":
        processedValue = "Z-A";
        break;
    }
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    this.getAllUsersRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.subscribedUserList}?search_query=${this.state.userSearch}&sort_by=${processedValue}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createEtohSupportChat = () => {
    const header = {
      token: this.userToken.meta.token,
    };
    const formData = new FormData();
    formData.append("chat[chat_type]", "etoh_shop_support");
    formData.append("chat[name]", "Etoh Support");

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.etohSupportLiveChatRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getChatPath
    );

    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  inviteMemberEvent = () => {
    const formdata = new FormData();
    formdata.append("[email]", this.state.email);

    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.inviteMemberPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.inviteMemberRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchProjectDetails = async () => {
    let token = this.userToken.meta.token;
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchProjectDetailsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectfilterEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchTeamDetails = async () => {
    let token = this.userToken.meta.token;
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchTeamDetailsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teamfilterEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChatFilterData = async () => {
    const token = this.userToken.meta.token;
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.applyAllFilterDetailsRequestId = requestMessage.messageId;
    let queryParams = `?project_name=${this.state.projectDetailName}&team=${this.state.teamDetailName}`;

    if (this.state.attachMedia) {
      queryParams += `&media_file=${this.state.attachMedia}`;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/chats/filter_chats${queryParams}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.messageId, requestMessage);
  };
  projectHandler = (e: any) => {
    this.setState({ projectDetailName: e.target.value });
  };
  projectmultiSelectSearchChange = (event: any) => {
    this.setState(
      {
        projectDetailSearchVal: event.target.value,
      },
      () => this.displayproject()
    );
  };
  displayproject = () => {
    return this.state.projectDetail.filter((obj: any) =>
      this.containsText(obj.label, this.state.projectDetailSearchVal)
    );
  };
  teamHandler = (e: any) => {
    this.setState({ teamDetailName: e.target.value });
  };
  teammultiSelectSearchChange = (event: any) => {
    this.setState(
      {
        teamDetailSearchVal: event.target.value,
      },
      () => this.displayteam()
    );
  };
  displayteam = () => {
    return this.state.teamDetail.filter((obj: any) =>
      this.containsText(obj.label, this.state.teamDetailSearchVal)
    );
  };
  containsText = (text: string, searchText: string) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  // Customizable Area End
}
