// Customizable Area Start
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Chip,
  Avatar,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import { teamLists } from "../assets";
import { configJSON } from "./AccountCreationController.web";
import ConfirmActionModal from "./ConfirmActionModal.web";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { useRunEngine } from "../../../utilities/src/hooks/useRunEngine";
import { Message } from "../../../../framework/src/Message";
import { getStorageData } from "../../../../framework/src/Utilities";

const subscribedMessages = [
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
];


interface EditMemberProps {
  classes: any;
  modalOpen: boolean;
  modalClose: () => void;
  memberDetails: any;
  roleChange: (event: any, type: string) => void;
  errorState: any;
  updateMemHandler: () => void;
  changeEmailHandler: () => void;
  changePasswordHandler: () => void;
  memberDetailsFun: (
    key: string,
    value: string | number | null,
    objectToUpdate: string
  ) => void;
  t: any;
  handleRemoveTeam: (id: any, title: string) => void;
  isRemoveTeam: boolean;
  handleCloseTeam: () => void;
  removeMemberApiCall: () => void;
  removeTeamTitle: string;
  // token: string;
}

const EditMember = (props: EditMemberProps) => {
  const {
    classes,
    modalOpen,
    modalClose,
    memberDetails,
    errorState,
    roleChange,
    updateMemHandler,
    changeEmailHandler,
    changePasswordHandler,
    memberDetailsFun,
    t,
    handleRemoveTeam,
    isRemoveTeam,
    handleCloseTeam,
    removeMemberApiCall,
    removeTeamTitle
  } = props;

  let teamsName;
  if (memberDetails.team !== null) {
    if (memberDetails.team.length > 0) {
      teamsName = memberDetails.team.map(
        (teamName: { title: string, id: any }, index: number) => {
          return (
            <Box onClick={() => handleRemoveTeam(teamName.id, teamName.title)}>
              <Chip
                key={index}
                className={classes.chipButton}
                avatar={<Avatar alt="minus-gray" src={teamLists} />}
                label={teamName.title}
              />
            </Box>
          );
        }
      );
    }
  }

  const helperTextStyles = {
    color: '#94A3B8',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    letterSpacing: '0.0075em',
    marginTop: '-15px',
  }


  const {
    sendNetworkRequest,
    setReceiveCallback,
  } = useRunEngine();

  const fetchTitleListApiCallId = useRef("")
  const [jobTitles, setJobTitles] = useState([])

  useEffect(() => {
    setReceiveCallback(receive);
    fetchNotificationList()
  }, []);



  const receive = async (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    setJobTitles(responseJson?.data)

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (apiRequestCallId === fetchTitleListApiCallId.current) {
      }
    }
  };

  const fetchNotificationList = async() => {
    let userData=await getStorageData('userData')
    let token=''
    console.log(userData,"userData")
    if(userData){
      userData=JSON.parse(userData)
      token=userData.meta.token
    }
    const headers = {
      "Content-Type": "application/json",
      token,
    };
    sendNetworkRequest(
      fetchTitleListApiCallId,
      "GET",
      `account_block/title_list`,
      headers
    );
  }

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={modalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
      >
        <Box className={classes.modalDialogBox}>
          <Box className="modal-heading">
            <Typography className="modal-title" component="h2">
              {t(`${configJSON.editProfileTxt}`)}
            </Typography>
            <Link to="#" className="close-icon" onClick={modalClose}>
              <CloseIcon />
            </Link>
          </Box>
          <Box className="modal-description">
            <Box className={`${classes.profileInfo} profile-info`}>
              <Box className="profile-outer">
                <Box className="profile-icon">
                  {memberDetails?.firstName?.charAt(0)}
                  {memberDetails?.lastName?.charAt(0)}
                </Box>
              </Box>
              <Box className="profile-name">
                {memberDetails.firstName} {memberDetails.lastName}
              </Box>
              <Box className="job-info">
                {t(`${memberDetails.companyName}`)} - {memberDetails.jobType} (
                {memberDetails.jobRole})
              </Box>
            </Box>
            {memberDetails.team !== null && (
              <Box className="profile-team">
                <Box className="team-label">{t(`${configJSON.teamsTxt}`)}</Box>
                <Box className="chip-wrapper">
                  {memberDetails.team.length > 4 ? (
                    <Chip
                      className={`more-btn ${classes.chipButton}`}
                      label="+2 more"
                    />
                  ) : (
                    teamsName
                  )}
                </Box>
              </Box>
            )}
            <Box className="modal-form" component="form">
              <CustomInputWeb
                type="text"
                isRequired={true}
                errors={errorState.errorMemFirstName}
                label={t(`${configJSON.firstNameTxt}`)}
                value={memberDetails.firstName}
                onChange={(event) =>
                  memberDetailsFun("firstName", event.target.value, "editMembers")
                }
                data-testid="editMemFname"
              />
              <CustomInputWeb
                type="text"
                isRequired={true}
                errors={errorState.errorMemLastName}
                label={t(`${configJSON.lastNameTxt}`)}
                value={memberDetails.lastName}
                onChange={(event) =>
                  memberDetailsFun("lastName", event.target.value, "editMembers")
                }
                data-testid="editMemLname"
              />
              <Box className="form-info-wrapper">
                <Typography className="form-heading" variant="h2">
                  {t(`${configJSON.txtChooseRole}`)}
                </Typography>
                <FormControl
                  component="fieldset"
                  className={`${classes.radioChoose} `}
                  error={memberDetails.jobType != ""}
                >
                  <RadioGroup
                    defaultValue={memberDetails.jobType}
                    className="radio-group"
                    name="role"
                    onChange={(event) =>
                      roleChange(event.target.value, "editMembers")
                    }
                    value={memberDetails.jobType}
                  >
                    <FormControlLabel
                      value="owner"
                      control={<Radio />}
                      label={t(`${configJSON.txtOwner}`)}
                    />
                    <FormControlLabel
                      value="manager"
                      control={<Radio />}
                      label={t(`${configJSON.txtManager}`)}
                    />
                    <FormControlLabel
                      value="employee"
                      control={<Radio />}
                      label={t(`${configJSON.txtEmployee}`)}
                    />
                  </RadioGroup>
                  {errorState.errorMemJobType && (
                    <FormHelperText>{errorState.errorMemJobType}</FormHelperText>
                  )}
                </FormControl>
              </Box>
              <CustomInputWeb
                type="text"
                isRequired={true}
                errors={errorState.errorMemJobRole}
                label={t(`${configJSON.txtJobTitle}`)}
                value={memberDetails.jobRole}
                select={true}
                selectOption={jobTitles}
                onChange={(event) =>
                  memberDetailsFun("jobRole", event.target.value, "editMembers")
                }
                data-testid="editMemjobRole"
              />
              <CustomInputWeb
                type="text"
                disabled={true}
                isRequired={true}
                errors={errorState.errorMemEmail}
                label={t(`${configJSON.placeHolderEmail}`)}
                value={memberDetails.email}
                onChange={(event) => memberDetailsFun("email", event.target.value, "editMembers")}
                data-testid="editMememail"
                endAdornment={
                  <InputAdornment position="end">
                    <Link
                      to="#"
                      onClick={changeEmailHandler}
                      className="secondary-link"
                      data-testid="changeEmail-link"
                    >
                      {t(`${configJSON.changeEmailTxt}`)}
                    </Link>
                  </InputAdornment>
                }
              />
              <CustomInputWeb
                type="password"
                disabled={true}
                isRequired={true}
                errors={errorState.errorMemPassword}
                label={t(`${configJSON.labelPassword}`)}
                value={memberDetails.password}
                onChange={(event) => memberDetailsFun("password", event.target.value, "editMembers")}
                data-testid="editMemPwd"
                endAdornment={
                  <InputAdornment position="end">
                    <Link
                      to="#"
                      onClick={changePasswordHandler}
                      className="secondary-link"
                      data-testid="changePassword-link"
                    >
                      {t(`${configJSON.changePasswordTxt}`)}
                    </Link>
                  </InputAdornment>
                }
              />
              <CustomInputWeb
                type="text"
                isRequired={true}
                errors={errorState.errorMemHourlyPay}
                label={t(`${configJSON.ownerHourText}`)}
                value={memberDetails.hourlyPay}
                onChange={(event: any) => {
                  const val = event.target.value;
                  if (val) {
                    memberDetailsFun(
                      "hourlyPay",
                      isNaN(Number(val)) ? null : Number(val),
                      "editMembers"
                    );
                  } else {
                    memberDetailsFun("hourlyPay", null, "editMembers");
                  }
                }}
                data-testid="editMemHpay"
              />
              {memberDetails.jobType === "owner" && (
                <>
                  {" "}
                  <FormHelperText className="helper-text" style={helperTextStyles}>
                    {t(`${configJSON.calculateTxt}`)}
                  </FormHelperText>
                  <Box className={`${classes.formInfoWrapper} form-info-wrapper`}>
                    <Typography className="form-heading" variant="h2">
                      {t(`${configJSON.businessInfoTxt}`)}
                    </Typography>
                    <Box className="form-row">
                      <Box className="form-col">
                        <CustomInputWeb
                          isRequired
                          disabled={true}
                          type="text"
                          label="Company Name"
                          name="userCompanyName"
                          value={memberDetails.companyName}
                          data-test-id="userCmpname"
                        />
                      </Box>
                    </Box>
                    <Typography className="form-subheading" variant="h3">
                      {t(`${configJSON.companyAddress}`)}
                    </Typography>
                    <Box className="form-row">
                      <Box className={`form-col col6 billing-select`}>
                        <FormControl variant="outlined" className="select-outer">
                          <InputLabel id="choose-country">
                            {t(`${configJSON.countryName}`)}
                          </InputLabel>
                          <Select
                            labelId="choose-country"
                            id="choose-country"
                            value={memberDetails.country}
                            label={t(`${configJSON.countryName}`)}
                            name="country"
                            disabled={true}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                              classes: {
                                paper: classes.dropdownStyle,
                              },
                            }}
                            data-test-id="countrySelect"
                          >
                            <MenuItem
                              key={memberDetails.country}
                              value={memberDetails.country}
                            >
                              {memberDetails.country}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box className={`form-col col6 billing-select `}>
                        <FormControl variant="outlined" className="select-outer">
                          <InputLabel id="choose-state">
                            {t(`${configJSON.stateName}`)}
                          </InputLabel>
                          <Select
                            labelId="choose-state"
                            id="choose-state"
                            name="stateVal"
                            value={memberDetails.state}
                            label={t(`${configJSON.stateName}`)}
                            disabled={true}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                              classes: {
                                paper: classes.dropdownStyle,
                              },
                            }}
                            data-test-id="stateSelect"
                          >
                            <MenuItem
                              key={memberDetails.state}
                              value={memberDetails.state}
                            >
                              {memberDetails.state}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box className="form-col col6">
                        <CustomInputWeb
                          type="number"
                          label={t(`${configJSON.labelPostalCode}`)}
                          name="postalCode"
                          disabled={true}
                          value={memberDetails.postal_code}
                          data-test-id="postalCode"
                        />
                      </Box>
                      <Box className={`form-col col6 billing-select`}>
                        <FormControl
                          // required
                          className="select-outer"
                          variant="outlined"
                        >
                          <InputLabel id="choose-city">
                            {t(`${configJSON.cityName}`)}
                          </InputLabel>
                          <Select
                            labelId="choose-city"
                            id="choose-city"
                            name="city"
                            value={memberDetails.city}
                            disabled={true}
                            label={t(`${configJSON.cityName}`)}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                              classes: {
                                paper: classes.dropdownStyle,
                              },
                            }}
                            data-test-id="citySelect"
                          >
                            <MenuItem
                              key={memberDetails.city}
                              value={memberDetails.city}
                            >
                              {memberDetails.city}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <CustomInputWeb
                          type="text"
                          label={t(`${configJSON.labelCompanyAddress}`)}
                          name="userCompanyAddress"
                          disabled={true}
                          value={memberDetails.companyAddress}
                          data-test-id="cmpAdreess"
                        />
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
              {/* end*/}
              <Box className="modal-footer">
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  title="Save"
                  onClick={updateMemHandler}
                  data-testid="saveMember"
                >
                  {t(`${configJSON.saveTxt}`)}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      {isRemoveTeam && (
        <ConfirmActionModal
          isOpen={isRemoveTeam}
          handleClose={handleCloseTeam}
          modalConfirmAction={removeMemberApiCall}
          modalMessage={t(configJSON.removeConfirmationTxt, { title: removeTeamTitle })}
          confirmBtnTxt={t(`${configJSON.remove}`)}
          modalHeading={t(`${configJSON.removeTeam}`)}
          cancelBtnTxt={t(`${configJSON.txtCancel}`)}
          data-testid="confirmRemoveTeam"
        />
      )}
    </>
  );
};

export default EditMember;
// Customizable Area End
