import React, { useState } from "react";
import {
  Box,
  FormControl,
  Select,
  Typography,
  Checkbox,
  Card,
  MenuItem,
  Modal,
  Button,
  InputLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {
  extractItemDetails,
  getImageForSubCategory,
  shouldShowVolume,
  shouldShowBrandOnTop,
} from "../../../components/src/ReusableFunctions";
import { checkedIcon, closeGray } from "../../../blocks/TaskList/src/assets";
import { configJSON } from "../../../blocks/TaskList/src/CreateTaskController";
import { Link } from "react-router-dom";
import CustomInputContent from "../CustomInput.web";

const ProductItem = (props: any) => {
  const {
    classes,
    isItemChecked,
    item,
    handleItemCheckbox,
    showPriceAs,
    changePrice,
    t,
    priceToShow,
  } = props;

  const {
    category,
    subCategory,
    targetName,
    brandName,
    materialName,
    colorName,
    style,
    appellation,
    grapeVariety,
    vintage,
    volume,
    area,
    unitOfMeasurement,
    stock_qty,
    itemName,
    price,
    dosage,
    pricess,
  } = extractItemDetails(item);

  const showVolume = shouldShowVolume(subCategory);
  const showBrandOnTop = shouldShowBrandOnTop(category, subCategory, brandName);
  const fractionValues = ["1", "1/2", "1/3", "1/4", "1/5"];
  const [openConsumable, setOpenConsumable] = useState(false);
  const [dosageChange, setDosageChange] = useState("1");
  const [selectedDosage, setSelectedDosage] = useState(false)

  const handleChange = (event: any) => {
    setDosageChange(event.target.value);
  };
  const onOpenConsumableModal = () => {
    setOpenConsumable(true);
  };

  const onOpenConsumableModalClose = () => {
    setOpenConsumable(false);
  };

  const handleCheckBox = (event: any, ID: number) => {
    setSelectedDosage(false)
    handleItemCheckbox(event, item.id)
  }

  const handleContinue = (event: any) => {
    setSelectedDosage(true);
    handleItemCheckbox(event, item.id)
    setOpenConsumable(false)
  }

  return (
    <>
      <Card
        onClick={(event: any) => {
          if (item.attributes.category.static_id === 4) {
            if (
              event.target.type !== "checkbox" &&
              event.target instanceof HTMLElement
            ) {
              onOpenConsumableModal();
            }
          }
        }}
        className={`${classes.productItemsSection} inventory-checkbox`}
        style={{ border : isItemChecked ? "1px solid #2B2B2B" :"1px solid #E8ECF2"}}
      >
        <Box className="infoBox">
          {getImageForSubCategory(subCategory, item.attributes)}
          <Box className="info-box-right">
            <Typography className="in-stock-badge">
              <span className="badge-name">In Stock: </span>
              <span className="counter">{stock_qty}</span>
            </Typography>
            <Checkbox
              checked={isItemChecked}
              onChange={(event) => handleCheckBox(event, item.id)}
              className={classes.checkedItem}
              data-test-id={`itemCheckbox-${item.id}`}
              icon={<CheckBoxOutlineBlankIcon />}
              checkedIcon={<img src={checkedIcon} alt="checkbox" />}
            />
          </Box>
        </Box>
        <Box className="itemDesc titleBox">
          <Box className="desc-left">
            <Box className="date" component="span">
              {vintage} {showBrandOnTop}
            </Box>
            {item.attributes.category.static_id === 4 && (
              <>
                <Box className="date" component="span">
                  {t("Unit of measurement")}&nbsp;
                </Box>
                <span className="unit">{unitOfMeasurement}</span>
              </>
            )}
            {category === 6 && volume && (
              <Box className="task-about">
                {(vintage || showBrandOnTop) && <span className="dot" />}
                {volume}
              </Box>
            )}
          </Box>
        </Box>
        <Box className="infoBox">
          <Box className="infoBoxLeft">
            <Typography className="itemTitle">{itemName}</Typography>
            {item.attributes.category.static_id === 4 && (
              <>
                <Box className="infoText" component="span">
                  {selectedDosage ? t("Selected Dosage") : t("Max dosage")}&nbsp;
                </Box>
                <span className="unit">{selectedDosage ? dosageChange : dosage}</span>
              </>
            )}
            <Typography className="infoText">
              {item.attributes.category.static_id !== 4 && brandName}
              {style}
              {targetName !== "" && brandName !== ""
                ? `, ${targetName}`
                : targetName}
              {materialName !== "" && brandName !== ""
                ? `, ${materialName}`
                : materialName}
              {colorName !== "" && (brandName !== "" || style !== "")
                ? `, ${colorName}`
                : colorName}
              {appellation}
              {grapeVariety !== "" && appellation !== ""
                ? `, ${grapeVariety}`
                : grapeVariety}
            </Typography>
          </Box>
          {category === 6 ||
            (item.attributes.category.static_id === 4 && (
              <Box className="infoBoxRight">
                <FormControl className={classes.productsFormControl}>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: { paper: classes.dropdownStyle },
                    }}
                    IconComponent={(props) => <ExpandMoreIcon {...props} />}
                    displayEmpty
                    defaultValue={t(`${configJSON.ShowAsOption1}`)}
                    data-test-id="PriceDropdown"
                    disableUnderline
                    value={showPriceAs}
                    onChange={changePrice}
                  >
                    <MenuItem value="Retail Price">
                      {t(`${configJSON.ShowAsOption1}`)}
                    </MenuItem>
                    <MenuItem value="Wholesale Price">
                      {t(`${configJSON.ShowAsOption2}`)}
                    </MenuItem>
                    <MenuItem value="Normal Price">
                      {t(`${configJSON.ShowAsOption3}`)}
                    </MenuItem>
                  </Select>
                </FormControl>
                <Typography className="itemPrice">
                  ${(price || 0).toFixed(2)}
                </Typography>
              </Box>
            ))}
          {item.attributes.category.static_id === 1 && (
            <Box className="infoBoxRight">
              <FormControl className={classes.productsFormControl}>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  IconComponent={(props) => <ExpandMoreIcon {...props} />}
                  displayEmpty
                  defaultValue={t(`${configJSON.ShowAsOption1}`)}
                  data-test-id="PriceDropdown"
                  disableUnderline
                  value={showPriceAs}
                  onChange={(event)=>changePrice(event, pricess, item.id)}
                >
                  {pricess.map((price: any) => {
                    return (
                      <MenuItem key={price.id} value={price.id}>{price.title}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <Typography className="itemPrice">
                ${((priceToShow === 0 ? price : priceToShow) || 0).toFixed(2)}
              </Typography>
            </Box>
          )}
          {category === 10 && (
            <Box className="infoBoxRight">
              <Typography className="itemHeading">
                {showVolume ? "Volume" : "Total Area"}
              </Typography>
              <Typography className="itemPrice">
                {showVolume ? volume : area} {unitOfMeasurement}
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
      {openConsumable && (
        <Modal
          open={openConsumable}
          onClose={onOpenConsumableModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalBoxCenter}
        >
          <Box className={classes.productListViewModal}>
            <Box className="modal-heading">
              <Box className="heading-left">
                <Typography className="modal-title" component="h2">
                  {t("Choose Fertilizer Details")}
                </Typography>
                <Box component="p" className="sub-txt">
                  {t("Edit, change, or delete this consumable from this task.")}
                </Box>
              </Box>
              <Box className="heading-right">
                <Link
                  to="#"
                  className="heading-icon"
                  onClick={onOpenConsumableModalClose}
                >
                  <img src={closeGray} alt="close-icon" />
                </Link>
              </Box>
            </Box>
            <Box className="form-row">
              <Box className="form-col">
                <Box className="form-control">
                  <FormControl className="select-outer" variant="outlined">
                  <InputLabel>{t("Dosage")}</InputLabel>
                    <Select
                      label={t("Dosage")}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        classes: {
                          paper: `${classes.dropdownStyle}`,
                        },
                      }}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      data-test-id="fractionDropdown"
                      disableUnderline
                      value={dosageChange}
                      onChange={handleChange}
                    >
                      {fractionValues.map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Box>
            <Box className="quantity-container">
              <Typography className="liters-text">6.33 liters</Typography>
              <Typography className="sub-text">
                {t("Is the consumable quantity to be used")}
              </Typography>
            </Box>
            <Button onClick={(e) => handleContinue(e)} className={`primary-btn ${classes.primaryButton}`}>
              {t("Continue")}
            </Button>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ProductItem;
