Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";

exports.btnExampleTitle = "CLICK ME";
exports.txtHourlyPayout = "HOURLY PAYOUT";

exports.txtMyTeams = "My Teams";
exports.txtOrderHistory = "Order History";
exports.txtTimesheet = "Time Sheet";
exports.txtSchedule = "Schedule";
exports.txtHelpCenter = "Help Center";
exports.txtSupportChat = "Support Chat";
exports.addManuallyTxt="Add Manually";
exports.bulkUploadTxt="Bulk Upload";
exports.txtMyTeamsSubtitle = "Manage your team and move users.";
exports.txtMyTeamsSubtitleEmployee = "See your team and other company members.";
exports.txtOrderHistorySubtitle = "See what you have ordered in the past.";
exports.txtTimesheetSubtitle = "Make and download employee timesheets.";
exports.txtScheduleSubtitle = "Set your working days and time off.";
exports.txtHelpCenterSubtitle = "Raise a ticket or view the status.";
exports.txtSupportChatSubtitle = "Chat with the support team.";

exports.profileEndPoint = "/account_block/profile_list";
exports.methodGET = "GET";
exports.methodPOST = "POST";
exports.methodPUT = "PUT";
exports.methodDELETE = "DELETE";
exports.methodPATCH = "PATCH";

exports.stageState = [
  { value: 0, name: "Initiated", className: "sky-blue" },
  { value: 1, name: "Sent Proposal", className: "orange" },
  { value: 2, name: "Follow Up", className: "yellow" },
  { value: 3, name: "Won", className: "green" },
  { value: 4, name: "Lost", className: "black" },
];

exports.errorFirstnameNotValid = "First name should not be blank";
exports.errorlastNameNotValid = "Last name should not be blank";
exports.updateProfileEndPoint = "account_block/edit_details";
exports.requestMethodPatch = "PATCH";
exports.contentTypeApiAddDetail = "application/json";
exports.webgetMembersList = "/account_block/member_list";
exports.webUpdateUserEmail = "/account_block/edit_email";
exports.memberListEndPoint = "/account_block/member_list"
exports.notifications = "bx_block_push_notifications/notifications";
exports.markReadAllNotification = "bx_block_push_notifications/read_all";
exports.webUpdateUserPassword = "account_block/edit_password";
exports.webRemoveMember = "account_block/remove_member";
exports.getDealsEndpoint ="/bx_block_contactslist/deals";
exports.searchDealEndPoint = "/bx_block_contactslist/search_sort_filter_deal";
exports.addAssigneesEndPoint = "/account_block/add_assignees"
exports.removeAssigneesEndPoint = "/account_block/remove_assignees"
exports.webUpdateMemberTeam = "account_block/update_member_team";
exports.webEditMemberDetails = "account_block/edit_member_details";
exports.webDeleteJobTitleEndPoint = "account_block/jobtitle_destroy";
exports.renameJobTitleEndPoint = "account_block/title_update";
exports.getJobTitlesEndPoint = "account_block/title_list";
exports.createNewJobTitleEndPoint = "account_block/title_create";
exports.webGetCountryList = "account_block/country";
exports.editCategoryEndPoint = "/account_block/category_update";
exports.webGetCityList = "account_block/city";
exports.createCategoryEndPoint = "/account_block/multiple_category";
exports.getCategoryListEndPoint = "/account_block/categories";
exports.webGetStateList = "account_block/state";
exports.projectListEndPoint = "/account_block/projects";
exports.webTeamsGetEndPoint = "account_block/teams";
exports.createTaskListEndPoint = "/account_block/tasklist";
exports.projectTaskListViewEndPoint = "/account_block/project_tasklist_view";
exports.errorPasswordNotValid = "Password does not meet requirements";
exports.undoActionAPIEndPint = "account_block/undo"
exports.personalInfo = "Personal Information";
exports.firstName = "First name";
exports.lastName = "Last name";
exports.email="Email";
exports.changeEmail="Change Email";
exports.clickHereTxt="Click here to";
exports.browseTxt ="browse";
exports.changePassword="Change Password";
exports.assigneeTxt="Assignee";
exports.projectLeadTxt="Project Lead";
exports.txtSortBy = "Sort by"
exports.changeProjectLeadTxt="Change Project Lead";
exports.selectSubTxt="Choose the lead for this project";
exports.searchProjectLeadTxt="Search Project Lead";
exports.projectRemoveTxt ="Remove Project Lead";
exports.addNewTaskListTxt="Add New TaskList";
exports.noTaskListFoundTxt="No TaskList found with";
exports.addNewJobtxt ="Add New job Title";
exports.projectCreatedMsgTxt="Project Created Successfully";
exports.projectCreateSuccessSubTxt="You can now add tasks to this project or create another project.";
exports.noJobTitle="No Job title found with";
exports.createAnotherProject="Create Another Project";
exports.txtRemoveAllAssignees = "Remove all assignees";
exports.txtUnassigned = "Unassigned";
exports.txtMemberSelected = "Member Selected";
exports.txtAssigneeDescription = "Select the team member(s) you want to assign to this task";
exports.txtAssignees = "Assignees";
exports.aTozTxt="A-Z";
exports.zToaTxt="Z-A";
exports.membersTxt="Members";
exports.txtSearchAssignees = "Search Assignees";
exports.chooseDealsTxt="Choose Deals";
exports.chosseDealsSubTxt="Below are the all deals.";
exports.memberTxt="Member";
exports.teamTxt="Team";
exports.teamsTxt="Teams";
exports.aTozTxt="A-Z";
exports.zToaTxt="Z-A";
exports.jobTitle="Job title";
exports.noJobTitleCreated ="No job title to create";
exports.deleteJobTitke="Are you sure you want to delete Job title ?";
exports.delete = "Delete";
exports.deleteTitle="Delete Job Title";
exports.hourlyPay= "Hourly Pay";
exports.calculateTxt="This is used to calculate labor cost for projects. This will not be shown to other members."
exports.businessInfoTxt ="Business Information";
exports.companyName="Company Name";
exports.billingAddress="Billing Address";
exports.country="Country";
exports.state="State";
exports.postalCode="Postal Code";
exports.city="City";
exports.createTaskTxt="Create Tasks";
exports.editProjectTxt="Edit Project";
exports.createProjectTxt="Create Project";
exports.updateProjectTxt="Update Project";
exports.taskCreateEndPoint = "/account_block/create_task"
exports.txtCreateTask = "Create Task";
exports.labelTitleText = "TaskList";
exports.txtLetCreate = "Let's create a task.";
exports.addNewCategoryTxt="Add New Category";
exports.addInventoryTxt ="Add Inventory or Assets";
exports.expendituresTxt ="Add Expenditures";
exports.addExpenditures="Add Expenditures";
exports.addProductAssestsTxt="Add Inventory or Assets";
exports.addDealTxt="Add Deal";
exports.expendituresText="Expenditures";
exports.chooseDealsTxt="Choose Deals";
exports.attachmentsTxt="Attachments";
exports.addAttachmentsTxt="Add attachments";
exports.brandTxt="brand";
exports.txtEmergency = "Emergency";
exports.txtCritical = "Critical";
exports.txtNone = "None";
exports.txtTaskHours = "Task Hours";
exports.txtTaskHoursUnTracked = "Set Untracked Hours";
exports.txtSave = "Save";
exports.txtDone = "Done";
exports.yourPictureTxt="your files";
exports.noCategoryFoundTxt="No Category found with";
exports.noCategoryCreatedTxt ="No Category to create";
exports.confirmationTxt="Are you sure you want to delete Category ?";
exports.deleteCategoryTxt="Delete Category";
exports.txtDelete = "Delete"
exports.txtTitle = "Title";
exports.categoryTxt="Category";
exports.assigneeTxt="Assignee";
exports.txtDueDate = "Due Date";
exports.txtDeadline = "Deadline";
exports.txtDescription = "Description";
exports.startDateTxt="Start Date";
exports.dueDate="Due Date";
exports.projectTxt="Project";
exports.serchHereTxt="Search here";
exports.endDateTxt="End Date";
exports.txtTitleError = "Please enter task title.";
exports.txtDueDateError = "Please select due date.";
exports.txtDeadlineError = "Please select deadline";
exports.txtDescriptionError = "Please enter task description";
exports.companyAddress="Company Address";
exports.save="Save";
exports.continoueBtnTxt="Continue";
exports.viewSingleDeal = "bx_block_contactslist/show_deal?";
exports.newEmail="New Email";
exports.enterNewEmailTxt="Please enter the new email address.";
exports.emailAddress="Email Address";
exports.confirmationTxt=" A confirmation link will be sent to the new email address.The user will need to click on the link to verify new email."
exports.backToEdit="Back to Edit";
exports.resendIn="Resend in";
exports.dealOwnerNameTxt="deal owner";
exports.dealAmountTxt="Deal Amount";
exports.stageTxt="Stage";
exports.txtFilters = "Filters";
exports.selectContactTxt="Select Contact";
exports.txtApplyFilters = "Apply Filters"
exports.txtReset = "Reset";
exports.contactTxt="Contact";
exports.hasTasks = "Has a Tasks"; 
exports.hasAttachment = "Has an Attachment"; 
exports.hasAssociatedProduct = "Has an Associated Product"; 
exports.fromTxt="From" ;
exports.toTxt="To";
exports.closeByDateTxt="Close by Date";
exports.selectDateFilter="Select Date";
exports.onOrBefore="on or before";
exports.onOrAfter="on or After";
exports.dealsTxt="Deals";
exports.dealTxt="Deal";
exports.searchPlaceholder="Start typing to search...";
exports.didntReceiveEmail="Didn't receive the email? Resend";
exports.sendConfirmation="Send Confirmation";
exports.createPassword="Create your new password";
exports.oldPassword = "Old Password";
exports.newPassWord="New Password";
exports.letsInviteMember="Let's invite more members";
exports.myTeamText="My Teams";
exports.inviteMember="Invite Members";
exports.addTeam="Add Team";
exports.selectTeamText="Select the team(s) to add yourself as a member.";
exports.removeConfirmationTxt ="Are you sure you want to remove yourself from {{title}} team?";
exports.remove="Remove";
exports.removeTeam="Remove Team";
exports.members="Members";
exports.dashboard="Dashboard";
exports.EScore="E-Score";
exports.inventory="Inventory";
exports.marketing="Marketing";
exports.etohShop="EtOH Shop";
exports.taskList="Tasklist";
exports.createNew="Create New";
exports.notificationsTxt="Notifications";
exports.notificationsSubTxt="Stay up to date with your notifications";
exports.markAllAsRead="Mark all as read";
exports.loading="Loading...";
exports.profile="Profile";
exports.settings="Settings";
exports.helpCenter="Help Center";
exports.logout="Logout";
exports.txtCancel="Cancel";
exports.exitWithoutSave="Exit without saving";
exports.exit="Exit";
exports.saveTxt="Save";
exports.exitSubTxt="Are you sure you want to exit without saving?"
exports.task = 'Task';
exports.project = 'Project';
exports.inventory = 'Inventory Item';
exports.emailCamp = 'Email Campaign'
exports.directemail = 'Direct Email'
exports.contact = "Contact";
exports.group = "Group";
exports.deal = "Deal"
// Customizable Area End
