// Customizable Area Start
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import { starSimulate, shareScoreBackground, calendarIcon } from "./assets";
export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    popover: {
      backgroundColor: "transparent",
      "& .MuiPaper-root": {
        backgroundColor: "transparent",
        "& .MuiBox-root": {
          [theme.breakpoints.down("xs")]: {
            display: "flex",
            flexDirection: "column",
            width: "150px",
            backgroundColor: "#E8ECF2",
            border: "1px stroke #E8ECF2",
            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
            borderRadius: "12px",
          },
          "& .MuiButtonBase-root": {
            textTransform: "none",
            "& .MuiButton-label": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
            },
          },
        },
      },
    },
    HeaderWrapper: {
      padding: "60px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      [theme.breakpoints.down("md")]: {
        padding: "40px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "60px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "60px 24px 24px",
      },
      "&.profile-header": {
        padding: "24px 32px",
        flexDirection: "unset",
        background: "transparent",
        boxShadow: "none",
        "& .header-left": {
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          "& .auth-logo": {
            maxWidth: "112px",
            marginRight: "120px",
          },
        },
        "& .header-nav": {
          "& .nav-list": {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            padding: "0",
            "& .MuiListItem-root": {
              width: "auto",
              padding: "0",
              marginRight: "48px",
              "& .nav-link": {
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "19px",
                color: "#2B2B2B",
                opacity: "0.37",
                textDecoration: "none",
                fontFamily: "Expletus Sans",
              },
            },
          },
        },
        "& .header-right": {
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          marginLeft: "15px",
          "& .MuiButton-root": {
            minHeight: "40px",
            padding: "10px 9px",
          },
          "& .right-icon": {
            marginLeft: "26px",
            "& .icon-btn": {
              padding: "6px",
            },
          },
          "& .profile-icon": {
            width: "36px",
            height: "36px",
            background: "#ECF1F4",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "14px",
            lineHeight: "19px",
            color: "#2B2B2B",
            fontWeight: 500,
            fontFamily: "Expletus Sans",
          },
        },
      },
      "& .auth-logo": {
        maxWidth: "162px",
        width: "100%",
        "& img": {
          maxWidth: "100%",
        },
      },
      "& .auth-back": {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        "& i": {
          marginRight: "10px",
        },
      },
      "& .auth-right": {
        marginLeft: "20px",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        // [theme.breakpoints.down("xs")]: {
        //   marginLeft: "0",
        //   marginTop: "20px",
        //   width: "100%",
        // },
        [theme.breakpoints.down("xs")]: {
          marginLeft: "10px",
        },
        "& .MuiButton-root": {
          marginRight: "15px",
          transition: "none",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
    },
    profileInfo: {
      marginBottom: "32px",
      textAlign: "center",
      "& .profile-outer": {
        width: "120px",
        height: "120px",
        border: "1px dashed rgba(43, 43, 43, 0.87)",
        borderRadius: "100%",
        padding: "10px",
        margin: "0 auto 16px",
        "& .profile-icon": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#4BA3B7",
          width: "100%",
          height: "100%",
          borderRadius: "inherit",
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "42px",
          color: "#FFFFFF",
          fontFamily: "Expletus Sans",
          overflow: "hidden",
        },
      },
      "& .profile-name": {
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "24px",
        color: "#2B2B2B",
        marginBottom: "4px",
        fontFamily: "Expletus Sans",
      },
      "& .job-info": {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.0075em",
        color: "rgba(43, 43, 43, 0.87)",
        fontFamily: "Roboto",
      },
      " & .profile-email": {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.0075em",
        color: "rgba(43, 43, 43, 0.87)",
        fontFamily: "Roboto",
      },
    },
    dropdownStyle: {
      marginTop: "21px",
      maxWidth: "190px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "26px",
      },
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "24px",
            paddingRight: "24px",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          color: "#2B2B2B",
          padding: "14px 24px",
          "& .MuiListItemText-root": {
            margin: "0",
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
            "& .multiselect-squareCheck": {
              "& .normal-img": {
                display: "none",
              },
              "& .selected-img": {
                display: "block",
              },
            },
          },
          "& .multiselect-check": {
            display: "none",
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
          },
          "& .multiselect-squareCheck": {
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
            "& .selected-img": {
              display: "none",
            },
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "&.escore-dropdown": {
        maxWidth: "122px",
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        whiteSpace: "normal",
        "& .MuiTypography-root": {
          font: "inherit",
          letterSpacing: "0",
        },
        "& .select-icon": {
          marginRight: "6px",
        },
      },
      "& .MuiList-padding": {
        paddingTop: "4px",
        paddingBottom: "8px",
      },
    },
    newContacts:{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      gap: "15px",
      
      "& .contacts-row": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderBottom: "1px solid #ECF1F4",
        paddingBottom: "20px",
        alignItems: "center",
      },
      "& .contact": {
        display: "flex",
        flexDirection: "row",
        gap: "10px",
      },
      "& .initials": {
        width: "38px",
        height: "38px",
        background: "#ECF1F4",
        fontWeight: "bold",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "14px",
        fontFamily: "Roboto",
      },
      "& .contact-name": {
        fontSize: "14px",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
      },
      "& .contact-email": {
        fontSize: "12px",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        color: "#94A3B8",
        marginTop: "5px",
      },
    },
    campaignsBlock: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      gap: "15px",
      "& .avatar":{
        width: "30px",
        height: "20px",
        background: "#E8ECF2",
        fontWeight: "bold",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "9px",
      },
      "& .user-col-two":{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%",
      },
      "& .user-details": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: "10px",
        "& .name": {
          color: "#4F4F4F",
        },
        "& .date": {
          color: "#94A3B8",
        }
      },
      "& .card-title":{
         fontFamily: "Expletus Sans",
         fontSize: "14px",
         fontWeight: 600,
      },
      "& .card-description": {
        fontFamily: "Roboto",
        fontSize: "14px",
        width: "320px",
      },
      "& .line":{
        border: "1px solid #E8ECF2",
        width: "100%",
      },
      "& .card-tabs":{
        display: "flex",
        flexDirection: "row",
        fontFamily: "Roboto",
        justifyContent: "space-between",
        "& .card-tab-wrap": {
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        },
        "& .card-head":{
          fontSize: "10px",
          color: "#4F4F4F"
        },
        "& .val-wrap":{
          display: "flex",
          flexDirection: "row",
          gap: "4px",
        },
        "& .val-one":{
          fontSize: "12px"
        },
        "& .val-two":{
          fontSize: "10px"
        },

      }
    },
    teamModuleWrapper: {
    "& .tooltip" : {
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContents: "center",
          alignItems: "center",
          width: "80px",
          height: "60px",
          backgroundColor: "#333",
          color: "#fff",
          borderRadius: "5px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
        },
      padding: "32px 24px",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        padding: "17px 24px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "25px 0 0",
      },
      "& .MuiCheckbox-root": {
        color: "#94A3B8",
        "&:hover": {
          backgroundColor: "rgba(54, 145, 166, 0.04)",
        },
        "&.Mui-checked": {
          color: "#4ba3b7",
        },
        "& .MuiSvgIcon-root": {
          width: "20px",
          height: "20px",
        },
      },
      "& .align-see-all": {
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
        "& .see-all-txt":{
          fontSize: "14px",
          color: "#4BA3B7"
        },
        },
      "& .heading-wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexWrap: "wrap",
        marginBottom: "48px",
        padding: "0 0 0 7px",
        [theme.breakpoints.down("md")]: {
          marginBottom: "16px",
        },
        [theme.breakpoints.down("xs")]: {
          marginBottom: "24px",
          padding: "0 24px",
        },
        "& .heading-right": {
          marginLeft: "10px",
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down("xs")]: {
            marginLeft: "0",
            width: "100%",
            marginTop: "10px",
            display: "none",
          },
          "& .MuiButton-root": {
            marginRight: "18px",
            marginLeft: "0",
            "&:last-child": {
              marginRight: "0",
            },
          },
          "& .primary-btn": {
            minWidth: "122px",
          },
          "& .secondary-btn": {
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            width: "148px",
            textTransform: "capitalize",
          },
        },
        "& .members-count": {
          fontSize: "14px",
          lineHeight: "20px",
          color: "#2b2b2b",
          letterSpacing: "0.1px",
          fontFamily: "Expletus Sans",
          fontWeight: "500",
          marginRight: "14px",
        },
        "& .heading": {
          fontSize: "24px",
          lineHeight: "32px",
          margin: "0 0 6px",
          color: "#2B2B2B",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: "400",
          [theme.breakpoints.down("md")]: {
            margin: "0 0 8px",
          },
          [theme.breakpoints.down("xs")]: {
            fontSize: "22px",
            lineHeight: "26px",
            margin: "0 0 8px",
            color: "#2B2B2B",
          },
        },
        "& .sub-txt": {
          fontSize: "14px",
          lineHeight: "26px",
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontWeight: "400",
          margin: "0",
          letterSpacing: "0.105px",
          [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
            lineHeight: "17px",
          },
        },
      },
      "& .right-pane": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center !important",
        height: "96%",
      },
      "& .google-heading-wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        marginBottom: "48px",
        padding: '40px',
        borderRadius: '12px',
        border: '1px solid #E8ECF2',
        [theme.breakpoints.down("md")]: {
          marginBottom: "16px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "0 24px",
        },
        "& .see-all-txt":{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        },
        "& .heading": {
          fontSize: "24px",
          lineHeight: "32px",
          margin: "0 0 6px",
          color: "#2B2B2B",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: "400",
          [theme.breakpoints.down("md")]: {
            margin: "0 0 8px",
          },
          [theme.breakpoints.down("xs")]: {
            fontSize: "22px",
            lineHeight: "26px",
            margin: "0 0 8px",
            color: "#2B2B2B",
          },
        },
        
        "& .google-card-row": {
          display: "flex",
          margin: "24px -12px 0",
          gap:"20px",
          [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            width: "100%",
            padding: 0,
            margin: "0",
          },
          "& .followers-card": {
            flex: "0 0 50%",
            borderRadius: "0",
            border: "0",
            boxShadow: "none",
            "& .MuiCardContent-root": {
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              position: "relative",
              [theme.breakpoints.down("md")]: {
              },
              [theme.breakpoints.down("xs")]: {
                border: "none",
                padding: "0",
                display: "flex",
                alignItems: "center",
              },
              "& .right-content": {
                [theme.breakpoints.down("xs")]: {
                  marginLeft: "8px",
                },
                "& .quiz-title": {
                  fontSize: "18px",
                  letterSpacing: "0",
                  lineHeight: "24px",
                  color: "#2B2B2B",
                  fontWeight: 400,
                  fontFamily: "Expletus Sans",
                  marginBottom: "12px",
                  wordBreak: "break-word",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "14px",
                    letterSpacing: 0.0125,
                    marginBottom: "5px",
                  },
                },
                "& .quiz-date": {
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#94A3B8",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  letterSpacing: "0.09px",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "10px",
                    letterSpacing: 0.0125,
                  },
                },
              },
            },
          },
          "& .google-card": {
            flex: "0 0 24%",
            borderRadius: "0",
            border: "0",
            boxShadow: "none",
           
            "& .MuiCardContent-root": {
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              position: "relative",
              [theme.breakpoints.down("md")]: {
              },
              [theme.breakpoints.down("xs")]: {
                border: "none",
                padding: "0",
                display: "flex",
                alignItems: "center",
              },
              "& .right-content": {
                [theme.breakpoints.down("xs")]: {
                  marginLeft: "8px",
                },
                "& .quiz-title": {
                  fontSize: "18px",
                  letterSpacing: "0",
                  lineHeight: "24px",
                  color: "#2B2B2B",
                  fontWeight: 400,
                  fontFamily: "Expletus Sans",
                  marginBottom: "12px",
                  wordBreak: "break-word",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "14px",
                    letterSpacing: 0.0125,
                    marginBottom: "5px",
                  },
                },
                "& .quiz-date": {
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#94A3B8",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  letterSpacing: "0.09px",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "10px",
                    letterSpacing: 0.0125,
                  },
                },
              },
            },
          },
          "& .google-card2": {
            flex: "0 0 19%",
            borderRadius: "0",
            border: "0",
            boxShadow: "none",
           
            "& .MuiCardContent-root": {
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              position: "relative",
              [theme.breakpoints.down("md")]: {
              },
              [theme.breakpoints.down("xs")]: {
                border: "none",
                padding: "0",
                display: "flex",
                alignItems: "center",
              },
              "& .right-content": {
                [theme.breakpoints.down("xs")]: {
                  marginLeft: "8px",
                },
                "& .quiz-title": {
                  fontSize: "18px",
                  letterSpacing: "0",
                  lineHeight: "24px",
                  color: "#2B2B2B",
                  fontWeight: 400,
                  fontFamily: "Expletus Sans",
                  marginBottom: "12px",
                  wordBreak: "break-word",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "14px",
                    letterSpacing: 0.0125,
                    marginBottom: "5px",
                  },
                },
                "& .quiz-date": {
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#94A3B8",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  letterSpacing: "0.09px",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "10px",
                    letterSpacing: 0.0125,
                  },
                },
              },
            },
          },
        },
        "& .sales-card-row": {
          display: "flex",
          margin: "24px -12px 0",
          gap:"20px",
          [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            width: "100%",
            padding: 0,
            margin: "0",
          },
          "& .followers-card": {
            flex: "0 0 50%",
            borderRadius: "0",
            border: "0",
            boxShadow: "none",
            "& .MuiCardContent-root": {
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              position: "relative",
              [theme.breakpoints.down("md")]: {
              },
              [theme.breakpoints.down("xs")]: {
                border: "none",
                padding: "0",
                display: "flex",
                alignItems: "center",
              },
              "& .right-content": {
                [theme.breakpoints.down("xs")]: {
                  marginLeft: "8px",
                },
                "& .quiz-title": {
                  fontSize: "16px",
                  letterSpacing: "0",
                  lineHeight: "24px",
                  color: "#2B2B2B",
                  fontWeight: 400,
                  fontFamily: "Expletus Sans",
                  marginBottom: "12px",
                  wordBreak: "break-word",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "14px",
                    letterSpacing: 0.0125,
                    marginBottom: "5px",
                  },
                },
                "& .quiz-date": {
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#94A3B8",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  letterSpacing: "0.09px",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "10px",
                    letterSpacing: 0.0125,
                  },
                },
              },
            },
          },
          "& .google-card": {
            flex: "0 0 49%",
            borderRadius: "0",
            border: "0",
            boxShadow: "none",
           
            "& .MuiCardContent-root": {
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              position: "relative",
              [theme.breakpoints.down("md")]: {
              },
              [theme.breakpoints.down("xs")]: {
                border: "none",
                padding: "0",
                display: "flex",
                alignItems: "center",
              },
              "& .right-content": {
                [theme.breakpoints.down("xs")]: {
                  marginLeft: "8px",
                },
                "& .quiz-title": {
                  fontSize: "16px",
                  letterSpacing: "0",
                  lineHeight: "24px",
                  color: "#2B2B2B",
                  fontWeight: 400,
                  fontFamily: "Expletus Sans",
                  marginBottom: "12px",
                  wordBreak: "break-word",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "14px",
                    letterSpacing: 0.0125,
                    marginBottom: "5px",
                  },
                },
                "& .quiz-title2": {
                  fontSize: "26px",
                  letterSpacing: "0",
                  lineHeight: "24px",
                  color: "#2B2B2B",
                  fontWeight: 500,
                  fontFamily: "Expletus Sans",
                  marginBottom: "12px",
                  wordBreak: "break-word",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "14px",
                    letterSpacing: 0.0125,
                    marginBottom: "5px",
                  },
                },

                "& .quiz-date": {
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#94A3B8",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  letterSpacing: "0.09px",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "10px",
                    letterSpacing: 0.0125,
                  },
                },
              },
            },
          },
          "& .google-card2": {
            flex: "0 0 19%",
            borderRadius: "0",
            border: "0",
            boxShadow: "none",
           
            "& .MuiCardContent-root": {
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              position: "relative",
              [theme.breakpoints.down("md")]: {
              },
              [theme.breakpoints.down("xs")]: {
                border: "none",
                padding: "0",
                display: "flex",
                alignItems: "center",
              },
              "& .right-content": {
                [theme.breakpoints.down("xs")]: {
                  marginLeft: "8px",
                },
                "& .quiz-title": {
                  fontSize: "16px",
                  letterSpacing: "0",
                  lineHeight: "24px",
                  color: "#2B2B2B",
                  fontWeight: 400,
                  fontFamily: "Expletus Sans",
                  marginBottom: "12px",
                  wordBreak: "break-word",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "14px",
                    letterSpacing: 0.0125,
                    marginBottom: "5px",
                  },
                },
                "& .quiz-date": {
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#94A3B8",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  letterSpacing: "0.09px",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "10px",
                    letterSpacing: 0.0125,
                  },
                },
              },
            },
          },
        },
       



      },
      "& .box-center": {
        display: "flex",
        justifyContent: "center",
      },
      "& .google-side-wrapper": {
      
        marginBottom: "48px",
        padding: '40px',
        borderRadius: '12px',
        border: '1px solid #E8ECF2',
        [theme.breakpoints.down("md")]: {
          marginBottom: "16px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "0 24px",
        },
        "& .heading": {
          fontSize: "24px",
          lineHeight: "32px",
          margin: "0 0 6px",
          color: "#2B2B2B",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: "400",
          [theme.breakpoints.down("md")]: {
            margin: "0 0 8px",
          },
          [theme.breakpoints.down("xs")]: {
            fontSize: "22px",
            lineHeight: "26px",
            margin: "0 0 8px",
            color: "#2B2B2B",
          },
        },
        "& .marketing-row":{
          display: "flex",
          flexDirection:"column",
          margin: "24px -12px 0",
          gap:"20px",
          [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            width: "100%",
            padding: 0,
            margin: "0",
          },
          "& .followers-card": {
            flex: "0 0 50%",
            borderRadius: "0",
            border: "0",
            boxShadow: "none",
            "& .MuiCardContent-root": {
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              position: "relative",
              [theme.breakpoints.down("md")]: {
              },
              [theme.breakpoints.down("xs")]: {
                border: "none",
                padding: "0",
                display: "flex",
                alignItems: "center",
              },
              "& .right-content": {
                [theme.breakpoints.down("xs")]: {
                  marginLeft: "8px",
                },
                // "& .quiz-title": {
                //   fontSize: "18px",
                //   letterSpacing: "0",
                //   lineHeight: "24px",
                //   color: "#2B2B2B",
                //   fontWeight: 400,
                //   fontFamily: "Expletus Sans",
                //   marginBottom: "12px",
                //   wordBreak: "break-word",
                //   [theme.breakpoints.down("xs")]: {
                //     fontSize: "14px",
                //     letterSpacing: 0.0125,
                //     marginBottom: "5px",
                //   },
                // },
                // "& .quiz-date": {
                //   fontSize: "12px",
                //   lineHeight: "16px",
                //   color: "#94A3B8",
                //   fontFamily: "Roboto",
                //   fontWeight: 400,
                //   letterSpacing: "0.09px",
                //   [theme.breakpoints.down("xs")]: {
                //     fontSize: "10px",
                //     letterSpacing: 0.0125,
                //   },
                // },
              },
            },
          },
          "& .google-card": {
            flex: "0 0 24%",
            borderRadius: "0",
            border: "0",
            boxShadow: "none",
           
            "& .MuiCardContent-root": {
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              position: "relative",
              [theme.breakpoints.down("md")]: {
              },
              [theme.breakpoints.down("xs")]: {
                border: "none",
                padding: "0",
                display: "flex",
                alignItems: "center",
              },
              "& .right-content": {
                [theme.breakpoints.down("xs")]: {
                  marginLeft: "8px",
                },
                "& .quiz-title": {
                  fontSize: "18px",
                  letterSpacing: "0",
                  lineHeight: "24px",
                  color: "#2B2B2B",
                  fontWeight: 400,
                  fontFamily: "Expletus Sans",
                  marginBottom: "12px",
                  wordBreak: "break-word",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "14px",
                    letterSpacing: 0.0125,
                    marginBottom: "5px",
                  },
                },
                "& .quiz-date": {
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#94A3B8",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  letterSpacing: "0.09px",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "10px",
                    letterSpacing: 0.0125,
                  },
                },
              },
            },
          },
          "& .google-card2": {
            flex: "0 0 19%",
            borderRadius: "0",
            border: "0",
            boxShadow: "none",
           
            "& .MuiCardContent-root": {
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              position: "relative",
              [theme.breakpoints.down("md")]: {
              },
              [theme.breakpoints.down("xs")]: {
                border: "none",
                padding: "0",
                display: "flex",
                alignItems: "center",
              },
              "& .right-content": {
                [theme.breakpoints.down("xs")]: {
                  marginLeft: "8px",
                },
                "& .quiz-title": {
                  fontSize: "18px",
                  letterSpacing: "0",
                  lineHeight: "24px",
                  color: "#2B2B2B",
                  fontWeight: 400,
                  fontFamily: "Expletus Sans",
                  marginBottom: "12px",
                  wordBreak: "break-word",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "14px",
                    letterSpacing: 0.0125,
                    marginBottom: "5px",
                  },
                },
                "& .quiz-date": {
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#94A3B8",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  letterSpacing: "0.09px",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "10px",
                    letterSpacing: 0.0125,
                  },
                },
              },
            },
          },
        },

      },
      "& .search-filter-sm": {
        display: "none",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          display: "flex",
          padding: "0 24px",
          marginBottom: "3px",
        },
        "& .custom-modal": {
          padding: "0 60px 0 60px",
        },
        "& .search-wrapper": {
          flex: "1",
          [theme.breakpoints.down("xs")]: {
            width: "100%",
          },
          "& .form-control": {
            marginBottom: "0",
            "& .MuiOutlinedInput-input": {
              fontSize: "13px",
              lineHeight: "17px",
              paddingTop: "16px",
              paddingBottom: "16px",
              "&::-webkit-input-placeholder": {
                color: "#94A3B8",
              },
              "&:-ms-input-placeholder": {
                color: "#94A3B8",
              },
              "&::placeholder": {
                color: "#94A3B8",
              },
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              "& .MuiSvgIcon-root": {
                fill: "#94A3B8",
                width: "20px",
                height: "20px",
              },
            },
          },
        },
        "& .sort-group": {
          [theme.breakpoints.down("xs")]: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            height: "48px",
            minWidth: "48px",
            marginLeft: "16px",
            border: "1px solid #E8ECF2",
            cursor: "pointer",
          },
        },
        "& .filter-group": {
          [theme.breakpoints.down("xs")]: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            height: "48px",
            minWidth: "48px",
            marginLeft: "16px",
            border: "1px solid #E8ECF2",
            cursor: "pointer",
          },
        },
      },
      "& .no-member": {
        color: "rgba(0,0,0,0.87)",
        fontSize: "24px",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        lineHeight: "32px",
        letterSpacing: "0",
        margin: "50px 0",
        textAlign: "center",
        width: "100%",
        padding: "0 12px",
      },
      "& .paginationTable": {
        "& .pagination-toolbar": {
          display: "flex",
          alignItems: "center",
          "& .pagination-caption": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "0.1px",
          },
          "& .page-btn": {
            color: "#2b2b2b",
            padding: "6px",
            minWidth: "unset",
            "&.disable": {
              opacity: "0.369",
            },
          },
        },
      },
      "& .table-top": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 0 8px 24px",
        borderTop: "1px solid #e8ecf2",
        borderBottom: "1px solid #e8ecf2",
        minHeight: "52px",
        [theme.breakpoints.down("sm")]: {
          padding: "7px 0 8px 24px",
        },
        [theme.breakpoints.down("xs")]: {
          marginLeft: "24px",
          marginRight: "24px",
          padding: 0,
          border: "none",
          justifyContent: "flex-start",
          display: "none",
        },
        "& .top-left": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          [theme.breakpoints.down("xs")]: {},
          "& .search-wrapper": {
            [theme.breakpoints.down("xs")]: {
              width: "210px",
            },
            "& .MuiInput-root": {
              width: "413px",
              maxWidth: "100%",
              [theme.breakpoints.down("md")]: {
                width: "308px",
              },
              [theme.breakpoints.down("sm")]: {
                width: "234px",
              },
              [theme.breakpoints.down("xs")]: {
                // width: "170px",
                border: "1px solid #E8ECF2",
                height: "48px",
                padding: "14px 16px",
                borderRadius: "8px",
              },
            },
            "& .MuiInputAdornment-positionStart": {
              marginRight: "13px",
            },
            "& .MuiInput-underline": {
              "&:before, &:after": {
                display: "none",
              },
            },
            "& .MuiSvgIcon-root": {
              color: "#000000",
              fontSize: "22px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
              },
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.1px",
              color: "#000000",
              fontWeight: "500",
              fontFamily: "Expletus Sans",
              "&::-webkit-input-placeholder": {
                opacity: "0.369",
                color: "rgba(43, 43, 43, 0.87)",
              },
              "&:-ms-input-placeholder": {
                opacity: "0.369",
                color: "rgba(43, 43, 43, 0.87)",
              },
              "&::placeholder": {
                opacity: "0.369",
                color: "rgba(43, 43, 43, 0.87)",
              },
            },
          },
        },
        "& .top-right": {
          marginLeft: "10px",
          [theme.breakpoints.down("xs")]: {
            marginLeft: "0px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          },
          "& .filter-list": {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            "& .divider-border": {
              paddingRight: "24px",
              marginRight: "24px",
              borderRight: "1px solid #E8ECF2",
            },
            
            "& .filter-control": {
              marginRight: "24px",
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              display: "flex",
              alignItems: "center",
              opacity: "0.67",
              "& .icon": {
                marginRight: "6px",
              },
            },
            "& .select-control": {
              marginRight: "24px",
              "&:last-child": {
                marginRight: "0",
              },
            },
            "& .datepicker-wrapper": {
              marginRight: "12px",
              "& .rmdp-container": {
                width:"108%",
                "& .rmdp-input": {
                  marginBottom:"-4px",
                  border: "0",
                  padding: "0 0 0 24px",
                  minHeight: "unset",
                  backgroundPosition: "left center",
                  backgroundImage: `url(${calendarIcon})`,
                },
              },
            },
          },
          "& .filter-with-label": {
            display: "flex",
            justifyContent:"flex-start",
            alignItems: "center",
          },
          "& .select-control": {
            [theme.breakpoints.down("xs")]: {
              display: "none",
            },
          },
          "& .btn-sort": {
            display: "none",
            [theme.breakpoints.down("xs")]: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              height: "48px",
              minWidth: "48px",
              marginLeft: "16px",
              border: "1px solid #E8ECF2",
            },
          },
          "& .btn-tune-vertical": {
            display: "none",
            [theme.breakpoints.down("xs")]: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              height: "48px",
              minWidth: "48px",
              marginLeft: "16px",
              border: "1px solid #E8ECF2",
            },
          },
        },
        "& .top-middle": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginLeft: "10px",
          // [theme.breakpoints.down("sm")]: {
          //   marginLeft: "0",
          //   marginTop: "10px",
          //   width: "100%",
          // },
        },
        "& .table-selection": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginRight: "35px",
          [theme.breakpoints.down("md")]: {
            marginRight: "37px",
          },
          [theme.breakpoints.down("sm")]: {
            marginRight: "17px",
          },
          [theme.breakpoints.down("xs")]: {
            marginRight: "0",
            width: "100%",
            marginBottom: "10px",
          },
          "& .select-control": {
            marginRight: "51px",
            [theme.breakpoints.down("md")]: {
              marginRight: "50px",
            },
            [theme.breakpoints.down("sm")]: {
              marginRight: "30px",
            },
            [theme.breakpoints.down("xs")]: {
              marginRight: "0",
              width: "100%",
              marginBottom: "15px",
            },
            "&:last-child": {
              marginRight: "0",
              [theme.breakpoints.down("xs")]: {
                marginBottom: "0",
              },
            },
          },
        },
        "& .select-control": {
          "&.multi-select": {
            "& .multiselect-label": {
              display: "none",
            },
            "& .MuiInput-formControl": {
              marginTop: "0",
            },
            "& .MuiSelect-select": {
              paddingRight: "40px",
            },
            "& .multiple-clear": {
              position: "absolute",
              fontSize: "15px",
              right: "18px",
              top: "3px",
              zIndex: 1,
              color: "#94A3B8",
            },
          },
          "&.score": {
            "&.multi-select": {
              "& .MuiSelect-select": {
                paddingRight: "0",
              },
            },
            "& .select-icon": {
              top: "3px",
            },
          },
          "&.sortby-control": {
            "& .MuiSelect-select": {
              paddingRight: "0",
            },
            "& .select-icon": {
              top: "3px",
            },
          
          },
          "& .select-icon": {
            position: "relative",
            marginRight: "6px",
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0.1px",
            color: "rgba(43, 43, 43, 0.87)",
            opacity: "0.671",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            paddingBottom: "0",
            paddingTop: "0",
            "&:focus": {
              backgroundColor: "transparent",
            },
            "& .clear-icon": {
              width: "50px",
              height: "50px",
              background: "red",
            },
          },
          "& .MuiInput-underline:before": {
            display: "none",
          },
          "& .MuiInput-underline:after": {
            display: "none",
          },
        },
      },
      "& .escore-card-row": {
        display: "flex",
        flexWrap: "wrap",
        margin: "24px -12px 0",
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
          width: "100%",
          padding: 0,
          margin: "0",
        },
        "& .escore-card": {
          flex: "0 0 20%",
          maxWidth: "20%",
          borderRadius: "0",
          border: "0",
          padding: "0 12px",
          boxShadow: "none",
          marginBottom: "24px",
          [theme.breakpoints.down("md")]: {
            flex: "0 0 25%",
            maxWidth: "25%",
            padding: "0 10px",
          },
          [theme.breakpoints.down("sm")]: {
            flex: "0 0 33.33%",
            maxWidth: "33.33%",
          },
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            maxWidth: "100%",
            flex: "0 0 100%",
            padding: "16px 24px",
            marginBottom: "0",
          },
          "& .MuiCardContent-root": {
            padding: "40px",
            borderRadius: "12px",
            border: "1px solid #E8ECF2",
            position: "relative",
            [theme.breakpoints.down("md")]: {
              padding: "26px",
            },
            [theme.breakpoints.down("xs")]: {
              border: "none",
              padding: "0",
              display: "flex",
              alignItems: "center",
            },
            "&:hover": {
              border: "1px solid #12AEF1",
              boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
              cursor: "pointer",
              [theme.breakpoints.down("xs")]: {
                border: "none",
                boxShadow: "unset",
              },
              "& .button-wrapper": {
                display: "block",
                [theme.breakpoints.down("xs")]: {
                  display: "none",
                },
              },
            },
            "& .quiz-score": {
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
              [theme.breakpoints.down("xs")]: {
                marginBottom: "0px",
              },
              "& .score": {
                marginRight: "4px",
                width: "24px",
                height: "24px",
                [theme.breakpoints.down("xs")]: {
                  width: "36px",
                  height: "36px",
                },
              },
              "& .score-text": {
                fontSize: "12px",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Roboto",
                fontWeight: 400,
                letterSpacing: "0.09px",
                [theme.breakpoints.down("xs")]: {
                  display: "none",
                },
              },
            },
            "& .right-content": {
              [theme.breakpoints.down("xs")]: {
                marginLeft: "8px",
              },
              "& .quiz-title": {
                fontSize: "18px",
                letterSpacing: "0",
                lineHeight: "24px",
                color: "#2B2B2B",
                fontWeight: 400,
                fontFamily: "Expletus Sans",
                marginBottom: "12px",
                wordBreak: "break-word",
                [theme.breakpoints.down("xs")]: {
                  fontSize: "14px",
                  letterSpacing: 0.0125,
                  marginBottom: "5px",
                },
              },
              "& .quiz-date": {
                fontSize: "12px",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Roboto",
                fontWeight: 400,
                letterSpacing: "0.09px",
                [theme.breakpoints.down("xs")]: {
                  fontSize: "10px",
                  letterSpacing: 0.0125,
                },
              },
            },
            "& .score-menu": {
              display: "none",
              [theme.breakpoints.down("xs")]: {
                display: "block",
                padding: "0",
                marginLeft: "auto",
                minWidth: "unset",
                width: "24px",
              },
              "& .img-dot": {
                width: "20px",
                height: "20px",
                objectFit: "contain",
              },
              "& .MuiButton-root": {
                [theme.breakpoints.down("xs")]: {
                  minWidth: "24px",
                  minHeight: "24px",
                  padding: 0,
                  margin: 0,
                  fontFamily: "Expletus Sans",
                  textAlign: "center",
                  textTransform: "unset",
                  textDecoration: "none",
                  "& .MuiButton-label": {
                    textTransform: "unset",
                    textDecoration: "none",
                  },
                },
              },
              "& .MuiPopover-root": {
                "& .MuiPaper-root": {
                  "& .MuiBox-root": {
                    [theme.breakpoints.down("xs")]: {
                      display: "flex",
                      flexDirection: "column",
                      width: "150px",
                      backgroundColor: "#E8ECF2",
                    },
                    "& .MuiButtonBase-root": {
                      textTransform: "none",
                    },
                  },
                },
              },
            },
            "& .button-wrapper": {
              display: "none",
              marginTop: "16px",
              [theme.breakpoints.down("xs")]: {
                display: "none",
              },
              "& .MuiButton-root": {
                marginRight: 0,
                marginBottom: "8px",
                borderRadius: "62px",
                boxShadow: "none",
                width: "100%",
                height: "48px",
                marginLeft: 0,
                "&:last-child": {
                  marginBottom: 0,
                },
              },
            },
          },
        },
        "& .btn-take-equiz": {
          display: "none",
          [theme.breakpoints.down("xs")]: {
            display: "flex",
            position: "fixed",
            bottom: "48px",
            right: "24px",
            zIndex: 1000,
            padding: "14px 24px",
            borderRadius: "20px",
            backgroundColor: "#4BA3B7",
            boxShadow: "0px 0px 24px 0px rgba(5, 111, 135, 0.47)",
            textTransform: "unset",
            textDecoration: "none",
            color: "#FFFFFF",
          },
        },
      },

    },
    dashboardWrapper: {
      "& .header-wrapper": {
        padding: "16px 24px",
        "& .auth-logo": {
          maxWidth: "118px",
        },
      },
      position: "relative",
      "& .sub-cards": {
        position: "relative", 
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginBottom: "10px",
        
      },
      "& .card-val": {
        fontSize: "23px",
        marginBottom: "5px",
      },
      "& .card-container-one": {
        display: "grid",
        gridAutoColumns: "46%",
      },
      "& .ongoing-tasks-box": {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "10px",
        "& .followers-card": {
            flex: "0 0 50%",
            borderRadius: "0",
            border: "0",
            boxShadow: "none",
            "& .MuiCardContent-root": {
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              position: "relative",
              [theme.breakpoints.down("md")]: {
              },
              [theme.breakpoints.down("xs")]: {
                border: "none",
                padding: "0",
                display: "flex",
                alignItems: "center",
              },
              "& .right-content": {
                overflow: "visible",
                [theme.breakpoints.down("xs")]: {
                  marginLeft: "8px",
                },
                "& .quiz-title": {
                  fontSize: "18px",
                  letterSpacing: "0",
                  lineHeight: "24px",
                  color: "#2B2B2B",
                  fontWeight: 400,
                  fontFamily: "Expletus Sans",
                  marginBottom: "12px",
                  wordBreak: "break-word",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "14px",
                    letterSpacing: 0.0125,
                    marginBottom: "5px",
                  },
                },
                "& .quiz-date": {
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#94A3B8",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  letterSpacing: "0.09px",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "10px",
                    letterSpacing: 0.0125,
                  },
                },
              },
            },
          },
      },
      "& .ongoing-tasks-card": {
        display: "flex",
        gap: "15px",
        flexDirection: "column",
        paddingTop: "5px",
        paddingBottom: "5px",
        "& .row-one":{
          fontFamily: "Expletus Sans",
          fontSize: "16px",
          fontWeight: 400,
          display: "flex",
          justifyContent: "space-between",
        },
        "& .row-two":{
          display: "flex",
          justifyContent: "space-around",
          paddingLeft: "5px",
          paddingRight: "5px",
          "& .row-elem": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5px"
          },
          "& .sub-head": {
            fontSize: "12px",
            color: "#94A3B8"
          },
        },
        "& .row-three": {
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          "& .sub-head": {
            fontSize: "12px",
            color: "#94A3B8"
          },
        }
      },
      "& .performance-card-container": {
        display: "grid",
        gridAutoFlow: "column",
        gridAutoColumns: "50%",
        gap: "15px",
        marginTop: "38px",
      },
      "& .performance-metrics-card": {
        border: "1px solid #E8ECF2",
        borderRadius: "12px",
        padding: "18px",
      },
      "& .pl-row-three": {
        fontSize: "12px",
        color: "#94A3B8",
        marginTop: "-6px",
        marginBottom: "5px",
      },
      "& .budget-cards-container": {
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        marginTop: "38px",
      },
      "& .heading-container": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginBottom: "30px",
      },
      "& .sub-head-container": {
        display: "flex",
        alignItems: "center",
        gap: "6px",
        color: "#2B2B2B",
        fontSize: "13px",
        fontWeight: 400,

      },
      "& .radio-container-head": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "15px",
        marginLeft: "5px"
      },  
      "& .tabs-container": {
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        marginTop: "40px",
      },
      "& .right-tabs-container":{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        marginTop: "40px",
      },
      "& .quizzes-container": {
        width: "100%",
      },
      "& .check-box-styles": {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      },
      "& .checked-styles": {
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      "& .e-score-img": {
        position: "absolute",
        bottom: 0,
        right: 10,
      },
      "& .right-tabs":{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        alignItems: "flex-start",
        border: "1px solid #E8ECF2",
        padding: "12px",
        borderRadius: "12px",
        width: "100%",
      },
      "& .tabs": {
        minWidth: "74px",
        maxWidth: "auto",
        height: "27px",
        border: "1px solid #E8ECF2",
        borderRadius: "6px",
        fontSize: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px",
        marginTop: "-10px",
      },
      "& .activeTab": {
        minWidth: "74px",
        maxWidth: "auto",
        height: "27px",
        border: "1px solid #4BA3B7",
        borderRadius: "6px",
        fontSize: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px",
        marginTop: "-10px",
        color: "#4BA3B7"
      },
      "& .owner-activeTab": {
        minWidth: "74px",
        maxWidth: "auto",
        height: "27px",
        border: "1px solid #4BA3B7",
        borderRadius: "6px",
        fontSize: "14px",
        fontWeight: "500",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        marginTop: "-10px",
        color: "#4BA3B7"
      },
      "& .owner-tabs": {
        minWidth: "74px",
        maxWidth: "auto",
        height: "27px",
        borderRadius: "6px",
        fontSize: "14px",
        fontWeight: "500",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px",
        marginTop: "-10px",
      },
      "& .optionContainers":{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        marginBottom: "10px",
      },
      "& .options" :{
        display: "flex",
        flexDirection: "row",
        gap: "10px"
      },
      "& .optionContainer": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "20px",
      },
      "& .textContainer": {
        display: "flex",
        flexDirection: "column",
      },
      "& .heading": {
        fontSize: "16px",
        fontWeight: "400",
      },
      "& .description": {
        fontSize: "12px",
        color: "#555",
      },
      "& .pl_dashboard_head": {
        fontSize: "12px",
        color: "#2B2B2B",
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        alignItems: "center",
        marginBottom: "5px",
      },
      "& .head_align":{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "8px"
      },
      "& .pl_tabs_container": {
        marginTop: "30px",
      },
      "& .tasks-over-tracked": {
        color: "#C7263E"
      },
      "& .hours-over-tracked": {
        color: "#C7263E"
      },
      "& .improve-btn": {
        width: "100%",
        color: "fff",
        fontSize: "14px",
        fontWeight: 500,
        background: "#4BA3B7",
        borderRadius: "6px",
        marginTop: "25px",
      },
      "& .suggestions-container": {
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        fontFamily: "Expletus Sans",
      },
      "& .got-it-btn": {
        width: "100%",
        height: "50px",
        fontSize: "14px",
        fontWeight: 500,
         color: "fff",
         background: "#4BA3B7",
         marginTop: "30px",
      },

    },
    filterBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
    dropdown_ele: {
      fontFamily: "Expletus Sans",
      paddingTop: "15px",
      paddingBottom: "15px",
      width: "150px",	
    },

    emptyDataBox: {
      minHeight: "54vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .emptydata-row": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        maxWidth: "809px",
        margin: "25px auto",
        [theme.breakpoints.down("md")]: {
          maxWidth: "685px",
        },
        [theme.breakpoints.down("sm")]: {
          maxWidth: "627px",
        },
        [theme.breakpoints.down("xs")]: {
          flexWrap: "wrap",
          justifyContent: "center",
          textAlign: "center",
          maxWidth: "100%",
          padding: "0 24px",
          margin: "18px auto",
        },
        "& .emptydata-img": {
          maxWidth: "389px",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            maxWidth: "280px",
          },
          [theme.breakpoints.down("sm")]: {
            maxWidth: "240px",
          },
          [theme.breakpoints.down("xs")]: {
            maxWidth: "327px",
          },
        },
      },
      "& .emptydata-content": {
        marginLeft: "93px",
        flex: "1",
        [theme.breakpoints.down("md")]: {
          marginLeft: "77px",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "59px",
        },
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          marginTop: "50px",
          width: "100%",
        },
      },
      "& .heading": {
        fontSize: "20px",
        lineHeight: "27px",
        margin: "0 0 12px",
        color: "rgba(0, 0, 0, 0.87)",
        letterSpacing: "0.25px",
        fontFamily: "Expletus Sans",
        fontWeight: "400",
        // [theme.breakpoints.down("md")]: {
        //   fontSize: "18px",
        //   lineHeight: "24px",
        // },
        [theme.breakpoints.down("xs")]: {
          fontSize: "18px",
          lineHeight: "21px",
          letterSpacing: "0.03em",
          color: "#363636",
          fontFamily: "Roboto",
          fontWeight: 700,
          margin: "0 0 16px",
        },
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "20px",
        margin: "0 0 32px",
        color: "#2b2b2b",
        opacity: "0.671",
        fontFamily: "Roboto",
        fontWeight: "400",
        letterSpacing: "0.25px",
        // [theme.breakpoints.down("md")]: {
        //   margin: "0 0 32px",
        // },
        [theme.breakpoints.down("xs")]: {
          letterSpacing: "0.03em",
          color: "#7A7A7A",
          margin: "0 0 24px",
        },
      },
    },
    tabWrapper: {
      "& .MuiTabs-flexContainer": {
        margin: "0 30px",
        [theme.breakpoints.down("xs")]: {
          margin: "0 24px",
        },
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "#3691a6",
      },
      "& .MuiTabs-root": {
        borderBottom: "0",
        [theme.breakpoints.down("xs")]: {
          borderBottom: "1px solid #E8ECF2",
        },
      },
      "& .MuiTab-root": {
        fontSize: "14px",
        lineHeight: "20px",
        color: "#2b2b2b",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        textTransform: "unset",
        letterSpacing: 0,
        opacity: "0.671",
        [theme.breakpoints.up("sm")]: {
          minWidth: "166px",
        },
        [theme.breakpoints.down("xs")]: {
          width: "50%",
        },
        "&.Mui-selected": {
          opacity: "1",
        },
      },
      "& .MuiTabPanel-root": {
        padding: "0",
      },
      "& .button-wrapper": {
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down("xs")]: {
          justifyContent: "space-between",
        },
        "& .MuiButton-root": {
          marginRight: "14px",
          marginLeft: "0",
          [theme.breakpoints.down("xs")]: {
            marginRight: "0",
            width: "48%",
            minWidth: "unset",
          },
          "&:last-child": {
            marginRight: "0",
          },
        },
        "& .btn-create-your-own":{
          textTransform: "capitalize"
        }
      },
    },
    datepickerWrapper: {
      "& .rmdp-container": {
        width: "100%",
        "& .rmdp-input": {
          backgroundImage: `url(${calendarIcon})`,
          backgroundRepeat: "no-repeat",
          color: "#2B2B2B",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "normal",
          padding: "20px 55px 20px 24px",
          borderRadius: "6px",
          height: "auto",
          border: "1px solid #E8ECF2",
          width: "100%",
          backgroundPosition: "calc(100% - 24px) center",
          minHeight: "56px",
          "&:focus": {
            boxShadow: "none",
          },
          "&::-webkit-input-placeholder": {
            color: "#94A3B8",
          },
          "&:-ms-input-placeholder": {
            color: "#94A3B8",
          },
          "&::placeholder": {
            color: "#94A3B8",
          },
        },
        "& .rmdp-wrapper": {
          boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
          borderRadius: "24px",
          "& .rmdp-header-values": {
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "15.696px",
            fontWeight: 700,
            lineHeight: "19.184px",
            "& span": {
              padding: "0",
            },
          },
          "& .rmdp-arrow": {
            borderColor: "#94A3B8",
            height: "6px",
            padding: "4px",
            width: "6px",
          },
          "& .rmdp-arrow-container": {
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          },
          "& .rmdp-left": {
            "& i": {
              marginLeft: "6px",
            },
          },
          "& .rmdp-right": {
            "& i": {
              marginRight: "6px",
            },
          },
          "& .rmdp-week-day": {
            color: "#2B2B2B",
            fontSize: "16.773px",
            fontWeight: "500",
            lineHeight: "normal",
            fontFamily: "Roboto",
            height: "40px",
            width: "40px",
          },
          "& .rmdp-calendar": {
            padding: "39px 19px 16px",
          },
          "& .rmdp-day-picker": {
            padding: "0",
          },
          "& .rmdp-day": {
            color: "#2B2B2B",
            height: "40px",
            width: "40px",
            "&.rmdp-selected": {
              "& span": {
                "&:not(.highlight)": {
                  backgroundColor: "#4BA3B7",
                  color: "#fff",
                  boxShadow: "none",
                },
              },
            },
            "&:not(.rmdp-disabled)": {
              "&:not(.rmdp-day-hidden)": {
                "& span": {
                  "&:hover": {
                    backgroundColor: "#4BA3B7",
                    color: "#fff",
                  },
                },
              },
            },
            "& span": {
              fontSize: "16.773px",
              fontWeight: "500",
              lineHeight: "normal",
              fontFamily: "Roboto",
              padding: 0,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            },
            "&.rmdp-today": {
              "& span": {
                backgroundColor: "transparent",
                color: "#7fdbff",
              },
            },
          },
          "& .rmdp-range": {
            backgroundColor: "#ECF1F4",
            boxShadow: "none",
          },
          "& .rmdp-range, & .rmdp-range-hover": {
            "&.rmdp-today": {
              "&.start, &.end": {
                "& span": {
                  backgroundColor: "#4BA3B7",
                  color: "#fff",
                },
              },
            },
            "&.start, &.end": {
              color: "#fff",
              "& span": {
                background: "#4BA3B7",
              },
            },
          },
          "& .rmdp-button-wrapper": {
            borderRadius: "0 0 24px 24px",
            background: "rgba(43, 43, 43, 0.87)",
            "& .button-actions": {
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "0 19px 19px",
              borderRadius: "0 0 24px 24px",
              background: "#ffffff",
              "& .secondary-btn": {
                minWidth: "unset",
                border: 0,
                padding: "6px 12px",
                minHeight: "30px",
                color: "#4BA3B7",
                marginRight: "12px",
                width: "auto",
              },
              "& .primary-btn": {
                minWidth: "unset",
                border: 0,
                padding: "6px 12px",
                minHeight: "30px",
                width: "auto",
              },
            },
            "& .button-bottom-actions": {
              padding: "24px 15px",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
              "& .tasklist-link": {
                color: "#FFF",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "26px",
                letterSpacing: "0.105px",
                textDecoration: "none",
                marginRight: "16px",
                "&:last-child": {
                  marginRight: "0",
                },
              },
            },
          },
        },
      },
    },

    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "7px",
      //filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "22px",
      color: "#ffffff",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      width: "auto",
      minWidth: "156px",
      textDecoration: "none",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
      marginLeft: "15px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "0px",
      },
    },
    secondaryButton: {
      background: "transparent",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      width: "156px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      marginRight: "16px",
      transition: "all 0.5s ease-in-out",
      letterSpacing: "0",
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    eScoreTabBtn:{
      background: "#ffffff",
      border: "1px solid #4BA3B7",
      borderRadius: "6px",
      fontSize: "14px",
      color: "#4BA3B7",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      padding: "12px 10px",
      height: "31px",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      width: "76px",
      textDecoration: "none",
      "&:hover": {
        background: "#ffffff",
        color: "#4BA3B7",
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: "0px",
      },
    },

   chooseBtn: {
      background: "transparent",
      border: "1px solid #e5e9f0",
      borderRadius: "6px",
      filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#4BA3B7",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      // width: "421px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      // marginRight: "16px",
      transition: "all 0.5s ease-in-out",
      letterSpacing: "0",
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    wrapper: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    authMainWrapper: {
      "& .add-option-wrapper": {
        borderRadius: "8px",
        filter: "drop-shadow(0px 1px 2.5px rgba(0,0,0,0.2))",
        backgroundColor: "#fafafa",
        padding: "19px 22px 22px",
        margin: "5px 0",
        "& .MuiOutlinedInput-input": {
          padding: "10px 0",
          fontSize: "14px",
          letterSpacing: "0px",
          lineHeight: "20px",
          color: "#000000",
          fontWeight: 500,
          fontFamily: "Expletus Sans",
        },
        "& .MuiFormLabel-root": {
          transform: "none",
        },
        "& .MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "transparent",
          },
        "& .form-control": {
          marginBottom: "0",
        },
        "& .button-wrapper": {
          borderTop: "1px solid #e8ecf2",
          marginTop: "9px",
          paddingTop: "16px",
          display: "flex",
          justifyContent: "flex-end",
          "& .add-link": {
            marginRight: "28px",
            opacity: "0.671",
            fontSize: "14px",
            lineHeight: "18px",
            letterSpacing: "0",
            color: "#4ba3b7",
            fontWeight: 500,
            fontFamily: "Expletus Sans",
            "&.secondary-link": {
              color: "#2b2b2b",
            },
            "&:last-child": {
              marginRight: "0",
            },
            "&:hover": {
              textDecoration: "underline",
            },
          },
        },
      },
      "& .wrapper": {
        paddingBottom: "0",
      },

      "& .header-wrapper": {
        display: "none",
        [theme.breakpoints.down("sm")]: {
          display: "flex",
        },
      },
      "& .footer-wrapper": {
        marginLeft: "576px",
        [theme.breakpoints.down("md")]: {
          marginLeft: "470px",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "0",
        },
      },
    },
    innerWrapper: {
      width: "100%",
    },
    taskDashboard:{
      display: "grid",
      gridTemplateColumns: "25% 75%",
      height: "100vh", 
      "& .card-head": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: "14px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
      },
      "& .left-pane": {
        padding: "20px",
        borderRight: "7px solid #E8ECF2",
      },
      "& .task-card": {
        display: "flex",
        flexDirection: "column",
        border: "1px solid #E8ECF2",
        padding: "20px",
        borderRadius: "10px",
      },
      "& .row-1": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      "& .date": {
        display: "flex",
        gap: "5px",
        alignItems: "center",
        color: "#2B2B2B",
        fontSize: "12px",
      },
      "& .col-1": {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      },
      "& .col-2": {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        alignItems: "end",
        fontSize: "10px",
      },
      "& .right-cols": {
        border: "3px dashed #E8ECF2",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        borderRadius: "20px",
        height: "auto",
      },
      "& .progress-bar-container": {
        display: "flex", 
        alignItems: "center", 
        gap: "10px", 
        width: "100%",
        marginTop: "10px",
      },
      
      "& .progress-bar-fill": {
        height: "10px",
        backgroundColor: "#4ba3b7", 
        transition: "width 0.3s ease-in-out", 
        borderRadius: "5px 0 0 5px",
        flexGrow: 1, 
      },
      "& .progress-bar-background": {
        height: "4px",
        width: "100%", 
        backgroundColor: "#e8ecf2", 
        borderRadius: "5px",
        overflow: "hidden",
        position: "relative",
      },
      
      "& .progress-bar-text": {
        fontSize: "12px",
        color: "#2b2b2b", 
        whiteSpace: "nowrap", 
      },
      "& .accordion-container": {
        position: "relative",
        padding: "10px",
        fontFamily: "Expletus Sans",
        // borderRight: "1px solid #e8ecf2",
        height: "100%", 
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      },
      "& .accordion-container:before": {
        content: "",
        position: "absolute",
        left: "15px", 
        top: "20px", 
        width: "1px",
        height: "calc(100% - 20px)",
        backgroundColor: "#e8ecf2", 
        zIndex: 0,
      },
      
      "& .custom-accordion": {
        boxShadow: "none",
        border: "none"
      },
      
      "& .accordion-header": {
        fontSize: "16px",
        color: "#2b2b2b",
        fontFamily: "Expletus Sans",
      },
      
      "& .accordion-details": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        position: "relative",
        width: "100%",
        // paddingLeft: "30px",
      },
      
      "& .accordion-item ": {
        display: "flex",
        justifyContent: "space-between",
        fontSize: "14px",
        fontWeight: 500,
        color: "#2b2b2b",
        padding: "5px 0",
        zIndex: 1,
        backgroundColor: "transparent", 
        borderRadius: "5px",
        position: "relative",
        cursor: "pointer",
      },
      "& .accordion-item .text-span": {
        backgroundColor: "transparent", 
        padding: "8px 20px",
        borderRadius: "3px",
      },
      "& .accordion-item.active .text-span": {
        backgroundColor: "#F2F8FC",
      },
      
      "& .accordion-item:before": {
        content: "",
        position: "absolute",
        left: "-15px", 
        top: "50%",
        transform: "translateY(-50%)",
        width: "10px",
        height: "10px",
        backgroundColor: "white",
        border: "2px solid #e8ecf2", 
        borderRadius: "50%", 
        zIndex: 1,
      },
      
      "& .accordion-item:last-child:before": {
        display: "none",
      },
      
      "& .custom-accordion .MuiAccordionSummary-root": {
        padding: 0,
        margin: 0,
      },
      
      "& .custom-accordion .MuiAccordionSummary-expandIcon": {
        color: "#94A3B8", 
      },
      
      "& .custom-accordion .MuiAccordionDetails-root": {
        padding: "0 10px",
        margin: 0,
      },
      
    },
    authWrapper: {
      textAlign: "left",
      "& img": {
        maxWidth: "100%",
      },
      "& .auth-inner-block": {
        display: "flex",
        flexWrap: "wrap",
        "& .auth-right": {
          flex: "1",
          padding: "64px 63px 142px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          [theme.breakpoints.down("md")]: {
            padding: "64px 63px 83px",
          },
          [theme.breakpoints.down("sm")]: {
            padding: "60px 60px 139px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "24px 24px 99px",
          },
          "& .right-inner-content": {
            maxWidth: "512px",
            width: "100%",
            margin: "auto",
          },
          "& .right-inner": {
            display: "flex",
            flexDirection: "column",
            height: "100%",
          },
          "& .back-logout-wrap": {
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            margin: "0 0 90px",
            [theme.breakpoints.down("md")]: {
              margin: "0 0 50px",
            },
          },
          "& .back-btn-wrap": {
            [theme.breakpoints.down("sm")]: {
              position: "absolute",
              top: "120px",
            },
            [theme.breakpoints.down("xs")]: {
              position: "absolute",
              top: "110px",
            },
            "& i": {
              display: "block",
            },
          },
        },
      },
      "& .heading": {
        fontSize: "24px",
        lineHeight: "30px",
        margin: "0 0 20px",
        color: "#000000",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        // [theme.breakpoints.down("md")]: {
        //   fontSize: "20px",
        //   lineHeight: "24px",
        //   margin: "0 0 15px",
        // },
        // [theme.breakpoints.down("sm")]: {
        //   fontSize: "18px",
        //   lineHeight: "24px",
        //   margin: "0 0 10px",
        // },
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        margin: "0 0 48px",
        color: "#505050",
        fontFamily: "Expletus Sans",
        [theme.breakpoints.down("md")]: {
          margin: "0 0 30px",
        },
      },
      "& .form-border": {
        borderRadius: "12px",
        border: "1px solid #e8ecf2",
        padding: "15px",
      },
      "& .form-info-wrapper": {
        margin: "0 0 12px",
      },
      "& .invite-member-block": {
        "&.auth-inner-block": {
          "& .auth-right": {
            "& .back-logout-wrap": {
              margin: "0 0 56px",
            },
          },
        },
        "& .heading": {
          margin: "0 0 11px",
        },
        "& .sub-txt": {
          margin: "0 0 37px",
        },
        "& .form-row": {
          "&:last-child": {
            "& .form-control": {
              margin: "0",
            },
          },
        },
      },
      "& .team-button-wrapper": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        // marginTop: "49px",
        marginTop: "44px",
        "&.full-btn": {
          "& .MuiButton-root": {
            width: "100%",
          },
        },
        "& .MuiButton-root": {
          minHeight: "56px",
          width: "auto",
          minWidth: "156px",
          [theme.breakpoints.down("xs")]: {
            width: "48%",
            minWidth: "110px",
          },
        },
      },
      "& .form-heading": {
        fontSize: "14px",
        lineHeight: "20px",
        margin: "0 0 20px",
        color: "#000000",
        letterSpacing: 0,
        fontFamily: "Expletus Sans",
        fontWeight: 500,
      },
      "& .form-row": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        margin: "0 -15px",
        [theme.breakpoints.down("xs")]: {
          margin: "0 -10px",
        },
        "& .form-col": {
          padding: "0 15px",
          flex: "0 0 100%",
          maxWidth: "100%",
          [theme.breakpoints.down("xs")]: {
            padding: "0 10px",
          },
        },
        "& .col6": {
          flex: "0 0 50%",
          maxWidth: "50%",
        },
      },
      "& .page-link": {
        textAlign: "right",
        marginBottom: "95px",
        [theme.breakpoints.down("md")]: {
          marginBottom: "60px",
        },
        [theme.breakpoints.down("xs")]: {
          marginBottom: "40px",
        },
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#505050",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        "& .form-link": {
          marginLeft: "10px",
        },
      },
    },
    modalDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      padding: "82px 0",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        width: 548,
        padding: "47px 0",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "100%",
        padding: "0px 24px 24px",
      },

      "&.filter-dialogbox": {
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
          padding: "24px",
          width: "100%",

          height:"50%",
          top:"auto",
          borderRadius:"50px 50px 0 0",
        },
        [theme.breakpoints.down(480)]: {
          padding: "15px",
          borderRadius:"40px 40px 0 0",
        },
        "& .modal-heading": {
          marginBottom: "40px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "20px",
          },
          "& .filter-heading": {
            display: "flex",
            alignItems: "center",
            "& .auth-back": {
              cursor: "pointer",
            },
            "& .modal-title ": {
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "Expletus Sans",
              marginLeft: "24px",
            },
            
            "& .modal-sub-head": {
              fontSize: "14px",
              display: "block",
              marginTop: "10px !important",
            },
            "& .head-align": {
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }
          },
          "& .filter-link": {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "19px",
            color: "#4BA3B7",
            fontFamily: "Expletus Sans",
            textDecoration: "none",
          },
        },
        "& .modal-form": {
          marginTop: "0",
          "& .form-info-wrapper": {
            "& .form-heading": {
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              marginBottom: "16px",
              letterSpacing: "0",
            },
            "& .form-control": {
              "& .MuiInputBase-input": {
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-root": {
                "&.MuiOutlinedInput-adornedEnd": {
                  paddingRight: "27px",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  width: "20px",
                  height: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                padding: "20px 24px",
                "& .MuiOutlinedInput-inputAdornedEnd": {
                  paddingRight: "0",
                },
              },
            },
            "& .select-control": {
              "&.multi-select": {
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                  marginBottom: "32px",
                },
                "& .multiselect-label": {
                  display: "none",
                  [theme.breakpoints.down("sm")]: {
                    display: "block",
                    position: "static",
                    transform: "none",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "19px",
                    color: "#2B2B2B",
                    fontFamily: "Expletus Sans",
                    marginBottom: "16px",
                    letterSpacing: "0",
                  },
                },
                "& .MuiSelect-select": {
                  paddingRight: "40px",
                  [theme.breakpoints.down("sm")]: {
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    color: "#94A3B8",
                    padding: "18px 40px 18px 24px",
                    lineHeight: "20px",
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  [theme.breakpoints.down("sm")]: {
                    borderColor: "#e8ecf2",
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      [theme.breakpoints.down("sm")]: {
                        borderWidth: "1px",
                      },
                    },
                  },
                },
                "& .multiple-clear": {
                  position: "absolute",
                  fontSize: "15px",
                  right: "18px",
                  top: "3px",
                  zIndex: 1,
                  color: "#94A3B8",
                  [theme.breakpoints.down("sm")]: {
                    top: "calc(50% + 17px)",
                    right: "27px",
                    transform: "translateY(-50%)",
                  },
                },
              },
              "& .MuiSelect-select": {
                fontSize: "14px",
                letterSpacing: "0",
                color: "#000000",
                opacity: "0.671",
                fontWeight: "500",
                fontFamily: "Expletus Sans",
                paddingBottom: "0",
                paddingTop: "0",
                "&:focus": {
                  backgroundColor: "transparent",
                },
              },
              "& .MuiInput-underline:before": {
                display: "none",
              },
              "& .MuiInput-underline:after": {
                display: "none",
              },
            },
          },
        },
        "& .modal-description": {
          marginLeft: "20px",
          paddingRight: "10px",
          [theme.breakpoints.down("xs")]: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
          },
        },
        "& .modal-footer": {
          [theme.breakpoints.down("xs")]: {
            marginTop: "auto",
            position:"static",
          },
          "& .primary-btn":{
            width:"100%",
            position:"static",
          },
        },
      },

      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "8px",
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "0px",
          paddingRight: "0px",
        },
        padding: "0 60px",
      },
      "& .modal-title": {
        fontSize: "24px",
        lineHeight: "28px",
        color: "rgba(43, 43, 43, 0.87)",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "26px",
        color: "#94A3B8",
        letterSpacing: "0.105px",
        fontWeight: 400,
        fontFamily: "Roboto",
        marginTop: "0",
        marginBottom: "48px",
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
      "& .form-info-wrapper": {
        margin: "0 0 28px",
      },
      "& .form-heading": {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "19px",
        color: "#2B2B2B",
        fontFamily: "Expletus Sans",
        margin: "0 0 8px",
        marginTop: "15px",
        letterSpacing: "0",
      },
      "& .profile-team": {
        "& .team-label": {
          fontWeight: 400,
          fontSize: "10px",
          lineHeight: "12px",
          textTransform: "uppercase",
          color: "#94A3B8",
          marginBottom: "8px",
          fontFamily: "Roboto",
        },
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#2B2B2B",
        fontWeight: "500",
        "& .modal-link": {
          marginLeft: "3px",
        },
      },
      "& .modal-form": {
        marginTop: "47px",
      },
      "& .current-team": {
        color: "#94A3B8",
        padding: "17px 16px",
        fontSize: "14px",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        border: "1px solid #e8ecf2",
        borderRadius: "6px",
      },
      "& .modal-footer": {
        marginTop: "44px",
        [theme.breakpoints.down("xs")]: {
          position: "absolute",
          bottom: "24px",
          width: "100%",
        },
        "& .half-btn": {
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          "& .MuiButton-root": {
            width: "auto",
            marginBottom: "0",
            marginRight: "10px",
            "&:last-child": {
              marginRight: "0",
            },
            "&.primary-btn": {
              padding: "12px 38px",
              [theme.breakpoints.down("xs")]: {
                marginLeft: "0px",
                marginRight: "0px",
              },
            },
            "&.secondary-btn": {
              minWidth: "140px",
            },
          },
        },
      },
      "& .primary-btn": {
        minHeight: "56px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "17px",
        marginLeft: "0",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px",
          width: "calc(100% - 48px)",
        },
      },
      "& .secondary-btn": {
        minHeight: "56px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "17px",
        width: "100%",
      },
      "& .modal-description": {
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "0px",
          paddingRight: "0px",
        },
        padding: "0 60px",
      },
    },
    dialogContent: {
      "& .search-wrapper": {
        padding: "0 60px",
        marginBottom: "26px",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "41px",
        },
        "& .form-control": {
          marginBottom: "0",
        },
        "& .MuiOutlinedInput-input": {
          paddingTop: "16px",
          paddingBottom: "16px",
          color: "#94A3B8",
          fontSize: "13px",
          lineHeight: "17px",
        },
        "& .MuiSvgIcon-root": {
          width: "20px",
          height: "20px",
          color: "#225980",
        },
      },
      "&.search-box": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "0px",
          paddingRight: "10px",
        },
        padding: "0 60px",
      },
      "& .select-control": {
        "&.multi-select": {
          "& .multiselect-label": {
            display: "none",
          },
          "& .MuiInput-formControl": {
            marginTop: "0",
          },
          "& .MuiSelect-select": {
            paddingRight: "40px",
          },
          "& .multiple-clear": {
            position: "absolute",
            fontSize: "15px",
            right: "18px",
            top: "3px",
            zIndex: 1,
            color: "#94A3B8",
          },
        },
        "& .MuiSelect-select": {
          fontSize: "12px",
          letterSpacing: "0.1px",
          color: "#2b2b2b",
          opacity: "0.671",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          paddingBottom: "0",
          paddingTop: "0",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .clear-icon": {
            width: "50px",
            height: "50px",
            background: "red",
          },
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
      },
      "&.filter-box": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "8px",
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "0px",
          paddingRight: "10px",
        },
        padding: "0 60px 16px",
        borderBottom: "1px solid #E8ECF2",
      },
      "&.txt-seach": {
        width: "496px",
        height: "48px",
      },
      "& .no-member": {
        color: "rgba(0,0,0,0.87)",
        fontSize: "24px",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        lineHeight: "32px",
        letterSpacing: "0",
        margin: "50px 0",
        textAlign: "center",
        width: "100%",
        padding: "0 12px",
      },
      "& .MuiOutlinedInput-input:focus": {
        borderColor: "#E8ECF2",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#E8ECF2",
        },
        "&:hover fieldset": {
          borderColor: "#E8ECF2",
        },
      },

      "&.button-filter": {
        width: "48px",
        height: "48px",
        marginLeft: "16px",
        borderRadius: "8px",
      },
      "&.quiz-count": {
        fontFamily: "Expletus Sans",
        fontSize: "12px",
        lineHeight: "16px",
        color: "#2b2b2b",
        fontWeight: 500,
        opacity: "0.67",
      },
      "& .short-by": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
      "& .list-item": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "72px",
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "0px",
          paddingRight: "0px",
        },
        padding: "16px 60px",
        cursor: "pointer",

        "& .left-view": {
          "& .list-item-title": {
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            lineHeight: "18.75px",
            fontWeight: 600,
            color: "#2B2B2B",
            marginBottom: "4px",
          },
          "& .list-item-sub-title": {
            fontFamily: "Roboto",
            fontSize: "10px",
            lineHeight: "17px",
            fontWeight: 400,
            color: "#828282",
            letterSpacing: "0.125px",
          },
        },
        "& .right-view": {
          "& .icon-check": {
            color: "#4BA3B7",
          },
          "& .icon-un-check": {
            color: "#E8ECF2",
          },
        },
      },
      "& .selected": {
        backgroundColor: "#F2F8FC",
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "#FFF",
        },
      },

      "&.bottom-box": {
        width: "100%",
        padding: "24px 60px",
        marginTop: "auto",
        [theme.breakpoints.down("xs")]: {
          padding: "24px 30px",
        },
      },
      "& .btn-continue": {
        backgroundColor: "#4BA3B7",
        borderRadius: "8px",
        width: "100%",
        padding: "8px 24px",
        height: "48px",
        textTransform: "none",
        color: "#FFFFFF",
      },
    },
    dialogContentFb: {
      marginTop:"20px",
      display: "flex",
      flexDirection: "column",
      "& .search-wrapper": {
        "& .custom-input-wrapper": {
          width: "100%"
        },
        display: "flex",
        "& .filter-group": {
          border: '1px solid #E8ECF2',
          cursor: 'pointer',
          height: '48px',
          display: 'flex',
          minWidth: '48px',
          alignItems: 'center',
          marginLeft: '16px',
          borderRadius: '8px',
          justifyContent: 'center'
        },
        marginBottom: "26px",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "41px",
        },
        "& .form-control": {
          marginBottom: "0",
        },
        "& .MuiOutlinedInput-input": {
          paddingTop: "16px",
          paddingBottom: "16px",
          color: "#94A3B8",
          fontSize: "13px",
          lineHeight: "17px",
        },
        "& .MuiSvgIcon-root": {
          width: "20px",
          height: "20px",
          color: "#225980",
        },
      },

      "& .search-box": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "0px",
          paddingRight: "10px",
        },
        padding: "0 60px",
      },
      "& .select-control": {
        "&.multi-select": {
          "& .multiselect-label": {
            display: "none",
          },
          "& .MuiInput-formControl": {
            marginTop: "0",
          },
          "& .MuiSelect-select": {
            paddingRight: "40px",
          },
          "& .multiple-clear": {
            position: "absolute",
            fontSize: "15px",
            right: "18px",
            top: "3px",
            zIndex: 1,
            color: "#94A3B8",
          },
        },
        "& .MuiSelect-select": {
          fontSize: "12px",
          letterSpacing: "0.1px",
          color: "#2b2b2b",
          opacity: "0.671",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          paddingBottom: "0",
          paddingTop: "0",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .clear-icon": {
            width: "50px",
            height: "50px",
            background: "red",
          },
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
      },
      "& .filter-box-fb": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "0px",
          paddingRight: "10px",
        },
        padding: "10px 20px !important",
        borderBottom: "1px solid #E8ECF2",
      },
      "& .txt-seach": {
        width: "496px",
        height: "48px",
      },
      "& .no-member": {
        color: "rgba(0,0,0,0.87)",
        fontSize: "24px",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        lineHeight: "32px",
        letterSpacing: "0",
        margin: "50px 0",
        textAlign: "center",
        width: "100%",
        padding: "0 12px",
      },
      "& .MuiOutlinedInput-input:focus": {
        borderColor: "#E8ECF2",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#E8ECF2",
        },
        "&:hover fieldset": {
          borderColor: "#E8ECF2",
        },
      },

      "&.button-filter": {
        width: "48px",
        height: "48px",
        marginLeft: "16px",
        borderRadius: "8px",
      },
      "&.quiz-count": {
        fontFamily: "Expletus Sans",
        fontSize: "12px",
        lineHeight: "16px",
        color: "#2b2b2b",
        fontWeight: 500,
        opacity: "0.67",
      },
      "& .short-by": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
      "& .list-item": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "72px",
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "0px",
          paddingRight: "0px",
        },
        padding: "16px 60px",
        cursor: "pointer",

        "& .left-view": {
          "& .list-item-title": {
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            lineHeight: "18.75px",
            fontWeight: 600,
            color: "#2B2B2B",
            marginBottom: "4px",
          },
          "& .list-item-sub-title": {
            fontFamily: "Roboto",
            fontSize: "10px",
            lineHeight: "17px",
            fontWeight: 400,
            color: "#828282",
            letterSpacing: "0.125px",
          },
        },
        "& .right-view": {
          "& .icon-check": {
            color: "#4BA3B7",
          },
          "& .icon-un-check": {
            color: "#E8ECF2",
          },
        },
      },
      "& .selected": {
        backgroundColor: "#F2F8FC",
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "#FFF",
        },
      },

      "&.bottom-box": {
        width: "100%",
        padding: "24px 60px",
        marginTop: "auto",
        [theme.breakpoints.down("xs")]: {
          padding: "24px 30px",
        },
      },
      "& .btn-continue": {
        backgroundColor: "#4BA3B7",
        borderRadius: "8px",
        width: "100%",
        padding: "8px 24px",
        height: "48px",
        textTransform: "none",
        color: "#FFFFFF",
      },
    },
    fbCardRow: {
      display: "flex",
      // flexWrap: "wrap",
      flexDirection: "column",
      margin: "24px -12px 0",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        padding: 0,
        margin: "0",
      },

      "& .escore-card": {
        flex: "0 0 20%",
        maxWidth: "20%",
        borderRadius: "0",
        border: "0",
        boxShadow: "none",
        marginBottom: "24px",
        [theme.breakpoints.down("md")]: {
          width: "100%",
          maxWidth: "100%",
          flex: "0 0 100%",
          marginBottom: "0",
        },
        "& .MuiCardContent-root": {
          padding: "40px",
          borderRadius: "12px",
          border: "1px solid #E8ECF2",
          position: "relative",
          [theme.breakpoints.down("md")]: {
            padding: "15px 10px",
            border: "none",
            // padding: "0",
            display: "flex",
            alignItems: "center",
          },
      
          "& .quiz-score": {
            display: "flex",
            alignItems: "center",
            marginBottom: "8px",
            [theme.breakpoints.down("md")]: {
              marginBottom: "0px",
            },
            "& .score": {
              marginRight: "4px",
              width: "24px",
              height: "24px",
              [theme.breakpoints.down("md")]: {
                width: "36px",
                height: "36px",
              },
            },
            "& .score-text": {
              fontSize: "12px",
              lineHeight: "16px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontWeight: 400,
              letterSpacing: "0.09px",
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            },
          },
          "& .right-content": {
            [theme.breakpoints.down("md")]: {
              marginLeft: "8px",
            },
            "& .quiz-title": {
              fontSize: "18px",
              letterSpacing: "0",
              lineHeight: "24px",
              color: "#2B2B2B",
              fontWeight: 400,
              fontFamily: "Expletus Sans",
              marginBottom: "12px",
              wordBreak: "break-word",
              [theme.breakpoints.down("md")]: {
                fontSize: "14px",
                letterSpacing: 0.0125,
                marginBottom: "5px",
              },
            },
            "& .quiz-date": {
              fontSize: "12px",
              lineHeight: "16px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontWeight: 400,
              letterSpacing: "0.09px",
              [theme.breakpoints.down("md")]: {
                fontSize: "10px",
                letterSpacing: 0.0125,
              },
            },
          },
          "& .score-menu": {
            display: "none",
            [theme.breakpoints.down("md")]: {
              display: "block",
              padding: "0",
              marginLeft: "auto",
              minWidth: "unset",
              width: "24px",
            },
            "& .img-dot": {
              width: "20px",
              height: "20px",
              objectFit: "contain",
            },
            "& .MuiButton-root": {
              [theme.breakpoints.down("md")]: {
                minWidth: "24px",
                minHeight: "24px",
                padding: 0,
                margin: 0,
                fontFamily: "Expletus Sans",
                textAlign: "center",
                textTransform: "unset",
                textDecoration: "none",
                "& .MuiButton-label": {
                  textTransform: "unset",
                  textDecoration: "none",
                },
              },
            },
            "& .MuiPopover-root": {
              "& .MuiPaper-root": {
                "& .MuiBox-root": {
                  [theme.breakpoints.down("xs")]: {
                    display: "flex",
                    flexDirection: "column",
                    width: "150px",
                    backgroundColor: "#E8ECF2",
                  },
                  "& .MuiButtonBase-root": {
                    textTransform: "none",
                  },
                },
              },
            },
          },
          "& .button-wrapper": {
            display: "none",
            marginTop: "16px",
            [theme.breakpoints.down("xs")]: {
              display: "none",
            },
            "& .MuiButton-root": {
              marginRight: 0,
              marginBottom: "8px",
              borderRadius: "62px",
              boxShadow: "none",
              width: "100%",
              height: "48px",
              marginLeft: 0,
              "&:last-child": {
                marginBottom: 0,
              },
            },
          },
        },
      },
      "& .btn-take-equiz": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          display: "flex",
          position: "fixed",
          bottom: "48px",
          right: "24px",
          zIndex: 1000,
          padding: "14px 24px",
          borderRadius: "20px",
          backgroundColor: "#4BA3B7",
          boxShadow: "0px 0px 24px 0px rgba(5, 111, 135, 0.47)",
          textTransform: "unset",
          textDecoration: "none",
          color: "#FFFFFF",
        },
      },
    },
    modalDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
    
    },
  
    modalLink: {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0",
      color: "#4ba3b7",
      fontWeight: 500,
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
    },
    createdTeamDataList: {
      padding: "0",
      "& .MuiListItem-root": {
        padding: "0",
        marginBottom: "25px",
        "&:last-child": {
          marginBottom: "0",
        },
      },
      "& .list-row": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "flex-start",
        flexWrap: "wrap",
        "& .list-left": {
          flex: "1",
        },
        "& .list-right": {
          marginLeft: "10px",
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginLeft: "0",
            marginTop: "10px",
          },
        },
        "& .menu-icon": {
          width: "17px",
          marginLeft: "23px",
        },
        "& .team-name": {
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "0",
          color: "#000000",
          fontWeight: 500,
          fontFamily: "Expletus Sans",
          marginBottom: "7px",
          textTransform: "capitalize",
        },
        "& .team-user": {
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0",
          color: "rgba(0,0,0,0.671)",
          fontWeight: 400,
          fontFamily: "Roboto",
        },
        "& .team-date": {
          fontSize: "12px",
          lineHeight: "23px",
          letterSpacing: "0",
          color: "rgba(0,0,0,0.671)",
          fontWeight: 400,
          fontFamily: "Roboto",
        },
      },
    },
    stepperRoot: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      "& .MuiStepConnector-line": {
        borderColor: "transparent",
      },
      "& .stepper-top": {
        padding: "16px 24px",
        [theme.breakpoints.down("xs")]: {
          padding: "21px 24px",
        },
        "& .stepper-row": {
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          margin: "0 -10px",
          [theme.breakpoints.down("sm")]: {
            margin: "0 -5px",
          },
          [theme.breakpoints.down("xs")]: {
            margin: "0",
            justifyContent: "space-between",
          },
          "& .stepper-col": {
            flex: "0 0 27%",
            maxWidth: "27%",
            padding: "0 10px",
            [theme.breakpoints.down("md")]: {
              flex: "0 0 21%",
              maxWidth: "21%",
            },
            [theme.breakpoints.down("sm")]: {
              padding: "0 5px",
              // flex: "unset",
              // maxWidth: "unset",
              flex: "0 0 18%",
              maxWidth: "18%",
            },
            [theme.breakpoints.down("xs")]: {
              padding: "0",
              flex: "unset",
              maxWidth: "unset",
            },
            "&.stepper-middle": {
              flex: "0 0 46%",
              maxWidth: "46%",
              [theme.breakpoints.down("md")]: {
                flex: "0 0 58%",
                maxWidth: "58%",
              },
              [theme.breakpoints.down("sm")]: {
                // flex: "unset",
                // maxWidth: "unset",
                flex: "0 0 64%",
                maxWidth: "64%",
              },
              [theme.breakpoints.down("xs")]: {
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
                flex: "unset",
                maxWidth: "unset",
              },
            },
            "&.stepper-action": {
              display: "flex",
              justifyContent: "end",
              [theme.breakpoints.down("sm")]: {
                marginLeft: "auto",
              },
              [theme.breakpoints.down("xs")]: {
                display: "none",
              },
            },
            "& .MuiMobileStepper-root": {
              [theme.breakpoints.down("xs")]: {
                padding: "0",
                background: "transparent",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "16px",
                color: "#94A3B8",
              },
            },
            "& .back-btn-wrap": {
              maxWidth: "18px",
              "& i": {
                display: "block",
                cursor: "pointer",
              },
            },
            "& .auth-logo": {
              maxWidth: "117px",
              width: "100%",
              "& img": {
                maxWidth: "100%",
              },
            },
            "& .button-wrapper": {
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              "& .secondary-btn": {
                minWidth: "116px",
                marginRight: "14px",
                [theme.breakpoints.down("md")]: {
                  minWidth: "90px",
                  marginRight: "10px",
                  padding: "12px 7px",
                },
                [theme.breakpoints.down("sm")]: {
                  minWidth: "unset",
                  marginRight: "5px",
                  padding: "9px 3px",
                  fontSize: "12px",
                  minHeight: "38px",
                },
              },
              "& .primary-btn": {
                minWidth: "142px",
                [theme.breakpoints.down("md")]: {
                  minWidth: "130px",
                  padding: "12px 7px",
                },
                [theme.breakpoints.down("sm")]: {
                  minWidth: "unset",
                  padding: "9px 3px",
                  fontSize: "12px",
                  minHeight: "38px",
                },
              },
            },
          },
        },
      },
      "& .sm-visible": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          display: "block",
        },
      },
      "& .stepIcon": {
        backgroundColor: "#fafcfe",
        zIndex: 1,
        color: "#94a3b8",
        width: 48,
        height: 48,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid transparent",
        [theme.breakpoints.down("xs")]: {
          width: 32,
          height: 32,
        },
        "& .MuiSvgIcon-root": {
          width: 20,
          height: 20,
        },
        "&.active": {
          backgroundColor: "#4ba3b7",
          color: "#ffffff",
        },
        "&.completed": {
          backgroundColor: "transparent",
          borderColor: "#00ba88",
          color: "#00ba88",
        },
      },
      "& .MuiStepper-root": {
        padding: "0",
        maxWidth: "718px",
        // maxWidth: "545px",
        width: "100%",
        margin: "0 auto",
        [theme.breakpoints.down("xs")]: {
          maxWidth: "unset",
          width: "auto",
        },
        "& .step-root": {
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
          "&.active-step-root": {
            [theme.breakpoints.down("xs")]: {
              display: "block",
              padding: "0",
            },
          },
        },
        "& .MuiStepConnector-root": {
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
        },
        "& .MuiStepLabel-iconContainer": {
          paddingRight: "13px",
          [theme.breakpoints.down("xs")]: {
            paddingRight: "5px",
          },
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
        },
      },
      "& .MuiStepLabel-label": {
        "&.MuiStepLabel-active": {
          "& .step-no": {
            //color: "#4ba3b7",
            color: "#94a3b8",
          },
          "& .step-label": {
            color: "#2b2b2b",
            [theme.breakpoints.down("xs")]: {
              color: "#4BA3B7",
            },
          },
        },
        "&.MuiStepLabel-completed": {
          "& .step-no": {
            color: "#00ba88",
          },
          "& .step-label": {
            color: "#00ba88",
          },
        },
        "& .step-no": {
          fontSize: "12px",
          lineHeight: "16px",
          color: "#94a3b8",
          fontWeight: 400,
          fontFamily: "Roboto",
          letterSpacing: "0.4px",
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
        },
        "& .step-label": {
          fontSize: "16px",
          lineHeight: "24px",
          color: "#94a3b8",
          fontWeight: 400,
          fontFamily: "Expletus Sans",
          letterSpacing: "0.15px",
          // [theme.breakpoints.down("sm")]: {
          //   fontSize: "13px",
          //   lineHeight: "17px",
          // },
          [theme.breakpoints.down("xs")]: {
            background: "rgba(75, 163, 183, 0.08)",
            borderRadius: "6px",
            padding: "4px 12px",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.0075em",
            color: "#4BA3B7",
            fontFamily: "Roboto",
            marginRight: "12px",
          },
        },
      },
      "& .step-content-root": {
        display: "flex",
        padding: "37px 24px",
        width: "100%",
        flex: "1",
        [theme.breakpoints.down("sm")]: {
          padding: "37px 60px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "18px 0 24px",
        },
      },
      "& .step-content": {
        width: "100%",
        display: "flex",
        "& .member-upload-content": {
          maxWidth: "100%",
          width: "520px",
          margin: "auto",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "0 24px",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
          },
          "& .file-drop": {
            [theme.breakpoints.down("xs")]: {
              marginBottom: "8px",
            },
          },
          "& .heading": {
            fontSize: "22px",
            lineHeight: "26px",
            margin: "0 0 8px",
            color: "#2B2B2B",
            letterSpacing: "0",
            fontFamily: "Expletus Sans",
            fontWeight: 400,
            [theme.breakpoints.down("xs")]: {
              fontSize: "22px",
              lineHeight: "26px",
              margin: "0 0 9px",
              color: "#2B2B2B",
            },
          },
          "& .sub-txt": {
            fontSize: "12px",
            lineHeight: "17px",
            margin: "0",
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontWeight: 400,
            opacity: "0.67",
            [theme.breakpoints.down("xs")]: {
              fontSize: "12px",
              lineHeight: "20px",
              margin: "0 0 78px",
              color: "#2B2B2B",
            },
          },
          "& .button-wrapper": {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            [theme.breakpoints.down("xs")]: {
            },
            "& .add-slider-btn": {
              border: "1px solid #4ba3b7",
              borderRadius: "7px",
              //filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
              fontSize: "14px",
              lineHeight: "18px",
              color: "#4ba3b7",
              fontWeight: 500,
              fontFamily: "Expletus Sans",
              textAlign: "center",
              padding: "12px 10px",
              minHeight: "48px",
              marginBottom: "20",
              transition: "all 0.5s ease-in-out",
              textTransform: "unset",
              width: "100%",
              marginTop: "48px",
              [theme.breakpoints.down("xs")]: {
                position: "static",
                bottom: "40px",
                left: "24px",
                width: "calc(100% - 48px)",
                // marginRight: "24px",
              },
              "&:hover": {
                background: "#4ba3b7",
                color: "#ffffff",
              },
            },
            "& .secondary-btn": {
              [theme.breakpoints.down("xs")]: {
                width: "100%",
                marginBottom: "24px",
                display: "none",
              },
            },
            "& .MuiButton-root": {
              marginRight: "16px",
              marginLeft: "0",
              [theme.breakpoints.down("xs")]: {
                width: "100%",
                marginBottom: "24px",
              },
              "&:last-child": {
                marginRight: "0",
                [theme.breakpoints.down("xs")]: {
                  marginBottom: "0",
                },
              },
            },
          },
        },
      },
      "& .quiz-question-wrapper": {
        "& .score-heading": {
          marginBottom: "24px",
        },
        "& .quiz-question-box": {
          marginBottom: "24px",
          "&:last-child": {
            marginBottom: "0",
          },
          "&.quiz-input-question": {
            "& .quiz-question": {
              marginBottom: "20px",
            },
          },
          "& .quiz-question": {
            display: "flex",
            marginBottom: "50px",
            "& .serial-no": {
              color: "#94A3B8",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
              fontFamily: "Expletus Sans",
              marginRight: "5px",
              "&.filled-question": {
                color: "#2B2B2B",
              },
            },
            "& .delete-disabled": {
              opacity: 0.5,
            },
            "& .form-control": {
              marginBottom: "0",
              flex: "1",
              "& .MuiFormHelperText-root": {
                "&.Mui-error": {
                  marginLeft: "0",
                },
              },
              "& .MuiOutlinedInput-input": {
                padding: "0",
                color: "#2B2B2B",
                fontFamily: "Expletus Sans",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                letterSpacing: "0",
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                  padding: "0",
                },
              },
            },
          },
          "& .quiz-input": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "8px",
            "& .form-control": {
              width: "60px",
              marginBottom: "0",
              "& .MuiOutlinedInput-input": {
                color: "#2b2b2b",
                padding: "16px 14px",
                textAlign: "center",
              },
            },
          },
        },
        "& .quiz-slider": {
          "& .MuiSlider-rail": {
            height: "4px",
            opacity: "1",
            backgroundColor: "#ECF1F4",
            borderRadius: "6px",
          },
          "& .MuiSlider-markLabel": {
            top: "28px",
            right:"100%",
            color: "#94A3B8",
            fontFamily: "Roboto",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "12px",
            textTransform: "uppercase",
            display: "inline-flex",
            alignItems: "center",
            flexDirection: "column",
            transform: "none",
            "& span": {
              display: "block",
              marginBottom: "3px",
            },
          },
          "& .MuiSlider-mark": {
            width: "1px",
            height: "6px",
            borderRadius: "0",
            backgroundColor: "#94A3B8",
            bottom: "0",
          },
          "&.MuiSlider-root": {
            padding: "0 0 19px",
            marginBottom: "35px",
            color: "#4BA3B7",
          },
          "& .MuiSlider-thumb": {
            width: "20px",
            height: "20px",
            backgroundColor: "white",
            border: "4px solid #4BA3B7",
            boxShadow: "none",
            marginLeft: "-10px",
            marginTop: "-7px",
          },
          "& .MuiSlider-track": {
            height: "4px",
            borderRadius: "6px",
            backgroundColor: "#4BA3B7",
            boxShadow: "none",
          },
          "& .MuiSlider-valueLabel": {
            color: "#4BA3B7",
            textAlign: "center",
            fontSize: "14px",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            lineHeight: "18px",
            letterSpacing: "0",
            "&:after": {
              content: "''",
              width: "0",
              height: "0",
              borderLeft: "6px solid transparent",
              borderRight: "6px solid transparent",
              borderTop: "7px solid #4BA3B7",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            },
            "& >span": {
              width: "31px",
              height: "27px",
              transform: "unset",
              borderRadius: "8px",
              fontSize:'12px',
              "& >span": {
                color: "#ffffff",
                transform: "unset",
              },
            },
          },
        },
        "& .add-slider-btn": {
          border: "1px solid #4ba3b7",
          borderRadius: "7px",
          //filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
          fontSize: "14px",
          lineHeight: "18px",
          color: "#4ba3b7",
          fontWeight: 500,
          fontFamily: "Expletus Sans",
          textAlign: "center",
          padding: "12px 10px",
          minHeight: "48px",
          marginBottom: "0",
          transition: "all 0.5s ease-in-out",
          textTransform: "unset",
          width: "100%",
          marginTop: "48px",
          [theme.breakpoints.down("xs")]: {
            position: "absolute",
            bottom: "86px",
            left: "24px",
            width: "calc(100% - 48px)",
            marginRight: "24px",
          },
          "&:hover": {
            background: "#4ba3b7",
            color: "#ffffff",
          },
        },
      },
    },
    container: {
      margin: "0 auto",
    },
    questionText: {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.primary.dark,
      lineHeight: 1.5,
      paddingBottom: 6,
    },
    slider: {
      width: "80%",
      margin: "0 auto",
    },
    inputContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginBottom: 10,
    },
    inputText: {
      height: 36,
      textAlign: "center",
      alignSelf: "center",
      borderColor: theme.palette.grey[400],
      borderWidth: 1,
      width: 35,
      borderRadius: 8,
      marginBottom: 10,
      fontWeight: 500,
      fontSize: 14,
      color: theme.palette.primary.dark,
    },
    markerContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    marker: {
      width: 24,
      height: 24,
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "bold",
      fontSize: 14,
    },
    quizScore: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "520px",
        width: "100%",
        margin: "0 auto",
      },
      "& .congratulations-text": {
        fontSize: "22px",
        lineHeight: "26px",
        fontWeight: 400,
        fontFamily: "Expletus Sans",
        letterSpacing: "0",
        marginBottom: "16px",
        color: "#2B2B2B",
      },
      "& .economic-text": {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 400,
        fontFamily: "Expletus Sans",
        color: "#2B2B2B",
        letterSpacing: "0",
      },
      "& .grade-score-title": {
        fontSize: "14px",
        lineHeight: "18px",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        color: "#2B2B2B",
        letterSpacing: "0",
        marginBottom: "8px",
      },
      "& .grade-score-sub-title-wrapper": {
        fontSize: "14px",
        fontFamily: "Roboto",
        fontWeight: 400,
        textAlign: "center",
        letterSpacing: "0.105px",
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "26px",
        maxWidth: "472px",
        width: "100%",
        margin: "0 auto",
        padding: "0",
      },
      "& .grade-score-sub-title": {
        color: "#4BA3B7",
        textDecoration: " none",
        margin: "0 3px",
      },
      "& .bottom-view": {
        display: "flex",
        marginTop: "48px",
        "& .primary-btn": {
          marginLeft: "0",
        },
      },
      // "& .secondary-bottom-btn": {
      //   marginLeft: "10px",
      //   textDecoration: "none",
      // },
    },
    modalQuizEScoreBox: {
      width: 968,
      maxWidth: "100%",
      backgroundColor: "#ffffff",
      position: "relative",
      borderRadius: "24px",
      overflow: "hidden",
      [theme.breakpoints.down("md")]: {
        width: 909,
      },
      [theme.breakpoints.down("sm")]: {
        width: 804,
      },
      [theme.breakpoints.down("xs")]: {
        height: "100%",
        width: "100%",
        borderRadius: 0,
      },
      "& .header-mobile": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: "24px 24px 0px",
        },
      },
      "& .quizscore-inner": {
        padding: "24px 40px 45px",
        maxHeight: "90vh",
        overflowY: "auto",
        [theme.breakpoints.down("md")]: {
          padding: "24px 40px 36px",
        },
        [theme.breakpoints.down("md")]: {
          padding: "0px 24px 24px",
          maxHeight: "100vh",
        },
      },
      "& .score-modal-heading": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: "24px",
        "& .close-icon": {
          color: "#94A3B8",
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
        },
      },
      "& .escore-content": {
        display: "flex",
        flexWrap: "wrap",
      },
      "& .try-simulation-mode": {
        width: "320px",
        padding: "32px",
        borderRadius: "12px",
        border: "1px solid #E8ECF2",
        [theme.breakpoints.down("sm")]: {
          width: "275px",
          padding: "24px",
        },
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          padding: "24px",
          marginTop: "16px",
        },
        "& .simulation-img": {
          maxWidth: "240px",
          marginBottom: "24px",
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
        },
        "& .primary-txt": {
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          fontSize: "22px",
          lineHeight: "26px",
          color: "#2B2B2B",
          letterSpacing: "0",
          marginBottom: "9px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "18px",
            lineHeight: "22px",
          },
          [theme.breakpoints.down("xs")]: {
            textAlign: "center",
          },
        },
        "& .sub-txt": {
          textAlign: "left",
          letterSpacing: "0.105px",
          fontSize: "14px",
          lineHeight: "26px",
          fontFamily: "Roboto",
          fontWeight: 400,
          color: "#2B2B2B",
          [theme.breakpoints.down("xs")]: {
            textAlign: "center",
          },
        },
        "& .secondary-btn": {
          width: "100%",
          marginTop: "24px",
          border: "1px solid #ECF1F4",
          borderRadius: "6px",
          color: "#4BA3B7",
        },
      },
      "& .grade-score": {
        flex: "1",
        marginRight: "48px",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
          marginRight: 0,
        },
        "& .primary-txt": {
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          fontSize: "22px",
          lineHeight: "26px",
          color: "#2B2B2B",
          marginBottom: "16px",
        },
        "& .sub-txt": {
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: "#2B2B2B",
          // marginBottom: "24px",
        },
        "& .recommendation-title": {
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          lineHeight: "19px",
          letterSpacing: "0",
          fontWeight: 500,
          color: "#2B2B2B",
          marginBottom: "7px",
          [theme.breakpoints.down("xs")]: {
            textAlign: "center",
            width: "calc(100% -48px)",
          },
        },
        "& .recommendation-content": {
          fontFamily: "Roboto",
          fontSize: "14px",
          lineHeight: "26px",
          letterSpacing: "0.105px",
          fontWeight: 400,
          [theme.breakpoints.down("xs")]: {
            textAlign: "center",
            width: "calc(100% -48px)",
          },
          "& .hightlight-txt": {
            color: "#4BA3B7",
            margin: "0 3px",
            textDecoration: "none",
          },
        },
      },

      "& .modal-bottom-view": {
        marginTop: "48px",
        [theme.breakpoints.down("xs")]: {
          position: "fixed",
          bottom: "24px",
          left: "24px",
          display: "flex",
          flexDirection: "column",
          width: "calc(100% - 48px)",
        },
        "& .retake-quiz-btn": {
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "16px",
          },
        },
        "& .MuiButton-root": {
          marginRight: "16px",
          marginLeft: "0",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
    },
    modalSharingScoreBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "#ffffff",
      padding: "80px 60px",
      width: "680px",
      maxWidth: "100%",
      overflow: "hidden",
      overflowY: "scroll",
      [theme.breakpoints.down("md")]: {
        padding: "61px 60px 44px",
        width: "551px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "100%",
        padding: "40px 33px",
      },
      "& .modal-heading": {
        position: "absolute",
        width: "24px",
        height: "24px",
        top: "80px",
        right: "52px",
        [theme.breakpoints.down("md")]: {
          top: "50px",
          right: "60px",
        },
        [theme.breakpoints.down("md")]: {
          top: "50px",
          right: "24px",
        },
        "& .close-icon": {
          color: "#94A3B8",
          "& .icon-close": {
            fontSize: "24px",
          },
        },
      },
      "& .sub-txt": {
        fontFamily: "Expletus Sans",
        fontSize: "9px",
        lineHeight: "13px",
        fontWeight: 600,
        color: "#2B2B2B",
        letterSpacing: "0",
        marginBottom: "3px",
        [theme.breakpoints.down("md")]: {
          fontSize: "7px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "8px",
        },
      },
      "& .primary-txt": {
        fontFamily: "Expletus Sans",
        fontSize: "25px",
        lineHeight: "29px",
        fontWeight: 600,
        marginBottom: "16px",
        color: "#2B2B2B",
        letterSpacing: "0",
        [theme.breakpoints.down("md")]: {
          fontSize: "20px",
          lineHeight: "24px",
          marginBottom: "11px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "22px",
          lineHeight: "26px",
        },
      },
      "& .sharing-content": {
        maxWidth: 424,
        width: "100%",
        margin: "0 auto",
        "& .sharing-box": {
          backgroundColor: "#F2F8FC",
          margin: "0 auto 36px",
          borderRadius: "39px",
          boxShadow: "0px 12px 56px 0px rgba(0, 0, 0, 0.07)",
          overflow: "hidden",
          maxWidth: "310px",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            maxWidth: "254px",
            margin: "0 auto 40px",
          },
          [theme.breakpoints.down("sm")]: {
            maxWidth: "269px",
            margin: "0 auto 50px",
          },
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            padding: 0,
            margin: 50,
            maxWidth: "100%",
            marginLeft: 0,
            marginRight: 0,
          },
          "& .top-box": {
            // backgroundImage: `url(${shareScoreBackground})`,
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
            position:"relative",
            background: `url(${shareScoreBackground}), lightgray 50% / cover no-repeat`,
            minHeight:"45%",
            padding: "18px 15px 22px",
            backgroundPosition: "top center",
            textAlign: "center !important",
            [theme.breakpoints.down("md")]: {
              padding: "15px",
              minHeight:"65%",
            },
            [theme.breakpoints.down("sm")]: {
              padding: "18px 15px",
              minHeight:"65%",
            },
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
            "& .sub-txt-container":{
                marginTop: "55px",
            },
            "& .logo": {
              width: "122px",
              maxWidth: "100%",
              position: "absolute",
              top: 30,
              bottom: 20,
              left: "50%",
              right:"50%",
              transform: "translate(-50%, -50%)",
              objectFit: "contain",
              [theme.breakpoints.down("md")]: {
                width: "100px",
                // margin: "0 auto 37px",
              },
              [theme.breakpoints.down("sm")]: {
                width: "104px",
                // margin: "0 auto 41px",
              },
            },
          },
        },
        "& .box-score": {
          marginTop: "4px",
          position:"relative",
          "& .lotus-img": {
            width: "171px",
            objectFit: "contain",
            // margin: "0 auto",
            [theme.breakpoints.down("md")]: {
              width: "138px",
            },
            [theme.breakpoints.down("sm")]: {
              width: "147px",
            },
          },
          "& .grade-img": {
            width: "132px",
            objectFit: "contain",
            position: "absolute",
            top: "70",
            // bottom: 20,
            left: "50%",
            right:"50%",
            transform: "translate(-50%, -50%)",
            // margin: "20px auto 0",
            [theme.breakpoints.down("md")]: {
              width: "110px",
            },
            [theme.breakpoints.down("xs")]: {
              height: "157px",
            },
          },
        },
        "& .text-larger": {
          fontFamily: "Expletus Sans",
          fontSize: "30px",
          textAlign: "center",
          fontWeight: "600",
          width: "100%",
        },
        "& .score-info": {
          padding: "19px 17px",
          [theme.breakpoints.down("md")]: {
            padding: "17px 14px",
          },
          [theme.breakpoints.down("sm")]: {
            padding: "19px 15px",
          },
          "& .score-date": {
            fontFamily: "Expletus Sans",
            fontSize: "7.5px",
            lineHeight: "12px",
            fontWeight: 500,
            color: "#2b2b2b",
            letterSpacing: "0",
            [theme.breakpoints.down("sm")]: {
              fontSize: "6.5px",
            },
          },
          "& .heading": {
            fontFamily: "Expletus Sans",
            fontSize: "16px",
            lineHeight: "26px",
            fontWeight: 600,
            color: "#2b2b2b",
            letterSpacing: "0",
            marginBottom: "6px",
            [theme.breakpoints.down("md")]: {
              fontSize: "13px",
              lineHeight: "23px",
              marginBottom: "2px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "14px",
            },
          },
          "& .para": {
            fontFamily: "Expletus Sans",
            fontSize: "10px",
            lineHeight: "18px",
            fontWeight: 500,
            color: "#2b2b2b",
            letterSpacing: "0",
            [theme.breakpoints.down("md")]: {
              fontSize: "8.5px",
              lineHeight: "15px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "9px",
              lineHeight: "17px",
            },
          },
        },
        "& .refer-box": {
          padding: "19px 17px 34px",
          marginBottom:"20px",
          [theme.breakpoints.down("md")]: {
            padding: "13px 14px 24px",
          },
          [theme.breakpoints.down("sm")]: {
            padding: "14px 15px 34px",
          },
          "& .main-text": {
            fontFamily: "Expletus Sans",
            fontSize: "9px",
            lineHeight: "12px",
            fontWeight: 500,
            color: "#2b2b2b",
            letterSpacing: "0",
            marginBottom: "8px",
            [theme.breakpoints.down("md")]: {
              fontSize: "7.5px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "8px",
              marginBottom: "6px",
            },
          },
          "& .download-app": {
            display: "flex",
            alignItems: "center",
            "& .main-text": {
              fontFamily: "Expletus Sans",
              fontSize: "16px",
              fontWeight: "500",
            },
            "& .sub-text": {
              fontFamily: "Expletus Sans",
              fontSize: "7.5px",
              lineHeight: "12px",
              fontWeight: 500,
              color: "#2b2b2b",
              letterSpacing: "0",
              [theme.breakpoints.down("md")]: {
                fontSize: "6.5px",
                lineHeight: "9px",
              },
            },
            "& .ml-15": {
              marginLeft: "4px",
            },
            "& .img-appstore": {
              width: "9px",
              height: "9px",
              objectFit: "contain",
              marginLeft: "4px",
            },
          },
        },
      },
      "& .share-box": {
        marginBottom: "51px",
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
          marginBottom: "33px",
        },
        "& .share-title": {
          fontFamily: "Expletus Sans",
          fontSize: "22px",
          fontWeight: 400,
          color: "#2B2B2B",
          lineHeight: "26px",
          letterSpacing: "0",
          marginBottom: "16px",
        },
        "& .share-sub-title": {
          fontFamily: "Expletus Sans",
          fontSize: "16px",
          fontWeight: 400,
          color: "#2B2B2B",
          lineHeight: "24px",
          letterSpacing: "0",
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
        },
        "& .mb-text-score-infor": {
          display: "none",
          [theme.breakpoints.down("xs")]: {
            display: "flex",
            color: "#2B2B2B",
            textAlign: "center",
            fontFamily: "Expletus Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px",
          },
        },
      },
      "& .sharing-bottom-view": {
        textAlign: "center",
        "& .MuiButton-root": {
          marginRight: "16px",
          marginLeft: "0",
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "16px",
          },
          "&:last-child": {
            marginRight: "0",
          },
          "& .secondary-bottom-btn": {
            [theme.breakpoints.down("xs")]: {
              marginBottom: "8px",
            },
          },
        },
      },
    },
    modalBoxCenter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalQuizResults: {
      width: 968,
      maxWidth: "100%",
      backgroundColor: "#ffffff",
      position: "relative",
      borderRadius: "24px",
      overflow: "hidden",
      [theme.breakpoints.down("md")]: {
        width: 909,
      },
      [theme.breakpoints.down("sm")]: {
        width: 804,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "100%",
        borderRadius: 0,
      },
      "& .quizresults-inner": {
        padding: "24px 40px 45px",
        maxHeight: "90vh",
        overflowY: "auto",
        [theme.breakpoints.down("md")]: {
          padding: "24px 40px 36px",
        },
        [theme.breakpoints.down("md")]: {
          maxHeight: "100vh",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "24px 24px 45px",
        },
      },
      "& .quiz-result-heading": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: "20px",
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
        "& .close-icon": {
          color: "#94A3B8",
        },
      },
      "& .mobile-heading": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        },
        "& .link-share-score": {
          textAlign: "right",
          color: "#4BA3B7",
          textTransform: "none",
          textDecoration: "none",
          height: "74px",
        },
        "& .close-icon": {
          "& .arrow-back": {
            width: "24px",
            height: "24px",
            color: "#94A3B8",
          },
        },
      },
      "& .quiz-result-content": {
        display: "flex",
        flexWrap: "wrap",
        "& .left-box": {
          width: "520px",
          maxWidth: "100%",
          [theme.breakpoints.down("md")]: {
            width: "459px",
          },
          [theme.breakpoints.down("sm")]: {
            width: "417px",
          },
          "& .left-box-header": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "22px",
            [theme.breakpoints.down("sm")]: {
              marginBottom: "24px",
            },
            "& .header-title": {
              fontFamily: "Expletus Sans",
              fontWeight: 400,
              fontSize: "22px",
              lineHeight: "26px",
              letterSpacing: "0",
              color: "#2B2B2B",
              marginBottom: "8px",
            },
            "& .header-sub-title": {
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "17px",
              letterSpacing: "0",
              color: "#2B2B2B",
              opacity: "0.67",
            },
            "& .grade-img": {
              marginLeft: "10px",
              width: "50px",
              height: "50px",
            },
          },
          "& .quiz-list-wrapper": {
            "& .quiz-question-box-v2": {
              marginBottom: "24px",
            },
            "& .txt-equiz-question": {
              fontFamily: "Expletus Sans",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "left",
              marginBottom: "50px",
              color: "#2b2b2b",
            },
            "& .quiz-slider-full-details": {
              "& .MuiSlider-rail": {
                height: "4px",
                opacity: "1",
                backgroundColor: "#ECF1F4",
                borderRadius: "6px",
              },
              "& .MuiSlider-markLabel": {
                top: "28px",
                color: "#94A3B8",
                fontFamily: "Roboto",
                fontSize: "10px",
                fontWeight: 400,
                lineHeight: "12px",
                textTransform: "uppercase",
                display: "inline-flex",
                alignItems: "center",
                flexDirection: "column",
                "& span": {
                  display: "block",
                  marginBottom: "3px",
                },
              },
              "& .MuiSlider-mark": {
                width: "1px",
                height: "6px",
                borderRadius: "0",
                backgroundColor: "#94A3B8",
                bottom: "0",
              },
              "&.MuiSlider-root": {
                padding: "0 0 19px",
                marginBottom: "35px",
                color: "#4BA3B7",
              },
              "& .MuiSlider-thumb": {
                width: "20px",
                height: "20px",
                backgroundColor: "white",
                border: "4px solid #94A3B8",
                boxShadow: "none",
                marginLeft: "-10px",
                marginTop: "-7px",
              },
              "& .MuiSlider-track": {
                height: "4px",
                borderRadius: "6px",
                backgroundColor: "#94A3B8",
                boxShadow: "none",
              },
              "& .MuiSlider-valueLabel": {
                color: "#94A3B8",
                textAlign: "center",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                lineHeight: "18px",
                letterSpacing: "0",
                "&:after": {
                  content: "''",
                  width: "0",
                  height: "0",
                  borderLeft: "6px solid transparent",
                  borderRight: "6px solid transparent",
                  borderTop: "7px solid #94A3B8",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                },
                "& >span": {
                  width: "31px",
                  height: "27px",
                  transform: "unset",
                  borderRadius: "8px",
                  "& >span": {
                    color: "#ffffff",
                    transform: "unset",
                  },
                },
              },
            },
          },
          "& .button-wrapper": {
            marginTop: "50px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            [theme.breakpoints.down("xs")]: {
              position: "fixed",
              bottom: 24,
              left: 24,
              display: "flex",
              flexDirection: "column",
              width: "calc(100% - 48px)",
            },
            "& .primary-btn": {
              [theme.breakpoints.down("xs")]: {
                marginRight: 0,
                backgroundColor: "#4BA3B7",
                width: "100%",
                color: "#FFFFFF",
              },
            },
            "& .btn-try-simulation-mode": {
              background: "transparent",
              border: "1px solid #e8ecf2",
              borderRadius: "7px",
              filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
              fontSize: "14px",
              lineHeight: "18px",
              color: "#2b2b2b",
              fontWeight: 500,
              fontFamily: "Expletus Sans",
              textAlign: "center",
              textTransform: "unset",
              maxWidth: "100%",
              padding: "12px 10px",
              minHeight: "48px",
              marginBottom: "0",
              marginRight: "16px",
              transition: "all 0.5s ease-in-out",
              letterSpacing: "0",
              textDecoration: "none",
              width: "auto",
              minWidth: "156px",
              [theme.breakpoints.down("xs")]: {
                marginBottom: "16px",
                width: "100%",
                marginRight: 0,
                backgroundColor: "#FFFFFF",
                transition: "none",
                filter: "none",
              },
              "&:last-child": {
                marginRight: "0",
              },
              "&:hover": {
                background: "transparent",
                color: "#2b2b2b",
              },
            },
          },
        },
        "& .right-box": {
          flex: "1",
          marginLeft: "48px",
          marginTop: "74px",
          [theme.breakpoints.down("sm")]: {
            marginLeft: "32px",
          },
          [theme.breakpoints.down("xs")]: {
            marginLeft: "0px",
            marginTop: "0px",
          },
          "& .right-box-header": {
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "19px",
            letterSpacing: 0,
            color: "#4BA3B7",
            marginBottom: "24px",
            textDecoration: "none",
            display: "inline-block",
          },
          "& .right-box-content": {
            border: "1px solid #E8ECF2",
            padding: "24px",
            borderRadius: "12px",
            transition: "all 0.5s ease-in-out",
            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
            [theme.breakpoints.down("xs")]: {
              border: "none",
              transition: "none",
              boxShadow: "none",
              padding: "0px",
            },
            "& .img-right-box": {
              objectFit: "contain",
              marginBottom: "16px",
              [theme.breakpoints.down("xs")]: {
                display: "none",
              },
            },
            "& .recomendation-title": {
              fontFamily: "Expletus Sans",
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "24px",
              marginBottom: "8px",
              color: "#2B2B2B",
              letterSpacing: "0",
            },
            "& .recomendation-content": {
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "26px",
              letterSpacing: "0.105px",
              color: "#2B2B2B",
            },
            "& .hightlight-txt": {
              color: "#4BA3B7",
              margin: "0 3px",
              textDecoration: "none",
            },
          },
          "& .modal-btn": {
            width: "100%",
            marginLeft: "0",
            marginTop: "18px",
            [theme.breakpoints.down("xs")]: {
              display: "none",
            },
          },
          "& .share-score-btn":{
            textTransform: "unset",
          }
        },
      },
    },
    fullscreenModal: {
      "& .modalQuizResults": {
        borderRadius: "0",
        width: "100%",
        height: "100%",
        "& .quizresults-inner": {
          maxHeight: "100vh",
          padding: "16px 24px",
        },
        "& .modal-heading": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "120px",
          [theme.breakpoints.down("md")]: {
            marginBottom: "110px",
          },
          [theme.breakpoints.down("sm")]: {
            marginBottom: "89px",
          },
          "& .close-icon": {
            color: "#94A3B8",
          },
          "& .btn-try-simulation-mode": {
            background: "transparent",
            border: "1px solid #e8ecf2",
            borderRadius: "7px",
            filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
            fontSize: "14px",
            lineHeight: "18px",
            color: "#2b2b2b",
            fontWeight: 500,
            fontFamily: "Expletus Sans",
            textAlign: "center",
            textTransform: "unset",
            maxWidth: "100%",
            padding: "12px 10px",
            minHeight: "48px",
            marginBottom: "0",
            marginRight: "16px",
            transition: "all 0.5s ease-in-out",
            letterSpacing: "0",
            textDecoration: "none",
            width: "auto",
            minWidth: "156px",
            [theme.breakpoints.down("xs")]: {
              marginBottom: "16px",
              width: "100%",
              marginRight: 0,
              backgroundColor: "#FFFFFF",
              transition: "none",
              filter: "none",
            },
            "&:last-child": {
              marginRight: "0",
            },
            "&:hover": {
              background: "transparent",
              color: "#2b2b2b",
            },
          },
        },
        "& .quiz-result-content": {
          maxWidth: "948px",
          margin: "0 auto",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            maxWidth: "710px",
          },
          [theme.breakpoints.down("sm")]: {
            maxWidth: "100%",
            padding: "0 18px",
          },
          "& .left-box": {
            [theme.breakpoints.down("md")]: {
              width: "394px",
            },
            [theme.breakpoints.down("sm")]: {
              width: "623px",
            },
          },
          "& .right-box": {
            [theme.breakpoints.down("md")]: {
              marginLeft: "60px",
            },
            [theme.breakpoints.down("sm")]: {
              marginLeft: "25px",
            },
          },
        },
      },
      "& .simulationQuizModal": {
        borderRadius: "0",
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        "& .simulationquiz-inner": {
          maxHeight: "100vh",
          padding: "34px 24px",
          [theme.breakpoints.down("md")]: {
            padding: "28px 24px",
          },
        },
        "& .quiz-result-heading": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "137px",
          [theme.breakpoints.down("md")]: {
            marginBottom: "90px",
          },
          [theme.breakpoints.down("sm")]: {
            marginBottom: "89px",
          },
          "& .close-icon": {
            color: "#2B2B2B",
            "& .arrow-back": {
              fontSize: "18px",
            }
          },
          "& .btn-try-simulation-mode": {
            background: "transparent",
            border: "1px solid #e8ecf2",
            borderRadius: "7px",
            filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
            fontSize: "14px",
            lineHeight: "18px",
            color: "#2b2b2b",
            fontWeight: 500,
            fontFamily: "Expletus Sans",
            textAlign: "center",
            textTransform: "unset",
            maxWidth: "100%",
            padding: "12px 10px",
            minHeight: "48px",
            marginBottom: "0",
            marginRight: "16px",
            transition: "all 0.5s ease-in-out",
            letterSpacing: "0",
            textDecoration: "none",
            width: "auto",
            minWidth: "156px",
            [theme.breakpoints.down("xs")]: {
              marginBottom: "16px",
              width: "100%",
              marginRight: 0,
              backgroundColor: "#FFFFFF",
              transition: "none",
              filter: "none",
            },
            "&:last-child": {
              marginRight: "0",
            },
            "&:hover": {
              background: "transparent",
              color: "#2b2b2b",
            },
          },
        },
        "& .quiz-result-content": {
          maxWidth: "1186px",
          margin: "0 auto",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            maxWidth: "1017px",
          },
          [theme.breakpoints.down("sm")]: {
            maxWidth: "100%",
            padding: "0 36px",
          },
        },
      },
    },
    modalreTakeQuiz: {
      maxWidth: "100%",
      backgroundColor: "#ffffff",
      position: "relative",
      borderRadius: "24px",
      overflow: "hidden",
      width: 580,
      padding: "48px",
      [theme.breakpoints.down("xs")]: {
        padding: "24px",
        maxWidth: "327px",
        width: "90%",
        borderRadius: "12px",
        backgroundColor: "#F2F8FC",
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "22px",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "23px",
        },
      },
      "& .modal-title": {
        fontSize: "24px",
        lineHeight: "30px",
        color: "#2B2B2B",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        // [theme.breakpoints.down("md")]: {
        //   fontSize: "20px",
        //   lineHeight: "24px",
        // },
        [theme.breakpoints.down("xs")]: {
          fontSize: "18px",
          lineHeight: "24px",
        },
      },
      "& .modal-desc": {
        fontSize: "14px",
        letterSpacing: "0.0075em",
        lineHeight: "26px",
        color: "#2b2b2b",
        fontWeight: 400,
        fontFamily: "Roboto",
        [theme.breakpoints.down("xs")]: {
          fontSize: "13px",
          lineHeight: "17px",
        },
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .modal-footer": {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "32px",
        [theme.breakpoints.down("xs")]: {
          marginTop: "24px",
        },
        "& .primary-btn, & .secondary-btn ": {
          width: "auto",
          minWidth: "156px",
          [theme.breakpoints.down("xs")]: {
            minWidth: "70px",
            padding: "4px 6px",
          },
        },
        "& .MuiButton-root": {
          marginRight: "24px",
          marginLeft: "0",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
      "& .primary-btn": {
        minHeight: "48px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "0",
        [theme.breakpoints.down("xs")]: {
          minHeight: "30px",
        },
      },
      "& .secondary-btn": {
        minHeight: "48px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "0",
        [theme.breakpoints.down("xs")]: {
          minHeight: "30px",
          border: "0",
          color: "#4BA3B7",
        },
      },
    },

    stepRoot: {
      borderLeftStyle: "dashed",
      borderLeftWidth: "1px",
      borderLeftColor: "#2B2B2BDE",
      marginLeft: "4px",
    },

    activeLabel: { backgroundColor: "#F2F8FC", height: "40px" },
    inactiveLabel: { backgroundColor: "transparent", height: "40px" },
    simulationQuizModal: {
      position: "relative",
      backgroundColor: "#ffffff",
      borderRadius: "24px",
      overflow: "hidden",
      maxWidth: "1274px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        maxWidth: "1097px",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "905px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "100%",
        borderRadius: 0,
      },
      "& .info-content-box": {
        position: "absolute",
        right: "0",
        bottom: "0",
        padding: "24px",
        borderRadius: "24px 0px 0px 0px",
        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2B2B2B",
        boxShadow: "0px -16px 48px 0px rgba(0, 0, 0, 0.22)",
        maxWidth: "279px",
        width: "100%",
        maxHeight: "100%",
        overflowY: "auto",
        "& .info-heading": {
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "5px",
          "& .heading": {
            color: "#FFFFFF",
            fontFamily: "Expletus Sans",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 400,
          },       
          "& .close-icon": {
            marginLeft: "10px",
            "& .MuiSvgIcon-root": {
              color: "#FFFFFF",
            }
          },  
        },
        "& .slider-progress": {
          padding: "16px",
          border: "1px dashed rgba(255, 255, 255, 0.37)",
          "&.slider-wrap": {
            padding: "48px 16px 16px",
          },
          "& .progress-wrap": {
            width: "100%",
            height: "4px",
            background: "#ECF1F4",
            borderRadius: "6px",
            "& .progress-inner": {              
              background: "#94A3B8",
              height: "inherit",
              borderRadius: "inherit",
              maxWidth: "100%",
            }
          }
        },
        "& .MuiSlider-rail": {
          height: "4px",
          opacity: "1",
          backgroundColor: "#ECF1F4",
          borderRadius: "6px",
        },
        "& .MuiSlider-markLabel": {
          top: "28px",
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontSize: "10px",
          fontWeight: 400,
          lineHeight: "12px",
          textTransform: "uppercase",
          display: "inline-flex",
          alignItems: "center",
          flexDirection: "column",
          "& span": {
            display: "block",
            marginBottom: "3px",
          },
        },
        "& .MuiSlider-mark": {
          width: "1px",
          height: "6px",
          borderRadius: "0",
          backgroundColor: "#94A3B8",
          bottom: "0",
        },
        "& .MuiSlider-root": {
          padding: "0",
          marginBottom: "0",
          color: "#4BA3B7",
        },
        "& .MuiSlider-thumb": {
          width: "17px",
          height: "17px",
          backgroundColor: "white",
          border: "4px solid #4BA3B7",
          boxShadow: "none",
          marginLeft: "-8px",
          marginTop: "-7px",
        },
        "& .MuiSlider-track": {
          height: "4px",
          borderRadius: "6px",
          backgroundColor: "#4BA3B7",
          boxShadow: "none",
        },
        "& .MuiSlider-valueLabel": {
          color: "#4BA3B7",
          textAlign: "center",
          fontSize: "10px",
          fontFamily: "Expletus Sans",
          fontWeight: 500,
          lineHeight: "14px",
          letterSpacing: "0",
          top: "-20px",
          "&:after": {
            content: "''",
            width: "0",
            height: "0",
            borderLeft: "3px solid transparent",
            borderRight: "3px solid transparent",
            borderTop: "4px solid #4BA3B7",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          },
          "& >span": {
            width: "21px",
            height: "18px",
            transform: "unset",
            borderRadius: "5px",
            "& >span": {
              color: "#ffffff",
              transform: "unset",
            },
          },
        },
        "& .para": {
          marginBottom: "24px",
          "&:last-child": {
            marginBottom: "0",
          }
        },
        "& .sub-txt": {
          color: "#FFFFFF",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px", 
          letterSpacing: "0.09px",    
        },
      },
      "& .simulationquiz-inner": {
        padding: "24px 40px 40px",
        maxHeight: "90vh",
        overflowY: "auto",
        [theme.breakpoints.down("md")]: {
          padding: "24px 40px 36px",
        },
        [theme.breakpoints.down("sm")]: {
          padding: "24px 0 36px",
        },
      },
      "& .quiz-result-heading": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: "24px",
        [theme.breakpoints.down("md")]: {
          marginBottom: "30px",
        },
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
        "& .close-icon": {
          color: "#94A3B8",
        },
      },
      "& .quiz-result-content": {
        display: "flex",
        flexWrap: "wrap",
      },

      "& .MuiStepper-root": {
        padding: "0 0 40px",
        "& .MuiStep-root": {
          padding: "0 24px",
          marginBottom: "16px",
          "&:last-child": {
            marginBottom: "0",
            "& .MuiStepLabel-root": {
              "&:before": {
                display: "none",
              },
            },
          },
          "& .MuiStepLabel-root": {
            position: "relative",
            "&:before": {
              content: '""',
              height: "16px",
              borderRight: "1px dashed #2B2B2BDE",
              position: "absolute",
              top: "100%",
              left: "3px",
            },
          },
          "& .MuiStepLabel-iconContainer": {
            paddingRight: "14px",
          },
          "&:first-child": {
            height: "40px",
            display: "flex",
            background: "#F2F8FC",
          },
        },
      },
      "& .MuiStepContent-root": {
        marginLeft: "4px",
      },
      "& .MuiStepConnector-line": {
        marginLeft: "-8px",
      },
      "& .MuiStepConnector-vertical": {
        padding: "0 23px",
      },
      "& .MuiStepConnector-lineVertical": {
        minHeight: "16px",
        borderLeftStyle: "dashed",
        borderLeftWidth: "1px",
        borderLeftColor: "#2B2B2BDE",
      },

      "& .left-box": {
        width: "250px",
        border: "1px solid #E8ECF2",
        borderRadius: "12px",
        overflowY: "auto",
        maxHeight: "calc(90vh - 158px)",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
        [theme.breakpoints.down("sm")]: {
          width: "225px",
        },
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
        "& .heading-wrapper": {
          padding: "24px",
        },
        "& .heading": {
          fontFamily: "Expletus Sans",
          fontWeight: "400",
          fontSize: "18px",
          lineHeight: "24px",
          color: "#2B2B2B",
          letterSpacing: "0",
          marginBottom: "8px",
        },
        "& .sub-title": {
          fontFamily: "Roboto",
          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "16px",
          color: "#2B2B2B",
          letterSpacing: "0.09px",
          opacity: "0.67",
        },
        "& .stepper": {
          padding: 0,
          marginTop: "30px",
        },
        "& .score-box-timeline": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          "& .txt-time-update": {
            fontFamily: "Expletus Sans",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "16px",
            color: "#2B2B2B",
          },
        },
        "& .simulation-date": {
          "& .MuiStepLabel-label": {
            color: "rgba(43, 43, 43, 0.87)",
            fontWeight: 600,
            fontFamily: "Roboto",
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.09px",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "8px",
          },
        },
      },
      "& .center-box": {
        flex: "1",
        padding: "0 48px",
        [theme.breakpoints.down("md")]: {
          padding: "0 34px",
        },
        [theme.breakpoints.down("sm")]: {
          padding: "0 22px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: 0,
        },
        "& .center-box-header": {
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "24px",
          [theme.breakpoints.down("xs")]: {
            flexDirection:"column-reverse",
          },
          "& .desktop-header": {
            [theme.breakpoints.down("xs")]: {
              marginTop:"35px",
              padding:"0 24px",
            },
            "& .header-title": {
              fontFamily: "Expletus Sans",
              fontWeight: 400,
              fontSize: "22px",
              lineHeight: "26px",
              color: "#2B2B2B",
              letterSpacing: "0",
              marginBottom: "8px",
            },
            "& .sub-title": {
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "17px",
              color: "#2B2B2B",
              letterSpacing: "0",
              opacity: "0.67",
            },
          },
          "& .mb-header": {
            display: "none",
            [theme.breakpoints.down("xs")]: {
              display: "flex",
              flexDirection:"column",
              width: "100%",
              backgroundColor: "rgba(238, 132, 100, 0.18)",
              borderBottomLeftRadius: "24px",
              borderBottomRightRadius: "24px",
            },
            "& .navigation": {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: "40px",
              backgroundColor: "#FFF",
              padding:"0 24px",
              "& a":{
                "& svg":{
                  "& path":{
                    fill:"rgba(43, 43, 43, 0.67)",
                  }
                }
              },
            },
            "& .mb-header-right": {
              position: "absolute",
              right: "24px",
              "& .MuiButton-root": {
                minWidth: 24,
                "& .img-icon": {
                  width: 24,
                  height: 24,
                },
              },
            },
            "& .simu-score-result": {
              display: "none",
              [theme.breakpoints.down("xs")]: {
                height: "auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor: "#FFF",
                borderBottomLeftRadius: "24px",
                borderBottomRightRadius: "24px",
                padding: "40px 20px"
              },

              "& .box-text": {
                "& .txt-title": {
                  fontSize: "22px",
                  fontWeight: "400",
                  fontFamily: "Expletus Sans",
                },
                "& .txt-sub-title": {
                  color: "#2B2B2B",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "17px",
                },
              },
              "& .right-box": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "85.266px",
                height: "43.878px",
                boxShadow: "none",
                position: "relative",
                "& .img-simu-score": {},
                "& .score-value": {
                  position: "absolute",
                  top: 13,
                  fontWeight: "700",
                  fontSize: "29px",
                  fontFamily: "Expletus Sans",
                },
              },
            },
            "& .offical-simu-score-result": {
              display: "none",
              [theme.breakpoints.down("xs")]: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: "60px",
                marginTop: "25px",
                padding: "0 20px 20px",
                // background: "var(--messages-color-warning, #EE8464)",
                // backgroundOpacity: 0.08,
                // borderBottomLeftRadius: "24px",
                // borderBottomRightRadius: "24px",
              },

              "& .offical-box-text": {
                "& .txt-title": {
                  fontSize: "18px",
                  fontWeight: "400",
                  fontFamily: "Expletus Sans",
                },
                "& .txt-sub-title": {
                  color: "rgba(43, 43, 43, 0.87)",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "17px",
                },
              },
              "& .offical-right-box": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "none",
                position: "relative",
                "& .img-score-outline": {
                  height: "47px",
                  width: "47px",
                  objectFit: "contain",
                },
                "& .score": {
                  position: "absolute",
                  color: "#EE8464",
                  fontFamily: "Roboto",
                  fontSize: "23.5px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "31.333px",
                  letterSpacing: "0.176px",
                },
              },
            },
          },
        },
        "& .quiz-question-wrapper": {
            padding:"0 24px",
          "& .quiz-question-box-v2": {
            marginBottom: "24px",
          },
          "& .txt-equiz-question": {
            fontFamily: "Expletus Sans",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            textAlign: "left",
            marginBottom: "50px",
            color: "#2b2b2b",
          },
          "& .quiz-slider-full-details": {
            "& .MuiSlider-rail": {
              height: "4px",
              opacity: "1",
              backgroundColor: "#ECF1F4",
              borderRadius: "6px",
            },
            "& .MuiSlider-markLabel": {
              top: "28px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "10px",
              fontWeight: 400,
              lineHeight: "12px",
              textTransform: "uppercase",
              display: "inline-flex",
              alignItems: "center",
              flexDirection: "column",
              "& span": {
                display: "block",
                marginBottom: "3px",
              },
            },
            "& .MuiSlider-mark": {
              width: "1px",
              height: "6px",
              borderRadius: "0",
              backgroundColor: "#94A3B8",
              bottom: "0",
            },
            "&.MuiSlider-root": {
              padding: "0 0 19px",
              marginBottom: "35px",
              color: "#4BA3B7",
            },
            "& .MuiSlider-thumb": {
              width: "20px",
              height: "20px",
              backgroundColor: "white",
              border: "4px solid #4BA3B7",
              boxShadow: "none",
              marginLeft: "-10px",
              marginTop: "-7px",
            },
            "& .MuiSlider-track": {
              height: "4px",
              borderRadius: "6px",
              backgroundColor: "#4BA3B7",
              boxShadow: "none",
            },
            "& .MuiSlider-valueLabel": {
              color: "#4BA3B7",
              textAlign: "center",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
              lineHeight: "18px",
              letterSpacing: "0",
              "&:after": {
                content: "''",
                width: "0",
                height: "0",
                borderLeft: "6px solid transparent",
                borderRight: "6px solid transparent",
                borderTop: "7px solid #4BA3B7",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
              },
              "& >span": {
                width: "31px",
                height: "27px",
                transform: "unset",
                borderRadius: "8px",
                "& >span": {
                  color: "#ffffff",
                  transform: "unset",
                },
              },
            },
          },
        },
        "& .button-wrapper": {
          marginTop: "50px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          [theme.breakpoints.down("xs")]: {
            position: "fixed",
            bottom: "24px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding:"0 24px",
          },
          "& .primaryButton": {
            [theme.breakpoints.down("sm")]: {
              minWidth: "130px",
            },
            [theme.breakpoints.down("xs")]: {
              width: "100%",
              marginTop: "16px",
              height: "48px",
            },
          },
          "& .btn-secondary": {
            background: "transparent",
            border: "1px solid #e8ecf2",
            borderRadius: "7px",
            filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
            fontSize: "14px",
            lineHeight: "18px",
            color: "#2b2b2b",
            fontWeight: 500,
            fontFamily: "Expletus Sans",
            textAlign: "center",
            textTransform: "unset",
            maxWidth: "100%",
            padding: "12px 10px",
            minHeight: "48px",
            marginBottom: "0",
            marginRight: "16px",
            transition: "all 0.5s ease-in-out",
            letterSpacing: "0",
            textDecoration: "none",
            width: "156px",
            [theme.breakpoints.down("sm")]: {
              width: "130px",
            },
            [theme.breakpoints.down("xs")]: {
              width: "100%",
              margin: 0,
              height: "48px",
            },
            "&:hover": {
              background: "transparent",
              color: "#2b2b2b",
            },
          },
          "& .MuiButton-root": {
            marginRight: "16px",
            marginLeft: "0",
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
      },
      "& .right-box": {
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
        width: "320px",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
        [theme.breakpoints.down("md")]: {
          width: "305px",
        },
        [theme.breakpoints.down("sm")]: {
          width: "272px",
        },
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
        "& .right-header-box": {
          display: "flex",
          justifyContent: "space-between",
          padding: "24px",
          alignItems: "center",
          backgroundColor: "#EE8464",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          [theme.breakpoints.down("md")]: {
            padding: "24px",
          },
          "& .heading": {
            fontFamily: "Expletus Sans",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "24px",
            color: "#ffffff",
            letterSpacing: "0",
            marginBottom: "8px",
          },
          "& .sub-title": {
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "16px",
            color: "#ffffff",
            letterSpacing: "0.09px",
          },
        },
        "& .group": {
          marginLeft: "10px",
          "& .overlap-group": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: `url(${starSimulate})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "47px",
            width: "47px",
            "& .group-name": {
              color: "#FFFFFF",
              fontSize: "23.5px",
              fontWeight: "400",
              lineHeight: "31.3px",
              whiteSpace: "nowrap",
              fontFamily: "Roboto",
              letterSpacing: "0.176px",
            },
          },
        },
        "& .txt-right-box-header": {
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "19px",
          letterSpacing: 0,
          color: "#4BA3B7",
          marginBottom: "24px",
        },
        "& .right-box-content": {
          padding: "24px",
          [theme.breakpoints.down("sm")]: {
            padding: "18px 18px 28px",
          },
          "& .content-top-header": {
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "34px",
            [theme.breakpoints.down("md")]: {
              marginBottom: "31px",
            },
            [theme.breakpoints.down("sm")]: {
              marginBottom: "30px",
            },
            "& .simulation-score": {
              fontFamily: "Expletus Sans",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "24px",
              color: "#2B2B2B",
              letterSpacing: 0,
              marginBottom: "8px",
            },
            "& .sub-simulation-score": {
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "16px",
              color: "#2B2B2B",
              letterSpacing: "0.09px",
              opacity: "0.67",
            },
          },
          "& .display-score": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            "& .commentBox": {
              marginTop: "24px",
              [theme.breakpoints.down("md")]: {
                marginTop: "12px",
              },
            },
            "& .disScoreImg": {
              width: "264px",
              objectFit: "contain",
              [theme.breakpoints.down("md")]: {
                width: "241px",
              },
              [theme.breakpoints.down("sm")]: {
                width: "220px",
              },
            },
            "& .score": {
              fontFamily: "Expletus Sans",
              fontWeight: 700,
              fontSize: "71px",
              color: "#2B2B2B",
              position: "absolute",
              top: "60px",
              lineHeight: "94px",
              letterSpacing: "0",
              [theme.breakpoints.down("md")]: {
                top: "62px",
              },
              [theme.breakpoints.down("sm")]: {
                fontSize: "58px",
                top: "62px",
              },
            },

            "& .comment": {
              fontFamily: "Expletus Sans",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
              color: "#2B2B2B",
              letterSpacing: "0",
              marginTop: "20px",
              textAlign: "center",
              [theme.breakpoints.down("sm")]: {
                marginTop: "44px",
              },
              "& .grade-score-A": {
                color: "#00BA88",
              },
              "& .grade-score-B": {
                color: "#12AEF1",
              },
              "& .grade-score-C": {
                color: "#F7E223",
              },
              "& .grade-score-D": {
                color: "#EE8464",
              },
              "& .grade-score-E": {
                color: "#C7263E",
              },
            },
          },
          "& .imporove-score-wrap": {
            position: "relative",
            "&:hover": {
              "& .wrap-black-box": {
                display: "flex",
              }
            }
          },
          "& .btn-improve-score": {
            marginTop: "45px",
            background: "transparent",
            border: "1px solid #e8ecf2",
            borderRadius: "7px",
            filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
            fontSize: "14px",
            lineHeight: "18px",
            color: "#2b2b2b",
            fontWeight: 500,
            fontFamily: "Expletus Sans",
            textAlign: "center",
            textTransform: "unset",
            maxWidth: "100%",
            width: "100%",
            padding: "12px 10px",
            minHeight: "48px",
            transition: "all 0.5s ease-in-out",
            letterSpacing: "0",
            textDecoration: "none",
            [theme.breakpoints.down("md")]: {
              marginTop: "16px",
            },
            [theme.breakpoints.down("sm")]: {
              marginTop: "21px",
            },
            "&:hover": {
              background: "transparent",
              color: "#2b2b2b",
            },
          },
          "& .wrap-black-box": {
            display: "none",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            marginBottom: "15px",
            bottom: "48px",
            "& .black-box": {
              background: "#2B2B2B",
              padding: "16px",
              borderRadius: "8px",

              "& .comment-out": {
                color: "#FFF",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "24px",
              },
              "& .highlight": {
                "& .highlight-link":{
                  color: "#4BA3B7",
                margin: "0 3 px",
                textDecoration: "none",
                },
                
              },
            },
          },
          "& .info-wrapper": {
            "& .info-img": {
              width: "24px",
              height: "24px",
            }
          }
        },
      },
    },

    mobileStepIndicator: {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "24px 0px",
      },
      "& .btn-top-back": {
        minWidth: "24px",
        padding: 0,
        margin: 0,
      },
      "& .step-indicator": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& .step-indicator-title": {
          borderRadius: "6px",
          backgroundColor: "rgba(75, 163, 183, 0.08)",
          padding: "4px 12px",
          "& .text-take-equiz": {
            color: "#4BA3B7",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: " 0.09px",
          },
        },
        "& .step-name": {
          marginLeft: "8px",
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
        },
      },
    },
    paginationToolbar: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "1px solid #e8ecf2",
      padding: "4px 0px 5px 16px",
      [theme.breakpoints.down("xs")]: {
        padding: "10px 23px 7px 14px",
      },
      "& .member-count": {
        fontSize: "14px",
        letterSpacing: "unset",
        lineHeight: "20px",
        color: "#2B2B2B",
        fontWeight: 500,
        fontFamily: "Expletus Sans",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px",
          letterSpacing: "0",
          lineHeight: "16px",
        },
        "& .delete-icon": {
          marginLeft: "23px",
        },
      },
    },
  });
// Customizable Area End
