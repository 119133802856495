//Customizable Area Start
import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import { Typography, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "./EquizStyles.web";
import EnterningQuizControllerWeb, {
  Props,
} from "./EnteringQuizController.web";
import QuestionComponent from "./components/QuestionComponent/QuestionComponent.web";
import TakeQuizWeb from "./components/QuestionComponent/TakeQuiz.web";
import EquizScoreWeb from "./QuizScoreScreen.web";
import { getSteps, ColorlibStepIcon } from "./StepUtils.web";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import WebLoader from "../../../components/src/WebLoader.web";
let stepsLabel: any = "";
export class EnteringQuiz extends EnterningQuizControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  getStepContent = (step: any) => {
    const { classes } = this.props;
    let quizId = this.requestedQuizId;
    if (quizId) {
      stepsLabel = {
        step1: this.t("Basic Info"),
        step2: this.t("Take E-Quiz"),
        step3: this.t("Get E-Score"),
      };
    } else {
      stepsLabel = {
        step1: this.t("Basic Info"),
        step2: this.t("Create E-Quiz"),
        step3: this.t("Take E-Quiz"),
        step4: this.t("Get E-Score"),
      };
    }
    const steps = getSteps(this.state.activeStep, stepsLabel, this.t);
    const { activeStep } = this.state;
    let createdQuiz = this.createdQuiz;
    if (quizId) {
      switch (step) {
        case 0:
          return (
            <Box className="button-wrapper">
              <Button
                className={`secondary-btn ${classes.secondaryButton}`}
                onClick={this.handleBackArrow}
              >
                {this.t("Cancel")}
              </Button>
              <Button
                onClick={this.handleNext}
                className={`primary-btn ${classes.primaryButton}`}
                
              >
                {this.t("Finish")}
              </Button>
            </Box>
          );
        case 1:
          return (
              <Box className="member-upload-content">
                <TakeQuizWeb
                  quizData={this.state.questionsRequestedData}
                  quizTitle={this.state.quizTitle}
                  quizCaption={this.state.quizCaption}
                  saveData={this.saveSlideAnswer}
                  data-test-id="take-quiz-list"
                />
                <Box className="button-wrapper">
                  <Button
                    className={`secondary-btn ${classes.secondaryButton}`}
                    onClick={this.handleBackArrow}
                    data-test-id="handle-backarrow-btn"
                  >
                   {this.t("Back")}
                  </Button>

                  <Button
                    onClick={this.handleQuizSubmission}
                    className={`primary-btn ${classes.primaryButton}`}
                  >
                    {activeStep === steps.length - 1 ? this.t("Finish") : this.t("Next Step")}
                  </Button>
                </Box>
              </Box>
          );
        case 2:
          return (
            <Box className="member-upload-content">
              <EquizScoreWeb
                quizTitle={this.state.quizTitle}
                grade={this.state.quizGrade}
                reTakeBtn={this.reTakeBtnHandler}
                dashboardBtnHandler={this.handleDashboardClick}
                scoreDescriptionType="chooseQuiz"
                isLoadingState={this.state.isLoading}
                t={this.t} 
              />
            </Box>
          );

        default:
          return null;
      }
    } else {
      switch (step) {
        case 0:
          return (
            <Box className="button-wrapper">
              <Button
                className={`secondary-btn ${classes.secondaryButton}`}
                onClick={this.handleBackArrow}
              >
                {this.t("Cancel")}
              </Button>
              <Button
                onClick={this.handleNext}
                className={`primary-btn ${classes.primaryButton}`}
              >
               {this.t("Finish")}
              </Button>
            </Box>
          );
        case 1:
          return (
            <Box className="member-upload-content">
              <QuestionComponent
                   classes={classes}
                   sliders={this.state.sliders}
                   minimumInputChange={(index, event) =>
                     this.handleMinimumInputChange(index, event)
                   }
                   maximumInputChange={this.handleMaximumInputChange}
                   sliderChange={this.handleChange}
                   addAnotherSlider={this.handleAddSlider}
                   confirmDelete={this.handleDeleteSlider}
                   deleteQuestion={this.handleDeleteQuestion}
                   closeModal={this.closeDeleteQuestion}
                   isDeletingQuestion={this.state.isDeletingQuestion}
                   addQuestionText={this.handleQuestionChange}
                   quizDetails={createdQuiz}
                   editingQuiz={this.state.isEditing}
                   t={this.t}
                   data-test-id="question-component"
              />
             <Box className="button-wrapper">
        {/* This is the new button we added */}
        <Button
          variant="outlined"
          onClick={() => this.handleAddSlider(this.state.sliders.length - 1)}
          className="add-slider-btn"
          style={{ marginBottom: '19px' ,width:"100%",marginRight:"0px"}}
          data-test-id="add-question-btn"
        >
          {this.t("Add another E-question")}
        </Button>

        {/* Existing buttons remain unchanged */}
        {this.state.isEditing ? (
          <>
            <Button
              className={`secondary-btn ${classes.secondaryButton}`}
              onClick={this.handleEditBackBtn}
              data-test-id="backArrowbtn"
            >
              {this.t("Back")}
            </Button>
            <Button
              onClick={this.editQuestionSbmt}
              className={`primary-btn ${classes.primaryButton}`}
              data-testid="editSbmtBtn"
            >
              {this.t("Save")}
            </Button>
          </>
        ) : (
          <>
            <Button
              className={`secondary-btn ${classes.secondaryButton}`}
              onClick={this.handleExitQuiz}
              data-test-id="backArrowbtn"
            >
              {this.t("Cancel")}
            </Button>
            <Button
              onClick={this.addQuestion}
              style={{ position:"static"}}
              className={`primary-btn ${classes.primaryButton}`}
              data-test-id="finishBtn"
            >
              {this.t("Finish")}
            </Button>
          </>
        )}
      </Box>
              {this.state.isExitingQuiz && (
                <Modal
                  open={this.state.isExitingQuiz}
                  onClose={this.handleExitQuizClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className={classes.modalBoxCenter}
                  data-test-id="exit-quiz-modal"
                >
                  <Box className={classes.modalreTakeQuiz}>
                    <Box className="modal-heading">
                      <Typography className="modal-title" component="h2">
                        {this.t("Exit E-score")}
                      </Typography>
                      <Link
                        to="#"
                        className="close-icon"
                        onClick={this.handleExitQuizClose}
                      >
                        <CloseIcon style={{ fontSize: "24px" }} />
                      </Link>
                    </Box>
                    <Box className="modal-description">
                      <Typography className="modal-desc">
                        {this.t("Are you sure you want to exit? All progress will be lost.")}
                      </Typography>
                      <Box className="modal-footer">
                        <Button
                          className={`secondary-btn ${classes.secondaryButton}`}
                          title="Cancel"
                          data-test-id="btn-cancel-quiz"
                          onClick={this.handleExitQuizClose}
                        >
                          {this.t("Cancel")}
                        </Button>
                        <Button
                          className={`primary-btn ${classes.primaryButton}`}
                          title="Exit Quiz"
                          data-test-id="btn-exit-quiz"
                          onClick={this.handleExitRedirect}
                        >
                          {this.t("Exit")}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Modal>
              )}
            </Box>
          );
        case 2:
          return (
              <Box className="member-upload-content">
                <TakeQuizWeb
                  quizData={this.state.questionsRequestedData}
                  quizTitle={this.state.quizTitle}
                  quizCaption={this.state.quizCaption}
                  saveData={this.saveSlideAnswer}
                  data-test-id="take-quiz-component"
                />
                <Box className="button-wrapper">
                  <Button
                    className={`secondary-btn ${classes.secondaryButton}`}
                    onClick={this.handleBackArrow}
                    data-test-id="backArrowTakeQuiz"
                  >
                    {this.t("Cancel")}
                  </Button>
                  <Button
                    onClick={this.handleQuizSubmission}
                    className={`primary-btn ${classes.primaryButton}`}
                    data-test-id="submit-quiz-btn"
                  >
                    {activeStep === steps.length - 1 ? this.t("Finish") : this.t("Submit")}
                  </Button>
                </Box>
              </Box>
          );
        case 3:
          return (
            <Box className="member-upload-content">
              <EquizScoreWeb
                quizTitle={this.state.quizTitle}
                grade={this.state.quizGrade}
                reTakeBtn={this.reTakeBtnHandler}
                scoreDescriptionType="createQuiz"
                dashboardBtnHandler={this.handleDashboardClick}
                isLoadingState={this.state.isLoading}
                t={this.t}
              />
            </Box>
          );
        default:
          return null;
      }
    }
  };

  render() {
    const { classes } = this.props;
    const steps = getSteps(this.state.activeStep, stepsLabel, this.t);
    const { activeStep } = this.state;
    const maxSteps = steps.length;

    return (
      <>
      {this.state.isLoading && <WebLoader/>}
      <Box className={classes.stepperRoot}>
        <Box className="stepper-top">
          <Box className="stepper-row">
            <Box className="stepper-col">
            {!(maxSteps === 3 && activeStep === 2) && !(maxSteps === 4 && activeStep === 3) &&
              <Box className="back-btn-wrap" onClick={this.handleBackArrow}>
                <i>
                  <svg
                    className="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                  </svg>
                </i>
              </Box>
              }
            </Box>
            <Box className="stepper-col stepper-middle">
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const props = { completed: false };
                  const labelProps = {};
                  if (this.isStepCompleted(index)) {
                    props.completed = true;
                  }
                  return (
                    <Step
                      key={`step-${index.toString()}`}
                      {...props}
                      className={`step-root ${
                        activeStep == index ? "active-step-root" : ""
                      }`}
                    >
                      <StepLabel
                        {...labelProps}
                        StepIconComponent={ColorlibStepIcon}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <MobileStepper
                steps={maxSteps}
                position="static"
                variant="text"
                activeStep={activeStep}
                nextButton={<></>}
                backButton={<></>}
                className="sm-visible"
              />
            </Box>

            <Box className="stepper-col stepper-action">
              {this.requestedQuizId === null && this.state.activeStep == 2 && (
                <Button
                  className={`secondary-btn ${classes.secondaryButton}`}
                  onClick={this.handleEditBtn}
                  data-test-id="edit-quiz-btn"
                >
                  {this.t("Edit Quiz")}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Box className="step-content-root">
          {activeStep === steps.length ? (
            <Box className="step-content">
              <Typography className="heading">
                {this.t("All steps completed - you're finished")}
              </Typography>
            </Box>
          ) : (
            <Box className="step-content">
              {this.getStepContent(activeStep)}
            </Box>
          )}
        </Box>
      </Box>
      </>
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(EnteringQuiz);
//Customizable Area End
