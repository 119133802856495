// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Box, CardMedia } from "@material-ui/core";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./ContactsListStyles.web";
import { toast } from "react-toastify";
import { warningIcon, createContactIcon, deleteOutline } from "./assets";
import i18n from "../../../web/src/utilities/i18n";

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation: any;
  id: string;
  classes: any;
  handleCloseDialog: any;
  handleMinimizeCreateContact: () => void;
  contactList?: () => void;
  isCreatingFromDeal?: boolean;
  handleEscCreateContact:(event:any) => void;
  anchorElState:any;
  createContactUndo?: (contactId:string) => void;
}

interface S {
  showBussinessInfo: boolean;
  showShippingInfo: boolean;
  showNotes: boolean;
  showFavoritesProduct: boolean;
  showGroup: boolean;

  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  businessGroup: string;
  companyName: string;
  vatNumber: string;
  shippingAddressCountry: string;
  shippingAddressCity: string;
  shippingAddressState: string;
  shippingAddressPinCode: string;
  shippingAddress1: string;
  shippingAddress2: string;

  billingAddressCountry: string;
  billingAddressCity: string;
  billingAddressState: string;
  billingAddressPinCode: string;
  billingAddress1: string;
  billingAddress2: string;

  notes: string;
  favoriteProducts: string[];
  groups: string[];
  error: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    companyName: string;
    vatNumber: string;
    newGroup: string;
    errorbusinessCategory: string;
  };
  checked: boolean;
  businessCategoryList: any;
  isFavProduct: boolean;
  searchTerm: string;
  selectedOptions: any;
  isSearching: boolean;
  searchedGroup: any;
  isNewGroup: boolean;
  newGroup: string;
  selectedProductsId: any;
  totalAmount: any;
  selectedProductsData: any;
  countryList: any;
  cityList: any;
  stateList: any;
  stateListShipping: any;
  cityListShipping: any;
  currentProductID: string;
  businessCategorys: any[];
  businessCategory: any;
  deleteBCategoryId: any;
  isDeleting: boolean;
  deleteTitle:string;
  createdContactId: string;
  countrySearchVal: string;
  stateSearchValue:string;
  citySearchValue:string;
  productsQuantity: number | null;
}

interface SS {
  id: any;
}

export default class CreateContactController extends BlockComponent<
  Props,
  S,
  SS
> {
  userSessionData: any;
  userToken: any;
  createContactApiId: string = "";
  getGroupsApiID: string = "";
  getBusinessCategoryApiID: string = "";
  getSearchGroupRequestID: string = "";
  createGroupId: string = "";
  countryListAPICallEventId: string = "";
  cityListAPICallEventId: string = "";
  stateListAPICallEventId: string = "";
  stateListAPICallEventIdShipping:string = "";
  cityListAPICallEventIdShipping:string = "";
  currenBusinessCategory: { id: number };
  showDialogTitle: any;
  setErrorTitle: any;
  updateBussinesCategoryRequestId: string = "";
  createBussinesCategoryRequestId: string = "";
  getBusinesCategoryRequestId: string = "";
  deleteBCategoryApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];
    this.state = {
      showBussinessInfo: false,
      showShippingInfo: false,
      showNotes: false,
      showFavoritesProduct: false,
      showGroup: false,

      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      businessGroup: "",
      companyName: "",
      vatNumber: "",

      shippingAddressCountry: "",
      shippingAddressCity: "",
      shippingAddressState: "",
      shippingAddressPinCode: "",
      shippingAddress1: "",
      shippingAddress2: "",

      billingAddressCountry: "",
      billingAddressCity: "",
      billingAddressState: "",
      billingAddressPinCode: "",
      billingAddress1: "",
      billingAddress2: "",
      notes: "",
      favoriteProducts: [],
      groups: [],
      error: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        companyName: "",
        vatNumber: "",
        newGroup: "",
        errorbusinessCategory: "",
      },
      checked: false,
      businessCategoryList: [],
      isFavProduct: false,
      searchTerm: "",
      selectedOptions: [],
      isSearching: false,
      searchedGroup: [],
      isNewGroup: false,
      newGroup: "",
      selectedProductsId: [],
      totalAmount: "",
      selectedProductsData: [],
      countryList: [],
      cityList: [],
      stateList: [],
      stateListShipping: [],
      cityListShipping: [],
      currentProductID: "1",
      businessCategorys: [],
      businessCategory: null,
      deleteBCategoryId: "",
      isDeleting: false,
      deleteTitle:"",
      createdContactId: "",
      countrySearchVal: "",
      stateSearchValue:"",
      citySearchValue:"",
      productsQuantity: 0,
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.currenBusinessCategory = { id: 0 };
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.clearError(prevState);
    this.checkBusinessCategoryState(prevState);
    if (prevState.newGroup !== this.state.newGroup) {
      this.setState({
        error: {
          ...this.state.error,
          newGroup: "",
        },
      });
    }   
    if (
      prevState.billingAddressCountry !== this.state.billingAddressCountry ||
      prevState.countryList !== this.state.countryList
    ) {
      this.setState({ cityList: [], stateList: [] });
      this.stateListAPICallEvent();
    }
    if(prevState.shippingAddressCountry !== this.state.shippingAddressCountry){
      this.setState({ stateListShipping: [] });
      this.stateListAPICallEventShipping();
    }
    if (
      prevState.billingAddressState !== this.state.billingAddressState ||
      prevState.stateList !== this.state.stateList
    ) {
      this.setState({ cityList: [] });
      this.cityListAPICallEvent();
    }
    if(prevState.shippingAddressState !== this.state.shippingAddressState){
      this.setState({ cityListShipping: [] });
      this.cityListAPICallEventShipping();
    }
  }

  async componentDidMount() {
    this.getGroupList();
    this.getBusinessCategory();
    this.countryListAPICallEvent();
    const lang = localStorage.getItem("lang") ?? "en";
    await i18n.changeLanguage(lang);
    this.fetchBCategoryList();
    this.loadDraftContact();
   
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      switch (apiRequestCallId) {
        case this.getGroupsApiID:
          this.setState({ groups: responseJson.data });
          break;
        case this.getBusinessCategoryApiID:
          this.setState({ businessCategoryList: responseJson });
          break;
        case this.getSearchGroupRequestID:
          this.setState({ searchedGroup: responseJson.data });
          break;
        case this.countryListAPICallEventId:
          this.setState({ countryList: responseJson.data });
          break;
        case this.stateListAPICallEventId:
          this.setState({ stateList: responseJson.data });
          break;
        case this.stateListAPICallEventIdShipping:
          this.setState({ stateListShipping: responseJson.data });
          break;
        case this.cityListAPICallEventIdShipping:
          this.setState({ cityListShipping: responseJson.data });
          break;
        case this.cityListAPICallEventId:
          this.setState({ cityList: responseJson.data });
          break;
        case this.createGroupId:
          this.createGroupResponse(responseJson);
          break;
        case this.createContactApiId:
          this.handleCreateContactResponse(responseJson)
          break;
        case this.getBusinesCategoryRequestId:
          this.setState({ businessCategorys: responseJson });
          break;
        case this.deleteBCategoryApiCallId:
          if (
            this.state.businessCategory &&
            this.state.deleteBCategoryId == this.state.businessCategory.id
          ) {
            this.setState({ businessCategory: null });
          }
          this.setState({ deleteBCategoryId: "" });
          this.fetchBCategoryList();
          this.handleCloseTitle();
          this.createToastNotificationSuccess(
            this.t(configJSON.deleteBCategoryTxtMsg), deleteOutline
          );
          break;
        case this.createBussinesCategoryRequestId:
          this.createBCategorySuccess(responseJson);
          break;
        case this.updateBussinesCategoryRequestId:
          this.updateBCategorySuccess(responseJson);
          break;
        default:
          this.parseApiCatchErrorResponse(errorResponse);
          break;
      }
    }
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  handleCloseTitle = () => {
    this.setState({
      isDeleting: false,
    });
  };
 
  loadDraftContact = () => {
    const draftContact = localStorage.getItem("draftContact");
    if (draftContact) {
      this.populateDraftContact(JSON.parse(draftContact));
    }
  }
  populateDraftContact(draftContact:any) {
    const lastDraft = draftContact[draftContact.length - 1];
    if (lastDraft) {
      this.setState({
        ...this.extractAddressDetails(lastDraft),
        ...this.extractGeneralDetails(lastDraft),
        ...this.extractPreferences(lastDraft)
      });
    }
  }
  extractAddressDetails(lastDraft:any) {
    return {
      shippingAddressCountry: lastDraft.shippingAddressCountry || "",
      shippingAddressCity: lastDraft.shippingAddressCity || "",
      shippingAddressState: lastDraft.shippingAddressState || "",
      shippingAddressPinCode: lastDraft.shippingAddressPinCode || "",
      shippingAddress1: lastDraft.shippingAddress1 || "",
      shippingAddress2: lastDraft.shippingAddress2 || "",
      billingAddressCountry: lastDraft.billingAddressCountry || "",
      billingAddressCity: lastDraft.billingAddressCity || "",
      billingAddressState: lastDraft.billingAddressState || "",
      billingAddressPinCode: lastDraft.billingAddressPinCode || "",
      billingAddress1: lastDraft.billingAddress1 || "",
      billingAddress2: lastDraft.billingAddress2 || ""
    };
  }
  extractGeneralDetails(lastDraft:any) {
    return {
      firstName: lastDraft.firstName || "",
      lastName: lastDraft.lastName || "",
      email: lastDraft.email || "",
      phone: lastDraft.phone || "",
      businessGroup: lastDraft.businessGroup || "",
      companyName: lastDraft.companyName || "",
      vatNumber: lastDraft.vatNumber || "",
      notes: lastDraft.notes || "",
      favoriteProducts: lastDraft.favoriteProducts || [],
      groups: lastDraft.groups || [],
      businessCategory: lastDraft.businessCategory || null,
      selectedOptions: lastDraft.selectedOptions || [],
      selectedProductsData: lastDraft.selectedProductsData || [],
      selectedProductsId: lastDraft.selectedProductsId || []
    };
  }

  extractPreferences(lastDraft:any) {
    return {
      checked: lastDraft.checked || false,
      showBussinessInfo: lastDraft.showBussinessInfo || false,
      showShippingInfo: lastDraft.showShippingInfo || false,
      showNotes: lastDraft.showNotes || false,
      showFavoritesProduct: lastDraft.showFavoritesProduct || false,
      showGroup: lastDraft.showGroup || false
    };
  }
  saveAsDraftContact = () => {
    const {
      firstName,
      lastName,
      email,
      phone,
      businessGroup,
      companyName,
      vatNumber,
      shippingAddressCountry,
      shippingAddressCity,
      shippingAddressState,
      shippingAddressPinCode,
      shippingAddress1,
      shippingAddress2,
      billingAddressCountry,
      billingAddressCity,
      billingAddressState,
      billingAddressPinCode,
      billingAddress1,
      billingAddress2,
      notes,
      favoriteProducts,
      groups,
      businessCategory,
      selectedOptions,
      selectedProductsData,
      checked,
      selectedProductsId,
      showBussinessInfo,
      showShippingInfo,
      showNotes,
      showFavoritesProduct,
      showGroup,
    } = this.state;
  
    let draftContact = []
  
    draftContact = [    
      {
        firstName,
        lastName,
        email,
        phone,
        businessGroup,
        companyName,
        vatNumber,
        shippingAddressCountry,
        shippingAddressCity,
        shippingAddressState,
        shippingAddressPinCode,
        shippingAddress1,
        shippingAddress2,
        billingAddressCountry,
        billingAddressCity,
        billingAddressState,
        billingAddressPinCode,
        billingAddress1,
        billingAddress2,
        notes,
        favoriteProducts,
        groups,
        businessCategory,
        selectedOptions,
        selectedProductsData,
        checked,
        selectedProductsId,
        showBussinessInfo,
        showShippingInfo,
        showNotes,
        showFavoritesProduct,
        showGroup,
      },
    ];  
    localStorage.setItem("draftContact", JSON.stringify(draftContact));
  };
  
  checkPrevStateBusinessCategory = (prevState: S) => {
    if (prevState.businessCategorys !== this.state.businessCategorys) {
      if (this.currenBusinessCategory.id !== 0) {
        const curTitle = this.state.businessCategorys.find(
          (x) => x.id === this.currenBusinessCategory.id
        );
        if (curTitle) {
          this.setState({
            businessCategory: {
              id: curTitle.id,
              title: curTitle.name,
            },
          });
        }
      }
      this.currenBusinessCategory = { id: 0 };
    }
  };

  checkBusinessCategoryState = (prevState: S) => {
    this.checkPrevStateBusinessCategory(prevState);
  };
  createBCategorySuccess = (responseJson: any) => {
    const errorMsg = responseJson.name[0];
    if (errorMsg && !responseJson.id) {
      this.setErrorTitle(this.t(configJSON.errorMsgForBussinesCategory));
    } else {
      this.currenBusinessCategory.id = responseJson.id;
      this.showDialogTitle(false);
      this.fetchBCategoryList();
    }
  };

  updateBCategorySuccess = (responseJson: any) => {
    const errorMsg = responseJson.name[0];
    if (errorMsg && !responseJson.id) {
      this.setErrorTitle(this.t(configJSON.errorMsgForBussinesCategory));
    } else {
      this.currenBusinessCategory.id = responseJson.id;
      this.showDialogTitle(false);
      this.fetchBCategoryList();
    }
  };

  clearError = (prevState: S) => {
    let error = { ...this.state.error };

    if (prevState.firstName !== this.state.firstName) {
      error.firstName = "";
    }

    if (prevState.lastName !== this.state.lastName) {
      error.lastName = "";
    }

    const email = this.state.email.trim();
    if (prevState.email !== email) {
      if (email === "") {
        error.email = "";
      } else if (!configJSON.emailRegex.test(email)) {
        error.email = this.t(configJSON.emailErrorMsg);
      } else {
        error.email = "";
      }
    }
    const phone = this.state.phone.trim();
    if (prevState.phone !== phone) {
      if (phone === "") {
        error.phone = "";
      } else if (!configJSON.phoneRegex.test(phone)) {
        error.phone = this.t(configJSON.phoneNumberErrorMsg);
      } else {
        error.phone = "";
      }
    }
    const vatNumber = this.state.vatNumber.trim();
    if (prevState.vatNumber !== vatNumber) {
      if (vatNumber === "") {
        error.vatNumber = "";
      } else if (!configJSON.vatNumberRegex.test(vatNumber)) {
        error.vatNumber = this.t(configJSON.vatNumberErrorMsg);
      } else {
        error.vatNumber = "";
      }
    }
    if (JSON.stringify(error) !== JSON.stringify(this.state.error)) {
      this.setState({ error: error });
    }
  };

  createContact = () => {
    let error = this.state.error;
    let hasError = false;
    if (this.state.firstName.trim() === "") {
      error.firstName = this.t(configJSON.firstNameBlankMsg);
      this.setState({ error: error });
      hasError = true;
    }
    if (this.state.firstName.length > 30) {
      error.firstName = 'First name cannot be more than 30 symbols';
      this.setState({ error: error });
      hasError = true;
    }
    if (this.state.lastName.trim() === "") {
      error.lastName = this.t(configJSON.lastNameBlankMsg);
      this.setState({ error: error });
      hasError = true;
    }

    if (this.state.lastName.length > 30) {
      error.lastName = 'Last name cannot be more than 30 symbols';
      this.setState({ error: error });
      hasError = true;
    }

    const email = this.state.email.trim();
    if (email === "") {
      this.setState({
        error: {
          ...this.state.error,
          email: "",
        },
      });
    } else if (!configJSON.emailRegex.test(email)) {
      error.email = this.t(configJSON.emailErrorMsg);
      this.setState({
        error: error,
      });
      hasError = true;
    }

    const phone = this.state.phone.trim();
    if (phone === "") {
      this.setState({
        error: {
          ...this.state.error,
          phone: "",
        },
      });
    } else if (!configJSON.phoneRegex.test(phone)) {
      error.phone = this.t(configJSON.phoneNumberErrorMsg);
      this.setState({
        error: error,
      });
      hasError = true;
    }
    const vatNo = this.state.vatNumber.trim();
    if (vatNo === "") {
      this.setState({
        error: {
          ...this.state.error,
          vatNumber: "",
        },
      });
    } else if (!configJSON.vatNumberRegex.test(vatNo)) {
      error.vatNumber = this.t(configJSON.vatNumberErrorMsg);
      this.setState({
        error: error,
      });
      hasError = true;
    }
    if (hasError) {
      return;
    }
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const viewId=localStorage.getItem("viewGroupId")
    const inputArray = [...this.state.selectedOptions, viewId];
    const uniqueArray = [...new Set(inputArray)];

    const body = {
      contact: {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        business_info: {
          company_name: this.state.companyName,
          business_category_id: this.state.businessCategory?.id,
          VAT_number: this.state.vatNumber,
        },
        billing_address: {
          country: this.state.billingAddressCountry,
          city: this.state.billingAddressCity,
          state: this.state.billingAddressState,
          pin_code: this.state.billingAddressPinCode,
          address1: this.state.billingAddress1,
          address2: this.state.billingAddress2,
        },
        shipping_address: {
          country: this.state.checked===true?this.state.billingAddressCountry:this.state.shippingAddressCountry,
          city:  this.state.checked===true?this.state.billingAddressCity:this.state.shippingAddressCity,
          state:  this.state.checked===true?this.state.billingAddressState:this.state.shippingAddressState,
          pin_code: this.state.checked===true?this.state.billingAddressPinCode:this.state.shippingAddressPinCode,
          address1: this.state.checked===true?this.state.billingAddress1:this.state.shippingAddress1,
          address2: this.state.checked===true?this.state.billingAddress2:this.state.shippingAddress2,
        },
        note: {
          content: this.state.notes,
        },
        contact_groups: {
          group_ids: this.props.id === "add-new-contact" ? uniqueArray : this.state.selectedOptions,
        },
        contact_products: {
          products: this.state.selectedProductsId,
        }
      },
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createContactApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createContactApiPath
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getGroupList = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = configJSON.groups;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupsApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createToastNotification = (toastMesssage: string) => {
    toast.error(toastMesssage, {
      position: toast.POSITION.TOP_CENTER,
      icon: () => (
        <CardMedia component="img" src={warningIcon} alt="emptydata" />
      ),
    });
  };

  createToastNotificationSuccess = (toastMesssage: string, toastIcon: string) => {
    toast.success(
      <Box className="toast-notification">
        <Box className="notification-txt">{toastMesssage}</Box>
        <a href="#" data-test-id="undoApi" className="toast-link"  onClick={() => {
    if (this.props.createContactUndo) {
      this.props.createContactUndo(this.state.createdContactId);
    }
  }}>
          {this.t("undo")}
        </a>
      </Box>,
      {
        position: toast.POSITION.TOP_CENTER,
        icon: () => (
          <CardMedia component="img" src={toastIcon} alt="emptydata" />
        ),
      }
    );
  };

  handleShowBussinessInfo = () => {
    this.setState({ showBussinessInfo: true });
  };

  handleShowNotes = () => {
    this.setState({ showNotes: true });
  };

  handleShowingFavoritesProduct = () => {
    this.setState({ showFavoritesProduct: true });
  };
  handleShowingFavoritesProductClose = () => {
    this.setState({ showFavoritesProduct: false });
  };

  handleShowingGroup = () => {
    this.setState({ showGroup: true });
  };

  handleAllInputChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  handleChecked = (event: any) => {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  getBusinessCategory = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBusinessCategoryApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.businessCategory
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onOpenFavProduct=()=>{
    this.setState({isFavProduct:true})
  }
  onCloseFavProduct=()=>{
    this.setState({isFavProduct:false})
  }

  handleGroupSearch = (text: any) => {
    this.setState({ searchTerm: text, isSearching: true }, () => {
      this.getSearchGroupList();
    });
  };

  multiSelectSearchChange = (event: any) => {
    this.setState({
      countrySearchVal : event.target.value,
    }, () => this.displayCountries())
   
  };

  displayCountries = () => {
    return this.state.countryList.filter((obj: any) => this.containsText(obj.country_name, this.state.countrySearchVal));
 
   }

   multiSelectSStateChange = (event: any) => {
    this.setState({
      stateSearchValue : event.target.value,
    }, () => this.displayStates())
   
  };

  shippingStateSelect = (event: any) => {
    this.setState({
      stateSearchValue : event.target.value,
    }, () => this.displayStatesShipping())
   
  };

  displayStates = () => {
    return this.state.stateList.filter((obj: any) => this.containsText(obj.state_name, this.state.stateSearchValue));
 
   }

   displayStatesShipping = () => {
    return this.state.stateListShipping.filter((obj: any) => this.containsText(obj.state_name, this.state.stateSearchValue));
 
   }


   multiSelectCityChange = (event: any) => {
    this.setState({
      citySearchValue : event.target.value,
    }, () => this.displayCities())
   
  };

  multiSelectCityShipping = (event: any) => {
    this.setState({
      citySearchValue : event.target.value,
    }, () => this.displayCitiesShipping())
   
  };

  displayCities = () => {
    return this.state.cityList.filter((obj: any) => this.containsText(obj.city_name, this.state.citySearchValue));
 
   }

   displayCitiesShipping = () => {
    return this.state.cityListShipping.filter((obj: any) => this.containsText(obj.city_name, this.state.stateSearchValue));
 
   }

   containsText = (text: string, searchText: string) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  
  saveSelectedOption = (event: any, key: string) => {
    const { value } = event.target;
    const { selectedOptions } = this.state;
    if (key === "selectedOptions") {
      this.setState({ selectedOptions: value });
    } else {
      const newSelectedOptions = selectedOptions.includes(value)
        ? selectedOptions.filter((option: any) => option !== value)
        : [...selectedOptions, value];

      this.setState({ selectedOptions: newSelectedOptions });
    }
  };

  getLabel = (value: any, def: string) => {
    const selectedItemCount = value.filter((item: any) => item !== undefined).length;
    let message;
    if (selectedItemCount === 0) {
      message = this.t("Choose Contact");
    } else {
      message = `${selectedItemCount} ${this.t("Group")}${selectedItemCount !== 1 ? 's' : ''} ${this.t("Selected")}`;
    }
    
    if (selectedItemCount >= 1 && this.state.selectedOptions) {
      return (
        <>
         {message}
        </>
      );
    }
  };

  getSearchGroupList = () => {
    const header = {
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchGroupRequestID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchApiGroup}?query=${this.state.searchTerm}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNewGroup = () => {
    this.setState({ isNewGroup: true });
  };
  handleCloseNewGroup = () => {
    this.setState({ newGroup: "", isNewGroup: false });
  };

  createGroupResponse = (response: any) => {
    if(response.errors){   
      //@ts-ignore
      this.setState({ error: { newGroup: `${this.t(response.errors[0])}` } });
    }
    if(response.data){
      this.handleCloseNewGroup()
      this.setState({newGroup:""})
      this.getGroupList()
    }
  }

  handleCreateContactResponse = (responseJson:any) => {
    if(responseJson.duplicate_email){
      this.setState({
        error:{
          ...this.state.error,
          email : responseJson.duplicate_email
        }
        
      })
      return
    }else{
      this.setState({
        createdContactId : responseJson.data.id
      })
      this.createToastNotificationSuccess(
        this.t(configJSON.contactCreateSuccessMsg), createContactIcon
      );
    }
    
    this.handleClosePopup();
    if (this.props.navigation === "/group-contacts") {
      window.location.href = this.props.navigation;
    } else {
      //@ts-ignore
      this.props.contactList();
    }
  }


  createGroup = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const body = {
      group: {
        name: this.state.newGroup,
        contact_ids: [],
      },
    };
    let endPoint = configJSON.viewGroupPath;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createGroupId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  selectedProducts = (
    productsSelected: string[],
    totalAmount: number | null,
    selectedProductsData: [],
    productsQuantity:number | null,
  ) => {
    this.setState(
      {
        selectedProductsId: productsSelected,
        selectedProductsData: selectedProductsData,
        totalAmount: totalAmount,
        productsQuantity,
       
      },
      () => {
        this.onCloseFavProduct();
      }
    );
  };

  countryListAPICallEvent = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.countryListAPICallEventId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webGetCountryList
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  stateListAPICallEventShipping = () => {
    if (
      this.state.shippingAddressCountry == "" ||
      this.state.countryList.length == 0
    ) {
      return;
    }
    let country = this.state.countryList.find(
      (x: any) => x.country_name == this.state.shippingAddressCountry
    );
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
      this.stateListAPICallEventIdShipping = requestMessage.messageId;
    

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webGetStateList + `?country_id=${country.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  stateListAPICallEvent = () => {
    if (
      this.state.billingAddressCountry == "" ||
      this.state.countryList.length == 0
    ) {
      return;
    }
    let country = this.state.countryList.find(
      (x: any) => x.country_name == this.state.billingAddressCountry
    );
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
      this.stateListAPICallEventId = requestMessage.messageId;
    
    

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webGetStateList + `?country_id=${country.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   
  };

 
  cityListAPICallEventShipping = () => {
    if (this.state.shippingAddressState === "" || this.state.stateListShipping.length === 0) {
      return;
    }
  
    let stateVal = this.state.stateListShipping.find((x: any) => x.state_name === this.state.shippingAddressState);
    if (!stateVal) {
      return;
    }
  
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
    
      this.cityListAPICallEventIdShipping = requestMessage.messageId;
     
   
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.webGetCityList}?state_id=${stateVal.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
   
  }

  cityListAPICallEvent = () => {
    if (this.state.billingAddressState === "" || this.state.stateList.length === 0) {
      return;
    }
  
    let stateVal = this.state.stateList.find((x: any) => x.state_name === this.state.billingAddressState);
    if (!stateVal) {
      return;
    }
  
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
     
      this.cityListAPICallEventId = requestMessage.messageId;
     
   
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.webGetCityList}?state_id=${stateVal.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  };

  addBCategory = (data: any, setError?: any, setShowDialog?: any) => {
    this.setErrorTitle = setError;
    this.showDialogTitle = setShowDialog;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (data.id != null) {
      this.updateBussinesCategoryRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.bussinessCategoryApiPath}/${data.id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          business_category: {
            name: data.title,
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodPUT
      );
    } else {
      this.createBussinesCategoryRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.bussinessCategoryApiPath}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          business_category: {
            name: data.title,
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodPOST
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  categoryChangeHandler = (e: any, newValue: any) => { 
    this.setState({
      businessCategory: newValue,
    });
  };
  fetchBCategoryList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBusinesCategoryRequestId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bussinessCategoryApiPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteBCategoryEvent = (data: any) => {
    this.setState({ isDeleting: true, deleteBCategoryId: data.id , deleteTitle:data.title});
  };
  deleteBCategoryFromList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteBCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.bussinessCategoryApiPath}/${this.state.deleteBCategoryId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  clearAllInputs = () => {
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      businessGroup: "",
      companyName: "",
      vatNumber: "",
      billingAddressCountry: "",
      billingAddressCity: "",
      billingAddressState: "",
      billingAddressPinCode: "",
      billingAddress1: "",
      billingAddress2: "",
      shippingAddressCountry:"",
      shippingAddressCity: "",
      shippingAddressState: "",
      shippingAddressPinCode: "",
      shippingAddress1: "",
      shippingAddress2: "",
      notes:"",
      showBussinessInfo: false,
      showNotes: false,
      isFavProduct: false,
      showGroup: false,
      selectedOptions: [],
      selectedProductsId:[],
    })
  }
  handleClosePopup = () => {
    this.clearAllInputs();
    this.props.handleCloseDialog()
  }
}
// Customizable Area End
