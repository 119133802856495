import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import i18n from "../../../web/src/utilities/i18n";
import { orderApiResponse, LiveChatEtohData, Chat } from "../../../components/src/ReusableEnums";
import { checkQueryParams } from "../../../components/src/ReusableFunctions";
type StateKeys = "neworderStatus" | "orderStatus" | "dateOrdered" | "orderType"; 
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  orderId: string;
  // Customizable Area Start
  classes: any;
  isMobile: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isProfileDrawerOpened: boolean;
  orderHistoryData: any;
  neworderStatus: number[];
  orderStatus: number;
  dateOrdered: number;
  orderType: number;
  overallOrderSearch: string;
  isViewDetails: boolean;
  viewDetailsID: any;
  isFillDetails: boolean;
  isLoading: boolean;
  sortValue: string;
  isFilter: boolean;
  orderListData:orderApiResponse[];
  currentUserId: number;
  quickChatData: Array<Chat>;
  showQuickChat:boolean,
  livechatData: Chat | null;
  // Customizable Area End
}

interface SS {
  orderId: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionData: any;
  userToken: any;
  getAllOrderRequestId: string = "";
  getAllOrdersListRequestId: string = "";
  etohSupportChatRequestId: string = "";
  getAllQuickChatRoomsRequestId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      isProfileDrawerOpened: false,
      orderHistoryData: [],
      neworderStatus: [],
      orderStatus: 0,
      dateOrdered: 0,
      orderType: 0,
      overallOrderSearch: "",
      isViewDetails: false,
      viewDetailsID: "",
      isFillDetails: false,
      isLoading: false,
      sortValue: "",
      isFilter: false,
      orderListData:[],
      currentUserId: 0,
      quickChatData: [],
      showQuickChat:true,
      livechatData: null,
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (responseJson.error) {
        return;
      } 
      
      switch (apiRequestCallId) {
        case this.getAllOrderRequestId:
          if (responseJson.message) {
            this.setState({ orderHistoryData: [], isLoading: false  });
          } else {
            this.setState({ orderHistoryData: responseJson.data ,isLoading: false  });
          }
          break;
        case this.getAllOrdersListRequestId:
          if (responseJson.message) {
            this.setState({ orderListData: [] ,isLoading: false });
          } else {
            this.setState({ orderListData: responseJson.data,isLoading: false });
          }
          break;
        case this.getAllQuickChatRoomsRequestId:
          this.handleAllChatResponse(responseJson)
          break;
        case this.etohSupportChatRequestId:
          this.handleContactSupportResponse(responseJson)
          break;
        default:
          this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    const orderId = checkQueryParams('isModal', 'true', 'id');
    if (orderId) { this.setState({ isViewDetails: true, viewDetailsID: orderId })}
    const userSessionData = sessionStorage.getItem("userData");
    const userInfo = userSessionData ? JSON.parse(userSessionData) : null;
    const currentUserId = userInfo?.data?.id ? parseInt(userInfo.data.id, 10) : 0;
    this.setState({ currentUserId });
    const lang = localStorage.getItem("lang") || "en";
    await (i18n as any).changeLanguage(lang);
    this.getAllOrdersList();
    this.getAllOrders();
    this.handleQuickChatRooms();
    if (new URLSearchParams(window.location.search).get('openPopup') === "true" && new URLSearchParams(window.location.search).get('id')) {
      this.setState({ isFillDetails: true, viewDetailsID: new URLSearchParams(window.location.search).get('id')});
    }   
  }

  t(key: any) {
    return (i18n as any).t(key, { ns: "translation" });
  }

  toggleProfileDrawer = () => {
    this.setState({
      isProfileDrawerOpened: true,
    });
  };

  closeProfileDrawer = () => {
    this.setState({
      isProfileDrawerOpened: false,
    });
  };

  handleBackBtn = () => {
    window.history.back();
  };

  goToEtohShop = () => {
    window.location.href = "/etoh-shop";
  };

  handleInputChangeEvent = (event: any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }),() => {
      this.getAllOrders();
    }
    );
  };

  handleCheckedSelection = (id: any, name: StateKeys) => {
    this.setState(
      (prevState: any) => {
        if (name === "neworderStatus") {
          const selectedItems = prevState.neworderStatus;
          return {
            ...prevState,
            neworderStatus: selectedItems.includes(id)
              ? selectedItems.filter((itemId: any) => itemId !== id)
              : [...selectedItems, id],
          };  
        }
  
        return {
          ...prevState,
          [name]: id, 
        };
      },  
      () => {
        this.getAllOrders();
      }
    );
  };

  handleOpenViewDetails = (id: string) => {
    this.setState({
      isViewDetails: true, 
      viewDetailsID: id, 
    });
  };

  handleCloseViewDetails = () => {
    this.setState({
      isViewDetails: false, 
    });
    const url = window.location.href.split('?')[0];
    window.history.replaceState({}, document.title, url);
  };
  handleOpenFillDetails = (id: string) => {
    this.setState({
      isFillDetails: true, 
      viewDetailsID: id, 
    });
  };
  handleQuickChatPopoverClose = () => {
    this.setState({ isLoading: false });
  };
  handleAllChatResponse = (response: any) => {
    this.setState({ isLoading: false , quickChatData: response.data});
  }
  handleContactSupport = (id: string) => {
    this.setState(
      (prevState) => ({
        showQuickChat: !prevState.showQuickChat,
      }),
      () => {
        this.createEtohSupportChatEvent();
      }
    );
  }
  handleContactSupportResponse = (responseJson: any) => {
    if (responseJson.errors) {
      this.handleEtohContactsupportErrorresponse(responseJson)
    } else {
      this.handleEtohContactsupportSuccessresponse(responseJson)
    }
  }
  handleEtohContactsupportErrorresponse = (responseJson: LiveChatEtohData) => {
    if (responseJson.errors) {
      const etohData = this.state.quickChatData.filter((cvl) => {
        return cvl.attributes.chat_type === 'etoh_shop_support';
      });
      
      if (etohData.length > 0) {
        this.setState({
          showQuickChat: true, 
          livechatData: etohData[0],
        });
      }
    }
  }
  handleEtohContactsupportSuccessresponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        showQuickChat: true,
        livechatData: responseJson.data,
      });
    }
  }
  handleCloseFillDetails = () => {
    this.setState({
      isFillDetails: false,
    });
    const url = window.location.href.split('?')[0];
    window.history.replaceState({}, document.title, url);
  };

  handleSortByChange = (event: any) => {
    this.setState({ sortValue: event.target.value, isFilter: true }, () => {
      this.getAllOrders();
    });
  };

  handleClearAll = () => {
    this.setState({ orderStatus: 0, dateOrdered: 0, orderType: 0 ,overallOrderSearch:"", sortValue:"", neworderStatus:[] }, () => {
      this.getAllOrders();
      this.getAllOrdersList();
    });
  };

  getAllOrders = () => {
    const { orderStatus, sortValue, dateOrdered, orderType , overallOrderSearch } = this.state;
    let sortByValue;
    let orderStatusValue;
    let dateOrderdState;
    let orderTypeValue;
    let orderStatusValues: string[] = [];

    this.state.neworderStatus.forEach(status => {
      let orderStatusValue;
      switch (status) {
        case 1:
          orderStatusValue = "Order placed";
          break;
        case 2:
          orderStatusValue = "In progress";
          break;
        case 3:
          orderStatusValue = "Shipped";
          break;
        case 4:
          orderStatusValue = "Delivered";
          break;
        case 5:
          orderStatusValue = "On hold";
          break;
        case 6:
          orderStatusValue = "Cancelled";
          break;
        default:
          orderStatusValue = "";
      }
      orderStatusValues.push(orderStatusValue);
    });
    const orderStatusValueString = orderStatusValues.join(',');    
    switch (sortValue) {
      case "Recently Ordered":
        sortByValue = "recently_ordered";
        break;
      case "Recent Status Change":
        sortByValue = "recent_status_change";
        break;
      default:
        sortByValue = "";
    }

    switch (orderStatus) {
      case 1:
        orderStatusValue = "Order placed";
        break;
      case 2:
        orderStatusValue = "In progress";
        break;
      case 3:
        orderStatusValue = "Shipped";
        break;
      case 4:
        orderStatusValue = "Delivered";
        break;
      case 5:
        orderStatusValue = "On hold";
        break;
      case 6:
        orderStatusValue = "Cancelled";
        break;
      default:
        orderStatusValue = "";
    }

    switch (orderType) {
      case 1:
        orderTypeValue = "Subscription";
        break;
      case 2:
        orderTypeValue = "One Time";
        break;
      default:
        orderTypeValue = "";
    }

    switch (dateOrdered) {
      case 1:
        dateOrderdState = "Past 30 days";
        break;
      case 2:
        dateOrderdState = "Past 3 months";
        break;
      case 3:
        dateOrderdState = "Past 6 months";
        break;
      case 4:
        dateOrderdState = "Past 1 year";
        break;
      case 5:
        dateOrderdState = "Any time";
        break;
      default:
        dateOrderdState = "";
    }
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllOrderRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ordeApiPath}?sort_by=${sortByValue}&status=${orderStatusValueString}&date_ordered=${dateOrderdState}&service_type=${orderTypeValue}&query=${overallOrderSearch}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    !overallOrderSearch && this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllOrdersList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllOrdersListRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ordeApiPath}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleQuickChatRooms = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    this.getAllQuickChatRoomsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createChatPath
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    this.setState({ isLoading: false });
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createEtohSupportChatEvent = () => {
    const formdata = new FormData();
    formdata.append("chat[chat_type]", "etoh_shop_support");
    formdata.append("chat[name]", "EtOH Support");    
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.etohSupportChatRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createChatPath
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
