// Customizable Area Start
import React from "react";
import { Typography, Slider, CardMedia, Button, Box } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import CustomInputWeb from "../../../../../components/src/CustomInput.web";
import { deleteIcon } from "../../assets";

interface SliderProps {
  classes: any;
  sliders: any;
  minimumInputChange: (
    index: number,
    event: { target: { value: string } }
  ) => void;
  maximumInputChange: (
    index: number,
    event: { target: { value: string } }
  ) => void;
  sliderChange: (index: number, newValue: any) => void;
  addAnotherSlider: (index: number) => void;
  confirmDelete: () => void;
  deleteQuestion: (index: number) => void;
  addQuestionText: (index: any, event: { target: { value: string } }) => void;
  quizDetails: any;
  editingQuiz: boolean;
  isDeletingQuestion: boolean;
  closeModal: () => void;
  t:any;
}

const QuestionComponent = (props: SliderProps) => {
  const {
    classes,
    sliders,
    minimumInputChange,
    maximumInputChange,
    sliderChange,
    addAnotherSlider,
    addQuestionText,
    confirmDelete,
    deleteQuestion,
    quizDetails,
    isDeletingQuestion,
    closeModal,
    t
  } = props;

  return (
    <Box className="quiz-question-wrapper">
      {quizDetails !== null && (
        <Box className="score-heading">
          <Typography className="heading" variant="h1">
            {quizDetails.quizTitle}
          </Typography>

          <Box component="p" className="sub-txt">
            {quizDetails.quizCaption}
          </Box>
        </Box>
      )}
      {sliders.map((slider: any, index: number) => (
        <Box key={index} className="quiz-question-box quiz-input-question">
          <Box className="quiz-question">
            <Box component="span" className="serial-no" style={{ color:"#2B2B2B" }}>
              {index + 1}.
            </Box>
            <CustomInputWeb
              type="text"
              isRequired={true}
              errors={slider.questionTextError}
              label=""
              value={slider.questionText}
              //rows={4}
              isMultiline
              onChange={(event) => addQuestionText(index, event)}
              placeholder={t("Type the next question...")}
            />

            {sliders.length > 1 ? (
              <CardMedia
                component="img"
                style={{
                  width: "16px",
                  height: "16px",
                  objectFit: "contain",
                  marginRight: "20px",
                }}
                image={deleteIcon}
                title=""
                onClick={() => deleteQuestion(index)}
                data-test-id="delete-question-image"
              />
            ) : (
              <CardMedia
                component="img"
                style={{
                  width: "16px",
                  height: "16px",
                  objectFit: "contain",
                  marginRight: "20px",
                }}
                image={deleteIcon}
                title=""
                className="delete-disabled"
              />
            )}
          </Box>
          <Box className="quiz-input">
            <CustomInputWeb
              type="number"
              isRequired={false}
              value={slider.minimumInput}
              onChange={(event) => minimumInputChange(index, event)}
              label={t("Worst")}
            />
            <CustomInputWeb
              type="number"
              isRequired={false}
              value={slider.maximumInput}
              onChange={(event) => maximumInputChange(index, event)}
              label={t("Best")}
            />
          </Box>
          <Slider
            value={slider.selectedValue}
            onChange={(event, newValue) => sliderChange(index, newValue)}
            min={slider.minimumValue}
            max={slider.maximumValue}
            step={1}
            valueLabelDisplay="auto"
            marks={slider.minimumValue < slider.maximumValue ? slider.marks : []}
            className="quiz-slider"
            disabled={true}
          />
          {isDeletingQuestion && (
            <Modal
              open={isDeletingQuestion}
              onClose={closeModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className={classes.modalBoxCenter}
              data-test-id="delete-quiz-modal"
            >
              <Box className={classes.modalreTakeQuiz}>
                <Box className="modal-heading">
                  <Typography className="modal-title" component="h2">
                    {t("Remove Question")}
                  </Typography>
                  <Link to="#" className="close-icon" onClick={closeModal}>
                    <CloseIcon style={{ fontSize: "24px" }} />
                  </Link>
                </Box>
                <Box className="modal-description">
                  <Typography className="modal-desc">
                    {t("Are you sure you want to remove the question?")}
                  </Typography>
                  <Box className="modal-footer">
                    <Button
                      className={`secondary-btn ${classes.secondaryButton}`}
                      title="Cancel"
                      data-test-id="btn-cancel-retake"
                      onClick={closeModal}
                    >
                      {t("Cancel")}
                    </Button>
                    <Button
                      className={`primary-btn ${classes.primaryButton}`}
                      title="Delete Quiz"
                      data-test-id="btn-delete-quiz"
                      onClick={confirmDelete}
                    >
                      {t("Delete")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default QuestionComponent;
// Customizable Area End
