// Customizable Area Start
import React from "react";
import { Box } from "@material-ui/core";
import { imgLogo, logoSvg } from "./assets";
import { makeStyles } from "@material-ui/core/styles";
import CustomSlider from "./CustomSlider.web";
import i18n from "../../web/src/utilities/i18n";

const useStyles = makeStyles((theme) => ({
  authLeft: {
    width: "576px",
    maxWidth: "100%",
    padding: "60px",
    backgroundColor: "#fafcfe",
    minHeight: "100vh",
    [theme.breakpoints.down("md")]: {
      width: "384px",
      padding: "60px 32px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "25px",
      minHeight: "unset",
      display: "none",
    },
    "& .auth-logo": {
      maxWidth: "162px",
      width: "100%",
      "& img": {
        maxWidth: "100%",
      },
    },
    "& .auth-inner": {
      paddingTop: "127px",
      [theme.breakpoints.down("md")]: {
        paddingTop: "60px",
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: "72px",
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: "41px",
      },
      "& .auth-left-row": {
        display: "flex",
        alignItems: "flex-end",
        flexWrap: "wrap",
        margin: "0 -10px",
        "& .col": {
          padding: "0 10px",
        },
        "& .col-left": {
          flex: "0 0 54%",
          maxWidth: "54%",
        },
        "& .col-right": {
          flex: "0 0 46%",
          maxWidth: "46%",
          paddingRight: "206px",
        },
      },
    },
    "& .auth-desc": {
      fontSize: "24px",
      lineHeight: "28px",
      color: "#000000",
      fontWeight: "400",
      marginBottom: "19px",
      fontFamily: "Expletus Sans",
    },
    "& .auth-name": {
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0",
      color: "#94a3b8",
      fontWeight: "400",
      fontFamily: "Expletus Sans",
    },
    "& .auth-img": {
      margin: "61px auto 0",
      maxWidth: "380px",
      height: "630px",
      width: "100%",
      position: "relative",
      "& img": {
        height: "100%",
        width: "100%",
        objectFit: "cover",
        objectPosition: "top",
        position: "relative",
        bottom: "-50px",
      },
    },
  },
  carouselWrapper: {
    maxWidth: "456px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
    },
    "& .slider-bottom": {
      marginRight: "80px",
    },
    "& .slider-quotes": {
      fontSize: "24px",
      lineHeight: "32px",
      color: "rgba(0,0,0,0.87)",
      fontFamily: "Expletus Sans",
      fontWeight: "400",
      marginBottom: "20px",
      minHeight: '80px',
      height: '125px',
      maxHeight: '125px',
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
        lineHeight: "24px",
        marginBottom: "10px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
        lineHeight: "20px",
      },
    },
    "& .slider-author": {
      fontSize: "14px",
      lineHeight: "23px",
      letterSpacing: "0.25px",
      color: "#94a3b8",
      fontFamily: "Roboto",
      fontWeight: 400,
      marginBottom: "24px",
      [theme.breakpoints.down("md")]: {
        marginBottom: "15px",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "10px",
      },
    },
    "& .slider-img": {
      marginBottom: "48px",
      height: "430px",
      display: "flex",
      alignItems: "flex-end",
      [theme.breakpoints.down("md")]: {
        height: "320px",
        marginBottom: "25px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "25px",
      },

      "& img": {
        maxHeight: "100%",
        objectFit: "contain",
      },
    },
    "& .slick-slide": {
      height: "inherit",
    },
    "& .slick-prev, & .slick-next": {
      bottom: "4px",
      top: "unset",
      transform: "unset",
      background: "transparent",
      color: "#2b2b2b",
      zIndex: 10,
      "&:before": {
        display: "none",
      },
      "&:hover, &:focus": {
        color: "#2b2b2b",
      },
    },
    "& .slick-prev": {
      left: "auto",
      right: 46,
    },
    "& .slick-next": {
      right: 0,
      transform: "rotateY(180deg)",
    },
    "& .slick-disabled ": {
      color: "#94a3b8",
    },
    "& .slick-disabled:hover, .slick-disabled:focus": {
      color: "#94a3b8 !important",
    },
    "& .start-icon": {
      lineHeight: 0,
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
        fontSize: "20px",
        marginRight: "4px",
      },
    },
  },
}));
function AuthLeft() {
  const classes = useStyles();
  const t = (key:any, variables?: Record<string, any>) => {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  return (
      <Box className={classes.authLeft}>
        <Box className="auth-logo">
          <img src={imgLogo} alt="logo" />
        </Box>
        <Box className="auth-inner">
          <Box className={classes.carouselWrapper}>
            <CustomSlider t={t}/>
          </Box>
        </Box>
      </Box>
  );
}

export default AuthLeft;
// Customizable Area End
