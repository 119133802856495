// Customizable Area Start
import React from "react";
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "6px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#f2f8fc",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      maxWidth: "100%",
      minWidth: "170px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    secondaryButton: {
      background: "transparent",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      minWidth: "198px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    modalDialog: {
      "& .MuiBackdrop-root, & .backdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502) !important",
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "rgba(34, 89, 128, 0.7) !important",
        },
        backdropFilter: "blur(3px)",
      },
    },
    rightModalDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
      [theme.breakpoints.down("md")]: {
        width: 548,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        maxWidth: "100%",
        top: "197px",
        borderRadius: "24px 24px 0 0",
        height: "auto",
        maxHeight: "100%",
      },
      "& .info-group": {
        padding: "16px 0px",
        width: "100%",
        "& .form-row": {
          display: "flex",
          flexWrap: "wrap",
          margin: "0 -8px",
          "&:last-child": {
            "& .form-control": {
              marginBottom: "0",
            },
          },
          "& .form-col": {
            maxWidth: "100%",
            flex: "0 0 100%",
            padding: "0 8px",
            "&.col6": {
              maxWidth: "50%",
              flex: "0 0 50%",
            },
            "& .form-control": {
              marginBottom: "16px",
            },
          },
        },
        "& .select-outer": {
          width: "100%",
          // marginTop:'-1.3vw',
          marginBottom: "16px",
          "& .error-select": {
            color: "#C7263E",
          },
          "& svg": {
            fill: "#94A3B8",
          },
          "& .Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#C7263E",
            },
          },
          "& .MuiInputLabel-formControl": {
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontWeight: 500,
            letterSpacing: "0.1px",
            lineHeight: "19px",
            color: "#94A3B8",
            width: "calc(100% - 46px)",
            display: "flex",
            "&.MuiFormLabel-filled": {
              width: "auto",
              fontSize: "12px",
              color: "rgba(43,43,43,0.87)",
              lineHeight: "16px",
              fontFamily: "Roboto",
              letterSpacing: "0.0075em",
              fontWeight: "400",
            },
          },
          "& .MuiSelect-select": {
            letterSpacing: "0",
            fontSize: "14px",
            color: "#000000",
            fontWeight: "500",
            opacity: "0.671",
            fontFamily: "Expletus Sans",
            justifyContent: "space-between",
            display: "flex",
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            },
          },
          "& .MuiFormLabel-root": {
            "&.Mui-focused": {
              color: "rgba(43,43,43,0.87)",
              fontSize: "12px",
              width: "auto",
              letterSpacing: "0.0075em",
              lineHeight: "16px",
              fontWeight: "400",
              fontFamily: "Roboto",
            },
            "& .MuiInputLabel-asterisk": {
              lineHeight: "23px",
              marginLeft: "auto",
              color: "#e86577",
              fontSize: "18px",
            },
          },
        },
        "& .group-title": {
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "19px",
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          marginBottom: "16px",
          letterSpacing: "0",
        },
        "& .shipping-address-chkbox": {
          color: "#4BA3B7",
        },
        "& .shipping-address-txt": {
          marginBottom: "16px",
        },
        "& .group-sub-title": {
          color: "var(--text-secondary, #94A3B8)",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "26px",
          letterSpacing: "0.105px",
          marginBottom: "16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        "& .group-caption": {
          color: "var(--text-secondary, #94A3B8)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.09px",
        },
      },
      "& .switch-wrapper": {
        marginBottom: "12px",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "30px",
        },
        "&:last-child": {
          marginBottom: "0",
        },
        "& .MuiFormControlLabel-root": {
          width: "100%",
          justifyContent: "space-between",
          marginLeft: "0",
          opacity: "1",
          color: "#2b2b2b",
        },
      },
      "&.filter-dialogbox": {
        "& .modal-heading": {
          marginBottom: "42px",
          "& .filter-heading": {
            display: "flex",
            alignItems: "center",
            "& .auth-back": {
              cursor: "pointer",
            },
            "& .modal-title ": {
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "19px",
              fontFamily: "Expletus Sans",
              marginLeft: "14px",
              color: "#2B2B2B",
            },
          },
          "& .right-block": {
            display: "flex",
            alignItems: "center",
            gap: "24px",
          },
          "& .filter-link": {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "19px",
            color: "#4BA3B7",
            fontFamily: "Expletus Sans",
            textDecoration: "none",
          },
        },
        "& .heading-block": {
          marginBottom: "46px",
          "& .primary-txt": {
            fontSize: "24px",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Expletus Sans",
            lineHeight: "32px",
            fontWeight: 400,
            marginBottom: "6px",
          },
          "& .sub-txt": {
            fontSize: "14px",
            color: "#94A3B8",
            lineHeight: "26px",
            marginBottom: "0",
            letterSpacing: "0.105px",
          },
        },
        "& .modal-form": {
          marginTop: "0",
          "& .form-info-wrapper": {
            marginBottom: "30px",
            "&:last-child": {
              marginBottom: "0",
            },
            "& .form-heading": {
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              marginBottom: "16px",
              letterSpacing: "0",
            },
            "& .form-control": {
              "& .MuiInputBase-input": {
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-root": {
                "&.MuiOutlinedInput-adornedEnd": {
                  paddingRight: "24px",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  width: "20px",
                  height: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                padding: "20px 24px",
                "& .MuiOutlinedInput-inputAdornedEnd": {
                  paddingRight: "0",
                },
              },
            },
          },
          "& .switch-wrapper": {
            marginBottom: "12px",
            [theme.breakpoints.down("xs")]: {
              marginBottom: "30px",
            },
            "&:last-child": {
              marginBottom: "0",
            },
            "& .MuiFormControlLabel-root": {
              width: "100%",
              justifyContent: "space-between",
              marginLeft: "0",
              opacity: "1",
              color: "#2b2b2b",
            },
          },
        },
        "& .modal-description": {
          marginBottom: "44px",
        },
        "& .modal-footer": {
          "&.full-btn": {
            "& .MuiButton-root": {
              width: "100%",
            },
          },
        },
      },
      "&.favourite-product-dialog": {
        "& .modal-dialoginner": {
          flexDirection: "unset",
          flexWrap: "wrap",
          alignItems: "flex-start",
        },
        "& .modal-heading": {
          width: "100%",
          "& .close-icon": {
            marginLeft: "auto",
          },
        },
        "& .modal-description": {
          marginBottom: "0",
          width: "100%",
        },
        "& .button-wrapper": {
          marginTop: "0",
        },
        "& .modal-footer": {
          marginTop: "auto",
          "& .MuiButton-root": {
            "&:last-child": {
              marginBottom: "0",
            },
          },
          "& .secondary-btn": {
            [theme.breakpoints.down("xs")]: {
              borderColor: "#4BA3B7",
              color: "#4BA3B7",
            },
          },
        },
      },
      "& .modal-dialoginner": {
        padding: "82px 60px",
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        [theme.breakpoints.down("md")]: {
          padding: "45px 60px 29px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "30px 24px",
        },
        "& .MuiCheckbox-root": {
          color: "#94A3B8",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
        "& .MuiRadio-root": {
          color: "#94A3B8",
          padding: "0",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "6px",
      },
      "& .modal-title": {
        fontSize: "24px",
        lineHeight: "32px",
        color: "rgba(43, 43, 43, 0.87)",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
      },
      "& .modal-description": {
        marginBottom: "20px",
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "26px",
        color: "#2B2B2B",
        letterSpacing: "0.0075em",
        fontWeight: 400,
        fontFamily: "Roboto",
        marginTop: "0",
        marginBottom: "48px",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "16px",
        },
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#2B2B2B",
        fontWeight: "500",
        "& .modal-link": {
          marginLeft: "3px",
        },
      },
      "& .button-wrapper": {
        marginTop: "auto",
        "&.full-btn": {
          "& .MuiButton-root": {
            width: "100%",
          },
        },
      },
      "& .select-control": {
        "&.multi-select": {
          "&.outline-control": {
            width: "100%",
            marginTop: "0",
            "& .multiselect-label": {
              display: "block",
              position: "static",
              transform: "none",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              marginBottom: "16px",
              letterSpacing: "0",
            },
            "& .MuiSelect-select": {
              fontFamily: "Roboto",
              fontWeight: 400,
              color: "#2B2B2B",
              padding: "18px 40px 18px 24px",
              lineHeight: "20px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
              },
            },
            "& .multiple-clear": {
              position: "absolute",
              fontSize: "15px",
              zIndex: 1,
              color: "#94A3B8",
              top: "calc(50% + 17px)",
              right: "27px",
              transform: "translateY(-50%)",
            },
          },
          "& .multiselect-label": {
            display: "none",
          },
          "& .MuiInput-formControl": {
            marginTop: "0",
          },
          "& .MuiSelect-select": {
            paddingRight: "40px",
            display: "flex",
            alignItems: "center",
            "& .select-icon": {
              marginRight: "6px",
            },
          },
          "& .multiple-clear": {
            position: "absolute",
            fontSize: "15px",
            right: "18px",
            top: "3px",
            zIndex: 1,
            color: "#94A3B8",
          },
        },
        "&.status-control": {
          borderRadius: "12px",
          background: "rgba(18, 174, 241, 0.07)",
          padding: "6px 12px",
          "& .down-arrow": {
            width: "16px",
            height: "16px",
          },
          "& .MuiSelect-select": {
            color: "#12AEF1",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.09px",
            opacity: "1",
            paddingRight: "5px",
          },
        },
        "& .select-icon": {
          position: "relative",
          marginRight: "6px",
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          color: "rgba(43, 43, 43, 0.87)",
          opacity: "0.671",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          paddingBottom: "0",
          paddingTop: "0",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .clear-icon": {
            width: "50px",
            height: "50px",
            background: "red",
          },
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
      },
      "& .modal-footer": {
        marginTop: "auto",
        display: "flex",
        justifyContent: "space-between",
        "& .half-btn": {
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          "& .MuiButton-root": {
            width: "auto",
            marginBottom: "0",
            marginRight: "10px",
            "&:last-child": {
              marginRight: "0",
            },
            "&.primary-btn": {
              padding: "12px 38px",
            },
            "&.secondary-btn": {
              minWidth: "140px",
            },
          },
        },
        "& .MuiButton-root": {
          marginRight: "24px",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
      "& .primary-btn": {
        minHeight: "56px",
        fontSize: "14px",
        lineHeight: "19px",
        minWidth: "156px",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px",
        },
      },
      "& .secondary-btn": {
        minHeight: "48px",
        fontSize: "14px",
        lineHeight: "19px",
        minWidth: "156px",
      },
      "& .template-list": {
        padding: "0",
        "& .MuiListSubheader-gutters": {
          paddingLeft: "0",
          paddingRight: "0",
          position: "static",
          "& .form-control": {
            marginBottom: "0",
            "& .MuiInputAdornment-root": {
              "& .MuiSvgIcon-root": {
                fill: "#225980",
                fontSize: "20px",
              },
            },
            "& .MuiOutlinedInput-adornedStart": {
              paddingLeft: "16px",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              minHeight: "56px",
            },
            "& .MuiOutlinedInput-input": {
              fontSize: "13px",
              lineHeight: "17px",
              letterSpacing: "0",
              padding: "17px 16px 16px",
              "&::-webkit-input-placeholder": {
                color: "#94A3B8",
              },
              "&:-ms-input-placeholder": {
                color: "#94A3B8",
              },
              "&::placeholder": {
                color: "#94A3B8",
              },
              "&.MuiOutlinedInput-inputAdornedStart": {
                paddingLeft: "7px",
              },
            },
          },
        },
        "& .multiselect-membres": {
          display: "flex",
          alignItems: "center",
          width: "auto",
          maxWidth: "unset",
          justifyContent: "space-between",
          padding: "16px 0",
          margin: "0",
          borderBottom: "1px solid #ECF1F4",
          [theme.breakpoints.down("xs")]: {
            padding: "15px 0",
            margin: "0",
          },
          "& .total-member": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "19px",
            opacity: "0.67",
          },
          "& .select-control": {
            opacity: "0.67",
            "& .MuiSelect-select": {
              color: "#2b2b2b",
              opacity: "1",
              fontSize: "12px",
              letterSpacing: "0",
              paddingRight: "26px",
            },
            "& .down-arrow": {
              color: "rgba(43, 43, 43, 0.67)",
            },
            "& .MuiSelect-icon": {
              top: "calc(50% - 11px)",
              color: "#2b2b2b",
              fontSize: "20px",
            },
          },
        },
        "& .template-li": {
          display: "flex",
          justifyContent: "space-between",
          background: "transparent",
          position: "relative",
          padding: "21px 0",
          borderBottom: "1px solid #ECF1F4",
          cursor: "pointer",
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
          },
          "& .template-left": {
            "& .name": {
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
              marginBottom: "4px",
            },
            "& .sub-txt": {
              fontSize: "12px",
              lineHeight: "16px",
              color: "#94A3B8",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
              letterSpacing: "0",
              marginBottom: "0",
            },
          },
          "& .template-icon": {
            minWidth: "unset",
            marginLeft: "10px",
            "& .MuiCheckbox-root": {
              padding: "0",
            },
            "& .MuiIconButton-colorSecondary": {
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          },
        },
      },
      "& .tasklist-member": {
        padding: "0",
        "& .tasklist-li": {
          display: "flex",
          background: "transparent",
          justifyContent: "space-between",
          position: "relative",
          padding: "26px 24px 18px",
          borderBottom: "1px solid #ECF1F4",
          "& .tasklist-left": {
            display: "flex",
            alignItems: "center",
            "& .assignee-info": {
              marginLeft: "12px",
            },
            "& .assignee-profile": {
              display: "flex",
              width: "38px",
              height: "38px",
              background: "#ECF1F4",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Roboto",
              borderRadius: "100%",
              color: "rgba(43, 43, 43, 0.87)",
              fontSize: "15.485px",
              fontWeight: 600,
              letterSpacing: "-0.395px",
              lineHeight: "21.679px",
            },
            "& .assignee-name": {
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
            },
            "& .assignee-email": {
              color: "#94A3B8",
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: 500,
              fontFamily: "Expletus Sans",
            },
          },
          "& .tasklist-icon": {
            minWidth: "unset",
          },
        },
      },
      "& .assignee-multiSelect-list": {
        padding: "0",
        marginBottom: "15px",
        "&.selected-list": {
          "& .MuiListItem-root": {
            background: "#F2F8FC",
          },
        },
        "& .search-filter": {
          display: "flex",
          alignItems: "center",
          gap: "24px",
          paddingBottom: "22px",
          borderBottom: "1px solid #ECF1F4",
          [theme.breakpoints.down("xs")]: {
            gap: "16px",
            paddingBottom: "0",
            borderBottom: "0",
          },
          "& .form-control": {
            width: "100%",
            marginBottom: "0",
            "& .MuiInputAdornment-root": {
              "& .MuiSvgIcon-root": {
                fill: "rgba(0,0,0,0.67)",
              },
            },
            "& .MuiOutlinedInput-root": {
              [theme.breakpoints.down("xs")]: {
                borderRadius: "12px",
              },
            },
          },
          "& .filter-group": {
            width: "56px",
            height: "56px",
            minWidth: "56px",
            [theme.breakpoints.down("xs")]: {
              width: "48px",
              height: "48px",
              minWidth: "48px",
            },
            "& img": {
              width: "100%",
              height: "100%",
            },
          },
        },
        "& .MuiListSubheader-gutters": {
          position: "static",
          paddingRight: "0",
          paddingLeft: "0",
          [theme.breakpoints.down("xs")]: {
            paddingLeft: "24px",
            paddingRight: "24px",
            margin: "0 -24px",
          },
          "& .form-control": {
            marginBottom: "12px",
            "& .MuiOutlinedInput-root": {
              minHeight: "56px",
              [theme.breakpoints.down("xs")]: {
                minHeight: "48px",
              },
            },
            "& .MuiOutlinedInput-input": {
              padding: "16px",
              lineHeight: "17px",
              letterSpacing: "0",
              fontSize: "13px",
              "&.MuiOutlinedInput-inputAdornedStart": {
                paddingLeft: "0",
              },
            },
            "& .MuiInputAdornment-positionStart": {
              marginRight: "16px",
            },
            "& .MuiInputAdornment-root": {
              "& .MuiSvgIcon-root": {
                fontSize: "20px",
                fill: "#225980",
              },
            },
          },
          "&.multiselect-subheader": {
            color: "#2B2B2B",
            marginBottom: "0",
            borderBottom: "1px solid #ECF1F4",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "14px",
            fontFamily: "Expletus Sans",
            fontWeight: "500",
            lineHeight: "19px",
            display: "flex",
            padding: "19px 24px",
            [theme.breakpoints.down("xs")]: {
              margin: "0 -24px",
              width: "auto",
            },
            "& .tasklist-link": {
              textDecoration: "none",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "19px",
              whiteSpace: "nowrap",
              marginLeft: "10px",
              color: "#C7263E",
              fontFamily: "Expletus Sans",
            },
          },
          "&.multiselect-subtxt": {
            borderBottom: "1px solid #ECF1F4",
            textTransform: "uppercase",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "normal",
            fontFamily: "Roboto",
            padding: "10px 20px",
            color: "#94A3B8",
            [theme.breakpoints.down("xs")]: {
              margin: "0 -24px",
              width: "auto",
            },
          },
        },
        "& .multiselect-membres": {
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          padding: "16px 20px",
          borderBottom: "1px solid #ECF1F4",
          margin: "0",
          [theme.breakpoints.down("xs")]: {
            width: "auto",
            margin: "0 -24px",
          },
          "& .total-member": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "0.1px",
          },
          "& .select-control": {
            "&.sortby-control": {
              "& .MuiSelect-select": {
                paddingRight: "26px",
              },
              "& .MuiSelect-icon": {
                top: "calc(50% - 11px)",
                color: "#2b2b2b",
                fontSize: "20px",
              },
            },
            "& .MuiSelect-select": {
              color: "#2b2b2b",
              opacity: "1",
              fontSize: "14px",
              letterSpacing: "0",
            },
          },
        },
        "& .MuiListItem-root": {
          borderBottom: "1px solid #ECF1F4",
          cursor: "pointer",
          padding: "8px 20px 6px",
          minHeight: "80px",
          [theme.breakpoints.down("xs")]: {
            margin: "0 -24px",
            padding: "8px 24px 6px",
            width: "auto",
          },
        },
        "& .assignee-detail": {
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          display: "flex",
          "& .assignee-left": {
            display: "flex",
            flex: "1",
            alignItems: "center",
            "& .assignee-info": {
              marginLeft: "13px",
            },
            "& .assignee-email": {
              fontWeight: 500,
              lineHeight: "16px",
              fontFamily: "Expletus Sans",
              color: "#94A3B8",
              fontSize: "12px",
            },
            "& .assignee-name": {
              marginBottom: "2px",
              lineHeight: "19px",
              fontWeight: 500,
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
            },
            "& .assignee-profile": {
              letterSpacing: "-0.395px",
              fontWeight: 600,
              display: "flex",
              color: "rgba(43, 43, 43, 0.87)",
              justifyContent: "center",
              background: "#ECF1F4",
              alignItems: "center",
              borderRadius: "100%",
              fontFamily: "Roboto",
              height: "48px",
              fontSize: "15.485px",
              lineHeight: "21.679px",
              minWidth: "48px",
            },
            "& .chip-wrapper": {
              marginTop: "3px",
              "& .MuiChip-root": {
                height: "24px",
                marginBottom: "2px",
              },
            },
          },
          "& .assignee-right": {
            marginLeft: "10px",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            "& .hours-details": {
              color: "#000",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 600,
              lineHeight: "16px",
              letterSpacing: "0.09px",
              marginRight: "16px",
              textAlign: "right",
              [theme.breakpoints.down("md")]: {
                marginRight: "8px",
              },
              "& .gray-txt": {
                color: "#94A3B8",
                fontWeight: 400,
                marginTop: "4px",
              },
            },
            "& .assinee-selected": {
              width: "20px",
              height: "20px",
              "& .normal-img": {
                display: "none",
              },
              "& .selected-img": {
                display: "block",
              },
            },
            "& .assinee-unselected": {
              width: "20px",
              height: "20px",
              "& .normal-img": {
                display: "block",
              },
              "& .selected-img": {
                display: "none",
              },
            },
          },
        },
      },
      "& .favourite-product-wrapper": {
        textAlign: "center",
        "& .project-img": {
          marginBottom: "40px",
          [theme.breakpoints.down("xs")]: {
            maxWidth: "289px",
            margin: "0 auto 32px",
          },
        },
      },
    },
    headerSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "28px 0 0px 20px",
      [theme.breakpoints.down("xs")]: {
        padding: "24px",
      },
      "&.fixed-header": {
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        backgroundColor: "#FFF", 
      },
      "& .backStyle": {
        display: "flex",
        alignItems: "center",
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Expletus Sans",
        cursor: "pointer",
        letterSpacing: "0.15px",
        color: "rgba(43, 43, 43, 0.87)",
        zIndex:"1111",
      },
      "& .backIcon": {
        marginRight: "14px",
      },
    },
    schedulingTabwrapper: {
      marginTop: "-35px",
      "& .main-container": {
        padding: "10% 0",
        width: "896px",
        margin: "0 auto",
        "& .container": {
          display: "flex",
          justifyContent: " space-between",
        },
        "& .days": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "50px 0",
          padding: "20px",
          border: "1px solid #E8ECF2",
          borderRadius: "12px",
          "& .day": {
            borderRight: "1px solid #E8ECF2",
            padding: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            "&:last-child": {
              borderRight: "none",
            },
          },
        },
        "& .button-wrapper": {
          display: "flex",
          justifyContent: "space-between",
          "& .secondary-btn": {
            minWidth: "156px",
          },
          "& .primary-btn": {
            minWidth: "156px",
          },
          "& .save-disabled": {
            display: "flex",
            minHeight: "48px",
            minWidth: "156px",
            padding: "8px 24px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "6px",
            background: "#92C7D3",
            color: "#FAFCFE",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            marginBottom: "24px",
            textTransform: "none",
            textDecoration: "none",
            width: "156px",
          },
        },
        "& .dropdown-menu-item-column": {
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          alignSelf: "stretch",
        },
        "& .MuiSvgIcon-root": {
          color: "#E8ECF2",
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
        "& .dropdown-menu-item-text": {
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
        },
        "& .title": {
          color: "#000",
          fontFamily: "Expletus Sans",
          fontSize: "24px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0.09px",
          marginBottom: "16px",
        },
        "& .sub-title": {
          color: "#505050",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0.09px",
        },
      },
      "& .MuiTabs-root": {
        marginBottom: "24px",
        borderBottom: "1px solid #E8ECF2",
        "& .MuiTabs-flexContainer": {
          padding: "0 24px",
          justifyContent: "center",
        },
        "& .MuiTab-root": {
          fontSize: "14px",
          color: "#2B2B2B",
          fontWeight: 500,
          lineHeight: "20px",
          fontFamily: "Expletus Sans",
          textTransform: "capitalize",
          padding: "16px 24px",
          opacity: "0.67",
          letterSpacing: "0",
          minWidth: "unset",
          "&.Mui-selected": {
            opacity: "1",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        },
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "#4BA3B7",
      },
      "& .tab-heading": {
        justifyContent: "space-between",
        display: "flex",
        marginBottom: "24px",
        alignItems: "center",
        "& .heading-right": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "& .heading-link": {
            marginRight: "24px",
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
        "& .primary-txt": {
          color: "#2B2B2B",
          fontWeight: 500,
          fontSize: "16px",
          letterSpacing: "0",
          lineHeight: "21px",
          fontFamily: "Expletus Sans",
          marginBottom: "5px",
        },
        "& .sub-txt": {
          color: "#94A3B8",
          fontWeight: 400,
          fontSize: "13px",
          lineHeight: "17px",
          fontFamily: "Roboto",
          letterSpacing: "0",
          marginBottom: "0",
        },
      },
    },
    schedulingCalenderWrapper: {
      display: "flex",
      height: "100vh",
      padding: "20px",
      marginLeft:"100px",
      marginRight: "100px",
      "& .time-offtext-content": {
        marginBottom: "30px",
        borderBottom: "1px solid #e8ecf2",
        paddingBottom: "20px",
      },
      "& .border-content": {
        width: "25%",
        borderRight: "1px solid #e8ecf2",
        padding: "10px 0 10px 10px",
      },
      "& .title": {
        color: "#000",
        fontFamily: "Expletus Sans",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: "0.09px",
        marginBottom: "16px",
      },
      "& .sub-title": {
        color: "#505050",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: "0.09px",
      },
      "& .calender": {
        flex: 1,
        "& .hover-box":{
          opacity: "0",
          visibility: "hidden", 
        },
        "& .rbc-event-content":{
          position: "relative",
          overflow: "visible !important",
          "& .event-label": {
            overflow: "visible !important",
          },
          "&:hover":{
          "& .hover-box":{
            opacity: "1",
            visibility: "visible",
            position: "absolute",
            top: "100%",
            left: "50%",
            transform:" translateX(-50%)",
            width: "488px",
            padding: "16px",
            //background: "red",
            border: "1px solid #ddd",
            borderRadius: "8px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 10,
            textAlign: "center",
            //height: "400px",
            "& .MuiTypography-body1":{
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0.09px",
            },
          },
        },
        },
        "& .event-title": {
          color: "#2B2B2B",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0.09px",
        },
        "& .rbc-event":{
          backgroundColor:"#ECF1F4",
          textAlign:"center",
        },
      },     
      "& .secondary-btn": {
        color: "#4BA3B7",
        fontFamily: "Expletus Sans",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "16px",
        letterSpacing: "0.09px",
        minWidth: "94px",
      },
      "& .primary-btn": {
        minWidth: "124px",
      },
      "& .month-txt": {
        color: "#2B2B2B",
        fontFamily: "Expletus Sans",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: "0.09px",
      },
      "& .topbar-container": {
        display: "flex",
        alignItems: "center",
        padding: "5px 0",
        justifyContent: "space-between",
        borderBottom: "1px solid #e8ecf2",
        marginBottom: "30px",
      },
      "& .today-content": {
        display: "flex",
        alignItems: "center",
        padding: "10px 0",
        "& .today-container": {
          display: "flex",
          alignItems: "center",
          gap: "10px",
        },
        "& .prev-btn": {
          marginRight: "8px",
        },
      },
      calendarHeader: {
        textTransform: "uppercase",
        textAlign: "center",
      },
      calendarDate: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: "1rem",
      },
      todayDate: {
        borderRadius: "50%",
        backgroundColor: "#1976d2",
        color: "#fff",
        width: "35px",
        height: "35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
      },
    },
    weekendDate: {
      //backgroundColor: "#f0f0f0",
      color: "#94A3B8",
      fontSize: "18px",
        fontFamily: 'Expletus Sans !important',
        fontWeight: 500,
      "& .rbc-button-link": {
        // fontSize: "18px",
        // fontFamily: 'Expletus Sans !important',
        // fontWeight: 500,
        color: "#94A3B8",
      },
    },
    calendarContainer: {
      "& .rbc-month-view": {
        border: "none",
      },
      "& .rbc-row-bg":{
        "& .rbc-day-bg":{
          "&.weekend-new":{
           // background: "red",
           position: "relative",
           overflow: "hidden",
           color: "red !important",
            "&::after": {
                  content: '""',
                  position: "absolute",
                  top: "10px",
                  left: "42%",
                  width: "22px", 
                  height: "2px",
                  backgroundColor: "#94A3B8",
                  transform: "rotate(-45deg)",
                  transformOrigin: "center",
                },
          },
        },
      },
      "& .rbc-row-content": {
        "& .rbc-row": {
          "& .rbc-date-cell": {
            "&:nth-child(6), &:nth-child(7)": {
              "& .rbc-button-link": {
                position: "relative",
                overflow: "hidden",
                fontSize: "18px",
                fontFamily: 'Expletus Sans !important',
                fontWeight: 500,
                color: "#94A3B8",
              },
            },
          },
        },
      },
     
      "& .rbc-date-cell": {
        border: "none",
        textAlign: "center",
        paddingRight: "0",
        "& .rbc-button-link":{
          fontSize: "18px",
          fontFamily: 'Expletus Sans !important',
          fontWeight: 500,
          color: "#2B2B2B",
        },
        "&.rbc-off-range":{
          "& .rbc-button-link":{
            color: "#94A3B8",
          },
        },
        "&.rbc-now":{
          "& .rbc-button-link":{
                width: "40px",
               height: "40px",
              backgroundColor: "#4dabf7",
              color: "white",
              fontSize: "16px",
              borderRadius: "50%",
          },
        },
      },
      "& .rbc-day-bg": {
        border: "none",
      },
      "& .rbc-header": {
        border: "none",
        color: "#2B2B2B",
        fontFamily: "Expletus Sans",
        fontSize: "18px",
        fontWeight: 500,
      },
      "& .rbc-month-header": {
        marginBottom: "30px",
      },
      "& .rbc-off-range-bg": {
        background: "none",
      },
      "& .rbc-off-range": {
        color: "#94A3B8",
      },
      "& .rbc-today": {
        background: "none",
      },
      "& .rbc-month-row": {
        border: "none",
      },
      "& .select-control": {
        margin: "0 12px",
        "&.multi-select": {
          "& .MuiInput-formControl": {
            marginTop: "0",
          },
          "& .multiselect-label": {
            display: "none",
          },
          "& .MuiSelect-select": {
            paddingRight: "40px",
          },
          "& .multiple-clear": {
            fontSize: "15px",
            position: "absolute",
            top: "3px",
            right: "18px",
            zIndex: 1,
            color: "#94A3B8",
          },
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          color: "#2b2b2b",
          letterSpacing: "0.1px",
          opacity: "0.671",
          fontFamily: "Expletus Sans",
          fontWeight: "500",
          paddingTop: "0",
          paddingBottom: "0",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .clear-icon": {
            height: "50px",
            width: "50px",
            background: "red",
          },
          "& .MuiBox-root": {
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& .select-icon": {
              marginRight: "6px",
            },
          },
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
      },
      "& .heading-time-off": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: "20px",
        marginBottom: "20px",
        borderBottom: "1px solid #e8ecf2",
        "& .days-title": {
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontWeight: 500,
        },
      },
      "& .heading-time-off2": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: "20px",
        marginBottom: "20px",
        borderBottom: "1px solid #e8ecf2",
        paddingRight: "20px",
        "& .days-sub-title": {
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
        },
      },
      "& .dates-container": {
        borderBottom: "1px solid #e8ecf2",
        marginTop: "20px",
        "& .heading-time-off3": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
          paddingRight: "20px",
          "& .days-sub-title": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "18px",
            fontWeight: 600,
          },
        },
        "& .dates": {
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          paddingRight: "20px",
          marginBottom: "20px",
          "& .txt": {
            color: "#94A3B8",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: 400,
          },
        },
      },
    },
    dropdownStyle: {
      marginTop: "16px",
      maxWidth: "135px",
      width: "100%",
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "24px",
            paddingRight: "24px",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          color: "#2B2B2B",
          padding: "14px 24px",
          "& .MuiListItemText-root": {
            margin: "0",
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
          },
          "& .multiselect-check": {
            display: "none",
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        whiteSpace: "normal",
        "& .MuiTypography-root": {
          font: "inherit",
          letterSpacing: "0",
        },
      },
      "& .MuiList-padding": {
        paddingTop: "4px",
        paddingBottom: "8px",
      },
    },
    modalFilter: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 580,
      backgroundColor: "#ffffff",
      padding: "0",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      "& .date-picker": {
        display: " flex",
        alignContent: " center",
        justifyContent: "center",
        padding: "40px",
        "& .calender-header":{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.5rem",
        },
        "& .txt": {
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          fontSize: "24px",
          fontWeight: 400,
        },
        "& .react-datepicker": {
          backgroundColor: "#fffff",
          border: "none",
        },
        "& .react-datepicker__day--selected": {
          backgroundColor: "#4BA3B7",
          color: "#FFFFFF",
          borderRadius: "50%",
        },
        "& .react-datepicker__day--keyboard-selected": {
          backgroundColor: "#4BA3B7",
          color: "#FFFFFF",
          borderRadius: "50%",
        },
        "& .react-datepicker__day--in-range": {
          backgroundColor: "#4BA3B7",
          color: "#FFFFFF",
          borderRadius: "50%",
        },
        "& .react-datepicker__day--in-selecting-range": {
          backgroundColor: "#4BA3B7",
          color: "#FFFFFF",
          borderRadius: "50%",
        },
        "& .react-datepicker__day:hover ": {
          backgroundColor: "#4BA3B7",
          color: "#FFFFFF",
          borderRadius: "50%",
        },
        "& .react-datepicker__header": {
          backgroundColor: "#FFFFFF",
          borderBottom: "none",
          minWidth: "500px",
        },
        "& .react-datepicker__day": {
          padding: "10px 55px 16px 8px",
        },
        "& .react-datepicker__day-name": {
          padding: "20px 47px 10px 20px",
          color: "#94A3B8",
        },
      },
      "&.view-modal-filter": {
        "& .filter-content": {
          position: "relative",
          padding: "80px 60px 24px 60px",
          "& .filter-heading": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          },
          "& .calender-title": {
            color: "#000000DE",
            fontFamily: "Expletus Sans",
            fontSize: "24px",
            fontWeight: 400,
          },
          "& .calender-sub-title": {
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 400,
          },
          "& .button-wrapper": {
            textAlign: "end",
            "& .secondary-btn": {
              minWidth: "120px",
              marginRight: "20px",
            },
            "& .primary-btn": {
              minWidth: "100px",
            },
          },
        },
      },
    },
    modalDialogBox: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 580,
      backgroundColor: "#ffffff",
      padding: "48px",
      borderRadius: "24px",
      [theme.breakpoints.down("xs")]: {
        padding: "24px",
        maxWidth: "327px",
        width: "90%",
        borderRadius: "12px",
        backgroundColor: "#F2F8FC",
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "22px",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "23px",
        },
      },
      "& .modal-title": {
        fontSize: "24px",
        lineHeight: "30px",
        color: "#000000",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        [theme.breakpoints.down("xs")]: {
          fontSize: "18px",
          lineHeight: "24px",
        },
      },
      "& .modal-desc": {
        fontSize: "14px",
        letterSpacing: "0.0075em",
        lineHeight: "26px",
        color: "#2b2b2b",
        fontWeight: 400,
        fontFamily: "Roboto",
        [theme.breakpoints.down("xs")]: {
          fontSize: "13px",
          lineHeight: "17px",
        },
        "& .title-dark": {
          fontWeight: "700",
        },
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .modal-footer": {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "32px",
        [theme.breakpoints.down("xs")]: {
          marginTop: "24px",
        },
        "& .primary-btn, & .secondary-btn ": {
          width: "auto",
          minWidth: "157px",
          [theme.breakpoints.down("xs")]: {
            minWidth: "70px",
            padding: "4px 6px",
          },
        },
        "& .MuiButton-root": {
          marginRight: "24px",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
      "& .primary-btn": {
        minHeight: "48px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "0",
        [theme.breakpoints.down("xs")]: {
          minHeight: "30px",
        },
      },
      "& .secondary-btn": {
        minHeight: "48px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "0",
        [theme.breakpoints.down("xs")]: {
          minHeight: "30px",
          border: "0",
          color: "#4BA3B7",
        },
      },
    },
  });
// Customizable Area End
