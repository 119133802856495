import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
const baseConfig = require("../../../framework/src/config");

// Initialize i18n
i18n.use(initReactI18next).init({
  resources: {},
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false
  }
});
const fetchTranslations = async (language: string) => {
  try {
    console.log("in this")
    const cachedTranslations = await getStorageData(`translations_${language}`);
    console.log(cachedTranslations)
    if (cachedTranslations) {
      const parsedTranslations = JSON.parse(cachedTranslations);
      i18n.addResourceBundle(language, 'translation', parsedTranslations);
      return Promise.resolve(); // Indicate success
    }

    const response = await fetch(
      `${baseConfig.baseURL}/bx_block_language_options/translations?code=${language}`
    );

    if (!response.ok) {
      throw new Error('Failed to fetch translations');
    }

    const translations = await response.json();
    console.log(translations)

    const result = Object.fromEntries(
      Object.values(translations).map((entry: any) => [entry.key, entry.value])
    );

    i18n.addResourceBundle(language, 'translation', result);

    // Cache translations
    await setStorageData(`translations_${language}`, JSON.stringify(result));
    return Promise.resolve(); // Indicate success
  } catch (error) {
    console.error('Error fetching translations:', error);
    return Promise.reject(error); // Indicate failure
  }
};

export const fetchAllTranslations = async () => {
  const lang = (await getStorageData('lang')) ?? 'en-us';
  await fetchTranslations(lang); // Wait for translations to be fetched
  await i18n.changeLanguage(lang); // Change language after translations are ready
};

fetchAllTranslations();

export const fetchLocales = async (): Promise<any> => {
  const apiUrl =  `${baseConfig.baseURL}/bx_block_language_options/locales`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
    
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch locales. Status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data)
    return data; // Return the response data
  } catch (error) {
    console.error("Error fetching locales:", error);
    throw error; // Rethrow the error to handle it at the caller
  }
};
export const fetchAllLocales = async() => {
  return await fetchLocales()

}

export default i18n;
