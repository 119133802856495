// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import i18n from "../../../../web/src/utilities/i18n";


export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    isLoading: boolean,
    isCartOpen: boolean,
}

interface SS {
    id: any;
}

interface DeviceToken {
    id: number;
    account_id: number;
    device_token: string;
    created_at: string;
    updated_at: string;
}

interface EmailAccount {
    id: string;
    type: string;
    attributes: {
        first_name: string;
        last_name: string;
        email: string;
        full_phone_number: string;
        user_name: string | null;
        country: string;
        city: string;
        state: string;
        country_code: string | null;
        phone_number: string | null;
        choose_team: string;
        job_type: string;
        job_role: string;
        hourly_pay: number;
        activated: boolean;
        is_new: boolean;
        company_name: string;
        company_place_address: string;
        unassigned: string | null;
        created_by: string;
        owner_onboard: boolean;
        original_owner: boolean;
        profile_created: boolean;
        invite_accepted: boolean;
        password_set: boolean;
        accepted_terms_and_conditions: boolean;
        preferred_locale: string;
        can_skip: boolean;
        stripe_subscription_id: string;
        device_tokens: DeviceToken[];
        last_device_token: string;
        brevo_verified: boolean;
    };
}

export default class ProcessingContactScreenController extends BlockComponent<
    Props,
    S,
    SS
> {
    userSessionData: any;
    userToken: any;
    GetUserDetailsRequestId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            isLoading: false,
            isCartOpen: false,
        };
        this.userSessionData = sessionStorage.getItem("userData") || localStorage.getItem("userData");
        this.userToken = JSON.parse(this.userSessionData);
        
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async componentDidMount() {
        this.GetUserDetails();
        const lang = localStorage.getItem("lang") ?? "en";
        await (i18n as any).changeLanguage(lang);
    }

    t(key: any, variables?: Record<string, any>) {
        return (i18n as any).t(key, { ns: "translation", ...variables })
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
    
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    
        if (!apiRequestCallId || !responseJson) {
            this.parseApiCatchErrorResponse(errorReponse);
            return;
        }
    
        if (responseJson.error) {
            await this.handleErrorResponse(responseJson.error);
        } else {
            await this.handleSuccessResponse(apiRequestCallId, responseJson);
        }
    }

    async handleErrorResponse(error: string) {
        this.setState({isLoading:false})
    }

    async handleSuccessResponse(apiRequestCallId: string, responseJson: any) {
        if (apiRequestCallId === this.GetUserDetailsRequestId) {
            this.handleUserSubscriptionResponse(responseJson.data)
        } else {
            this.parseApiCatchErrorResponse(responseJson);
        }
    }

    handleUserSubscriptionResponse = (responseJson: EmailAccount) => {
        const UserSubscribed = responseJson.attributes.stripe_subscription_id === null;
        this.setState({ isLoading: false, });
        if (UserSubscribed) {
            this.handleOpenCart();
        }
    }

    GetUserDetails = async() => {
        let token=  this.userToken.meta.token;        
        const header = {
            "Content-Type": "application/json",
            token: await token,
        };

        const requestMessage: Message = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.GetUserDetailsRequestId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.GetUserDetails
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        this.setState({ isLoading: true });

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    
    handleOpenCart = () => {
        this.setState({ isCartOpen: true });
    };
    handleCloseCart = () => {
        this.setState({ isCartOpen: false });
    };

}
// Customizable Area End
