import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  InputLabel,
  Typography,
  Input,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Popover,
  CardMedia,
  ListSubheader,
  Drawer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  List,
  ListItem,
  ListItemText,
  Modal,
  Tabs,
  Tab,
  Chip,
} from "@material-ui/core";
import {
  noTimesheetImage,
  menu,
  closeGray,
  minimizeGray,
  checkBoxEmpty,
  checkBoxFilled,
  minimizeTimesheet,
  arrowDropdown,
} from "./assets";
import ProfileSidebar from "../../../components/src/CustomProfileSidebar";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "../../../components/src/TimesheetManagementHelper.web";
import { Link } from "react-router-dom";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import CustomMultiSelect from "../../../components/src/CustomMultiSelect.web";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import DeleteConfirmationModal from "../../../components/src/DeleteConfirmationModal.web";
import ConfirmationBox from "../../../components/src/ConfirmationBox.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import WebLoader from "../../../components/src/WebLoader.web";
import moment from "moment";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

export function viwA11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function assigneeA11yProps(index: any) {
  return {
    id: `assignee-tab-${index}`,
    "aria-controls": `assignee-tabpanel-${index}`,
  };
}
// Customizable Area End
import TimesheetManagementControllerWeb, {
  Props,
  configJSON,
  // Customizable Area Start
  // Customizable Area End
} from "./TimesheetManagementController.web";
export class TimesheetManagement extends TimesheetManagementControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  chooseMembersModal = (classes: any) => {
    return (
      <Modal
        open={this.state.isActiveChooseMembers}
        onClose={this.closeMembersModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
      >
        <Box className={classes.modalDialogBox}>
          <Box className="modal-dialoginner">
            <Box className="modal-heading">
              <Typography className="modal-title" component="h2">
                {this.t(`${configJSON.txtMemberSelected}`)}
              </Typography>
              <Link
                to="#"
                className="close-icon"
                onClick={this.closeMembersModal}
                data-test-id="membersModalClose"
              >
                <CloseIcon />
              </Link>
            </Box>
            <Box className="modal-description">
              <Box component="p" className="sub-txt">
                {this.t(`${configJSON.txtAssigneeDescription}`)}
              </Box>
              <Box className="modal-form" component="form">
                <List className="assignee-multiSelect-list selected-list">
                  <ListSubheader>
                    <CustomInputWeb
                      placeholder={this.t(`${configJSON.txtSearch}`)}
                      fullWidth={true}
                      autoFocus={true}
                      name="assigneeSearchText"
                      onChange={this.handleSearchMembers}
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                      data-test-id="handleSearchMembers"
                    />
                  </ListSubheader>

                  <ListSubheader className="multiselect-subheader">
                    {this.t(`${configJSON.txtSelected}`)}
                    <Link
                      to="#"
                      className="tasklist-link"
                      onClick={this.clearSelectedMembers}
                      data-test-id="clear-all-members"
                    >
                      {this.t(`${configJSON.txtRemoveAllAssignees}`)}
                    </Link>
                  </ListSubheader>

                  {this.membersModalSelectedLists(classes)}
                  <ListSubheader className="multiselect-subtxt">
                    {this.t(`${configJSON.membersTxt}`)}
                  </ListSubheader>

                  <ListSubheader className="multiselect-subheader">
                    {this.t(`${configJSON.txtUnassigned}`)}
                  </ListSubheader>
                </List>
                <Box className="tasklist-tabwrapper">
                  <Box className="assignee-tabs">
                    <Tabs
                      data-test-id="members-teams-tab"
                      onChange={this.setAssigneeTabsValue}
                      value={this.state.assigneeTabsValue}
                      aria-label="simple tabs example"
                    >
                      <Tab
                        label={this.t(`${configJSON.teamsTxt}`)}
                        {...assigneeA11yProps(0)}
                      />
                      <Tab
                        label={this.t(`${configJSON.membersTxt}`)}
                        {...assigneeA11yProps(1)}
                      />
                    </Tabs>
                  </Box>

                  <TabPanel value={this.state.assigneeTabsValue} index={0}>
                    <List className="assignee-multiSelect-list">
                      <ListSubheader className="multiselect-membres">
                        <Box className="total-member">
                          {this.showTeamsLengthText(this.displayTeamOptions()
                        .filter(
                          (ass: any) =>
                            !this.state.selectedTeam.includes(ass.id)
                        ).length)}
                        </Box>
                        
                      </ListSubheader>

                      {this.displayTeamOptions()
                        .filter(
                          (ass: any) =>
                            !this.state.selectedTeam.includes(ass.id)
                        )
                        .map((team: any) => (
                          <ListItem
                            data-test-id={`change-team-${team.id}`}
                            value={team.id}
                            key={team.id}
                            onClick={() => this.handleTeamsClick(team.id)}
                          >
                            <Box className="assignee-detail">
                              <Box className="assignee-left">
                                <Box className="assignee-profile">
                                  {team.initials}
                                </Box>
                                <Box className="assignee-info">
                                  <Box className="assignee-name">
                                    {team.title}
                                  </Box>
                                </Box>
                              </Box>
                              <Box className="assignee-right">
                                <Box className="assinee-unselected">
                                  <img
                                    alt="checkBoxEmpty"
                                    src={checkBoxEmpty}
                                    className="normal-img"
                                  />
                                  <img
                                    className="selected-img"
                                    alt="checkBoxFilled"
                                    src={checkBoxFilled}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </ListItem>
                        ))}
                    </List>
                  </TabPanel>
                  <TabPanel value={this.state.assigneeTabsValue} index={1}>
                    <List className="assignee-multiSelect-list">
                      <ListSubheader className="multiselect-membres">
                        <Box className="total-member">
                          {this.showMembersCell(this.displayMemberOptions().filter(
                              (y: any) =>
                                !this.state.selectedMembers.includes(y.id)
                            ).length)}
                        </Box>
                        <FormControl className="select-control sortby-control">
                          <Select
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                              anchorOrigin: {
                                horizontal: "left",
                                vertical: "bottom",
                              },
                              transformOrigin: {
                                horizontal: "left",
                                vertical: "top",
                              },
                              classes: { paper: classes.dropdownStyle },
                              getContentAnchorEl: null,
                            }}
                            displayEmpty
                            defaultValue={this.t(`${configJSON.sortByTxt}`)}
                            onChange={this.changeSortByMembers}
                            data-test-id="sort-members"
                            renderValue={(value: any) => {
                              return <Box>{value}</Box>;
                            }}
                          >
                            <MenuItem value="A-Z">
                              {this.t(`${configJSON.aTozTxt}`)}
                            </MenuItem>
                            <MenuItem value="Z-A">
                              {this.t(`${configJSON.zToaTxt}`)}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </ListSubheader>
                      {this.sortMemberRecords(this.displayMemberOptions())
                        .filter(
                          (ass: any) =>
                            !this.state.selectedMembers.includes(ass.id)
                        )
                        .map((user: any) => (
                          <ListItem
                            data-test-id={`change-member-${user.id}`}
                            value={user.id}
                            key={user.id}
                            onClick={() => this.handleMembersClick(user.id)}
                          >
                            <Box className="assignee-detail">
                              <Box className="assignee-left">
                                <Box className="assignee-profile">
                                  {user.initials}
                                </Box>
                                <Box className="assignee-info">
                                  <Box className="assignee-name">
                                    {user.title}
                                  </Box>
                                  <Box className="assignee-email">
                                    {user.email}
                                  </Box>
                                </Box>
                              </Box>
                              <Box className="assignee-right">
                                <Box className="assinee-unselected">
                                  <img
                                    alt="checkBoxEmpty"
                                    src={checkBoxEmpty}
                                    className="normal-img"
                                  />
                                  <img
                                    className="selected-img"
                                    alt="checkBoxFilled"
                                    src={checkBoxFilled}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </ListItem>
                        ))}
                    </List>
                  </TabPanel>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };
  membersModalSelectedLists = (classes: any) => {
    return (
      <>
        {this.state.assigneeTabsValue === 0 ? (
          <>
            {this.displayTeamOptions()
              .filter((x: any) => this.state.selectedTeam.includes(x.id))
              .map((team: any) => (
                <ListItem
                  onClick={() => this.handleTeamsClick(team.id)}
                  value={team.id}
                  key={team.id}
                >
                  <Box className="assignee-detail">
                    <Box className="assignee-left">
                      <Box className="assignee-profile">{team.initials}</Box>
                      <Box className="assignee-info">
                        <Box className="assignee-name">{team.title}</Box>
                      </Box>
                    </Box>
                    <Box className="assignee-right">
                      <Box className="assinee-selected">
                        <img
                          alt="checkBoxEmpty"
                          src={checkBoxEmpty}
                          className="normal-img"
                        />
                        <img
                          alt="checkBoxFilled"
                          src={checkBoxFilled}
                          className="selected-img"
                        />
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
              ))}
          </>
        ) : (
          <>
            {this.displayMemberOptions()
              .filter((x: any) => this.state.selectedMembers.includes(x.id))
              .map((member: any) => (
                <ListItem
                  onClick={() => this.handleMembersClick(member.id)}
                  value={member.id}
                  key={member.id}
                >
                  <Box className="assignee-detail">
                    <Box className="assignee-left">
                      <Box className="assignee-profile">{member.initials}</Box>
                      <Box className="assignee-info">
                        <Box className="assignee-name">{member.title}</Box>
                        <Box className="assignee-email">{member.email}</Box>
                        {member.job_role && (
                          <Chip
                            className={classes.chipButton}
                            label={member.job_role}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box className="assignee-right">
                      <Box className="assinee-selected">
                        <img
                          alt="checkBoxEmpty"
                          src={checkBoxEmpty}
                          className="normal-img"
                        />
                        <img
                          alt="checkBoxFilled"
                          src={checkBoxFilled}
                          className="selected-img"
                        />
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
              ))}
          </>
        )}
      </>
    );
  };
  
  showMembersCell = (count: number) => {
    const text = count > 1 ? this.t(configJSON.membersTxt) : this.t(configJSON.membersSingleTxt);
    return `${count} ${text}`;
  }
  showTasksCell = (count: number) => {
    if(count >1 ){
      return `${count} Tasks`
    }else {
      return `${count} Task`
    }
  }
  showTeamsLengthText = (teamLength : number) => {
    const text = teamLength > 1 ? this.t(configJSON.teamsTxt) : this.t(configJSON.teamsSingleTxt);
    return `${teamLength} ${text}`;
  }
  showSelectedMembers = () => {
    if(this.state.selectedMembers.length > 0){
      return (
        
        this.state.selectedMembers.length > 0 ? (
          <InputAdornment position="start">
            <Box className="assignee-list">
              {this.displayMemberOptions()
                .filter((x: any) =>
                  this.state.selectedMembers.includes(x.id)
                )
                .slice(0, 2)
                .map((option: any) => {
                  return (
                    <>
                    <Box className="assignee-bubble" key={option.id}>
                      {option.initials} 
                    </Box>
                    </>
                    
                  );
                })}
               
              {this.state.selectedMembers.length > 2 && (
                <Box className="assignee-bubble">
                  +{this.state.selectedMembers.length - 2}
                </Box>
              )}
               Members selected
            </Box>
          </InputAdornment>
        ) : null
      )
    }
    if(this.state.selectedTeam.length > 0){
      return (
        
        this.state.selectedTeam.length > 0 ? (
          <InputAdornment position="start">
            <Box className="assignee-list">
              {this.displayTeamOptions()
                .filter((x: any) =>
                  this.state.selectedTeam.includes(x.id)
                )
                .slice(0, 2)
                .map((option: any) => {
                  return (
                    <>
                    <Box className="assignee-bubble" key={option.id}>
                      {option.initials} 
                    </Box>
                    </>
                    
                  );
                })}
               
              {this.state.selectedTeam.length > 2 && (
                <Box className="assignee-bubble">
                  +{this.state.selectedTeam.length - 2}
                </Box>
              )}
               Teams selected
            </Box>
          </InputAdornment>
        ) : null
      )
    }
    
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {allTimesheetData, isLoading} = this.state;
    const popOverOpen = Boolean(this.state.popoveAnchorEl);
    const popoverId = popOverOpen ? "simple-popover" : undefined;
    let projectsData;

    if (this.state.isSearching) {
      projectsData = this.state.searchedProjects;
    } else {
      projectsData = this.state.projectLists;
    }
    return (
      <>
      {this.state.isLoading && <WebLoader/>}
        <GlobalHeaderWeb />
        <Box
           className={`wrapper ${classes.wrapper} ${classes.timesheetWrapper}`}
        >
          <Box style={{marginTop: "20px"}} className={`inner-wrapper ${classes.innerWrapper}`}>
            <Box className={classes.timesheetInnerWrapper}>
              <Box className="main-heading">
                <Box className="back-btn-wrap">
                  <i>
                    <svg
                      className="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                    </svg>
                  </i>
                </Box>
                <Typography className="heading" variant="h1">
                  {this.t("Timesheet")}
                </Typography>
                <Box className="profile-menu">
                  <img src={menu} alt="menu" />
                </Box>
              </Box>
              <Box className={classes.drawerWrapper}>
                <Box className={classes.drawer}>
                  <Drawer
                    classes={{
                      paper: classes.drawerPaper,
                      root: classes.drawerRootWrapper,
                    }}
                    variant={"permanent"}
                    open={true}
                  >
                    <ProfileSidebar t={this.t} />
                  </Drawer>
                </Box>
                <Box className={classes.drawerContent}>
                  <Box className={`${classes.profileBlockOuter}`}>
                    <Box className="timesheet-heading-wrapper">
                      <Typography className="timesheet-title">
                        {this.t(`${configJSON.noTimesheetText}`)}
                      </Typography>
                      <Typography className="timesheet-sub-title">
                        {this.t(`${configJSON.viewTimesheetText}`)}
                      </Typography>
                    </Box>
                    {allTimesheetData.length > 0 && (
                      <>
                        <Box className="top-content-timesheet">
                          <Box className="top-left">
                            {" "}
                            <Box className="search-wrapper">
                              <Input
                                placeholder={this.t(
                                  `${configJSON.searchTxtType}`
                                )}
                                type="search"
                                startAdornment={
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                }
                              />
                            </Box>
                          </Box>
                          <Box className="top-right">
                            <FormControl className="select-control-deals sortby-control">
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                  classes: {
                                    paper: classes.dropdownStyleDeals,
                                  },
                                }}
                                displayEmpty
                                defaultValue={this.t(`${configJSON.sortByTxt}`)}
                                data-test-id="sort-timesheet"
                                inputProps={{ IconComponent: () => null }}
                                onChange={(event:any) => this.sortTimesheets(event)}
                                renderValue={(value) => {
                                  return (
                                    <Box>
                                      {value}
                                      <ArrowDropDownIcon />
                                    </Box>
                                  );
                                }}
                              >
                                <MenuItem value={this.t(`${configJSON.sortByTxt}`)}>
                                  {this.t(`${configJSON.sortByTxt}`)}
                                </MenuItem>
                                <MenuItem value={this.t(`${configJSON.newestToOldest}`)}>{this.t(`${configJSON.newestToOldest}`)}</MenuItem>
                                <MenuItem value={this.t(`${configJSON.oldestToNewest}`)}>{this.t(`${configJSON.oldestToNewest}`)}</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>

                        <Box className="timesheet-inner">
                          <TableContainer>
                            <Table
                              className={`table-wrap-timesheet ${classes.table}`}
                              aria-labelledby="tableTitle"
                              aria-label="enhanced table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell className="name-cell">
                                    Date
                                  </TableCell>
                                  <TableCell className="stage-cell">
                                    Members
                                  </TableCell>
                                  <TableCell className="deal-amount-cell">
                                    Tasks
                                  </TableCell>
                                  <TableCell className="task-cell">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {allTimesheetData.map((records: any) => {
                                  return (
                                    <TableRow key={records.id}>
                                      <TableCell
                                        className="name-cell"
                                        scope="row"
                                      >
                                        <Box className="name-col">
                                          <Link
                                            to={`/downloadtimesheet/${records.id}`}
                                            className="table-link"
                                          >
                                            {moment(records.attributes.start_date).format("MMMM DD, YYYY")} - {moment(records.attributes.end_date).format("MMMM DD, YYYY")}
                                          </Link>
                                        </Box>
                                      </TableCell>
                                      <TableCell className="stage-cell">
                                        {this.showMembersCell(records.attributes.members_count)}
                                      </TableCell>
                                      <TableCell>
                                      {this.showTasksCell(5)}
                                      </TableCell>

                                      <TableCell className="action-link">
                                        <Link
                                          to="#"
                                          title="Delete"
                                          className="table-link"
                                          onClick={() => this.openDeleteModal(records.id)}
                                          data-test-id={`delete-action-modal-${records.id}`}
                                        >
                                          Delete
                                        </Link>
                                        <Link
                                          to={`/downloadtimesheet/${records.id}`}
                                          title="Download"
                                          className="table-link"
                                        >
                                          Download
                                        </Link>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.newTimesheetBtn}>
          <Box className="timesheet-actions">
            <Button
              className={`primary-btn ${classes.primaryButton}`}
              aria-describedby={popoverId}
              onClick={(event: any) => this.createTimesheetForm(event)}
              data-test-id="new-timesheet-btn"
            >
              + {this.t(`${configJSON.newTimesheetTxt}`)}
            </Button>
          </Box>
        </Box>
        {this.state.showTimesheetForm && (
          <Popover
            id={popoverId}
            open={popOverOpen}
            anchorEl={this.state.popoveAnchorEl}
            onClose={this.timesheetFormClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            classes={{ paper: `${classes.createTimesheetForm}` }}
            data-test-id="timesheet-popover"
            onKeyDown={(event) => {this.handleTimesheetescevent(event)}}

          >
            <Box className="deals-heading">
              <Box className="heading-left-deals">
                <Typography className="modal-title" component="h2">
                  {this.t(`${configJSON.noTimesheetText}`)}
                </Typography>
                <Box component="p" className="sub-txt">
                  {this.t(`${configJSON.timesheetFormDesc}`)}
                </Box>
              </Box>
              <Box className="heading-right">
                <Link
                  to="#"
                  className="heading-icon"
                  data-test-id="minimizeTimesheetForm"
                  onClick={this.openFloatCreateTimesheet}
                >
                  <img src={minimizeGray} alt="minimizeGray" />
                </Link>
                <Link
                  to="#"
                  className="heading-icon"
                  onClick={this.timesheetFormClose}
                  data-test-id="timesheet-form-close"
                >
                  <img src={closeGray} alt="closeGray" />
                </Link>
              </Box>
            </Box>
            <Box className="task-description">
              <Box className="task-form" component="form">
                <Box className="form-info-wrapper">
                  <Box className="form-row">
                    <Box className="form-col" style={{ position: 'relative' }}>
                      <CustomInputWeb
                        type="text"
                        label="Choose Teams or Members"
                        name="MembersInput"
                        disabled={true}
                        isRequired={true}
                        errors={this.state.generateTimesheetError.timesheetMembersError}
                        data-test-id="choose-members-input"
                        value=""
                        startAdornment={this.showSelectedMembers()}
                        endAdornment={
                          <InputAdornment
                            position="end"
                            onClick={this.openMembersModal}
                            data-test-id="openMembersModal"
                          >
                            <img src={arrowDropdown} />
                          </InputAdornment>
                        }
                      />
                      <Box 
                      onClick={this.openMembersModal} 
                      style={{ 
                      position: 'absolute', 
                      top: 0, 
                      left: 0, 
                      right: 0, 
                      bottom: 0, 
                      cursor: 'pointer' 
                      }} 
                      />
                    </Box>
                  </Box>
                  <Box className="form-row">
                    <Box className="form-col">
                      <Box className="form-control">
                        <CustomDatePicker
                          placeholder={this.t(
                            `${configJSON.dateRangePlaceholder}`
                          )}
                          range={true}
                          t={this.t}
                          value={this.state.chooseDateRange}
                          currentDate=""
                          onChange={this.choosetimesheetDates}
                          onOpenPickNewDate={false}
                          data-test-id="timesheetDateRange"
                          calendarPosition="top-right"
                          required={true}
                          error={this.state.generateTimesheetError.timesheetDateError}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomMultiSelect
                        label="Choose Project"
                        selectedText="Project"
                        value={this.state.selectedOptions}
                        saveSelectedOption={this.saveSelectedOption}
                        searchTerm={this.state.searchProjectTerm}
                        onSearchChange={this.handleProjectsSearch}
                        data={projectsData}
                        displayLabel={this.getLabel}
                        classes={classes}
                        placeholder={this.t(`${configJSON.searchTxt}`)}
                        data-test-id="select-projects"
                      />
                    </Box>
                  </Box>

                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomMultiSelect
                        label="Choose TaskList"
                        selectedText="TaskList"
                        value={this.state.saveSelectedTaskList}
                        saveSelectedOption={this.saveSelectedTaskList}
                        searchTerm={this.state.searchProjectTerm}
                        onSearchChange={this.handleTaskListSearch}
                        data={this.state.isSearchingTaskList ? this.state.searchedTaskList : this.state.taskLists}
                        displayLabel={this.getLabel}
                        classes={classes}
                        placeholder={this.t(`${configJSON.searchTxt}`)}
                        data-test-id="select-tasklist"
                      />
                    </Box>
                  </Box>
                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomMultiSelect
                        label="Choose Tasks"
                        selectedText="Task"
                        value={this.state.selectedTaskOptions}
                        saveSelectedOption={this.selectedTaskOptions}
                        searchTerm={this.state.searchProjectTerm}
                        onSearchChange={this.handleTaskSearch}
                        data={this.state.isSearchingTask ? this.state.searchedTask : this.state.allTaskLists}
                        displayLabel={this.getLabel}
                        classes={classes}
                        placeholder={this.t(`${configJSON.searchTxt}`)}
                        data-test-id="select-task"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="createtask-bottom">
              <Box className="full-btn">
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  onClick={this.generateTimesheetAPIRequest}
                  data-test-id="generate-timesheet"
                >
                  {this.t(`${configJSON.createTimesheetTxt}`)}
                </Button>
              </Box>
            </Box>
          </Popover>
        )}
        {this.state.floatTimeSheet && (
          <Box className={classes.btnMinimizeForm}>
            <CardMedia
              component="img"
              src={minimizeTimesheet}
              className="expand-icon"
              onClick={this.maximizeTimesheetForm}
              data-test-id="maximizeTimesheetForm"
            />
            <Typography className="create-txt">Create Timesheet</Typography>
            <Button className="minimize-close-btn">
              <CardMedia
                component="img"
                src={closeGray}
                className="close-icon"
                onClick={this.closeFloatCreateTimesheet}
                data-test-id="close-floating-deal"
              />
            </Button>
          </Box>
        )}
        {this.state.isActiveChooseMembers && this.chooseMembersModal(classes)}
        {this.state.deleteModal && (
          <DeleteConfirmationModal
            modalOpen={this.state.deleteModal}
            modalClose={this.closeDeleteModal}
            deleteHeading="Delete Timesheet"
            deleteText={this.t(`${configJSON.deleteTimesheetTxt}`)}
            confirmHandler={this.deleteSelectedTimesheet}
            data-test-id="delete-timesheet-modal"
            t={this.t}
            closeIconGray={true}
          />
        )}
        
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(
  TimesheetManagement
);
// Customizable Area End
