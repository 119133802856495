import React from "react";
// Customizable Area Start
import {
  Typography,
  Modal,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Button,
  Box,
} from "@material-ui/core";
import NewChatModalController, {
  Props,
  configJSON,
} from "./NewChatModalController.web";
import { withStyles } from "@material-ui/core/styles";
import { liveChatCustomStyles } from "../../../components/src/LiveChatStyles.web";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import withMediaQuery from "../../../components/src/withMediaQuery.web";

import WebLoader from "../../../components/src/WebLoader.web";
import CustomInputContent from "../../../components/src/CustomInput.web";
import {
  browse,
  close,
  etohSupportImg,
  invitemenberImg,
  sortby,
} from "./assets";
import { CustomSearchDropdown } from "../../../components/src/CustomSearchDropdown.web";

// Customizable Area End

export class NewChatModal extends NewChatModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, openModal ,closeModal} = this.props;
    const {
      subscribedUserData,
      userSearch,
      userSortBy,
      openExternalChat,
      email,
      openEmailvarification,
      isSortModalOpenEvent,
      projectDetailName,
      projectDetailSearchVal,
      teamDetailName,
      teamDetailSearchVal,
    } = this.state;
    const MessageText =
      subscribedUserData?.length >= 2
        ? this.translateChat(`${configJSON.membersTxt}`)
        : this.translateChat(`${configJSON.memberTxt}`);

    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.isLoading && <WebLoader />}
        <Modal
          className={classes.modalCreateDialog}
          aria-labelledby="modal-modal-title"
          onClose={closeModal}
          open={openModal}
          aria-describedby="modal-modal-description"
        >
          <Box className={`view-modal-dialog ${classes.modalCreateDialogBox}`}>
            <Box className="modal-content">
              <Box className="modal-wrapper">
                <Typography className="modal-headding">
                  {this.translateChat(`${configJSON.newChatBtn}`)}
                </Typography>
                <Box
                  className="close-icon"
                  onClick={closeModal}
                  data-test-id="closeNewChat"
                >
                  <img src={close} alt="closeGray" />
                </Box>
              </Box>
              <Typography className="desc">
                {this.translateChat(`${configJSON.newChatSubTxt}`)}
              </Typography>
              <Box className="search-wrapper">
                <Box className="search-box">
                  <CustomInputContent
                    type="text"
                    placeholder={this.translateChat(
                      `${configJSON.searchPlaceholder}`
                    )}
                    data-test-id="userSearch"
                    name="userSearch"
                    value={userSearch}
                    onChange={this.handleAllInputChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#225980" }} />
                      </InputAdornment>
                    }
                  />
                  <Box
                    onClick={this.openSortModal}
                    data-test-id="handleFiltersOPen"
                    className="sort-by"
                  >
                    <img src={sortby} alt="bg-image" className="filter-image" />
                  </Box>
                </Box>
              </Box>
              <Box
                className="etoh-header"
                data-test-id="createEtohSupportChat"
                onClick={this.createEtohSupportChat}
              >
                <Box className="etoh-img">
                  <img src={etohSupportImg} alt="EtOH Support" />
                </Box>
                <Box className="etoh-content">
                  <Typography className="support-txt">
                    EtOH {this.translateChat(`${configJSON.supportChat}`)}
                  </Typography>
                  <Typography className="support-sub-txt">
                    {this.translateChat(`${configJSON.etohSupportSubText}`)}
                  </Typography>
                </Box>
              </Box>
              <Box
                className="etoh-header"
                data-test-id="openExternalChatEvent"
                onClick={this.openExternalChatEvent}
              >
                <Box className="etoh-img">
                  <img src={browse} alt="External Chat" />
                </Box>
                <Box className="etoh-content">
                  <Typography className="support-txt">
                    {this.translateChat(`${configJSON.extenalChat}`)}
                  </Typography>
                  <Typography className="support-sub-txt">
                    {this.translateChat(`${configJSON.externalChatSubText}`)}
                  </Typography>
                </Box>
              </Box>
              <Box className="sort-container">
                <Typography className="conversion-count-text">
                  {subscribedUserData?.length}&nbsp;{MessageText}
                </Typography>
                <FormControl className="select-control">
                  <Select
                    inputProps={{ IconComponent: () => null }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: { paper: classes.dropdownStyle },
                    }}
                    value={userSortBy}
                    name="userSortBy"
                    onChange={this.handleAllInputChange}
                    renderValue={(value: any) => {
                      return (
                        <Box>
                          {value}
                          <ExpandMoreIcon />
                        </Box>
                      );
                    }}
                    data-test-id="userSortBy"
                  >
                    <MenuItem value="A-Z">
                      {this.translateChat(`${configJSON.aDashZTxt}`)}
                    </MenuItem>
                    <MenuItem value="Z-A">
                      {this.translateChat(`${configJSON.zDashATxt}`)}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {subscribedUserData?.map((item: any) => {
                const userName =
                  item.attributes.first_name + " " + item.attributes.last_name;
                const intitials = `${item.attributes.first_name
                  .charAt(0)
                  .toUpperCase()}${item.attributes.last_name
                  .charAt(0)
                  .toUpperCase()}`;
                return (
                  <Box
                    className="etoh-header-chat"
                    key={item.id}
                    onClick={() =>
                      this.createExternalAndInternalChatEvent(item, "internal")
                    }
                    data-test-id={`createExternalAndInternalChatEvent-${item.id}`}
                  >
                    <Box className="etoh-name">{intitials}</Box>
                    <Box className="etoh-content">
                      <Typography className="support-txt">
                        {userName}
                      </Typography>
                      <Typography className="support-sub-txt">
                        {item.attributes.email}
                      </Typography>
                      <Box>
                        {item.attributes.team &&
                          item.attributes.team.length > 0 &&
                          typeof item.attributes.team[0] === "object" &&
                          "title" in item.attributes.team[0] && (
                            <Chip
                              className={classes.chipButton}
                              label={
                                (item.attributes.team[0] as { title: string })
                                  .title
                              }
                            />
                          )}
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Modal>
        <Box className={classes.externalChatModal}>
          <Modal
            open={openExternalChat}
            onClose={this.closeExternalChat}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.modalDialogChat}
          >
            <Box className={classes.modalDialogBox}>
              <Box className="modal-heading">
                <Typography className="modal-title" component="h2">
                  {this.translateChat(`${configJSON.externalChatSubText}`)}
                </Typography>
                <Box
                  data-test-id="closeExternalChat"
                  onClick={this.closeExternalChat}
                >
                  <img src={close} alt="close external" />
                </Box>
              </Box>
              <Typography className="modal-sub-title" component="h2">
                {this.translateChat(`${configJSON.extenalChatHeadding}`)}
              </Typography>
              <Box className="modal-description">
                <CustomInputContent
                  type="email"
                  label={this.translateChat(`${configJSON.emailAddressTxt}`)}
                  name="email"
                  value={email}
                  onChange={this.handleAllInputChange}
                  data-test-id="emailEventID"
                  errors={this.state.error.email}
                />
                {this.state.error.email === "Member profile not found" && (
                  <Box className="modal-footer">
                    <Button
                      className={`secondary-btn ${classes.secondaryButton}`}
                      onClick={() => this.handleInviteMember()}
                      data-test-id="handleInviteMember"
                    >
                      {this.translateChat(`${configJSON.inviteMemberTxt}`)}
                    </Button>
                  </Box>
                )}

                <Box className="modal-footer">
                  <Button
                    className={
                      this.state.error.email === "Member profile not found" ||
                      email === ""
                        ? `primary-btn  ${classes.diabledPrimaryButton}`
                        : `primary-btn  ${classes.primaryButton}`
                    }
                    onClick={this.createExternalChatEvent}
                    data-test-id="createExternalChatEvent"
                    disabled={
                      this.state.error.email === "Member profile not found" ||
                      email === ""
                        ? true
                        : false
                    }
                  >
                    {this.translateChat(`${configJSON.startChatTxt}`)}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
        {openEmailvarification && (
          <Box className={classes.externalChatModal}>
            <Modal
              open={openEmailvarification}
              onClose={this.closeEmailVarification}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className={classes.modalDialogChat}
            >
              <Box className={classes.modalDialogBox}>
                <Box className="modal-heading-invite">
                  <img
                    className="invite-member-img"
                    src={invitemenberImg}
                    alt="invitemenberImg"
                  ></img>
                  <Box
                    data-test-id="closeEmailVarification"
                    onClick={this.closeEmailVarification}
                  >
                    <img src={close} alt="close external" />
                  </Box>
                </Box>
                <Box className="modal-description">
                  <CustomInputContent
                    type="email"
                    label={this.translateChat(`${configJSON.emailAddressTxt}`)}
                    name="email"
                    value={email}
                    onChange={this.handleAllInputChange}
                    data-test-id="email"
                  />
                  <Typography className="member-criteria">
                    {this.translateChat(`${configJSON.memberCriteriaTxt}`)}
                  </Typography>
                  <Typography className="confirm-text">
                    {this.translateChat(`${configJSON.subTxtForInvitaion}`)}
                  </Typography>
                  <Typography className="confirm-text-1">
                    <div className="points" />{" "}
                    {this.translateChat(`${configJSON.ownerTxt}`)}
                  </Typography>

                  <Typography className="confirm-text-1">
                    <div className="points" />
                    {this.translateChat(`${configJSON.employeeTxt}`)}
                  </Typography>
                  <Box className="modal-footer">
                    <Button
                      className={`primary-btn ${classes.primaryButton}`}
                      onClick={this.inviteMemberEvent}
                      data-test-id="inviteMemberEvent"
                    >
                      {this.translateChat(`${configJSON.inviteMemberTxt}`)}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
          </Box>
        )}
        {isSortModalOpenEvent && (
          <Modal
            open={isSortModalOpenEvent}
            onClose={this.closeSortModal}
            aria-labelledby="sort-modal-title"
            aria-describedby="sort-modal-description"
          >
            <Box className={`view-modal-filter ${classes.modalFilter}`}>
              <Box className="filter-content">
                <Box className="filter-wrapper">
                  <Box className="filter-heading">
                    <Typography className="filters-text">
                      {this.translateChat(`${configJSON.filters}`)}
                    </Typography>
                    <Box className="heading-center">
                      <Typography className="filter-reset">
                        {this.translateChat(`${configJSON.reset}`)}
                      </Typography>
                      <Box
                        onClick={this.closeSortModal}
                        data-test-id="closeSortModal"
                      >
                        <img src={close} alt="close" />
                      </Box>
                    </Box>
                  </Box>

                  <Typography className="filters-assigned">
                    {this.translateChat(`${configJSON.assignedtothisproject}`)}
                  </Typography>
                  <Box className="search-wrapper">
                    <Box className="search-box">
                      <CustomSearchDropdown
                        classes={classes}
                        label={this.translateChat(
                          `${configJSON.search_project_name}`
                        )}
                        value={projectDetailName}
                        changeHandler={this.projectHandler}
                        searchChangeHAndler={
                          this.projectmultiSelectSearchChange
                        }
                        searchValue={projectDetailSearchVal}
                        dropdownListing={this.displayproject}
                        selectName="projectname"
                        searchValueName="projectnameSearchVal"
                        itemValueKey="label"
                        itemLabelKey="label"
                        data-test-id="project-name-dropdown"
                      />
                    </Box>
                  </Box>
                  <Typography className="filters-team">
                    {this.translateChat(`${configJSON.Team}`)}
                  </Typography>
                  <Box className="search-wrapper">
                    <Box className="search-box">
                      <CustomSearchDropdown
                        classes={classes}
                        label={this.translateChat(
                          `${configJSON.search_project_name}`
                        )}
                        value={teamDetailName}
                        changeHandler={this.teamHandler}
                        searchChangeHAndler={this.teammultiSelectSearchChange}
                        searchValue={teamDetailSearchVal}
                        dropdownListing={this.displayteam}
                        selectName="projectname"
                        searchValueName="projectnameSearchVal"
                        itemValueKey="label"
                        itemLabelKey="label"
                        data-test-id="project-name-dropdown"
                      />
                    </Box>
                  </Box>
                  <Button
                    className="button-filter"
                    onClick={this.handleChatFilterData}
                  >
                    {this.translateChat(`${configJSON.ApplyFilters}`)}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(liveChatCustomStyles, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(NewChatModal)
);
// Customizable Area End
