// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import React from "react";

import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./TeamsStyles.web";
import { toast } from "react-toastify";
import { checkedIcon } from "../assets";
import i18n from "../../../../web/src/utilities/i18n";

export type TeamTitleTextInputType = {
  id: number;
  showError: boolean;
};

const TeamTitleInitialState: TeamTitleTextInputType = {
  id: 0,
  showError: false,
} as const;

export const configJSON = require("../config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation: any;
  id: string;
}

export interface S {
  isSubscriptionModal: boolean; 
  fName: string;
  lName: string;
  email: string;
  hourlyPay: number | null;
  role: string;
  teams: any[];
  members: any[];
  jobTitles: any[];
  team: any;
  jobTitle: any;
  tempMembers: any[];
  addedMembers: any[];
  deleteTeamId: string;
  deleteJobTitleId: string;
  error: {
    fName: string;
    lName: string;
    email: string;
    team: string;
    hourlyPay: string;
    role: string;
    jobTitle: string;
  };
  editError: {
    fName: string;
    lName: string;
    email: string;
    team: string;
    hourlyPay: string;
    role: string;
    jobTitle: string;
  };
  emailError: boolean;
  editEmailError: boolean;
  isDeleting: boolean;
  isDeletingTeam: boolean;
  isDeletingMember: boolean;
  editMember: any;
  houryPayFocused: boolean;
  isLoading: boolean;
  isCartOpen: boolean;
  currentTotalAddedMembers: object[];
  responseAddedMembers: object[];
}

export interface SS {
  id: any;
}

export default class InviteMembersController extends BlockComponent<
  Props,
  S,
  SS
> {
  fetchTeamApiCallId: string = "";
  fetchTitleListApiCallId: string = "";
  createTitleApiCallId: string = "";
  editTitleApiCallId: string = "";
  createTeamApiCallId: string = "";
  inviteMemberApiCallId: string = "";
  deleteTeamAPICallId: string = "";
  deleteJobTitleAPICallId: string = "";
  emailValidateApiCallId: string = "";
  updateMemberApiCallId: string = "";
  editTeamApiCallId: string = "";
  userSessionData: any;
  userToken: any;
  setErrorTitle: any;
  showDialogTitle: any;
  setErrorTeam: any;
  showDialogTeam: any;
  rowIndex: number;
  currentTeam: { id: number; title: string };
  currentJobTitle: { id: number };
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isSubscriptionModal: false,
      fName: "",
      lName: "",
      email: "",
      hourlyPay: null,
      teams: [],
      members: [],
      jobTitles: [],
      role: "",
      team: null,
      jobTitle: null,
      tempMembers: [],
      addedMembers: [],
      deleteTeamId: "",
      deleteJobTitleId: "",
      error: {
        fName: "",
        lName: "",
        email: "",
        team: "",
        hourlyPay: "",
        role: "",
        jobTitle: "",
      },
      editError: {
        fName: "",
        lName: "",
        email: "",
        team: "",
        hourlyPay: "",
        role: "",
        jobTitle: "",
      },
      emailError: false,
      editEmailError: false,
      isDeleting: false,
      isDeletingTeam: false,
      isDeletingMember: false,
      houryPayFocused: false,
      isLoading: false,
      editMember: {
        fName: "",
        lName: "",
        email: "",
        team: "",
        hourlyPay: "",
        role: "",
        jobTitle: "",
        id: "",
      },
      isCartOpen: false,
      currentTotalAddedMembers: [],
      responseAddedMembers: [],
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.rowIndex = 0;
    this.currentTeam = { id: 0, title: "" };
    this.currentJobTitle = { id: 0 };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    this.fetchTeams();
    this.fetchTitleList();
    if (new URLSearchParams(window.location.search).get('openPopup') === "true" && new URLSearchParams(window.location.search).get('failpopup') === "true") {
      this.setState({ isCartOpen: true });
  }
    const langC = localStorage.getItem("lang") ?? "en"; 
    await (i18n as any).changeLanguage(langC);
  }
  t(key:any, variable?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variable } )
  }
  handleClose = () => {
    this.setState({
      isDeleting: false,
      isDeletingTeam: false,
      isDeletingMember: false,
    });
  };

  createToastNotification = (
    toastMesssage: string,
    isError: boolean = false
  ) => {
    isError
      ? toast.error(
          <div className="toast-notification">
            <div className="notification-txt">{toastMesssage}</div>
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        )
      : toast.success(
          <div className="toast-notification">
            <div className="notification-txt">{toastMesssage}</div>
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            icon: () => <img src={checkedIcon} alt="emptydata" />,
          }
        );
  };
  createTitleSuccess = (responseJson: any) => {
    this.currentJobTitle.id = responseJson.data.attributes.id;
    this.showDialogTitle(false);
    this.fetchTitleList();
  };
  editTitleSuccess = (responseJson: any) => {
    this.currentJobTitle.id = responseJson.id;
    this.showDialogTitle(false);
    this.fetchTitleList();
  };
  createTeamApiResponse = (responseJson: any) => {
    if (responseJson.created_teams.length > 0) {
      this.currentTeam.title = responseJson.created_teams[0];
      this.showDialogTeam(false);
      this.fetchTeams();
    } else {
      const msg: string = responseJson.failed_teams;
      this.setErrorTeam(msg);
    }
  };
  editTeamSuccess = (responseJson: any) => {
    this.currentTeam.title = responseJson.title;
    this.currentTeam.id = responseJson.id;
    this.showDialogTeam(false);
    this.fetchTeams();
  };
  deleteTeamSuccess = (responseJson: any) => {
    if (this.state.team && this.state.deleteTeamId == this.state.team.id) {
      this.setState({ team: null });
    }
    if (this.state.editMember.rowIndex) {
      this.setState({
        editMember: {
          ...this.state.editMember,
          team: null,
        },
      });
    }
    this.setState({ deleteTeamId: "" });
    this.fetchTeams();
    this.handleClose();
    this.createToastNotification("Team deleted Successfully");
  };
  inviteMemberApiResponse = (responseJson: any) => {
    if (responseJson.message.added.length) {
      this.setState({ addedMembers: [] });
      const ids = responseJson.message.added.map((item :string)=>{
          const match = item.match(/\(ID: (\d+)\)/);
          return match ? parseInt(match[1], 10) : null;
      });
      this.setState({ responseAddedMembers: ids },() => {this.handleOpenCart()});
    } else {
      this.createToastNotification("Member already exists", true);
      
    }
  };
  deleteJobTitleResponse = () => {
    if (
      this.state.jobTitle &&
      this.state.deleteJobTitleId == this.state.jobTitle.id
    ) {
      this.setState({ jobTitle: null });
    }
    if (this.state.editMember.rowIndex) {
      this.setState({
        editMember: {
          ...this.state.editMember,
          jobTitle: null,
        },
      });
    }
    this.setState({ deleteJobTitleId: "" });
    this.fetchTitleList();
    this.handleClose();
    this.createToastNotification("Title deleted Successfully");
  };
  emailValidationResponse = (responseJson: any) => {
    if (responseJson.message[0].status) {
      if (this.state.editMember.rowIndex) {
        this.setState({
          editError: {
            ...this.state.editError,
            email: "Email already exists",
          },
          editEmailError: true,
        });
      } else {
        this.setState({
          error: {
            ...this.state.error,
            email: "Email already exists",
          },
          emailError: true,
        });
      }
    } else {
      if (this.state.editMember.rowIndex) {
        this.setState({
          editError: {
            ...this.state.editError,
            email: "",
          },
          editEmailError: false,
        });
      } else {
        this.setState({
          error: {
            ...this.state.error,
            email: "",
          },
          emailError: false,
        });
      }
    }
  };
  checkTitleError = (responseJson: any, apiRequestCallId: string) => {
    if (
      apiRequestCallId === this.createTitleApiCallId ||
      apiRequestCallId === this.editTitleApiCallId
    ) {
      const msg: string = responseJson.errors[0].message;
      if (msg.includes("already exists")) {
        this.setErrorTitle("Job title already exists");
      } else {
        this.setErrorTitle(msg);
      }
    }
  };
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.setState({ isLoading: false });
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.error) {
          this.setState({ isLoading: false });
          return;
        } else if (apiRequestCallId === this.inviteMemberApiCallId) {
          this.inviteMemberApiResponse(responseJson);
        } else if (!responseJson.errors) {
          switch (apiRequestCallId) {
            case this.fetchTeamApiCallId:
              this.setState({
                teams: responseJson.data,
              });
              break;
            // case this.fetchMembersApiCallId:
            //   this.setState({
            //     members: responseJson.data,
            //   });
            //  break;

            case this.fetchTitleListApiCallId:
              this.setState({
                jobTitles: responseJson.data,
              });
              break;
            case this.createTitleApiCallId:
              this.createTitleSuccess(responseJson);
              break;
            case this.editTitleApiCallId:
              this.editTitleSuccess(responseJson);
              break;
            case this.createTeamApiCallId:
              this.createTeamApiResponse(responseJson);
              break;
            case this.editTeamApiCallId:
              this.editTeamSuccess(responseJson);
              break;
            case this.deleteTeamAPICallId:
              this.deleteTeamSuccess(responseJson);
              break;
            case this.deleteJobTitleAPICallId:
              this.deleteJobTitleResponse();

              break;
            case this.emailValidateApiCallId:
              this.emailValidationResponse(responseJson);
              break;
          }
        } else {
          this.checkTitleError(responseJson, apiRequestCallId);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
  }

  checkPrevStateTeams = (prevState: S) => {
    if (prevState.teams !== this.state.teams) {
      if (this.currentTeam.id !== 0) {
        const curTeam = this.state.teams.find(
          (x) => x.id == this.currentTeam.id
        );

        if (this.state.editMember.rowIndex) {
          this.setState({
            editMember: {
              ...this.state.editMember,
              team: {
                id: curTeam.id,
                title: curTeam.attributes.title,
              },
            },
          });
        } else {
          this.setState({
            team: {
              id: curTeam.id,
              title: curTeam.attributes.title,
            },
          });
        }
      } else if (this.currentTeam.title !== "") {
        const curTeam = this.state.teams.find(
          (x) => x.attributes.title == this.currentTeam.title
        );
        if (this.state.editMember.rowIndex) {
          this.setState({
            editMember: {
              ...this.state.editMember,
              team: {
                id: curTeam.id,
                title: curTeam.attributes.title,
              },
            },
          });
        } else {
          this.setState({
            team: {
              id: curTeam.id,
              title: curTeam.attributes.title,
            },
          });
        }
      }
      this.currentTeam = { id: 0, title: "" };
    }
  };
  checkPrevStateTitles = (prevState: S) => {
    if (prevState.jobTitles !== this.state.jobTitles) {
      if (this.currentJobTitle.id !== 0) {
        const curTitle = this.state.jobTitles.find(
          (x) => x.id == this.currentJobTitle.id
        );
        if (this.state.editMember.rowIndex) {
          this.setState({
            editMember: {
              ...this.state.editMember,
              jobTitle: {
                id: curTitle.id,
                title: curTitle.attributes.title,
              },
            },
          });
        } else {
          this.setState({
            jobTitle: {
              id: curTitle.id,
              title: curTitle.attributes.title,
            },
          });
        }
      }
      this.currentJobTitle = { id: 0 };
    }
  };
  checkTeamAndTitle = (prevState: S) => {
    this.checkPrevStateTeams(prevState);
    this.checkPrevStateTitles(prevState);
  };
  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.checkTeamAndTitle(prevState);
    if (prevState.fName !== this.state.fName) {
      this.setState({
        error: {
          ...this.state.error,
          fName: "",
        },
      });
    }

    if (prevState.lName !== this.state.lName) {
      this.setState({
        error: {
          ...this.state.error,
          lName: "",
        },
      });
    }
    if (prevState.email !== this.state.email) {
      this.setState({
        error: {
          ...this.state.error,
          email: "",
        },
        emailError: false,
      });
    }
    if (prevState.team !== this.state.team) {
      this.setState({
        error: {
          ...this.state.error,
          team: "",
        },
      });
    }
    if (prevState.role !== this.state.role) {
      this.setState({
        error: {
          ...this.state.error,
          role: "",
        },
      });
    }
    if (prevState.jobTitle !== this.state.jobTitle) {
      this.setState({
        error: {
          ...this.state.error,
          jobTitle: "",
        },
      });
    }
    if (prevState.hourlyPay !== this.state.hourlyPay) {
      this.setState({
        error: {
          ...this.state.error,
          hourlyPay: "",
        },
      });
    }
    if (prevState.editMember.fName !== this.state.editMember.fName) {
      this.setState({
        editError: {
          ...this.state.editError,
          fName: "",
        },
      });
    }

    if (prevState.editMember.lName !== this.state.editMember.lName) {
      this.setState({
        editError: {
          ...this.state.editError,
          lName: "",
        },
      });
    }
    if (prevState.editMember.email !== this.state.editMember.email) {
      this.setState({
        editError: {
          ...this.state.editError,
          email: "",
        },
        emailError: false,
      });
    }
    if (prevState.editMember.team !== this.state.editMember.team) {
      this.setState({
        editError: {
          ...this.state.editError,
          team: "",
        },
      });
    }
    if (prevState.editMember.role !== this.state.editMember.role) {
      this.setState({
        editError: {
          ...this.state.editError,
          role: "",
        },
      });
    }
    if (prevState.editMember.jobTitle !== this.state.editMember.jobTitle) {
      this.setState({
        editError: {
          ...this.state.editError,
          jobTitle: "",
        },
      });
    }
    if (prevState.editMember.hourlyPay !== this.state.editMember.hourlyPay) {
      this.setState({
        editError: {
          ...this.state.editError,
          hourlyPay: "",
        },
      });
    }
  }

  editClickHandler = (val: any) => {
    return (event: React.MouseEvent<HTMLLIElement>) => {
      this.editOnClick(val);
    };
  };

  teamChangeHandler = (e: any, newValue: any) => {
    this.setState({ team: newValue });
  };
  editTeamChangeHandler = (e: any, newValue: any) => {
    this.setState({
      editMember: {
        ...this.state.editMember,
        team: newValue,
      },
    });
  };
  titleChangeHandler = (e: any, newValue: any) => {
    this.setState({
      jobTitle: newValue,
    });
  };
  editTitleChangeHandler = (e: any, newValue: any) => {
    this.setState({
      editMember: {
        ...this.state.editMember,
        jobTitle: newValue,
      },
    });
  };
  editOnClick = (val: any) => {
    this.setState({
      editMember: {
        fName: val.first_name,
        lName: val.last_name,
        email: val.email,
        team: val.team,
        hourlyPay: val.hourly_pay,
        role: val.job_type,
        jobTitle: val.jobTitle,
        id: 0,
        rowIndex: val.rowIndex,
      },
    });
  };
  onFocusHourlyPay = () => {
    this.setState({
      houryPayFocused: true,
    });
  };
  onBlurHourlyPay = () => {
    this.setState({
      houryPayFocused: false,
    });
  };
  handleBack = () => {
    this.setState({isSubscriptionModal:true})
  };
  handleEditChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState({
      editMember: {
        ...this.state.editMember,
        [name]: value,
      },
    });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof S;
    const value = event.target.value;
    this.setState(({ [name]: value } as unknown) as Pick<S, keyof S>);
  };
  validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.exec(email);
  };
  handleBlur = () => {
    if (!this.state.editMember.rowIndex) {
      if (this.state.email !== "") {
        if (!this.validateEmail(this.state.email)) {
          this.setState({
            error: {
              ...this.state.error,
              email: "Invalid email",
            },
            emailError: true,
          });
          return;
        }
      }
    } else {
      if (!this.validateEmail(this.state.editMember.email)) {
        this.setState({
          editError: {
            ...this.state.editError,
            email: "Invalid email",
          },
          editEmailError: true,
        });
        return;
      }
    }

    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emailValidateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webEmailValidateEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        email: this.state.editMember.rowIndex
          ? this.state.editMember.email
          : this.state.email,
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodPost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  checkErrors = () => {
    let errorFlag = this.state.emailError;
    let error = this.state.error;
    if (this.state.fName.trim() === "") {
      error.fName = `${this.t("Please enter a first name")}`;
      errorFlag = true;
    }
    if (this.state.lName.trim() === "") {
      error.lName = `${this.t("Please enter a last name")}`;
      errorFlag = true;
    }
    if (this.state.email.trim() === "") {
      error.email = `${this.t("Please enter an email")}`;
      errorFlag = true;
    }
    if (this.state.team === null) {
      error.team = `${this.t("Please select a team")}`;
      errorFlag = true;
    }
    if (this.state.role.trim() === "") {
      error.role = `${this.t("Please select a job role")}`;
      errorFlag = true;
    }
    if (this.state.jobTitle === null) {
      error.jobTitle = `${this.t("Please select or create a job title")}`;
      errorFlag = true;
    }
    if (!this.state.hourlyPay) {
      error.hourlyPay = `${this.t("Please enter the hourly pay")}`;
      errorFlag = true;
    }
    if (errorFlag) {
      this.setState({ error: error });
      return true;
    }
    return false;
  };
  handleFinish = () => {
    let currentAddedMembers = this.state.addedMembers.map(
      ({
        jobTitle,
        team,
        teamTitle,
        rowIndex,
        jobtypeName,
        job_type,
        ...keepAttrs
      }) => {
        return { job_type: Number(job_type), ...keepAttrs };
      }
    );
    let hasValue = false;
    if (
      this.state.fName !== "" ||
      this.state.lName !== "" ||
      this.state.email !== "" ||
      this.state.team !== null ||
      this.state.role !== "" ||
      this.state.jobTitle !== null ||
      this.state.hourlyPay
    ) {
      hasValue = true;
    }
    if (currentAddedMembers.length == 0 || hasValue) {
      let errorFlag = this.checkErrors();
      if (errorFlag) {
        return;
      }
      this.rowIndex += 1;
      currentAddedMembers = [
        ...currentAddedMembers,
        {
          first_name: this.state.fName,
          last_name: this.state.lName,
          email: this.state.email,
          choose_team: [this.state.team.id],
          job_type: Number(this.state.role),
          job_role: this.state.jobTitle.title,
          hourly_pay: this.state.hourlyPay,
          activated: "true",
        },
      ];
      this.setState({
        addedMembers: [
          ...this.state.addedMembers,
          {
            rowIndex: this.rowIndex,
            first_name: this.state.fName,
            last_name: this.state.lName,
            email: this.state.email,
            choose_team: [this.state.team.id],
            teamTitle: this.state.team.title,
            team: this.state.team,
            job_type: this.state.role,
            jobtypeName:
              Number(this.state.role) == 0
                ? "Owner"
                : Number(this.state.role) == 1
                ? "Manager"
                : "Employee",
            job_role: this.state.jobTitle.title,
            jobTitle: this.state.jobTitle,
            hourly_pay: this.state.hourlyPay,
            activated: "true",
          },
        ],
        fName: "",
        lName: "",
        email: "",
        team: null,
        jobTitle: null,
        hourlyPay: null,
        role: "",
      });
    }

    if (currentAddedMembers.length == 0) return;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.inviteMemberApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webInviteMemberListEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          type: "EmailAccount",
          attributes: currentAddedMembers,
          device_type: "web",
        },
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodPost
    );
    this.setState({ isLoading: true });
    this.setState({currentTotalAddedMembers:currentAddedMembers})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  addMembers = () => {
    let errorFlag = this.checkErrors();
    if (errorFlag) {
      return;
    }
    this.rowIndex += 1;
    const newMember ={
      rowIndex: this.rowIndex,
      first_name: this.state.fName,
      last_name: this.state.lName,
      email: this.state.email,
      choose_team: [this.state.team.id],
      teamTitle: this.state.team.title,
      team: this.state.team,
      job_type: this.state.role,
      jobtypeName:
        Number(this.state.role) == 0
          ? "Owner"
          : Number(this.state.role) == 1
          ? "Manager"
          : "Employee",
      job_role: this.state.jobTitle.title,
      jobTitle: this.state.jobTitle,
      hourly_pay: this.state.hourlyPay,
      activated: "true",
      // account_block_teams_id: this.state.team.id,
    }
    const updatedMembers = [...this.state.addedMembers, newMember]
    updatedMembers.sort((a,b) => Number(a.job_type) - Number(b.job_type))
    this.setState({
      addedMembers:updatedMembers,
      fName: "",
      lName: "",
      email: "",
      team: null,
      jobTitle: null,
      hourlyPay: null,
      role: "",
    });
  };

  deleteMember = (data: any) => {
    this.setState({ isDeletingMember: true });
  };

  deleteMemberFromList = () => {
    this.setState({
      addedMembers: this.state.addedMembers.filter(
        (x) => x.rowIndex != this.state.editMember.rowIndex
      ),
    });
    this.createToastNotification("Member deleted Successfully");
    this.setState({
      editMember: {
        fName: "",
        lName: "",
        email: "",
        team: "",
        hourlyPay: "",
        role: "",
        jobTitle: "",
        id: "",
        rowIndex: "",
      },
    });
  };

  updateMembers = () => {
    let errorFlag = this.state.editEmailError;
    let error = this.state.editError;
    if (this.state.editMember.fName.trim() === "") {
      error.fName = `${this.t("Please enter a first name")}`;
      errorFlag = true;
    }
    if (this.state.editMember.lName.trim() === "") {
      error.lName = `${this.t("Please enter a last name")}`;
      errorFlag = true;
    }
    if (this.state.editMember.email.trim() === "") {
      error.email = `${this.t("Please enter an email")}`;
      errorFlag = true;
    }
    if (this.state.editMember.team === null) {
      error.team = `${this.t("Please select a team")}`;
      errorFlag = true;
    }
    if (this.state.editMember.role.trim() === "") {
      error.role = `${this.t("Please select a job role")}`;
      errorFlag = true;
    }
    if (this.state.editMember.jobTitle === null) {
      error.jobTitle = `${this.t("Please select or create a job title")}`;
      errorFlag = true;
    }
    if (this.state.editMember.hourlyPay === null) {
      error.hourlyPay = `${this.t("Please enter the hourly pay")}`;
      errorFlag = true;
    }
    if (errorFlag) {
      this.setState({ editError: error });
      return;
    }
    let allMembers = this.state.addedMembers;
    let curMember = allMembers.find(
      (x) => (x.rowIndex = this.state.editMember.rowIndex)
    );
    if (curMember) {
      curMember.first_name = this.state.editMember.fName;
      curMember.last_name = this.state.editMember.lName;
      curMember.email = this.state.editMember.email;
      curMember.choose_team = [this.state.editMember.team.id];
      curMember.teamTitle = this.state.editMember.team.title;
      curMember.team = this.state.editMember.team;
      curMember.job_type = this.state.editMember.role;
      curMember.jobtypeName =
        Number(this.state.editMember.role) == 0
          ? "Owner"
          : Number(this.state.editMember.role) == 1
          ? "Manager"
          : "Employee";
      curMember.job_role = this.state.editMember.jobTitle.title;
      curMember.jobTitle = this.state.editMember.jobTitle;
      curMember.hourly_pay = this.state.editMember.hourlyPay;
    }
    this.setState({
      editMember: {
        fName: "",
        lName: "",
        email: "",
        team: "",
        hourlyPay: "",
        role: "",
        jobTitle: "",
        id: "",
        rowIndex: "",
      },
      addedMembers: allMembers,
    });
  };
  deleteTeam = (data: any) => {
    this.setState({ isDeletingTeam: true, deleteTeamId: data.id });
  };
  deleteTeamFromList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body = {
      id: this.state.deleteTeamId,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTeamAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webDeleteTeamsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodDelete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteJobTitle = (data: any) => {
    this.setState({ isDeleting: true, deleteJobTitleId: data.id });
  };
  deleteJobTitleFromList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body = {
      id: this.state.deleteJobTitleId,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteJobTitleAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webDeleteJobTitleEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchTeams = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchTeamApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webTeamsGetEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchTitleList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchTitleListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webTitleListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addJobTitle = (data: any, setError?: any, setShowDialog?: any) => {
    this.setErrorTitle = setError;
    this.showDialogTitle = setShowDialog;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (data.id != null) {
      this.editTitleApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.webUpdateJobTitleEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            id: data.id,
            title: data.title,
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.requestMethodPatch
      );
    } else {
      this.createTitleApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.webCreateJobTitleEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            title: data.title,
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getMethodPost
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addEditTeam = (data: any, setError?: any, setShowDialog?: any) => {
    this.setErrorTeam = setError;
    this.showDialogTeam = setShowDialog;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (data.id != null) {
      this.editTeamApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.webUpdateTeamsEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            id: data.id,
            title: data.title,
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.requestMethodPatch
      );
    } else {
      this.createTeamApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.webcreateTeamsEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            title: [data.title],
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getMethodPost
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleOpenCart = () => {
    this.setState({ isCartOpen: true });
  };
  handleCloseCart = () => {
    this.setState({ isCartOpen: false });
  };

  handleCloseCartEvent=()=>{
    this.setState({
    isSubscriptionModal:false  
    })
  }
  onBack =()=>{
    this.props.navigation.navigate("AccountCreation");
  }
}
// Customizable Area End
