import React from "react";

import {
  Box,
  Button,
  Container,
  FormControl,
  TextField,
  Typography,
  // Customizable Area Start
  Popover,
  InputAdornment,
  Modal,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  List,
  ListItem
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import TicketSystemController, {
  configJSON,
  Props,
} from "./TicketSystemController.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import { appStore, attach, download, bitmap1, bitmap2, bitmap3, close, frame, googlePlay, mobile, screenshot, sortby, success, uploadImg } from "./assets";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "../../../components/src/TicketSyatemHelper.web";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileDrop } from "react-file-drop";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import WebLoader from "../../../components/src/WebLoader.web";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import ImageSlider from "../../../components/src/ImageSliderComponet.web";
import Autocomplete from "@material-ui/lab/Autocomplete";
// Customizable Area End

export class TicketSystem extends TicketSystemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { singleTicketData } = this.state;

    const remainingTickets = this.state.ticketList.length - this.state.visibleItems;
    const message = remainingTickets === 1
      ? `1 ${this.t(configJSON.moreTicket)}`
      : `${remainingTickets} ${this.t(configJSON.moreTickets)}`;
    const renderViewSingleTicketModal = () => (
      <Modal
        className={classes.modalViewDialog}
        aria-labelledby="modal-modal-title"
        onClose={this.handleCloseViewSingleTicket}
        open={this.state.viewSingleTicket}
        aria-describedby="modal-modal-description"
      >
        <Box className={`view-modal-dialog ${classes.modalViewDialogBox}`}>
          <Box className="modal-content">
            <Box className="ticket-content-box">
              <Box className="heading-right">
                <Box className="sort-by-txt">
                  <Box className={`container ${classes.carouselWrapper}`} >
                    <ImageSlider />
                    <Box className="download-action">
                      <Box
                        className="download-icon"
                        onClick={this.handleCloseViewSingleTicket}
                        data-test-id="download"
                      >
                        <img src={download} alt="download" />
                      </Box>
                      <Box
                        className="close-icon"
                        onClick={this.handleCloseViewSingleTicket}
                        data-test-id="handleCloseViewSingleTicket"
                      >
                        <img src={close} alt="closeGray" />
                      </Box>
                    </Box>
                  </Box>

                  <Box className="main-container">
                    <Box className="main-content-txt">
                      <Box component="p" className="content-sub-txt">
                        #{singleTicketData.id}
                      </Box>
                      <Box component="p" className="content-sub-txt content-dots">
                        {this.formatTicketDate(singleTicketData.date_submitted)}
                      </Box>
                      <Box component="p" className="content-sub-txt content-dots">
                        {singleTicketData.category}
                      </Box>
                    </Box>
                    <Box className="main-content-info">
                      <Typography className="title" component="h2">
                        {singleTicketData.name}
                      </Typography>
                      <Box className={`ticket-system-status ${singleTicketData.status}`}>
                        {singleTicketData.status}
                      </Box>
                    </Box>
                    <Typography className="content" component="h2">
                      {singleTicketData.description}
                    </Typography>
                    <Box className="user-name">
                      <Box className="avatar">
                        <Typography className="short-name">
                          {singleTicketData.ticket_created_by?.charAt(0).toUpperCase()}
                        </Typography>
                        <Typography className="name">
                          {singleTicketData.ticket_created_by}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="button-action">
                      {singleTicketData.status !== "Resolved" &&
                        <>
                          <Button className={`secondary-btn ${classes.secondaryButton}`} data-test-id="goToChat" onClick={this.goToChat}>{this.t(`${configJSON.goToChat}`)}</Button>
                          <Button className={`primary-btn ${classes.primaryButton}`}
                            data-test-id="markAsResolved"
                            onClick={this.markAsResolved}>
                            {this.t(`${configJSON.markAsResolved}`)}
                          </Button>
                        </>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    );

    const renderNewTicketModal = () => (
      <Modal
        className={classes.modalCreateDialog}
        aria-labelledby="modal-modal-title"
        onClose={this.handleNewTicketClose}
        open={this.state.openNewTicket}
        aria-describedby="modal-modal-description"
      >
        <Box className={`view-modal-dialog ${classes.modalCreateDialogBox}`}>
          <Box className="modal-content">
            <Box className="modal-wrapper">
              <Typography className="modal-headding">{this.t(`${configJSON.raiseTicket}`)}</Typography>
              <Box
                className="close-icon"
                onClick={this.handleNewTicketClose}
                data-test-id="handleNewTicketClose"
              >
                <img src={close} alt="closeGray" />
              </Box>
            </Box>
            <Typography className="desc">{this.t(`${configJSON.raiseTicketSubTxt}`)}</Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <CustomInputWeb
                  type="text"
                  label={this.t(`${configJSON.ticketTitleTxt}`)}
                  value={this.state.ticketTitle}
                  name="ticketTitle"
                  onChange={this.handleInputChange}
                  data-test-id="ticket-title"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  className="select-outer"
                  variant="outlined"
                >
                  <InputLabel>
                    {this.t(`${configJSON.category}`)}
                  </InputLabel>
                  <Select
                    value={this.state.category}
                    name="category"
                    onChange={this.handleInputChange}
                    label={this.t(`${configJSON.category}`)}
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: { paper: classes.dropdownStyle },
                    }}
                    data-test-id="category"
                  >
                    {configJSON.categoryList.map((unit: any) => {
                      return (
                        <MenuItem value={unit.id} key={unit.id}>{this.t(`${unit.name}`)}</MenuItem>
                      )
                    })}

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <CustomInputWeb
                  type="text"
                  label={this.t(`${configJSON.description}`)}
                  isMultiline
                  rows={4}
                  value={this.state.description}
                  name="description"
                  onChange={this.handleInputChange}
                  data-test-id="description"
                />
              </Grid>
              <Grid item xs={12}>
                <FileDrop>
                  <Box
                    className={`${classes.fileUploadWrapper}`}
                    onClick={this.onBrowse}
                    data-test-id="onBrowse"
                  >
                    <Box className="upload-icon">
                      <img src={uploadImg} alt="uploadImg" />
                    </Box>
                    <form ref={this.formRef}>
                      <input
                        name="upload-photo"
                        // accept=".jpg, .jpeg, .png, .mp4"
                        multiple
                        type="file"
                        onChange={(e) => {
                          if (e.preventDefault) {
                            e.preventDefault();
                          }
                          this.handleOnDrop(e.target.files);
                        }}
                        hidden
                        ref={this.fileRef}
                        data-test-id="fileInput"
                      />
                    </form>
                    <Box className="file-upload-block">
                      <Typography className="file-heading" variant="h2">
                        {this.t(`${configJSON.dropToUpload}`)}
                      </Typography>
                      <Box component="p" className="file-subtxt">
                        {this.t(`${configJSON.or}`)}
                        <Box component="span" className="browse-link">
                          {this.t(`${configJSON.browse}`)}
                        </Box>
                        {this.t(`${configJSON.toChooseFile}`)}
                      </Box>
                    </Box>
                    <Box className="file-upload-sub-headding">{this.t(`${configJSON.addScreenshottxt}`)}</Box>
                  </Box>
                </FileDrop>
                {this.state.isLoading ? (
                  <Box className={classes.uploadProgress}>
                    <Box
                      className="progress-status"
                      style={{ width: `${this.state.progress}%` }}
                    ></Box>
                    <Box className="progress-row">
                      <Box className="left-col">
                        <Typography variant="h2" className="progress-heading">
                          {this.t(`${configJSON.uploading}`)}
                        </Typography>
                        <Box className="progress-subtxt" component="p">
                          <Box className="completed" component="span">
                            {this.state.progress}%
                          </Box>
                          <Box className="remaining" component="span">
                            {this.state.timeRemain} {this.t(`${configJSON.secRemaining}`)}
                          </Box>
                        </Box>
                      </Box>
                      <Box className="right-col">
                        <Link
                          className="play-pause"
                          to="#"
                          data-test-id="pause-Resume-Upload"
                          onClick={this.pauseUpload}
                        >
                          {this.state.isPause ? (
                            <PlayCircleOutlineIcon />
                          ) : (
                            <PauseCircleOutlineIcon />
                          )}
                        </Link>
                        <Link
                          className="close"
                          to="#"
                          data-test-id="close"
                          onClick={this.cancelUploadAction}
                        >
                          <CloseIcon />
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
                {!this.state.isLoading && this.state.filesUploaded.length > 0 && (
                  <List className={classes.uploadFileList}>
                    {this.state.filesUploaded.map((file: any, index: any) => (
                      <ListItem key={file.name}>
                        <Box className="file-info">
                          <Box className="file-name">{file.name}</Box>
                          <Link
                            className="close"
                            to="#"
                            data-test-id="removeFileBtn"
                            onClick={() => this.cancelParsingAction(index)}
                          >
                            <CloseIcon />
                          </Link>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Grid>
            </Grid>
            <Button className={`primary-btn ${classes.primaryButton}`} data-test-id="create-ticket" onClick={this.handleSubmitTicket}>{this.t(`${configJSON.submit}`)}</Button>
          </Box>
        </Box>
      </Modal>
    );

    const renderFiltersModal = () => (
      <Modal
        className={classes.modalFilterDialog}
        aria-labelledby="modal-modal-title"
        onClose={this.handleFiltersClose}
        open={this.state.onFilters}
        aria-describedby="modal-modal-description"
      >
        <Box className={`view-modal-dialog ${classes.modalFilterDialogBox}`}>
          <Box className="modal-content">
            <Box className="modal-wrapper">
              <Box>
                <Typography className="modal-headding">{this.t(`${configJSON.filters}`)}</Typography>
              </Box>
              <Box className="reset-cross">
                <Box
                  className="reset-txt"
                  onClick={this.resetFilters}
                  data-test-id="resetFilters"
                >
                  {this.t(`${configJSON.reset}`)}
                </Box>
                <Box
                  className="close-icon"
                  onClick={this.handleFiltersClose}
                  data-test-id="handleFiltersClose"
                >
                  <img src={close} alt="closeGray" />
                </Box>
              </Box>
            </Box>
            <Typography className="desc">{this.t(`${configJSON.adjustFilters}`)}</Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className="filter-headding">{this.t(`${configJSON.category}`)}</Typography>
                <FormControl
                  className="select-outer"
                  variant="outlined"
                >
                  <InputLabel>
                    {this.t(`${configJSON.chooseCategory}`)}
                  </InputLabel>
                  <Select
                    value={this.state.categoryFilter}
                    name="categoryFilter"
                    onChange={this.handleFiltersInputChange}
                    label={this.t(`${configJSON.chooseCategory}`)}
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: { paper: classes.dropdownStyle },
                    }}
                    data-test-id="category-filter"
                  >
                    {configJSON.categoryList.map((unit: any) => {
                      return (
                        <MenuItem value={unit.id} key={unit.id}>{this.t(`${unit.name}`)}</MenuItem>
                      )
                    })}

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography className="filter-headding">{this.t(`${configJSON.dateSubmited}`)}</Typography>
                <CustomDatePicker
                  range={true}
                  placeholder={this.t(`${configJSON.chooseDate}`)}
                  value={this.state.date}
                  currentDate=""
                  onChange={this.chooseDate}
                  onOpenPickNewDate={false}
                  t={this.t}
                  data-test-id="date"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="filter-headding">{this.t(`${configJSON.status}`)}</Typography>
                <FormControl
                  className="select-outer"
                  variant="outlined"
                >
                  <InputLabel>
                    {this.t(`${configJSON.status}`)}
                  </InputLabel>
                  <Select
                    value={this.state.status}
                    name="status"
                    onChange={this.handleFiltersInputChange}
                    label={this.t(`${configJSON.status}`)}
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: { paper: classes.dropdownStyle },
                    }}
                    data-test-id="status"
                  >
                    {configJSON.statusList.map((unit: any) => {
                      return (
                        <MenuItem value={unit.id} key={unit.id}>{unit.name}</MenuItem>
                      )
                    })}

                  </Select>
                </FormControl>
                <Button className={`primary-btn2 ${classes.primaryButton}`} data-test-id="applyFilters" onClick={this.applyFilters}>{this.t(`${configJSON.applyFilters}`)}</Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    );

    const renderSuccessModal = () => (
      <Modal
        className={classes.modalFilterDialog}
        aria-labelledby="modal-modal-title"
        onClose={this.handleSuccessClose}
        open={this.state.isSuccess}
        aria-describedby="modal-modal-description"
      >
        <Box className={`view-modal-dialog ${classes.modalFilterDialogBox}`}>
          <Box className="modal-content">
            <Box className="modal-wrapper-success">
              <Box
                className="close-icon"
                onClick={this.handleSuccessClose}
                data-test-id="handleFiltersClose"
              >
                <img src={close} alt="closeGray" />
              </Box>
            </Box>
            <Box>
              <img src={success} alt="closeGray" />
            </Box>
            <Typography className="headding">{this.t(`${configJSON.requestSubmited}`)}</Typography>
            <Typography className="success-desc">{this.t(`${configJSON.requestSubtxt}`)}</Typography>
            <Button className={`primary-btn-back ${classes.primaryButton}`} data-test-id="back-btn-id" onClick={this.backToHelpCenter}>{this.t(`${configJSON.backTohelpCenter}`)}</Button>
          </Box>
        </Box>
      </Modal>
    );
    const renderSearchModal = () =>{
      return  <>
      {this.state.ticketList.length != 0 &&                
        <Box className="search-wrapper">
          <Box className="search-box">
            <CustomInputWeb
              type="search"
              value={this.state.searchValue}
              label=""
              placeholder={this.t(`${configJSON.searchPlaceholder}`)}
              onChange={(event: any) => this.handleSearch(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "#225980" }} />
                </InputAdornment>
              }
              data-test-id="searchfield"
            />
            <Box className="sort-by" data-test-id="handleFiltersOPen" onClick={this.handleFiltersOPen}>
              <img src={sortby} alt="bg-image" className="filter-image" />
            </Box>
          </Box>
        </Box>
        } 
      </>
    }
    const renderViewAllTxt = () =>{
      return <>
        {this.state.ticketList.length != 0 &&
         <Box component="p" className="view-all-txt" data-test-id="handleOpenViewAllTickets" onClick={this.handleOpenViewAllTickets}>
          {this.t(`${configJSON.viewAlltext}`)}
         </Box>
        }
      </>
    }
    // Merge Engine - render - Start
    return (
      <>
        {this.state.isLoading && <WebLoader />}
        <Box className={classes.ticketSystemRoot}>
          <Box className="header-content">
            <Box className="back-btn-content" data-test-id="handleBackBtn" onClick={this.handleBackBtn}>
              <ArrowBackIcon style={{ color: "#2B2B2B" }} />
              <Typography className="back-txt">{this.t(`${configJSON.backText}`)}</Typography>
            </Box>
            <Box className="live-chat-box">
              <ChatBubbleOutlineIcon className="chat-icon" />
              <Typography className="live-btn-txt">{this.t(`${configJSON.liveChatText}`)}</Typography>
            </Box>
          </Box>
          <Box className="iframe-view">
            <iframe
              src="https://etoh.freshdesk.com/support/home"
              title="iframe-view"
              width="100%"
              height="500px"
              style={{ border: "none" , display: "block"}}
              allow="fullscreen"
              data-test-id="iframe-view"
            ></iframe>
            <Box className="view-ticket-wrapper">
              <Button
                className="btn-view-ticket"
                data-test-id="btn-view-ticket"
                onClick={this.handleOpenMyTicket}
              >
                {this.t(`${configJSON.myTicketsText}`)}
              </Button>
              <Popover
                open={Boolean(this.state.anchorEl)}
                anchorEl={this.state.anchorEl}
                onClose={this.handleCloseMyTicket}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                classes={{ paper: `${classes.viewTicketBox}` }}
                style={{ bottom: "100px" }}
              >
                <Box className="view-ticket-heading">
                  <Box className="heading-left">
                    <Typography className="modal-title" component="h2">
                      {this.t(`${configJSON.myTicketsText}`)}
                    </Typography>
                    <Box component="p" className="sub-txt">
                      {this.t(`${configJSON.myTicketSubText}`)}
                    </Box>
                  </Box>
                  <Box className="heading-right">
                   {renderViewAllTxt()}
                    <Box
                      className="heading-icon"
                      onClick={this.handleCloseMyTicket}
                      data-test-id="handleCloseMyTicket"
                    >
                      <img src={close} alt="closeGray" />
                    </Box>

                  </Box>
                </Box>
                {renderSearchModal()}
                {
                  this.state.ticketList.length > 0 && this.state.ticketList?.slice(0, this.state.visibleItems).map((ticket: any) => {
                    return <Box className="main-container" key={ticket.id}>
                      <Box className="main-content-info">
                        <Box component="p" className="content-sub-txt">
                          {this.t(`${configJSON.ticketTxt}`)} #{ticket.id}  /  {this.formatTicketWithDateTime(ticket.attributes.date_submitted)}
                        </Box>
                        <Box className={`${ticket.attributes.status} ticket-system-status`}>
                          {ticket.attributes.status}
                        </Box>
                      </Box>
                      <Typography component="h2" className="title" data-test-id="handleOpenViewSingleTicket2" onClick={() => this.handleOpenViewSingleTicket(ticket.id)}>
                        {ticket.attributes.name}
                      </Typography>
                      <Typography component="h2" className="content">
                        {ticket.attributes.description}
                      </Typography>
                      <Box className="user-name">
                        <Box className="avatar">
                          <Typography className="short-name" component="h2">
                            {ticket.attributes.ticket_created_by?.charAt(0).toUpperCase()}
                          </Typography>
                          <Typography className="name" component="h2">
                            {ticket.attributes.ticket_created_by}
                          </Typography>
                        </Box>
                        <Box className="technical-team">
                          <Typography className="name" component="h2" >
                            {ticket.attributes.category}
                          </Typography>
                          <Typography className="name" component="h2">
                            <img src={attach} alt="attachments" className="attachment-icon" />
                          </Typography>
                          <Typography className="name" component="h2" >
                            2
                          </Typography>
                        </Box>

                      </Box>
                    </Box>
                  })
                }
                {
                  this.state.ticketList.length === 0 &&
                  <Box className="frame-img">
                    <img src={frame} alt="frame" />
                    <Box className="ticket-empty-view">
                      <Typography className="ticket-headding">
                        {this.t(`${configJSON.noTickets}`)}
                      </Typography>
                      <Typography className="ticket-sub-headding">
                        {this.t(`${configJSON.canIHelpYou}`)}
                      </Typography>
                    </Box>
                  </Box>
                }

                {this.state.visibleItems < this.state.ticketList?.length && (
                  <Typography className="more-ticket-txt" data-test-id="handleViewMore" onClick={this.handleViewMore}>
                    {message}
                  </Typography>
                )}
                <Button className={`primary-btn ${classes.primaryButton}`} data-test-id="handleNewTicket" onClick={this.handleNewTicket}>+ {this.t(`${configJSON.newTicket}`)}</Button>
              </Popover>
            </Box>
          </Box>
          <Modal
            className={classes.modalDialog}
            aria-labelledby="modal-modal-title"
            onClose={this.handleCloseViewAllTickets}
            open={this.state.viewAllTickets}
            aria-describedby="modal-modal-description"
          >
            <Box className={`view-modal-dialog ${classes.modalDialogBox}`}>
              <Box className="header-content">
                <Box className="back-btn-content" data-test-id="handleCloseViewAllTickets" onClick={this.handleCloseViewAllTickets}>
                  <ArrowBackIcon style={{ color: "#2B2B2B" }} />
                  <Typography className="back-txt">{this.t(`${configJSON.backText}`)}</Typography>
                </Box>
                <Box className="live-chat-box">
                  <ChatBubbleOutlineIcon className="chat-icon" />
                  <Typography className="live-btn-txt">{this.t(`${configJSON.liveChatText}`)}</Typography>
                </Box>
              </Box>
              <Box className="modal-content">
                <Typography className="modal-headding">{this.t(`${configJSON.myTicketsText}`)}</Typography>
                <Grid container spacing={8} className="ticket-content-box">
                  <Grid item xs={4}>
                    <Typography className="search-txt">{this.t(`${configJSON.searchContent}`)}</Typography>
                    <Typography className="search-sub-txt">{this.t(`${configJSON.subContent}`)}</Typography>
                    <Box className="search-content-textfield">
                      <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        options={this.state.searchOptions.map((option: any) => option.name)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            margin="normal"
                            variant="outlined"
                            placeholder={this.t(`${configJSON.startSearch}`)}
                            InputProps={{
                              ...params.InputProps, type: 'search', endAdornment: (
                                <InputAdornment position="end">
                                  <SearchIcon style={{ color: '#959595' }} />
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                        onChange={this.handleSearch}
                        data-test-id="search-field"
                        ListboxComponent={(props) => <ul {...props}>{React.Children.map(props.children, (child: any) => child && React.cloneElement(child, { style: { color: '#000000', background: '#fff' } }))}</ul>}
                      />
                    </Box>
                    <Button className={`primary-btn-new ${classes.primaryButton}`} onClick={this.handleNewTicket}>+ {this.t(`${configJSON.newTicket}`)}</Button>
                  </Grid>
                  <Grid item xs={8}>
                    <Box className="heading-right">
                      <Typography className="search-txt" component="h2">
                        {this.state.ticketList?.length} {this.t(`${configJSON.ticketsText}`)}
                      </Typography>
                      <Box className="sort-by-txt">
                        <Box className="sort-by-block">
                          <Box component="p" className="search-sub-txt">
                            {this.t(`${configJSON.myTicketSubText}`)}
                          </Box>
                          <FormControl className="select-control sortby-control">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                classes: { paper: classes.dropdownStyle },
                              }}
                              onChange={(e: any) => this.handleSort(e.target.value)}
                              data-test-id="sort-textfield"
                              displayEmpty
                              defaultValue={this.t(`${configJSON.sortByTxt}`)}
                              renderValue={(value: any) => {
                                return <Box>{value}</Box>;
                              }}
                              IconComponent={() => (
                                <ArrowDropDownIcon style={{ color: "rgb(120 118 118)" }} />
                              )}
                            >
                              <MenuItem value={this.t(`${configJSON.categoryTxt}`)}>{this.t(`${configJSON.categoryTxt}`)}</MenuItem>
                              <MenuItem value={this.t(`${configJSON.recentlyAdded}`)}>{this.t(`${configJSON.recentlyAdded}`)}</MenuItem>
                              <MenuItem value={this.t(`${configJSON.status}`)}>{this.t(`${configJSON.status}`)}</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                        {this.state.ticketList?.map((ticket: any) => {
                          return <Box key={ticket.id} className="main-container" data-test-id="handleOpenViewSingleTicket" onClick={() => this.handleOpenViewSingleTicket(ticket.id)}>
                            <Box className="main-content-info">
                              <Box component="p" className="content-sub-txt">
                                {this.t(`${configJSON.ticketTxt}`)} #{ticket.id}  /  {this.formatTicketWithDateTime(ticket.attributes.date_submitted)}
                              </Box>
                              <Box className={`ticket-system-status ${ticket.attributes.status}`}>
                                {ticket.attributes.status}
                              </Box>
                            </Box>
                            <Typography className="title" component="h2">
                              {ticket.attributes.name}
                            </Typography>
                            <Typography className="content" component="h2">
                              {ticket.attributes.description}
                            </Typography>
                            <Box className="user-name">
                              <Box className="avatar">
                                <Typography className="short-name">
                                  {ticket.attributes.ticket_created_by?.charAt(0).toUpperCase()}
                                </Typography>
                                <Typography className="name">
                                  {ticket.attributes.ticket_created_by}
                                </Typography>
                              </Box>
                              <Box className="technical-team">
                                <Typography className="name">
                                  {ticket.attributes.category}
                                </Typography>
                                <Typography className="name">
                                  <img src={attach} alt="attachment" className="attachment-icon" />
                                </Typography>
                                <Typography className="name">
                                  2
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        })}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Modal>
          <Box className="footer-view-top" />
          <Box className="footer-view">
            <img src={bitmap1} alt="mobile" className="bitmap1-img" />
            <img src={bitmap2} alt="bitmap" className="bitmap2-img" />
            <img src={bitmap3} alt="bitmap" className="bitmap3-img" />
            <Grid container>
              <Grid item xs={6}>
                <img src={mobile} alt="mobile" className="mobile-img" />
              </Grid>
              <Grid item xs={6} className="footer">
                <Box className="footer-content">
                  <Typography className="download-txt">{this.t(`${configJSON.downloadEtoh}`)}</Typography>
                  <Typography className="download-sub-txt">{this.t(`${configJSON.etohDis}`)}</Typography>
                  <Box className="button-content">
                    <a href="https://apps.apple.com/us/app/etoh-coach/id6444042680" target="_blank">
                      <Button
                        className={`footer-btn ${classes.footerButton}`}
                        startIcon={<img src={appStore} alt="bitmap" className="app-store-img" />}>
                        {this.t(`${configJSON.ethohIOS}`)}
                      </Button>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.EtOHCoachFinal" target="_blank">
                      <Button
                        className={`footer-google-btn ${classes.footerButton}`}
                        startIcon={<img src={googlePlay} alt="bitmap" className="app-store-img" />}>
                        {this.t(`${configJSON.ethohAndroid}`)}
                      </Button>
                    </a>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {this.state.viewSingleTicket && renderViewSingleTicketModal()}
          {this.state.openNewTicket && renderNewTicketModal()}
          {this.state.onFilters && renderFiltersModal()}
          {this.state.isSuccess && renderSuccessModal()}
        </Box>
      </>
      // Merge Engine - render - End
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(TicketSystem);
// Customizable Area End
