import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { producerData } from "../../../components/src/productData";
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  openProductsModal: boolean;
  closeProductsModal: () => void;
  selectedDealProducts: (choosenProducts:string[], totalAmount:number| null,choosenProductsData:[], productsQuantity: any) => void;
  chosenDealProductIds?:any;
  chosenQuantity?:any;
  classes?: any;
  topButtonTxt?:string;
  headingTxt?:string;
  headingSubTxt?:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openItemInfo: any;
  choosenProducts: any;
  choosenProductsData: any;
  allProductsData: any;
  productQuantities: any;
  totalAmount: number | null;
  selectAll: boolean;
  expanded: boolean;
  itemList: any;
  isLoading: boolean;
  producerDeals: any;
  searchProducerDeals: any;
  brandDeals:string;
  searchBrandDeals:string;
  colorDeals:string;
  searchColorDeals:string;
  vintageDeals:string;
  searchVintageDeals:string;
  appelation:string;
  searchAppellation:string;
  country: string;
  searchCountry:string;
  region: string;
  searchRegion:string;
  subRegion: string;
  searchSubRegion:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AssociatedProductsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllProductsAPICallId: string = "";
  userSessionStorageData: any;
  userToken: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      openItemInfo: this.props.chosenDealProductIds.length > 0 ? true : false,
      choosenProducts: this.props.chosenDealProductIds,
      allProductsData: [],
      choosenProductsData: [],
      productQuantities: this.props.chosenQuantity ? this.props.chosenQuantity : {},
      totalAmount: null,
      selectAll: false,
      expanded: false,
      itemList: producerData,
      isLoading: false,
      producerDeals: "",
      searchProducerDeals: "",
      brandDeals:"",
      searchBrandDeals:"",
      colorDeals:"",
      searchColorDeals:"",
      vintageDeals:"",
      searchVintageDeals:"",
      appelation:"",
      searchAppellation:"",
      country: "",
      searchCountry:"",
      region: "",
      searchRegion:"",
      subRegion: "",
      searchSubRegion:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.userSessionStorageData = sessionStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionStorageData);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
     // Customizable Area Start
     if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorResponseAPI = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const responseJsonAPI = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      

      if (errorResponseAPI || !responseJsonAPI || !apiRequestCallId) {
        this.setState({ isLoading: false });
        return;
      } 

      if(apiRequestCallId === this.getAllProductsAPICallId){
        this.setState({isLoading : false})
        this.setState({allProductsData : responseJsonAPI.data})
      }

     }

    // Customizable Area End
    }
  
   // Customizable Area Start
   async componentDidMount() {
    this.getAllAssociatedProducts();
    const lang = localStorage.getItem("lang") ?? "en";
    await i18n.changeLanguage(lang);
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
   componentDidUpdate(prevProps:any, prevState:any) {
    const { allProductsData } = this.state;
    const { choosenProducts, productQuantities } = this.state;

    if (prevState.allProductsData !== allProductsData || prevState.choosenProducts !== choosenProducts) {
      const filteredProducts = allProductsData.filter((product:any) => choosenProducts.includes(product.id));
      this.setState({ choosenProductsData: filteredProducts });
    }

    if (prevState.choosenProductsData !== this.state.choosenProductsData || prevState.productQuantities !== productQuantities) {
      const updatedResponse = this.calculateUpdatedResponse(this.state.choosenProductsData, productQuantities);
      const totalAmount = this.calculateTotalAmount(updatedResponse);
      this.setState({ totalAmount });
    }
  }
  viewMoreResults = () => {
    this.setState({
      expanded: !this.state.expanded
    })
   
    
  };
  openItemsInfoDetail = () => {
    this.setState({ openItemInfo: true });
  };
  closeItemsInfoDetail = () => {
    this.setState({ openItemInfo: false });
  }
   handleSelectedItem = (productId: string) => {
    this.setState((prevState) => {
      const { choosenProducts, productQuantities } = prevState;
      const updatedchoosenProducts = choosenProducts.includes(productId)
        ? choosenProducts.filter((id: any) => id !== productId)
        : [...choosenProducts, productId];
      const updatedQuantities = { ...productQuantities };
      if (updatedchoosenProducts.includes(productId)) {
        updatedQuantities[productId] = productQuantities[productId] || 1;
      } else {
        delete updatedQuantities[productId];
      }
  
      return {
        choosenProducts: updatedchoosenProducts,
        productQuantities: updatedQuantities,
      };
    });
  
  };

  handleSelectAllItems = (event: any) => {
    const { allProductsData, productQuantities } = this.state;
    const allProductIds = allProductsData.map((product: any) => product.id);
    const updatedQuantities = { ...productQuantities };
    if (event.target.checked) {
      allProductIds.forEach((productId: any) => {
        if (!updatedQuantities[productId]) {
          updatedQuantities[productId] = 1;
        }
      });
    } else {
     
      allProductIds.forEach((productId: any) => {
        delete updatedQuantities[productId];
      });
    }
  
    this.setState({
      selectAll: event.target.checked,
      choosenProducts: event.target.checked ? allProductIds : [],
      productQuantities: updatedQuantities,
    });
  };
  calculateUpdatedResponse = (originalResponse:any, productQuantities:any) => {
    return originalResponse.map((item:any) => {
      const productId = item.id;
      const pquantity = productQuantities[productId] || 1;

      if (item.attributes.prices && item.attributes.prices.length > 0) {
        const price = item.attributes.prices[0];
        const totalPrice = (price.amount || 1) * pquantity;

        return {
          ...item,
          attributes: {
            ...item.attributes,
            prices: [
              {
                ...price,
                amount: totalPrice,
              },
            ],
          },
        };
      }

      return item;
    });
  };
  calculateTotalAmount = (products:any) => {
    let totalAmount = 0;

    products.forEach((product:any) => {
      if (product.attributes.prices && product.attributes.prices.length > 0) {
        const price = product.attributes.prices[0];
        totalAmount += price.amount || 0;
      }
    });

    return totalAmount;
  };
  handleAddButtonClickItems = (productId:string) => {
    this.setState((prevState) => {
      const { productQuantities } = prevState;
      const currentQuantity = productQuantities[productId] || 1;
      const updatedQuantities = { ...productQuantities, [productId]: currentQuantity + 1 };
      return { productQuantities: updatedQuantities };
    });
  };

  handleRemoveButtonClickItems = (productId:string) => {
    this.setState((prevState) => {
      const { productQuantities } = prevState;
      const currentQuantity = productQuantities[productId] || 1;
      const updatedQuantities = { ...productQuantities, [productId]: Math.max(0, currentQuantity - 1) };
      return { productQuantities: updatedQuantities };
    });
  };
  removeChosenProductItems = (productIdToRemove:any) => {
    this.setState((prevState) => ({
      choosenProducts: prevState.choosenProducts.filter((id:any) => id !== productIdToRemove),
    }));
  };
  handleAllSearchesDeals = (event: any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  handleCheckedSelectionDeals = (id: any, name: any) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: id,
    }));
  };
  getAllAssociatedProducts = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllProductsAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );

   

    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
   // Customizable Area End
}
