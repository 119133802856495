// Customizable Area Start
import React, { useEffect } from "react";
import { Box, ListItem, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

const useStyles = makeStyles((theme) => ({
  fieldRequirement: {
    marginTop: "24px",
    marginBottom: "18px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "14px",
    },
    "& .requirement-title": {
      fontSize: "12px",
      lineHeight: "16px",
      marginBottom: "14px",
      letterSpacing: "0.4px",
      color: "#505050",
      fontWeight: "400",
      fontFamily: "Roboto",
    },
    "& .requirement-list": {
      padding: "0",
      display: "flex",
      flexWrap: "wrap",
      margin: "0 -10px",
      "& .list-icon": {
        lineHeight: "0",
        marginRight: "5px",
        "& .MuiSvgIcon-root": {
          width: "10px",
          height: "10px",
          color: "#94a3b8",
          "&.check-icon": {
            color: "#00ba88",
          },
          "&.clear-icon": {
            color: "#bf3e50",
          },
        },
      },
      "& .list-item": {
        width: "50%",
        padding: "0 10px",
        fontSize: "12px",
        lineHeight: "16px",
        marginBottom: "6px",
        letterSpacing: "0.4px",
        color: "rgba(0,0,0,0.87)",
        fontWeight: "400",
        fontFamily: "Roboto",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          marginBottom: "14px",
        },
        "&:last-child": {
          [theme.breakpoints.down("xs")]: {
            marginBottom: "0",
          },
        },
      },
    },
  },
}));
const CustomPwdRequirement: React.FC<{
  password: string;
  onPassChange?: (isValid: boolean) => void;
  t?: any;
}> = ({ password, onPassChange, t }) => {
  let isContainsUpperCase = () => /[A-Z]/.test(password);
  let isContainsLowerCase = () => /[a-z]/.test(password);
  let isContainsNumber = () => /\d/.test(password);
  let isMinLength = () => /.{8,}$/.test(password);
  const passwordIconChecker = (checkerType: any) => {
    if (password === "") {
      return <RadioButtonUncheckedIcon className="circle-icon" />;
    } else if (checkerType) {
      return <CheckIcon className="check-icon" />;
    } else {
      return <ClearIcon className="clear-icon" />;
    }
  };
  const classes = useStyles();
  useEffect(() => {
    if (onPassChange) {
      onPassChange(
        password !== "" &&
          isContainsUpperCase() &&
          isContainsLowerCase() &&
          isContainsNumber() &&
          isMinLength()
      );
    }
  }, [password]);
  return (
    <>
      <Box className={classes.fieldRequirement}>
        <Box className="requirement-title">Must Contain</Box>
        <List className="requirement-list">
          <ListItem className="list-item">
            <Box className="list-icon">
            {passwordIconChecker(isContainsUpperCase())}
            </Box>
            {t("At least one capital letter")}
          </ListItem>
          <ListItem className="list-item">
            <Box className="list-icon">
            {passwordIconChecker(isContainsLowerCase())}
            </Box>
            {t("At least one lowercase letter")}
          </ListItem>
          <ListItem className="list-item">
            <Box className="list-icon">
            {passwordIconChecker(isContainsNumber())}
            </Box>
            {t("At least one number")}
          </ListItem>
          <ListItem className="list-item">
            <Box className="list-icon">
              {passwordIconChecker(isMinLength())}
            </Box>
            {t("Minimum character length is 8 characters")}
          </ListItem>
        </List>
      </Box>
    </>
  );
};

export default CustomPwdRequirement;
// Customizable Area End
