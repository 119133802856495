import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  Divider,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  FormHelperText
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme
} from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CustomInput from "../../../components/src/CustomInput.web";
import AddPrice from "./AddPrice.web";
import CertificationModal from "./CertificationModal.web";
import Trophy from "./Trophy.web";
import { PriceData, TrophyData } from "./InventoryTypes";
import { ovalShape } from "./assets";
import { customStyles } from "../../../components/src/AddItemStyles.web";
import { Link } from "react-router-dom";
// import { customStyles } from "../../../components/src/Inventory/ItemDetailsStyles.web";
// Customizable Area End

import ItemDetailsController, {
  Props,
  configJSON,
} from "./ItemDetailsController.web";

export class ItemDetails extends ItemDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes, seletedItem } = this.props;
    const certifcation = this.getCertificateNames();
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box className={classes.basicInfoSection}>
        <Box className="heading-box">
          <Box>
            <Typography className={classes.heading} variant="h1">
              {this.t(`${configJSON.detailsTitle}`)}
            </Typography>
            <Typography className={classes.subtitleText}>
              {this.props.seletedItem === "Other Product" ? <>{this.t(`${configJSON.otherDetails}`)}</> : <>{this.t(`${configJSON.detailSubtext}`)}</>}
            </Typography>
          </Box>
          <Box className="item-box">
            {seletedItem}
          </Box>
        </Box>
        {/* details form */}
        <Grid className={classes.formSection} container alignItems="center" justifyContent="center">
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <CustomInput
              value={this.state.description}
              onChange={(event) => this.handleDescription(event)}
              placeholder={this.t(`${configJSON.descPlaceholder}`)}
              data-testId={'descriptionField'}
              isMultiline={true}
              rows={5}
            />
          </Grid>
          {seletedItem === configJSON.otherText ? null :
            <Grid item md={12} lg={12} sm={12} xs={12}>
              <CustomInput
                value={this.state.aging}
                onChange={(event) => this.handleAging(event)}
                placeholder={this.t(`${configJSON.agingPlaceholder}`)}
                data-testId={'agingField'}
                errors={this.state.agingPotentialError}
              />
            </Grid>
          }
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <FormControl className="select-outer" variant="outlined">
              <InputLabel>
                {this.t(`${configJSON.volumePlaceholder}`)}
              </InputLabel>
              <Select data-testId={"volumeFeild"}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  classes: { paper: classes.dropdownStyle },
                }}
                value={this.state.volume}
                onChange={(event) => this.handleVolume(event)}
                IconComponent={(props) => (
                  <ExpandMoreIcon {...props} />
                )}
                label={this.t(`${configJSON.volumePlaceholder}`)}
              >
                {/* {this.state.ContainerVolumeData.map((data)=>{
                return <MenuItem value={data.name} key={data.id}
                data-testId={`volume-${data.id}`}
                >
                  {data.name}
                </MenuItem>
              })} */}
                {this.state.ContainerVolumeData
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort the ContainerVolumeData alphabetically by name
                  .map((data) => (
                    <MenuItem value={data.name} key={data.id} data-testId={`volume-${data.id}`}>
                      {data.name}
                    </MenuItem>
                  ))
                }


              </Select>
            </FormControl>
          </Grid>
          {seletedItem === configJSON.otherText ? null :
            <Grid item md={12} lg={12} sm={12} xs={12} >
              <CustomInput
                value={this.state.alcohol}
                onChange={(event) => this.handleAlcohol(event)}
                placeholder={this.t(`${configJSON.alcoholPlaceholder} %`)}
                data-testId={"alcoholFeild"}
              />
            </Grid>
          }
          {/* Price and tax box */}
          {this.state.priceData.length != 0 ?
            <Box className={classes.priceSection}>
              <Box className={classes.PriceHeading}>{this.t(`${configJSON.priceTitle}`)}</Box>
              <Box className={classes.priceDetails} data-testId={"price-detailsBox"}>
                {this.state.priceData.map((data: PriceData, index: number) => {
                  return (
                    <Box className="price-items" key={index} data-testId={`priceData-${index}`}>
                      <Typography className="price-label">
                        {this.t(`${data.priceLabel}`)}
                      </Typography>
                      <Box className="details-box">
                        <Typography className="price">{configJSON.dollarSymbol} {data.itemPrice}</Typography>
                        <Typography className="price-label" data-testId={`taxText-${index}`}>
                          {data.isTaxIncluded === true && this.t(configJSON.taxincludedText)}
                        </Typography>
                        <EditOutlinedIcon className="icons" onClick={() => this.editItemPrice(data)} />
                        <CloseIcon className="icons" data-testId={"del-price"}
                          onClick={() => this.deleteItemPrice(data.labelId)} />
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            </Box>
            : null
          }
          <Box className={classes.priceTaxBox} onClick={this.handlePriceModal}
            data-testId={"addPriceBtn"} tabIndex={0}
          >
            + {this.t(`${configJSON.priceAndTaxText}`)}
          </Box>
          {/* Price Modal */}
          <AddPrice
            openPriceModal={this.state.ispriceModal}
            onclose={this.closePriceModal}
            onAddingPrice={this.createdItemPrice}
            data-testId={"addPriceModal"}
            editPriceData={this.state.editPriceData}
          />
          {seletedItem === (configJSON.beerText) || seletedItem === (configJSON.spiritText) ?
            <Grid item md={12} lg={12} sm={12} xs={12}>
              <CustomInput
                value={this.state.production}
                onChange={(event) => this.handleProductionDescription(event)}
                placeholder={this.t(`${configJSON.prodProcessPlaceholder}`)}
                data-testId={'productionDesc'}
                rows={5}
                isMultiline={true}
              />
            </Grid>
            :
            <Grid item md={12} lg={12} sm={12} xs={12}>
              <FormControl className="select-outer" variant="outlined">
                <InputLabel>
                  {this.t(`${configJSON.prodProcessPlaceholder}`)}
                </InputLabel>
                <Select
                  data-testId={"productionFeild"}
                  label={this.t(`${configJSON.prodProcessPlaceholder}`)}
                  value={this.state.production}
                  onChange={(event) => this.handleProduction(event)}
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} />
                  )}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                >
                  {/* {this.state.ProductionProcessData.map((data) => {
                    return <MenuItem value={data.name} key={data.id} data-testId={`process-${data.id}`}>
                      {data.name}
                    </MenuItem>
                  })} */}
                  {this.state.ProductionProcessData
                    .sort((a, b) => a.name.localeCompare(b.name)) // Sort the ProductionProcessData alphabetically by name
                    .map((data) => (
                      <MenuItem value={data.name} key={data.id} data-testId={`process-${data.id}`}>
                        {data.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          }

          <Grid item md={12} lg={12} sm={12} xs={12} className="form-row-6">
            <Grid item md={6} lg={6} sm={6} xs={12}>
              <CustomInput
                value={this.state.agingProcess}
                onChange={(event) => this.handleagingProcess(event)}
                placeholder={this.t(`${configJSON.agingProcessPlaceholder}`)}
                data-testId={"agingProcessFeild"}
              />
            </Grid>
            <Grid item md={6} lg={6} sm={6} xs={12}>
              <Box className={classes.certificateBox}
                tabIndex={0} onClick={this.openCertification}
                data-testId={"certificationField"}
              >
                {certifcation.name == "" ? 
                <>
                <Typography>{this.t(`${configJSON.certificationPlaceholder}`)}</Typography>
                <ArrowForwardIosIcon /> 
                </>
                :<Box className={classes.selectedCertificate}>
                  <img src={certifcation.image} alt="" className="certificateImg" />
                  <span className="cardBoxName">{certifcation.name}</span>
                  <Link
                    className="close"
                    to="#"
                  >
                    <CloseIcon />
                  </Link>
                </Box>   
                }             
              </Box>
            </Grid>
          </Grid>
          {/* certification modal */}
          <CertificationModal
            certificates={this.state.certificates}
            openModal={this.state.isCertification}
            closeModal={this.closeCertification}
            data-testId={"certificationModal"}
          />
          <Grid item md={12} lg={12} sm={12} xs={12} className="form-row-6">
            <Grid item md={6} lg={6} sm={6} xs={12}>
              <CustomInput
                value={this.state.sku}
                onChange={(event) => this.handlesku(event)}
                placeholder={this.t(`${configJSON.skuPlaceholder}`)}
                data-testId={'skuField'}
              />
            </Grid>
            <Grid item md={6} lg={6} sm={6} xs={12} className={classes.withHelperText}>
              <CustomInput
                value={this.state.gtinNumber}
                onChange={(event) => this.handlegtinNum(event)}
                placeholder={this.t(`${configJSON.GtinPlaceholder}`)}
                data-testId={'gtinField'}
                errors={this.state.gtinError}
              />
              <FormHelperText className="helper-text">
                {this.t(`${configJSON.gtinDes}`)}
              </FormHelperText>
            </Grid>
          </Grid>
          {seletedItem === configJSON.beerText &&
            <>
              <Grid item md={12} lg={12} sm={12} xs={12} className="form-row-6">
                <Grid item md={6} lg={6} sm={6} xs={12}>
                  <CustomInput data-testId="IbuField"
                    value={this.state.ibu}
                    onChange={(event) => this.handleIBU(event)}
                    placeholder="IBU"
                    errors={this.state.ibuError}
                  />
                </Grid>
                <Grid item md={6} lg={6} sm={6} xs={12}>
                  <CustomInput
                    data-testId="srmField"
                    value={this.state.srm}
                    onChange={(event) => this.handleSRM(event)}
                    placeholder="SRM"
                    errors={this.state.srmError}
                  />
                </Grid>
              </Grid>
              <Grid item md={12} lg={12} sm={12} xs={12} className="form-row-6">
                <Grid item md={6} lg={6} sm={6} xs={12}>
                  <CustomInput
                    data-testId="ogField"
                    value={this.state.OGfield}
                    onChange={(event) => this.handleOG(event)}
                    errors={this.state.OGError}
                    placeholder="OG"
                  />
                </Grid>
                <Grid item md={6} lg={6} sm={6} xs={12}>
                  <CustomInput
                    data-testId="fgField"
                    value={this.state.fGfield}
                    onChange={(event) => this.handleFGField(event)}
                    placeholder="FG"
                    errors={this.state.fGError}
                  />
                </Grid>
              </Grid>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <CustomInput
                  value={this.state.eBCfield}
                  onChange={(event) => this.handleEBC(event)}
                  placeholder="EBC"
                  data-testId="ebcField"
                  errors={this.state.eBCError}
                />
              </Grid>
            </>
          }
          {seletedItem === configJSON.otherText &&
            <Grid item md={12} lg={12} sm={12} xs={12}>
              <CustomInput
                value={this.state.otherProComment}
                onChange={(event) => this.handleOtherProdComment(event)}
                placeholder={this.t(`${configJSON.commentPlaceholder}`)}
                data-testId={'otherCommentField'}
                isMultiline={true}
                rows={5}
              />
            </Grid>
          }
          {/*  trophy details box */}
          {this.state.trophyData.length != 0 ?
            <Box className={classes.trophySection}>
              <Box className={classes.PriceHeading}>{this.t(`${configJSON.trophyModalTitle}`)}</Box>
              <Box className={classes.trophyDetails}>
                {this.state.trophyData.map((data: TrophyData, index: number) => {
                  return (
                    <Box className="torphy-items" key={index} data-testId={`trophyData-${index}`}>
                      <Box className="details-box">
                        <Typography className="trophy-name" data-testId={`trophy-name${data.id}`}>
                          {data.trophyName}
                        </Typography>
                        <EditOutlinedIcon className="icons" data-testId="edit-trophy"
                          onClick={() => this.editTrophy(data)} />
                        <CloseIcon className="icons" data-test-id="removeTrophy"
                          onClick={() => this.deleteTrophy(data.trophyId)} />
                      </Box>
                      <Box className="details-box">
                        <Typography className="sub-text">{data.trophyYear}</Typography>
                        <img src={ovalShape} alt="dot img" className="dot-img" />
                        <Typography className="sub-text">{data.trophyResult}</Typography>
                      </Box>
                      <Typography className="sub-text">{data.trophyComment}</Typography>
                    </Box>
                  )
                })}
              </Box>
            </Box>
            : null
          }
          <Box className={classes.priceTaxBox} data-testId={"addTrophyBtn"}
            onClick={this.handleTrophyModal}
          >
            {this.state.trophyData.length>0? this.t(`${configJSON.addAnotherTrophyText}`):this.t(`${configJSON.trophyText}`)}
          </Box>
          <Trophy
            openTrophyModal={this.state.isTrophyModal}
            closeTrophy={this.closeTrophyModal}
            data-testId={'trophyModal'}
            addingTrpohyData={this.trophyDetails}
            editTrophyData={this.state.editTrophyData}
          />
        </Grid>
        <Box className={classes.formButtons}>
          <Button className="backBtn" onClick={this.goBackToLocationPage} data-testId="back-btn">
            {this.t(`${configJSON.backButton}`)}
          </Button>
          <Button className="next-step-btn" onClick={this.handleItemdetails} data-testId="detailNextBtn">
            {this.t(`${configJSON.nextButton}`)}
          </Button>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start


export default withStyles(customStyles, { withTheme: true })(ItemDetails);
// Customizable Area End
